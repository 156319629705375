import * as API from "../../../utils/axios";
import * as subCategoriesTypes from "../types/subcategory";
import { catchError } from "./error";

export const getSubCategoryByCategoryCreator = (categoryId) => {
  return (dispatch) => {
    dispatch({ type: subCategoriesTypes.FETCH_SUBCATEGORIES });
    // now call API
    API.get(`api/subcategory/categories/${categoryId}`)
      .then((res) => {
        dispatch({
          type: subCategoriesTypes.FETCH_SUBCATEGORIES_SUCCESS,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: subCategoriesTypes.FETCH_SUBCATEGORIES_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
