import React from "react";
import StylesWrapper from "./styles";

const BannerWithScroll = ({
  image,
  imageHeight,
  title,
  subtitle,
  renderMain,
}) => (
  <StylesWrapper imageHeight={imageHeight}>
    <img className="cover-img" src={image} />
    <div className="cover-txt">
      <div className="subtitle mx-1">{subtitle}</div>
      <div className="title">{title}</div>
    </div>
    <div className="main">{renderMain()}</div>
  </StylesWrapper>
);

export default BannerWithScroll;
