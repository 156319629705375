import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import addIcon from "../../icons/addIcon.png";
import closeCircle from "../../assets/icons/close-circular.svg";
import "./UploadImage.css";

const UploadImage = ({
  fieldName,
  onFileSelected,
  onFileRemove,
  onlyPreviewImage,
  closeIcon,
}) => {
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState();
  const [previewImage, setPreviewImage] = useState();

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = function (e) {
        setPreviewImage(reader.result);
      };
    }
  }, [selectedFile]);

  const renderViewOnlyPreview = () => {
    return (
      <div className="previewContainer">
        <img
          src={onlyPreviewImage}
          className="previewImage"
          alt="test"
          style={{ objectFit: "contain" }}
        />
        {closeIcon && (
          <img
            src={closeCircle}
            width={32}
            height={32}
            className="closeIcon"
            alt="close icon"
            onClick={() => {
              setSelectedFile(undefined);
              setPreviewImage("");
              onFileRemove();
            }}
          />
        )}
      </div>
    );
  };

  const renderUploadBtn = () => {
    return (
      <>
        {!selectedFile ? (
          <label className="uploadCardLabelContainer">
            <img src={addIcon} width={32} height={32} alt="add icon" />
            <span className="modal-section-title uploadLabelText">
              {t("upload")}
            </span>
            <input
              type="file"
              name={fieldName}
              onChange={(e) => {
                onFileSelected(e.target.files[0]);
                setSelectedFile(e.target.files[0]);
              }}
              style={{ display: "none" }}
            />
          </label>
        ) : (
          <div className="previewContainer">
            <img
              src={previewImage}
              className="previewImage"
              alt="test"
              style={{ objectFit: "contain" }}
            />
            <img
              src={closeCircle}
              width={32}
              height={32}
              className="closeIcon"
              alt="close icon"
              onClick={() => {
                setSelectedFile(undefined);
                setPreviewImage("");
                onFileRemove();
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {onlyPreviewImage && typeof onlyPreviewImage === "string"
        ? renderViewOnlyPreview()
        : renderUploadBtn()}
    </>
  );
};

export default UploadImage;
