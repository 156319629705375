export const GET_PROJECTS_LOADING = "GET_PROJECTS_LOADING";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR";

export const GET_PROJECT_DETAILS_LOADING = "GET_PROJECT_DETAILS_LOADING";
export const GET_PROJECT_DETAILS_SUCCESS = "GET_PROJECT_DETAILS_SUCCESS";
export const GET_PROJECT_DETAILS_ERROR = "GET_PROJECT_DETAILS_ERROR";

export const GET_PROJECT_NFTS_LOADING = "GET_PROJECT_NFTS_LOADING";
export const GET_PROJECT_NFTS_SUCCESS = "GET_PROJECT_NFTS_SUCCESS";
export const GET_PROJECT_NFTS_ERROR = "GET_PROJECT_NFTS_ERROR";

export const GET_PROJECT_NFT_DETAILS_LOADING =
  "GET_PROJECT_NFT_DETAILS_LOADING";
export const GET_PROJECT_NFT_DETAILS_SUCCESS =
  "GET_PROJECT_NFT_DETAILS_SUCCESS";
export const GET_PROJECT_NFT_DETAILS_ERROR = "GET_PROJECT_NFT_DETAILS_ERROR";

export const SAVE_PROJECT_FILTER_NAME = "SAVE_PROJECT_FILTER_NAME";
export const SAVE_PROJECT_FILTER_CONTRACT = "SAVE_PROJECT_FILTER_CONTRACT";
export const SAVE_PROJECT_FILTER_PRICE = "SAVE_PROJECT_FILTER_PRICE";
export const SAVE_PROJECT_FILTER_SALE_TYPES = "SAVE_PROJECT_FILTER_SALE_TYPES";
export const SAVE_PROJECT_FILTER_PROPERTIES = "SAVE_PROJECT_FILTER_PROPERTIES";
export const REMOVE_PROJECT_FILTER_SALE_TYPES =
  "REMOVE_PROJECT_FILTER_SALE_TYPES";
export const REMOVE_PROJECT_FILTER_PROPERTIES =
  "REMOVE_PROJECT_FILTER_PROPERTIES";
export const CLEAR_PROJECT_FILTER = "CLEAR_PROJECT_FILTER";
