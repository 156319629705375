import * as userTypes from "../actions/types/user";

const popularSellers = {
  loading: false,
  users: [],
  error: null,
};

const latestSellerUploadNFTs = {
  loading: false,
  users: [],
  error: null,
};

const allSellers = {
  loading: false,
  users: [],
  count: 0,
  has_more: true,
  error: null,
};

export const popularSellersReducer = (state = popularSellers, action) => {
  switch (action.type) {
    case userTypes.GET_POPULAR_SELLERS_LOADING:
      return { ...state, loading: true };
    case userTypes.GET_POPULAR_SELLERS_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: null };
    case userTypes.GET_POPULAR_SELLERS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const latestSellersUploadNftsReducer = (
  state = latestSellerUploadNFTs,
  action
) => {
  switch (action.type) {
    case userTypes.GET_LATEST_SELLERS_UPLOAD_LOADING:
      return { ...state, loading: true };
    case userTypes.GET_LATEST_SELLERS_UPLOAD_SUCCESS:
      return { ...state, loading: false, users: action.payload, error: null };
    case userTypes.GET_LATEST_SELLERS_UPLOAD_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const allSellersReducer = (state = allSellers, action) => {
  switch (action.type) {
    case userTypes.GET_ALL_SELLERS_LOADING:
      return { ...state, loading: true };
    case userTypes.GET_ALL_SELLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.load
          ? [...state.users, ...action.payload]
          : action.payload,
        count: action.count,
        has_more: action.has_more,
        error: null,
      };
    case userTypes.GET_ALL_SELLERS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
