import { useTranslation } from "react-i18next";
import transactions from "../../assets/icons/transactions.svg";
import transfer from "../../assets/icons/transfer.svg";
import topUp from "../../assets/icons/top-up.svg";
import linkedNfts from "../../assets/icons/linked-nfts.svg";
// import cards from "../../assets/icons/cards.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 48px 0;
  border-right: 1px solid #efefef;
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  li {
    padding: 8px 0;
  }
  li:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }
  a {
    text-decoration: none;
    color: #777e90;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 8px 0 8px 48px;
    border-left: 5px solid transparent;
  }
  .rtl a {
    padding: 8px 48px 8px 0;
  }
  img {
    margin-right: 20px;
  }
  .rtl img {
    margin-left: 20px;
    margin-right: 0;
  }
  a:hover {
    border-left: 5px solid #6d3190;
    color: #23262f;
  }
  .active {
    color: #23262f;
    border-left: 5px solid #6d3190;
  }
  @media (max-width: 1200px) {
    padding: 24px 0;
    border-right: none;
    .active {
      border-left: none;
    }
    ul {
      flex-direction: row;
      flex-wrap: wrap;
    }
    li:not(:last-child) {
      border-bottom: none;
    }
    a {
      border-left: none;
      padding: 8px 0 8px 0;
      margin-right: 32px;
    }
    a:hover {
      border-left: none;
    }
    img {
      margin-right: 10px;
    }
  }
`;

const SideLinks = ({ setCurrentSection, currentSection }) => {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper>
      <ul className={i18n.dir()}>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection("LinkedNft");
            }}
            className={currentSection === "LinkedNft" ? "active" : ""}
          >
            <img src={linkedNfts} alt="" className="d-none d-md-inline-block" />
            <span>{t("Linked NFTs")}</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection("Transactions");
            }}
            className={currentSection === "Transactions" ? "active" : ""}
          >
            <img
              src={transactions}
              alt=""
              className="d-none d-md-inline-block"
            />
            <span>{t("Transactions")}</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection("Transfer");
            }}
            className={currentSection === "Transfer" ? "active" : ""}
          >
            <img src={transfer} alt="" className="d-none d-md-inline-block" />
            <span>{t("Transfer")}</span>
          </a>
        </li>
        <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection("TopUp");
            }}
            className={currentSection === "TopUp" ? "active" : ""}
          >
            <img src={topUp} alt="" className="d-none d-md-inline-block" />
            <span>{t("Top up")}</span>
          </a>
        </li>
        {/* <li>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentSection("Cards");
            }}
            className={currentSection === "Cards" ? "active" : ""}
          >
            <img src={cards} alt="" className="d-none d-md-inline-block" />
            <span>{t("Cards")}</span>
          </a>
        </li> */}
      </ul>
    </Wrapper>
  );
};

export default SideLinks;
