import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import card from '../assets/icons/card-big.svg'
import styled from 'styled-components'
import arrowRight from '../icons/arrow-right.svg'

const Wrapper = styled.div`
    padding-bottom: 144px;
    form {
        max-width: 465px;
    }
    .form-check-input {
        width: 20px !important;
        margin-right: 8px;
    }
    .form-check-input:checked {
        background-color: #717171 !important;
    }
    .form-check-label {
        font-size: 14px;
        color: #717171;
        text-transform: uppercase;
    }
`

export const EditCreditCard = () => {
    return (
        <Container>
            <Wrapper>
                <Row className="border-bottom-1 justify-content-between pt-5 pb-4">
                    <Col>
                        <h4 className="fw-bold">Add credit card</h4>
                        <p className="width-600 mb-4">
                            With supporting text below as a natural lead-in to
                            additional content
                        </p>
                    </Col>
                    <Col className="d-none d-md-block">
                        <div className="d-flex justify-content-end">
                            <img src={card} alt="" />
                        </div>
                    </Col>
                </Row>
                <Form className="mt-5">
                    {/* card number */}
                    <Form.Group
                        controlId="card-number"
                        className="payment-page-form-group"
                    >
                        <Form.Label className="payment-page-label">
                            Card Number
                        </Form.Label>
                        <Form.Control
                            className="payment-page-input"
                            type="text"
                            placeholder="4242424242424242"
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            {/* expiration date */}
                            <Form.Group
                                controlId="expiration-date"
                                className="payment-page-form-group"
                            >
                                <Form.Label className="payment-page-label">
                                    Expiration Date
                                </Form.Label>
                                <Form.Control
                                    className="payment-page-input"
                                    type="date"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            {/* cvv */}
                            <Form.Group
                                controlId="cvv"
                                className="payment-page-form-group"
                            >
                                <Form.Label className="payment-page-label">
                                    CVV
                                </Form.Label>
                                <Form.Control
                                    className="payment-page-input"
                                    type="text"
                                    placeholder="424"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* card holder */}
                    <Form.Group
                        controlId="card-holder"
                        className="payment-page-form-group"
                    >
                        <Form.Label className="payment-page-label">
                            Card Holder
                        </Form.Label>
                        <Form.Control
                            className="payment-page-input"
                            type="text"
                            placeholder="Cameron Williamson"
                        />
                    </Form.Group>
                    <Row className="mt-5">
                        <Col>
                            {/* first name */}
                            <Form.Group
                                controlId="first-name"
                                className="payment-page-form-group"
                            >
                                <Form.Label className="payment-page-label">
                                    First Name
                                </Form.Label>
                                <Form.Control
                                    className="payment-page-input"
                                    type="text"
                                    placeholder="Khaled"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            {/* last name */}
                            <Form.Group
                                controlId="last-name"
                                className="payment-page-form-group"
                            >
                                <Form.Label className="payment-page-label">
                                    Last Name
                                </Form.Label>
                                <Form.Control
                                    className="payment-page-input"
                                    type="text"
                                    placeholder="Ahmad"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* address 1 */}
                    <Form.Group
                        controlId="address-1"
                        className="payment-page-form-group"
                    >
                        <Form.Label className="payment-page-label">
                            Address 1
                        </Form.Label>
                        <Form.Control
                            className="payment-page-input"
                            type="text"
                            placeholder="Amman, airport st"
                        />
                    </Form.Group>
                    {/* address 2 */}
                    <Form.Group
                        controlId="address-2"
                        className="payment-page-form-group"
                    >
                        <Form.Label className="payment-page-label">
                            Address 2
                        </Form.Label>
                        <Form.Control
                            className="payment-page-input"
                            type="text"
                            placeholder="Road, Apt 20"
                        />
                    </Form.Group>
                    {/* country */}
                    <Form.Group
                        controlId="country"
                        className="payment-page-form-group"
                    >
                        <Form.Label className="payment-page-label">
                            Country
                        </Form.Label>
                        <Form.Control
                            className="payment-page-input"
                            type="text"
                            placeholder="Jordan"
                        />
                    </Form.Group>
                    <Row>
                        <Col>
                            {/* state */}
                            <Form.Group
                                controlId="state"
                                className="payment-page-form-group"
                            >
                                <Form.Label className="payment-page-label">
                                    State
                                </Form.Label>
                                <Form.Control
                                    className="payment-page-input"
                                    type="text"
                                    placeholder="Swefieh"
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            {/* zip code */}
                            <Form.Group
                                controlId="zip"
                                className="payment-page-form-group"
                            >
                                <Form.Label className="payment-page-label">
                                    ZIP Code
                                </Form.Label>
                                <Form.Control
                                    className="payment-page-input"
                                    type="text"
                                    placeholder="10001"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex align-items-center">
                        <Button className="me-4">
                            Save
                            <img src={arrowRight} alt="arrow right" />
                        </Button>
                    </div>
                </Form>
            </Wrapper>
        </Container>
    )
}

