import axios from "axios";
import { chainIds } from "../../../common/constants/chainIds";
import * as moralisType from "../types/moralis";

//! not ready
export const getBezelNftsCreator = () => {};

export const getLinkedNftsCreator = (address, params) => {
  return (dispatch) => {
    if (address) {
      dispatch({
        type: moralisType.GET_LINKED_NFTS_LOADING,
        loading: true,
      });

      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-API-Key":
            window.MORALIS_API_KEY,
        },
      };
      const url = `https://deep-index.moralis.io/api/v2/${address}/nft?chain=${
        chainIds[params.chain]
      }&format=decimal${params?.cursor ? `&cursor=${params?.cursor}` : ""}`;

      console.log({ url });
      fetch(url, options)
        .then((response) => response.json())
        .then(async (res) => {
          //* extract the token metadata from token_uri or directly from metadata prop
          const nfts = [];
          const results = res.result;
          console.log({ results });
          for (let i = 0; i < results?.length; i++) {
            if (results[i]?.metadata) {
              const metadata = JSON.parse(results[i]?.metadata);

              nfts.push({
                _id: "none",
                token_id: results[i].token_id,
                contract_address: results[i].token_address,
                url: metadata?.image?.startsWith("ipfs")
                  ? `https://ipfs.io/ipfs/${metadata?.image.substring(7)}`
                  : metadata?.image,
                name: metadata?.name,
                token_standard: results[i]?.contract_type.substring(3),
                type: "image",
                no_action: true,
              });
            }
          }

          //* start dispatch the data
          dispatch({
            type: moralisType.GET_LINKED_NFTS_SUCCESS,
            loading: false,
            payload: nfts,
            cursor: res.cursor,
            has_more: res.cursor ? true : false,
            chain: params?.chain || "matic",
          });
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: moralisType.GET_LINKED_NFTS_ERROR,
            error: error,
          });
        });
    }
  };
};
