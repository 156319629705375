import { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  PlaceBidModals,
  BuyOfferModal,
  ListTokenModal,
  BurnTokenModal,
  ChangeCollectionModal,
  CancelSaleModal,
  EditBidModals,
  EditSalePriceModal,
  CancelBidModal,
  AcceptHighestBidModals,
  WalletModal,
  ImageModal,
  ExtendAuctionModal,
  TransferNFTModal,
  CancelOfferModal,
  EditOfferModal,
  AcceptOfferModal,
  MakeOfferModal,
} from "@components";
import { chains } from "@config";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "@context/Web3auth";
import { ReactComponent as Expand } from "@assets/icons/expand.svg";

//store
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
// import { creatorCollectionsCreators } from "../../../store";
//moralis
import Moralis from "moralis";
//
// import { useSubscription } from "../../../socket";
// import { nftDetailsEvents, socketChannels } from "../../../common/sockets";
// import { ReactComponent as Expand } from "@assets/icons/expand.svg";
import {
  NFTMedia,
  NFTProperties,
  ShareOptions,
  NFTOptions,
  //   SaleButtons,
  Tabs,
  TabsContent,
  NFTDetails,
  //   NFTDetailsBezel,
  NFTListing,
  //   NFTAuction,
} from "../../../NFT/Partials";
// import { themeColors } from "../../../common/constants/partner";
// import { serverUrl, appId } from "../../../data";
import { StylesWrapper } from "../../../NFT/styles";
import { projectActionCreators, offerActionsCreators } from "../../../../store";
import { themeColors } from "../../../../common/constants/partner";

// Moralis.start({ serverUrl, appId });

export const ProjectNftDetails = () => {
  const colors = themeColors("light");
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { contract, token_id } = useParams();
  const { t, i18n } = useTranslation();

  const { loading, nft } = useSelector((state) => state.projectNftDetails);
  //use context
  const { account, active, chainId } = useWeb3React();
  const { chain, privateKey, getAccounts, onChangeChain } = useWeb3Auth();
  //modal options
  const [imageModal, setImageModal] = useState(false);

  //wallets
  const [defaultWallet, setDefaultWallet] = useState("");
  const [selectedWallet, setSelectWallet] = useState("");
  const [walletOptions, setWalletOptions] = useState([]);
  const [connectWalletModal, setConnectWalletModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false);
  const [showEditOfferModal, setShowEditOfferModal] = useState(false);
  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);

  const auth = useSelector((state) => state.auth);

  const { getProjectNftDetailsCreator } = bindActionCreators(
    projectActionCreators,
    dispatch
  );
  const { getOffersCreator } = bindActionCreators(
    offerActionsCreators,
    dispatch
  );

  useEffect(() => {
    if (privateKey) dataload();
  }, [privateKey, account, chain]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getProjectNftDetailsCreator(contract, token_id);
  };

  useEffect(() => {
    if (nft?._id)
      getOffersCreator(nft?._id, { skip: 0, limit: 10 }, false, "project");
  }, [nft]);

  // useSubscription(`${socketChannels.NFT_DETAILS}_${nft_id}`, nftDetailsEvents);

  const dataload = async () => {
    //get default wallet
    const wallet = await getAccounts();
    setSelectWallet(wallet.address);
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });

    if (account)
      if (chains[chain].chainId === chainId)
        wallets.push({ value: account, label: account });

    setWalletOptions(wallets);
  };

  const externalLoad = async () => {
    const options = { address: defaultWallet, chain: "rinkeby" };
    await Moralis.Web3API.account.getNFTs(options);
  };

  //0 sold, 1 not for sale, 2 fixed price, 3 auction
  const nftStatus =
    nft?.status == "PURCHASE"
      ? 0
      : nft?.listing?.sale_type == "0" ||
        nft?.status == "CANCEL" ||
        nft?.status == "UNLISTED"
      ? 1
      : nft?.listing?.sale_type == "1"
      ? 2
      : 3;
  const modalProps = {
    nft,
    selectedOwner,
    selectedOffer,
    dataload,
    externalLoad,
    walletOptions,
    selectedWallet,
    isProject: true,
    isApproved: false,
    isLazyItem: false,
    isMultiple: false,
    isExternal: false,
    isCancel: false,
    defaultWallet,
    closeOfferModal: (refresh = false) => {
      !!refresh && getData();
      setShowMakeOfferModal(false);
      setShowCancelOfferModal(false);
      setShowEditOfferModal(false);
      setShowAcceptOfferModal(false);
    },
  };
  const nftOptionsProps = {
    nft,
    nftStatus,
    selectedOwner,
    setSelectedOwner,
    setConnectWalletModal,
  };
  const is1155 = nft?.token_standard === "1155";
  const is721 = nft?.token_standard === "721";
  // const isFixedPrice = nft?.listing?.sale_type == "1";
  const isAuction = nft?.listing?.sale_type == "2";

  const isOwner = is1155
    ? auth?.account?.wallets.some((el) =>
        nft?.owners?.some((e) => el == e?.user?.create_wallet)
      )
    : auth?.account?.wallets.includes(nft?.owner?.create_wallet);

  const nftProps = {
    nft,
    colors,
    isOwner,
    isAuction,
    nftStatus,
    isProject: true,
    externalNFT: false,
    setSelectedOffer,
    setShowAcceptOfferModal: () => setShowAcceptOfferModal(true),
  };
  // const onScroll = () => {
  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight === scrollHeight) {
  //       // TO SOMETHING HERE
  //       console.log("Reached bottom");
  //     }
  //   }
  // };
  // const listInnerRef = useRef();

  return (
    <StylesWrapper isAuction={isAuction}>
      {loading ? (
        <p className="loading">{t("Loading...")}</p>
      ) : (
        <div>
          {connectWalletModal && (
            <WalletModal
              isOpen={connectWalletModal}
              setIsOpen={setConnectWalletModal}
            />
          )}
          {/* {showBuyOfferModal && <BuyOfferModal {...modalProps} />}
        {showTransferModal && <TransferNFTModal {...modalProps} />}
        {showListTokenModal && <ListTokenModal {...modalProps} />}
        {showEditBidModal && <EditBidModals {...modalProps} />}
        {showEditSalePriceModal && <EditSalePriceModal {...modalProps} />}
        {showBurnModal && <BurnTokenModals {...modalProps} />}
        // {showCancelSaleModal && <CancelSaleModal {...modalProps} />}
        {showPlaceBidModal && <PlaceBidModals {...modalProps} />}
        {showCancelBidModal && <CancelBidModals {...modalProps} />}
        {showAcceptHighestBidModal && (
          <AcceptHighestBidModals {...modalProps} />
        )}
        <ChangeCollectionModal
          nftId={nft?._id}
          colors={colors}
          collections={collections}
          isOpen={showChangeCollectionModal}
          setIsOpen={setShowChangeCollectionModal}
        />
        <ExtendAuctionModal
          {...modalProps}
          isOpen={showExtendAuctionModal}
          setIsOpen={setShowExtendAuctionModal}
        /> */}
          {showMakeOfferModal && <MakeOfferModal {...modalProps} />}
          {showCancelOfferModal && <CancelOfferModal {...modalProps} />}
          {showEditOfferModal && <EditOfferModal {...modalProps} />}
          {showAcceptOfferModal && <AcceptOfferModal {...modalProps} />}

          <ImageModal
            nft={nft}
            colors={colors}
            isOpen={imageModal}
            setIsOpen={setImageModal}
          />

          <div>
            <div className="media-bg">
              <Container fluid="lg">
                <NFTMedia nft={nft} fullView />
                <div className="d-flex flex-row justify-content-end">
                  <div
                    className="cursor expand"
                    onClick={() => setImageModal(true)}
                  >
                    <Expand color={colors?.icon} />
                  </div>
                  <ShareOptions {...nftProps} />
                  {isOwner && is721 && (
                    <div style={{ marginInlineStart: 10 }}>
                      <NFTOptions {...nftOptionsProps} />
                    </div>
                  )}
                </div>
              </Container>
            </div>
            <Container fluid="lg">
              <div className="flexbox d-flex flex-md-row flex-column align-items-center align-items-md-stretch mt-5">
                <div className="details-table" id="details-table">
                  <NFTDetails {...nftProps} />
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
                    <Tabs
                      {...nftProps}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                    {!isOwner &&
                      is721 &&
                      (nft?.offer?._id ? (
                        <div
                          className="d-flex flex-wrap align-items-center"
                          key={Math.random()}
                        >
                          <div
                            style={{ margin: 5 }}
                            className="btn btn-purple-outline"
                            onClick={() => setShowCancelOfferModal(true)}
                          >
                            {t("cancel_an_offer")}
                          </div>
                          <div
                            style={{ margin: 5 }}
                            className="btn btn-primary"
                            onClick={() => setShowEditOfferModal(true)}
                          >
                            {t("edit_an_offer")}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div
                            className="btn btn-primary"
                            onClick={() => setShowMakeOfferModal(true)}
                          >
                            {t("make_an_offer")}
                          </div>
                        </div>
                      ))}
                  </div>
                  <TabsContent {...nftProps} selectedTab={selectedTab} />
                </div>
                {is721 && nftStatus == 3 ? (
                  <div
                    style={{ flex: 1, width: "100%" }}
                    className="d-flex flex-column align-items-center align-items-md-stretch"
                  >
                    <div className="details-table-2">
                      {/* <NFTAuction {...nftProps} onOpenModal={onOpenModal} /> */}
                    </div>
                    <div className="details-table-2">
                      <NFTProperties {...nftProps} />
                    </div>
                  </div>
                ) : (
                  <div className="details-table-2">
                    <NFTProperties {...nftProps} />
                  </div>
                )}
              </div>
              {is1155 && (
                <div className="mt-4">
                  <div className="title">{t("listing")}</div>
                  <div className="details-table-3">
                    <NFTListing {...nftProps} {...nftOptionsProps} />
                  </div>
                </div>
              )}
            </Container>
          </div>

          <br />
          <br />
        </div>
      )}
    </StylesWrapper>
  );
};
