/* eslint-disable import/no-anonymous-default-export */
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Container } from "react-bootstrap";
import { RouteList } from "./routeList";
import ScrollToTop from "../ScrollToTop";
import { useWeb3Auth } from "../../context/Web3auth";
import { bindActionCreators } from "redux";
import { authActionsCreators } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../Layout";
import keys from "../../lib/config";

export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { logoutCreator } = bindActionCreators(authActionsCreators, dispatch);
  const { authenticated } = useSelector((state) => state.auth);
  const { privateKey, walletInfo, loading, logout } = useWeb3Auth();

  const logoutUser = async () => {
    await logout();
    logoutCreator();
  };

  useEffect(() => {
    if (!loading) {
      if (
        authenticated &&
        (privateKey === "" || typeof privateKey === "undefined")
      ) {
        // console.log("===> logging out", walletInfo?.address);
        logoutUser();
      }
    }
  }, [authenticated, loading, walletInfo, privateKey]);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          {authenticated !== null &&
            RouteList.map((props, key) => {
              return props.access ? (
                <Route
                  key={key}
                  {...props}
                  element={
                    loading ? (
                      <Container>
                        <p className="py-5">{t("Loading...")}</p>
                      </Container>
                    ) : authenticated ? (
                      props.element
                    ) : (
                      <Navigate to="/" />
                    )
                  }
                />
              ) : (
                <Route key={`p-${key}`} {...props} />
              );
            })}
        </Route>
      </Routes>
    </Router>
  );
};
