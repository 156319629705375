//* get latest nfts
export const GET_LATEST_NFTS_LOADING = "GET_LATEST_NFTS_LOADING";
export const GET_LATEST_NFTS_SUCCESS = "GET_LATEST_NFTS_SUCCESS";
export const GET_LATEST_NFTS_ERROR = "GET_LATEST_NFTS_ERROR";

//* get all nfts
export const GET_ALL_NFTS_LOADING = "GET_ALL_NFTS_LOADING";
export const GET_ALL_NFTS_SUCCESS = "GET_ALL_NFTS_SUCCESS";
export const GET_ALL_NFTS_ERROR = "GET_ALL_NFTS_ERROR";

//* get nft details
export const GET_NFT_DETAILS_LOADING = "GET_NFT_DETAILS_LOADING";
export const GET_NFT_DETAILS_SUCCESS = "GET_NFT_DETAILS_SUCCESS";
export const GET_NFT_DETAILS_ERROR = "GET_NFT_DETAILS_ERROR";

export const UPDATE_NFT_COLLECTION_LOADING = "UPDATE_NFT_COLLECTION_LOADING";
export const UPDATE_NFT_COLLECTION_SUCCESS = "UPDATE_NFT_COLLECTION_SUCCESS";
export const UPDATE_NFT_COLLECTION_ERROR = "UPDATE_NFT_COLLECTION_ERROR";

export const LIKE_NFT_LOADING = "LIKE_NFT_LOADING";
export const LIKE_NFT_SUCCESS = "LIKE_NFT_SUCCESS";
export const LIKE_NFT_ERROR = "LIKE_NFT_ERROR";

//* get user created nfts
export const GET_USER_CREATED_NFTS_LOADING = "GET_USER_CREATED_NFTS_LOADING";
export const GET_USER_CREATED_NFTS_SUCCESS = "GET_USER_CREATED_NFTS_SUCCESS";
export const GET_USER_CREATED_NFTS_ERROR = "GET_USER_CREATED_NFTS_ERROR";

//* get user collected nfts
export const GET_USER_COLLECTED_NFTS_LOADING =
  "GET_USER_COLLECTED_NFTS_LOADING";
export const GET_USER_COLLECTED_NFTS_SUCCESS =
  "GET_USER_COLLECTED_NFTS_SUCCESS";
export const GET_USER_COLLECTED_NFTS_ERROR = "GET_USER_COLLECTED_NFTS_ERROR";

//* get user liked nfts
export const GET_USER_LIKED_NFTS_LOADING = "GET_USER_LIKED_NFTS_LOADING";
export const GET_USER_LIKED_NFTS_SUCCESS = "GET_USER_LIKED_NFTS_SUCCESS";
export const GET_USER_LIKED_NFTS_ERROR = "GET_USER_LIKED_NFTS_ERROR";

//* create nft
export const CREATE_NFT_LOADING = "CREATE_NFT_LOADING";
export const CREATE_NFT_SUCCESS = "CREATE_NFT_SUCCESS";
export const CREATE_NFT_ERROR = "CREATE_NFT_ERROR";

//* update nft
export const UPDATE_NFT_LOADING = "UPDATE_NFT_LOADING";
export const UPDATE_NFT_SUCCESS = "UPDATE_NFT_SUCCESS";
export const UPDATE_NFT_ERROR = "UPDATE_NFT_ERROR";

//* generate ipfs nft
export const GENERATE_IPFS_LOADING = "GENERATE_IPFS_LOADING";
export const GENERATE_IPFS_SUCCESS = "GENERATE_IPFS_SUCCESS";
export const GENERATE_IPFS_ERROR = "GENERATE_IPFS_ERROR";

//* get edit nft details
export const GET_EDIT_NFT_DETAILS_LOADING = "GET_EDIT_NFT_DETAILS_LOADING";
export const GET_EDIT_NFT_DETAILS_SUCCESS = "GET_EDIT_NFT_DETAILS_SUCCESS";
export const GET_EDIT_NFT_DETAILS_ERROR = "GET_EDIT_NFT_DETAILS_ERROR";

//* approve nft
export const APPROVE_NFT_LOADING = "APPROVE_NFT_LOADING";
export const APPROVE_NFT_SUCCESS = "APPROVE_NFT_SUCCESS";
export const APPROVE_NFT_ERROR = "APPROVE_NFT_ERROR";

//* get featured nft
export const GET_FEATURED_NFTS_LOADING = "GET_FEATURED_NFTS_LOADING";
export const GET_FEATURED_NFTS_SUCCESS = "GET_FEATURED_NFTS_SUCCESS";
export const GET_FEATURED_NFTS_ERROR = "GET_FEATURED_NFTS_ERROR";

//* purchase nft
export const PURCHASE_NFT_LOADING = "PURCHASE_NFT_LOADING";
export const PURCHASE_NFT_SUCCESS = "PURCHASE_NFT_SUCCESS";
export const PURCHASE_NFT_ERROR = "PURCHASE_NFT_ERROR";

//* cancel nft
export const CANCEL_NFT_LOADING = "CANCEL_NFT_LOADING";
export const CANCEL_NFT_SUCCESS = "CANCEL_NFT_SUCCESS";
export const CANCEL_NFT_ERROR = "CANCEL_NFT_ERROR";

//* burn nft
export const BURN_NFT_LOADING = "BURN_NFT_LOADING";
export const BURN_NFT_SUCCESS = "BURN_NFT_SUCCESS";
export const BURN_NFT_ERROR = "BURN_NFT_ERROR";

//* change nft price
export const CHANGE_NFT_PRICE_LOADING = "CHANGE_NFT_PRICE_LOADING";
export const CHANGE_NFT_PRICE_SUCCESS = "CHANGE_NFT_PRICE_SUCCESS";
export const CHANGE_NFT_PRICE_ERROR = "CHANGE_NFT_PRICE_ERROR";

//* list nft
export const LIST_NFT_LOADING = "LIST_NFT_LOADING";
export const LIST_NFT_SUCCESS = "LIST_NFT_SUCCESS";
export const LIST_NFT_ERROR = "LIST_NFT_ERROR";

//* get external nfts
export const GET_EXTERNAL_MORALIS_NFTS_LOADING =
  "GET_EXTERNAL_MORALIS_NFTS_LOADING";
export const GET_EXTERNAL_MORALIS_NFTS_SUCCESS =
  "GET_EXTERNAL_MORALIS_NFTS_SUCCESS";
export const GET_EXTERNAL_MORALIS_NFTS_ERROR =
  "GET_EXTERNAL_MORALIS_NFTS_ERROR";

//* get linked nft details
export const GET_LINKED_NFT_DETAILS_LOADING = "GET_LINKED_NFT_DETAILS_LOADING";
export const GET_LINKED_NFT_DETAILS_SUCCESS = "GET_LINKED_NFT_DETAILS_SUCCESS";
export const GET_LINKED_NFT_DETAILS_ERROR = "GET_LINKED_NFT_DETAILS_ERROR";

//* create nft progress status
export const CREATE_NFT_PROGRESS = "CREATE_NFT_PROGRESS";

//* upload nft progress
export const UPLOAD_NFT_PROGRESS = "UPLOAD_NFT_PROGRESS";

//* download nft progress
export const DOWNLOAD_NFT_PROGRESS = "DOWNLOAD_NFT_PROGRESS";

//* reset nft actions
export const RESET_NFT_ACTIONS = "RESET_NFT_ACTIONS";
