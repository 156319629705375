//* login
export const FETCH_GALLERY_ARTIST = "FETCH_GALLERY_ARTIST";
export const FETCH_GALLERY_ARTIST_SUCCESS = "FETCH_GALLERY_ARTIST_SUCCESS";
export const FETCH_GALLERY_ARTIST_ERROR = "FETCH_GALLERY_ARTIST_ERROR";

//* login
export const CREATE_GALLERY_ARTIST = "CREATE_GALLERY_ARTIST";
export const CREATE_GALLERY_ARTIST_SUCCESS = "CREATE_GALLERY_ARTIST_SUCCESS";
export const CREATE_GALLERY_ARTIST_ERROR = "CREATE_GALLERY_ARTIST_ERROR";

//* UPDATE GALLERY
export const UPDATE_GALLERY_ARTIST = "UPDATE_GALLERY_ARTIST";
export const UPDATE_GALLERY_ARTIST_SUCCESS = "UPDATE_GALLERY_ARTIST_SUCCESS";
export const UPDATE_GALLERY_ARTIST_ERROR = "UPDATE_GALLERY_ARTIST_ERROR";

//* DELETE GALLERY
export const DELETE_GALLERY_ARTIST = "DELETE_GALLERY_ARTIST";
export const DELETE_GALLERY_ARTIST_SUCCESS = "DELETE_GALLERY_ARTIST_SUCCESS";
export const DELETE_GALLERY_ARTIST_ERROR = "DELETE_GALLERY_ARTIST_ERROR";

//* DELETE GALLERY
export const FETCH_GALLERY_ARTIST_BY_ID = "FETCH_GALLERY_ARTIST_BY_ID";
export const FETCH_GALLERY_ARTIST_BY_ID_SUCCESS =
  "FETCH_GALLERY_ARTIST_BY_ID_SUCCESS";
export const FETCH_GALLERY_ARTIST_BY_ID_ERROR =
  "FETCH_GALLERY_ARTIST_BY_ID_ERROR";

export const ASSIGN_GALLERY_ARTIST = "ASSIGN_GALLERY_ARTIST";
export const ASSIGN_GALLERY_ARTIST_SUCCESS = "ASSIGN_GALLERY_ARTIST_SUCCESS";
export const ASSIGN_GALLERY_ARTIST_ERROR = "ASSIGN_GALLERY_ARTIST_ERROR";

export const FETCH_PUBLIC_GALLERY_ARTIST = "FETCH_PUBLIC_GALLERY_ARTIST";
export const FETCH_PUBLIC_GALLERY_ARTIST_SUCCESS =
  "FETCH_PUBLIC_GALLERY_ARTIST_SUCCESS";
export const FETCH_PUBLIC_GALLERY_ARTIST_ERROR =
  "FETCH_PUBLIC_GALLERY_ARTIST_ERROR";

export const RESET_FETCH_GALLERY_ARTIST = "RESET_FETCH_GALLERY_ARTIST";
export const CREATE_GALLERY_ARTIST_RESET = "CREATE_GALLERY_ARTIST_RESET";
export const UPDATE_GALLERY_ARTIST_RESET = "UPDATE_GALLERY_ARTIST_RESET";
export const DELETE_GALLERY_ARTIST_RESET = "DELETE_GALLERY_ARTIST_RESET";
export const ASSIGN_GALLERY_ARTIST_RESET = "ASSIGN_GALLERY_ARTIST_RESET";
export const RESET_FETCH_GALLERY_ARTIST_BY_ID =
  "RESET_FETCH_GALLERY_ARTIST_BY_ID";
export const RESET_FETCH_PUBLIC_GALLERY_ARTIST =
  "RESET_FETCH_PUBLIC_GALLERY_ARTIST";

export const FETCH_ARTIST = "FETCH_ARTIST";
export const FETCH_ARTIST_SUCCESS = "FETCH_ARTIST_SUCCESS";
export const FETCH_ARTIST_ERROR = "FETCH_ARTIST_ERROR";

export const FETCH_ARTIST_COLLECTIONS = "FETCH_ARTIST_COLLECTIONS";
export const FETCH_ARTIST_COLLECTIONS_SUCCESS =
  "FETCH_ARTIST_COLLECTIONS_SUCCESS";
export const FETCH_ARTIST_COLLECTIONS_PAGINATED_SUCCESS =
  "FETCH_ARTIST_COLLECTIONS_PAGINATED_SUCCESS";
export const FETCH_ARTIST_COLLECTIONS_FAILURE =
  "FETCH_ARTIST_COLLECTIONS_FAILURE";

export const RESET_ARTIST_COLLECTIONS = "RESET_ARTIST_COLLECTIONS";

//* login
export const FETCH_OWNER_ID = "FETCH_OWNER_ID";
export const FETCH_OWNER_ID_SUCCESS = "FETCH_OWNER_ID_SUCCESS";
export const FETCH_OWNER_ID_ERROR = "FETCH_OWNER_ID_ERROR";
