
import { TypedDataUtils } from 'ethers-eip712'

export const createLazyMintData = async (
  signer,
  tokenAddress,
  managerAddress,
  minPrice,
  creators,
  royalties,
  investors,
  revenues,
  uri,
  quantity,
  currency,
  domainName = "NiftySouqMarketplaceManager",
  version = "1.0") => {
  const types = {
    EIP712Domain: [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
      { name: 'verifyingContract', type: 'address' }
    ],
    LazyMintData: [
      { name: 'uri', type: 'string' },
      { name: 'creators', type: 'address[]' },
      { name: 'royalties', type: 'uint256[]' },
      { name: 'investors', type: 'address[]' },
      { name: 'revenues', type: 'uint256[]' },
      { name: 'currency', type: 'string'},
      { name: 'minPrice', type: 'uint256' },
      { name: 'quantity', type: 'uint256' },
      
    ]
  };

  const domain = {
    name: domainName,
    version: version,
    chainId: await signer.getChainId(),
    verifyingContract: managerAddress
  };

  const lazyMintData = {
    uri,
    creators,
    royalties,
    investors,
    revenues,
    currency,
    minPrice,
    quantity,

  };

  const digest = TypedDataUtils.encodeDigest({
    domain,
    types,
    primaryType: "LazyMintData",
    message: lazyMintData
  });

  const signature = await signer.signMessage(digest);
  console.log('lazy mint data ===============', domain, lazyMintData, signature, await signer.getAddress(), tokenAddress);

  return {
    ...lazyMintData,
    signature,
    seller: await signer.getAddress(),
    tokenAddress
  }
}