import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import notFound from "../images/not-found.png";
import styled from "styled-components";
import artwork from "../../src/icons/artwork.svg";
import photography from "../../src/icons/photography.svg";
import music from "../../src/icons/music.svg";
import content from "../../src/icons/content.svg";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 115px;
  .image-container {
    max-width: 650px;
    margin-top: 125px;
    margin-bottom: 100px;
  }
  img {
    display: inline-block;
    width: 100%;
  }
  h2 {
    max-width: 675px;
  }
  form {
    width: 300px;
    margin-bottom: 100px;
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
    color: #23262f;
  }
  a {
    width: 250px;
    padding: 8px;
    border: 1px solid #e6e8ec;
    border-radius: 40px;
    text-decoration: none;
    margin-bottom: 24px;
    @media (max-width: 1200px) {
      width: 200px;
    }
  }
  a:hover {
    border: 1px solid #7e7e7e;
    .subject,
    .count {
      color: #6d3190;
    }
  }
  .icon-container {
    border: 3px #6d3190 solid;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    margin-right: 12px;
    padding: 4px;
  }
  .subject {
    color: #141416;
    font-size: 18px;
    font-weight: 400;
  }
  .count {
    color: #777e90;
    font-size: 14px;
  }
`;

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        {/* image */}
        <div className="image-container">
          <img src={notFound} alt="not found" />
        </div>
        {/* message */}
        <h2 className="home-title mb-5">{t("NoResults")}</h2>
        {/* form */}
        <Form className="d-flex">
          <FormControl
            type="text"
            placeholder="Enter Your Search..."
            className="newsletter-box me-2"
            aria-label="Enter Your Search..."
          />
          <Button className="newsletter-btn" variant="outline-none"></Button>
        </Form>
        {/* explore more */}
        <div className="mb-5 page-header">Explore More</div>
        <Row>
          <Col sm={6} lg={3}>
            <a href="#" className="d-flex align-items-center">
              <div className="icon-container">
                <img src={artwork} alt="artwork" />
              </div>
              <div>
                <div className="subject">Artwork</div>
                <div className="count">138 items</div>
              </div>
            </a>
          </Col>
          <Col sm={6} lg={3}>
            <a href="#" className="d-flex align-items-center">
              <div className="icon-container">
                <img src={photography} alt="photography" />
              </div>
              <div>
                <div className="subject">Photography</div>
                <div className="count">138 items</div>
              </div>
            </a>
          </Col>
          <Col sm={6} lg={3}>
            <a href="#" className="d-flex align-items-center">
              <div className="icon-container">
                <img src={music} alt="music" />
              </div>
              <div>
                <div className="subject">Music</div>
                <div className="count">138 items</div>
              </div>
            </a>
          </Col>
          <Col sm={6} lg={3}>
            <a href="#" className="d-flex align-items-center">
              <div className="icon-container">
                <img src={content} alt="content" />
              </div>
              <div>
                <div className="subject">Content</div>
                <div className="count">138 items</div>
              </div>
            </a>
          </Col>
        </Row>
      </Wrapper>
    </Container>
  );
};
