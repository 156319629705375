import * as galleryTypes from "../types/gallery";
import { API_PATH } from "../../../common/constants/api";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getAllGalleriesCreator = (params, load) => {
  return (dispatch) => {
    dispatch({
      type: galleryTypes.FETCH_GALLERIES,
      loading: true,
    });

    API.get(`api/gallery`, params)
      .then((res) => {
        dispatch({
          type: galleryTypes.FETCH_GALLERIES_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          has_more: res.data.data?.length === 20,
          load,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.FETCH_GALLERIES_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getPublicGalleriesCreator = (params, load) => {
  return (dispatch) => {
    dispatch({
      type: galleryTypes.FETCH_PUBLIC_GALLERIES,
      loading: true,
    });

    API.get(`api/gallery/public`, params)
      .then((res) => {
        dispatch({
          type: galleryTypes.FETCH_PUBLIC_GALLERIES_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          load,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.FETCH_PUBLIC_GALLERIES_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const createGalleryCreator = (payload) => {
  const requestData = new FormData();
  requestData.append("title", payload.title);
  requestData.append("description", payload.description);
  requestData.append("profileImage", payload.profileImage);
  requestData.append("coverImage", payload.coverImage);
  requestData.append("instagram_url", payload.instagram_url || "");
  requestData.append("twitter_url", payload.twitter_url || "");
  requestData.append("website_url", payload.website_url || "");
  requestData.append("facebook_url", payload.facebook_url || "");
  requestData.append("youtube_url", payload.youtube_url || "");

  return (dispatch) => {
    dispatch({
      type: galleryTypes.CREATE_GALLERY,
      loading: true,
    });

    API.put(API_PATH.CREATE_GALLERY, requestData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        dispatch({
          type: galleryTypes.CREATE_GALLERY_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.CREATE_GALLERY_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const updateGalleryCreator = (galleryId, payload) => {
  const requestData = new FormData();
  requestData.append("title", payload.title);
  requestData.append("description", payload.description);
  requestData.append("instagram_url", payload.instagram_url);
  requestData.append("twitter_url", payload.twitter_url);
  requestData.append("website_url", payload.website_url);
  requestData.append("facebook_url", payload.facebook_url);
  requestData.append("youtube_url", payload.youtube_url);
  if (typeof payload.profileImage !== "string") {
    requestData.append("profileImage", payload.profileImage);
  }
  if (typeof payload.coverImage !== "string") {
    requestData.append("coverImage", payload.coverImage);
  }
  return (dispatch) => {
    dispatch({
      type: galleryTypes.UPDATE_GALLERY,
      loading: true,
    });
    API.post(API_PATH.UPDATE_GALLERY(galleryId), requestData)
      .then((res) => {
        dispatch({
          type: galleryTypes.UPDATE_GALLERY_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.UPDATE_GALLERY_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const deleteGalleryCreator = (galleryId) => {
  return (dispatch) => {
    dispatch({
      type: galleryTypes.DELETE_GALLERY,
      loading: true,
    });

    API.remove(API_PATH.DELETE_GALLERY(galleryId))
      .then((res) => {
        dispatch({
          type: galleryTypes.DELETE_GALLERY_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.DELETE_GALLERY_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getGalleryByIdCreator = (galleryId) => {
  return (dispatch) => {
    dispatch({
      type: galleryTypes.FETCH_GALLERY_BY_ID,
      loading: true,
    });

    API.get(API_PATH.GALLERY_BY_ID(), {
      galleryId,
    })
      .then((res) => {
        dispatch({
          type: galleryTypes.FETCH_GALLERY_BY_ID_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.FETCH_GALLERY_BY_ID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getFeaturedGalleriesCreator = (limit, offset, status) => {
  return (dispatch) => {
    dispatch({
      type: galleryTypes.FETCH_FEATURED_GALLERIES,
      loading: true,
    });

    API.get(API_PATH.FEATURED_GALLERY(limit, offset))
      .then((res) => {
        dispatch({
          type: galleryTypes.FETCH_FEATURED_GALLERIES_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: galleryTypes.FETCH_FEATURED_GALLERIES_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const resetCreateGalleryCreator = () => {
  return (dispatch) => dispatch({ type: galleryTypes.CREATE_GALLERY_RESET });
};

export const resetUpdateGalleryCreator = () => {
  return (dispatch) => dispatch({ type: galleryTypes.UPDATE_GALLERY_RESET });
};

export const resetDeleteGalleryCreator = () => {
  return (dispatch) => dispatch({ type: galleryTypes.DELETE_GALLERY_RESET });
};

export const resetGalleryByIdCreator = () => {
  return (dispatch) => dispatch({ type: galleryTypes.GALLERY_BY_ID_RESET });
};

export const resetGalleriesCreator = () => {
  return (dispatch) => dispatch({ type: galleryTypes.RESET_FETCH_GALLERIES });
};

export const resetPublicGalleriesCreator = () => {
  return (dispatch) =>
    dispatch({ type: galleryTypes.RESET_FETCH_FEATURED_GALLERIES });
};
