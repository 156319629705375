import * as Yup from "yup";
export const FILE_SIZE = 100 * 1048576; // 100 MB

const urlMatches =
  /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9_.#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const IMAGE_FILE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const getCreateAndEditArtistInitialValues = (t) => {
  return {
    name: "",
    description: "",
    country: "",
    profileImage: undefined,
    coverImage: undefined,
    facebook_url: "",
    instagram_url: "",
    twitter_url: "",
    youtube_url: "",
    website_url: "",
  };
};

export const getCreateAndEditArtistValidationSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, t("name_max_length"))
      .required(t("name_required"))
      .trim(),
    description: Yup.string()
      .max(250, t("description_max_length"))
      .required(t("description_required"))
      .trim(),
    country: Yup.string().notRequired(),
    profileImage: Yup.mixed()
      .required(t("profile_image_required"))
      .test("fileSize", t("invalid_file_size"), (value) => {
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.size) {
          return value.size <= FILE_SIZE;
        }
      })
      .test("fileFormat", t("unsupported_file_types"), (value) => {
        console.log("value", value);
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.type) {
          return IMAGE_FILE_SUPPORTED_FORMATS.includes(value.type);
        }
      }),
    coverImage: Yup.mixed()
      .required(t("banner_image_required"))
      .test("fileSize", t("invalid_file_size"), (value) => {
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.size) {
          return value.size <= FILE_SIZE;
        }
      })
      .test("fileFormat", t("unsupported_file_types"), (value) => {
        console.log("value", value);
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.type) {
          return IMAGE_FILE_SUPPORTED_FORMATS.includes(value.type);
        }
      }),
    facebook_url: Yup.string()
      .matches(urlMatches, t("fb_validation"))
      .nullable()
      .notRequired()
      .matches("facebook.com", t("fb_validation")),
    instagram_url: Yup.string()
      .matches(urlMatches, t("insta_validation"))
      .nullable()
      .notRequired()
      .matches("instagram.com", t("insta_validation")),
    twitter_url: Yup.string()
      .matches(urlMatches, t("twitter_validation"))
      .nullable()
      .notRequired()
      .matches("twitter.com", t("twitter_validation")),
    youtube_url: Yup.string()
      .matches(urlMatches, t("youtube_validation"))
      .nullable()
      .notRequired()
      .matches("youtube.com", t("youtube_validation")),
    website_url: Yup.string()
      .matches(urlMatches, t("website_validation"))
      .nullable()
      .notRequired(),
  });
};

export const getCreateAndEditGalleryInitialValues = (t) => {
  return {
    title: "",
    description: "",
    profileImage: "",
    coverImage: "",
    facebook_url: "",
    instagram_url: "",
    twitter_url: "",
    youtube_url: "",
    website_url: "",
  };
};

export const getGalleryRejectionInitialValues = (t) => {
  return {
    title: "",
    description: "",
    profileImage: undefined,
    coverImage: undefined,
    rejectionReason: "",
  };
};

export const getCreateAndEditGalleryValidationSchema = (t) => {
  return Yup.object().shape({
    title: Yup.string()
      .max(50, t("title_max_length"))
      .required(t("title_required"))
      .trim(),
    description: Yup.string()
      .max(250, t("description_max_length"))
      .required(t("description_required"))
      .trim(),
    profileImage: Yup.mixed()
      .required(t("profile_image_required"))
      .test("fileSize", t("invalid_file_size"), (value) => {
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.size) {
          return value.size <= FILE_SIZE;
        }
      })
      .test("fileFormat", t("unsupported_file_types"), (value) => {
        console.log("value", value);
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.type) {
          return IMAGE_FILE_SUPPORTED_FORMATS.includes(value.type);
        }
      }),
    coverImage: Yup.mixed()
      .required(t("banner_image_required"))
      .test("fileSize", t("invalid_file_size"), (value) => {
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.size) {
          return value.size <= FILE_SIZE;
        }
      })
      .test("fileFormat", t("unsupported_file_types"), (value) => {
        console.log("value", value);
        if (value && typeof value === "string") {
          return true;
        }
        if (value && value.type) {
          return IMAGE_FILE_SUPPORTED_FORMATS.includes(value.type);
        }
      }),
    facebook_url: Yup.string()
      .matches(urlMatches, t("fb_validation"))
      .nullable()
      .notRequired()
      .matches("facebook.com", t("fb_validation")),
    instagram_url: Yup.string()
      .matches(urlMatches, t("insta_validation"))
      .nullable()
      .notRequired()
      .matches("instagram.com", t("insta_validation")),
    twitter_url: Yup.string()
      .matches(urlMatches, t("twitter_validation"))
      .nullable()
      .notRequired()
      .matches("twitter.com", t("twitter_validation")),
    youtube_url: Yup.string()
      .matches(urlMatches, t("youtube_validation"))
      .nullable()
      .notRequired()
      .matches("youtube.com", t("youtube_validation")),
    website_url: Yup.string()
      .matches(urlMatches, t("website_validation"))
      .nullable()
      .notRequired(),
  });
};
