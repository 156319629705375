import { Row, Col } from "react-bootstrap";
import CollectionNFT from "./CollectionNFT";

const CollectionNFTs = (props) => {
  if (props.noActions) {
    return (
      <Row>
        {props.collections?.map((item, index) => {
          return (
            <Col key={index} xs={12} sm={6} md={4} lg={4}>
              <CollectionNFT {...item} />
            </Col>
          );
        })}
      </Row>
    );
  } else {
    return (
      <Row style={{ padding: "0 50px" }}>
        {props?.collections?.map((item, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={4}>
            <CollectionNFT collection={item} />
          </Col>
        ))}
      </Row>
    );
  }
};

export default CollectionNFTs;
