import React from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { projectActionCreators } from "../../../store";
import { useTranslation } from "react-i18next";

export const Reset = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { clearFilter } = bindActionCreators(projectActionCreators, dispatch);
  return (
    <div
      style={{
        fontWeight: 500,
        borderBottom: "1px solid #E4E4E4",
        padding: "12px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <span
        style={{ color: "#979797", fontSize: 13, textTransform: "uppercase" }}
      >
        {t("Filter by")}
      </span>
      <span
        onClick={() => {
          console.log("first");
          clearFilter();
        }}
        style={{
          fontSize: 14,
          color: "#536DFE",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {t("reset")}
      </span>
    </div>
  );
};
