// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";

// ------------------------------------
// Actions
// ------------------------------------
export function showModal(modal_id, data, cb) {
	console.log({modal_id, data, cb})
	return dispatch =>  dispatch({
		type: MODAL_SHOW,
		payload: {
			id: modal_id,
			data,
			cb,
		},
	})
}
export function hideModal() {
	return dispatch => dispatch({
		type: MODAL_HIDE,
	});
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
	id: null, data: null, cb: null
};
export function modalReducer(state = initialState, action) {
	switch (action.type) {
		case MODAL_SHOW:
			return {
				...state,
				id: action.payload.id,
				data: action.payload.data,
				cb: action.payload.cb,
			};
		case MODAL_HIDE:
			return {...state, id: null, data: null, cb: null};
		default:
			return state;
	}
}