import * as projectTypes from "../types/project";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getProjectsCreator = () => {
  return (dispatch) => {
    dispatch({ loading: true, type: projectTypes.GET_PROJECTS_LOADING });

    API.get(`api/project`, {})
      .then((res) => {
        console.log({ tt: res.data.data });
        dispatch({
          loading: false,
          payload: res.data.data,
          type: projectTypes.GET_PROJECTS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: projectTypes.GET_PROJECTS_ERROR,
        });
      });
  };
};

export const getProjectDetailsCreator = (contract, params) => {
  return (dispatch) => {
    dispatch({ loading: true, type: projectTypes.GET_PROJECT_DETAILS_LOADING });

    API.get(`api/project/details/${contract}`, params)
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data.data,
          has_more: res.data.data?.length === params?.limit || 9,
          type: projectTypes.GET_PROJECT_DETAILS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: projectTypes.GET_PROJECT_DETAILS_ERROR,
        });
      });
  };
};

export const getProjectNftDetailsCreator = (contract, token_id) => {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: projectTypes.GET_PROJECT_NFT_DETAILS_LOADING,
    });

    API.get(`api/project/nft/details/${contract}/${token_id}`)
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data.data,
          type: projectTypes.GET_PROJECT_NFT_DETAILS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: projectTypes.GET_PROJECT_NFT_DETAILS_ERROR,
        });
      });
  };
};

export const getProjectNftsCreator = (body, params, is_new) => {
  return (dispatch) => {
    dispatch({ loading: true, type: projectTypes.GET_PROJECT_NFTS_LOADING });

    const { skip, limit } = params;
    API.post(`api/project/nft/?skip=${skip}&limit=${limit}`, body)
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data.data,
          has_more: res.data.data?.length === params?.limit,
          is_new,
          type: projectTypes.GET_PROJECT_NFTS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: projectTypes.GET_PROJECT_NFTS_ERROR,
        });
      });
  };
};

export const saveNameFilter = (name) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.SAVE_PROJECT_FILTER_NAME,
      payload: name,
    });
};

export const saveContractFilter = (contract) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.SAVE_PROJECT_FILTER_CONTRACT,
      payload: contract,
    });
};

export const savePriceFilter = (price) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.SAVE_PROJECT_FILTER_PRICE,
      payload: price,
    });
};

export const addToSaleTypesFilter = (sale_type) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.SAVE_PROJECT_FILTER_SALE_TYPES,
      payload: sale_type,
    });
};

export const addToPropertiesFilter = (properties) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.SAVE_PROJECT_FILTER_PROPERTIES,
      payload: properties,
    });
};

export const removeFromPropertiesFilter = (properties) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.REMOVE_PROJECT_FILTER_PROPERTIES,
      payload: properties,
    });
};

export const removeFromSaleTypesFilter = (sale_type) => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.REMOVE_PROJECT_FILTER_SALE_TYPES,
      payload: sale_type,
    });
};

export const clearFilter = () => {
  return (dispatch) =>
    dispatch({
      type: projectTypes.CLEAR_PROJECT_FILTER,
    });
};
