import { t } from "i18next";
import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export const ConfirmDeleteModal = ({
  isOpen,
  setIsOpen,
  name,
  id,
  callback,
}) => {
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("confirm_delete")}</ModalHeader>
      <ModalBody style={{fontSize: 13}}>
        {t("sure_to_delete")}
        <span style={{color: "#6d3190"}}>
        {name}
        </span>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={async () => await callback(id)}>
          {t("Delete")}
        </Button>{" "}
        <Button color="light" onClick={toggle}>
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
