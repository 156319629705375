import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 290px;
  height: 190px;
  background-color: #170055;
  border-radius: 12px;
  color: white;
  font-weight: 500;
  .default {
    border-radius: 50px;
    padding: 6px 10px;
    background-color: #6d3190;
    display: inline-block;
    font-size: 14px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .card-type {
    size: 12px;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .card-number {
    font-size: 20px;
    position: absolute;
    bottom: 64px;
    left: 16px;
  }
  .card-owner {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
  .valid-thru {
    font-size: 8px;
    position: absolute;
    bottom: 72px;
    right: 56px;
  }
  .card-date {
    font-size: 12px;
    position: absolute;
    bottom: 70px;
    right: 16px;
  }
  .orange-circle {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #e33a24;
    position: absolute;
    bottom: 16px;
    right: 32px;
  }
  .yellow-circle {
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgba(248, 203, 46, 0.8);
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
`;

const Card = (props) => {
  return (
    <Wrapper>
      {props.default && <span className="default">Default</span>}
      <span className="card-type">Debit</span>
      <span className="card-number">**** **** **** 0329</span>
      <span className="card-owner">Cameron Williamson</span>
      <span className="valid-thru">VALID</span>
      <span className="card-date">03/24</span>
      <span className="orange-circle"></span>
      <span className="yellow-circle"></span>
    </Wrapper>
  );
};

export default Card;
