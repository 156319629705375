//* get popular sellers
export const GET_POPULAR_SELLERS_LOADING = "GET_POPULAR_SELLERS_LOADING";
export const GET_POPULAR_SELLERS_SUCCESS = "GET_POPULAR_SELLERS_SUCCESS";
export const GET_POPULAR_SELLERS_ERROR = "GET_POPULAR_SELLERS_ERROR";

//* get latest seller upload nfts
export const GET_LATEST_SELLERS_UPLOAD_LOADING =
  "GET_LATEST_SELLERS_UPLOAD_LOADING";
export const GET_LATEST_SELLERS_UPLOAD_SUCCESS =
  "GET_LATEST_SELLERS_UPLOAD_SUCCESS";
export const GET_LATEST_SELLERS_UPLOAD_ERROR =
  "GET_LATEST_SELLERS_UPLOAD_ERROR";

//* get all sellers
export const GET_ALL_SELLERS_LOADING = "GET_ALL_SELLERS_LOADING";
export const GET_ALL_SELLERS_SUCCESS = "GET_ALL_SELLERS_SUCCESS";
export const GET_ALL_SELLERS_ERROR = "GET_ALL_SELLERS_ERROR";
