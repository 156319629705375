import axios from "axios";
import Cookies from "universal-cookie";
import keys from "../../lib/config";

axios.interceptors.request.use(
  (conf) => {
    const cookies = new Cookies("token");
    const token = cookies.get("token");
    // console.log({token})
    conf.headers = { Authorization: "Bearer " + token };
    return conf;
  },
  (error) => {
    Promise.reject(error);
  }
);

const post = (endpoint, body, cbUploadProgress, cbDownloadProgress) => {
  return axios.post(`${window.API_URL}/${endpoint}`, body, {
    headers: {
      "Content-Type": "application/json",
    },
    onUploadProgress: (data) => {
      if (cbUploadProgress) {
        return cbUploadProgress(Math.round((data.loaded / data.total) * 100));
      }
    },
    onDownloadProgress: (data) => {
      if (cbDownloadProgress) {
        return cbDownloadProgress(Math.round((data.loaded / data.total) * 100));
      }
    },
  });
};

const postWithParams = (endpoint, body, params) => {
  return axios.post(`${window.API_URL}/${endpoint}`, body, {
    params: params,
  });
};

const postMultiPart = (endpoint, requestObj) => {
  return axios.post(`${window.API_URL}/${endpoint}`, requestObj, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const get = (endpoint, params) => {
  return axios.get(`${window.API_URL}/${endpoint}`, { params });
};

const remove = (endpoint) => {
  return axios.delete(`${window.API_URL}/${endpoint}`);
};

const put = (endpoint, body, header) => {
  return axios.put(`${window.API_URL}/${endpoint}`, body, {
    headers: {
      "Content-Type": "application/json",
      ...header,
    },
  });
};

const patch = (endpoint, body, header) => {
  return axios.patch(`${window.API_URL}/${endpoint}`, body, {
    headers: {
      "Content-Type": "application/json",
      ...header,
    },
  });
};

export { post, get, put, remove, patch, postMultiPart, postWithParams };
