import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CollectionCard } from "../pages/Partners/Popular/Partials";
import { userActionsCreators } from "../store";
import ScrollBar from "./ScrollBar";
import styled from "styled-components";

const Wrapper = styled.div`
  .main {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 65px;
    }
  }
  .card {
    positions: relative;
    min-width: 291px;
    max-width: 291px;
    margin-right: 15px;
    margin-left: 10px;
    border: 1px solid #efefef;
    // box-shadow: 0px 5.0181px 0px 1.8258px rgba(214, 214, 214, 0.25);
    border-radius: 13.8258px;
  }
  .wrapper {
    width: 264px;
    height: 255px;
  }
  .image {
    object-fit: cover;
  }
  .radius {
    border-radius: 9.2px;
  }
  .creator {
    color: #000;
    font-size: 12px;
    font-weight: 450;
  }
  .tag {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 400;
  }
  .name {
    font-size: 16px;
    color: #1c0039;
  }
  .divider {
    width: 100%;
    height: 0.66px;
    background-xolor: #e5e4de;
  }
  .label {
    color: #afa9bd;
    font-size: 12px;
  }
  .note {
    color: #afa9bd;
    height: 100%;
    font-size: 14px;
  }
  .value {
    color: #1c0039;
    font-size: 14px;
    font-weight: 400;
  }
  .card:hover {
    .overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  .overlay {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.83) 0%,
      rgba(255, 255, 255, 0.7802) 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 291px;
    border-radius: 13.8258px;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
  }
`;

const LatestNFTs = () => {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language != "ar";
  const { loading, error, projects } = useSelector((state) => state.projects);

  return (
    <>
      {projects && (
        <section>
          <Wrapper>
            <Container fluid="lg" className="main">
              {/* if loading */}
              {loading && <p>{t("Loading...")}</p>}
              {/* if error */}
              {error && <p>{error}</p>}
              {/* if the featured item exists */}
              {projects && projects?.length > 0 && (
                <div>
                  <ScrollBar
                    data={projects}
                    link="partners"
                    title={isLTR ? t("global") : t("Collections")}
                    subtitle={isLTR ? t("Collections") : t("global")}
                    // scrollStyle="pe-4"
                    renderCard={(item) => {
                      return (
                        <CollectionCard item={item} small key={Math.random()} />
                      );
                    }}
                  />
                </div>
              )}
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

export default LatestNFTs;
