import { combineReducers } from "redux";
import * as nftReducers from "./nft";
import * as profileReducers from "./profile";
import * as authReducers from "./auth";
import * as categoryReducers from "./category";
import * as userReducers from "./user";
import * as bidReducers from "./bid";
import * as walletReducers from "./wallet";
import * as transactionsReducers from "./transactions";
import * as historyReducers from "./history";
import * as ethReducers from "./eth";
import * as subcategoryReducers from "./subcategory";
import * as creatorCollectionReducers from "./creatorCollection";
import * as appReducers from "./app";
import * as artistReducers from "./artist";
import * as partnerReducers from "./partner";
import * as galleryReducers from "./gallery";
import * as moralisReducers from "./moralis";
import * as projectReducers from "./project";
import * as offerReducers from "./offer";
import * as stripeReducers from "./stripe";
import * as errorReducers from "./error";

import { modalReducer } from "./modal";

const reducers = combineReducers({
  app: appReducers.appReducer,
  allError: errorReducers.errorReducer,
  discover_nfts: nftReducers.allNftsReducer,
  latest_nfts: nftReducers.latestNFTsReducer,
  nft_details: nftReducers.nftDetailsReducer,
  nft_bids: bidReducers.nftBidsReducer,
  hot_bids: bidReducers.hotBidsReducer,
  nft_history: historyReducers.nftHistoryReducer,
  nft_actions: nftReducers.nftActionsReducer,
  external_nfts: nftReducers.externalNFTsReducer,
  linked_nft_details: nftReducers.linkedNftDetailsReducer,
  featured_nfts: nftReducers.featuredNFTsReducer,
  profile_nfts: nftReducers.profileNFTsReducer,
  profile: profileReducers.profileReducer,
  profile_update: profileReducers.updatingProfileReducer,
  auth: authReducers.authReducer,
  auth_actions: authReducers.authActionsReducer,
  category: categoryReducers.categoryReducer,
  popular_sellers: userReducers.popularSellersReducer,
  latest_sellers: userReducers.latestSellersUploadNftsReducer,
  all_sellers: userReducers.allSellersReducer,
  bid_actions: bidReducers.bidActionsReducer,
  owner_editions: bidReducers.ownerEditionsReducer,
  wallets: walletReducers.walletReducer,
  transactions: transactionsReducers.transactionReducer,
  subcategories: subcategoryReducers.subcategoryReducer,
  creatorCollections: creatorCollectionReducers.creatorCollectionsReducer,
  modal: modalReducer,
  eth: ethReducers.ethReducer,
  wert: ethReducers.wertReducer,
  artist: artistReducers.artistReducer,
  gallery: galleryReducers.galleryReducer,
  linkedNfts: moralisReducers.linkedNftsReducer,
  partner: partnerReducers.partnerNFTsReducer,
  partners: partnerReducers.partnersReducer,
  partner_nft_details: partnerReducers.partnerNftDetailsReducer,
  partner_nft_actions: partnerReducers.partnerNftActionsReducer,
  project: projectReducers.projectReducer,
  project_search: projectReducers.projectSearchReducer,
  projectNftDetails: projectReducers.projectNftDetailsReducer,
  projects: projectReducers.projectsReducer,
  offers: offerReducers.offersReducer,
  offers_action: offerReducers.offerActionsReducer,
  stripe: stripeReducers.stripeReducer,
});

export default reducers;
