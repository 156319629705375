import styled from "styled-components";

const StylesWrapper = styled.div`
  .card {
    max-width: ${(props) => (props.small ? "300px" : "350px")};
    min-width: ${(props) => (props.small ? "300px" : "350px")};
    height: 300px;
    background: #ffffff;
    border-radius: 15px 15px 0px 0px;
    border: 0.987557px solid #efefef;
    box-shadow: 0px 25.0181px 39.5023px rgba(214, 214, 214, 0.25);
    border-radius: 12px;
    margin-bottom: 40px;
    color: #000;
  }
  .card-cover-img {
    width: 100%;
    height: 60%;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .user-img-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    display: flex;
    justify-content: center;
  }
  .user-img {
    width: 79px;
    height: 79px;
    border-radius: 200px;
    border: 2.77083px solid #ededed;
    object-fit: cover;
  }
  .card-text {
    height: 40%;
    padding-top: 25px;
  }
  .card-name {
    font-weight: 350;
    max-width: ${(props) => (props.small ? "200px" : "225px")};
    font-size: ${(props) => (props.small ? "18px" : "20px")};
  }
  .card-label {
    font-weight: 350;
    font-size: 14px;
  }
`;
export default StylesWrapper;
