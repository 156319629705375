import * as partnerTypes from "../actions/types/partner";

const partners = {
  data: [],
  error: null,
  loading: false,
  has_more: true,
};
const partnerNFTs = {
  nfts: [],
  error: null,
  loading: false,
  has_more: true,
};
const partnerNFTDetails = {
  loading: false,
  nft: null,
  error: null,
};
const partnerNftActions = {
  loading: false,
  success: false,
  status: 0,
  data: null,
  error: false,
  action: null,
  progress: 0,
};

export const partnersReducer = (state = partners, action) => {
  switch (action.type) {
    //* loading
    case partnerTypes.GET_PARTNERS_LOADING:
      return { ...state, loading: true };

    //* success
    case partnerTypes.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.paginated
          ? [...state.nfts, ...action.payload]
          : action.payload,
        has_more: action.has_more,
        error: null,
      };

    //* error
    case partnerTypes.GET_PARTNERS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
export const partnerNFTsReducer = (state = partnerNFTs, action) => {
  switch (action.type) {
    //* loading
    case partnerTypes.GET_PARTNER_NFTS_LOADING:
      return { ...state, loading: true };

    //* success
    case partnerTypes.GET_PARTNER_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nfts: action.paginated
          ? [...state.nfts, ...action.payload]
          : action.payload,
        has_more: action.has_more,
        error: null,
      };

    //* error
    case partnerTypes.GET_PARTNER_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const partnerNftDetailsReducer = (state = partnerNFTDetails, action) => {
  switch (action.type) {
    case partnerTypes.GET_PARTNER_NFT_DETAILS_LOADING:
      return { ...state, loading: true };
    case partnerTypes.GET_PARTNER_NFT_DETAILS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case partnerTypes.GET_PARTNER_NFT_DETAILS_ERROR:
      return { ...state, loading: false, error: action.error };

    default:
      return state;
  }
};
export const partnerNftActionsReducer = (state = partnerNftActions, action) => {
  switch (action.type) {
    //* purchase partner nft
    case partnerTypes.PURCHASE_PARTNER_NFT_LOADING:
      return { ...state, loading: true };
    case partnerTypes.PURCHASE_PARTNER_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case partnerTypes.PURCHASE_PARTNER_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* cancel partner nft
    case partnerTypes.CANCEL_PARTNER_NFT_LOADING:
      return { ...state, loading: true };
    case partnerTypes.CANCEL_PARTNER_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case partnerTypes.CANCEL_PARTNER_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* burn partner nft
    case partnerTypes.BURN_PARTNER_NFT_LOADING:
      return { ...state, loading: true };
    case partnerTypes.BURN_PARTNER_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case partnerTypes.BURN_PARTNER_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* list partner nft
    case partnerTypes.LIST_PARTNER_NFT_LOADING:
      return { ...state, loading: true };
    case partnerTypes.LIST_PARTNER_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case partnerTypes.LIST_PARTNER_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* change partner nft price
    case partnerTypes.CHANGE_PARTNER_NFT_PRICE_LOADING:
      return { ...state, loading: true };
    case partnerTypes.CHANGE_PARTNER_NFT_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case partnerTypes.CHANGE_PARTNER_NFT_PRICE_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* default
    default:
      return state;
  }
};
