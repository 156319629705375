import { useTranslation } from "react-i18next";
import {
  offers_cut,
  walletCut,
  walletCutPhoneView,
  walletCutPhoneViewExternalWallet,
  walletSmallCut,
} from "../core/string";
import wallet from "../../src/assets/icons/wallet.svg";
import styled from "styled-components";

const CopyWallet = (props) => {
  const { t, i18n } = useTranslation();

  const Address = styled.span`
    font-size: 16px;
    color: ${(props) => props?.colors?.text};
  `;
  const handelClick = () => {
    var copyTxt = document.querySelector(`#copiedTxt${props.id}`);
    var copyImg = document.querySelector(`#copyImg${props.id}`);
    navigator.clipboard.writeText(props.address);
    copyTxt.classList.remove("d-none");
    copyImg.classList.add("d-none");
    setTimeout(() => {
      copyTxt.classList.add("d-none");
      copyImg.classList.remove("d-none");
    }, 3000);
  };
  return (
    <>
      {props.offersCut ? (
        <Address className="cursor" onClick={handelClick}>
          {offers_cut(props.address)}{" "}
          <img id={`copyImg${props.id}`} src={wallet} alt="" />{" "}
          <span id={`copiedTxt${props.id}`} className="text-purple d-none">
            {t("copied")}
          </span>
        </Address>
      ) : props.phoneViewExternalWallet ? (
        <Address className="cursor" onClick={handelClick}>
          {walletCutPhoneViewExternalWallet(props.address)}{" "}
          <img id={`copyImg${props.id}`} src={wallet} alt="" />{" "}
          <span id={`copiedTxt${props.id}`} className="text-purple d-none">
            {t("copied")}
          </span>
        </Address>
      ) : props.fullWallet ? (
        <Address onClick={handelClick} colors={props.colors}>
          <span className="cursor">
            {props.address?.length > 42
              ? walletSmallCut(props.address)
              : props.address}{" "}
            <img id={`copyImg${props.id}`} src={wallet} alt="" />{" "}
          </span>
          <span id={`copiedTxt${props.id}`} className="text-purple d-none">
            {t("copied")}
          </span>
        </Address>
      ) : (
        <>
          {props.phoneView ? (
            <Address className="cursor" onClick={handelClick}>
              {walletCutPhoneView(props.address)}{" "}
              <img id={`copyImg${props.id}`} src={wallet} alt="" />{" "}
              <span id={`copiedTxt${props.id}`} className="text-purple d-none">
                {t("copied")}
              </span>
            </Address>
          ) : (
            <Address className="cursor" onClick={handelClick}>
              {walletCut(props.address)}{" "}
              <img id={`copyImg${props.id}`} src={wallet} alt="" />{" "}
              <span id={`copiedTxt${props.id}`} className="text-purple d-none">
                {t("copied")}
              </span>
            </Address>
          )}
        </>
      )}
    </>
  );
};

export default CopyWallet;
