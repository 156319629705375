import * as Yup from "yup";
import keys from "../../lib/config";

export const initialValues = {
  nft: "",
  name: "",
  description: "",
  // chain: "ETH",
  creator: "",
  totalRoyalty: 0,
  isList: false,
  listType: "fixedPrice",
  price: 0,
  amount: 1,
  startBidPrice: 0,
  reservePrice: 0,
  day: 1,
  isPartners: false,
  partners: [],
  isCollection: false,
  collection: null,
  category: null,
  isLazymint: false,
  paymentType: "wallet",
};

export const createNFTValidationSchema = (t) => {
  return Yup.object().shape({
    nft: Yup.mixed()
      .required(t("create-nft.nft_required"))
      .test("fileSize", t("invalid_file_size"), (value) => {
        if (value && typeof value === "string") return true;
        if (value && value?.file?.size)
          return value.file.size <= keys.FILE_SIZE;
      })
      .test("fileType", t("unsupported_file_types"), (value) => {
        console.log("value.file.type", value?.file?.type);
        if (value && typeof value === "string") return true;
        if (value && value?.file?.type)
          return keys.FILE_SUPPORTED_FORMATS.includes(value.file.type);
      }),
    name: Yup.string()
      .max(50, t("name_max_length"))
      .required(t("name_required")),
    description: Yup.string()
      .max(800, t("description_max_length"))
      .required(t("description_required")),

    creator: Yup.string().required(t("create-nft.wallet_required")),
    isList: Yup.boolean(),
    listType: Yup.string(),
    price: Yup.number().when(["isList", "listType"], {
      is: (isList, listType) => isList && listType == "fixedPrice",
      then: Yup.number()
        .positive(t("create-nft.price_positive"))
        .required(t("create-nft.price_required"))
        .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
          (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
        ),
    }),
    amount: Yup.number()
      .max(50000, t("create-nft.maximum_editions"))
      .positive(t("create-nft.amount_positive"))
      .integer(t("create-nft.amount_positive"))
      .required(t("create-nft.amount_required")),
    reservePrice: Yup.number().when(["isList", "listType"], {
      is: (isList, listType) => isList && listType == "auction",
      then: Yup.number()
        .positive(t("create-nft.price_positive"))
        .required(t("create-nft.price_required"))
        .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
          (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
        ),
    }),
    startBidPrice: Yup.number().when(["isList", "listType"], {
      is: (isList, listType) => isList && listType == "auction",
      then: Yup.number()
        .positive(t("create-nft.price_positive"))
        .required(t("create-nft.price_required"))
        .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
          (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
        ),
    }),
  });
};

export const editNFTValidationSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string()
      .max(50, t("name_max_length"))
      .required(t("name_required")),
    description: Yup.string()
      .max(800, t("description_max_length"))
      .required(t("description_required")),

    creator: Yup.string().required(t("create-nft.wallet_required")),
    isList: Yup.boolean(),
    listType: Yup.string(),
    price: Yup.number().when(["isList", "listType"], {
      is: (isList, listType) => isList && listType == "fixedPrice",
      then: Yup.number()
        .positive(t("create-nft.price_positive"))
        .required(t("create-nft.price_required"))
        .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
          (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
        ),
    }),
    amount: Yup.number()
      .max(50000, t("create-nft.maximum_editions"))
      .positive(t("create-nft.amount_positive"))
      .integer(t("create-nft.amount_positive"))
      .required(t("create-nft.amount_required")),

    reservePrice: Yup.number().when(["isList", "listType"], {
      is: (isList, listType) => isList && listType == "auction",
      then: Yup.number()
        .positive(t("create-nft.price_positive"))
        .required(t("create-nft.price_required"))
        .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
          (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
        ),
    }),
    startBidPrice: Yup.number().when(["isList", "listType"], {
      is: (isList, listType) => isList && listType == "auction",
      then: Yup.number()
        .positive(t("create-nft.price_positive"))
        .required(t("create-nft.price_required"))
        .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
          (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
        ),
    }),
  });
};
