import React, { useEffect, useState } from "react";
import { NFTForm } from "./NFTForm";
import { useLocation, useParams } from "react-router-dom";
//store
import { bindActionCreators } from "redux";
import { nftActionsCreators } from "../store";
import { useSelector, useDispatch } from "react-redux";
import PageNotFound from "../components/PageNotFound";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const EditNFT = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { nft_id, erc } = useParams();
  const { state } = useLocation();
  const auth_account = useSelector((state) => state.auth);
  const { getNFTDetailsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );
  const { loading, nft, error, error_status } = useSelector(
    (state) => state.nft_details
  );

  useEffect(() => {
    getNFTDetailsCreator(nft_id, erc);
  }, [nft_id]);

  if (loading)
    return (
      <Container>
        <p className="py-5">{t("Loading...")}</p>
      </Container>
    );
  if (nft?.is_lazy) {
    if (
      auth_account.account.wallets.includes(
        nft?.creators?.minter ? nft?.owner?.wallets[0] : nft?.owner
      )
    ) {
      return <NFTForm loading={loading} error={error} nft={nft} />;
    } else {
      return <PageNotFound />;
    }
  } else {
    return <PageNotFound />;
  }
};
