import * as createCollectionTypes from "../actions/types/creatorCollection";
const initialState = {
  loading: false,
  latestCollectionsLoading: false,
  collections: [],
  collectionDetail: null,
  collectionActivities: [],
  trendingCollectionsLoading: false,
  trendingCollections: [],
  latestCollections: [],
  collectionNFTs: [],
  hasMore: false,
  count: 0,
  error: null,
  collectionCreated: false,
};

export const creatorCollectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    //* edit collection
    case createCollectionTypes.EDIT_COLLECTION_SUCCESS:
      return {
        ...state,
        collectionDetail: Object.assign(state.collectionDetail, action.payload),
      };
    case createCollectionTypes.CREATE_COLLECTION:
      return { ...state, loading: true };
    case createCollectionTypes.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collectionCreated: true,
        collections: [
          { ...action.payload, nftCounts: 0 },
          ...state.collections,
        ],
      };
    case createCollectionTypes.CREATE_COLLECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        collectionCreated: false,
      };

    case createCollectionTypes.FETCH_USER_COLLECTIONS:
      return { ...state, loading: true };
    case createCollectionTypes.FETCH_USER_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: [...action.payload],
        hasMore: action.hasMore,
        count: action.count,
      };

    case createCollectionTypes.FETCH_USER_COLLECTIONS_PAGINATED_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: [...state.collections, ...action.payload],
        hasMore: action.hasMore,
        count: action.count,
      };
    case createCollectionTypes.FETCH_USER_COLLECTIONS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case createCollectionTypes.FETCH_COLLECTION_DETAIL:
      return { ...state, loading: true };
    case createCollectionTypes.FETCH_COLLECTION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collectionDetail: action.payload,
      };

    case createCollectionTypes.FETCH_COLLECTION_DETAIL_FAILURE:
      return { ...state, loading: false, error: action.error };

    case createCollectionTypes.RESET_COLLECTION_FLAG: {
      return { ...state, collectionCreated: false };
    }

    case createCollectionTypes.RESET_INITIAL_STATE: {
      return { ...initialState };
    }

    case createCollectionTypes.FETCH_COLLECTION_ACTIVITIES:
      return { ...state, loading: true };
    case createCollectionTypes.FETCH_COLLECTION_ACTIVITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collectionActivities: action.payload,
      };

    case createCollectionTypes.FETCH_TRENDING_COLLECTIONS:
      return { ...state, trendingCollectionsLoading: true };

    case createCollectionTypes.FETCH_TRENDING_COLLECTIONS_SUCCESS:
      return {
        ...state,
        trendingCollectionsLoading: false,
        error: null,
        trendingCollections: action.payload,
      };

    case createCollectionTypes.FETCH_TRENDING_COLLECTIONS_FAILURE:
      return {
        ...state,
        trendingCollectionsLoading: false,
        error: action.error,
      };

    case createCollectionTypes.FETCH_COLLECTION_NFTS:
      return { ...state, loading: true };
    case createCollectionTypes.FETCH_COLLECTION_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        collectionNFTs:
          action.skip === 0
            ? [...action.payload]
            : [...state.collectionNFTs, ...action.payload],
        hasMore: action.hasMore,
        count: action.count,
      };
    case createCollectionTypes.FETCH_COLLECTION_NFTS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case createCollectionTypes.FETCH_LATEST_COLLECTIONS:
      return { ...state, latestCollectionsLoading: true };

    case createCollectionTypes.FETCH_LATEST_COLLECTIONS_SUCCESS:
      return {
        ...state,
        latestCollectionsLoading: false,
        error: null,
        latestCollections: action.payload,
      };

    case createCollectionTypes.FETCH_LATEST_COLLECTIONS_FAILURE:
      return { ...state, latestCollectionsLoading: false, error: action.error };
    default:
      return { ...state };
  }
};
