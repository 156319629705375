import { createContext, useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { web3auth } from "@config";
import { useWeb3Auth } from "@context/Web3auth";
import { Biconomy } from "@biconomy/mexa";
import { useWeb3React } from "@web3-react/core";

export const BiconomyProvider = ({ children }) => {
  const [biconomyWeb3Default, setBiconomyWeb3Default] = useState();
  const [biconomyWeb3Metamask, setBiconomyWeb3Metamask] = useState();
  const { chain, privateKey } = useWeb3Auth();
  const { active, library, chainId } = useWeb3React();
  const [loadedBiconomy, setLoadBiconomy] = useState(false);
  async function initializeOpenlogin() {
    try {
      const options = {
        // Enable auto reconnection
        reconnect: {
          auto: true,
          delay: 5000, // ms
          maxAttempts: 5,
          onTimeout: false,
        },
      };

      const biconomyDefault = new Biconomy(
        new Web3.providers.WebsocketProvider(
          web3auth.websocketUrl[chain],
          options
        ),
        {
          apiKey:
            chain === "ETH"
              ? "cW9hDwaQR.7b1a8834-c5f3-452b-af82-d492e44e8764"
              : "VUV5Bwu4T.5ef11de6-b577-44c2-a77f-4aa2834d0381",
        }
      );
      const web3 = new Web3(biconomyDefault);
      const account = web3.eth.accounts.privateKeyToAccount(privateKey);
      biconomyDefault
        .onEvent(biconomyDefault.READY, async () => {
          console.log(biconomyDefault, "biconomyDefault");
          console.log(web3auth.websocketUrl[chain], "websocket");
          if (!active) {
            setLoadBiconomy(true);
          }
          setBiconomyWeb3Default(web3);
        })
        .onEvent(biconomyDefault.ERROR, (error, message) => {
          console.log(error);
        });

      if (active) {
        const biconomyMetamask = new Biconomy(library.provider, {
          apiKey:
            chain === "ETH"
              ? "cW9hDwaQR.7b1a8834-c5f3-452b-af82-d492e44e8764"
              : "VUV5Bwu4T.5ef11de6-b577-44c2-a77f-4aa2834d0381",
        });
        const web3Metamask = new Web3(biconomyMetamask);
        biconomyMetamask
          .onEvent(biconomyMetamask.READY, async () => {
            setLoadBiconomy(true);
            setBiconomyWeb3Metamask(web3Metamask);
          })
          .onEvent(biconomyMetamask.ERROR, (error, message) => {
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const load = async () => {
      setLoadBiconomy(false);
      await initializeOpenlogin();
    };
    if (privateKey) {
      load();
    }
  }, [privateKey, active, chain]);

  const contextProvider = {
    biconomyWeb3Default,
    biconomyWeb3Metamask,
    loadedBiconomy,
  };
  return (
    <BiconomyContext.Provider value={contextProvider}>
      {children}
    </BiconomyContext.Provider>
  );
};

export const BiconomyContext = createContext({
  biconomyWeb3Default: null,
  biconomyWeb3Metamask: null,
  loadedBiconomy: null,
});

export function useBiconomy() {
  return useContext(BiconomyContext);
}
