import { Container, Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import filter from "../assets/icons/filter.svg";
import close from "../assets/icons/close.svg";
import Breadcrumbs from "../components/Breadcrumbs";
import styled from "styled-components";
import Filter from "../components/Filter";
import Activity from "../components/Activity";

const Wrapper = styled.div`
  padding: 80px 0;
  position: relative;
  @media (max-width: 992px) {
    padding-top: 50px;
  }
  .btn {
    font-size: 14px;
    height: 38px;
    padding: 6px 20px 5px !important;
    border-radius: 50px;
    border: none;
    font-weight: 600;
  }
  .btn:hover {
    opacity: 0.9;
  }
  .not-active {
    background-color: transparent;
    color: #777e90;
  }
  .active {
    color: #fcfcfd;
    background-color: #353945;
  }
  .filter-mobile {
    position: absolute;
    z-index: 10;
    background-color: white;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .toggle-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .filter-icon {
    background-color: transparent;
    border: 1px solid #e6e8ec;
  }
  .filter-icon:hover {
    border: 1px solid #b8babd;
  }
  .close-icon {
    border: none;
    background-color: #6d3190;
  }
  .close-icon:hover {
    opacity: 90%;
  }
  @media (max-width: 992px) {
    .mark-read {
      width: 100%;
      margin-top: 16px;
    }
  }
`;

export const Activities = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  return (
    <>
      <Breadcrumbs
        links={["Profile", "Activity"]}
        className="d-none d-lg-block"
      />
      <Wrapper>
        <Container>
          {/* filter - small screen */}
          {isFilterOpen && (
            <div className="d-lg-none filter-mobile">
              <Filter />
            </div>
          )}
          <Row className="flex-row-reverse">
            {/* filter - large screen */}
            <Col
              className="d-none d-lg-flex justify-content-end align-items-start"
              lg={5}
            >
              <Filter />
            </Col>
            {/* activities */}
            <Col xs={12} lg={7}>
              <Row className="justify-content-between align-items-center mb-5">
                <Col xs={6}>
                  <h1 className="fw-bold">Activity</h1>
                </Col>
                <Col className="d-lg-none d-flex justify-content-end">
                  {!isFilterOpen && (
                    <button
                      className="toggle-icon filter-icon"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                      <img src={filter} alt="" />
                    </button>
                  )}
                  {isFilterOpen && (
                    <button
                      className="toggle-icon close-icon"
                      onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                      <img src={close} alt="" />
                    </button>
                  )}
                </Col>
                <Col xs={12} lg={6} className="d-flex justify-content-end">
                  <Button className="modal-outline-button mark-read">
                    Mark all as read
                  </Button>
                </Col>
              </Row>

              <div>
                <button className="btn me-4 active">All activity</button>
                <button className="btn not-active">My activity</button>
              </div>

              <div className="py-5 my-2">
                <Activity />
                <Activity />
                <Activity />
                <Activity />
                <Activity />
              </div>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};
