import * as moralisType from "../actions/types/moralis";

const linkedNfts = {
  loading: false,
  nfts: [],
  has_more: true,
  chain: "polygon",
  error: null,
  cursor: null,
};

const linkedNftDetails = {
  loading: false,
  nft: null,
  error: null,
};

export const linkedNftsReducer = (state = linkedNfts, action) => {
  switch (action.type) {
    case moralisType.GET_LINKED_NFTS_LOADING:
      return { ...state, loading: true };
    case moralisType.GET_LINKED_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nfts:
          action.chain === state.chain
            ? [...state.nfts, ...action.payload]
            : action.payload,
        chain: action.chain,
        cursor: action.cursor,
        has_more: action.has_more,
        error: null,
      };
    case moralisType.GET_LINKED_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
