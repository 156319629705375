import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 16px;
  .modal-header {
    border: none;
  }
  .modal-title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  p {
    font-size: 18px;
    margin-top: -24px;
    text-align: center;
    margin-bottom: 32px;
  }
`;

const RemoveMetaMaskModal = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal {...props} size="md" centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Remove")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="address">
            {t("Are you sure want to remove your Metamask?")}
          </p>
          <div>
            <a
              href="#"
              className="btn btn-purple d-block mw-cta radius-50 mb-3 width-100"
            >
              {t("Remove")}
            </a>
            <a
              href="#"
              className="btn btn-secondary-outline mw-cta d-block radius-50 width-100"
            >
              {t("Cancel")}
            </a>
          </div>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
};

export default RemoveMetaMaskModal;
