import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  nftActionsCreators,
  userActionsCreators,
  bidActionsCreators,
  projectActionCreators,
} from "../store";
import FeaturedItem from "../components/FeaturedItem";
import HomeTopSlider from "../components/HomeTopSlider";
import LatestNFTs from "../components/LatestNFTs";
import PopularSellers from "../components/PopularSellers";
// import Categories from "../components/Categories";
import CreateAndSellNFT from "../components/CreateAndSellNFT";
import LatestCollections from "../components/TrendingCollections";
import TrendingCollections from "../components/LatestCollections";
import FeaturedGlobalCollections from "../components/FeaturedGlobalCollections";
// import HotBids from "../components/HotBids";
import styled from "styled-components";

const Wrapper = styled.div`
  .button-icon-grey-40 img {
    width: 14px;
    height: 9px;
  }
  @media (min-width: 1400px) {
    .container {
      max-width: 1160px;
    }
  }
`;

export const Home = () => {
  const dispatch = useDispatch();
  const [section, setSection] = useState();
  const [position, setPosition] = useState(true);
  const sections = document.querySelectorAll("section[id]");
  const { getPopularSellersCreator, getLatestSellersUploadCreator } =
    bindActionCreators(userActionsCreators, dispatch);
  const { getProjectsCreator } = bindActionCreators(
    projectActionCreators,
    dispatch
  );
  // const { getHotBidsCreator } = bindActionCreators(
  //   bidActionsCreators,
  //   dispatch
  // );
  const { getLatestNFTsCreator, getFeaturedNFTsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );

  window.addEventListener("scroll", (event) => {
    let scrollY = window.pageYOffset;
    const sec = document.getElementById("create");
    const sectop = sec?.offsetTop + 400;
    if (scrollY < sectop) {
      setPosition(true);
    } else {
      setPosition(false);
    }
    sections.forEach((current) => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 290;
      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        setSection(current.getAttribute("id"));
      }
    });
  });

  const initHome = () => {
    getLatestNFTsCreator();
    getFeaturedNFTsCreator();
    getPopularSellersCreator();
    getLatestSellersUploadCreator();
    getProjectsCreator();
    // getHotBidsCreator();
  };

  useEffect(() => initHome(), []);

  return (
    <Wrapper>
      <HomeTopSlider />
      <FeaturedItem />
      <LatestNFTs />
      <LatestCollections />
      <TrendingCollections />
      {/* <section id="hotBids" style={{ backgroundColor: "#F4F4F4" }}>
        <HotBids />
      </section> */}
      <FeaturedGlobalCollections />
      <PopularSellers />
      <CreateAndSellNFT id="create" />
    </Wrapper>
  );
};
