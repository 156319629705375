import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { transactionsActionsCreators } from "../../store";
import { txScanURL } from "../../core/nft";
import Moment from "moment";
import { InfoTooltip } from "@components";

const Wrapper = styled.div`
  thead {
    border-bottom: 1px solid #e6e8ec;
  }
  .table-container {
    border: 1px solid #e6e8ec;
    border-radius: 8px;
    padding: 16px;
    margin-top: 24px;
  }
  tr th {
    padding-bottom: 16px;
  }
  tr td {
    padding-top: 16px;
  }
  th {
    font-weight: 400;
  }
  th:nth-child(n + 2) {
    min-width: 120px;
  }
  td {
    font-size: 14px;
    font-weight: 350;
    color: #777e90;
  }
  .txn {
    color: #6d3190;
  }
`;

const Transactions = () => {
  const dispatch = useDispatch();
  const { getTransactionsCreator } = bindActionCreators(
    transactionsActionsCreators,
    dispatch
  );
  const { loading, transactions, error } = useSelector(
    (state) => state.transactions
  );

  const { t, i18n } = useTranslation();

  useEffect(() => getTransactionsCreator(), []);

  return (
    <Wrapper>
      <div className="page-header-md">
        {t("Transactions")}
        <InfoTooltip text={t("wallet.transactions_tooltip")} />
      </div>
      {loading ? (
        <Spinner color="#6d3190" />
      ) : (
        <div className="table-container">
          <Table borderless responsive className="table">
            <thead>
              <tr>
                <th style={{ minWidth: "240px" }}>{t("TXN hash Method")}</th>
                <th style={{ minWidth: "240px" }}>{t("Date")}</th>
                <th>{t("Blockchain")}</th>
                <th>{t("Action")}</th>
                <th>{t("Value")}</th>
                <th>{t("From")}</th>
                <th>{t("To")}</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((el, index) => (
                <tr key={index}>
                  <td className="txn">
                    {" "}
                    <a
                      href={txScanURL(el?.tx, el?.payment_method)}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <span className="txn">{el?.tx?.substring(0, 21)}...</span>
                    </a>
                  </td>
                  <td>
                    {Moment(el?.createdAt)
                      .locale(i18n.language)
                      .format("DD-MM-YYYY HH:mm")}
                  </td>
                  <td>{t(el?.payment_method)}</td>
                  <td>{t(el?.action.toLowerCase())}</td>
                  <td>{el?.price}</td>
                  <td>{el?.tx_from?.name}</td>
                  <td>{el?.tx_to?.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Wrapper>
  );
};

export default Transactions;
