import React, { useRef, useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import {
  partnerDetailsOptions,
  nftLazyMinted,
  nft1155Minted,
  nft721ListedMintedAuction,
  nft721ListedMintedFixedPrice,
  nft721UnListedMinted,
} from "../../../../common/nftDetailsOptions";
import { ListItem } from "./styles";
const NFTOptions = ({ nft, nftStatus, onOpenModal, colors, externalNFT }) => {
  const { t, i18n } = useTranslation();

  const onClick = (el) => onOpenModal(el.key);

  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const isFixedPrice = nftStatus == 2;
  const isAuction = nftStatus == 3;
  const isNotListed = nftStatus == 0 || nftStatus == 1;
  const isLazyMint = nft?.is_lazy;
  const is1155 = nft?.token_standard == "1155";
  // const isLazyMint = false;

  const options = isBezelClub
    ? partnerDetailsOptions
    : isLazyMint
    ? nftLazyMinted
    : is1155
    ? nft1155Minted
    : isFixedPrice
    ? nft721ListedMintedFixedPrice
    : isAuction
    ? nft721ListedMintedAuction
    : nft721UnListedMinted;

  return (
    <ListItem colors={colors}>
      <NavDropdown
        align="end"
        className={isBezelClub ? "nft-menu-dark" : "nft-menu"}
        id="dropdown-menu-align-end"
        style={{ zIndex: 0 }}
        title={
          <div className="uploadcircle cursor expand">
            <BsThreeDots style={{ fontSize: 22 }} />
          </div>
        }
      >
        {options.map((el, index) => (
          <>
            <NavDropdown.Item
              onClick={() => onClick(el)}
              className="d-flex justify-content-start align-items-center list-item"
            >
              <span>
                {el.icon}{" "}
                <span style={{ marginInlineStart: 3 }}>{t(el.text)}</span>
              </span>
            </NavDropdown.Item>
            {index !== options?.length - 1 && (
              <div>
                <hr style={{ width: "100%", margin: "8px 0px" }} />
              </div>
            )}
          </>
        ))}
      </NavDropdown>
    </ListItem>
  );
};

export default NFTOptions;
