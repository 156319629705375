import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import ChooseCollections from "../components/searchBox/SearchBox";
import CreateCollectionPopup from "../pages/CreateCollectionPopup";
import { creatorCollectionsCreators, nftActionsCreators } from "../store";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";

// import addIcon from "../icons/addIcon.png";

const ChangeCollectionModal = ({
  nftId,
  isOpen,
  setIsOpen,
  collections,
  colors,
  creator_collectionId,
}) => {
  const [selectedCollection, setSelectedCollection] = useState();
  const [defaultCollection, setDefaultCollection] = useState("");
  const [isOpenCreateCollection, setOpenCreateCollection] = useState(false);

  const categoryState = useSelector((state) => state.category);
  const { account } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { updateNFTCollectionsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );

  const { resetCollectionFlags } = bindActionCreators(
    creatorCollectionsCreators,
    dispatch
  );

  const { collectionCreated } = useSelector(
    (state) => state.creatorCollections
  );

  const { t } = useTranslation();

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (collectionCreated) {
      setOpenCreateCollection(false);
      Swal.fire({
        icon: "success",
        text: t("collection_created"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetCollectionFlags);
        },
        confirmButtonColor: "#6d3190",
      });
    }
  }, [collectionCreated]);
  useEffect(() => {
    if (defaultCollection?.length === 0) {
      const collectionItem = collections.find(
        (item) => item._id === creator_collectionId
      );
      setDefaultCollection(collectionItem);
    }
  });

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} centered>
        <div style={{ backgroundColor: colors?.bg, color: colors?.text }}>
          <Modal.Header>
            <Modal.Title>
              <h3
                className="create-section-title"
                style={{ color: colors?.text }}
              >
                {t("Change Collection")}
              </h3>
            </Modal.Title>
            <CloseIcon
              className="modal-close-icon"
              color={colors?.text1}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <Container className="create-page mt-0 mb-0">
              {/* preview */}
              <div>
                <ChooseCollections
                  colors={colors}
                  label={t("List of collections")}
                  items={collections.map((item) => ({
                    _id: item._id,
                    title: item.title,
                    logo: item.logo,
                  }))}
                  onSelect={(selectedItem) => {
                    const collectionItem = collections.find(
                      (item) => item._id === selectedItem._id
                    );
                    setSelectedCollection(collectionItem);
                  }}
                  selectedCollection={defaultCollection}
                />
                <p style={{ fontSize: 13, color: "#aaa" }}>
                  {t("have_no_collection")}
                  <span
                    style={{ color: "#6d3190", cursor: "pointer" }}
                    onClick={() => setOpenCreateCollection(true)}
                  >
                    {t("create_collection")}
                  </span>
                </p>
                <button
                  className="mt-2 btn btn-primary"
                  onClick={() => {
                    updateNFTCollectionsCreator(nftId, selectedCollection?._id);
                    handleClose();
                  }}
                >
                  {t("Submit")}
                </button>
              </div>
            </Container>
          </Modal.Body>
        </div>
      </Modal>
      <CreateCollectionPopup
        isOpen={isOpenCreateCollection}
        setIsOpen={setOpenCreateCollection}
        categories={categoryState.categories}
        creatorId={account?._id}
      />
    </>
  );
};

export default ChangeCollectionModal;
