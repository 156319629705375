import { Formik, useFormik } from "formik";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ClosedFilled from "../icons/ClosedFilled.svg";
import * as yup from "yup";
import { bindActionCreators } from "redux";
import {
  creatorCollectionsCreators,
  subcategoryActionCreators,
} from "../store";
import keys from "../lib/config";
import UploadImage from "../components/uploadImage/UploadImage";

const CreateCollectionPopup = ({
  isOpen,
  setIsOpen,
  categories,
  creatorId,
  artistId,
  collection,
  action = "create",
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formikRef = useRef();

  const { loading } = useSelector((state) => state.creatorCollections);
  const FILE_SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/webp",
    "image/png",
  ];

  const subCategorySelector = useSelector((state) => state.subcategories);
  const { account } = useSelector((state) => state.auth);

  const { collections } = useSelector((state) => state.artist);
  const [defaultCategory, setDefaultCategory] = useState("");

  const [initialValue, setInitialValue] = useState({
    title: collection?.title || "",
    categoryId: "",
    subcategoryId: "",
    logo: collection?.logo || undefined,
    coverImage: collection?.coverImage || undefined,
    artistId: collection?.artist?._id || undefined,
  });
  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .trim()
      .required(t("empty_collection_name"))
      .max(100, t("error_collection_name_length")),
    categoryId: yup.string().required(t("choose_category")),
    // subcategoryId: yup.string(),
    logo:
      typeof collection?.logo === "string"
        ? yup
            .mixed()
            .required(t("empty_logo_file"))
            .test("fileFormat", t("unsupported_file_types"), (value) => {
              if (value && typeof value === "string") {
                return true;
              }
              if (value && value.type) {
                return FILE_SUPPORTED_FORMATS.includes(value.type);
              }
            })
        : yup
            .mixed()
            .required(t("empty_logo_file"))
            .test(
              "fileSize",
              t("invalid_file_size"),
              (value) => value && value.size <= keys.FILE_SIZE
            )
            .test(
              "fileFormat",
              t("unsupported_file_types"),
              (value) => value && FILE_SUPPORTED_FORMATS.includes(value.type)
            ),
    coverImage:
      typeof collection?.coverImage === "string"
        ? yup
            .mixed()
            .required(t("empty_banner_file"))
            .test("fileFormat", t("unsupported_file_types"), (value) => {
              if (value && typeof value === "string") {
                return true;
              }
              if (value && value.type) {
                return FILE_SUPPORTED_FORMATS.includes(value.type);
              }
            })
        : yup
            .mixed()
            .required(t("empty_banner_file"))
            .test(
              "fileSize",
              t("invalid_file_size"),
              (value) => value && value.size <= keys.FILE_SIZE
            )
            .test(
              "fileFormat",
              t("unsupported_file_types"),
              (value) => value && FILE_SUPPORTED_FORMATS.includes(value.type)
            ),
  });

  const { getSubCategoryByCategoryCreator } = bindActionCreators(
    subcategoryActionCreators,
    dispatch
  );
  const { createCollectionCreator, editCollectionCreator } = bindActionCreators(
    creatorCollectionsCreators,
    dispatch
  );

  useEffect(() => {
    if (collection) {
      setInitialValue({
        title: collection?.title || "",
        categoryId: collection?.category?._id,
        subcategoryId: "",
        logo: collection?.logo || undefined,
        coverImage: collection?.coverImage || undefined,
        artistId: collection?.artist?._id || "",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  useEffect(() => {
    if (categories && categories?.length > 0) {
      setInitialValue({
        ...initialValue,
        // categoryId: category_Id,
      });
      formikRef?.current?.setFieldValue("categoryId", categories[0]._id);
      getSubCategoryByCategoryCreator(categories[0]._id);
    }
    setDefaultCategory(categories[0]?._id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  useEffect(() => {
    if (subCategorySelector.subcategories?.length > 0) {
      if (initialValue.subcategoryId?.length === 0) {
        setInitialValue({
          ...initialValue,
          subcategoryId: subCategorySelector.subcategories[0]._id,
        });
      }
      formikRef?.current?.setFieldValue(
        "subcategoryId",
        subCategorySelector.subcategories[0]?._id || ""
      );
    } else {
      formikRef?.current?.setFieldValue("subcategoryId", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategorySelector.subcategories]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal size="lg" show={isOpen} onHide={handleClose}>
      <Modal.Header className="p-5 pb-3 pt-4">
        <Modal.Title>
          <h3 className="create-section-title">{t("create_collection")}</h3>
        </Modal.Title>
        <img
          onClick={handleClose}
          src={ClosedFilled}
          width={32}
          height={32}
          alt=""
        />
      </Modal.Header>
      <Modal.Body className="pl-2 pr-2 pb-0">
        <Container className="create-page mt-0 mb-0">
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValue}
            onSubmit={async (values) => {
              const requestData = {
                ...values,
                creatorId: creatorId,
              };

              if (artistId) {
                requestData.artistId = artistId;
              }

              if (action === "create") {
                await createCollectionCreator(requestData);
              } else if (action === "edit") {
                await editCollectionCreator(collection._id, requestData);
              }
            }}
            innerRef={formikRef}
          >
            {({
              handleChange,
              handleSubmit,
              handleBlur,
              values,
              touched,
              errors,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                style={
                  loading || collections.action_loading
                    ? {
                        pointerEvents: "none",
                        cursor: "not-allowed",
                        opacity: "0.65",
                      }
                    : {}
                }
              >
                {/* preview */}
                <div className="item-details pb-0">
                  <Form.Group controlId="item-name" className="mb-4">
                    <Form.Label className="create-section-label">
                      {t("collection_name")}
                      <span className="text-danger" style={{ fontSize: 14 }}>
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      className="create-page-input-field"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.title}
                      errors={errors.title}
                      value={values.title}
                      name="title"
                      placeholder={t("Enter Title")}
                    />
                    {touched.title && errors.title && (
                      <Form.Text className="text-danger">
                        {errors.title}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Col xs={12} md={8} className="mb-4">
                    <Form.Group>
                      <Form.Label className="create-section-label">
                        {t("category")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Select Category"
                        className="payment-page-input select"
                        value={values.categoryId}
                        name="categoryId"
                        onChange={(e, i) => {
                          getSubCategoryByCategoryCreator(e.target.value);
                          handleChange(e);
                        }}
                      >
                        <option selected="" key="" value="">
                          {t("select_category")}
                        </option>
                        {categories.map((item) => (
                          <option
                            selected={values.categoryId === item._id}
                            key={item._id}
                            value={item._id}
                          >
                            {t(item.name)}
                          </option>
                        ))}
                      </Form.Select>
                      {touched.categoryId && errors.categoryId && (
                        <Form.Text className="text-danger">
                          {errors.categoryId}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  {/* <Col xs={12} md={8} className="mb-4">
                    <Form.Group>
                      <Form.Label className="create-section-label">
                        {t("sub_category")}
                      </Form.Label>

                      <Form.Select
                        aria-label="Select Subcategory"
                        className="payment-page-input select"
                        value={values.subcategoryId}
                        onChange={handleChange}
                        name="subcategoryId"
                      >
                        {subCategorySelector.subcategories.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      {touched.subcategoryId && errors.subcategoryId && (
                        <Form.Text className="text-danger">
                          {errors.subcategoryId}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col> */}
                  <Col xs={12} md={8} className="mb-4">
                    <Form.Group>
                      <Form.Label className="create-section-label">
                        {t("owner_name")}
                      </Form.Label>
                      <Form.Control
                        className="payment-page-input select"
                        type="text"
                        placeholder={t("Owner Name")}
                        disabled={true}
                        name="ownerName"
                        value={account?.name || "Owner Name"}
                      />
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col xs={12} md={8}>
                      <h5 className="mt-5 modal-section-title">
                        {t("upload_logo")}
                        <span className="text-danger" style={{ fontSize: 14 }}>
                          *
                        </span>
                      </h5>
                      <p className="mt-2 modal-section-text">
                        {t("upload_logo_p1")}
                      </p>
                      {touched.logo && errors.logo && (
                        <Form.Text className="text-danger">
                          {errors.logo}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} md={4} className="mt-5">
                      <UploadImage
                        fieldName="logo"
                        onFileSelected={(file) => setFieldValue("logo", file)}
                        onFileRemove={() => setFieldValue("logo", undefined)}
                        onlyPreviewImage={values.logo}
                        closeIcon={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={8}>
                      <h5 className="mt-5 modal-section-title">
                        {t("upload_banner_image")}
                        <span className="text-danger" style={{ fontSize: 14 }}>
                          *
                        </span>
                      </h5>
                      <p className="mt-2 modal-section-text">
                        {t("upload_banner_p1")}
                      </p>
                      {touched.coverImage && errors.coverImage && (
                        <Form.Text className="text-danger">
                          {errors.coverImage}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} md={4} className="mt-5">
                      <UploadImage
                        fieldName="coverImage"
                        onFileSelected={(file) =>
                          setFieldValue("coverImage", file)
                        }
                        onFileRemove={() =>
                          setFieldValue("coverImage", undefined)
                        }
                        onlyPreviewImage={values.coverImage}
                        closeIcon={true}
                      />
                    </Col>
                  </Row>
                  <Button
                    style={{ width: "163px", height: "48px" }}
                    className="mx-0 mx-md-2 my-2 my-md-5 btn btn-primary"
                    type="submit"
                    disabled={loading || collections.action_loading}
                  >
                    {action === "create" ? t("Create") : t("Save")}
                    {(loading || collections.action_loading) && (
                      <Spinner
                        size="sm"
                        style={{ color: "#fff", marginLeft: "5px" }}
                      />
                    )}
                  </Button>

                  <Button
                    style={{ width: "163px", height: "48px" }}
                    variant="secoundary-outline"
                    className=" mx-0 mx-md-5 my-2 my-md-0 btn btn-secondary-outline radius-50 mb-4 "
                    onMouseEnter={""}
                    onClick={handleClose}
                    disabled={loading}
                  >
                    {t("Cancel")}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCollectionPopup;
