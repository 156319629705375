import keys from "../lib/config";

export const uploadFileToIPFS = async (client, file) => {
  try {
    const added = await client.add(file, {
      progress: (prog) => console.log(`received: ${prog}`),
    });
    const url = `https://niftysouq.infura-ipfs.io/ipfs/${added.path}`;
    return url;
  } catch (error) {
    console.error(error);
  }
};

export const getEditions = (editions, editions_number) => {
  return editions?.slice(0, editions_number);
};

export const extractLoggedInOwner = (user_id, owners) => {
  const user = owners?.find((el) => el?.user?._id === user_id);
  return user ? user : {};
};

export const txScanURL = (tx, chain) => {
  if (chain === "MATIC") {
    return `${window.POLYGON_SCAN}/${tx}`;
  } else {
    return `${window.ETH_SCAN}/${tx}`;
  }
};

export const convertDateToSeconds = (date) => Math.floor(new Date(date.toString()).getTime() / 1000);
