import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import rightArrow from "../assets/icons/right-arrow.svg";
import arrowRight from "../assets/icons/arrow-right.svg";
import arrowLeft from "../assets/icons/arrow-left.svg";
import styled from "styled-components";

const Wrapper = styled.div`
  .title1 {
    font-size: 24px;
    font-weight: 350;
  }
  .subtitle {
    color: #29367a;
    font-size: 24px;
    font-weight: 450;
  }
  .link {
    font-size: 16px;
    font-weight: 350;
    color: #6d3190;
  }
  .divider {
    height: 1;
    width: 100%;
    background-color: #c6c6c6;
  }
  .cards {
    list-style: none;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .cards::-webkit-scrollbar {
    display: none;
  }

  .arrow {
    transform: ${(props) => (props.isLTR ? "rotate(0deg)" : "rotate(180deg)")};
  }
  .right-arrow {
    position: absolute;
    bottom: 50%;
    right: ${(props) => (props.isLTR ? "-40px" : "unset")};
    left: ${(props) => (props.isLTR ? "unset" : "-40px")};
  }
  .left-arrow {
    position: absolute;
    bottom: 50%;
    left: ${(props) => (props.isLTR ? "0" : "unset")};
    right: ${(props) => (props.isLTR ? "unset" : "0")};
  }
`;

const ScrollBar = ({
  data,
  renderCard,
  renderFilter,
  title,
  subtitle,
  headerStyle,
  scrollStyle,
  link = "discover",
  noDataMessage = "",
}) => {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language !== "ar";

  const [reachedLeft, setReachedLeft] = useState(true);
  const [reachedRight, setReachedRight] = useState(false);

  const threshold = 291;

  const scrollItemsRef = useRef(null);

  const scrollLeft = () =>
    scrollItemsRef.current?.scrollBy(isLTR ? -threshold : threshold, 0);
  const scrollRight = () =>
    scrollItemsRef.current?.scrollBy(isLTR ? threshold : -threshold, 0);

  const onScroll = () => {
    if (scrollItemsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollItemsRef.current;
      const toRight = Math.abs(scrollLeft) + clientWidth === scrollWidth;
      setReachedRight(toRight ? true : false);
      setReachedLeft(scrollLeft == 0 ? true : false);
    }
  };
  useEffect(() => {
    onScroll();
  }, [scrollItemsRef]);
  return (
    <Wrapper isLTR={isLTR}>
      <div
        className={`mx-3 d-flex justify-content-between align-items-center flex-wrap ${headerStyle}`}
      >
        <div className="d-flex flex-row">
          <div className="title1">{title}</div>
          <div className="subtitle mx-2">{subtitle}</div>
        </div>
        {link !== "" && (
          <Link to={link} className="link">
            <span className="d-flex align-items-center">
              <span className="me-2 link">{t("See all")}</span>
              <img src={rightArrow} className="arrow" />
            </span>
          </Link>
        )}
        {renderFilter && renderFilter()}
      </div>

      <div
        className="mt-2 mb-5"
        style={{ height: 1, width: "100%", backgroundColor: "#c6c6c6" }}
      />
      {data && data?.length > 0 ? (
        <div style={{ position: "relative" }}>
          <div
            ref={scrollItemsRef}
            onScroll={onScroll}
            className={`d-flex cards ${scrollStyle}`}
          >
            {data.map((item) => renderCard(item))}
          </div>

          {!reachedLeft && (
            <button
              onClick={scrollLeft}
              className="d-none d-lg-inline-block button-icon-grey-40 left-arrow"
            >
              <img src={arrowLeft} alt="" />
            </button>
          )}
          {!reachedRight && (
            <button
              onClick={scrollRight}
              className="d-none d-lg-inline-block button-icon-grey-40 right-arrow"
            >
              <img alt="" src={arrowRight} />
            </button>
          )}
        </div>
      ) : (
        <p>{t(noDataMessage)}</p>
      )}
    </Wrapper>
  );
};

export default ScrollBar;
