import * as artistTypes from "../types/artist";
import { API_PATH } from "../../../common/constants/api";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const createGalleryArtistCreator = (galleryId, payload) => {
  const requestData = new FormData();
  requestData.append("name", payload.name);
  requestData.append("description", payload.description);
  requestData.append("country", payload.country);
  requestData.append("profileImage", payload.profileImage);
  requestData.append("coverImage", payload.coverImage);
  requestData.append("instagram_url", payload.instagram_url || "");
  requestData.append("twitter_url", payload.twitter_url || "");
  requestData.append("website_url", payload.website_url || "");
  requestData.append("facebook_url", payload.facebook_url || "");
  requestData.append("youtube_url", payload.youtube_url || "");
  return (dispatch) => {
    dispatch({
      type: artistTypes.CREATE_GALLERY_ARTIST,
      loading: true,
    });

    API.put(API_PATH.CREATE_GALLERY_ARTIST(galleryId), requestData, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        dispatch({
          type: artistTypes.CREATE_GALLERY_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: artistTypes.CREATE_GALLERY_ARTIST_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const updateGalleryArtistCreator = (galleryId, artistId, payload) => {
  const requestData = new FormData();
  requestData.append("name", payload.name);
  requestData.append("description", payload.description);
  requestData.append("country", payload.country);
  requestData.append("instagram_url", payload.instagram_url);
  requestData.append("twitter_url", payload.twitter_url);
  requestData.append("website_url", payload.website_url);
  requestData.append("facebook_url", payload.facebook_url);
  requestData.append("youtube_url", payload.youtube_url);
  if (payload.profileImage !== "string") {
    requestData.append("profileImage", payload.profileImage);
  }
  if (payload.profileImage !== "string") {
    requestData.append("coverImage", payload.coverImage);
  }
  return (dispatch) => {
    dispatch({
      type: artistTypes.UPDATE_GALLERY_ARTIST,
      loading: true,
    });

    API.post(API_PATH.UPDATE_GALLERY_ARTIST(galleryId, artistId), requestData)
      .then((res) => {
        dispatch({
          type: artistTypes.UPDATE_GALLERY_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: artistTypes.UPDATE_GALLERY_ARTIST_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getGalleryArtistCreator = (params, load) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.FETCH_GALLERY_ARTIST,
      loading: true,
    });

    API.get(`api/gallery/artists`, params)
      .then((res) => {
        console.log("res", res);
        dispatch({
          type: artistTypes.FETCH_GALLERY_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          has_more: res.data.data?.length === 20,
          load,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        console.log("error", error);
        dispatch({
          type: artistTypes.FETCH_GALLERY_ARTIST_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getPublicGalleryArtistCreator = (params, load) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.FETCH_PUBLIC_GALLERY_ARTIST,
      loading: true,
    });

    API.get(`api/gallery/public/artists`, params)
      .then((res) => {
        dispatch({
          type: artistTypes.FETCH_PUBLIC_GALLERY_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          load,
          galleryId: params?.galleryId,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: artistTypes.FETCH_PUBLIC_GALLERY_ARTIST_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getGalleryArtistByIdCreator = (galleryId, artistId) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.FETCH_GALLERY_ARTIST_BY_ID,
      loading: true,
    });

    API.get(API_PATH.GET_GALLERY_ARTIST_BY_ID(galleryId, artistId))
      .then((res) => {
        dispatch({
          type: artistTypes.FETCH_GALLERY_ARTIST_BY_ID_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: artistTypes.FETCH_GALLERY_ARTIST_BY_ID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const deleteGalleryArtistCreator = (galleryId, artistId) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.DELETE_GALLERY_ARTIST,
      loading: true,
    });

    API.remove(API_PATH.DELETE_GALLERY_ARTIST(galleryId, artistId))
      .then((res) => {
        dispatch({
          type: artistTypes.DELETE_GALLERY_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: artistTypes.DELETE_GALLERY_ARTIST_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const assignGalleryArtistCreator = (galleryId, artistPayload) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.ASSIGN_GALLERY_ARTIST,
      loading: true,
    });

    API.patch(API_PATH.ASSIGN_GALLERY_ARTIST(galleryId), artistPayload, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        dispatch({
          type: artistTypes.ASSIGN_GALLERY_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: artistTypes.ASSIGN_GALLERY_ARTIST_SUCCESS,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const resetGalleryArtistByIdCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.RESET_FETCH_GALLERY_ARTIST_BY_ID });
};

export const resetCreateGalleryArtistCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.CREATE_GALLERY_ARTIST_RESET });
};

export const resetUpdateGalleryArtistCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.UPDATE_GALLERY_ARTIST_RESET });
};

export const resetDeleteGalleryArtistCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.DELETE_GALLERY_ARTIST_RESET });
};

export const resetAssignGalleryArtistCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.ASSIGN_GALLERY_ARTIST_RESET });
};

export const resetGalleryArtistCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.RESET_FETCH_GALLERY_ARTIST });
};

export const resetPublicGalleryArtistCreator = () => {
  return (dispatch) =>
    dispatch({ type: artistTypes.RESET_FETCH_PUBLIC_GALLERY_ARTIST });
};

export const getArtistCreator = (artistId) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.FETCH_ARTIST,
      loading: true,
    });

    API.get(API_PATH.GET_ARTIST(artistId), {})
      .then((res) => {
        dispatch({
          type: artistTypes.FETCH_ARTIST_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: artistTypes.FETCH_ARTIST_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const fetchArtistCollectionCreator = (request, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.FETCH_ARTIST_COLLECTIONS,
      loading: true,
    });

    API.postWithParams("api/creatorcollection/find", request, queryParams)
      .then((res) => {
        dispatch({
          type: queryParams
            ? artistTypes.FETCH_ARTIST_COLLECTIONS_PAGINATED_SUCCESS
            : artistTypes.FETCH_ARTIST_COLLECTIONS_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          hasMore: res.data.data?.length === 6,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);
        dispatch({
          type: artistTypes.FETCH_ARTIST_COLLECTIONS_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const resetArtistCollectionCreator = () => {
  return (dispatch) => dispatch({ type: artistTypes.RESET_ARTIST_COLLECTIONS });
};

export const fetchOwnerIdCreator = (artistId) => {
  return (dispatch) => {
    dispatch({
      type: artistTypes.FETCH_OWNER_ID,
      loading: true,
    });
    API.get(`api/artist/gallery-owner/${artistId}`)
      .then((res) => {
        dispatch({
          type: artistTypes.FETCH_OWNER_ID_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: artistTypes.FETCH_OWNER_ID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
