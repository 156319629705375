import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
//store
import { useSelector, useDispatch } from "react-redux";
import { stripeActionsCreators } from "../store";
import { bindActionCreators } from "redux";
import keys from "../lib/config";
import styled from "styled-components";
import ModalAndOverlay from "./ModalAndOverlay";

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(35, 38, 47, 0.9);
  z-index: 999;
`;
const StylesWrapper = styled.div`
  .text {
    color: #fff;
  }
  .title {
    font-weight: 700;
    font-size: 32px;
  }
  .success-title {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
  }
  .subtitle {
    font-weight: 700;
    font-size: 16px;
  }
  .success-subtitle {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
  }
`;
const StripeModal = ({
  clientSecret,
  isOpen,
  colors,
  setIsOpen,
  nftId,
  nft,
  refresh,
}) => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { t } = useTranslation();

  const { checkBackendStatus } = bindActionCreators(
    stripeActionsCreators,
    dispatch
  );

  const { status, loading } = useSelector((state) => state.stripe);

  useEffect(() => {
    console.log("loading", loading);
    if (!loading) {
      console.log("status", status);
      if (!status)
        setTimeout(() => {
          callApi({ clientSecret });
        }, 10000);
      else {
        // refresh();
        navigator(`/club/${window.ADDRESSES.BEZEL.ETH}/${nftId}`, {
          replace: true,
          state: { refresh: true },
        });
      }
    }
  }, [loading, status]);

  const callApi = (body) => {
    checkBackendStatus(body);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <ModalAndOverlay colors={colors}>
        <StylesWrapper>
          <div>
            {!status && (
              <div>
                <div className="d-flex justify-content-between">
                  <div className="title"> {t("processing_purchase")}</div>
                  <CloseIcon
                    className="modal-close-icon"
                    onClick={handleClose}
                  />
                </div>
                <div
                  className="my-3"
                  style={{
                    width: "100%",
                    height: 0.8,
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            )}
            <div className="text">
              {status ? (
                <div>
                  <div className="success-title">
                    {t("successfully_bought")} <span>{nft?.name}</span>{" "}
                  </div>
                  <div className="success-subtitle mt-2">
                    <span> {t("congrats")}</span>
                    <span className="mx-1">{t("successfully_bought")}</span>
                    <span className="mx-1">{nft?.name}</span>
                    <span>{t("on_nifty_souq")}</span>
                  </div>
                </div>
              ) : (
                <div className="subtitle">{t("processing_purchase_text")}</div>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center mt-4 mb-4">
              {status ? (
                <img
                  className="mt-3"
                  style={{ width: 200, height: 200 }}
                  src={require("@assets/images/done.png")}
                />
              ) : (
                <div className="animated-wrapper mt-5">
                  <div className="animated-circle"></div>
                  <div className="animated-circle"></div>
                  <div className="animated-circle"></div>
                  <div className="animated-shadow"></div>
                  <div className="animated-shadow"></div>
                  <div className="animated-shadow"></div>
                </div>
              )}
            </div>
            {status && (
              <div className="d-flex flex-row justify-content-center">
                <Button
                  onClick={handleClose}
                  style={{ width: "50%" }}
                  className={`modal-button modal-outline-button-dark`}
                >
                  {t("Continue")}
                </Button>
              </div>
            )}
          </div>
        </StylesWrapper>
      </ModalAndOverlay>
    </>
  );
};

export default StripeModal;
