import React from "react";

import { Web3AuthProvider } from "@context/Web3auth";
import Web3Provider from "@context/Web3Provider";
import { Web3IntegrationProvider } from "@context/Web3";
import { BiconomyProvider } from "./Biconomy";
const GlobalContext = ({ children }) => {
  return (
    <Web3AuthProvider>
      <Web3Provider>
        <BiconomyProvider>
          <Web3IntegrationProvider>{children}</Web3IntegrationProvider>
        </BiconomyProvider>
      </Web3Provider>
    </Web3AuthProvider>
  );
};

export default GlobalContext;
