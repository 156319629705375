import * as ethTypes from "../types/eth";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getEthUSDCreator = () => {
  return (dispatch) => {
    dispatch({
      type: ethTypes.GET_ETH_USD_LOADING,
    });

    API.get(`api/eth/usd/`, {})
      .then((res) => {
        dispatch({
          type: ethTypes.GET_ETH_USD_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: ethTypes.GET_ETH_USD_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getSignatureCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: ethTypes.GET_SIGNATURE_LOADING,
    });

    API.post(`api/eth/signature/`, body)
      .then((res) => {
        dispatch({
          type: ethTypes.GET_SIGNATURE_SUCCESS,
          payload: res?.data?.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: ethTypes.GET_SIGNATURE_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const createWertSignatureCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: ethTypes.CREATE_WERT_SIGNATURE_LOADING,
    });
    API.post(`api/eth/wert/signature/`, body)
      .then((res) => {
        dispatch({
          type: ethTypes.CREATE_WERT_SIGNATURE_SUCCESS,
          payload: res?.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);
        dispatch({
          type: ethTypes.CREATE_WERT_SIGNATURE_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
