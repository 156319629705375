/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import arrowRight from "../assets/icons/arrow-right.svg";
import arrowLeft from "../assets/icons/arrow-left.svg";
import { ReactComponent as Arrow } from "@assets/icons/arrow-down.svg";
import { useRef } from "react";

//store
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const icons = [
  {
    icon: "winner",
    color: "blue",
  },
  {
    icon: "donut",
    color: "purple",
  },
  {
    icon: "lightning",
    color: "green",
  },
  {
    icon: "donut",
    color: "green",
  },
  {
    icon: "donut",
    color: "black",
  },
  {
    icon: "donut",
    color: "black",
  },
];

const Wrapper = styled.div`
  .label {
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #b1b5c4;
  }
  .slider-container {
    padding: 0 48px;
  }
  .slider {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  .slider::-webkit-scrollbar {
    width: 0;
  }
  @media (min-width: 1200px) {
    .left-btn {
      position: absolute;
      top: 110px;
      left: 0;
    }
    .right-btn {
      position: absolute;
      top: 110px;
      right: 0;
    }
  }
  .logo {
    transition: all 0.2s;
  }
  .seller-box {
    transition: all 0.2s;
    margin-right: 32px;
    width: 200px;
    overflow-wrap: break-word;
  }
  .seller-box:hover {
    height: 267px !important;
    margin-bottom: 20px !important;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  }
  .seller-box:hover .logo {
    width: 80px !important;
    height: 80px !important;
  }
  .switch-icon {
    cursor: pointer;
  }
  .dropdown {
    color: #777e90;
    width: 150px;
    cursor: pointer;
  }
  .dropdown:hover {
    color: #6d3190;
  }
`;

const SellersComponent = () => {
  const { loading, users } = useSelector((state) => state.popular_sellers);
  console.log("users", users);

  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isSeller, setIsSeller] = useState(true);

  const { t, i18n } = useTranslation();

  // const {
  //     items: sellers,
  //     loading,
  //     error,
  // } = useFetch(window.API_URL + '/api/nft/sellers')
  const options = [
    { label: "Today", id: "0" },
    { label: "This Week", id: "1" },
    { label: "This Month", id: "2" },
  ];

  const scrollItemsRef = useRef(null);

  const scrollRight = () => {
    scrollItemsRef.current.scrollBy(-232, 0);
  };

  const scrollLeft = () => {
    scrollItemsRef.current.scrollBy(232, 0);
  };

  const changeType = () => {
    setIsDropDownOpen(false);
    setIsSeller(!isSeller);
  };

  const onFollow = (e) => {
    e.preventDefault();
    console.log("following");
  };
  return (
    <Wrapper>
      <div className="gray-container main-section">
        <Container>
          <Row className="mb-5">
            <Col md={8} xl={9} className="mb-3 mb-md-0">
              <h5 className="text-muted">{t("Popular")}</h5>
              <div className="d-flex">
                <h2 className="me-2">
                  {isSeller ? t("Sellers") : t("Buyers")}
                </h2>
                <div
                  className="switch-icon"
                  onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                >
                  <Arrow color="#777E91" />
                </div>
              </div>
              {isDropDownOpen && (
                <div className="dropdown" onClick={changeType}>
                  <h4>{isSeller ? "Buyers" : "Sellers"}</h4>
                </div>
              )}
            </Col>
            {/* <Col md={4} xl={3}>
              <div className="mb-2 label">{t("Timeframe")}</div>
              <DropDown label="Today" options={options} />
            </Col> */}
          </Row>
          <div className="slider-container position-relative">
            <div className="slider" ref={scrollItemsRef}>
              <div className="d-flex">
                {/* if loading */}
                {/* {loading && <p>Loading...</p>} */}
                {/* if error */}
                {/* {error && <p>{error}</p>} */}
                {/* if sellers array contains items */}
                {users &&
                  users?.length > 0 &&
                  users.map((seller, index) => {
                    return (
                      <div className="seller-box" key={index}>
                        <div className="seller-top d-flex">
                          <div className="flex-fill">
                            <a
                              href="#"
                              className={`d-inline-block tag ${
                                icons[+2 - 1]?.color
                              }`}
                            >
                              <span className="d-flex align-items-center">
                                {/* <i
                                                                    className={`${
                                                                        icons[
                                                                            + (++index) -
                                                                                1
                                                                        ].icon
                                                                    } me-1`}
                                                                ></i> */}
                                <span>#{++index}</span>
                              </span>
                            </a>
                          </div>
                          <div>
                            <div
                              className="plus-btn"
                              onClick={(e) => onFollow()}
                            ></div>
                            {/* <a href="#">
                              <i className="share"></i>
                            </a> */}
                          </div>
                        </div>
                        <div className="seller-info">
                          <div className="text-center">
                            <Link
                              to={`/profile/${seller?.user?._id}`}
                              className="d-inline-block position-relative"
                            >
                              <img
                                src={
                                  seller?.user?.profile?.image_url ||
                                  "/user-placeholder.png"
                                }
                                width="64"
                                height="64"
                                alt=""
                                className="radius-50 logo"
                                style={{ objectFit: "cover" }}
                              />
                              <i className="tiny-logo"></i>
                            </Link>
                            <Link
                              to={`/profile/${seller?.user?._id}`}
                              className="d-block mt-4 text-center"
                            >
                              <span
                                className="d-block"
                                style={{ textDecoration: "none" }}
                              >
                                {seller?.user?.name}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                className="left-btn button-icon-grey-40 me-3"
                onClick={scrollRight}
              >
                <img src={arrowLeft} alt="" />
              </button>
              <button
                className="right-btn button-icon-grey-40"
                onClick={scrollLeft}
              >
                <img src={arrowRight} alt="" />
              </button>
            </div>
          </div>
        </Container>
      </div>
    </Wrapper>
  );
};

export default SellersComponent;
