import * as subCategoryTypes from "../actions/types/subcategory";
const initialState = {
  loading: false,
  subcategories: [],
  error: null,
};

export const subcategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case subCategoryTypes.FETCH_SUBCATEGORIES:
      return { ...state, loading: true };
    case subCategoryTypes.FETCH_SUBCATEGORIES_SUCCESS:
      return { ...state, loading: false, error: null, subcategories: action.payload };
    case subCategoryTypes.FETCH_SUBCATEGORIES_FAILURE:
      return { ...state, loading: false, error: action.error };
    default:
      return { ...state };
  }
};

