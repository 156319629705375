import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Container, Row, Col } from "react-bootstrap";
import WalletInfo from "../components/Payment/WalletInfo";
import SideLinks from "../components/Payment/SideLinks";
import LinkedNft from "../components/Payment/LinkedNft";
import Transfer from "../components/Payment/Transfer.js";
import styled from "styled-components";
import TopUp from "../components/Payment/TopUp";
import Transactions from "../components/Payment/Transactions";
import Cards from "../components/Payment/Cards";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------
import { v4 as uuidv4 } from "uuid";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3React } from "@web3-react/core";
import useJwt from "@library/jwt/useJwt";
import WalletModal from "@components/WalletModal";
import { createMessage, encrypt as pgpEncrypt, readKey } from "openpgp";
// ------------------------------------------------------

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { walletActionsCreators, authActionsCreators } from "../store";
import Swal from "sweetalert2";
import { RiCoinsLine } from "react-icons/ri";

const Wrapper = styled.div`
  .main {
    padding-top: 0px;
    padding-bottom: 144px;
  }
  @media (min-width: 1200px) {
    .special-container {
      margin-left: 0;
      padding-left: 0;
      max-width: calc(100vw - (100vw - 1140px) / 2) !important;
    }
    .main {
      padding-top: 40px;
    }
  }
  @media (min-width: 1400px) {
    .special-container {
      max-width: calc(100vw - (100vw - 1320px) / 2) !important;
    }
  }
`;

export const Payment = () => {
  const dispatch = useDispatch();
  const { saveWalletsCreator } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );

  const { wallets } = useSelector((state) => state.wallets);
  const { authenticated } = useSelector((state) => state.auth);

  const [currentSection, setCurrentSection] = useState("LinkedNft");

  // ------------------------------------------------------
  const { jwt } = useJwt({});
  const { t, i18n } = useTranslation();

  const {
    privateKey,
    load,
    web3,
    getAccounts,
    getWrappedBalance,
    getUSDTBalance,
    userPrincipal,
    SendTransaction,
  } = useWeb3Auth();

  const { activate, active, account, deactivate, connector, library } =
    useWeb3React();

  const [error, setError] = useState();
  const [amount, setAmount] = useState(1);
  const [userData, setUserData] = useState({});
  const [walletData, setWalletData] = useState({});
  //const [NFTData, setNFTData] = useState([]);
  const [transactionData, setTransactionData] = useState();
  const [recipientAddress, setRecipientAddress] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [accountWallets, setAccountWallets] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [externalBalance, setExternalBalance] = useState("");
  const [WrappedBalance, setWrappedBalance] = useState(undefined);
  const [usdtBalance, setUsdtBalance] = useState(undefined);

  const onConnectWallet = async () => {
    setIsOpenDialog(true);
  };

  const makeTransaction = async () => {
    if (amount > walletData?.balance || !recipientAddress) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("no_funds"),
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    } else {
      if (amount > 0) {
        setLoading(true);
        const result = await SendTransaction(amount, recipientAddress);
        if (result.status) {
          setTransactionData(result.data);
        } else {
          setError(result.data);
        }
        setLoading(false);
      } else {
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("Amount is 0."),
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };

  const dataLoad = async () => {
    const walletInfo = await getAccounts();
    setWalletData(walletInfo);
    const wrappedData = await getWrappedBalance();
    setWrappedBalance(wrappedData);
    const usdtData = await getUSDTBalance();
    setUsdtBalance(usdtData);
    const data = await load();
    setUserData(data);
    // const web3 = new Web3(library.provider);
    // const balance = await web3.eth.getBalance(account)
    // const ExternalBalance = web3.utils.fromWei(balance);
    // setExternalBalance(ExternalBalance);
  };

  // console.log(usdtBalance, WrappedBalance, account, library);
  useEffect(() => {
    dataLoad();
  }, [privateKey, web3, usdtBalance, WrappedBalance]);

  useEffect(() => {
    walletData?.address && saveWalletsCreator(0, walletData?.address);
  }, [walletData]);

  // ------------------------------------------------------

  return (
    <Wrapper>
      <Container>
        <div>
          <div className="my-3 page-header">{t("My Wallet")}</div>
        </div>
      </Container>
      <WalletInfo
        address={walletData?.address}
        balance={walletData?.balance}
        onConnectWallet={onConnectWallet}
        AlgoAccount={walletData?.AlgoAccount}
        algoBalance={walletData?.algoBalance}
        wrappedBalance={WrappedBalance}
        usdtBalance={usdtBalance}
        // externalBalance={externalBalance}
      />
      {/* --------- */}
      <Container>
        <Row>
          <Col xs={12} xl={3} className="side">
            <SideLinks
              setCurrentSection={setCurrentSection}
              currentSection={currentSection}
            />
          </Col>
          <Col xs={12} xl={9} className="main flex-fill">
            {currentSection === "LinkedNft" && <LinkedNft />}
            {currentSection === "Transfer" && (
              <Transfer
                amount={amount}
                setAmount={setAmount}
                recipientAddress={recipientAddress}
                setRecipientAddress={setRecipientAddress}
                makeTransaction={makeTransaction}
              />
            )}
            {currentSection === "TopUp" && <TopUp dataLoad={dataLoad} />}
            {currentSection === "Transactions" && <Transactions />}
            {currentSection === "Cards" && <Cards />}
          </Col>
        </Row>
        <WalletModal
          isOpen={isOpenDialog}
          setIsOpen={setIsOpenDialog}
        ></WalletModal>
      </Container>
    </Wrapper>
  );
};
