import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

const StylesWrapper = styled("div")`
  .create-page-title {
    font-size: 48px;
    line-height: 64px;
    font-weight: 400;
    color: #23262f;
    text-transform: capitalize;
    margin-bottom: 100px;
  }
  .create-section-title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #23262f;
  }
  .create-section-text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 350;
    color: #777e90;
  }
  .css-tlfecz-indicatorContainer {
    display: none !important;
  }
  .css-1hb7zxy-IndicatorsContainer {
    display: none !important;
  }
  .create-section-label {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #777e90;
    text-transform: uppercase;
  }
  .form-control.create-page-input-field,
  .form-select.create-page-input-field {
    border: #e6e8ec solid 1.5px;
    border-radius: 12px;
    height: 48px;
    font-weight: 350;
    font-size: 16px;
  }
  .create-page {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .item-details {
    padding-bottom: 40px;
    border-bottom: 1px solid #e6e8ec;
  }
  .loading-icon {
    width: 14px;
    height: 14px;
    margin-left: 12px;
  }
  .create-collection {
    width: 160px;
    height: 130px;
    background-color: #f4f5f6;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .upload-file {
    border-radius: 24px;
    background-color: #f4f5f6;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #777e90;
    font-weight: 350;
  }
  .collection-filter {
    border-radius: 8px;
    background-color: #f4f5f6;
    height: 70px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #777e90;
  }
  .upload-file img {
    margin-bottom: 12px;
    font-weight: 350;
  }
  .create-collection p {
    font-size: 16px;
  }
  .plus-circle-fill {
    width: 28px;
    height: 28px;
    margin-bottom: 8px;
  }
  // .form-check-input {
  //   width: 20px !important;
  //   height: 20px !important;
  // }
  .form-check-input[type="radio"] {
    width: 20px !important;
    height: 20px !important;
  }
  .form-check-label {
    margin-top: 3px;
  }
  .form-check-input:checked {
    background-color: #6d3190 !important;
    border: none !important;
  }
  .preview {
    width: 350px;
    height: 600px;
    border-radius: 16px;
    padding: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .preview-container {
    display: flex;
    justify-content: end;
  }
  .create-collection-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px;
    gap: 10px;
    width: 180px;
    height: 80px;
    background-color: ${(props) =>
      props.disabledField ? "#EAECEF" : "#f4f5f6"};
    border-radius: 16px;
    cursor: ${(props) => (props.disabledField ? "initial" : "pointer")};
    margin-top: 16px;
  }

  .iconP {
    width: 70px;
    height: 32px;
    display: flex;
    align-items: center;
    color: #23262f;
    margin-bottom: 0px;
  }
  .chip {
    margin-inline-start: 10px;
    color: #6d3190;
    border: #6d3190 solid 1px !important;
  }
  .file-note {
    color: #b1b5c4;
    font-size: 14px;
  }
  .file-wrapper {
    position: relative;
  }
  .file-delete {
    position: absolute;
    top: 9px;
    right: 20px;
    color: #6d3190;
    text-decoration: underline;
    font-size: 14px;
  }
  .section-divider {
    width: 100%;
    height: 1px;
    background-color: lightgray;
  }
  .mandatory {
    color: #ff006b;
  }
  .expand-button {
    width: 44px;
    height: 44px;
    border-radius: 24px;
    background-color: #6d3190;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .nft-input {
    font-weight: 350;
    font-size: 16px;
    border-radius: 12px;
    border: #e6e8ec solid 1.5px;
    padding-right: ${(props) => (props.isLTR ? "80px" : "10px")};
    padding-left: ${(props) => (props.isLTR ? "10px" : "80px")};
  }
  .remove {
    position: absolute;
    top: 9px;
    color: #6d3190;
    font-weight: 400;
    text-decoration: underline;
    font-size: 15px;
    right: ${(props) => (props.isLTR ? "10px" : "unset")};
    left: ${(props) => (props.isLTR ? "unset" : "10px")};
  }
`;
export default StylesWrapper;
