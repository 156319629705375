import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import sadFaceIcon from "../../../../../assets/icons/sad-face-icon.svg";

import "./styles.scss";

const NoDataFound = (props) => {
  const { text, image, createBtnText, onCreateButtonClick } = props;
  const navigate = useNavigate();

  return (
    <>
      <Row>
        {createBtnText && (
          <Col className="d-flex justify-content-end">
            <Button onClick={onCreateButtonClick} className="btnText">
              {createBtnText}
            </Button>
          </Col>
        )}
      </Row>
      <div className="no-galleries-found">
        <Row>
          <Col className="d-flex justify-content-center">
            <img
              src={image}
              alt="sadFace"
              width="100%"
              height="300"
              style={{ objectFit: "contain" }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <p className="text">{text}</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default NoDataFound;
