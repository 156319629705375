import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { truncate } from "../../../../core/string";

const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: #e6e8ec 0 20px 20px;
  margin-bottom: 16px;
  width: 226px;
  .banner {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: left;
  }
  .avatar {
    width: 56px;
    height: 56px;
    object-fit: cover;
    object-position: left;
    border-radius: 50%;
    margin-top: -28px;
  }
  .artist-info {
    text-align: center;
  }
  .name-text {
    font-weight: 400;
    font-size: 20px;
    color: #23262f;
    height: 30px;
    text-overflow: ellipsis;
  }
  .horizontal-line {
    border-top: 1px solid #e6e8ec;
    padding-bottom: 12px;
  }
  .collection-text {
    font-weight: 350;
    font-size: 15.9529px;
    line-height: 27px;
    text-align: center;
    color: #353945;
  }
`;

const ArtistCard = (props) => {
  const { t } = useTranslation();
  const { item, styles } = props;
  const navigate = useNavigate();
  const getCollectionCount = (count) => {
    if (count === 0) return "0";
    return count;
  };
  return (
    <Card
      className="cursor"
      style={styles}
      onClick={() => navigate(`/created-artist/${item._id}`)}
    >
      <div>
        <div>
          {item && (
            <img
              src={item.coverImage || "/images/collection_1.png"}
              alt=""
              className="radius-8 banner"
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <img
          src={item.profileImage || `/images/avatar_1.png`}
          className="avatar"
          alt="avatarIcon"
        />
      </div>
      <div className="artist-info mt-3 p-3">
        <p className="name-text">{truncate(item?.name, 25)}</p>
        {props.galleryId === "63fee9aeede894003eee3879" ? (
          <div className="horizontal-line mt-5" />
        ) : (
          <div className="horizontal-line" />
        )}
        <p className="collection-text">
          {t("collections", {
            count: getCollectionCount(item.collectionCounts),
          })}
        </p>
      </div>
    </Card>
  );
};

export default ArtistCard;
