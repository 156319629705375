import * as ethTypes from "../actions/types/eth";

const eth = {
  loading: false,
  usd: 0,
  signature: null,
  error: null,
};

const wert = {
  loading: false,
  signature: null,
  error: null,
};


export const ethReducer = (state = eth, action) => {
  switch (action.type) {
    case ethTypes.GET_ETH_USD_LOADING:
      return { ...state, loading: true };
    case ethTypes.GET_ETH_USD_SUCCESS:
      return { ...state, loading: false, usd: action.payload, error: null };
    case ethTypes.GET_ETH_USD_ERROR:
      return { ...state, loading: false, error: action.error };
      case ethTypes.GET_SIGNATURE_LOADING:
        return { ...state, loading: true };
      case ethTypes.GET_SIGNATURE_SUCCESS:
        return { ...state, loading: false, signature: action.payload, error: null };
      case ethTypes.GET_SIGNATURE_ERROR:
        return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const wertReducer = (state = wert, action) => {
  switch (action.type) {
    case ethTypes.CREATE_WERT_SIGNATURE_LOADING:
      return { ...state, loading: true };
    case ethTypes.CREATE_WERT_SIGNATURE_SUCCESS:
      return {
        ...state,
        loading: false,
        signature: action.payload,
        error: null,
      };
    case ethTypes.CREATE_WERT_SIGNATURE_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};