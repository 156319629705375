import {
  Home,
  Sellers,
  AllSellers,
  Profile,
  NFTDetails,
  PartnerNFTDetails,
  NewLogin,
  Auth,
  Agreement,
  EditProfile,
  FAQ,
  Privacy,
  Search,
  Activities,
  ContactUs,
  AboutUs,
  Payment,
  AddCreditCard,
  EditCreditCard,
  Discover,
  TermsAndConditions,
  NotFound,
  CreateNFT,
  EditNFT,
  GalleryIntro,
  Artists,
  Galleries,
  Gallery,
  CreateGallery,
  EditGallery,
  GalleryArtist,
  ViewRejectionReason,
  CollectionActivity,
  LinkedNftDetails,
  CreatedArtist,
  CollectionNFTs,
  NftClub,
  // TheBezelClub,
  PopularPartners,
  PopularPartnerDetails,
  ProjectNftDetails,
} from "../../index";

import { private_routes, public_routes } from "..";

export const RouteList = [
  //* public routes
  {
    path: public_routes.home,
    element: <Home />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.projectNftDetails(),
    element: <ProjectNftDetails />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.sellers,
    element: <AllSellers />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.profile,
    element: <Profile />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.profileTab,
    element: <Profile />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.clubs(),
    element: <NftClub />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.nftDetails(),
    element: <NFTDetails />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.linkedNftDetails(),
    element: <LinkedNftDetails />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.faq,
    element: <FAQ />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.privacy,
    element: <Privacy />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.search,
    element: <Search />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.contactUs,
    element: <ContactUs />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.aboutUs,
    element: <AboutUs />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.discover,
    element: <Discover />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.termsAndConditions,
    element: <TermsAndConditions />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.notFound,
    element: <NotFound />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.login,
    element: <NewLogin />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.auth,
    element: <Auth />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.agreement,
    element: <Agreement />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.galleryIntro,
    element: <GalleryIntro />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.galleries,
    element: <Galleries />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.artists,
    element: <Artists />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.createdArtist,
    element: <CreatedArtist />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.collectionNFTs,
    element: <CollectionNFTs />,
    injectRequests: null,
    access: false,
  },
  //* private routes
  {
    path: private_routes.editProfile,
    element: <EditProfile />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.activities,
    element: <Activities />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.wallet,
    element: <Payment />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.addCreditCard,
    element: <AddCreditCard />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.editCreditCard,
    element: <EditCreditCard />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.createNFT,
    element: <CreateNFT status={false} />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.editNFT(),
    element: <EditNFT status={false} />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.createNFTWithCollectionId(),
    element: <CreateNFT status={false} />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.gallery,
    element: <Gallery />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.createGallery,
    element: <CreateGallery />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.editGallery,
    element: <EditGallery />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.galleryArtist,
    element: <GalleryArtist />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.galleryRejection,
    element: <ViewRejectionReason />,
    injectRequests: null,
    access: true,
  },
  {
    path: private_routes.collectionActivity,
    element: <CollectionActivity />,
    injectRequests: null,
    access: true,
  },
  // {
  //   path: public_routes.theBezelClub,
  //   element: <TheBezelClub />,
  //   injectRequests: null,
  //   access: false,
  // },
  {
    path: public_routes.popularPartners,
    element: <PopularPartners />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.popularPartnerDetails(),
    element: <PopularPartnerDetails />,
    injectRequests: null,
    access: false,
  },
  {
    path: public_routes.partnerNftDetails(),
    element: <PartnerNFTDetails />,
    injectRequests: null,
    access: false,
  },
];
