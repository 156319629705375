import { Container } from "react-bootstrap";
import styled from "styled-components";
import leftArrow from "../assets/icons/left-arrow.svg";

const Wrapper = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid #e6e8ec;
  a {
    color: #777e90;
    font-size: 16px;
    text-decoration: none;
  }
  a:hover,
  a:active {
    color: #23262f;
  }
  .active {
    color: #23262f;
  }
`;

const Breadcrumbs = ({ links, className }) => {
  const linksTags = [];
  for (let i = 0; i < links?.length; i++) {
    if (i < links?.length - 1) {
      linksTags.push([
        <a href="#" className="me-4" key={`link-${i}`}>
          {links[i]}
        </a>,
        <img src={leftArrow} alt="" className="me-4" key={`arrow-${i}`} />,
      ]);
    } else {
      linksTags.push(
        <a href="#" className="active" key={`link-${i}`}>
          {links[i]}
        </a>
      );
    }
  }

  return (
    <Wrapper className={className}>
      <Container>{linksTags}</Container>
    </Wrapper>
  );
};

export default Breadcrumbs;
