import { Container, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import setUpWallet from "../assets/icons/set-up-wallet.svg";
import createCollection from "../assets/icons/create-collection.svg";
import addNft from "../assets/icons/add-nft.svg";
import sellNft from "../assets/icons/sell-nft.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  .main {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .header {
    font-size: 36px;
    text-align: center;
  }
  .subheader {
    font-weight: 450;
    color: #29367a;
  }
  .wrapper {
    background: #ffffff;
    // min-width: 256px;
    // max-width: 256px;
    border-radius: 12px;
    // margin-right: 20px;
    // margin-left: 20px;
    // box-shadow: 0px 20px 50px 0 rgba(18, 17, 39, 0.08);
    padding: 60px 15px 40px 15px;
  }
  @media screen and (max-width: 767px) {
    .wrapper {
      padding: 20px 15px 10px 15px;
    }
    .main {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
  .title {
    font-size: 18px;
    text-align: center;
  }
  .description {
    font-size: 16px;
    font-weight: 325;
    text-align: center;
  }
  .padding {
    padding: 24px 0;
  }
`;
const data = [
  {
    title: "create_sell.create_account",
    description: "create_sell.create_account_desc",
    icon: setUpWallet,
  },
  {
    title: "create_sell.create_nft",
    description: "create_sell.create_nft_desc",
    icon: createCollection,
  },
  {
    title: "create_sell.upload_nft",
    description: "create_sell.upload_nft_desc",
    icon: addNft,
  },
  {
    title: "create_sell.list_nft",
    description: "create_sell.list_nft_desc",
    icon: sellNft,
  },
];
const CreateAndSellNFT = () => {
  const { t } = useTranslation();
  const { account, authenticated, progress } = useSelector(
    (state) => state.auth
  );
  return (
    <>
      {data && (
        <section id="create">
          <Wrapper>
            <Container fluid="lg" className="main">
              <div className="d-flex justify-content-center">
                <div className="header">
                  {t("create_sell.title")}
                  {/* <span className="subheader">{t(" NFTs")}</span> */}
                </div>
              </div>
              <Row className="px-5">
                {data.map(({ title, description, icon }) => (
                  <Col
                    key={Math.random()}
                    className="wrapper"
                    xl={3}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                  >
                    <div className="padding">
                      <span className="d-flex justify-content-center">
                        <img src={icon} alt="" width="50" height="50" />
                      </span>
                      <div className="title padding">{t(title)}</div>
                      <div className="description">{t(description)}</div>
                    </div>
                  </Col>
                ))}
                <div className="d-flex justify-content-center">
                  {authenticated ? (
                    // Upload
                    <Link to="/nft/create">
                      <div className="mx-0 btn btn-primary">{t("Create")}</div>
                    </Link>
                  ) : (
                    // Register
                    <Link
                      to="/login"
                      className="mx-0 mx-md-2 my-2 my-md-0 btn btn-primary"
                    >
                      {t("Register")}
                    </Link>
                  )}
                </div>
              </Row>
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

export default CreateAndSellNFT;
