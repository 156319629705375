import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import following from "../assets/icons/activities/following.svg";
import accept from "../assets/icons/activities/accept.svg";
import decline from "../assets/icons/activities/decline.svg";
import threeDots from "../assets/icons/activities/three-dots.svg";

const Wrapper = styled.div`
  position: relative;
  border-radius: 24px;
  &:hover {
    background-color: #f4f5f6;
  }
  .action-btn-container {
    opacity: 0;
  }
  &:hover .action-btn-container {
    opacity: 100;
  }
  &:hover .not-read {
    display: none;
  }
  .activity-image {
    border-radius: 50%;
    width: 96px;
    height: 96px;
    object-fit: cover;
  }
  .icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    .activity-image {
      width: 64px;
      height: 64px;
    }
    .icon {
      width: 24px;
      height: 24px;
    }
  }
  .activity-type-icon {
    background-color: black;
  }
  .timing {
    color: #777e91;
    font-weight: 600;
    font-size: 14px;
  }
  .accept-decline-btn {
    background-color: transparent;
    border: 1px solid #e6e8ec;
  }
  .accept-decline-btn:hover {
    border: 1px solid #b8babd;
  }
  .action-link {
    font-weight: 600;
  }
  .not-read {
    width: 12px;
    height: 12px;
    background-color: #6d3190;
    border-radius: 50%;
  }
  .action-link:hover {
    color: #6d3190;
    background-color: #f4f5f6;
  }
  @media (max-width: 992px) {
    .not-read {
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
  }
`;

const Activity = () => {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper className="p-3 d-flex align-items-center">
      <div className="position-relative me-4">
        <div className="activity-type-icon icon position-absolute end-0">
          <img src={following} alt="" />
        </div>
        <img src="/thumb-3.jpg" alt="" className="activity-image" />
      </div>
      <div>
        <h4 className="mb-1">ETH {t("received")}</h4>
        <p className="my-0">0.08 ETH {t("received")}</p>
        <p className="timing my-0">2 {t("days ago")}</p>
      </div>
      {/* accept/decline large screen */}
      <div className="ms-auto d-none d-lg-flex action-btn-container">
        <button className="icon accept-decline-btn me-2">
          <img src={decline} alt="" />
        </button>
        <button className="icon accept-decline-btn">
          <img src={accept} alt="" />
        </button>
      </div>
      {/* not read */}
      <div className="not-read"></div>
      {/* accept/decline mobile screen */}
      <div className="ms-auto d-lg-none">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
            <img src={threeDots} alt="" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="rounded">
            <Dropdown.Item className="action-link" href="#">
              {t("Accept")}
            </Dropdown.Item>
            <Dropdown.Item className="action-link" href="#">
              {t("Reject")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Wrapper>
  );
};

export default Activity;
