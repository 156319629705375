import React, { useEffect } from "react";
import { Table, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import NoDataImage from "@assets/images/noDataNftDetails.png";
import CopyWallet from "../components/CopyWallet";
import InfiniteScroll from "react-infinite-scroll-component";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { offerActionsCreators } from "../store";

import styled from "styled-components";

const StylesWrapper = styled.div`
  .offer-label {
    font-size: 16px;
    color: ${(props) => props?.colors?.text};
  }
  .offer-image {
    object-fit: cover;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .offer-name {
    font-size: 16px;
    font-weight: 325;
    margin-inline-start: 10px;
    color: ${(props) => props?.colors?.text};
  }
  .offer-wallet {
    font-size: 16px;
    font-weight: 325;
    margin-inline-end: 5px;
    color: ${(props) => props?.colors?.text};
  }
  .copy-icon {
    margin-bottom: 7px;
  }
  .offers-nodata {
    font-size: 15px;
    color: ${(props) => props?.colors?.label};
  }
`;
const NFTOffers = ({
  nft,
  isOwner,
  isProject = false,
  setSelectedOffer,
  setShowAcceptOfferModal,
  colors,
  externalNFT,
}) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { getOffersCreator } = bindActionCreators(
    offerActionsCreators,
    dispatch
  );
  const { offers, has_more } = useSelector((state) => state.offers);

  const getMoreOffers = () => {
    getOffersCreator(
      nft?._id,
      { skip: offers?.length, limit: 10 },
      true,
      isProject ? "project" : "nft"
    );
  };
  return (
    <StylesWrapper colors={colors}>
      {nft?.offers?.length == 0 ||
      nft?.owner_wallet === offers[0]?.user?.create_wallet ? (
        <div style={{ textAlign: "center" }}>
          <img src={NoDataImage} alt="no data" height="155" />
          <p className="offers-nodata">{t("no_offers")}</p>
        </div>
      ) : (
        <InfiniteScroll
          scrollableTarget="details-table"
          style={{
            width: "100%",
            overflow: "none",
          }}
          dataLength={offers?.length}
          next={getMoreOffers}
          hasMore={has_more}
          loader={
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" size="sm" />
            </div>
          }
          endMessage=""
          scrollThreshold={0.8}
        >
          <Table borderless>
            <thead className="d-none d-lg-block">
              <tr>
                <td className="owner-label" style={{ paddingRight: "130px" }}>
                  {t("user_name")}
                </td>
                <td className="owner-label" style={{ paddingRight: "140px" }}>
                  {t("wallet_address")}
                </td>
                <td className="owner-label" style={{ paddingRight: "55px" }}>
                  {t("Price")}
                </td>
                {isOwner && <td className="owner-label">{t("Action")}</td>}
              </tr>
            </thead>

            <tbody>
              {nft?.offers.map((offer, index) => {
                return (
                  offer.user.create_wallet !== nft.owner.create_wallet && (
                    <>
                      <table className="table d-block d-lg-none">
                        <tbody>
                          <tr key={index}>
                            <td colspan="2" className="owner-label">
                              {t("user_name")}{" "}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <div>
                                <img
                                  className="offer-image"
                                  alt={offer?.user?.name || "Anonymous"}
                                  src={
                                    offer?.user?.profile?.image_url ||
                                    "/user-placeholder.png"
                                  }
                                />
                                <span className="offer-name">
                                  {offer?.user?.name || "Anonymous"}
                                </span>
                              </div>
                            </td>
                          </tr>
                          <tr key={index}>
                            <td colspan="2" className="owner-label">
                              {t("wallet_address")}
                            </td>
                            <td
                              style={{ verticalAlign: "middle", width: "45%" }}
                            >
                              <div className="mt-1">
                                <CopyWallet
                                  fullWallet={false}
                                  id={`6${index}`}
                                  address={
                                    offer?.user?.create_wallet ||
                                    nft?.owner_wallet
                                  }
                                />
                              </div>
                            </td>
                          </tr>

                          <tr key={index}>
                            <td colspan="2" className="owner-label">
                              {t("Price")}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <span className="offer-price">
                                {offer.price} {offer.payment_method}
                              </span>
                            </td>
                          </tr>
                          <tr key={index}>
                            <td colspan="2" className="owner-label">
                              {t("Action")}{" "}
                            </td>
                            {isOwner && (
                              <td style={{ verticalAlign: "middle" }}>
                                <div className="mt-1">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                      setShowAcceptOfferModal();
                                      setSelectedOffer(offer);
                                    }}
                                  >
                                    {t("Accept")}
                                  </button>
                                </div>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                      <tr key={index} className="d-none d-lg-block">
                        <td style={{ verticalAlign: "middle" }}>
                          <div>
                            <img
                              className="offer-image"
                              alt={offer?.user?.name || "Anonymous"}
                              src={
                                offer?.user?.profile?.image_url ||
                                "/user-placeholder.png"
                              }
                            />
                            <span className="offer-name">
                              {offer?.user?.name || "Anonymous"}
                            </span>
                          </div>
                        </td>

                        <td style={{ verticalAlign: "middle", width: "50%" }}>
                          <div className="mt-1">
                            <CopyWallet
                              fullWallet={false}
                              id={`66${index}`}
                              offersCut={true}
                              address={
                                offer?.user?.create_wallet || nft?.owner_wallet
                              }
                            />
                          </div>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <span className="offer-price">
                            {offer.price} {offer.payment_method}
                          </span>
                        </td>
                        {isOwner && (
                          <td style={{ verticalAlign: "middle" }}>
                            <div className="mt-1">
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  setShowAcceptOfferModal();
                                  setSelectedOffer(offer);
                                }}
                              >
                                {t("Accept")}
                              </button>
                            </div>
                          </td>
                        )}
                      </tr>
                    </>
                  )
                );
              })}
            </tbody>
          </Table>
        </InfiniteScroll>
      )}
    </StylesWrapper>
  );
};

export default NFTOffers;
