import { Row, Col } from "react-bootstrap";
import styled from "styled-components";

export const StylesWrapper = styled("div")`
  color: ${(props) => props?.colors?.text};
  background-color: ${(props) => props?.colors?.bg};
  .loading {
    padding: 50px;
  }

  .expand {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #bebebe;
    border-radius: 50%;
    height: 48px;
    width: 48px !important;
    margin-inline-end: 15px;
    background-color: ${(props) => props?.colors?.bg};
    &:hover {
      background-color: ${(props) => props?.colors?.bg};
    }
    &:focus {
      background-color: ${(props) => props?.colors?.bg};
    }
  }
  .media-bg {
    height: 750px;
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .main-bg {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // margin-left: auto;
    // margin-right: auto;
    background-color: red;
  }
  .details-table {
    height: 450px;
    flex: 1.8;
    border: 1px solid #dadada;
    border-radius: 16px;
    margin: 10px;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .details-table-2 {
    flex: 1;
    border: 1px solid #dadada;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin: 10px;
    padding: 20px 20px 20px 20px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .title {
    color: #23262f;
    font-size: 24px;
    font-weight: 400;
    margin-inline-start: 30px;
  }
  .details-table-3 {
    flex-basis: 100%;
    border: 1px solid #dadada;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin: 10px;
    height: 400px;
    padding: 20px 20px 20px 20px;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  @media (max-width: 768px) {
    .details-table-2 {
      width: 100%;
    }
  }
  .details-table::-webkit-scrollbar {
    display: none;
  }
  .details-table-2::-webkit-scrollbar {
    display: none;
  }
  .details-table-3::-webkit-scrollbar {
    display: none;
  }
  .flexbox {
    display: flex;
    overflow: hidden;
    display: -webkit-flex;
    display: -ms-flexbox;
    height: ${(props) => (props?.isAuction ? "672px" : "auto")};
  }
`;
