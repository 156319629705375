import React from "react";
import { Accordion } from "react-bootstrap";
import { CheckBoxProp } from "./CheckBoxProp";

export const AccordionProp = ({prop, index}) => {
  return (
    <Accordion.Item eventKey={`${index}`}>
      <Accordion.Header>{prop?.name}</Accordion.Header>
      <Accordion.Body>
        {prop?.properties_list?.map((el, i) => (
          <CheckBoxProp key={i} prop={el} name={prop?.name} />
        ))}
      </Accordion.Body>
    </Accordion.Item>
  );
};
