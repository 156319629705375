import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  .subtitle {
    font-size: 26px;
    font-weight: 400;
  }
  .terms-label {
    color: #777e91;
    font-size: 12px;
    text-transform: uppercase;
  }
  .terms-header {
    font-size: 48px;
    margin: -8px 0 24px 0;
  }
  .terms {
    color: #777e90;
    font-weight: 500;
    margin-bottom: 32px;
  }
`;

export const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <Container className="pb-5">
        <div className="mt-5 mb-4 page-header">{t("terms_footer")}</div>
        <Row className="align-items-center pb-5">
          <Col>
            <div className="subtitle">{t("Nifty Souq Terms of Use")}</div>
            <p>{t("NIFTY Souq Terms of Use Last revised: FEB 24, 2022")}</p>
            <p>{t("terms_p")}</p>
            <div className="subtitle">{t("1) Accepting these Terms")}</div>
            <p>{t("terms_1_1")}</p>
            <p>{t("terms_1_2")}</p>
            <p>{t("terms_1_3")}</p>
            <div className="subtitle">{t("2) Your Nifty Souq Account")}</div>
            <p>{t("terms_2_1")}</p>
            <p>{t("terms_2_2")}</p>
            <ul>
              <li>{t("terms_2_3")}</li>
              <li>{t("terms_2_4")}</li>
              <li>{t("terms_2_5")}</li>
              <li>{t("terms_2_6")}</li>
              <li>{t("terms_2_7")}</li>
              <li>{t("terms_2_8")}</li>
              <li>{t("terms_2_9")}</li>
            </ul>
            <div className="subtitle">{t("3) Account Suspension")}</div>
            <p>{t("terms_3_1")}</p>
            <p>{t("terms_3_2")}</p>
            <div className="subtitle">{t("4) Communication")}</div>
            <p>{t("terms_4_1")}</p>
            <p>{t("terms_4_2")}</p>
            <p>{t("terms_4_3")}</p>
            <div className="subtitle">{t("5) User Content")}</div>
            <p>{t("terms_5_1")}</p>
            <p>{t("terms_5_2")}</p>
            <p>{t("terms_5_3")}</p>
            <p>{t("terms_5_4")}</p>
            <ul>
              <li>{t("terms_5_5")}</li>
              <li>{t("terms_5_6")}</li>
              <li>{t("terms_5_7")}</li>
              <li>{t("terms_5_8")}</li>
            </ul>
            <p>{t("terms_5_9")}</p>
            <ul>
              <li>{t("terms_5_10")}</li>
              <li>{t("terms_5_11")}</li>
              <li>{t("terms_5_12")}</li>
              <li>{t("terms_5_13")}</li>
              <li>{t("terms_5_14")}</li>
            </ul>
            <p>{t("terms_5_15")}</p>
            <div className="subtitle">{t("6) Ownership")}</div>
            <p>{t("terms_6_1")}</p>
            <p>{t("terms_6_2")}</p>
            <p>{t("terms_6_3")}</p>
            <div className="subtitle">{t("7) Terms of Sale")}</div>
            <p>{t("terms_7_1")}</p>
            <p>{t("terms_7_2")}</p>
            <p>{t("terms_7_3")}</p>
            <div className="subtitle">{t("8) Intellectual Property")}</div>
            <p>{t("terms_8_1")}</p>
            <p>{t("terms_8_2")}</p>
            <p>{t("terms_8_3")}</p>
            <div className="subtitle">{t("9) Your Use of Nifty Souq")}</div>
            <p>{t("terms_9_1")}</p>
            <ul>
              <li>{t("terms_9_2")}</li>
              <li>{t("terms_9_3")}</li>
              <li>{t("terms_9_4")}</li>
              <li>{t("terms_9_5")}</li>
              <li>{t("terms_9_6")}</li>
              <li>{t("terms_9_7")}</li>
              <li>{t("terms_9_8")}</li>
              <li>{t("terms_9_9")}</li>
              <li>{t("terms_9_10")}</li>
              <li>{t("terms_9_11")}</li>
            </ul>
            <p>{t("terms_9_12")}</p>
            <div className="subtitle">{t("10) Accidental Transfers")}</div>
            <p>{t("terms_10_1")}</p>
            <p>{t("terms_10_2")}</p>
            <div className="subtitle">{t("11) Privacy")}</div>
            <p>{t("terms_11_1")}</p>
            <div className="subtitle">{t("12) Modifications")}</div>
            <p>{t("terms_12_1")}</p>
            <div className="subtitle">{t("13) Risks")}</div>
            <p>{t("terms_13_1")}</p>
            <p>{t("terms_13_2")}</p>
            <div className="subtitle">{t("14) Third Parties")}</div>
            <p>{t("terms_14_1")}</p>
            <p>{t("terms_14_2")}</p>
            <p>{t("terms_14_3")}</p>
            <div className="subtitle">{t("15) External Projects")}</div>
            <p>{t("terms_15_1")}</p>
            <ol>
              <li>{t("terms_15_2")}</li>
              <li>{t("terms_15_3")}</li>
              <li>{t("terms_15_4")}</li>
            </ol>
            <div className="subtitle">{t("16) External Listings")}</div>
            <p>{t("terms_16_1")}</p>
            <p>{t("terms_16_2")}</p>
            <p>{t("terms_16_3")}</p>
            <p>{t("terms_16_4")}</p>
            <div className="subtitle">{t("17) Disclaimers")}</div>
            <p>{t("terms_17_1")}</p>
            <p>{t("terms_17_2")}</p>
            <p>{t("terms_17_3")}</p>
            <p>{t("terms_17_4")}</p>
            <p>{t("terms_17_5")}</p>
            <p>{t("terms_17_6")}</p>
            <p>{t("terms_17_7")}</p>
            <p>{t("terms_17_8")}</p>
            <p>{t("terms_17_9")}</p>
            <div className="subtitle">{t("18) Limitation of Liability")}</div>
            <p>{t("terms_18_1")}</p>
            <p>{t("terms_18_2")}</p>
            <p>{t("terms_18_3")}</p>
            <div className="subtitle">{t("19) Indemnification")}</div>
            <p>{t("terms_19_1")}</p>
            <div className="subtitle">{t("20) Governing Law")}</div>
            <p>{t("terms_20_1")}</p>
            <div className="subtitle">{t("21) Disputes")}</div>
            <p>{t("terms_21_1")}</p>
            <p>{t("terms_21_2")}</p>
            <p>{t("terms_21_3")}</p>
            <p>{t("terms_21_4")}</p>
            <p>{t("terms_21_5")}</p>
            <p>{t("terms_21_6")}</p>
            <p>{t("terms_21_7")}</p>
            <div className="subtitle">{t("22) Termination")}</div>
            <p>{t("terms_22_1")}</p>
            <div className="subtitle">{t("23) Severability")}</div>
            <p>{t("terms_23_1")}</p>
            <p>{t("terms_23_2")}</p>
            <p>{t("terms_23_3")}</p>
            <div className="subtitle">{t("24) Survival")}</div>
            <p>{t("terms_24_1")}</p>
            <div className="subtitle">{t("25) Contact Information")}</div>
            <p>{t("terms_25_1")}</p>
            <p>{t("terms_25_2")}</p>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
