import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const CreatorName = ({ item }) => {
  const { t } = useTranslation();
  return (
    <>
      {item?.artist?.length > 0 ? (
        <>
          {item?.artist?.map((artist, index) => {
            return (
              <Link
                key={index}
                to={`/created-artist/${artist?._id}`}
                className="d-flex align-items-center link"
              >
                <img
                  src={artist?.profileImage || "/user-placeholder.png"}
                  alt="user"
                  className="me-3 user-40-rounded img"
                  i="true"
                />
                <span className="creator-box">
                  <span>{artist?.name}</span>
                  <span className="d-block text-muted">{t("Creator")}</span>
                </span>
              </Link>
            );
          })}
        </>
      ) : (
        <Link
          to={`/profile/${item?.creators?.minter._id}`}
          className="d-flex align-items-center link"
        >
          <img
            src={
              item?.creators?.minter?.profile?.image_url ||
              "/user-placeholder.png"
            }
            alt="user"
            className="me-3 user-40-rounded img"
            i="true"
          />
          <span className="creator-box">
            <span>{item?.creators?.minter?.name}</span>
            <span className="d-block text-muted">{t("Creator")}</span>
          </span>
        </Link>
      )}
    </>
  );
};
