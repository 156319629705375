import { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import wallet from "@assets/icons/copy-wallet.svg";
import NFT1155Options from "../NFT1155Options";
import StylesWrapper from "./styles";
import CopyWallet from "../../../../components/CopyWallet";

const NFTListing = ({
  nft,
  colors,
  externalNFT,
  onOpenModal,
  setSelectedOwner,
  setConnectWalletModal,
}) => {
  const { t, i18n } = useTranslation();

  const auth = useSelector((state) => state.auth);
  const modals = {
    nft,
    colors,
    onOpenModal,
    setConnectWalletModal,
  };
  const Row = ({ owner, index, listed }) => {
    const isOwner = auth?.account?.wallets.includes(owner?.user?.create_wallet);
    return (
      <tr key={index}>
        <td style={{ verticalAlign: "middle" }}>
          <img
            className="owner-image"
            alt={owner?.user?.name || "Anonymous"}
            src={owner?.user?.profile?.image_url || "/user-placeholder.png"}
          />
          <span className="owner-name">{owner?.user?.name || "Anonymous"}</span>
        </td>
        <td style={{ verticalAlign: "middle", width: "45%" }}>
          <CopyWallet
            fullWallet={true}
            address={owner?.user?.create_wallet || nft?.owner_wallet}
            id={"7"}
          />
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <span className="owner-text">
            {owner?.listed_editions?.length + owner?.unlisted_editions?.length}{" "}
            / {nft?.editions?.total}
          </span>
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <span className="owner-text">
            {owner?.listed_editions?.length > 0 ? owner?.price : 0}{" "}
            {nft?.listing?.currency}
          </span>
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <span className="owner-status cursor">
            {listed ? (
              isOwner ? (
                <>
                  <NFT1155Options
                    listed
                    {...modals}
                    index={index}
                    setSelectedOwner={() => setSelectedOwner(owner)}
                  />
                </>
              ) : (
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    setSelectedOwner(owner);
                    onOpenModal("buy-now");
                  }}
                >
                  {t("Buy")}
                </div>
              )
            ) : isOwner ? (
              <>
                <NFT1155Options
                  {...modals}
                  index={index}
                  listed={false}
                  setSelectedOwner={() => setSelectedOwner(owner)}
                />
              </>
            ) : (
              <div> {t("Not for sale")}</div>
            )}
          </span>
        </td>
      </tr>
    );
  };
  const isGeneralOwner = auth?.account?.wallets.some((el) =>
    nft?.owners?.some((e) => el == e?.user?.create_wallet)
  );
  return (
    <StylesWrapper colors={colors}>
      <Table borderless>
        <thead>
          <tr>
            <td className="owner-label">{t("owned_by")}</td>
            <td className="owner-label">{t("wallet_address")}</td>
            <td className="owner-label">{t("edition")}</td>
            <td className="owner-label">{t("Price")}</td>
            <td className="owner-label">{t("Status")}</td>
          </tr>
        </thead>
        <tbody>
          {nft?.owners.map((owner, index) => {
            const isListed = owner?.listed_editions?.length > 0;
            const isUnListed = owner?.unlisted_editions?.length > 0;

            return (
              <>
                {isListed && <Row owner={owner} index={index} listed />}
                {isUnListed && isGeneralOwner && (
                  <Row owner={owner} index={index} listed={false} />
                )}
              </>
            );
          })}
        </tbody>
      </Table>
    </StylesWrapper>
  );
};

export default NFTListing;
