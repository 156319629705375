import { useState } from "react";
//bootstrap
import { Button, Form, Image, Spinner, InputGroup } from "react-bootstrap";
//web3
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
//config and library
import { chains } from "@config";
import useJwt from "@library/jwt/useJwt";
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
import { useTranslation } from "react-i18next";
import { offerActionsCreators } from "../store";
//icons
import ModalAndOverlay from "./ModalAndOverlay";
import errorIcon from "@assets/images/icons/error.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import WalletModal from "./WalletModal";
import { Checkout } from "./Modals";
import Swal from "sweetalert2";
import WalletDropDownList from "./WalletDropDownList";

const MakeOfferModal = (props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [price, setPrice] = useState();
  const [status, setStatus] = useState(0);
  const [amount, setAmount] = useState(1);
  const [response, setResponse] = useState({});
  const [error, setError] = useState("");
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [selectedWallet, setSelectWallet] = useState(props.selectedWallet);
  const { chain } = useWeb3Auth();
  const { placeOffer } = useWeb3Context();
  const { makeOfferCreator } = bindActionCreators(
    offerActionsCreators,
    dispatch
  );
  const { default_wallet, another_wallet } = useSelector(
    (state) => state.wallets
  );
  const { nft, isProject, closeOfferModal } = props;

  const is1155 = nft?.token_standard === "1155";

  const makeNFTOffer = async () => {
    try {
      setStatus(1);
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );

      const offerAmount = web3.utils.toWei(price.toString());

      const makeOfferData = {
        tokenAddress: nft?.contract,
        tokenId: nft?.token_id,
        quantity: amount,
        price: offerAmount,
        currency: nft?.blockchain === "ETH" ? "WETH" : "WMATIC",
      };

      const res = await placeOffer(
        makeOfferData,
        offerAmount,
        selectedWallet === props.defaultWallet
      );

      // save to backend
      if (res.status) {
        const offerData = {
          nft_id: nft._id,
          wallet: selectedWallet,
          tx: res.data.transactionHash,
          price,
          offer_id: res.data.events.eMakeOffer.returnValues.makeOfferId,
          payment_method: nft?.blockchain === "ETH" ? "WETH" : "WMATIC",
        };
        //setResponse(res.data);
        makeOfferCreator(offerData, isProject ? "project" : "nft");
        setStatus(2);
      } else {
        setStatus(3);
        setError(res.data);
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("transaction_failed"),
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
        props.closeOfferModal();
      }
    } catch (e) {
      setStatus(3);
      setError(e);
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("sending_offer_failed"),
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      props.closeOfferModal();
    }
  };
  const validationPriceInput = () => {
    return parseFloat(price) !== 0 || !isNaN(price) || price !== "";
  };
  return (
    <div>
      {connectWalletModal && (
        <WalletModal
          colors={props.colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}
      <ModalAndOverlay>
        <Checkout color={props?.colors}>
          <div className="d-flex justify-content-between mb-2">
            <h3 className="modal-heading">{t("make_an_offer")}</h3>
            <CloseIcon
              className="modal-close-icon"
              color={props.colors?.text1}
              onClick={closeOfferModal}
            />
          </div>
          {status === 0 ? (
            <>
              <p style={{ fontWeight: 350 }}>
                {t("you_are_about_to_make_offer")}
                <span className="mx-1">{nft?.name}</span>
              </p>
              <Form.Group controlId="price" className="mb-4">
                <Form.Label>
                  <div style={{ fontWeight: 400, fontSize: 16 }}>
                    {t("offer_price")}
                  </div>
                </Form.Label>
                <InputGroup size="md">
                  <Form.Control
                    type="text"
                    value={price}
                    placeholder={t("Price")}
                    className="create-page-input-field"
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  <InputGroup.Text>
                    {nft?.blockchain === "ETH" ? "wETH" : "wMATIC"}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              {is1155 && (
                <>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <strong>{t("Amount")}</strong>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      step={1}
                      min={1}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder={t("amount_placeholder")}
                    />
                  </Form.Group>
                </>
              )}
              <Form.Group className="mb-5" controlId="formBasicEmail">
                <Button
                  className="modal-button mb-2 mt-2"
                  onClick={() => setConnectWalletModal(true)}
                >
                  {props.walletOptions?.length > 1
                    ? t("External wallet connected")
                    : t("Connect external wallet")}
                </Button>
                <Form.Label className="mt-3">
                  <div>{t("Wallets")}</div>
                </Form.Label>
                <Form.Select
                  className="mb-3"
                  style={{
                    color: props?.colors?.text,
                    backgroundColor: props?.colors?.bg,
                  }}
                  value={selectedWallet}
                  onChange={(e) => setSelectWallet(e.target.value)}
                >
                  <WalletDropDownList />
                </Form.Select>
              </Form.Group>
            </>
          ) : (
            <>
              <div className="mb-4 pb-2">
                {status == 3 && (
                  <div className="mb-3">
                    <h4>{t("transaction_failed")}</h4>
                    <span>{error.toString()}</span>
                  </div>
                )}
                <div className="d-flex align-items-center mb-3">
                  <div>
                    {status === 1 ? (
                      <Spinner animation="border" variant="primary" />
                    ) : status === 3 ? (
                      <Image src={errorIcon}></Image>
                    ) : (
                      <Image src={completedIcon}></Image>
                    )}
                  </div>
                  &nbsp;&nbsp;
                  <div>
                    <h5 className="m-0 modal-section-title">
                      <strong>{t("send_offer")}</strong>
                    </h5>
                    <p className="m-0 modal-section-text">
                      {t("sending_your_offer")}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          <Button
            className="modal-button mb-3"
            disabled={status === 1}
            onClick={status == 0 ? () => makeNFTOffer() : closeOfferModal}
            style={
              validationPriceInput() && selectedWallet
                ? {}
                : {
                    pointerEvents: "none",
                    cursor: "not-allowed",
                    opacity: "0.65",
                  }
            }
          >
            {status === 0
              ? t("Confirm")
              : status === 1
              ? t("Calling...")
              : t("Done")}
          </Button>
          <Button
            onClick={closeOfferModal}
            className="modal-button modal-outline-button"
          >
            {t("Cancel")}
          </Button>
        </Checkout>
      </ModalAndOverlay>
    </div>
  );
};

export default MakeOfferModal;
