const FILE_SIZE = 100 * 1048576; // 100 MB
const FILE_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/webp",
  "image/png",
  "audio/mp3",
  "audio/mpeg",
  "video/mp4",
];

const keys = {
  // +ENV_NAME: ENV_NAME,
  // +FRONT_END_URL: FRONT_END_URL,
  // +API_URL: API_URL,
  // +DOMAIN: DOMAIN,
  // +WEB3AUTH_KEY: WEB3AUTH_KEY,

  // +INFURA_ID: INFURA_ID,
  // +INFURA_RPC_URL: INFURA_RPC_URL,
  // +INFURA_RPC_NAME: INFURA_RPC_NAME,
  // +INFURA_BLOCKEXPLORER: INFURA_BLOCKEXPLORER,
  // +INFURA_BLOCKEXPLORER_MATIC: INFURA_BLOCKEXPLORER_MATIC,
  // +INFURA_LOGIN_NETWORK: INFURA_LOGIN_NETWORK,

  // TODO: not used????: INFURA_IPFS,
  // TODO: not used????: INFURA_IPFS_ID,
  // TODO: not used????: INFURA_IPFS_SECRET,

  // +BLOCKEXPLORER_BOBA: BLOCKEXPLORER_BOBA,
  // +BLOCKEXPLORER_BNB: BLOCKEXPLORER_BNB,

  // +CHAIN_ID_ETH: CHAIN_ID_ETH,
  // +CHAIN_ID_MATIC: CHAIN_ID_MATIC,
  // +CHAIN_ID_BOBA: CHAIN_ID_BOBA,
  // +CHAIN_ID_BNB: CHAIN_ID_BNB,
  // +WEB3AUTH_CHAIN_ID: WEB3AUTH_CHAIN_ID,

  // +RPC_URL_ETH: RPC_URL_ETH,
  // +RPC_URL_MATIC: RPC_URL_MATIC,
  // +RPC_URL_BOBA: RPC_URL_BOBA,
  // +RPC_URL_BNB: RPC_URL_BNB,

  // +ETH_NET_NAME: ETH_NET_NAME,
  // +MATIC_NET_NAME: MATIC_NET_NAME,
  // +BOBA_NET_NAME: BOBA_NET_NAME,
  // +BNB_NET_NAME: BNB_NET_NAME,

  // +WS_ETH: WS_ETH,
  // +WS_MATIC: WS_MATIC,
  // +WS_BOBA: WS_BOBA,
  // +WS_BNB: WS_BNB,

  // +ADDRESSES: ADDRESSES,

  // +WERT_PARTNER_ID: WERT_PARTNER_ID,
  // +WERT_ORIGIN: WERT_ORIGIN,
  // TODO: not used???? // WERT_PK: WERT_PK,

  // TODO: not used???? // MORALIS_API_KEY,

  // +POLYGON_SCAN,
  // +ETH_SCAN,

  // +PUSHER_KEY,
  // +PUSHER_CLUSTER,

  // +WETH_ADDRESS,
  // +WMATIC_ADDRESS,
  // +USDC_ETH_ADDRESS,
  // +USDC_MATIC_ADDRESS,
  // +BEZEL_SELLER_ADDRESS,

  // +ALGOLIA_INDEX,
  // +ALGOLIA_APP_ID,
  // +ALGOLIA_API_KEY,
  // +ALGORAND_API_KEY,

  // +STRIPE_PUBLISH_KEY,
  // +SENTRY_DNS_URL
  // +GAS_LIMIT,

  FILE_SIZE: FILE_SIZE,
  FILE_SUPPORTED_FORMATS

};

export default keys;
