import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
  border: 1px solid #e6e8ec;
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
  width: 350px;
  padding: 48px;
  border-radius: 32px;
  .form-check-input {
    width: 20px !important;
    margin-right: 16px;
  }
  .form-check-label {
    font-weight: 600;
  }
  .buttons-container {
    border-top: 1px solid #e6e8ec;
    padding-top: 32px;
  }
`;

const checkItems = [
  "Sales",
  "Listings",
  "Bids",
  "Burns",
  "Followings",
  "Likes",
  "Purchase",
  "Transfers",
];

const Filter = () => {
  const { t, i18n } = useTranslation();

  return (
    <Wrapper>
      <h3 className="mb-4">{t("Filters")}</h3>
      <Form>
        {checkItems.map((item, key) => {
          return (
            <Form.Check
              type="checkbox"
              key={key}
              id={`default-${item}`}
              label={item}
              className="mb-4"
            />
          );
        })}
        <div className="d-flex justify-content-between buttons-container">
          <Button className="modal-outline-button">{t("Select all")}</Button>
          <Button className="modal-outline-button">{t("Unselect all")}</Button>
        </div>
      </Form>
    </Wrapper>
  );
};

export default Filter;
