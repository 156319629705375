import * as nftTypes from "../actions/types/nft";
import * as profileTypes from "../actions/types/profile";
import * as socketTypes from "../actions/types/socket";

const latestNFTs = {
  loading: false,
  nfts: [],
  error: null,
};

const linkedNftDetails = {
  loading: false,
  nft: null,
  error: null,
};

const nftDetails = {
  loading: false,
  nft: null,
  error: null,
};

const nftAuction = {
  loading: false,
  nft: null,
  error: null,
};

const featuredNFTs = {
  loading: false,
  nft: null,
  error: null,
};

const nftActions = {
  loading: false,
  success: false,
  status: 0,
  data: null,
  error: false,
  action: null,
  progress: 0,
  done: false,
  ipfs_path: "",
};

const externalNFTs = {
  loading: false,
  nfts: [],
  has_more: true,
  chain: "polygon",
  error: null,
  cursor: null,
};

const allNfts = {
  loading: false,
  nfts: [],
  count: 0,
  has_more: true,
  error: null,
};

const profileNFTs = {
  loading: false,
  created_nfts: [],
  collected_nfts: [],
  liked_nfts: [],
  error: null,
  has_more_created: true,
  has_more_collected: true,
  has_more_liked: true,
};

export const profileNFTsReducer = (state = profileNFTs, action) => {
  switch (action.type) {
    //* loading
    case nftTypes.GET_USER_CREATED_NFTS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_USER_COLLECTED_NFTS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_USER_LIKED_NFTS_LOADING:
      return { ...state, loading: true };
    //* success
    case nftTypes.GET_USER_CREATED_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        created_nfts: [...state.created_nfts, ...action.payload],
        has_more_created: action.has_more,
        error: null,
      };
    case nftTypes.GET_USER_COLLECTED_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        collected_nfts: [...state.collected_nfts, ...action.payload],
        has_more_collected: action.has_more,
        error: null,
      };
    case nftTypes.GET_USER_LIKED_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        liked_nfts: [...state.liked_nfts, ...action.payload],
        has_more_liked: action.has_more,
        error: null,
      };
    //* profile
    case profileTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        created_nfts: action.payload.created_nfts,
        collected_nfts: action.payload.collected_nfts,
        liked_nfts: action.payload.liked_nfts,
        has_more_created: action.payload.created_nfts?.length === 16,
        has_more_collected: action.payload.collected_nfts?.length === 16,
        has_more_liked: action.payload.liked_nfts?.length === 16,
        error: null,
      };
    //* error
    case nftTypes.GET_USER_CREATED_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    case nftTypes.GET_USER_COLLECTED_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    case nftTypes.GET_USER_LIKED_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const featuredNFTsReducer = (state = featuredNFTs, action) => {
  switch (action.type) {
    case nftTypes.GET_FEATURED_NFTS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_FEATURED_NFTS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case nftTypes.GET_FEATURED_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const latestNFTsReducer = (state = latestNFTs, action) => {
  switch (action.type) {
    case nftTypes.GET_LATEST_NFTS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_LATEST_NFTS_SUCCESS:
      return { ...state, loading: false, nfts: action.payload, error: null };
    case nftTypes.GET_LATEST_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const linkedNftDetailsReducer = (state = linkedNftDetails, action) => {
  switch (action.type) {
    case nftTypes.GET_LINKED_NFT_DETAILS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_LINKED_NFT_DETAILS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case nftTypes.GET_LINKED_NFT_DETAILS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const nftDetailsReducer = (state = nftDetails, action) => {
  switch (action.type) {
    case nftTypes.GET_NFT_DETAILS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_NFT_DETAILS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case nftTypes.GET_NFT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        error_status: action.error_status,
      };
    case nftTypes.GET_EDIT_NFT_DETAILS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case nftTypes.GET_EDIT_NFT_DETAILS_LOADING:
      return { ...state, loading: true, error: action.error };
    case nftTypes.GET_EDIT_NFT_DETAILS_ERROR:
      return { ...state, loading: false, error: action.error };
    case nftTypes.UPDATE_NFT_COLLECTION_LOADING:
      return { ...state, loading: true };
    case nftTypes.UPDATE_NFT_COLLECTION_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case nftTypes.UPDATE_NFT_COLLECTION_ERROR:
      return { ...state, loading: false, error: action.error };
    case nftTypes.LIKE_NFT_LOADING:
      return { ...state, loading: true };
    case nftTypes.LIKE_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        nft: { ...state.nft, isliked: action.payload },
      };
    case nftTypes.LIKE_NFT_ERROR:
      return { ...state, loading: false, error: action.error };
    //* change price success
    case nftTypes.CHANGE_NFT_PRICE_SUCCESS:
      return {
        ...state,
        nft: Object.assign(state.nft, {
          listing: { ...state.nft.listing, price: action.payload },
        }),
      };
    //*socket
    case socketTypes.PURCHASE_NFT_SOCKET:
      return { ...state, nft: Object.assign(state.nft, action?.payload?.nft) };
    case socketTypes.CANCEL_NFT_SOCKET:
      return { ...state, nft: Object.assign(state.nft, action?.payload?.nft) };

    default:
      return state;
  }
};

export const nftAuctionReducer = (state = nftAuction, action) => {
  switch (action.type) {
    case nftTypes.GET_NFT_DETAILS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_NFT_DETAILS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case nftTypes.GET_NFT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        error_status: action.error_status,
      };
    default:
      return state;
  }
};

export const nftActionsReducer = (state = nftActions, action) => {
  switch (action.type) {
    //* create nft
    case nftTypes.CREATE_NFT_LOADING:
      return { ...state, loading: true };
    case nftTypes.CREATE_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        data: action.nft_id,
        error: null,
        progress: 100 - state.progress + state.progress,
      };
    case nftTypes.CREATE_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* create nft progress
    case nftTypes.CREATE_NFT_PROGRESS:
      return {
        ...state,
        status: action.payload,
        progress: action.payload === null ? 0 : state.progress,
      };
    //* create nft socket
    case socketTypes.CREATE_NFT_SOCKET:
      return { ...state, progress: state.progress + action?.payload?.progress };
    //* upload nft progress
    case nftTypes.UPLOAD_NFT_PROGRESS:
      return { ...state, progress: Math.round(action.progress / 2) };
    //* update nft
    case nftTypes.UPDATE_NFT_LOADING:
      return { ...state, loading: true, done: false };
    case nftTypes.UPDATE_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        data: action.nft_id,
        error: null,
        progress: 100,
        done: true,
      };
    case nftTypes.UPDATE_NFT_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        done: true,
        error: action.error,
      };
    //* generate ipfs
    case nftTypes.GENERATE_IPFS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GENERATE_IPFS_SUCCESS:
      return { ...state, ipfs_path: action.data?.ipfs };
    case nftTypes.GENERATE_IPFS_ERROR:
      return { ...state, loading: false, error: action.error };
    //* purchase nft
    case nftTypes.PURCHASE_NFT_LOADING:
      return { ...state, loading: true };
    case nftTypes.PURCHASE_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case nftTypes.PURCHASE_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* cancel nft
    case nftTypes.CANCEL_NFT_LOADING:
      return { ...state, loading: true };
    case nftTypes.CANCEL_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case nftTypes.CANCEL_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* burn nft
    case nftTypes.BURN_NFT_LOADING:
      return { ...state, loading: true };
    case nftTypes.BURN_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case nftTypes.BURN_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* list nft
    case nftTypes.LIST_NFT_LOADING:
      return { ...state, loading: true };
    case nftTypes.LIST_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: action.action,
        error: null,
      };
    case nftTypes.LIST_NFT_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    case nftTypes.RESET_NFT_ACTIONS:
      return {
        ...state,
        loading: false,
        success: false,
        status: 0,
        data: null,
        error: false,
        action: null,
        progress: 0,
        ipfs_path: "",
      };

    //* default
    default:
      return state;
  }
};

export const externalNFTsReducer = (state = externalNFTs, action) => {
  switch (action.type) {
    case nftTypes.GET_EXTERNAL_MORALIS_NFTS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_EXTERNAL_MORALIS_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nfts:
          action.chain === state.chain
            ? [...state.nfts, ...action.payload]
            : action.payload,
        chain: action.chain,
        cursor: action.cursor,
        has_more: action.has_more,
        error: null,
      };
    case nftTypes.GET_EXTERNAL_MORALIS_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const allNftsReducer = (state = allNfts, action) => {
  switch (action.type) {
    case nftTypes.GET_ALL_NFTS_LOADING:
      return { ...state, loading: true };
    case nftTypes.GET_ALL_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        nfts: action.load ? [...state.nfts, ...action.payload] : action.payload,
        count: action.count,
        has_more: action.has_more,
        error: null,
      };
    case nftTypes.GET_ALL_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
