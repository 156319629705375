import styled from "styled-components";

const StylesWrapper = styled.div`
  .search-wrapper {
    position: relative;
    width: 256px;
  }
  .search-input {
    height: 40px;
    width: 256px;
    border-radius: 8px;
    padding: 10px 12px 10px 16px;
    border: 2px solid #e6e8ec;
    font-weight: 325;
  }
  .search-icon {
    position: absolute;
    top: 12px;
    right: ${(props) => (props.isLTR ? "12px" : "unset")};
    left: ${(props) => (props.isLTR ? "unset" : "12px")};
  }
`;
export default StylesWrapper;
