import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import PageNotFound from "../components/PageNotFound";
import { CollectionNFTsComponents } from "../components/CollectionNFTsComponents";
import { resetCollectionState } from "../store/actions/creators/creatorCollection";
import { useEffect, useState } from "react";
import { creatorCollectionsCreators } from "../store";

export const CollectionNFTs = () => {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const { fetchCollectionDetailCreator } = bindActionCreators(
    creatorCollectionsCreators,
    dispatch
  );
  const { collectionDetail, loading, error } = useSelector(
    (state) => state.creatorCollections
  );
  const [test, setTest] = useState(false);
  useEffect(() => {
    fetchCollectionDetailCreator(collectionId);
    setTest(true);
    return () => dispatch(resetCollectionState());
  }, []);

  if (collectionDetail)
    if (collectionDetail?._id) {
      return <CollectionNFTsComponents />;
    } else {
      return <PageNotFound />;
    }
};
