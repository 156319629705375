export const private_routes = {
  editProfile: "/edit-profile",
  activities: "/activities",
  wallet: "/wallet",
  addCreditCard: "/add-credit-card",
  editCreditCard: "/edit-credit-card",
  createNFT: "/nft/create",
  editNFT: (erc = ":erc", id = ":nft_id") => `/nft/edit/${erc}/${id}`,
  createNFTWithCollectionId: (collectionId = ":collectionId") =>
    `/nft/create/${collectionId}`,
  gallery: "/gallery",
  createGallery: "/gallery/create",
  editGallery: "/gallery/edit/:galleryId",
  galleryArtist: "/gallery/:galleryId/artist/:isOpen",
  galleryRejection: "/gallery/:galleryId/rejection",
  collectionActivity: `/collection/activity/:collectionId`,
};

export const public_routes = {
  home: "/",
  aboutUs: "/about-us",
  faq: "/faq",
  contactUs: "/contact-us",
  discover: "/discover",
  nftDetails: (erc = ":erc", id = ":nft_id") => `/nft/${erc}/${id}`,
  linkedNftDetails: (
    contract_address = ":contract_address",
    token_id = ":token_id"
  ) => `/linked/${contract_address}/${token_id}`,
  sellers: "/sellers",
  profile: `/profile/:user_id`,
  profileTab: `/profile/:user_id/:tab`,
  privacy: "/privacy",
  search: "/sellers",
  termsAndConditions: "/terms-and-conditions",
  login: "/login",
  auth: "/auth",
  agreement: "/agreement",
  galleryIntro: "/gallery-intro",
  collectionNFTs: `/collection/nft/:collectionId`,
  galleries: "/galleries",
  artists: "/galleries/:galleryId/artists",
  createdArtist: "/created-artist/:artistId",
  clubs: (club = ":club") => `/club/${club}`,
  // theBezelClub: "/the-bezel-club",
  popularPartners: `/partners/`,
  popularPartnerDetails: (contract = ":contract") => `/partners/${contract}`,
  partnerNftDetails: (contract = ":contract", id = ":nft_id") =>
    `/club/${contract}/${id}`,
  projectNftDetails: (contract = ":contract", token_id = ":token_id") =>
    `/nft/partner/${contract}/${token_id}`,
  notFound: "*",
};
