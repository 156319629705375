import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StylesWrapper from "./styles";
import { tabs } from "./data";

const Tabs = ({
  nft,
  isProject,
  selectedTab,
  setSelectedTab,
  nftStatus,
  externalNFT,
  colors,
  filteredLinkedNFTs,
}) => {
  const { t } = useTranslation();
  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const is721 = nft?.token_standard === "721";
  return (
    <StylesWrapper>
      <ul
        className="header"
        style={{
          width: nftStatus > 2 ? "392px" : "auto",
        }}
      >
        {tabs.map(
          (item, index) =>
            (!isBezelClub || (isBezelClub && item.external)) && (
              <li
                className="list"
                style={{
                  color: selectedTab == index ? "#FCFCFD" : colors?.tab,
                  background: selectedTab == index ? "#6D3190" : "transparent",
                }}
                onClick={() => setSelectedTab(index)}
              >
                {t(item.label)}
              </li>
            )
        )}
        {nftStatus == 3 && (
          <li
            className="list"
            style={{
              color: selectedTab == 3 ? "#FCFCFD" : colors?.tab,
              background: selectedTab == 3 ? "#6D3190" : "transparent",
            }}
            onClick={() => setSelectedTab(3)}
          >
            {t("bidding_history")}
          </li>
        )}
        {is721 &&
          (nftStatus == 1 || nftStatus == 2 || nftStatus == 0) &&
          !nft?.is_lazy &&
          nft?.blockchain !== "BOBA" && (
            <li
              className="list"
              style={{
                color: selectedTab == 4 ? "#FCFCFD" : colors?.tab,
                background: selectedTab == 4 ? "#6D3190" : "transparent",
              }}
              onClick={() => setSelectedTab(4)}
            >
              {t("Offers")}
            </li>
          )}
      </ul>
    </StylesWrapper>
  );
};
export default Tabs;
