import styled from "styled-components";

export const Checkout = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  .label {
    color: ${(props) => props.colors?.label};
  }
  .button {
    color: ${(props) => props.colors?.text1};
  }
  .success-message {
    font-size: 34px;
    font-weight: 325;
    color: ${(props) => props.colors?.text1};
  }
  .success-message_1 {
    font-size: 18px;
    font-weight: 325;
    color: ${(props) => props.colors?.text1};
  }
  h4 {
    color: #23262f;
    font-size: 24px;
  }
  h6 {
    color: #23262f;
    font-weight: 400;
  }
  p {
    font-weight: 400;
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    font-weight: 400;
    color: ${(props) => props.colors?.text};
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
  .error-container {
    background-color: #fce8ed;
    border-radius: 8px;
    h6,
    p {
      color: #ef466f;
      font-weight: 400;
    }
  }
  .message {
    border: 1px solid #e6e8ec;
    border-radius: 8px;
    margin: 64px 0 32px 0;
    span {
      text-align: left;
      flex: 1;
      font-weight: 400;
    }
    .table-title {
      span {
        color: #777e90;
      }
    }
    .table-row {
      span:first-child {
        color: #6d3190;
      }
      span:last-child {
        color: ${(props) => props.colors?.text1};
      }
    }
  }
  .icons {
    h6 {
      color: #23262f;
      font-size: 16px;
    }
    a {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #e6e8ec;
      border-radius: 50%;
      margin-right: 8px;
      margin-left: 8px;
      &:hover {
        border: 2px solid #23262f;
      }
    }
  }
  .arrow {
    margin-inline-end: 10px;
    transform: ${(props) => (props?.isLTR ? "rotate(0deg)" : "rotate(180deg)")};
  }
  .form-check-input[type="radio"] {
    width: 20px !important;
    height: 20px !important;
  }
  .form-check-label {
    margin-top: 3px;
  }
  .form-check-input:checked {
    background-color: #6d3190 !important;
    border: none !important;
  }
`;

export const Modals = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => props.colors?.text2};
  }
  .address {
    border-bottom: 1px solid #e6e8ec;
    p {
      font-weight: 400;
    }
  }
  .label {
    font-size: 14px;
    text-transform: uppercase;
    color: #b1b5c3;
    font-weight: 400;
  }
  .text-area {
    border-radius: 8px;
    background: transparent;
    border: #e6e8ec solid 1px;
  }
  .text-area::placeholder {
    font-weight: 400;
    color: ${(props) => props.colors?.text2};
  }
  .message {
    border: 1px solid #e6e8ec;
    border-radius: 8px;
    margin: 64px 0 32px 0;
    span {
      text-align: left;
      flex: 1;
      font-weight: 400;
    }
    .table-title {
      span {
        color: ${(props) => props.colors?.text2};
      }
    }
    .table-row {
      span:first-child {
        color: #6d3190;
      }
      span:last-child {
        color: ${(props) => props.colors?.text};
      }
    }
  }
  .icons {
    h6 {
      color: #23262f;
      font-size: 16px;
    }
    a {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #e6e8ec;
      border-radius: 50%;
      margin-right: 8px;
      margin-left: 8px;
      &:hover {
        border: 2px solid #23262f;
      }
    }
  }
`;
