import React from "react";
import { Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getGalleryArtistNav } from "../../../../common/constants/gallery";
import "./styles.css";

const ArtistNav = ({ status, handleStatusChange, onCreateArtistClicked }) => {
  const { t } = useTranslation();
  const renderNavItem = (config, i) => {
    return (
      <li className="nav-item" key={i}>
        <a
          onClick={(e) => handleStatusChange(e, config.key)}
          className={`nav-link ${status === config.key ? "active" : ""}`}
          aria-current="page"
          href="#"
        >
          {config.text}
        </a>
      </li>
    );
  };

  return (
    <React.Fragment>
      <Col md="8">
        <div className="ga-navs">
          <div>
            <ul className="nav nav-pills">
              {getGalleryArtistNav(t).map((config, i) =>
                renderNavItem(config, i)
              )}
            </ul>
          </div>
        </div>
      </Col>
      <Col md="4">
        <div className="ga-navs-btn">
          <Button onClick={onCreateArtistClicked}>{t("add_new_artist")}</Button>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default ArtistNav;
