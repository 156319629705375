import { useTranslation } from "react-i18next";

const NFTDescription = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ fontSize: 18, color: props.colors?.label, fontWeight: 325 }}>
      {(props?.nft?.description
        ? props?.nft?.description
        : props?.description) || t("no_description")}
    </div>
  );
};

export default NFTDescription;
