//* login
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

//* verify account
export const VERIFY_ACCOUNT_LOADING = "VERIFY_ACCOUNT_LOADING";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_ERROR = "VERIFY_ACCOUNT_ERROR";

//* import wallet
export const IMPORT_WALLET_LOADING = "IMPORT_WALLET_LOADING";
export const IMPORT_WALLET_SUCCESS = "IMPORT_WALLET_SUCCESS";
export const IMPORT_WALLET_ERROR = "IMPORT_WALLET_ERROR";

export const LOG_OUT = "LOG_OUT";

export const RESET_PROGRESS = 'RESET-PROGRESS';
