import styled from "styled-components";

const StylesWrapper = styled.div`
  padding-bottom: 75px;
  .user-image {
    height: 113px;
    width: 114px;
    border-radius: 128px;
    object-fit: cover;
  }
  .profile-box {
    padding: 32px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.78);
    backdrop-filter: blur(34px);
    margin-top: -100px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  }
  .name {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }
  .description {
    font-size: 14px;
    font-weight: 325;
    line-height: 16px;
  }
  .label {
    color: #777e90;
    font-weight: 325;
    text-align: center;
  }
  .value {
    color: #000;
    font-weight: 400;
    text-align: center;
  }
  .nft-card {
    margin: 10px 0;
  }
  .note {
    font-size: 22px;
    text-align: center;
  }
  .icon {
    margin-bottom: 3px;
    margin-inline-end: 5px;
  }
  .search-wrapper {
    position: relative;
    width: 256px;
  }
  .search-input {
    height: 40px;
    width: 256px;
    border-radius: 8px;
    padding: 10px 12px 10px 16px;
    border: 2px solid #e6e8ec;
    font-weight: 325;
  }
  .search-icon {
    position: absolute;
    top: 12px;
    right: ${(props) => (props.isLTR ? "12px" : "unset")};
    left: ${(props) => (props.isLTR ? "unset" : "12px")};
  }
`;
export default StylesWrapper;
