import React, { useEffect } from "react";
import { useFormik } from "formik";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Form, Row } from "react-bootstrap";
import { galleryActionsCreators } from "../../../../store";
import { getGalleryRejectionInitialValues } from "../utilities";
import UploadImage from "../../../uploadImage/UploadImage";
import createGalleryImage from "../../../../assets/images/create-gallery.jpg";
import "./styles.css";

const ViewRejectionReson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { galleryId } = useParams();
  let gallery = useSelector((state) => state.gallery.getGalleryById.entities);
  const { getGalleryByIdCreator, resetGalleryByIdCreator } = bindActionCreators(
    galleryActionsCreators,
    dispatch
  );
  const { values, setFieldValue } = useFormik({
    initialValues: getGalleryRejectionInitialValues(),
  });

  useEffect(() => {
    if (galleryId) {
      getGalleryByIdCreator(galleryId);
    }
    return () => {
      resetGalleryByIdCreator();
    };
  }, []);

  useEffect(() => {
    if (!values.title && gallery) {
      setFieldValue("title", gallery.title);
      setFieldValue("description", gallery.description);
      setFieldValue("profileImage", gallery.profileImage);
      setFieldValue("coverImage", gallery.coverImage);
      setFieldValue("rejectionReason", gallery.rejectionReason);
    }
  }, [gallery]);

  return (
    <div>
      <div className="cover-photo">
        <img
          src={createGalleryImage}
          className="gallery-artist-bg"
          alt="galleryIcon"
        />
      </div>
      <Container
        className="create-page mt-0 mb-0"
        style={{
          maxWidth: "1120px",
        }}
      >
        <Row>
          <h1 className="vr-title">{t("gallery")}</h1>
        </Row>
        <Row>
          <h4 className="vr-sub-title">{t("rejected_gallery")}</h4>
        </Row>
        <Row>
          <Col md="7">
            <Form>
              <div className="item-details pb-0">
                <Form.Group controlId="item-name" className="mb-4">
                  <Form.Label className="create-section-label">
                    {t("title")}
                    <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="create-page-input-field"
                    type="text"
                    value={values.title}
                    name="title"
                    placeholder={t("title_placeholder")}
                    readonly
                  />
                </Form.Group>

                <Form.Group controlId="item-description" className="mb-4">
                  <Form.Label className="create-section-label">
                    {t("description")}
                    <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="create-page-input-field textarea-h-100"
                    as="textarea"
                    value={values.description}
                    name="description"
                    placeholder={t("description_placeholder")}
                    readonly
                  />
                </Form.Group>
                <Row>
                  <Col xs={12} md={8}>
                    <h5 className="mt-5 modal-section-title">
                      {t("upload_profile_image")}
                      <span className="required">*</span>
                    </h5>
                    <p className="mt-2 modal-section-text">
                      {t("upload_logo_p1")}
                    </p>
                  </Col>
                  <Col xs={12} md={4} className="mt-5">
                    <UploadImage
                      fieldName="profileImage"
                      onlyPreviewImage={values.profileImage}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <h5 className="mt-5 modal-section-title">
                      {t("upload_banner_image")}
                      <span className="required">*</span>
                    </h5>
                    <p className="mt-2 modal-section-text">
                      {t("upload_banner_p1")}
                    </p>
                  </Col>

                  <Col xs={12} md={4} className="mt-5">
                    <UploadImage
                      fieldName="coverImage"
                      onlyPreviewImage={values.coverImage}
                    />
                  </Col>
                </Row>
                <Row>
                  <h5 className="mt-5 modal-section-title">
                    {t("rejection_Reason")}
                  </h5>
                  <p className="mt-2 modal-section-text">
                    {values.rejectionReason}
                  </p>
                </Row>
                <button
                  style={{ width: "163px", height: "48px" }}
                  className="mx-0 mx-md-2 my-2 my-md-5 btn btn btn-secondary-outline radius-50 mb-4"
                  onClick={() => navigate("/gallery")}
                >
                  {t("cancel")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewRejectionReson;
