import { Row } from "react-bootstrap";
import styled from "styled-components";

const StylesWrapper = styled("div")`
  .name {
    font-size: 42px;
    color: ${(props) => props?.colors?.text1};
  }
  .label {
    font-size: 18px;
    color: ${(props) => props?.colors?.text1};
  }
  .price {
    font-size: 32px;
    font-weight: 400;
    color: #6d3190;
  }
  .chain {
    font-size: 32px;
    font-weight: 325;
    margin-left: 8px;
  }
  .price-usd {
    font-size: 20px;
    color: #808080;
  }
  .profile-img {
    height: 48px;
    max-width: 48px;
    min-width: 48px;
    border-radius: 50%;
    object-fit: cover;
    background-color: lightgray;
  }
  .copy-icon {
    margin-bottom: 7px;
    margin-inline-start: 5px;
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: ${(props) => props?.colors?.divider};
  }
`;
export default StylesWrapper;
