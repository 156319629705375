import { Container, Form, Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useState, useContext, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import { useWeb3Auth } from "../../context/Web3auth";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
// import { useSelector, useDispatch } from "react-redux";
// import { bindActionCreators } from "redux";
// import { authActionsCreators } from "../../store";

// icons
import registrationIcon from "../../assets/icons/register-new.png";
import facebook from "../../assets/icons/social/facebook.svg";
import discord from "../../assets/icons/social/discord.svg";
import google from "../../assets/icons/social/google.svg";
import apple from "../../assets/icons/social/apple.svg";
import twitch from "../../assets/icons/social/twitch.svg";
import twitter from "../../assets/icons/social/twitter.svg";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
// import Cookies from "universal-cookie";

const Wrapper = styled.div`
  padding-top: 48px;
  padding-bottom: 100px;
  .login {
    max-width: 550px;
  }
  .disabled-button {
    background-color: #bdbdbd;
    border: none;
  }
  input,
  textarea {
    border: #e6e8ec solid 2px;
    border-radius: 12px;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #b1b5c3;
  }
  .social-text {
    font-weight: 500;
  }
  .social-link {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 16px auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .line-before-and-after {
    font-size: 14px;
    margin: 16px auto;
    text-align: center;
    position: relative;
    color: #b1b5c3;
  }
  .line-before-and-after:before {
    content: "";
    display: block;
    width: 45%;
    height: 1px;
    background: #e6e8ec;
    position: absolute;
    left: 0;
    top: 50%;
  }
  .line-before-and-after:after {
    content: "";
    display: block;
    width: 45%;
    height: 1px;
    background: #e6e8ec;
    position: absolute;
    right: 0;
    top: 50%;
  }
  #checkbox {
    width: 20px !important;
    margin-right: 8px;
  }
  .form-check-label {
    color: #6d3190;
    text-transform: uppercase;
    transform: translateY(4px);
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  .main-image {
    margin-top: 70px;
  }
  .image {
    width: 450px;
    height: auto;
    object-fit: cover;
  }
`;

export const NewLogin = () => {
  const { t } = useTranslation();
  let regexEmail = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/);
  const { login, loginEmail, loading } = useWeb3Auth();
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [loadingForpage, setLoadingForpage] = useState(false);
  const { account } = useSelector((state) => state.auth);
  // const authContext = useContext(AuthContext);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmail(email);
    setIsValidEmail(regexEmail.test(email));
  };

  const loginWithEmail = async () => {
    if (!isValidEmail) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("EmailValidation"),
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    } else if (!isChecked) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("agree_terms_conds"),
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    } else {
      await loginEmail("email_passwordless", email.toLowerCase());
    }
  };

  const loginWith = async (social) => {
    await login(social);
  };
  useEffect(() => {
    if (!loading) {
      if (account) {
        window.location = `profile/${account._id}`;
      } else {
        setLoadingForpage(true);
      }
    }
  });
  return (
    <Wrapper>
      <Container>
        {loadingForpage && (
          <Row>
            <Col xs={12} md={6}>
              <div className="login">
                <h1>{t("Register")}</h1>
                <p className="mb-4 label">{t("Login & register")}</p>
                {/* social */}
                <Row>
                  {/* twitter */}
                  <Col xs={6}>
                    <Button
                      variant="secondary-outline"
                      className="btn btn-secondary-outline d-block radius-50 social-link"
                      onClick={() => loginWith("twitter")}
                      disabled={loading}
                    >
                      <span style={{ marginLeft: "-8px" }}>
                        <img src={twitter} alt="" className="me-2" />
                        <span className="social-text">{t("Twitter")}</span>
                      </span>
                    </Button>
                  </Col>
                  {/* discord */}
                  <Col xs={6}>
                    <Button
                      variant="secondary-outline"
                      className="btn btn-secondary-outline d-block radius-50 social-link"
                      onClick={() => loginWith("discord")}
                      disabled={loading}
                    >
                      {" "}
                      <span style={{ marginLeft: "-2px" }}>
                        <img src={discord} alt="" className="me-2" />
                        <span className="social-text">{t("Discord")}</span>
                      </span>
                    </Button>
                  </Col>
                  {/* facebook */}
                  <Col xs={6}>
                    <Button
                      variant="secondary-outline"
                      className="btn btn-secondary-outline d-block radius-50 social-link"
                      onClick={() => loginWith("facebook")}
                      disabled={loading}
                    >
                      <span style={{ marginLeft: "6px" }}>
                        <img src={facebook} alt="" className="me-2" />
                        <span className="social-text">{t("Facebook")}</span>
                      </span>
                    </Button>
                  </Col>
                  {/* instagram */}
                  <Col xs={6}>
                    <Button
                      variant="secondary-outline"
                      className="btn btn-secondary-outline d-block radius-50 social-link"
                      onClick={() => loginWith("apple")}
                      disabled={loading}
                    >
                      <span style={{ marginLeft: "-8px" }}>
                        <img
                          src={apple}
                          alt=""
                          className="me-2"
                          width="24"
                          height="24"
                        />
                        <span className="social-text">{t("Apple")}</span>
                      </span>
                    </Button>
                  </Col>
                  {/* google */}
                  <Col xs={6}>
                    <Button
                      variant="secondary-outline"
                      className="btn btn-secondary-outline d-block radius-50 social-link"
                      onClick={() => loginWith("google")}
                      disabled={loading}
                    >
                      <span style={{ marginLeft: "-5px" }}>
                        <img src={google} alt="" className="me-2" />
                        <span className="social-text">{t("Google")}</span>
                      </span>
                    </Button>
                  </Col>
                  {/* twitch */}
                  <Col xs={6}>
                    <Button
                      variant="secondary-outline"
                      className="btn btn-secondary-outline d-block radius-50 social-link"
                      onClick={() => loginWith("twitch")}
                      disabled={loading}
                    >
                      <span style={{ marginLeft: "-14px" }}>
                        <img src={twitch} alt="" className="me-2" />
                        <span className="social-text">{t("Twitch")}</span>
                      </span>
                    </Button>
                  </Col>
                </Row>
                {/* or */}
                <p className="line-before-and-after my-4">{t("OR")}</p>
                {/* email */}
                <Form>
                  <Form.Group controlId="email" className="mb-4">
                    <Form.Label className="label">{t("Email")}</Form.Label>
                    <Form.Control
                      type="email"
                      required
                      placeholder={t("Enter your email address")}
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </Form.Group>
                  {/* terms and conditions */}

                  <Form.Group
                    className="my-4 d-flex"
                    controlId="checkbox"
                    onChange={() => setIsChecked(!isChecked)}
                  >
                    <Form.Check type="checkbox" checked={isChecked} />
                    <a
                      href="/terms-and-conditions"
                      className="form-check-label"
                      target="_blank"
                    >
                      {t("terms_conditions")}
                    </a>
                  </Form.Group>

                  {/* button */}
                  <Button
                    // disabled={!isValidEmail}
                    // className={!isValidEmail ? "disabled-button" : ""}
                    onClick={() => loginWithEmail()}
                  >
                    {t("Continue with email")}
                  </Button>
                </Form>
              </div>
            </Col>
            <Col className="d-none d-md-flex justify-content-center main-image">
              <img src={registrationIcon} alt="" className="image" />
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  );
};
