import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import arrowRight from "../../icons/arrow-right.svg";
import card from "../../assets/icons/card-big.svg";
import deleteIcons from "../../assets/icons/delete.svg";
import editIcon from "../../assets/icons/edit.svg";
import { Link } from "react-router-dom";
import Card from "./Card";

const CardWrapper = styled.div`
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin: 8px;
  .make-default {
    color: #6d3190;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  .action-icons {
    cursor: pointer;
  }
`;

const Cards = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <Row className="border-bottom-1 justify-content-between">
        <Col>
          <h4 className="fw-bold">{t("Credit Card")}</h4>
          {/* <p className="width-600 mb-4">
                        With supporting text below as a natural lead-in to
                        additional content
                    </p> */}
        </Col>
        <Col className="d-none d-md-block">
          <div className="d-flex justify-content-end">
            <img src={card} alt="" />
          </div>
        </Col>
      </Row>

      <div className="d-flex flex-wrap my-4 justify-content-center justify-content-md-start">
        <CardWrapper>
          <Card default={true} />
          <div className="d-flex mt-3">
            <span className="flex-fill make-default"></span>
            <Link to="/edit-credit-card">
              <img src={editIcon} alt="" className="me-3 action-icons" />
            </Link>
            <img src={deleteIcons} className="action-icons" alt="" />
          </div>
        </CardWrapper>
        <CardWrapper>
          <Card default={false} />
          <div className="d-flex mt-3">
            <span className="flex-fill make-default">
              {t("Make it Default")}
            </span>
            <Link to="/edit-credit-card">
              <img src={editIcon} alt="" className="me-3 action-icons" />
            </Link>
            <img src={deleteIcons} className="action-icons" alt="" />
          </div>
        </CardWrapper>
      </div>

      <div className="mt-3 d-flex justify-content-center justify-content-md-start">
        <Link className="me-4 btn btn-primary" to="/add-credit-card">
          {t("Add New Card")}
          <img src={arrowRight} alt="arrow right" />
        </Link>
      </div>
    </div>
  );
};

export default Cards;
