import styled from "styled-components";

const StylesWrapper = styled.div`
  .card-title {
    z-index: 2;
    font-size: 30px;
  }
  .card-content {
    z-index: 2;
    max-width: 599px;
  }
  .card-image {
    position: relative;
    width: 550px;
    height: 350px;
    margin-inline-start: 0px;
    margin-inline-end: 30px;
    border-radius: 18.0068px;
    border: 1.3px solid #975f00;
    background-color: #000000;
    z-index: 2;
  }
  .card-image-end {
    position: absolute;
    left: 30px;
    top: 0;
    width: 124px;
    height: 6px;
    top: -4px;
    background-color: #c7b300;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffa100 0%,
      rgba(210, 174, 113, 0) 100%
    );
  }
  .card-image-reverse {
    margin-inline-start: 30px;
    margin-inline-end: 0px;
  }
  .card-image-reverse-end {
    position: absolute;
    top: 30px;
    right: -4px;
    width: 6px;
    height: 124px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffa100 0%,
      rgba(210, 174, 113, 0) 100%
    );
  }
  .card-divider {
    z-index: 2;
    position: relative;
    width: 92%;
    height: 0.8px;
    background-color: #6b6102;
  }
  .card-divider-end {
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
    height: 6px;
    background-color: #c7b300;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffeb34 0%,
      rgba(205, 194, 95, 0) 100%
    );
  }
  .card-divider-reverse-end {
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
    height: 6px;
    background-color: #c7b300;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #ffeb34 0%,
      rgba(205, 194, 95, 0) 100%
    );
  }
`;
export default StylesWrapper;
