//* nft details sockets
export const PLACE_BID_SOCKET = "PLACE_BID_SOCKET";
export const EDIT_BID_SOCKET = "EDIT_BID_SOCKET";
export const CANCEL_BID_SOCKET = "CANCEL_BID_SOCKET";

export const PURCHASE_NFT_SOCKET = "PURCHASE_NFT_SOCKET";
export const CANCEL_NFT_SOCKET = "CANCEL_NFT_SOCKET";
export const LIST_NFT_SOCKET = "LIST_NFT_SOCKET";

export const CREATE_NFT_SOCKET = "CREATE_NFT_SOCKET";
