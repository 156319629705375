import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import ArtistCard from "../components/ArtistCard";
import { useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { social } from "../../../../common/constants/gallery";
import noArtists from "../../../../assets/images/no-artists.png";
// import featuredIcon from "../../../../assets/icons/featured-icon.svg";
import {
  artistActionsCreators,
  galleryActionsCreators,
} from "../../../../store";
import NoDataFound from "../components/NoDataFound";
import "./styles.scss";

const Artists = () => {
  const { galleryId } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    data,
    loading,
    count,
    galleryId: fetchedGalleryId,
  } = useSelector((state) => state.artist.publicArtists);
  let { entities: gallery } = useSelector(
    (state) => state.gallery.getGalleryById
  );
  const { getPublicGalleryArtistCreator, resetPublicGalleryArtistCreator } =
    bindActionCreators(artistActionsCreators, dispatch);

  const { getGalleryByIdCreator, resetGalleryByIdCreator } = bindActionCreators(
    galleryActionsCreators,
    dispatch
  );

  useEffect(() => {
    if (galleryId) {
      getPublicGalleryArtistCreator({
        limit: 8,
        skip: 0,
        galleryId,
      });
      getGalleryByIdCreator(galleryId);
    }
    return () => {
      resetPublicGalleryArtistCreator();
      resetGalleryByIdCreator();
    };
  }, [galleryId]);
  const urlWithProtocol = (data) => {
    if (data) {
      if (data.includes("https://")) {
        return data;
      } else if (data.includes("http://")) {
        return data;
      } else {
        return "https://" + data;
      }
    } else {
      return "";
    }
  };
  return (
    <div>
      {galleryId != fetchedGalleryId && loading ? (
        <Container>
          <p style={{ padding: 50 }}>{t("Loading...")}</p>
        </Container>
      ) : (
        <Container
          className="container-fluid artists mr-0"
          style={{
            maxWidth: "1180px",
          }}
        >
          <div className="title">{gallery?.title}</div>
          {gallery?.coverImage && (
            <div className="artist-photo-cover">
              <img
                alt="artistIcon"
                className="cover-image"
                src={gallery?.coverImage}
                style={{ objectFit: "cover" }}
              />
            </div>
          )}
          <div className="d-flex flex-row justify-content-end">
            {gallery?.media &&
              social.map(
                ({ value, Icon }) =>
                  gallery?.media[value] &&
                  gallery?.media[value] !== "undefined" && (
                    <a
                      href={urlWithProtocol(gallery?.media[value])}
                      target="_blank"
                    >
                      <div className="social-icon-button cursor mt-3">
                        <Icon width="14" height="14" color="#fff" />
                      </div>
                    </a>
                  )
              )}
          </div>
          <Row>
            <p className="sub-title-1">{gallery?.description}</p>
          </Row>
          <Row>
            <p className="sub-title-2">
              {gallery?._id === "63fee9aeede894003eee3879"
                ? t("Editions")
                : t("artists")}
            </p>
          </Row>
          <Row>
            {data.map((el, idx) => (
              <Col xs={12} md={6} lg={3} style={{ margin: "10px 0" }}>
                <ArtistCard item={el} key={idx} galleryId={gallery?._id} />
              </Col>
            ))}
          </Row>
          {count > data?.length && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  borderRadius: 25,
                  border: "none",
                  margin: "0 auto",
                  padding: "10px 20px",
                  background: `linear-gradient(to right, #6D3190, #BC60F1)`,
                }}
                onClick={() => {
                  getPublicGalleryArtistCreator(
                    {
                      galleryId,
                      skip: data?.length,
                    },
                    true
                  );
                }}
              >
                {t("Load More")}
                {loading && (
                  <Spinner
                    size="sm"
                    style={{ color: "#fff", marginRight: "5px" }}
                  />
                )}
              </Button>
            </div>
          )}

          {!loading && data?.length == 0 && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={noArtists}
                width="100%"
                height="300"
                className="my-5"
                style={{ objectFit: "contain" }}
              />
              <div className="mt-4 mb-1" style={{ fontSize: 22 }}>
                {t("no_artists_found")}
              </div>
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default Artists;
