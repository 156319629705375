import { Container, Row, Col } from "react-bootstrap";

import aboutImage from "../icons/about-nifty.png";
import exploreIcon from "../icons/explore.svg";
import cartIcon from "../icons/cart.svg";
import shapesIcon from "../icons/shapes.svg";

import nabilPhoto from "../images/nabil.jpeg";
import talalPhoto from "../images/talal.jpeg";

import "./AboutUs.scss";
import { useTranslation } from "react-i18next";

export const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div className="mt-5 mb-4 page-header">{t("About Us")}</div>
      <Row className="align-items-center pb-5">
        <Col sm={12} md={6}>
          <div className="page-header">{t("About")}</div>
          <div
            className="page-header"
            style={{ color: "#6d3190", lineHeight: "0.7em" }}
          >
            {t("Nifty Souq")}
          </div>
          <p className="about-content mt-5">{t("about_p1")}</p>
        </Col>
        <Col
          sm={12}
          md={6}
          className="d-flex justify-content-center jusify-content-md-end"
        >
          <img src={aboutImage} alt="" width="500" />
        </Col>
      </Row>
      <Row className="mb-5 d-none">
        <h2 className="mb-5">
          {t("Co-founders of")} <br />
          {t("Nifty Souq")}
        </h2>
        <Col>
          <div className="pb-5 d-flex justify-content-center">
            <div className="founder px-3 px-md-5">
              <img src={nabilPhoto} alt="" className="user-xlg-rounded mb-3" />
              <div className="mb-1">
                <b>{t("Nabil Al Sayed")}</b>
              </div>
              <div className="text-muted">{t("Co-founder and CEO")}</div>
            </div>
            <div className="founder px-3 px-md-5">
              <img src={talalPhoto} alt="" className="user-xlg-rounded mb-3" />
              <div className="mb-1">
                <b>{t("Talal Al Afghani")}</b>
              </div>
              <div className="text-muted">{t("Co-founder and CSO")}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col>
          <div className="sup-title pb-3">{t("You should know")}</div>
          <div
            className="page-header"
            style={{ maxWidth: "480px", lineHeight: "1.1em" }}
          >
            {t("Why Nifty Souq is better")}
          </div>
        </Col>
        <Col>
          <div className="using-reasons justify-content-center d-flex flex-column align-items-center">
            <div>
              <div className="step-number">01</div>
              {t("Variety of collectibles")}
            </div>
            <div>
              <div className="step-number">02</div>
              {t("Easy & free")}
            </div>
            <div>
              <div className="step-number">03</div>
              {t("Secure & reliable")}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className="text-center uppercase pt-5 mb-5 page-header-lg">
          {t("How it works")}
        </div>
        <Col className="pb-5 mb-5">
          <div className="d-flex flex-md-row flex-column justify-content-center align-items-md-stretch align-items-center">
            <div className="how-box mx-2 mb-4 mb-md-0">
              <img src={exploreIcon} alt="" />
              <div className="mb-4 page-header-sm">
                {t("Explore Our Platform")}
              </div>
              <p>{t("Check out the latest and greatest NFTs.")}</p>
            </div>
            <div className="how-box mx-2 mb-4 mb-md-0">
              <img src={cartIcon} alt="" />
              <div className="mb-4 page-header-sm">{t("Bid or Buy")}</div>
              <p>
                {t(
                  "Buy_NFTs_001"
                )}
              </p>
            </div>
            <div className="how-box mx-2 mb-4 mb-md-0">
              <img src={shapesIcon} alt="" />
              <div className="mb-4 page-header-sm">
                {t("Enjoy Your Collection")}
              </div>
              <p>
                {t(
                  "Marketplace_001"
                )}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
