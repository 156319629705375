import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Form,
  Button,
  Row,
  Col,
  Spinner,
  Fade,
  InputGroup,
} from "react-bootstrap";

import { useTranslation } from "react-i18next";
import arrowRight from "../../icons/arrow-right.svg";
import transfer from "../../assets/icons/transfer-big.svg";
import eth from "../../assets/icons/eth.svg";
import Swal from "sweetalert2";
import { InfoTooltip } from "@components";
import { useWeb3Auth } from "@context/Web3auth";

const Wrapper = styled.div`
  .amount-container {
    position: relative;
    max-width: 465px;
    .select {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 100px;
      height: 36px;
      border: none !important;
      border-left: 1px solid #e6e8ec !important;
      border-radius: 0 !important;
      font-weight: 700;
      font-size: 14px;
    }
  }
`;
const Transfer = () => {
  const { t, i18n } = useTranslation();
  const { privateKey, web3, SendTransaction, chain } = useWeb3Auth();
  const [recipientAddress, setRecipientAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [walletData, setWalletData] = useState({});
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);
  const [error, setError] = useState();
  const [transactionData, setTransactionData] = useState();
  const [show, setShow] = useState(false);

  const account = web3.eth.accounts.privateKeyToAccount(privateKey);
  console.log(account.address);

  const makeTransfer = async () => {
    if (amount > walletData?.balance || !recipientAddress) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("no_funds"),
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    } else {
      if (amount > 0) {
        setLoading(true);
        setStatus(1);
        const result = await SendTransaction(amount, recipientAddress);
        if (result.status) {
          setStatus(2);
          setTransactionData(result.data);
        } else {
          setError(result.data);
          setStatus(3);
        }
        setLoading(false);
      } else {
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("Amount is 0."),
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };
  const ref = useRef();
  const afterTransfer = () => {
    setStatus(0);
    setRecipientAddress("");
    setAmount("");
    setShow(false);
    window.location = window.location.href + "?updated";
  };

  return (
    <Wrapper ref={ref}>
      <Row className="border-bottom-1 justify-content-between">
        <Col>
          <div className="page-header-md">
            {t("Transfer to another wallet")}
            <InfoTooltip text={t("wallet.transfer_tooltip")} />
          </div>
        </Col>
        <Col className="d-none d-md-block">
          <div className="d-flex justify-content-end">
            <img src={transfer} alt="" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-3">
            {/* recipient wallet address */}
            <Form.Group
              controlId="wallet-address"
              className="payment-page-form-group"
            >
              <Form.Label className="payment-page-label">
                {t("Recipient wallet address")}
              </Form.Label>
              <Form.Control
                value={recipientAddress}
                onChange={(e) => setRecipientAddress(e.target.value)}
                type="text"
                placeholder="0x00...."
              />
            </Form.Group>
            {/* amount */}
            <Form.Group controlId="amount" className="payment-page-form-group">
              <Form.Label className="payment-page-label">
                {t("Amount")}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  value={amount}
                  type="number"
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder={t("amount_placeholder")}
                />
                <InputGroup.Text>{chain}</InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {/* buttons */}
            <div className="d-flex align-items-center">
              <Button className="me-4" onClick={() => setShow(!show)}>
                {t("Continue")}
                <img src={arrowRight} alt="arrow right" />
              </Button>
              <Button id="toggle-check" onClick={(e) => setShow(false)}>
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </Col>

        <Col style={{ paddingTop: 100 }}>
          {show && (
            <div className="text-center">
              {status === 0 ? (
                <p className="payment-page-label">
                  {t("this_will_trans") +
                    `${amount} ${chain}` +
                    " " +
                    t("from_wallet") +
                    `${account.address}` +
                    " " +
                    t("to ") +
                    `${recipientAddress}`}
                </p>
              ) : status === 2 ? (
                <p className="payment-page-label">
                  {t("success_transferred") +
                    `${amount} ${chain}` +
                    " " +
                    t("from_wallet") +
                    `${account.address}` +
                    " " +
                    t("to ") +
                    `${recipientAddress}`}
                </p>
              ) : (
                <p className="payment-page-label">
                  {t("transfer_amount") +
                    `${amount} ${chain}` +
                    " " +
                    t("from_wallet") +
                    `${account.address}` +
                    " " +
                    t("to ") +
                    `${recipientAddress}`}
                </p>
              )}
              <Button
                className="me-4"
                onClick={() =>
                  status === 0 ? makeTransfer() : afterTransfer()
                }
              >
                {status === 0
                  ? t("Transfer")
                  : status === 1
                  ? t("Transfering...")
                  : t("Done")}
                <img src={arrowRight} alt="arrow right" />
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Transfer;
