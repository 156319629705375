import React, { useEffect, useState } from "react";
//web3 integraion
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import { Button, Spinner, Image } from "react-bootstrap";
import { NIFTYSouq721, NIFTYSouq1155, chains } from "@config";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3Context } from "@context/Web3";
import { Modals } from "./Modals";
//wert
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";

//svg file
import ModalAndOverlay from "@components/ModalAndOverlay";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import visamaster from "@assets/icons/visa-master.png";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import {
  bidActionsCreators,
  walletActionsCreators,
  authActionsCreators,
} from "../store";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import styled from "styled-components";
import { MdNorthWest } from "react-icons/md";
import moment from "moment";
import { setMaxListeners } from "events";
const AcceptBid = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
  .note {
    font-size: 20px;
  }
  .purple-text {
    color: #6d3190;
  }
  .price {
    font-size: 32px;
    color: #6d3190;
  }
  .chain {
    font-weight: 325;
  }
`;
export const AcceptHighestBidModals = (props) => {
  const auth_account = useSelector((state) => state.auth);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { acceptBidCreator } = bindActionCreators(bidActionsCreators, dispatch);
  const { saveWalletsCreator } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );
  const { nft, error } = props;
  //Sale Type
  const { chainId, account } = useWeb3React();
  const { acceptBid, acceptHighestBid } = useWeb3Context();
  const { userPrincipal, privateKey, getAccounts, chain } = useWeb3Auth();

  //when create token , stauts
  const [status, setStatus] = useState(0);
  //is stauts
  const [isMultiple, setMultiple] = useState(props.status);
  const [isFiat, setFiatPay] = useState(false);

  //basic datas
  const [defaultWallet, setDefaultWallet] = useState("");
  const [selectedWallet, setSelectWallet] = useState("");
  const [txHash, setTx] = useState("");
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");

  const endDate = 0 * 86400;

  const [tokenData, setTokenData] = useState({
    name: nft?.name,
    price: "",
    creators: nft?.royalties?.royalties[0]?.creator,
    royalties: nft?.royalties?.royalties.map(function (item) {
      return item["royalty"];
    }),
    amount: nft?.editions?.current,
    tx: "",
    isLazymint: nft?.is_lazy,
    property: "",
    description: nft?.description,
    isList: false,
    isAuction: false,
    startBidPrice: "",
    reservePrice: "",
    auctionPeriod: endDate,
    categories: [],
  });

  //wert options
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC",
    commodity_amount: "0.003",
    address: selectedWallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
        console.log("status", status);
      },
    },
  };

  const wertWidget = new WertWidget(options);

  const acceptBidPrice = async (isFiat) => {
    if (isFiat) wertWidget.mount();
    else {
      try {
        setStatus(1);
        const web3 = new Web3(
          new Web3.providers.HttpProvider(chains[chain].rpcTarget)
        );
        const res = await acceptHighestBid(
          nft?.offer_id,
          selectedWallet === defaultWallet
        );
        console.log(nft?.offer_id);
        setTokenData({ ...tokenData, tx: res.data.transactionHash });
        const acceptData = {
          nft_id: nft?._id,
          bid_id: nft?.bids[0]?._id,
          tx: res.data.transactionHash,
        };
        console.log(res);
        //Save to backend
        if (res.status) {
          acceptBidCreator(acceptData);
          setTx(res.data.transactionHash);
          setStatus(2);
        } else {
          Swal.fire({
            icone: "error",
            title: t("Ooops..."),
            text: t("user canceled the transaction"),
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }
      } catch (e) {
        Swal.fire({
          icone: "error",
          title: t("Ooops..."),
          text: e.message,
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };

  const dataload = async () => {
    //get default wallet
    const wallet = await getAccounts();
    auth_account.account &&
      wallet.address &&
      !auth_account.account?.wallets.includes(wallet.address) &&
      (await importWalletCreator({ wallet: wallet.address }));
    saveWalletsCreator(0, wallet.address);
    setSelectWallet(nft?.owner?.create_wallet);
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }

    //contrat setting
    setMultiple(props.status);
  };

  useEffect(() => {
    if (privateKey) dataload();
  }, [userPrincipal, privateKey, account, chain, isMultiple]);

  return (
    <div>
      <ModalAndOverlay colors={props.colors}>
        <AcceptBid colors={props.colors}>
          <div>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading title">
                {t("Accept bid with card")}
              </h3>
              <CloseIcon
                className="modal-close-icon"
                color={props.colors?.text1}
                onClick={() => setFiatPay(false)}
              />
            </div>
            <div id="wert-widget"></div>
            {wertPaymentStatus == "success" && (
              <Button
                disabled={status === 0 ? false : true}
                className={`modal-button ${props?.colors?.button}`}
                onClick={() => {
                  setFiatPay(false);
                  acceptBidPrice();
                }}
              >
                {t("Proceed")}
              </Button>
            )}
          </div>
        </AcceptBid>
      </ModalAndOverlay>

      {!isFiat && (
        <ModalAndOverlay colors={props.colors}>
          <Modals colors={props.colors}>
            <AcceptBid colors={props.colors}>
              <div className="d-flex justify-content-between mb-4">
                <h3 className="modal-heading title">
                  {t("accept_highest_bid")}
                </h3>
                <CloseIcon
                  className="modal-close-icon"
                  color={props.colors?.text1}
                  onClick={props.closeOfferModal}
                />
              </div>
              {status === 0 ? (
                <>
                  <div>
                    <div className="note">
                      {t("are_you_sure")}
                      <span className="purple-text">
                        {" "}
                        {t("accept_highest_bid")}{" "}
                      </span>
                    </div>
                    <span>{t("from")}</span>
                    <span className="purple-text">
                      {" "}
                      {nft?.highest_bid?.user?.name || "Test"}{" "}
                    </span>
                  </div>
                  <div>
                    <div className="mt-4">{t("bid_amount")}</div>
                    <div className="price">
                      {nft?.highest_bid?.price || 0}
                      <span className="mx-2 chain">{nft?.blockchain}</span>
                    </div>
                  </div>
                </>
              ) : status === 2 ? (
                <>
                  <h2>{t("Yay!")} 🎉</h2>
                  <div className="message p-4">
                    <div className="d-flex table-title mb-3">
                      <span>{t("Status")}</span>
                      <span>{t("Transaction ID")}</span>
                    </div>
                    <div className="d-flex table-row">
                      <span>{t("Success")}</span>
                      <span>
                        <a
                          href={
                            chain === "ETH"
                              ? window.ETH_SCAN + "/" + `${txHash}`
                              : window.POLYGON_SCAN + "/" + `${txHash}`
                          }
                        >
                          {txHash.substring(40)}
                        </a>
                      </span>
                    </div>
                  </div>
                </>
              ) : status === 3 ? (
                <>
                  <h4>{t("transaction_failed")} 😢</h4>
                  <div className="message p-4">
                    <div className="d-flex table-row">
                      <span>{error}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mb-4 pb-2">
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      {status === 1 ? (
                        <Spinner animation="border" variant="primary" />
                      ) : status > 1 ? (
                        <Image src={completedIcon}></Image>
                      ) : (
                        <Image src={lockOrderIcon}></Image>
                      )}
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <div className="m-0 modal-section-title title">
                        <div>{t("Accept Bid")}</div>
                      </div>
                      <p className="m-0 modal-section-text">
                        {t("Call contract method")}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <Button
                className="modal-button mb-3 mt-4"
                onClick={() => {
                  status === 2 ? props.closeOfferModal() : acceptBidPrice();
                }}
              >
                {status === 0
                  ? t("Confirm")
                  : status === 1
                  ? t("Accepting...")
                  : status === 2
                  ? t("Done")
                  : t("Faild")}
              </Button>
              {nft?.is_lazy ? (
                ""
              ) : (
                <Button
                  className={`modal-button ${props?.colors?.button}`}
                  id="burn-fiat"
                  onClick={() => {
                    setFiatPay(true);
                    acceptBidPrice(true);
                  }}
                >
                  {status === 0
                    ? t("Continue")
                    : status === 1
                    ? t("Accepting...")
                    : status === 2
                    ? t("Done")
                    : t("Faild")}{" "}
                  <img src={visamaster} width="80" height="30" />
                </Button>
              )}
              {/* <Button
                className={`modal-button ${props?.colors?.button}`}
                disabled={status === 0 || status === 2 ? false : true}
                onClick={() => props.closeOfferModal()}
              >
                {t("Cancel")}
              </Button> */}
            </AcceptBid>
          </Modals>
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default AcceptHighestBidModals;
