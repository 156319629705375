//* login
export const FETCH_GALLERIES = "FETCH_GALLERIES";
export const FETCH_GALLERIES_SUCCESS = "FETCH_GALLERIES_SUCCESS";
export const FETCH_GALLERIES_ERROR = "FETCH_GALLERIES_ERROR";

//* login
export const FETCH_PUBLIC_GALLERIES = "FETCH_PUBLIC_GALLERIES";
export const FETCH_PUBLIC_GALLERIES_SUCCESS = "FETCH_PUBLIC_GALLERIES_SUCCESS";
export const FETCH_PUBLIC_GALLERIES_ERROR = "FETCH_PUBLIC_GALLERIES_ERROR";

//* login
export const CREATE_GALLERY = "CREATE_GALLERY";
export const CREATE_GALLERY_SUCCESS = "CREATE_GALLERY_SUCCESS";
export const CREATE_GALLERY_ERROR = "CREATE_GALLERY_ERROR";

//* UPDATE GALLERY
export const UPDATE_GALLERY = "UPDATE_GALLERY";
export const UPDATE_GALLERY_SUCCESS = "UPDATE_GALLERY_SUCCESS";
export const UPDATE_GALLERY_ERROR = "UPDATE_GALLERY_ERROR";

//* DELETE GALLERY
export const DELETE_GALLERY = "DELETE_GALLERY";
export const DELETE_GALLERY_SUCCESS = "DELETE_GALLERY_SUCCESS";
export const DELETE_GALLERY_ERROR = "DELETE_GALLERY_ERROR";

//* DELETE GALLERY
export const FETCH_GALLERY_BY_ID = "FETCH_GALLERY_BY_ID";
export const FETCH_GALLERY_BY_ID_SUCCESS = "FETCH_GALLERY_BY_ID_SUCCESS";
export const FETCH_GALLERY_BY_ID_ERROR = "FETCH_GALLERY_BY_ID_ERROR";

export const RESET_FETCH_GALLERIES = "RESET_FETCH_GALLERIES";
export const CREATE_GALLERY_RESET = "CREATE_GALLERY_RESET";
export const GALLERY_BY_ID_RESET = "GALLERY_BY_ID_RESET";
export const UPDATE_GALLERY_RESET = "UPDATE_GALLERY_RESET";
export const DELETE_GALLERY_RESET = "DELETE_GALLERY_RESET";

//* login
export const FETCH_FEATURED_GALLERIES = "FETCH_FEATURED_GALLERIES";
export const FETCH_FEATURED_GALLERIES_SUCCESS =
  "FETCH_FEATURED_GALLERIES_SUCCESS";
export const FETCH_FEATURED_GALLERIES_ERROR = "FETCH_FEATURED_GALLERIES_ERROR";

//* login
export const RESET_FETCH_FEATURED_GALLERIES = "RESET_FETCH_FEATURED_GALLERIES";
