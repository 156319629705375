/* eslint-disable eqeqeq */
import * as nftTypes from "../types/nft";
import * as API from "../../../utils/axios";
import axios from "axios";
import { catchError } from "./error";

export const resetNFTActionsData = () => ({
  type: nftTypes.RESET_NFT_ACTIONS,
});
export const getLatestNFTsCreator = (skip, limit = 12) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_LATEST_NFTS_LOADING,
      loading: true,
    });

    API.get("api/nft/latest", { skip, limit })
      .then((res) => {
        dispatch({
          type: nftTypes.GET_LATEST_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_LATEST_NFTS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const changeNftPriceCreator = (id, body) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.CHANGE_NFT_PRICE_LOADING,
      loading: true,
    });

    API.put(`api/nft/price/change/${id}`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.CHANGE_NFT_PRICE_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.CHANGE_NFT_PRICE_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const approveNftCreator = (erc, body) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.APPROVE_NFT_LOADING,
      loading: true,
    });

    API.post(`api/nft/${erc}/approve`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.APPROVE_NFT_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.APPROVE_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getNFTDetailsCreator = (id, erc) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_NFT_DETAILS_LOADING,
      loading: true,
    });

    API.get(`api/nft/${erc}/details/${id}`, {})
      .then((res) => {
        dispatch({
          type: nftTypes.GET_NFT_DETAILS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);
        dispatch({
          type: nftTypes.GET_NFT_DETAILS_ERROR,
          loading: false,
          error: error.response.data,
          error_status: error.response.status,
        });
      });
  };
};

export const updateNFTCollectionsCreator = (nftId, collectionId) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.UPDATE_NFT_COLLECTION_LOADING,
      loading: true,
    });

    API.put(`api/nft/updateCollection`, {
      collectionId: collectionId,
      nftId: nftId,
    })
      .then((res) => {
        dispatch({
          type: nftTypes.UPDATE_NFT_COLLECTION_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.UPDATE_NFT_COLLECTION_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getFeaturedNFTsCreator = (id) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_FEATURED_NFTS_LOADING,
      loading: true,
    });

    API.get(`api/nft/featured`, {})
      .then((res) => {
        dispatch({
          type: nftTypes.GET_FEATURED_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_FEATURED_NFTS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getUserCreatedNFTsCreator = (id, params) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_USER_CREATED_NFTS_LOADING,
      loading: true,
    });

    API.get(`api/nft/created/${id}`, params)
      .then((res) => {
        dispatch({
          type: nftTypes.GET_USER_CREATED_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
          has_more: res.data.data?.length === 16,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_USER_CREATED_NFTS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getUserCollectedNFTsCreator = (id, params) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_USER_COLLECTED_NFTS_LOADING,
      loading: true,
    });

    API.get(`api/nft/collected/${id}`, params)
      .then((res) => {
        dispatch({
          type: nftTypes.GET_USER_COLLECTED_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
          has_more: res.data.data?.length === 16,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_USER_COLLECTED_NFTS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getUserCLikedNFTsCreator = (id, params) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_USER_LIKED_NFTS_LOADING,
      loading: true,
    });

    API.get(`api/nft/liked/${id}`, params)
      .then((res) => {
        dispatch({
          type: nftTypes.GET_USER_LIKED_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
          has_more: res.data.data?.length === 16,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_USER_LIKED_NFTS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const createNFTCreator = (body, erc) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.CREATE_NFT_LOADING,
      loading: true,
    });

    API.post(`api/nft/${erc}/create/`, body, (progress) => {
      dispatch({
        type: nftTypes.UPLOAD_NFT_PROGRESS,
        progress,
      });
    })
      .then((res) => {
        dispatch({
          type: nftTypes.CREATE_NFT_SUCCESS,
          action: "create",
          nft_id: res.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.CREATE_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
export const updateNFTCreator = (body, erc, nft_id) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.UPDATE_NFT_LOADING,
      loading: true,
    });

    API.post(`api/nft/${erc}/update/${nft_id}`, body, (progress) => {
      dispatch({
        type: nftTypes.UPLOAD_NFT_PROGRESS,
        progress,
      });
    })
      .then((res) => {
        dispatch({
          type: nftTypes.UPDATE_NFT_SUCCESS,
          action: "update",
          nft_id: res.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.UPDATE_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
export const generateIPFSNFTCreator = (body, nft_id) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GENERATE_IPFS_LOADING,
      loading: true,
    });
    API.post(`api/nft/ipfs/generate/${nft_id}`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.GENERATE_IPFS_SUCCESS,
          action: "update",
          data: res.data.data,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GENERATE_IPFS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
export const getEditNFTDetailsCreator = (erc, id) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_EDIT_NFT_DETAILS_LOADING,
      loading: true,
    });

    API.get(`api/nft/data/${erc}/${id}`, {})
      .then((res) => {
        dispatch({
          type: nftTypes.GET_EDIT_NFT_DETAILS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_EDIT_NFT_DETAILS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const purchaseNFTCreator = (nft_id, body, erc) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.PURCHASE_NFT_LOADING,
      loading: true,
    });

    API.post(`api/nft/${erc}/purchase/${nft_id}`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.PURCHASE_NFT_SUCCESS,
          action: "purchase",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.PURCHASE_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const cancelNFTCreator = (nft_id, erc, body) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.CANCEL_NFT_LOADING,
      loading: true,
    });

    API.put(`api/nft/${erc}/cancel/${nft_id}`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.CANCEL_NFT_SUCCESS,
          action: "cancel",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.CANCEL_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const burnNFTCreator = (nft_id, erc, body) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.BURN_NFT_LOADING,
      loading: true,
    });

    API.put(`api/nft/${erc}/burn/${nft_id}`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.BURN_NFT_SUCCESS,
          action: "burn",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.BURN_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const putOnSaleCreator = (nft_id, body, erc) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.LIST_NFT_LOADING,
      loading: true,
    });

    API.put(`api/nft/${erc}/list/${nft_id}`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.LIST_NFT_SUCCESS,
          action: "list",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.LIST_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
export const likeNFTCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.LIKE_NFT_LOADING,
      loading: true,
    });

    API.post(`api/nft/like`, body)
      .then((res) => {
        dispatch({
          type: nftTypes.LIKE_NFT_SUCCESS,
          payload: res?.data?.data == "like" ? true : false,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        alert("error", error);
        dispatch({
          type: nftTypes.LIKE_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getExternalMoralisNFTsCreator = (wallet, params) => {
  return (dispatch) => {
    if (wallet) {
      dispatch({
        type: nftTypes.GET_EXTERNAL_MORALIS_NFTS_LOADING,
        loading: true,
      });

      API.get(`api/nft/moralis/${wallet}/nft`, params).then(async (res) => {
        //* extract the token metadata from token_uri or directly from metadata prop
        const nfts = [];
        const results = res.data.data.result;
        for (let i = 0; i < results?.length; i++) {
          if (results[i]?.metadata) {
            const metadata = JSON.parse(results[i]?.metadata);

            nfts.push({
              _id: "none",
              token_id: results[i].token_id,
              contract_address: results[i].token_address,

              url: metadata?.image,
              name: metadata?.name,
              token_standard: results[i]?.contract_type.substring(3),
              type: "image",
              no_action: true,
            });
          } else if (results[i].token_uri) {
            await axios
              .get(results[i]?.token_uri)
              .then((res) => {
                res?.data?.data &&
                  nfts.push({
                    _id: "none",
                    token_id: results[i].token_id,
                    contract_address: results[i].token_address,
                    url: res?.data?.data?.image,
                    name: res?.data?.data?.name,
                    token_standard: results[i]?.contract_type.substring(3),
                    type: "image",
                    no_action: true,
                  });
              })
              .catch((error) => {
                catchError(error, dispatch);

                return;
              });
          }
        }

        //* start dispatch the data
        dispatch({
          type: nftTypes.GET_EXTERNAL_MORALIS_NFTS_SUCCESS,
          loading: false,
          payload: nfts,
          cursor: res.data.data.cursor,
          has_more: res.data.data.cursor ? true : false,
          chain: params?.chain || "matic",
        });
      });
    }
  };
};

export const getAllNftsCreator = (params, load) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_ALL_NFTS_LOADING,
      loading: true,
    });

    API.get(`api/nft/`, params)
      .then((res) => {
        dispatch({
          type: nftTypes.GET_ALL_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          has_more: res.data.data?.length === 20,
          load,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_ALL_NFTS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getLinkedNftDetailsCreator = (params) => {
  return (dispatch) => {
    dispatch({
      type: nftTypes.GET_LINKED_NFT_DETAILS_LOADING,
      loading: true,
    });

    API.get("api/nft/moralis/details", params)
      .then(async (res) => {
        let nft = null;
        if (res.data.data.metadata) {
          const metadata = JSON.parse(res.data.data?.metadata);

          nft = {
            _id: "none",

            creators: { minter: { create_wallet: res.data.data.owner_of } },

            price: parseFloat(res.data.data.amount),
            token_id: res.data.data.token_id,
            contract_address: res.data.data.token_address,
            url: metadata?.image,
            name: metadata?.name,
            // description: metadata?.description,
            token_standard: res.data.data?.contract_type.substring(3),
            type: "image",
            no_action: true,
          };
        } else if (res.data.data.token_uri) {
          await axios
            .get(res.data.data?.token_uri)
            .then((response) => {
              if (response?.data?.data)
                nft = {
                  _id: "none",

                  creators: {
                    minter: { create_wallet: res.data.data.owner_of },
                  },

                  price: parseFloat(res.data.data.amount),
                  token_id: response.data.data.token_id,
                  contract_address: response.data.data.token_address,
                  url: response?.data?.data?.image,
                  name: response?.data?.data?.name,
                  // description: response?.data?.data?.description,
                  token_standard:
                    response.data.data?.contract_type.substring(3),
                  type: "image",
                  no_action: true,
                };
            })
            .catch((error) => {
              catchError(error, dispatch);

              return;
            });
        }
        dispatch({
          type: nftTypes.GET_LINKED_NFT_DETAILS_SUCCESS,
          payload: nft,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: nftTypes.GET_LINKED_NFT_DETAILS_ERROR,
          error: error.response.data,
        });
      });
  };
};

export const createNftProgressCreator = (status) => {
  return (dispatch) =>
    dispatch({
      type: nftTypes.CREATE_NFT_PROGRESS,
      payload: status,
    });
};
