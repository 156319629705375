/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row, Col, Spinner } from "react-bootstrap";
// import { Link } from 'react-router-dom'
import "./profile.css";
import facebook from "../icons/facebook.svg";
import twitter from "../icons/twitter.svg";
import instagram from "../icons/instagram.svg";
import youtube from "../icons/youtube.svg";
import { IoIosGlobe } from "react-icons/io";
import { useState, useEffect } from "react";
import DropDown from "../components/DropDown";
// import FollowersModal from "../components/Profile/FollowersModal";
// import { AuthContext } from "../context/AuthContext";
import profileCover from "../assets/images/profile-cover.png";
import NFTs from "../components/Profile/NFTs";
import CollectionNFTs from "../components/Profile/CollectionNFTs";
import keys from "../lib/config";
// import useFetch from "../customHooks/useFetch";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreateCollectionPopup from "./CreateCollectionPopup";
import edit from "./../assets/icons/edit.svg";
import Swal from "sweetalert2";

// import { useWeb3Auth } from '../context/Web3auth'
// import HotBids from '../components/HotBids'
// import DropDown from '../components/DropDown'
// import NewCollections from '../components/NewCollections'
// import MoralisTest from "./MoralisTest";

// const user = JSON.parse(localStorage.getItem('user'))

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  creatorCollectionsCreators,
  nftActionsCreators,
  profileActionsCreators,
} from "../store";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "reactstrap";
import { NftCard } from "../components/NftCard";

const options = [
  { label: "Newly", id: "0" },
  { label: "This Week", id: "1" },
  { label: "This Month", id: "2" },
];
export const Profile = () => {
  const { user_id, tab } = useParams();
  const { account } = useSelector((state) => state.auth);
  const { loading, user, error } = useSelector((state) => state.profile);
  const {
    collections,
    hasMore: collectionMore,
    count: collectionCount,
    collectionCreated,
    loading: collectionsLoading,
  } = useSelector((state) => state.creatorCollections);

  const {
    created_nfts,
    collected_nfts,
    liked_nfts,
    has_more_created,
    has_more_collected,
    has_more_liked,
  } = useSelector((state) => state.profile_nfts);

  const categoryState = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const { getUserProfile } = bindActionCreators(
    profileActionsCreators,
    dispatch
  );

  const { getUserCreatedNFTsCreator, getUserCollectedNFTsCreator } =
    bindActionCreators(nftActionsCreators, dispatch);

  const {
    fetchUserCollectionCreator,
    resetCollectionState,
    resetCollectionFlags,
  } = bindActionCreators(creatorCollectionsCreators, dispatch);

  // const {
  //     items: collections,
  //     loading,
  //     error,
  // } = useFetch(window.API_URL + '/api/nft/collections')
  let navigate = useNavigate();

  const [currentSection, setCurrentSection] = useState(tab || "created");
  const { t, i18n } = useTranslation();

  // const authContext = useContext(AuthContext)
  // const user = authContext.user && authContext.user.data

  const [profile, setProfile] = useState({});
  const [isOpenCreateCollection, setOpenCreateCollection] = useState(false);

  // const [showFollowersModal, setShowFollowersModal] = useState(false);
  // const [showFollowingModal, setShowFollowingModal] = useState(false);
  // const { userPrincipal } = useWeb3Auth()
  // const [loading, setLoading] = useState(true)
  // const [error, setError] = useState('')
  const toEditProfile = () => {
    navigate("/edit-profile");
  };

  useEffect(() => {
    if (tab == "collections")
      fetchUserCollectionCreator({ creatorId: user_id });
    if (tab) setCurrentSection(tab);
  }, [tab]);

  useEffect(() => getUserProfile(user_id), [user_id]);

  useEffect(() => {
    if (collectionCreated) {
      setOpenCreateCollection(false);
      Swal.fire({
        icon: "success",
        text: t("collection_created"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetCollectionFlags);
        },
        confirmButtonColor: "#6d3190",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionCreated]);

  let walletProfile = "";
  if (profile.walletAddress) {
    walletProfile = (
      <a
        href={
          "https://polygonscan.com/address/" + profile.walletAddress[0].address
        }
        className="d-block break-line"
        onClick={() => {
          navigator.clipboard.writeText(profile.walletAddress[0].address);
        }}
        target="_blank"
      >
        {`${profile.walletAddress[0].address} `}
        <i className="wallet-address-icon"></i>
      </a>
    );
  }
  const currentUser = account?._id == user_id;

  return (
    <div>
      {/* cover photo */}
      <div className="cover-photo">
        <img
          src={user?.profile?.cover_ur || profileCover}
          className="profile-bg"
          alt=""
        />
      </div>
      <Container>
        {/* profile info */}
        <div className="profile-info">
          {loading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <div>
              <Row>
                <Col
                  xs={12}
                  lg={8}
                  className="d-flex flex-column flex-lg-row align-items-center"
                >
                  <img
                    src={user?.profile?.image_url || "/user-placeholder.png"}
                    alt=""
                    width="120"
                    height="120"
                    className="rounded-full me-3 mb-3"
                    style={{ objectFit: "cover" }}
                  />
                  <div className="text-center text-lg-start flex-fill">
                    <div style={{ fontSize: 27 }}>{user?.name}</div>
                    {walletProfile}
                    <p className="profile-message">{user?.bio}</p>
                    <p className="member-since">
                      {t("Member since")}{" "}
                      {new Date(user?.createdAt).toLocaleDateString(
                        i18n.language,
                        {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      )}
                    </p>
                    <div className="d-flex justify-content-center justify-content-lg-start">
                      {/* {account?._id !== user_id ? (
                    <a href="#" className="btn btn-purple radius-50 px-4 me-1">
                      {t("Follow")}
                    </a>
                  ) : (
                    ""
                  )} */}
                      {/* <button className="button-icon-grey-40 me-1">
                                        <img src={upload} alt="" />
                                    </button>
                                    <button
                                        onClick={toEditProfile}
                                        className="button-icon-grey-40"
                                    >
                                        <img src={threeDots} alt="" />
                                    </button> */}
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={4}
                  className="mt-4 d-flex justify-content-center justify-content-lg-end"
                >
                  <div>
                    {/* <div className="mb-3">
                      <button
                        style={{ fontWeight: 350 }}
                        className="followers-btn me-4"
                        onClick={() => setShowFollowersModal(true)}
                      >
                        <span>{user?.followers}</span> {t("Followers")}
                      </button>
                      <button
                        style={{ fontWeight: 350 }}
                        className="followers-btn"
                        onClick={() => setShowFollowingModal(true)}
                      >
                        <span>{user?.following}</span> {t("Following")}
                      </button>
                    </div> */}
                    <div className="d-flex justify-content-center align-items-center">
                      {user?.media?.twitter && (
                        <a
                          href={user?.media?.twitter}
                          className="mx-3"
                          target="_blank"
                        >
                          <img src={twitter} alt="" />
                        </a>
                      )}
                      {user?.media?.instagram && (
                        <a
                          href={user?.media?.instagram}
                          className="mx-3"
                          target="_blank"
                        >
                          <img src={instagram} alt="" />
                        </a>
                      )}
                      {user?.media?.facebook && (
                        <a
                          href={user?.media?.facebook}
                          className="mx-3"
                          target="_blank"
                        >
                          <img src={facebook} alt="" />
                        </a>
                      )}
                      {user?.media?.youtube && (
                        <a
                          href={user?.media?.youtube}
                          className="mx-3"
                          target="_blank"
                        >
                          <img src={youtube} alt="" />
                        </a>
                      )}
                      {user?.media?.website && (
                        <a
                          href={user?.media?.website}
                          className="mx-3"
                          target="_blank"
                        >
                          <IoIosGlobe
                            style={{
                              width: "24px",
                              height: "24px",
                              color: "#777e90",
                            }}
                          ></IoIosGlobe>
                        </a>
                      )}
                    </div>
                    {account?._id === user_id ? (
                      <div>
                        <div className="editcontainer">
                          {/* <button className='editbtn'>{t('Edit cover photo')} <img src={edit}  alt="" /></button> */}
                          <button
                            className="editbtn px-4"
                            onClick={toEditProfile}
                          >
                            {t("Edit profile")} <img src={edit} alt="" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        {/* links */}
        <Row>
          <Col md="9">
            <div className="profile-navs">
              <ul className="nav nav-pills">
                <li className="nav-item m-2">
                  <Link
                    to={`/profile/${user_id}/created`}
                    className={`nav-link ${
                      currentSection === "created" ? "active" : ""
                    }`}
                    aria-current="page"
                  >
                    {t("Created")}
                  </Link>
                </li>
                <li className="nav-item m-2">
                  <Link
                    className={`nav-link ${
                      currentSection === "collected" ? "active" : ""
                    }`}
                    to={`/profile/${user_id}/collected`}
                  >
                    {t("Collected")}
                  </Link>
                </li>
                <li className="nav-item m-2">
                  <Link
                    className={`nav-link ${
                      currentSection === "collections" ? "active" : ""
                    }`}
                    to={`/profile/${user_id}/collections`}
                  >
                    {t("Collections")}
                  </Link>
                </li>
                {/* <li className="nav-item m-2">
                  <Link
                    className={`nav-link ${
                      currentSection === "liked" ? "active" : ""
                    }`}
                    to={`/profile/${user_id}/liked`}
                  >
                    {t("Liked")}
                  </Link>
                </li> */}
              </ul>
            </div>
          </Col>
          {/* TODO: make the filter worke  */}
          {/* <Col md="3">
            <div className="profile-navs">
              <DropDown
                label={t("Newly")}
                options={options}
                created_nfts={created_nfts}
              />
            </div>
          </Col> */}
        </Row>
        {/* sections */}
        {!loading && (
          <div className="pt-2">
            {currentSection === "created" &&
              (created_nfts?.length > 0 ? (
                <InfiniteScroll
                  style={{
                    width: "100%",
                    padding: "10px 0",
                    overflow: "none",
                    marginBottom: "100px",
                  }}
                  dataLength={created_nfts?.length}
                  next={() =>
                    created_nfts?.length >= 16 &&
                    getUserCreatedNFTsCreator(user_id, {
                      skip: created_nfts?.length,
                    })
                  }
                  hasMore={has_more_created}
                  loader={<Spinner color="#6d3190" />}
                  endMessage=""
                >
                  <Row>
                    {created_nfts.map((el) => (
                      <Col xs={12} md={6} lg={3} style={{ margin: "10px 0" }}>
                        <NftCard item={el} />
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center pb-5 mb-5">
                  <img
                    width="450"
                    height="450"
                    style={{ objectFit: "contain" }}
                    src={require("../assets/images/empty-section.png")}
                  />
                  <div
                    style={{ fontSize: 22, textAlign: "center" }}
                    className="mt-4 "
                  >
                    {currentUser
                      ? t("You don't have any created NFTs!")
                      : t("There are no created NFTs!")}
                  </div>
                  {currentUser && (
                    <Link to="/nft/create">
                      <div className="btn btn-primary gradient mt-4 ">
                        {t("Create")}
                      </div>
                    </Link>
                  )}
                </div>
              ))}
            {currentSection === "collected" &&
              (collected_nfts?.length > 0 ? (
                <InfiniteScroll
                  style={{
                    width: "100%",
                    padding: "10px 0",
                    overflow: "none",
                    marginBottom: "100px",
                  }}
                  dataLength={collected_nfts?.length}
                  next={() =>
                    collected_nfts?.length >= 16 &&
                    getUserCollectedNFTsCreator(user_id, {
                      skip: collected_nfts?.length,
                    })
                  }
                  hasMore={has_more_collected}
                  loader={<Spinner color="#6d3190" />}
                  endMessage=""
                >
                  <Row>
                    {collected_nfts.map((el) => (
                      <Col xs={12} md={6} lg={3} style={{ margin: "10px 0" }}>
                        <NftCard item={el} lableSold={false} />
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center mt-4 pb-5 mb-5">
                  <img
                    width="450"
                    height="450"
                    style={{ objectFit: "contain" }}
                    src={require("../assets/images/empty-section.png")}
                  />
                  <div
                    style={{ fontSize: 22, textAlign: "center" }}
                    className="mt-4"
                  >
                    {currentUser
                      ? t("You don't have any collected NFTs!")
                      : t("There are no collected NFTs!")}
                  </div>
                  {currentUser && (
                    <Link to={`/discover`}>
                      <div className="btn btn-primary gradient mt-4">
                        {t("Collect")}
                      </div>
                    </Link>
                  )}
                </div>
              ))}
            {currentSection === "collections" &&
              (collectionsLoading ? (
                <p>{t("Loading...")}</p>
              ) : collections?.length > 0 ? (
                <div>
                  {account?._id === user_id && (
                    <Button
                      style={{ borderRadius: 20, background: "#6d3190" }}
                      onClick={() => setOpenCreateCollection(true)}
                    >
                      {t("create_collection")}
                    </Button>
                  )}
                  <InfiniteScroll
                    style={{
                      width: "100%",
                      padding: "10px 0",
                      overflow: "none",
                      marginBottom: "100px",
                      marginTop: 20,
                    }}
                    dataLength={collections?.length}
                    next={() =>
                      collections?.length >= 16 &&
                      fetchUserCollectionCreator(
                        { creatorId: user_id },
                        {
                          skip: collections?.length,
                          limit: 16,
                        }
                      )
                    }
                    hasMore={collectionMore}
                    loader={<Spinner color="#ee2a7b" />}
                    endMessage=""
                  >
                    <CollectionNFTs collections={collections} />
                  </InfiniteScroll>
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center mt-4 pb-5 mb-5">
                  <img
                    width="450"
                    height="450"
                    style={{ objectFit: "contain" }}
                    src={require("../assets/images/empty-section.png")}
                  />
                  <div
                    style={{ fontSize: 22, textAlign: "center" }}
                    className="mt-4"
                  >
                    {currentUser
                      ? t("You don't have any collections!")
                      : t("There are no collections!")}
                  </div>
                  {currentUser && (
                    <div onClick={() => setOpenCreateCollection(true)}>
                      <div className="btn btn-primary gradient mt-4">
                        {t("Create")}
                      </div>
                    </div>
                  )}
                </div>
              ))}

            {currentSection === "liked" &&
              (liked_nfts?.length > 0 ? (
                <NFTs nfts={liked_nfts} />
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center mt-4 pb-5 mb-5">
                  <img
                    width="450"
                    height="450"
                    style={{ objectFit: "contain" }}
                    src={require("../assets/images/empty-section.png")}
                  />
                  <div
                    style={{ fontSize: 22, textAlign: "center" }}
                    className="mt-4"
                  >
                    {currentUser
                      ? t("You don't have any liked NFTs!")
                      : t("There are no liked NFTs!")}
                  </div>
                </div>
              ))}
          </div>
        )}
        {/* <Row>
          <FollowersModal
            title="Followers"
            show={showFollowersModal}
            onHide={() => setShowFollowersModal(false)}
          />
          <FollowersModal
            title="Following"
            show={showFollowingModal}
            onHide={() => setShowFollowingModal(false)}
          />
        </Row> */}
      </Container>
      <CreateCollectionPopup
        isOpen={isOpenCreateCollection}
        setIsOpen={setOpenCreateCollection}
        categories={categoryState.categories}
        creatorId={account?._id}
      />
    </div>
  );
};
