export const API_PATH = {
  GALLERY: () => `api/gallery`,
  PUBLIC_GALLERIES: (limit, offset, status) =>
    `api/gallery/public/${status}/${limit}/${offset}`,
  CREATE_GALLERY: "api/gallery",
  UPDATE_GALLERY: (galleryId) => `api/gallery/${galleryId}`,
  DELETE_GALLERY: (galleryId) => `api/gallery/${galleryId}`,
  GALLERY_BY_ID: () => `api/gallery/details`,
  CREATE_GALLERY_ARTIST: (galleryId) => `api/gallery/${galleryId}/artist`,
  UPDATE_GALLERY_ARTIST: (galleryId, artistId) =>
    `api/gallery/${galleryId}/artist/${artistId}`,
  GET_GALLERY_ARTISTS: (galleryId, limit, offset) =>
    `api/gallery/${galleryId}/artist/${limit}/${offset}`,
  GET_PUBLIC_GALLERY_ARTISTS: (galleryId, limit, offset) =>
    `api/gallery/public/${galleryId}/artist/${limit}/${offset}`,
  GET_GALLERY_ARTIST_BY_ID: (galleryId, artistId) =>
    `api/gallery/${galleryId}/artist/${artistId}`,
  DELETE_GALLERY_ARTIST: (galleryId, artistId) =>
    `api/gallery/${galleryId}/artist/${artistId}`,
  ASSIGN_GALLERY_ARTIST: (galleryId) =>
    `api/gallery/${galleryId}/artist/assignment`,
  FEATURED_GALLERY: (limit, offset) =>
    `api/gallery/featured/galleries/${limit}/${offset}`,
  GET_ARTIST: (artistId) => `api/artist/${artistId}`,
};
