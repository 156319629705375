import React from "react";
import { Modal } from "react-bootstrap";

const ChangeCollectionModal = ({ nft, isOpen, setIsOpen }) => {
  return (
    <Modal size="lg" show={isOpen} onHide={() => setIsOpen(false)} centered>
      <div className="d-flex justify-content-center align-items-center">
        {nft?.type === "image" ? (
          <img
            alt=""
            src={nft?.url}
            style={{ objectFit: "contain", height: "90vh", width: "90vw" }}
          />
        ) : nft?.type === "video" ? (
          <video
            muted
            autoPlay
            loop
            controls
            controlsList="nodownload"
            style={{ objectFit: "contain", height: "90vh", width: "90vw" }}
            webkit-playsinline="true"
            playsInline
          >
            <source src={nft?.url} type="video/mp4" />
          </video>
        ) : (
          nft?.type === "audio" && (
            <audio controls controlsList="nodownload" muted>
              <source src={nft?.url} type="audio/mp3" />
            </audio>
          )
        )}
      </div>
    </Modal>
  );
};

export default ChangeCollectionModal;
