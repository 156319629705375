import { Container, Row, Col, Dropdown } from "react-bootstrap";
// import HotBids from "../components/HotBids";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import chevronDown from "../icons/chevron-down.svg";
// import "./FAQ.css";

const Wrapper = styled.div`
  padding-top: 55px;
  padding-bottom: 100px;
  input,
  textarea {
    max-width: 500px;
    border: #e6e8ec solid 2px;
    border-radius: 12px;
  }
  .header {
    font-size: 45px;
  }
  .label {
    font-size: 14px;
    text-transform: uppercase;
    color: #b1b5c3;
  }
  .faq-heading {
    padding-top: 25px;
    padding-bottom: 75px;
  }
  .faq-small-text {
    font-size: 14px;
    color: #777e90;
    font-weight: 400;
  }
  .faq-title {
    font-size: 48px;
    line-height: 64px;
    font-weight: 400;
    color: #23262f;
    letter-spacing: -1px;
  }
  .faq-text {
    font-size: 18px;
    color: #353945;
    font-weight: 400;
    max-width: 700px;
  }
  .faq-link {
    color: #6d3190;
    font-weight: 400;
    text-decoration: none;
  }
  .faq-icon {
    height: 16px;
    width: 16px;
    margin-right: 20px;
    margin-top: -4px;
  }
  .faq-span {
    color: #777e90;
    font-size: 16px;
  }
  .faq-links {
    display: block;
    font-weight: 300;
    text-decoration: none;
  }
  /* --- accordion --- */
  .accordion a {
    text-decoration: none;
    color: #23262f;
    padding: 8px 16px;
    border: 2px #6d3190 solid;
    border-radius: 25px;
    display: inline-block;
    margin-top: 12px;
  }
  .accordion-item {
    border: none !important;
    border-top: 1px solid #e6e8ec !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }
  .accordion-button,
  .accordion-button:focus {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    color: #23262f !important;
    font-weight: 500;
    font-size: 18px;
  }
  .accordion-body {
    border: none !important;
  }
  .accordion-body p {
    font-size: 18px !important;
    color: #777e90 !important;
    font-weight: 500 !important;
  }
  /* --- dropdown --- */
  .dropdown {
    margin-bottom: 64px !important;
  }
  #dropdown-basic {
    width: 100% !important;
    text-align: left !important;
    border-radius: 12px !important;
    background-color: white !important;
    color: #23262f !important;
    border: 1px solid #e6e8ec !important;
    height: 48px;
    font-weight: 500;
    position: relative;
  }
  #dropdown-basic::after {
    display: none !important;
  }
  .dropdown-menu {
    width: 100% !important;
    padding: 0 !important;
  }
  .dropdown-item {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }
  .btn-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 14px;
    right: 16px;
  }
  .faqs h3 {
    scroll-margin-top: 86px;
  }
  .faqs article {
    margin-bottom: 48px;
  }
  .faq-links {
    margin-top: 10px;
    padding: 10px;
    /* border: #6d3190 solid 1px; */
    /* background-color: #FCF8FA; */
    border-radius: 6px;
    color: #777e90 !important;
    font-size: 14px;
    font-weight: 450;
  }
  @media (max-width: 768px) {
    .faq-heading {
      padding-top: 75px;
      padding-bottom: 45px;
    }
  }
`;
export const FAQ = () => {
  const { t, i18n } = useTranslation();
  return (
    <Wrapper>
      <Container className="pb-5">
        {/* --------- faq heading --------- */}
        <div className="faq-heading">
          <div className="faq-title page-header">
            {t("Frequently Asked Questions")}
          </div>
          <p className="faq-text">{t("Learn_more_001")}</p>
        </div>
        <Row>
          {/* --------- dropdown --------- */}
          <Col className="d-block d-md-none">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {t("General")}
                <img
                  src={chevronDown}
                  alt="chevron down"
                  className="btn-icon"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">Support</Dropdown.Item>
                <Dropdown.Item href="#">Hosting</Dropdown.Item>
                <Dropdown.Item href="#">Product</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          {/* --------- links --------- */}
          <Col className="d-none d-md-block">
            <div className="pe-5">
              <a href="#q1" className="faq-links">
                {t("What is Nifty Souq?")}
              </a>
              <a href="#q2" className="faq-links">
                {t("What is an NFT?")}
              </a>
              <a href="#q3" className="faq-links">
                {t("How can I create an NFT?")}
              </a>
              <a href="#q4" className="faq-links">
                {t("How do I buy an NFT?")}
              </a>
              <a href="#q5" className="faq-links">
                {t("How can I sell my NFT?")}
              </a>
              <a href="#q6" className="faq-links">
                {t("How can I top-up my wallet?")}
              </a>
              <a href="#q7" className="faq-links">
                {t("How can I link an external digital wallet?")}
              </a>
              <a href="#q8" className="faq-links">
                {t("How does the auction work?")}
              </a>
              <a href="#q9" className="faq-links">
                {t("What can I do with the NFT I have purchased?")}
              </a>
              <a href="#q10" className="faq-links">
                {t("What does minting mean?")}
              </a>
              <a href="#q11" className="faq-links">
                {t("What is lazy-minting?")}
              </a>
              <a href="#q12" className="faq-links">
                {t("What are crypto digital wallets?")}
              </a>
              <a href="#q13" className="faq-links">
                {t("What are smart contracts?")}
              </a>
              <a href="#q14" className="faq-links">
                {t("What are the resell fees on Nifty Souq?")}
              </a>
              <a href="#q15" className="faq-links">
                {t("How do I know if my NFT is authentic?")}
              </a>
              <a href="#q16" className="faq-links">
                {t("How does the royalty system work?")}
              </a>
              <a href="#q17" className="faq-links">
                {t("What is split royalty?")}
              </a>
              <a href="#q18" className="faq-links">
                {t("What is split revenue?")}
              </a>
              <a href="#q19" className="faq-links">
                {t("What is explicit content?")}
              </a>
              <a href="#q20" className="faq-links">
                {t("q20_Q")}
              </a>
              <a href="#q21" className="faq-links">
                {t("q21_Q")}
              </a>
              <a href="#q22" className="faq-links">
                {t("q22_Q")}
              </a>
              <a href="#q23" className="faq-links">
                {t("q23_Q")}
              </a>
              <a href="#q23" className="faq-links">
                {t("q23_Q")}
              </a>
              <a href="#q24" className="faq-links">
                {t("q24_Q")}
              </a>
              <a href="#q25" className="faq-links">
                {t("q25_Q")}
              </a>
              <a href="#q26" className="faq-links">
                {t("q26_Q")}
              </a>
            </div>
          </Col>
          {/* --------- accordion --------- */}
          <Col className="faqs" xs={12} md={9}>
            <h3 id="q1">{t("What is Nifty Souq?")}</h3>
            <article>{t("q1_answer")}</article>
            <h3 id="q2">{t("What is an NFT?")}</h3>
            <article>
              <p>{t("q2_answer1")}</p>
              <p>{t("q2_answer2")}</p>
            </article>
            <h3 id="q3">{t("How can I create an NFT?")}</h3>
            <article>
              <p>{t("q3_answer")}</p>
              <ol>
                <li>{t("q3_ol_1")}</li>
                <li>{t("q3_ol_2")}</li>
                <li>{t("q3_ol_3")}</li>
                <li>{t("q3_ol_4")}</li>
                <li>{t("q3_ol_5")}</li>
                <li>{t("q3_ol_6")}</li>
                <li>{t("q3_ol_7")}</li>
                <li>{t("q3_ol_8")}</li>
                <li>{t("q3_ol_9")}</li>
                <li>{t("q3_ol_10")}</li>
                <li>{t("q3_ol_11")}</li>
              </ol>
            </article>
            <h3 id="q4">{t("How do I buy an NFT?")}</h3>
            <article>
              <p>{t("q4_answer")}</p>
              <ol>
                <li>{t("q4_ol_1")}</li>
                <li>{t("q4_ol_2")}</li>
                <li>{t("q4_ol_3")}</li>
                <li>{t("q4_ol_4")}</li>
                <li>{t("q4_ol_5")}</li>
                <li>{t("q4_ol_6")}</li>
                <li>{t("q4_ol_7")}</li>
              </ol>

              <p>{t("q4_answer2")}</p>
            </article>
            <h3 id="q5">{t("How can I sell my NFT?")}</h3>
            <article>
              <p>{t("q5_answer")}</p>
              <ol>
                <li>{t("q5_ol_1")}</li>
                <li>{t("q5_ol_2")}</li>
                <li>{t("q5_ol_3")}</li>
                <li>{t("q5_ol_4")}</li>
                <li>{t("q5_ol_5")}</li>
              </ol>
              <p>{t("q5_answer2")}</p>
            </article>
            <h3 id="q6">{t("How can I top-up my wallet?")}</h3>
            <article>
              <p>{t("q6_answer")}</p>
              <ol>
                <li>{t("q6_ol_1")}</li>
                <li>{t("q6_ol_2")}</li>
                <li>{t("q6_ol_3")}</li>
                <li>{t("q6_ol_4")}</li>
                <li>{t("q6_ol_5")}</li>
                <li>{t("q6_ol_6")}</li>
                <li>{t("q6_ol_7")}</li>
                <li>{t("q6_ol_8")}</li>
                <li>{t("q6_ol_9")}</li>
              </ol>
            </article>
            <h3 id="q7">{t("How can I link an external digital wallet?")}</h3>
            <article>
              <p>{t("q7_answer")}</p>
              <ol>
                <li>{t("q7_ol_1")}</li>
                <li>{t("q7_ol_2")}</li>
                <li>{t("q7_ol_3")}</li>
                <li>{t("q7_ol_4")}</li>
              </ol>
              <p>{t("q7_answer2")}</p>
            </article>
            <h3 id="q8">{t("How does the auction work?")}</h3>
            <article>
              <p>{t("q8_answer")}</p>
              <ol>
                <li>{t("q8_ol_1")}</li>
                <li>{t("q8_ol_2")}</li>
                <li>{t("q8_ol_3")}</li>
                <li>{t("q8_ol_4")}</li>
                <li>{t("q8_ol_5")}</li>
              </ol>
            </article>
            <h3 id="q9">{t("What can I do with the NFT I have purchased?")}</h3>
            <article>{t("q9_answer")}</article>
            <h3 id="q10">{t("What does minting mean?")}</h3>
            <article>{t("q10_answer")}</article>
            <h3 id="q11">{t("What is lazy-minting?")}</h3>
            <article>{t("q11_answer")}</article>
            <h3 id="q12">{t("What are crypto digital wallets?")}</h3>
            <article>
              <p>{t("q12_answer")}</p>

              <p>{t("q12_answer2")}</p>

              <p>{t("q12_answer3")}</p>
            </article>
            <h3 id="q13">{t("What are smart contracts?")}</h3>
            <article>
              <p>{t("q13_answer")}</p>

              <p>{t("q13_answer2")}</p>
            </article>
            <h3 id="q14">{t("What are the resell fees on Nifty Souq?")}</h3>
            <article>{t("q14_answer")}</article>
            <h3 id="q15">{t("How do I know if my NFT is authentic?")}</h3>
            <article>{t("q15_answer")}</article>
            <h3 id="q16">{t("How does the royalty system work?")}</h3>
            <article>{t("q16_answer")}</article>
            <h3 id="q17">{t("What is split royalty?")}</h3>
            <article>{t("q17_answer")}</article>
            <h3 id="q18">{t("What is split revenue?")}</h3>
            <article>{t("q18_answer")}</article>
            <h3 id="q19">{t("What is explicit content?")}</h3>
            <article>
              <ul>
                <li>{t("q19_answer")}</li>
                <li>{t("q19_answer2")}</li>
                <li>{t("q19_answer3")}</li>
              </ul>
            </article>
            <h3 id="q20">{t("q20_Q")}</h3>
            <article>
              <p>{t("q20_answerP")}</p>
              <ol>
                <li>{t("q20_answer")}</li>
                <li>{t("q20_answer2")}</li>
                <li>{t("q20_answer3")}</li>
                <li>{t("q20_answer4")}</li>
                <li>{t("q20_answer5")}</li>
                <li>{t("q20_answer6")}</li>
                <li>{t("q20_answer7")}</li>
                <li>{t("q20_answer8")}</li>
              </ol>
              <p>{t("q20_answerP2")}</p>
            </article>
            <h3 id="q21">{t("q21_Q")}</h3>
            <article>
              <p>{t("q21_answerP")}</p>
              <ol>
                <li>{t("q21_answer")}</li>
                <li>{t("q21_answer2")}</li>
                <li>{t("q21_answer3")}</li>
                <li>{t("q21_answer4")}</li>
                <li>{t("q21_answer5")}</li>
                <li>{t("q21_answer6")}</li>
                <li>{t("q21_answer7")}</li>
                <li>{t("q21_answer8")}</li>
              </ol>
            </article>
            <h3 id="q22">{t("q22_Q")}</h3>
            <article>
              <p>{t("q22_answerP")}</p>
              <ol>
                <li>{t("q22_answer")}</li>
                <li>{t("q22_answer2")}</li>
                <li>{t("q22_answer3")}</li>
                <li>{t("q22_answer4")}</li>
                <li>{t("q22_answer5")}</li>
                <li>{t("q22_answer6")}</li>
                <li>{t("q22_answer7")}</li>
                <li>{t("q22_answer8")}</li>
              </ol>
            </article>
            <h3 id="q23">{t("q23_Q")}</h3>
            <article>
              <p>{t("q23_answerP")}</p>
              <ol>
                <li>{t("q23_answer")}</li>
                <li>{t("q23_answer2")}</li>
                <li>{t("q23_answer3")}</li>
                <li>{t("q23_answer4")}</li>
              </ol>
              <p>{t("q23_answerP2")}</p>
            </article>
            <h3 id="q24">{t("q24_Q")}</h3>
            <article>
              <p>{t("q24_answerP")}</p>
              <ol>
                <li>{t("q24_answer")}</li>
                <li>{t("q24_answer2")}</li>
                <li>{t("q24_answer3")}</li>
                <li>{t("q24_answer4")}</li>
                <li>{t("q24_answer5")}</li>
              </ol>
              <p>{t("q24_answerP2")}</p>
            </article>
            <h3 id="q25">{t("q25_Q")}</h3>
            <article>
              <p>{t("q25_answerP")}</p>
              <ol>
                <li>{t("q25_answer")}</li>
                <li>{t("q25_answer2")}</li>
                <li>{t("q25_answer3")}</li>
                <li>{t("q25_answer4")}</li>
                <li>{t("q25_answer5")}</li>
                <li>{t("q25_answer6")}</li>
                <li>{t("q25_answer7")}</li>
              </ol>
            </article>
            <h3 id="q26">{t("q26_Q")}</h3>
            <article>
              <p>{t("q26_answerP")}</p>
              <ul>
                <li>{t("q26_answer")}</li>
                <li>{t("q26_answer2")}</li>
                <li>{t("q26_answer3")}</li>
                <li>{t("q26_answer4")}</li>
                <li>{t("q26_answer5")}</li>
                <li>{t("q26_answer6")}</li>
                <li>{t("q26_answer7")}</li>
                <li>{t("q26_answer8")}</li>
              </ul>
            </article>
          </Col>
        </Row>
      </Container>
      {/* --------- hot bids --------- */}
      {/* <Container className="home-box">
        <HotBids />
      </Container> */}
    </Wrapper>
  );
};
