export const GET_ETH_USD_LOADING = "GET_ETH_USD_LOADING";
export const GET_ETH_USD_SUCCESS = "GET_ETH_USD_SUCCESS";
export const GET_ETH_USD_ERROR = "GET_ETH_USD_ERROR";

export const GET_SIGNATURE_LOADING = "GET_SIGNATURE_LOADING";
export const GET_SIGNATURE_SUCCESS = "GET_SIGNATURE_SUCCESS";
export const GET_SIGNATURE_ERROR = "GET_SIGNATURE_ERROR";

export const CREATE_WERT_SIGNATURE_LOADING = "CREATE_WERT_SIGNATURE_LOADING";
export const CREATE_WERT_SIGNATURE_SUCCESS = "CREATE_WERT_SIGNATURE_SUCCESS";
export const CREATE_WERT_SIGNATURE_ERROR = "CREATE_WERT_SIGNATURE_ERROR";
