import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TimerBox from "@components/TimerBox";
import { intervalToDuration } from "date-fns";
import StylesWrapper from "./styles";
import moment from "moment";
const SaleButtons = ({ nft, externalNFT, colors, onOpenModal, nft_id }) => {
  const { t, i18n } = useTranslation();
  const [duration, setDuration] = useState(null);
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    let startTimerVar = null;
    function startTimer() {
      let startTimer = null;
      if (nft?.listing?.sale_type === "2" && nft?.listing?.auction_end_date) {
        if (new Date(nft?.listing?.auction_end_date.toString()) > Date.now()) {
          startTimerVar = setInterval(() => {
            let dur = intervalToDuration({
              start: new Date(),
              end: new Date(nft?.listing?.auction_end_date),
            });
            setDuration(dur);
          }, 1000);
        }
      }

      return {
        if(startTimerVar) {
          clearInterval(startTimerVar);
        },
      };
    }
    nft_id == nft?._id && startTimer();
  }, [nft?.listing]);

  const isOwner = auth?.authenticated && auth?.account?._id === nft?.owner?._id;

  const isAuctionRunning = moment(
    new Date(nft?.listing?.auction_end_date)
  ).isAfter(new Date());

  return (
    <StylesWrapper>
      {/* fixed price */}
      {nft?.listing?.sale_type === "1" && !auth?.loading && (
        <div>
          {isOwner ? (
            <div className="edit-cancel-sale-wrapper">
              <button
                className="edit-sale me-1"
                onClick={() => onOpenModal("edit-sale")}
              >
                {t("edit_sale")}
              </button>
              <button
                className="cancel-sale"
                onClick={() => onOpenModal("cancel-sale")}
              >
                {t("cancel__sale")}
              </button>
            </div>
          ) : (
            <div className="d-flex flex-wrap buy-now-wrapper">
              <button
                className="buy-now"
                onClick={() => onOpenModal("buy-now")}
              >
                {t("Buy now")}
              </button>
            </div>
          )}
        </div>
      )}
      {/* auction */}
      {nft?.listing?.sale_type === "2" && !auth?.loading && (
        <div>
          {nft?.highest_bid?.price && (
            <div className="my-3">
              <div className="label">{t("Highest bid")}</div>
              <div className="value">
                {nft?.highest_bid?.price}
                {nft?.blockchain}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div style={{ flex: 1.5 }} className="d-flex flex-column">
              {isAuctionRunning ? (
                <div>
                  <span className="ends-in">{t("Ends in")}</span>
                  <div className="d-flex align-items-center justify-content-start">
                    <TimerBox
                      colors={colors}
                      text={t("Days")}
                      time={duration?.days || "00"}
                    />
                    <div className="blank" />
                    <TimerBox
                      colors={colors}
                      text={t("Hours")}
                      time={duration?.hours || "00"}
                    />
                    <div className="blank" />
                    <TimerBox
                      colors={colors}
                      text={t("Mins")}
                      time={duration?.minutes || "00"}
                    />
                    <div className="blank" />
                    <TimerBox
                      colors={colors}
                      text={t("Secs")}
                      time={duration?.seconds || "00"}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="ended">{t("Auction Ended")}</div>
                  <span>
                    {moment(new Date(nft?.listing?.auction_end_date))
                      .locale(i18n.language)
                      .fromNow()}
                  </span>
                </div>
              )}
            </div>

            {isOwner ? (
              <div className="buttons">
                {nft?.highest_bid?.price ? (
                  <div>
                    <button
                      className="place-bid"
                      onClick={() => onOpenModal("accept-bid")}
                    >
                      {t("accept_heighest_bid")}
                    </button>
                  </div>
                ) : (
                  <div>
                    {isAuctionRunning ? (
                      <button
                        className="place-bid"
                        onClick={() => onOpenModal("cancel-auction")}
                      >
                        {t("Cancel Auction")}
                      </button>
                    ) : null}
                  </div>
                )}
              </div>
            ) : (
              <div style={{ flex: 1 }}>
                {isAuctionRunning && (
                  <div className="buttons">
                    {nft?.bid?._id !== auth?.account?._id && (
                      <div>
                        <button
                          className="place-bid"
                          onClick={() => onOpenModal("place-bid")}
                        >
                          {t("Place a Bid")}
                        </button>
                      </div>
                    )}
                    {nft?.bid?._id === auth?.account?._id && (
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        {nft?.highest?.user?._id !== auth?.account?._id && (
                          <div>
                            <button
                              className="edit-bid"
                              onClick={() => onOpenModal("edit-bid")}
                            >
                              {t("Edit Bid")}
                            </button>
                            <div className="d-flex justify-content-between align-items-center mt-3"></div>
                            <button
                              className="edit-bid"
                              onClick={() => onOpenModal("cancel-bid")}
                            >
                              {t("Cancel Bid")}
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </StylesWrapper>
  );
};
export default SaleButtons;
