/* eslint-disable no-undef */
import { useEffect, useState } from "react";
//web3
import Web3 from "web3";
import WalletModal from "./WalletModal";
//bootstrap
import { Button, Form, Row, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalAndOverlay from "./ModalAndOverlay";
// grey icons
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { bidActionsCreators } from "../store";
//config and library
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3React } from "@web3-react/core";
import { chains } from "@config";

import Swal from "sweetalert2";
import styled from "styled-components";

const PlaceBid = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    color: ${(props) => props.colors?.text1};
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
`;

const EditBidModals = (props) => {
  const { nft } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [bidAmount, setBidAmount] = useState(nft?.bid?.price || 0);
  const [walletOptions, setWalletOptions] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [defaultWallet, setDefaultWallet] = useState("");
  const [balance, setBalance] = useState("");
  const [accountbalance, setAccountBalance] = useState("");
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [status, setStatus] = useState(0);
  const { editBidCreator } = bindActionCreators(bidActionsCreators, dispatch);
  const { chain, onChangeChain, privateKey, getAccounts, chainId } =
    useWeb3Auth();
  const { account } = useWeb3React();
  const { placeHigherBid } = useWeb3Context();

  const getWallets = async () => {
    const wallet = await getAccounts();
    console.log(wallet);
    setBalance(wallet.balance);
    setSelectedWallet(nft?.bid?.user);
    setDefaultWallet(wallet.address);

    const wallets = [];
    wallets.push({
      value: wallet.address,
      label: wallet.address,
      balance: wallet.balance,
    });
    if (account)
      if (chains[chain].chainId === chainId)
        wallets.push({ value: account, label: account });

    setWalletOptions(props.walletOptions);
    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    if (account) {
      const accountBalance = await web3.eth.getBalance(account);
      const accountbalance = web3.utils.fromWei(accountBalance, "ether");
      setAccountBalance(accountbalance);
    }
  };

  useEffect(() => {
    getWallets();
  }, [privateKey, account, balance, accountbalance]);

  const updateBid = async () => {
    try {
      setStatus(1);
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );
      const biddingAmount = web3.utils.toWei(bidAmount.toString());
      if (nft?.is_lazy) {
        const bidData = {
          nft_id: nft?._id,
          wallet: selectedWallet,
          price: bidAmount,
          tx: "0x00",
        };
        editBidCreator(bidData);
        setStatus(2);
      } else {
        console.log(nft, props.selectedWallet, props.defaultWallet);
        const res = await placeHigherBid(
          nft?.offer_id,
          nft?.bid?.bid_index,
          biddingAmount,
          selectedWallet === props.defaultWallet
        );
        console.log("SelectedWallet====>", selectedWallet);
        const bidData = {
          nft_id: nft._id,
          prev_bid: nft?.bid?._id,
          wallet: selectedWallet,
          bid_index: nft?.bid?.bid_index,
          price: bidAmount,
          tx: res.data.transactionHash,
          is_offer: false,
        };
        // save to backend
        if (res.status) {
          editBidCreator(bidData);
          setStatus(2);
        } else {
          console.log(res.data);
          Swal.fire({
            icone: "error",
            title: t("Ooops..."),
            text: t("transaction_failed"),
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }
      }
    } catch (e) {
      console.log(e.message);
      Swal.fire({
        icone: "error",
        title: t("Ooops..."),
        text: t("bidding failed"),
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  return (
    <ModalAndOverlay colors={props.colors}>
      {connectWalletModal && (
        <WalletModal
          colors={props.colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}

      {/* --------- place a bid --------- */}
      <PlaceBid colors={props.colors}>
        <div className="d-flex justify-content-between mb-4">
          <h3 className="modal-heading title">{t("Edit Bid")}</h3>
          <CloseIcon
            className="modal-close-icon"
            color={props.colors?.text1}
            onClick={props.closeOfferModal}
          />
        </div>
        {chain !== nft?.blockchain ? (
          <>
            <h4> {t("Please switch network")}</h4>
          </>
        ) : (
          ""
        )}
        <div style={{ fontWeight: 325 }}>
          {t("edit_bid_note")}{" "}
          <span style={{ fontWeight: 400 }}>{nft?.name}</span> {t("from")}
          <span style={{ fontWeight: 400 }}>
            {" "}
            {nft?.creators?.minter?.name}
          </span>
        </div>
        <Form.Label className="mt-4">{t("New bid")}</Form.Label>
        <InputGroup size="md">
          <Form.Control
            type="text"
            placeholder={t("Amount")}
            value={bidAmount}
            style={{
              color: props?.colors?.text,
              backgroundColor: props?.colors?.bg,
            }}
            onChange={(e) => setBidAmount(e.target.value)}
          />
          <InputGroup.Text>{props?.nft?.blockchain}</InputGroup.Text>
        </InputGroup>
        <p style={{ color: "red" }}>
          {bidAmount < nft?.listing?.min_bid && t("bid_validation")}
        </p>
        <Form.Group className="mt-4" controlId="wallet-address">
          <Form.Label>
            {t("Current bid value")} {nft?.bid?.price}
          </Form.Label>
          <Row
            style={{
              letterSpacing: -0.02,
              fontFamily: "Open Sans",
              position: "relative",
              color: "#777E90",
            }}
          />
        </Form.Group>
        <div className="d-flex justify-content-between">
          <div>{t("Your balance")}</div>
          {selectedWallet === defaultWallet ? (
            <span>
              {balance ? parseFloat(balance).toFixed(3) : 0} {chain}
            </span>
          ) : (
            <span>
              {" "}
              {accountbalance ? parseFloat(accountbalance).toFixed(3) : 0}{" "}
              {chain}
            </span>
          )}
        </div>
        <Button
          disabled={bidAmount == 0 ? true : false}
          className="modal-button mb-3 mt-5"
          onClick={
            status === 2
              ? props.closeOfferModal
              : () => {
                  updateBid();
                }
          }
        >
          {status === 0
            ? t("Confirm")
            : status === 1
            ? t("Updating your bid...")
            : status === 2
            ? t("Done")
            : t("Error")}
        </Button>
        {/* <Button
          onClick={props.closeOfferModal}
          className={`modal-button ${props?.colors?.button}`}
        >
          {t("Cancel")}
        </Button> */}
      </PlaceBid>
    </ModalAndOverlay>
  );
};

export default EditBidModals;
