import React, { useEffect, useState, useContext, useRef } from "react";
import { Container, Nav, Spinner } from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import Navbar from "react-bootstrap/Navbar";
import { useWeb3Auth } from "@context/Web3auth";
import logo from "../assets/images/logo-new.svg";
import arabic from "../assets/icons/header/arabic.svg";
import { AuthContext } from "../context/AuthContext";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import algoliasearch from "algoliasearch";
import "@algolia/autocomplete-theme-classic";
import styled from "styled-components";

// dropdowns
import NotificationsDropdown from "./HeaderDropdowns/NotificationsDropdown";
import UserDropdown from "./HeaderDropdowns/UserDropdown";
import UserNavDropdown from "./HeaderDropdowns/UserNavDropdown";
import MarketplaceDropdown from "./HeaderDropdowns/MarketplaceDropdown";
import WalletModal from "./WalletModal";
import { useTranslation } from "react-i18next";
import "./Header-rtl.scss";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authActionsCreators } from "../store";
import keys from "../lib/config";
import { CgCloseO } from "react-icons/cg";
import { browserName, browserVersion } from "react-device-detect";
import Swal from "sweetalert2";

const searchClient = algoliasearch(window.ALGOLIA_APP_ID, window.ALGOLIA_API_KEY);

const FormWrapper = styled.div`
  width: 32%;
  @media (max-width: 992px) {
    width: 70%;
  }
  .icon {
    left: ${(props) => (props.isLTR ? 35 : 0)}px;
    right: ${(props) => (props.isLTR ? 0 : 35)}px;
  }
  .search-input {
    padding-inline-end: 10px;
    padding-inline-start: 40px;
  }
`;
function Header() {
  const containerRef = useRef(null);
  const querySuggestionsPlugin = createQuerySuggestionsPlugin({
    searchClient,
    indexName: "dev_nfts",
    getSearchParams() {
      return {
        hitsPerPage: 5,
      };
    },
  });
  const dispatch = useDispatch();
  const { resetProgressCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );
  const {
    account,
    authenticated,
    progress,
    verify_loading: loading,
  } = useSelector((state) => state.auth);
  // const [isRegistered, setIsRegistered] = useState(false)
  const [visible, setVisible] = useState(false);
  const [status, setStatus] = useState(false);
  const [searchRef, setSearchRef] = useState(false);
  const [unsupported_browsersRef, setUnsupported_browsersRef] = useState(true);
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const { t, i18n } = useTranslation();
  const isLTR = i18n.language !== "ar";

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  // useEffect(() => {
  //     if (user && user.data && user.data.email) {
  //         setIsRegistered(true)
  //     } else {
  //         setIsRegistered(false)
  //     }
  // }, [user])

  const { privateKey, load, getAccounts, logout, web3, chain } = useWeb3Auth();
  const [userData, setUserData] = useState({});
  const [walletData, setWalletData] = useState({});
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(true);
  const [displaySearchVar, setDisplaySearchVar] = useState("d-block d-lg-none");
  // const { activate, active, account, deactivate, connector } = useWeb3React()

  // --------- handle dropdowns ---------
  const [isNotificationsDropdown, SetIsNotificationsDropdown] = useState(false);
  const [isUserDropdown, setIsUserDropdown] = useState(false);
  const [notificationsDropdownLocation, setNotificationsDropdownLocation] =
    useState({});
  const [userDropdownLocation, setUserDropdownLocation] = useState({});
  const [isMarketplaceDropdown, setIsMarketplaceDropdown] = useState(false);
  const [marketplaceDropdownLocation, setMarketplaceDropdownLocation] =
    useState({});
  const openNotificationsDropdown = (e) => {
    const btn = e.target.getBoundingClientRect();
    const center = (btn.left + btn.right) / 2;
    const bottom = btn.bottom + 10;
    setNotificationsDropdownLocation({ center, bottom });
    SetIsNotificationsDropdown(true);
  };
  const openUserDropdown = (e) => {
    const btn = e.target.getBoundingClientRect();
    const center = (btn.left + btn.right) / 2.1;
    const bottom = btn.bottom - 5;
    setUserDropdownLocation({ center, bottom });
    setIsUserDropdown(!isUserDropdown);
  };
  const openMarketplaceDropdown = (e) => {
    const btn = e.target.getBoundingClientRect();
    const center = (btn.left + btn.right) / 2.1;
    const bottom = btn.bottom - 10;
    setMarketplaceDropdownLocation({ center, bottom });
    setIsMarketplaceDropdown(!isMarketplaceDropdown);
  };
  const closeDropdowns = () => {
    SetIsNotificationsDropdown(false);
    setIsUserDropdown(false);
    setIsMarketplaceDropdown(false);
  };

  const Logout = async () => {
    setVisible(false);
    setStatus(false);
    navigate("/login");
    await logout();
  };
  const goPage = (link) => {
    setVisible(false);
    navigate(link);
  };

  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );

  useEffect(() => {
    const runAutocomplete = () => {
      setSearchRef("true");

      if (!searchRef) {
        autocomplete({
          container: "#autocomplete",
          placeholder: t("search"),
          openOnFocus: false,
          getSources() {
            return [
              {
                sourceId: "querySuggestions",
                getItemInputValue: ({ item }) => item.query,
                getItems({ query }) {
                  return getAlgoliaResults({
                    searchClient,
                    queries: [
                      {
                        indexName: window.ALGOLIA_INDEX,
                        query,
                        params: {
                          hitsPerPage: 5,
                        },
                      },
                    ],
                  });
                },
                templates: {
                  item({ item, html }) {
                    let item_url = "";
                    item.is_club
                      ? (item_url = `/club/${item.contract}/${item._id}`)
                      : (item_url = `/nft/${item.token_standard}/${item._id}`);
                    return html`
                      <div>
                        <a
                          href="${item_url}"
                          className="d-flex align-items-center"
                          style="text-decoration:none"
                        >
                          <span
                            className="col-2 me-2 user-40-rounded overflow-hidden rounded-0"
                          >
                            <img src="${item.url}" alt="" />
                          </span>
                          <span className="col-10">${item.name}</span>
                        </a>
                      </div>
                    `;
                  },
                },
              },
            ];
          },
        });
      }
    };
    const dataLoad = async () => {
      const walletInfo = await getAccounts();
      setWalletData(walletInfo);
      const data = await load();
      setUserData(data);
      const wallets = await getAccounts();
      if (wallets.address)
        await importWalletCreator({ wallet: wallets.address });

      runAutocomplete();
    };
    dataLoad();
  }, [privateKey, web3, chain]);

  const onConnectWallet = async () => {
    setIsOpenDialog(true);
    // console.log("open wallet connect modal");
  };
  // display search box
  const toggleSearch = () => {
    setDisplaySearch(!displaySearch);
    {
      displaySearch
        ? setDisplaySearchVar("d-none d-lg-block")
        : setDisplaySearchVar("d-block d-lg-none");
    }
  };
  const unsupported_browsers = [
    "Chrome",
    "Brave",
    "Firefox",
    "Opera",
    "Osiris",
    "Puma",
    "Beaker",
    "Edge",
  ];
  if (!unsupported_browsers.includes(browserName)) {
    if (unsupported_browsersRef) {
      setUnsupported_browsersRef(false);

      Swal.fire("", t("unsupported_browsers"), "question");
    }
  }
  return (
    <header className="app-header" ref={containerRef}>
      {/* <img
        src={"/user-placeholder.png"}
        alt=""
        className="user-40-rounded"
        style={{ width: "100%", height: 200, backgroundAttachment: "fixed" }}
      /> */}
      <LoadingBar
        progress={progress}
        color="#6d3190"
        onLoaderFinished={() => resetProgressCreator()}
      />
      <Navbar
        expand="lg"
        className="py-0 px-2 navbar-dark "
        onMouseLeave={closeDropdowns}
      >
        <Container fluid className="position-relative ms-sm-5 ps-sm-3 me-sm-4 ">
          {/* dropdowns */}
          <NotificationsDropdown
            isDropdown={isNotificationsDropdown}
            dropdownLocation={notificationsDropdownLocation}
          />
          {authenticated && (
            <UserDropdown
              userName={account?.name || "Placeholder Name"}
              setIsUserDropdown={setIsUserDropdown}
              isDropdown={isUserDropdown}
              dropdownLocation={userDropdownLocation}
            />
          )}
          <MarketplaceDropdown
            isDropdown={isMarketplaceDropdown}
            dropdownLocation={marketplaceDropdownLocation}
          />

          {/* brand */}
          <div
            className="me-4 pe-2 py-0"
            style={{ zIndex: "1011" }}
            // onMouseEnter={closeDropdowns}
          >
            <Link to="/">
              <img
                src={logo}
                width="117"
                height="auto"
                className="d-inline-block align-top"
                alt="NiftySouq"
              />
            </Link>
          </div>
          {/* --------- small screen --------- */}
          <div className="d-flex">
            {authenticated && !loading && (
              <Nav className="flex-row align-items-center">
                {/* ------ notification ------ */}
                {/* <Nav.Link
                  href="#action1"
                  className="notifications d-lg-none me-3"
                /> */}
                {/* ------ registered user ------ */}
                <UserNavDropdown navStyle="d-lg-none me-4" img="big" />
              </Nav>
            )}
            <Navbar.Toggle />
          </div>
          {/* --------- collapse screen --------- */}
          <Navbar.Collapse className="justify-content-end pt-2">
            {displaySearch && (
              <Nav
                style={{ maxHeight: "100px" }}
                className="me-auto my-2 px-0  my-lg-0 mb-5 mb-lg-0"
              >
                {/* <NavLink
                to="/"
                exact="true"
                className={({ isActive }) =>
                  isActive ? "headermenu active" : "headermenu"
                }
              >
                {t("Home")}
              </NavLink> */}
                {/* <div className="headermenu" onClick={openMarketplaceDropdown}>
                {t("Marketplace")}
                {/* <img className="ms-1" src={arrowDown} alt="" /> * / }
              </div> */}

                <NavLink to="/galleries" className="headermenu" exact="true">
                  {t("Galleries")}
                </NavLink>

                {/* <NavLink
                  to="/the-bezel-club"
                  className="headermenu"
                  exact="true"
                >
                  {t("bezel-club.the_bezel_club")}
                </NavLink> */}

                <NavLink to="/about-us" className="headermenu " exact="true">
                  {t("About Us")}
                </NavLink>
              </Nav>
            )}

            {/* search */}

            <div
              id="autocomplete"
              className={`overlay-content ${displaySearchVar}  col  p-0 m-2`}
            >
              {" "}
            </div>
            {displaySearch ? (
              <button
                className="btn btn-purple rounded-full d-none d-lg-block"
                onClick={toggleSearch}
                style={{ background: "none" }}
              >
                <i
                  className="search-icon"
                  style={{
                    backgroundSize: "cover",
                    width: "22px",
                    height: "22px",
                    marginTop: "9px",
                  }}
                ></i>
              </button>
            ) : (
              <CgCloseO
                onClick={toggleSearch}
                style={{ color: "white" }}
              ></CgCloseO>
            )}

            <Nav
              navbarScroll
              className="align-items-center flex-row justify-content-between mb-4 mb-lg-0"
              style={{ zIndex: "1011" }}
            >
              {/*  notification  */}
              {false && authenticated && (
                <Link to="/action1">
                  <Nav.Link
                    // href="#action1"
                    className="notifications d-none d-lg-block"
                    onMouseEnter={openNotificationsDropdown}
                  />
                </Link>
              )}
              {loading ? (
                <div className="btn btn-primary gradient">
                  <Spinner size="sm" animation="border" color="#fff" />
                </div>
              ) : authenticated ? (
                // Upload
                <Link to="/nft/create" className="link_create_nft">
                  <div
                    className="mx-0 mx-md-2 my-2 my-md-0 btn btn-primary "
                    // onMouseEnter={closeDropdowns}
                  >
                    {t("Create")}
                  </div>
                </Link>
              ) : (
                // Register
                <Nav.Link
                  className="mx-0 mx-md-2 my-2 my-md-0 btn btn-primary"
                  href="/login"
                >
                  {t("login_register")}
                </Nav.Link>
              )}

              {/*  registered user  */}
              {authenticated && (
                <UserNavDropdown navStyle="d-none d-lg-block" img="small" />
              )}

              {/* arabic */}

              {isLTR ? (
                <Nav.Link
                  // onMouseEnter={closeDropdowns}
                  onClick={() => changeLanguage("ar")}
                  style={{ zIndex: "1011" }}
                >
                  <img src={arabic} alt="ar" />
                </Nav.Link>
              ) : (
                <Nav.Link
                  style={{ color: "#fff", zIndex: "1011" }}
                  // onMouseEnter={closeDropdowns}
                  onClick={() => changeLanguage("en")}
                >
                  En
                </Nav.Link>
              )}
            </Nav>
            <WalletModal isOpen={isOpenDialog} setIsOpen={setIsOpenDialog} />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
