import { relative } from "path";
import React, { useEffect, useState } from "react";
import { Button, Col, FormControl, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SearchBox.css";

const SearchBox = ({
  items,
  onSelect,
  label,
  colors,
  disabled = false,
  selectedCollection,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    if (selectedCollection) setSelectedItem(selectedCollection);
  }, [selectedCollection]);

  const handleSearch = (e) => {
    const searchItems = items.filter(
      (item) =>
        e.target.value?.length > 0 &&
        item.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResult(searchItems);
  };

  const { t } = useTranslation();
  return (
    <>
      <Form.Label
        className=" create-section-label"
        style={{ color: colors?.text }}
      >
        {label}
      </Form.Label>
      <div className="d-flex">
        <FormControl
          className="create-page-input-field"
          type="text"
          placeholder={t("search")}
          autoComplete="off"
          onFocus={(e) => setSearchResult(items)}
          value={selectedItem ? selectedItem?.title : searchInput}
          onChange={(e) => {
            setSelectedItem(null);
            setSearchInput(e.target.value);
            handleSearch(e);
          }}
          style={{ backgroundColor: colors?.bg, color: colors?.text }}
          disabled={disabled}
        />

        <i className="searchIcon" />
      </div>

      <div className="dropdownOverlay">
        {searchResult?.length > 0 && (
          <ul className="dropdownView ">
            <li
              className="close-search-result"
              onClick={() => {
                setSearchResult([]);
              }}
            >
              X
            </li>
            {searchResult.map((item, index) => (
              <li
                className="dropdownItem"
                style={{
                  borderBottom: index === searchResult?.length - 1 && "0px",
                }}
                onClick={() => {
                  setSelectedItem(item);
                  setSearchResult([]);
                  onSelect(item);
                }}
                key={item._id}
              >
                {item.logo && (
                  <img
                    src={item.logo}
                    width={24}
                    height={24}
                    style={{ borderRadius: "50%" }}
                    alt="logo"
                    className="mx-2"
                  />
                )}
                <span style={{ marginLeft: item.logo && "10px" }}>
                  {item.title}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBox;
