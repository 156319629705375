import * as socketTypes from "../types/socket";

export const handleSockets = (data) => {
  return (dispatch) => {
    console.log({data})
    if (data?.action === "cancel_bid") {
      dispatch({
        type: socketTypes.CANCEL_BID_SOCKET,
        payload: data,
      });
    }
    if (data?.action === "edit_bid") {
      dispatch({
        type: socketTypes.EDIT_BID_SOCKET,
        payload: data,
      });
    }
    if (data?.action === "place_bid") {
      dispatch({
        type: socketTypes.PLACE_BID_SOCKET,
        payload: data,
      });
    }
    if (data?.action === "purchase_nft") {
      dispatch({
        type: socketTypes.PURCHASE_NFT_SOCKET,
        payload: data,
      });
    }
    if (data?.action === "cancel_nft") {
      dispatch({
        type: socketTypes.CANCEL_NFT_SOCKET,
        payload: data,
      });
    }
    if (data?.action === "list_nft") {
      dispatch({
        type: socketTypes.LIST_NFT_SOCKET,
        payload: data,
      });
    }
    if (data?.action === "create_nft") {
      dispatch({
        type: socketTypes.CREATE_NFT_SOCKET,
        payload: data,
      });
    }
  };
};
