import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { intervalToDuration } from "date-fns";
import TimerBox from "@components/TimerBox";
import { walletCut } from "../../../../core/string";
import wallet from "@assets/icons/copy-wallet.svg";
import StylesWrapper from "./styles";
import moment from "moment";
import CopyWallet from "../../../../components/CopyWallet";

const NFTAuction = ({ nft, isOwner, onOpenModal, colors }) => {
  const navigator = useNavigate();
  const { t, i18n } = useTranslation();

  const [usdPrice, setUsdPrice] = useState(0);
  const [duration, setDuration] = useState(null);

  const auth = useSelector((state) => state.auth);
  const eth = useSelector((state) => state.eth);

  const isAuction = nft?.listing?.sale_type == "2";

  const AuctionRunning = moment(
    new Date(nft?.listing?.auction_end_date)
  ).isAfter(new Date());
  // const AuctionRunning = true;

  useEffect(() => {
    if (nft && eth) {
      if (nft?.blockchain === "ETH")
        setUsdPrice(
          nft?.highest_bid?.price
            ? nft?.highest_bid?.price * parseFloat(eth?.usd.eth)
            : 0
        );

      if (nft?.blockchain === "MATIC")
        setUsdPrice(
          nft?.highest_bid?.price
            ? nft?.highest_bid?.price * parseFloat(eth?.usd.matic)
            : 0
        );
    }
  }, [nft, eth]);

  useEffect(() => {
    let startTimerVar = null;
    function startTimer() {
      if (isAuction && nft?.listing?.auction_end_date)
        if (
          moment(new Date(nft?.listing?.auction_end_date)).isAfter(new Date())
        )
          startTimerVar = setInterval(() => {
            let dur = intervalToDuration({
              start: new Date(),
              end: new Date(nft?.listing?.auction_end_date),
            });
            setDuration(dur);
          }, 1000);

      return {
        if(startTimerVar) {
          clearInterval(startTimerVar);
        },
      };
    }
    startTimer();
  }, [nft?.listing]);
  return (
    <StylesWrapper colors={colors}>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-column">
          <div className="label">{t("Highest Bid")}</div>
          <div className="price">
            {nft?.highest_bid?.price?.toFixed(3) || 0}
            <span className="chain">{nft?.blockchain}</span>
          </div>
          <div className="price-usd">${usdPrice.toFixed(3)}</div>
        </div>
        {isOwner && (
          <button
            className="btn btn-primary"
            disabled={!nft?.highest_bid?.price || AuctionRunning}
            onClick={() => onOpenModal("accept-bid")}
          >
            {t("accept")}
          </button>
        )}
      </div>
      <div>
        {AuctionRunning ? (
          <div className="mt-4">
            <span className="">{t("Ends in")}</span>
            <div className="d-flex align-items-center justify-content-start">
              <TimerBox
                time={duration?.days || "00"}
                text={t("Days")}
                colors={colors}
              />
              <div className="mx-1" />
              <TimerBox
                time={duration?.hours || "00"}
                text={t("Hours")}
                colors={colors}
              />
              <div className="mx-1" />
              <TimerBox
                time={duration?.minutes || "00"}
                text={t("Mins")}
                colors={colors}
              />
              <div className="mx-1" />
              <TimerBox
                time={duration?.seconds || "00"}
                text={t("Secs")}
                colors={colors}
              />
            </div>
          </div>
        ) : (
          <div className="mt-4">
            {nft?.highest_bid?.user && (
              <div>
                <div className="">{t("Highest Bidder")}</div>
                <Link
                  to={`/profile/${nft?.highest_bid?.user?._id}`}
                  style={{ textDecoration: "none" }}
                ></Link>
                <div className="mt-2 d-flex flex-row align-items-center">
                  <img
                    alt=""
                    className="profile-img"
                    src={
                      nft?.highest_bid?.user?.profile?.image_url ||
                      "/user-placeholder.png"
                    }
                  />
                  <div style={{ marginLeft: 10 }}>
                    <div style={{ fontSize: 14, color: "#777E91" }}>
                      {nft?.highest_bid?.user?.name}
                    </div>

                    <CopyWallet
                      fullWallet={false}
                      address={nft?.highest_bid?.user?.create_wallet}
                      id={"6"}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* <TimerBox
              time={duration?.days || "00"}
              text={t("Days")}
              colors={colors}
            /> */}
            <div className="divider my-4" />
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="">{t("Ended On")}</div>
              <div style={{ color: "#777E91" }}>
                {moment(
                  new Date(
                    nft?.listing?.auction_end_date || "2022-11-21T06:24:00.367Z"
                  )
                )
                  .locale(i18n.language)
                  .format("D MMM YYYY, H.MM A")}
              </div>
            </div>
          </div>
        )}
      </div>
      
        {isOwner ? (
          <div className="mt-4 d-flex justify-content-center">
            <div
              style={{ width: "50%" }}
              className="btn-border-gradient cursor"
              onClick={() => {
                if (auth.authenticated) onOpenModal("cancel-auction");
                else navigator("/login");
              }}
            >
              {t("cancel_auction")}
            </div>
          </div>
        ) : (
          <div className="mt-4 d-flex justify-content-center">
            {/* placed a bid */}
            {auth?.account?.wallets?.includes(nft?.bid?.user) ? (
              <div
                style={{ width: "100%" }}
                className="d-flex flex-row justify-content-evenly"
              >
                <div
                  style={{ width: "40%" }}
                  className="btn btn-primary"
                  onClick={() => {
                    if (auth.authenticated) onOpenModal("edit-bid");
                    else navigator("/login");
                  }}
                >
                  {t("Edit Bid")}
                </div>
                <div
                  style={{ width: "40%" }}
                  className="btn-border-gradient cursor"
                  onClick={() => {
                    if (auth.authenticated) onOpenModal("cancel-bid");
                    else navigator("/login");
                  }}
                >
                  {t("Cancel Bid")}
                </div>
              </div>
            ) : (
              AuctionRunning ? 
              <div
                style={{ width: "50%" }}
                className="btn btn-primary"
                onClick={() => {
                  if (auth.authenticated) onOpenModal("place-bid");
                  else navigator("/login");
                }}
              >
                {t("Place a Bid")}
              </div> : ""
            )}
          </div>
        )}
    </StylesWrapper>
  );
};

export default NFTAuction;
