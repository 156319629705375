import { Card } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: #23262f;
    z-index: 10;
  }
  .purchasing .overlay {
    display: flex;
  }
  .time-remaining {
    color: #fcfcfd;
    background-color: #ee2a7b;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    img {
      margin-top: -4px;
    }
  }
  .purchasing-container {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    span {
      background-color: #45b26b;
      padding: 6px 8px;
      border-radius: 4px;
    }
    div {
      background-color: #fcfcfd;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  .nft-card {
    height: 250px;
    max-height: 250px;
    overflow: hidden;
  }

  .chip {
    border: #d3d3d3 solid 2.17px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
  }
`;

const CollectionNFT = ({ collection }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.profile);

  return (
    <Wrapper>
      <Card
        className="collection nft-item card"
        style={{
          width: "350px",
          padding: "0px",
          marginBottom: "70px",
          marginRight: "0px !important",
        }}
      >
        <Link to={`/collection/nft/${collection._id}`}>
          <div className="position-relative nft-card d-flex">
            <Card.Img
              variant="top"
              src={collection.coverImage}
              className="nft-image"
              style={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className="d-flex pt-3 p-3"
            style={{ paddingBottom: "0px !important" }}
          >
            <Card.Title className="flex-fill nft-name" style={{ width: "70%" }}>
              {collection.title}
            </Card.Title>
            <Card.Body className="mt-0 p-0" style={{ lineHeight: "73px" }}>
              <div className="chip">
                <span className="text-muted px-1">
                  {t("nft_items", {
                    count: collection.nftCounts,
                  }).toUpperCase()}
                </span>
              </div>
              {/* <span
                  className="nft-price"
                  style={{
                    display: "initial",
                    float: "right",
                  }}
                >
                  {t("nft_items", { count: collection.nftCounts })}
                </span> */}
            </Card.Body>
          </div>
        </Link>
      </Card>
    </Wrapper>
  );
};

export default CollectionNFT;
