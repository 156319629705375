import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import {
  PlaceBidModals,
  BuyOfferModal,
  ListTokenModal,
  BurnTokenModal,
  ChangeCollectionModal,
  CancelSaleModal,
  EditBidModals,
  EditSalePriceModal,
  CancelBidModal,
  AcceptHighestBidModals,
  WalletModal,
  ImageModal,
  ExtendAuctionModal,
  TransferNFTModal,
  MakeOfferModal,
  EditOfferModal,
  CancelOfferModal,
  AcceptOfferModal,
  StripeStatusModal,
} from "@components";
import { chains } from "@config";
import { getFileType } from "../utils/Helper";
import { useTranslation } from "react-i18next";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "@context/Web3auth";
import Web3 from "web3";

//store
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import {
  creatorCollectionsCreators,
  offerActionsCreators,
} from "../../src/store";
//moralis

import Moralis from "moralis";
//
import { useSubscription } from "../../src/socket";
import { nftDetailsEvents, socketChannels } from "../../src/common/sockets";
import { ReactComponent as Expand } from "@assets/icons/expand.svg";
import {
  NFTMedia,
  NFTProperties,
  ShareOptions,
  NFTOptions,
  SaleButtons,
  Tabs,
  TabsContent,
  NFTDetails,
  NFTDetailsBezel,
  NFTListing,
  NFTAuction,
} from "../../src/pages/NFT/Partials";

import ScrollBar from "@components/ScrollBar";
import ArtistCollectionNFT from "@components/template/Gallery/components/ArtistCollectionNFT";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import Swal from "sweetalert2";
import keys from "../../src/lib/config";
import NFTListingPhoneView from "../../src/pages/NFT/Partials/NFTListingPhoneView";
import { themeColors } from "../common/constants/partner";
import { StylesWrapper } from "./NFT/styles";
import { chainIds } from "../common/constants/chainIds";
import axios from "axios";

const stripeKey = loadStripe(window.STRIPE_PUBLISH_KEY);

export const LinkedNftDetails = (props) => {
  const { error, loading, nft, theme = "light", externalNFT = null } = props;
  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const colors = themeColors(theme);

  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { contract_address, token_id } = useParams();

  const { t, i18n } = useTranslation();

  //use context
  const { account, active, chainId } = useWeb3React();
  const { chain, privateKey, getAccounts, onChangeChain } = useWeb3Auth();
  const [accountBalance, setAccountBalance] = useState(0);
  const [defaultBalance, setDefaultBalance] = useState(0);

  const [mimeType, setMimeType] = useState("");
  //modal options
  const [imageModal, setImageModal] = useState(false);

  const [showBuyOfferModal, setShowBuyOfferModal] = useState(false);
  const [showBurnModal, setShowBurnModal] = useState(false);
  const [showListTokenModal, setShowListTokenModal] = useState(false);
  const [showEditSalePriceModal, setShowEditSalePriceModal] = useState(false);
  const [showCancelSaleModal, setShowCancelSaleModal] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showPlaceBidModal, setShowPlaceBidModal] = useState(false);
  const [showEditBidModal, setShowEditBidModal] = useState(false);
  const [showCancelBidModal, setShowCancelBidModal] = useState(false);
  const [showAcceptHighestBidModal, setShowAcceptHighestBidModal] =
    useState(false);
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false);
  const [showEditOfferModal, setShowEditOfferModal] = useState(false);
  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);

  const [showExtendAuctionModal, setShowExtendAuctionModal] = useState(false);
  const [showChangeCollectionModal, setShowChangeCollectionModal] =
    useState(false);

  //wallets
  const [defaultWallet, setDefaultWallet] = useState("");
  const [selectedWallet, setSelectWallet] = useState("");
  const [walletOptions, setWalletOptions] = useState([]);
  const [connectWalletModal, setConnectWalletModal] = useState(false);

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedOffer, setSelectedOffer] = useState(null);

  //stripe
  const [stripeModal, setStripeModal] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [stripeStatus, setStripeStatus] = useState(false);
  const [stripeMessage, setStripeMessage] = useState("");
  const [showStripeMessage, setShowStripeMessage] = useState(true);
  const { status } = useSelector((state) => state.stripe);

  const auth = useSelector((state) => state.auth);
  const { collections } = useSelector((state) => state.creatorCollections);

  const { fetchUserCollectionCreator } = bindActionCreators(
    creatorCollectionsCreators,
    dispatch
  );
  const { getOffersCreator } = bindActionCreators(
    offerActionsCreators,
    dispatch
  );
  const [linkednfts, setLinkednfts] = useState([]);
  const [filteredLinkedNFTs, setfilteredLinkedNFTs] = useState([]);
  const [metaData, setMetaData] = useState("");
  const [erc, setErc] = useState("");
  const [linkedChin, setlinkedChin] = useState("");
  useEffect(() => {
    if (privateKey) dataload();
  }, [privateKey, account, chain]);

  useEffect(() => {
    if (nft && auth.account?._id === nft.owner?._id)
      fetchUserCollectionCreator({ creatorId: nft.owner?._id });
    if (nft?._id) getOffersCreator(nft?._id, { skip: 0, limit: 10 }, false);
  }, [nft]);

  //stripe
  useEffect(() => {
    const clientSecretKey = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!!clientSecretKey) setClientSecret(clientSecretKey);
  }, []);

  useEffect(() => {
    if (showStripeMessage && stripeMessage) {
      setShowStripeMessage(false);
      if (stripeStatus) {
        if (!status) setStripeModal(true);
        else return;
      } else
        Swal.fire({
          icone: "error",
          title: t("Card Payment"),
          text: stripeMessage,
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
    }
  }, [stripeMessage, showStripeMessage]);

  // useSubscription(`${socketChannels.NFT_DETAILS}_${nft_id}`, nftDetailsEvents);

  const closeOfferModal = (refresh = false) => {
    dataload();
    !!refresh && props.refresh();
    setShowListTokenModal(false);
    setShowBurnModal(false);
    setShowBuyOfferModal(false);
    setShowTransferModal(false);
    setShowCancelSaleModal(false);
    setShowPlaceBidModal(false);
    setShowEditBidModal(false);
    setShowEditSalePriceModal(false);
    setShowCancelBidModal(false);
    setShowAcceptHighestBidModal(false);
    setShowMakeOfferModal(false);
    setShowCancelOfferModal(false);
    setShowEditOfferModal(false);
    setShowAcceptOfferModal(false);
  };

  const dataload = async () => {
    //get default wallet
    const wallet = await getAccounts();
    setDefaultBalance(wallet.balance);
    setSelectWallet(account);
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });

    if (account)
      if (chains[chain].chainId === chainId)
        wallets.push({ value: account, label: account });

    setWalletOptions(wallets);
    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    if (account) {
      const balance = await web3.eth.getBalance(account);
      const balance1 = web3.utils.fromWei(balance, "ether");
      setAccountBalance(balance1);
    }
  };

  const externalLoad = async () => {
    const options = { address: defaultWallet, chain: "rinkeby" };
    await Moralis.Web3API.account.getNFTs(options);
  };

  const onOpenModal = (type) => {
    if (auth?.authenticated) {
      onChangeChain(nft?.blockchain);
      if (type == "buy-now") setShowBuyOfferModal(true);
      else if (type == "burn") setShowBurnModal(true);
      else if (type == "put-sale") setShowListTokenModal(true);
      else if (type == "edit-sale") setShowEditSalePriceModal(true);
      else if (type == "cancel-sale") setShowCancelSaleModal(true);
      else if (type == "accept-bid") setShowAcceptHighestBidModal(true);
      else if (type == "cancel-auction") setShowCancelSaleModal(true);
      else if (type == "place-bid") setShowPlaceBidModal(true);
      else if (type == "edit-bid") setShowEditBidModal(true);
      else if (type == "cancel-bid") setShowCancelBidModal(true);
      else if (type == "change-collection") setShowChangeCollectionModal(true);
      else if (type == "extend-auction") setShowExtendAuctionModal(true);
      else if (type == "transfer") setShowTransferModal(true);
      else if (type == "make-offer") setShowMakeOfferModal(true);
      else if (type == "edit-offer") setShowEditOfferModal(true);
      else if (type == "cancel-offer") setShowCancelOfferModal(true);
      else if (type == "accept-offer") setShowAcceptOfferModal(true);
      else if (type == "edit-nft")
        navigator(`/nft/edit/${nft?.token_standard}/${nft?._id}`, {
          state: { nft },
        });
    } else navigator("/login");
  };

  const StripePaymentStatus = ({ clientSecret }) => {
    const stripe = useStripe();
    useEffect(() => {
      if (!stripe || !clientSecret) return;

      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setStripeStatus(true);
            setStripeMessage(t("payment_succeeded"));
            break;
          case "processing":
            setStripeStatus(true);
            setStripeMessage(t("payment_processing"));
            break;
          case "requires_payment_method":
            setStripeStatus(false);
            setStripeMessage(t("payment_failed"));
            break;
          default:
            setStripeMessage(t("payment_error"));
            break;
        }
      });
    }, [clientSecret]);

    return <div />;
  };
  //0 sold, 1 not for sale, 2 fixed price, 3 auction
  const nftStatus =
    nft?.status == "PURCHASE"
      ? 0
      : nft?.listing?.sale_type == "0" ||
        nft?.status == "CANCEL" ||
        nft?.status == "UNLISTED"
      ? 1
      : nft?.listing?.sale_type == "1"
      ? 2
      : 3;
  const modalProps = {
    nft,
    colors,
    externalNFT,
    selectedOwner,
    selectedOffer,
    dataload,
    externalLoad,
    walletOptions,
    selectedWallet,
    isApproved: false,
    isLazyItem: false,
    isMultiple: false,
    isExternal: false,
    isCancel: false,
    defaultWallet,
    closeOfferModal,
    accountBalance,
    defaultBalance,
  };
  const nftOptionsProps = {
    nft,
    nftStatus,
    colors,
    externalNFT,
    onOpenModal,
    selectedOwner,
    setSelectedOwner,
    setConnectWalletModal,
  };
  const is1155 = nft?.token_standard === "1155";
  const is721 = nft?.token_standard === "721";
  const isFixedPrice = nft?.listing?.sale_type == "1";
  const isAuction = nft?.listing?.sale_type == "2";

  const isOwner = is1155
    ? auth?.account?.wallets.some((el) =>
        nft?.owners?.some((e) => el == e?.user?.create_wallet)
      )
    : auth?.account?.wallets.includes(nft?.owner?.create_wallet);

  const isCreator = auth?.account?.wallets.includes(
    nft?.creators?.minter?.create_wallet
  );
  const nftProps = {
    nft,

    colors,
    externalNFT,
    isOwner,
    isAuction,
    nftStatus,
    setSelectedOffer,
    setShowAcceptOfferModal: () => onOpenModal("accept-offer"),
  };

  useEffect(() => {
    let response;
    let ChainCode;
    if (window.ENV_NAME.trim() === "development") {
      if (chain === "MATIC") {
        ChainCode = chainIds.mumbai;
      } else if (chain === "ETH") {
        ChainCode = chainIds.sepolia;
      }
    } else if (window.ENV_NAME.trim() === "staging") {
      if (chain === "MATIC") {
        ChainCode = chainIds.polygon;
      } else if (chain === "ETH") {
        ChainCode = chainIds.eth;
      }
    } else if (window.ENV_NAME.trim() === "production") {
      if (chain === "MATIC") {
        ChainCode = chainIds.polygon;
      } else if (chain === "ETH") {
        ChainCode = chainIds.eth;
      }
    } else if (window.ENV_NAME.trim() === "local") {
      if (chain === "MATIC") {
        ChainCode = chainIds.mumbai;
      } else if (chain === "ETH") {
        ChainCode = chainIds.sepolia;
      }
    }
    setlinkedChin(ChainCode);
    async function getData() {
      response = await axios
        .get(`${window.API_URL}/getnfts`, {
          params: { account, ChainCode },
        })
        .then((response) => {
          setLinkednfts(response.data);
        });
    }

    if (account) getData();
  }, [chain, account]);
  useEffect(() => {
    if (linkednfts.result) {
      const filtered = linkednfts.result.filter((linkednft) => {
        return linkednft.token_id === token_id;
      });
      setfilteredLinkedNFTs(filtered[0]);
    } else {
      const filtered = linkednfts.filter((linkednft) => {
        return linkednft.tokenId === token_id;
      });
      setfilteredLinkedNFTs(filtered[0]);
    }
    if (filteredLinkedNFTs) {
      const ercTest = filteredLinkedNFTs?.contract_type
        ? filteredLinkedNFTs?.contract_type
        : filteredLinkedNFTs?.contractType;
      setErc(ercTest);
      if (filteredLinkedNFTs.name) {
        const metaDataTest = filteredLinkedNFTs?.contract_type
          ? JSON.parse(filteredLinkedNFTs.metadata)
          : filteredLinkedNFTs.metadata;
        setMetaData(metaDataTest);
      }
    }
  }, [filteredLinkedNFTs, linkednfts, token_id]);
  useEffect(() => {
    async function checkType() {
      const fileType = await getFileType(metaData.image);
      setMimeType(fileType);
    }
    if (metaData) checkType();
  }, [metaData]);
  return (
    <StylesWrapper colors={colors} isAuction={isAuction}>
      {loading ? (
        <p className="loading">{t("Loading...")}</p>
      ) : (
        <div>
          {connectWalletModal && (
            <WalletModal
              colors={colors}
              isOpen={connectWalletModal}
              setIsOpen={setConnectWalletModal}
            />
          )}
          {stripeModal && (
            <StripeStatusModal
              nft={nft}
              refresh={props.refresh}
              nftId={nft?._id}
              colors={colors}
              clientSecret={clientSecret}
              isOpen={stripeModal}
              setIsOpen={setStripeModal}
            />
          )}
          {showBuyOfferModal && <BuyOfferModal {...modalProps} />}
          {showTransferModal && <TransferNFTModal {...modalProps} />}
          {showListTokenModal && <ListTokenModal {...modalProps} />}
          {showEditBidModal && <EditBidModals {...modalProps} />}
          {showEditSalePriceModal && <EditSalePriceModal {...modalProps} />}
          {showBurnModal && <BurnTokenModal {...modalProps} />}
          {showCancelSaleModal && <CancelSaleModal {...modalProps} />}
          {showPlaceBidModal && <PlaceBidModals {...modalProps} />}
          {showCancelBidModal && <CancelBidModal {...modalProps} />}
          {showAcceptHighestBidModal && (
            <AcceptHighestBidModals {...modalProps} />
          )}
          {showMakeOfferModal && <MakeOfferModal {...modalProps} />}
          {showEditOfferModal && <EditOfferModal {...modalProps} />}
          {showCancelOfferModal && <CancelOfferModal {...modalProps} />}
          {showAcceptOfferModal && <AcceptOfferModal {...modalProps} />}
          <ChangeCollectionModal
            nftId={nft?._id}
            colors={colors}
            collections={collections}
            isOpen={showChangeCollectionModal}
            setIsOpen={setShowChangeCollectionModal}
            creator_collectionId={nft?.creator_collectionId}
          />
          <ExtendAuctionModal
            {...modalProps}
            isOpen={showExtendAuctionModal}
            setIsOpen={setShowExtendAuctionModal}
          />
          <ImageModal
            isOpen={imageModal}
            setIsOpen={setImageModal}
            nft={{ type: mimeType, url: metaData?.image }}
          />
          <div>
            <div className="media-bg">
              <Container fluid="lg">
                <NFTMedia
                  fullView
                  nft={{ type: mimeType, url: metaData?.image }}
                />
                <div className="d-flex flex-row justify-content-end mt-4">
                  <div
                    className="cursor expand"
                    onClick={() => setImageModal(true)}
                  >
                    <Expand color={colors?.icon} width="17" height="17" />
                  </div>
                  <ShareOptions {...nftProps} />
                  {/* <div>
                    <NFTOptions {...nftOptionsProps} />
                  </div> */}
                </div>
              </Container>
            </div>
            <Container fluid="lg">
              <div
                style={{ height: "auto" }}
                className="flexbox d-flex flex-md-row flex-column align-items-center align-items-md-stretch mt-5"
              >
                <div className="details-table">
                  <NFTDetails
                    name={metaData?.name}
                    nft={filteredLinkedNFTs}
                    ChainCode={linkedChin}
                  />
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 flex-wrap">
                    <Tabs
                      filteredLinkedNFTs={filteredLinkedNFTs}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                    />
                    <div className="d-flex flex-row" key={Math.random()}>
                      {!isOwner && is721 && nftStatus == 2 && (
                        <div style={{ marginInlineEnd: 10 }}>
                          <div
                            className="btn btn-primary"
                            onClick={() => {
                              onChangeChain(nft?.blockchain);
                              onOpenModal("buy-now");
                            }}
                          >
                            {t("Buy now")}
                          </div>
                        </div>
                      )}
                      {!isOwner &&
                        is721 &&
                        (nftStatus == 1 || nftStatus == 2 || nftStatus == 0) &&
                        (nft?.offer?._id ? (
                          <>
                            <div
                              className="btn btn-primary"
                              onClick={() => {
                                onChangeChain(nft?.blockchain);
                                onOpenModal("edit-offer");
                              }}
                            >
                              {t("edit_an_offer")}
                            </div>
                            <div
                              style={{ marginInlineStart: 10 }}
                              className="btn btn-purple-outline"
                              onClick={() => {
                                onChangeChain(nft?.blockchain);
                                onOpenModal("cancel-offer");
                              }}
                            >
                              {t("cancel_an_offer")}
                            </div>
                          </>
                        ) : nft?.is_lazy ? (
                          ""
                        ) : (
                          nft?.blockchain !== "BOBA" && (
                            <div>
                              <div
                                className="btn btn-primary"
                                onClick={() => {
                                  onChangeChain(nft?.blockchain);
                                  onOpenModal("make-offer");
                                }}
                              >
                                {t("make_an_offer")}
                              </div>
                            </div>
                          )
                        ))}
                    </div>
                  </div>

                  <TabsContent
                    filteredLinkedNFTs={filteredLinkedNFTs}
                    description={metaData.description}
                    selectedTab={selectedTab}
                  />
                </div>
                {is721 && nftStatus == 3 ? (
                  <div
                    style={{ flex: 1, width: "100%" }}
                    className="d-flex flex-column align-items-center align-items-md-stretch"
                  >
                    <div className="details-table-2">
                      <NFTAuction {...nftProps} onOpenModal={onOpenModal} />
                    </div>
                    <div className="details-table-2">
                      <NFTProperties {...nftProps} />
                    </div>
                  </div>
                ) : (
                  <div className="details-table-2">
                    <NFTProperties filteredLinkedNFTs={filteredLinkedNFTs} />
                  </div>
                )}
              </div>
              {is1155 &&
                (isOwner ||
                  nft?.owners?.reduce(
                    (partialSum, a) => partialSum + a?.listed_editions?.length,
                    0
                  ) > 0) && (
                  <div className="mt-4">
                    <div className="title">{t("listing")}</div>
                    <div className="details-table-3 d-none d-lg-block">
                      <NFTListing {...nftProps} {...nftOptionsProps} />
                    </div>
                    <div className="details-table-3 d-block d-lg-none">
                      <NFTListingPhoneView {...nftProps} {...nftOptionsProps} />
                    </div>
                  </div>
                )}
              {nft?.collection_details?.artist?.collections?.length > 0 && (
                <div className="mt-5">
                  <ScrollBar
                    data={nft?.collection_details?.artist?.collections}
                    link={`/created-artist/${nft.collection_details?.artist?._id}`}
                    title={t("more_collections_from_artist")}
                    subtitle={t("artist")}
                    renderCard={(item) => (
                      <div className="mx-2" key={Math.random()}>
                        <ArtistCollectionNFT
                          item={{ ...item, nftCounts: item.nft_count }}
                        />
                      </div>
                    )}
                  />
                </div>
              )}
            </Container>
          </div>
          )
          <br />
          <br />
        </div>
      )}
    </StylesWrapper>
  );
};
