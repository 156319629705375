import Cookies from "universal-cookie";
import keys from "../lib/config";
const cookie = new Cookies();
export const saveCookie = (key, value, days = 60) => {
  try {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    cookie.set(key, value, {
      sameSite: "strict",
      path: "/",
      expires: date,
      domain: window.DOMAIN,
      httpOnly: false,
      secure: false,
    });
  } catch (error) {
    throw error;
  }
};
export const removeFromCookies = (key) => {
  cookie.remove(key, { domain: window.DOMAIN, path: "/" });
};

export const getCookie = (key) => {
  return cookie.get(key);
};
