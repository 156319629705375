import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "@assets/icons/arrow-down.svg";

const Main = styled("div")`
  font-family: sans-serif;
`;

const DropDownContainer = styled("div")`
  width: 100%;
`;

const DropDownHeader = styled("div")`
  border: #e6e8ec solid 2px;
  border-radius: 12px;
  margin-bottom: 0.8em;
  padding: 5px 12px;
  font-size: 14px;
  color: #23262f;
  font-weight: 350;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 2px solid #e6e8ec;
    &:hover {
      border: 2px solid #b8babd;
    }
    & img {
      width: 10px;
      height: 6px;
    }
  }
`;

const DropDownListContainer = styled("div")`
  // box-shadow: #e6e8ec 0 20px 20px;
  border: 2px solid #e5e5e5;
  border-radius: 12px;
  overflow: hidden;
`;

const DropDownList = styled("ul")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  box-sizing: border-box;
  color: #23262f;
  font-size: 14px;
  font-weight: 350;
  padding: 8px;
`;

const ListItem = styled("li")`
  color: #777e90;
  font-weight: 350;
  list-style: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 48px;
  &:hover {
    background-color: #f4f5f6;
  }
`;

export default function App(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const sortByOptionsRef = useRef();
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (option) => () => {
    setSelectedOption(option.label);
    if (props.onOptionSelected) {
      props.onOptionSelected(option.value);
    }
    setIsOpen(false);
  };
  const useClickOutsidesortByOptionsRef = (handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (
          !sortByOptionsRef.current ||
          sortByOptionsRef.current.contains(event.target)
        ) {
          return;
        }
        handler(event);
      };

      document.addEventListener("click", listener);
      return () => document.removeEventListener("click", listener);
    }, [sortByOptionsRef, handler]);

    return [sortByOptionsRef];
  };
  useClickOutsidesortByOptionsRef(() => setIsOpen(false));
  return (
    <Main ref={sortByOptionsRef}>
      <DropDownContainer className="position-relative">
        <DropDownHeader onClick={toggling}>
          {selectedOption || props.label}
          <button className="icon" type="button">
            <Arrow color="#777E91" />
          </button>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer
            className="position-absolute w-100"
            style={{
              zIndex: 99,
              marginTop: "-14px",
            }}
          >
            <DropDownList>
              {props.options.map((option, key) => (
                <ListItem onClick={onOptionClicked(option)} key={key}>
                  {t(option.label)}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Main>
  );
}
