import { Row } from "react-bootstrap";
import styled from "styled-components";

const StylesWrapper = styled("div")`
  .profile-img {
    height: 48px;
    max-width: 48px;
    min-width: 48px;
    border-radius: 50%;
    object-fit: cover;
    background-color: lightgray;
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: ${(props) => props?.colors?.divider};
  }
  .details {
    width: 100%;
    font-size: 14px;
    line-height: 35px;
  }
  .label {
    color: ${(props) => props?.colors?.label};
  }
  .value {
    text-align: right;
    font-weight: 400;
    color: ${(props) => props?.colors?.value};
  }
`;
export default StylesWrapper;
