/* eslint-disable no-undef */
import { useState } from "react";
//bootstrap
import { Button, Form, Spinner, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalAndOverlay from "./ModalAndOverlay";
// grey icons
import errorIcon from "@assets/images/icons/error.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
//store
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { bidActionsCreators } from "../store";
//config and library
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
import { chains } from "@config";
//web3
import Web3 from "web3";
import Swal from "sweetalert2";
import WalletModal from "./WalletModal";
import { offerActionsCreators } from "../store";
import styled from "styled-components";

const CancelOffer = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    color: ${(props) => props.colors?.text1};
  }
  .note {
    font-size: 24px;
  }
  .action {
    font-weight: 400;
    color: #6d3190;
  }
  .light-text {
    font-weight: 325;
  }
`;

const CancelOfferModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { chain } = useWeb3Auth();
  const { deleteOffer } = useWeb3Context();

  const { isProject, nft, colors, closeOfferModal } = props;

  const [error, setError] = useState("");
  const [status, setStatus] = useState(0);
  const [connectWalletModal, setConnectWalletModal] = useState(false);

  const { cancelOfferCreator } = bindActionCreators(
    offerActionsCreators,
    dispatch
  );
  const cancelNFTOffer = async () => {
    try {
      setStatus(1);

      const selectedWallet = nft?.offer?.user;

      const res = await deleteOffer(
        nft?.offer?.offer_id,
        selectedWallet === props.defaultWallet
      );
      console.log("res", res);

      // save to backend
      if (res.status) {
        const offerData = {
          nft_id: nft._id,
          prev_offer: nft?.offer?._id,
          wallet: selectedWallet,
          tx: res.data.transactionHash,
          offer_id: nft?.offer?.offer_id,
        };
        //setResponse(res.data);
        cancelOfferCreator(offerData, isProject ? "project" : "nft");
        setStatus(2);
      } else {
        console.log(res.data);
        setStatus(3);
        setError(res.data);
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("transaction_failed"),
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
        closeOfferModal();
      }
    } catch (e) {
      setStatus(3);
      console.log(e.message);
      setError(e);
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("canceling_offer_failed"),
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      closeOfferModal();
    }
  };

  return (
    <ModalAndOverlay colors={colors}>
      {connectWalletModal && (
        <WalletModal
          colors={colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}

      {/* --------- cancel an offer --------- */}
      <CancelOffer colors={colors}>
        <div className="d-flex justify-content-between mb-5">
          <h3 className="modal-heading title">{t("cancel_offer")}</h3>
          <CloseIcon
            color={colors?.text1}
            className="modal-close-icon"
            onClick={() => closeOfferModal(status == 2 ? true : false)}
          />
        </div>
        {status == 0 ? (
          <div>
            {chain !== nft?.blockchain && (
              <h4 className="mb-4"> {t("Please switch network")}</h4>
            )}
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <div className="note">
                {t("are_you_sure")}
                <div className="action">{t("cancel_offer")}?</div>
              </div>
            </Form.Group>
          </div>
        ) : (
          <>
            <div className="mb-4 pb-2">
              {status == 3 && (
                <div className="mb-3">
                  <h4>{t("transaction_failed")}</h4>
                  <span>{error.toString()}</span>
                </div>
              )}
              <div className="d-flex align-items-center mb-3">
                <div>
                  {status === 1 ? (
                    <Spinner animation="border" variant="primary" />
                  ) : status === 3 ? (
                    <Image src={errorIcon}></Image>
                  ) : (
                    <Image src={completedIcon}></Image>
                  )}
                </div>
                &nbsp;&nbsp;
                <div>
                  <h5 className="m-0 modal-section-title">
                    <strong>{t("cancel_offer")}</strong>
                  </h5>
                  <p className="m-0 modal-section-text">
                    {t("canceling_your_offer")}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        <Button
          disabled={status == 1}
          className="modal-button my-3"
          onClick={() =>
            status === 2 ? closeOfferModal(true) : cancelNFTOffer()
          }
        >
          {status === 0
            ? t("Confirm")
            : status === 1
            ? t("Calling...")
            : t("Done")}
        </Button>
      </CancelOffer>
    </ModalAndOverlay>
  );
};

export default CancelOfferModal;
