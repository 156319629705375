import * as offerTypes from "../types/offer";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const makeOfferCreator = (body, type = "nft") => {
  return (dispatch) => {
    dispatch({
      type: offerTypes.MAKE_OFFER_LOADING,
      loading: true,
    });

    API.post(`api/offer/${type}/place/`, body)
      .then((res) => {
        dispatch({
          type: offerTypes.MAKE_OFFER_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: offerTypes.MAKE_OFFER_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const editOfferCreator = (body, type = "nft") => {
  return (dispatch) => {
    dispatch({
      type: offerTypes.EDIT_OFFER_LOADING,
      loading: true,
    });

    API.post(`api/offer/${type}/edit/`, body)
      .then((res) => {
        dispatch({
          type: offerTypes.EDIT_OFFER_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: offerTypes.EDIT_OFFER_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const cancelOfferCreator = (body, type = "nft") => {
  return (dispatch) => {
    dispatch({
      type: offerTypes.CANCEL_OFFER_LOADING,
      loading: true,
    });

    API.post(`api/offer/${type}/cancel/`, body)
      .then((res) => {
        dispatch({
          type: offerTypes.CANCEL_OFFER_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: offerTypes.CANCEL_OFFER_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const acceptOfferCreator = (body, type = "nft") => {
  return (dispatch) => {
    dispatch({
      type: offerTypes.ACCEPT_OFFER_LOADING,
      loading: true,
    });

    API.post(`api/offer/${type}/accept/`, body)
      .then((res) => {
        dispatch({
          type: offerTypes.ACCEPT_OFFER_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: offerTypes.ACCEPT_OFFER_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getOffersCreator = (
  nft_id,
  queries,
  paginated = false,
  type = "nft"
) => {
  return (dispatch) => {
    dispatch({
      type: offerTypes.GET_OFFERS_LOADING,
      loading: true,
    });
    API.get(`api/offer/${type}/${nft_id}/`, queries)
      .then((res) => {
        dispatch({
          paginated,
          has_more: res.data.data?.length == 10,
          type: offerTypes.GET_OFFERS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: offerTypes.GET_OFFERS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
