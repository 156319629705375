import { createContext, useContext, useEffect, useState } from "react";
import keys from "../lib/config";
import Web3 from "web3";
import {
  NIFTYSouq721,
  NIFTYSouq1155,
  NIFTYSouqMarketPlace,
  NIFTYSouqFixed,
  NIFTYSouqAuction,
  web3auth,
  Weth,
  Wmatic,
  usdt,
} from "@config";
import { Web3AuthCore } from "@web3auth/core";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { CHAIN_NAMESPACES, ADAPTER_EVENTS } from "@web3auth/base";
// import { Web3Auth } from "@web3auth/web3auth";
// import { SafeEventEmitterProvider } from "@web3auth/base";
import algosdk from "algosdk";
import { AuthContext } from "./AuthContext";
import { ethers } from "ethers";
import { deployEnforcer, deployNFT } from "./AlgoUtils";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authActionsCreators, walletActionsCreators } from "../store";
// const abiDecoder = require("abi-decoder");

const md5 = require("md5");

const options = {
  // Enable auto reconnection
  reconnect: {
    auto: true,
    delay: 5000, // ms
    maxAttempts: 5,
    onTimeout: false,
  },
};

export const Web3AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { loginCreator, importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );

  const { authenticated } = useSelector((state) => state.auth);

  const [openlogin, setSdk] = useState();
  const [loginning, setLoginning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [web3Auth, setWeb3auth] = useState();
  const [privateKey, setPrivateKey] = useState("");
  const [userPrincipal, setUserPrincipal] = useState("");
  const [walletInfo, setUserAccountInfo] = useState({});
  const { account } = useSelector((state) => state.auth);
  const [chain, setChain] = useState(
    sessionStorage.chain ? sessionStorage.chain : "MATIC"
  );
  const authContext = useContext(AuthContext);

  const [web3, setWeb3] = useState(
    new Web3(
      new Web3.providers.WebsocketProvider(
        web3auth.websocketUrl[chain],
        options
      )
    )
  );

  let adapter = "";

  const subscribeAuthEvents = (web3Auth) => {
    // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
    web3Auth.on(ADAPTER_EVENTS.CONNECTED, async (data) => {
      console.log("Yeah!, you are successfully logged in");
      const user = await web3Auth.getUserInfo();
      const wallets = await getAccounts();
      const principal = md5(user.email);
      // console.log(first)
      await loginCreator({
        name: user.name,
        principal,
        email: user.email,
        profileImage: user.profileImage,
        typeLogin: user.typeOfLogin,
      });
      // if (wallets.address)
      //   await importWalletCreator({ wallet: wallets.address });

      setUserPrincipal(principal);
    });

    web3Auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      setLoginning(true);
      console.log("connecting");
    });

    web3Auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      console.log("disconnected");
    });

    web3Auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
      console.error("some error or user has cancelled login request", error);
    });
  };

  async function initializeOpenlogin() {
    try {
      const clientId = window.WEB3AUTH_KEY;
      const web3authCore = new Web3AuthCore({
        chainConfig: {
          chainNamespace: CHAIN_NAMESPACES.EIP155,
          chainId: window.WEB3AUTH_CHAIN_ID,
          rpcTarget: window.INFURA_RPC_URL,
          displayName: window.INFURA_RPC_NAME,
          blockExplorer:
            chain === "MATIC"
              ? window.INFURA_BLOCKEXPLORER_MATIC
              : window.INFURA_BLOCKEXPLORER,
          ticker: chain === "MATIC" ? "MATIC" : "ETH",
          tickerName: chain === "MATIC" ? "Matic" : "Ethereum",
          storageKey: "session",
        },
        clientId: clientId,
      });
      const openloginAdapter = new OpenloginAdapter({
        adapterSettings: {
          network: window.INFURA_LOGIN_NETWORK,
          clientId: clientId,
          uxMode: "redirect",
          redirectUrl: `${window.FRONT_END_URL}/auth`, // "http://localhost:3000/auth", //**CHECK THIS WHEN DEPLOYED */
        },
        loginSettings: {
          relogin: true,
        },
      });
      web3authCore.configureAdapter(openloginAdapter);
      subscribeAuthEvents(web3authCore);
      adapter = openloginAdapter;
      await setSdk(openloginAdapter);
      // console.log("web3authCore", web3authCore);
      await setWeb3auth(web3authCore);
      web3authCore.connectedAdapterName = openloginAdapter.name;
      web3authCore.cachedAdapter = openloginAdapter.name;
      web3authCore.status = ADAPTER_EVENTS.CONNECTED;
      await web3authCore.init();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
      const privKey = adapter.openloginInstance?.privKey;
      setPrivateKey(privKey);
      importUserAccount(privKey);
    }
  }

  useEffect(() => {
    const load = async () => {
      const result = await initializeOpenlogin();
      setLoading(false);
    };
    load();
  }, []);

  const importUserAccount = async (privateKey) => {
    if (privateKey) {
      await web3.eth.accounts.wallet.add(privateKey);
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const passphrase = algosdk.secretKeyToMnemonic(
        Buffer.from(privateKey, "hex")
      );
      const keyPair = algosdk.mnemonicToSecretKey(passphrase);
      const AlgoAccount = keyPair.addr;
      let balance = await web3.eth.getBalance(userAccount.address);
      let address = userAccount.address;
      setUserAccountInfo({ balance, address, AlgoAccount });
      address &&
        authenticated &&
        !userAccount?.wallets?.some(address) &&
        (await importWalletCreator({ wallet: address }));
      console.log("Algo account: ", AlgoAccount);
    }
  };

  const getAlgoAccount = async () => {
    if (privateKey) {
      const passphrase = algosdk.secretKeyToMnemonic(
        Buffer.from(privateKey, "hex")
      );
      const keyPair = algosdk.mnemonicToSecretKey(passphrase);
      const AlgoAccount = keyPair.addr;
      return AlgoAccount;
    }
  };

  const getAccount = async () => {
    if (privateKey) {
      await web3.eth.accounts.wallet.add(privateKey);
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const passphrase = algosdk.secretKeyToMnemonic(
        Buffer.from(privateKey, "hex")
      );
      const keyPair = algosdk.mnemonicToSecretKey(passphrase);
      const AlgoAccount = keyPair.addr;
      return userAccount, AlgoAccount;
    }
  };

  const onChangeChain = (data) => {
    sessionStorage.setItem("chain", data);
    setChain(data);
    setWeb3(
      new Web3(
        new Web3.providers.WebsocketProvider(web3auth.websocketUrl[data])
      )
    );
  };

  const login = async (method) => {
    console.log(web3Auth);
    if (web3Auth) {
      try {
        setLoginning(true);
        await web3Auth.connectTo(openlogin.name, {
          loginProvider: method,
        });
      } catch (e) {
        // User is already loggedin to Web3Auth
        if (e.code === 5111) {
          // web3 logout
          // Redirect to profile page
          await logout();
          await login(method);
        }
      }
    } else {
      initializeOpenlogin().then(async () => {
        await web3Auth.connectTo(openlogin.name, {
          loginProvider: method,
        });
      });
    }
  };

  const loginEmail = async (method, email) => {
    if (web3Auth) {
      try {
        await web3Auth.connectTo(openlogin.name, {
          loginProvider: method,
          extraLoginOptions: {
            login_hint: email,
          },
        });
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  const load = async () => {
    if (web3Auth) {
      if (openlogin) {
        try {
          const data = await web3Auth.getUserInfo();
          const principal = md5(data.email);
          setUserPrincipal(principal);
          return data;
        } catch (e) {
          console.log(e);
        }
      }
    }
  };
  const logout = async () => {
    try {
      await web3Auth.logout();
      localStorage.removeItem("user");
      localStorage.removeItem("user_profile");
      localStorage.removeItem("user_image");
      setPrivateKey("");
      setWeb3auth("");
      return true;
    } catch (e) {
      return false;
    }
  };

  const getWalletKey = async () => {
    if (openlogin) {
      return privateKey;
    }
  };

  const getAccounts = async () => {
    if (privateKey) {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      let balance = "0";
      try {
        balance = await web3.eth.getBalance(userAccount.address);
      } catch (e) {
        console.log(e);
      }
      let address = userAccount.address;
      // saveWalletsCreator(wallets, address);

      const passphrase = algosdk.secretKeyToMnemonic(
        Buffer.from(privateKey, "hex")
      );
      const keyPair = algosdk.mnemonicToSecretKey(passphrase);
      const AlgoAccount = keyPair.addr;
      // Making a connection to the Algorand TestNet
      const algodToken = {
        "x-api-key": window.ALGORAND_API_KEY,
      };
      const algodServer = "https://testnet-algorand.api.purestake.io/ps2"; // for testnet
      const algodPort = "";
      const client = new algosdk.Algodv2(algodToken, algodServer, algodPort);
      const algoInfo = await client.accountInformation(keyPair.addr).do();
      const algoBalance = algoInfo.amount;
      return {
        balance: web3.utils.fromWei(balance),
        address,
        AlgoAccount,
        algoBalance,
      };
    } else {
      return { balance: "", address: "", AlgoAccount: "", algoBalance: "" };
    }
  };

  const getWrappedBalance = async () => {
    const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
    const wrapped =
      chain === "ETH"
        ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
        : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
    const balance = await wrapped.methods.balanceOf(userAccount.address).call();
    const wrappedBalance = web3.utils.fromWei(balance);
    return wrappedBalance;
  };

  const getUSDTBalance = async () => {
    const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
    const usdc = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
    const balance = await usdc.methods.balanceOf(userAccount.address).call();
    const usdtBalance = web3.utils.fromWei(balance);
    return usdtBalance;
  };

  const SendTransaction = async (amount, recipientAddress) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        await web3.eth.accounts.wallet.add(privateKey);
        
        const res = await web3.eth.sendTransaction({
          from: userAccount.address,
          to: recipientAddress,
          value: web3.utils.toWei(amount.toString()),
          gasLimit: window.GAS_LIMIT[chain],
          gasPrice: web3.utils.toWei("250", "gwei"),
        });
        return { data: res, status: true };
      } catch (e) {
        console.log(e);
        return { data: e, status: false };
      }
    }
  };

  const mintDefaultAlgorand = async () => {
    console.log("In algo logic");
    const passphrase = algosdk.secretKeyToMnemonic(
      Buffer.from(privateKey, "hex")
    );
    const keyPair = algosdk.mnemonicToSecretKey(passphrase);
    const AlgoAccount = keyPair.addr;
    // Making a connection to the Algorand TestNet
    const algodToken = {
      "x-api-key": window.ALGORAND_API_KEY,
    };
    const algodServer = "https://testnet-algorand.api.purestake.io/ps2"; // for testnet
    const algodPort = "";
    const algodClient = new algosdk.Algodv2(algodToken, algodServer, algodPort);

    // deploy enforcer for royalties
    const enforcerAppId = await deployEnforcer(AlgoAccount, algodClient);
    const enforcerAddress = algosdk.getApplicationAddress(enforcerAppId);

    // deploy nft and freeze assets to the enforcer
    const assetId = await deployNFT(AlgoAccount, algodClient, enforcerAddress);
    return { status: true, data: assetId };
  };

  const mintDefault = async (data) => {
    console.dir(data);
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        console.log(data);
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqContract.methods.mintNft(data).send({
            from: userAccount.address,
            gasPrice: web3.utils.toWei("250", "gwei"),
            gasLimit: window.GAS_LIMIT[chain],
          });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqContract.methods.mintNft(data).send({
            from: userAccount.address,
            gasLimit: window.GAS_LIMIT[chain],
          });
          return { status: true, data: res };
        }
      } catch (e) {
        return { data: e, status: false };
      }
    }
  };

  const listSaleDefault = async (
    tokenId,
    tokenAddress,
    price,
    amount,
    currency
  ) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        
        await web3.eth.accounts.wallet.add(privateKey);
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .sellNft(tokenId, tokenAddress, price, amount, currency)
            .send({
              from: userAccount.address,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .sellNft(tokenId, tokenAddress, price, amount, currency)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  const mintListDefault = async (mintData, price) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .mintSellNft(mintData, price, "")
            .send({
              from: userAccount.address,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .mintSellNft(mintData, price, "")
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //list batch collection
  const listBatchCollectionDefault = async (
    tokenAddress,
    intialTokenId,
    price
  ) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const NIFTYSouqFixedContract = new web3.eth.Contract(
        NIFTYSouqFixed.abi,
        NIFTYSouqFixed.address[chain]
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      if (chain === "MATIC") {
        const res = await NIFTYSouqFixedContract.methods
          .batchSellNft(tokenAddress, intialTokenId, "USDT", price)
          .send({
            from: userAccount.address,
            gasLimit: window.GAS_LIMIT[chain],
            gasPrice: web3.utils.toWei("250", "gwei"),
          });
        return { status: true, data: res };
      } else {
        const res = await NIFTYSouqFixedContract.methods
          .batchSellNft(tokenAddress, intialTokenId, "USDT", price)
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        return { status: true, data: res };
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const editSaleDefault = async (offerId, newPrice) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .updateSalePrice(offerId, newPrice, "")
            .send({
              from: userAccount.address,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          console.log(res);
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .updateSalePrice(offerId, newPrice, "")
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  const cancelListingDefault = async (offerId) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .cancelSale(offerId)
            .send({
              from: userAccount.address,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .cancelSale(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  const buyOfferDefault = async (offerId, amount, price, buyer) => {
    console.log(offerId, amount, price);
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const value = web3.utils.toWei(price.toString());
      const NIFTYSouqFixedContract = new web3.eth.Contract(
        NIFTYSouqFixed.abi,
        NIFTYSouqFixed.address[chain]
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      if (chain === "MATIC") {
        const res = await NIFTYSouqFixedContract.methods
          .buyNft(offerId, amount, buyer)
          .send({
            from: userAccount.address,
            value: value,
            gasPrice: web3.utils.toWei("250", "gwei"),
            gasLimit: window.GAS_LIMIT[chain],
          });
        return { status: true, data: res };
      } else {
        const res = await NIFTYSouqFixedContract.methods
          .buyNft(offerId, amount, buyer)
          .send({
            from: userAccount.address,
            value: value,
            gasLimit: window.GAS_LIMIT[chain],
          });
        return { status: true, data: res };
      }
    } catch (e) {
      console.log(e.message);
      return { status: false, data: e };
    }
  };

  const buyOfferUSDTDefault = async (offerId, amount, price, buyer) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
      const value = web3.utils.toWei(price.toString(), "tether");
      const NIFTYSouqFixedContract = new web3.eth.Contract(
        NIFTYSouqFixed.abi,
        NIFTYSouqFixed.address[chain]
      );
      
      await web3.eth.accounts.wallet.add(privateKey);
      await tusdt.methods
        .approve(
          NIFTYSouqFixed.address[chain],
          ethers.BigNumber.from(2).pow(256).sub(1)
        )
        .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });

      if (chain === "MATIC") {
        const res = await NIFTYSouqFixedContract.methods
          .buyNft(offerId, amount, buyer)
          .send({
            from: userAccount.address,
            gasPrice: web3.utils.toWei("250", "gwei"),
            gasLimit: window.GAS_LIMIT[chain],
          });
        return { status: true, data: res };
      } else {
        const res = await NIFTYSouqFixedContract.methods
          .buyNft(offerId, amount, buyer)
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        return { status: true, data: res };
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const lazyMintDefault = async (amount, data, value, currency, buyer) => {
    console.log({ amount, data, value, currency, buyer });
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
      const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
        NIFTYSouqMarketPlace.abi,
        NIFTYSouqMarketPlace.address[chain]
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      
      if (currency === "USDC") {
        const allowance = await tusdt.methods.allowance(
            account,
            NIFTYSouqMarketPlace.address[chain]
          );
          if (parseInt(allowance) < parseInt(value)) {
        console.log(tusdt);
        chain === "MATIC"
          ? await tusdt.methods
              .approve(
                NIFTYSouqMarketPlace.address[chain],
                ethers.BigNumber.from(2).pow(256).sub(1)
              )
              .send({
                from: userAccount.address,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              })
          : await tusdt.methods
              .approve(
                NIFTYSouqMarketPlace.address[chain],
                ethers.BigNumber.from(2).pow(256).sub(1)
              )
              .send({
                from: userAccount.address,
                gasLimit: window.GAS_LIMIT[chain],
              });
      }
    }
      if (chain === "MATIC") {
        const result = await NIFTYSouqMarketPlaceContract.methods
          .lazyMintSellNft(amount, data, buyer)
          .call({
            from: userAccount.address,
            value: value,
            gasPrice: web3.utils.toWei("250", "gwei"),
          });
        console.log(result);
        const res =
          currency === "USDC"
            ? await NIFTYSouqMarketPlaceContract.methods
                .lazyMintSellNft(amount, data, buyer)
                .send({
                  from: userAccount.address,
                  gasPrice: web3.utils.toWei("250", "gwei"),
                  gas: 3000000
                })
            : await NIFTYSouqMarketPlaceContract.methods
                .lazyMintSellNft(amount, data, buyer)
                .send({
                  from: userAccount.address,
                  value: value,
                  gasPrice: web3.utils.toWei("250", "gwei"),
                  gas: 3000000
                });
        console.log(res);
        return { status: true, data: { result, res } };
      } else {
        const result = await NIFTYSouqMarketPlaceContract.methods
          .lazyMintSellNft(amount, data, buyer)
          .call({
            from: userAccount.address,
            value: value,
            gasPrice: web3.utils.toWei("250", "gwei"),
          });
        const res =
          currency === "USDC"
            ? await NIFTYSouqMarketPlaceContract.methods
                .lazyMintSellNft(amount, data, buyer)
                .send({
                  from: userAccount.address,
                  gasPrice: web3.utils.toWei("250", "gwei"),
                })
            : await NIFTYSouqMarketPlaceContract.methods
                .lazyMintSellNft(amount, data, buyer)
                .send({
                  from: userAccount.address,
                  value: value,
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        return { status: true, data: { res, result } };
      }
    } catch (e) {
      console.log(e);
      return { data: e, status: false };
    }
  };

  const lazyMintTokenUSDTDefault = async (data, value, currency) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
      const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
        NIFTYSouqMarketPlace.abi,
        NIFTYSouqMarketPlace.address[chain]
      );
      web3.eth.accounts.wallet.add(privateKey);
      
      if (currency === "USDC") {
        console.log(tusdt);
        console.log(data, "-------", userAccount.address);
        await tusdt.methods
          .approve(
            NIFTYSouqMarketPlace.address[chain],
            ethers.BigNumber.from(2).pow(256).sub(1)
          )
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        console.log("Approved");
      }
      if (chain === "MATIC") {
        const res = await NIFTYSouqMarketPlaceContract.methods
          .sellBezelNft(data)
          .send({
            from: userAccount.address,
            gasLimit: window.GAS_LIMIT[chain],
            gasPrice: web3.utils.toWei("250", "gwei"),
          });
        return { status: true, data: res };
      } else {
        console.log(data);
        const res =
          currency === ""
            ? await NIFTYSouqMarketPlaceContract.methods
                .sellBezelNft(data)
                .send({
                  from: userAccount.address,
                  value: value,
                  gasLimit: window.GAS_LIMIT[chain],
                })
            : await NIFTYSouqMarketPlaceContract.methods
                .sellBezelNft(data)
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                });
        return { status: true, data: res };
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const transferTokenDefault = async (to, tokenId, tokenAddress, amount) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
        NIFTYSouqMarketPlace.abi,
        NIFTYSouqMarketPlace.address[chain]
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      if (chain === "MATIC") {
        const data = await NIFTYSouqMarketPlaceContract.methods
          .transferNFT(userAccount.address, to, tokenId, tokenAddress, amount)
          .send({
            from: userAccount.address,
            gasLimit: window.GAS_LIMIT[chain],
            gasPrice: web3.utils.toWei("250", "gwei"),
          });
        return { status: true, data: data };
      } else {
        const data = await NIFTYSouqMarketPlaceContract.methods
          .transferNFT(userAccount.address, to, tokenId, tokenAddress, amount)
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        return { status: true, data: data };
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //burn trasnfer erc721
  const burnTokenDefault = async (tokenId, contract) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const NIFTYSouqContract = new web3.eth.Contract(
        NIFTYSouq721.abi,
        contract
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      if (chain === "MATIC") {
        const data = await NIFTYSouqContract.methods.burn(tokenId).send({
          from: userAccount.address,
          gasPrice: web3.utils.toWei("250", "gwei"),
          gasLimit: window.GAS_LIMIT[chain],
        });
        return { status: true, data: data };
      } else {
        const data = await NIFTYSouqContract.methods
          .burn(tokenId)
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        return { status: true, data: data };
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //token trasnfer erc1155
  const transferToken1155Default = async (to, tokenId, amount) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const NIFTYSouqContract = new web3.eth.Contract(
        NIFTYSouq1155.abi,
        NIFTYSouq1155.address[chain]
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      if (chain === "MATIC") {
        const data = await NIFTYSouqContract.methods
          .transferNft(userAccount.address, to, tokenId, amount)
          .send({
            from: userAccount.address,
            gasPrice: web3.utils.toWei("250", "gwei"),
            gasLimit: window.GAS_LIMIT[chain],
          });
        return { status: true, data: data };
      } else {
        const data = await NIFTYSouqContract.methods
          .transferNft(userAccount.address, to, tokenId, amount)
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        return { status: true, data: data };
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //burn trasnfer erc1155
  const burnToken1155Default = async (tokenId, amount, contract) => {
    try {
      const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
      const NIFTYSouqContract = new web3.eth.Contract(
        NIFTYSouq1155.abi,
        contract
      );
      await web3.eth.accounts.wallet.add(privateKey);
      
      if (chain === "MATIC") {
        const data = await NIFTYSouqContract.methods
          .burn(userAccount.address, tokenId, amount)
          .send({
            from: userAccount.address,
            gasPrice: web3.utils.toWei("250", "gwei"),
            gasLimit: window.GAS_LIMIT[chain],
          });
        return { status: true, data: data };
      } else {
        const data = await NIFTYSouqContract.methods
          .burn(userAccount.address, tokenId, amount)
          .send({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        return { status: true, data: data };
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //create auction
  const createAuctionDefault = async (AuctionData) => {
    console.log(AuctionData);
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const result = await NIFTYSouqAuctionContract.methods
            .createAuction(AuctionData)
            .send({
              from: userAccount.address,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: result };
        } else {
          const result = await NIFTYSouqAuctionContract.methods
            .createAuction(AuctionData)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: result };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //mint create auction
  const mintCreateAuctionDefault = async (mintAuctionData) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        console.log(userAccount);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        console.log(NIFTYSouqAuction.address[chain]);
        web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const result = await NIFTYSouqAuctionContract.methods
            .mintCreateAuctionNft(mintAuctionData)
            .send({
              from: userAccount.address,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: result };
        } else {
          const result = await NIFTYSouqAuctionContract.methods
            .mintCreateAuctionNft(mintAuctionData)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: result };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //Accept Bid
  const acceptBidDefault = async (offerId, bidIdx) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId, bidIdx)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId, bidIdx)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //Accept Highest Bid
  const acceptHighestBidDefault = async (offerId) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //cancel Auction
  const cancelAuctionDefault = async (offerId) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          try {
            const res = await NIFTYSouqAuctionContract.methods
              .cancelAuction(offerId)
              .send({
                from: userAccount.address,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });

            return { status: true, data: res };
          } catch (error) {
            console.log(error);
          }
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .cancelAuction(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //place bid
  const placeBidDefault = async (offerId, bidPrice) => {
    console.log({ offerId, bidPrice });
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        const checkAllowance = await wrapped.methods
          .allowance(userAccount.address, NIFTYSouqAuction.address[chain])
          .call({ from: userAccount.address });
        console.log(checkAllowance);
        if (checkAllowance < bidPrice) {
          const appr =
            chain === "ETH"
              ? await wrapped.methods
                  .approve(
                    NIFTYSouqAuction.address[chain],
                    ethers.BigNumber.from(2).pow(256).sub(1)
                  )
                  .send({
                    from: userAccount.address,
                    gasLimit: window.GAS_LIMIT[chain],
                  })
              : await wrapped.methods
                  .approve(
                    NIFTYSouqAuction.address[chain],
                    ethers.BigNumber.from(2).pow(256).sub(1)
                  )
                  .send({
                    from: userAccount.address,
                    gasLimit: window.GAS_LIMIT[chain],
                    gasPrice: web3.utils.toWei("250", "gwei"),
                  });
          console.log(appr);
        }

        const weth_balance = await wrapped.methods
          .balanceOf(userAccount.address)
          .call({ from: userAccount.address });

        console.log(weth_balance);
        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(bidPrice);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          let deposit = bidPrice;
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .placeBid(offerId, bidPrice)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .placeBid(offerId, bidPrice)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //place higher bid
  const placeHigherBidDefault = async (offerId, bidIdx, amount) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        const checkAllowance = await wrapped.methods
          .allowance(userAccount.address, NIFTYSouqAuction.address[chain])
          .call({ from: userAccount.address });
        console.log(checkAllowance);
        if (checkAllowance < amount) {
          chain === "ETH"
            ? await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                })
            : await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        }

        const weth_balance = await wrapped.methods
          .balanceOf(userAccount.address)
          .call({ from: userAccount.address });

        console.log(weth_balance);
        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(amount);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();

          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          console.log(wrapped);
          console.log("depositting " + amount.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: amount,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: amount,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .placeHigherBid(offerId, bidIdx, amount)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .placeHigherBid(offerId, bidIdx, amount)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //lazy place bid
  const lazyPlaceBidDefault = async (lazyMintAuctionData, bidPrice) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        const checkAllowance = await wrapped.methods
          .allowance(userAccount.address, NIFTYSouqAuction.address[chain])
          .call({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });
        console.log(checkAllowance);
        if (checkAllowance < bidPrice) {
          chain === "ETH"
            ? await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                })
            : await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        }

        const weth_balance = await wrapped.methods
          .balanceOf(userAccount.address)
          .call({ from: userAccount.address, gasLimit: window.GAS_LIMIT[chain] });

        console.log(weth_balance);

        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(bidPrice);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();

          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          console.log(wrapped);
          console.log("depositting " + bidPrice.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: bidPrice,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: bidPrice,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const result = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .call({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          const res = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: { res, result } };
        } else {
          const result = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .call({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          const res = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: { res, result } };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //cancel bid
  const cancelBidDefault = async (offerId, bidIdx) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .cancelBid(offerId, bidIdx)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .cancelBid(offerId, bidIdx)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //Place Offer
  const placeOfferDefault = async (data, value) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqMarketplaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        const checkAllowance = await wrapped.methods
          .allowance(userAccount.address, NIFTYSouqMarketPlace.address[chain])
          .call({ from: userAccount.address });
        console.log(checkAllowance);
        if (checkAllowance < value) {
          chain === "ETH"
            ? await wrapped.methods
                .approve(
                  NIFTYSouqMarketPlace.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                })
            : await wrapped.methods
                .approve(
                  NIFTYSouqMarketPlace.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        }

        const weth_balance = await wrapped.methods
          .balanceOf(userAccount.address)
          .call({ from: userAccount.address });

        console.log(weth_balance);
        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(value);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();

          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          console.log(wrapped);
          console.log("depositting " + value.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: value,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: value,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketplaceContract.methods
            .makeOffer(data)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketplaceContract.methods
            .makeOffer(data)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //Edit Offer
  const changeOfferDefault = async (offerId, price) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        const checkAllowance = await wrapped.methods
          .allowance(userAccount.address, NIFTYSouqMarketPlace.address[chain])
          .call({ from: userAccount.address });
        console.log(checkAllowance);
        if (checkAllowance < price) {
          chain === "ETH"
            ? await wrapped.methods
                .approve(
                  NIFTYSouqMarketPlace.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                })
            : await wrapped.methods
                .approve(
                  NIFTYSouqMarketPlace.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: userAccount.address,
                  gasLimit: window.GAS_LIMIT[chain],
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        }

        const weth_balance = await wrapped.methods
          .balanceOf(userAccount.address)
          .call({ from: userAccount.address });

        console.log(weth_balance);

        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(price);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();

          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          console.log(wrapped);
          console.log("depositting " + price.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: price,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: userAccount.address,
                value: price,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .editOffer(offerId, price)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .editOffer(offerId, price)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //Cancel Offer
  const deleteOfferDefault = async (offerId) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .cancelOffer(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .cancelOffer(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  //Accept Offer
  const acceptOfferPriceDefault = async (offerId) => {
    if (web3) {
      try {
        const userAccount = web3.eth.accounts.privateKeyToAccount(privateKey);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        await web3.eth.accounts.wallet.add(privateKey);
        
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .acceptOffer(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .accepOffer(offerId)
            .send({
              from: userAccount.address,
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        }
      } catch (e) {
        return { status: false, data: e };
      }
    }
  };

  const contextProvider = {
    privateKey,
    web3Auth,
    walletInfo,
    userPrincipal,
    loginning,
    chain,
    web3,
    loading,
    lazyMintDefault,
    lazyMintTokenUSDTDefault,
    buyOfferDefault,
    buyOfferUSDTDefault,
    cancelListingDefault,
    mintDefault,
    listSaleDefault,
    getAlgoAccount,
    getAccount,
    setChain,
    onChangeChain,
    load,
    loginEmail,
    login,
    logout,
    getWalletKey,
    getAccounts,
    getWrappedBalance,
    getUSDTBalance,
    SendTransaction,
    mintListDefault,
    listBatchCollectionDefault,
    transferTokenDefault,
    burnTokenDefault,
    transferToken1155Default,
    burnToken1155Default,
    lazyPlaceBidDefault,
    createAuctionDefault,
    mintCreateAuctionDefault,
    acceptBidDefault,
    acceptHighestBidDefault,
    placeBidDefault,
    placeHigherBidDefault,
    cancelBidDefault,
    cancelAuctionDefault,
    editSaleDefault,
    placeOfferDefault,
    changeOfferDefault,
    deleteOfferDefault,
    acceptOfferPriceDefault,
    mintDefaultAlgorand,
  };
  return (
    <Web3AuthContext.Provider value={contextProvider}>
      {children}
    </Web3AuthContext.Provider>
  );
};

export const Web3AuthContext = createContext({
  privateKey: null,
  web3Auth: null,
  walletInfo: null,
  userPrincipal: null,
  loginning: null,
  chain: null,
  web3: null,
  loading: true,
  lazyMintDefault: async () => {},
  lazyMintTokenUSDTDefault: async () => {},
  buyOfferDefault: async () => {},
  buyOfferUSDTDefault: async () => {},
  cancelListingDefault: async () => {},
  setApprovedDefault: async () => {},
  mintDefault: async () => {},
  listSaleDefault: async () => {},
  getAccount: async () => {},
  getAlgoAccount: async () => {},
  setChain: async () => {},
  onChangeChain: async () => {},
  load: async () => {},
  loginEmail: async () => {},
  login: async () => {},
  logout: async () => {},
  getWalletKey: async () => {},
  getAccounts: async () => {},
  getWrappedBalance: async () => {},
  getUSDTBalance: async () => {},
  SendTransaction: async () => {},
  mintListDefault: async () => {},
  listBatchCollectionDefault: async () => {},
  setApproved1155Default: async () => {},
  transferTokenDefault: async () => {},
  burnTokenDefault: async () => {},
  transferToken1155Default: async () => {},
  burnToken1155Default: async () => {},
  lazyPlaceBidDefault: async () => {},
  createAuctionDefault: async () => {},
  mintCreateAuctionDefault: async () => {},
  acceptBidDefault: async () => {},
  acceptHighestBidDefault: async () => {},
  placeBidDefault: async () => {},
  placeHigherBidDefault: async () => {},
  cancelBidDefault: async () => {},
  cancelAuctionDefault: async () => {},
  editSaleDefault: async () => {},
  placeOfferDefault: async () => {},
  changeOfferDefault: async () => {},
  deleteOfferDefault: async () => {},
  acceptOfferPriceDefault: async () => {},
  mintDefaultAlgorand: async () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

export const authUserPost = async (user) => {
  let response = await fetch(window.API_URL + "/api/user/login", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify({
      email: user.email,
      profileImage: user.profileImage,
      loginType: user.typeOfLogin,
      name: user.name,
      status: 1,
    }),
  });
  let result = await response.json();
  return result;
};
