import keys from "../../lib/config";
import { v4 as uuidv4 } from "uuid";
import WertWidget from "@wert-io/widget-initializer";
import Swal from "sweetalert2";
import { t } from "i18next";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "@context/Web3auth";

import {
  chains,
  NIFTYSouq1155,
  NIFTYSouq721,
  NIFTYSouqManager,
  NIFTYSouqMarketPlace,
  web3auth,
} from "../../config";
import { createDefaultLazyMintData } from "../../utils/lazy-mint-data-default";
import { createLazyMintData } from "../../utils/lazy-mint-data";
import { createAuctionLazyMint } from "../../utils/lazy-mint-auction";
import { createAuctionLazyMintDefault } from "../../utils/lazy-mint-auction-default";
import Web3Modal from "web3modal";
import { GiConsoleController } from "react-icons/gi";

const ethers = require("ethers");
const date = Date.now();

const startDate = Math.floor(new Date(date).getTime() / 1000);
export const errorAlert = (msg) => {
  Swal.fire({
    icon: "error",
    title: t("oops"),
    text: t(msg),
    confirmButtonText: t("Ok"),
    confirmButtonColor: "#6d3190",
  });
};

export const lazyMintDefault = async (args) => {
  const {
    NIFTYSouqManager,
    NIFTYSouq1155,
    NIFTYSouq721,
    privateKey,
    chain,
    saleType,
    web3,
    price,
    currency,
    fileUrl,
    amount,
    isMultiple,
    allData,
    reservePrice,
    startBidPrice,
    endDate,
  } = args;

  const wallet = new ethers.Wallet(privateKey);
  let reserve = 0;
  let minBid = 0;
  if (saleType == "2") {
    reserve = web3.utils.toWei(reservePrice.toString());
    minBid = web3.utils.toWei(startBidPrice.toString());
  }
  const lazyData =
    saleType == "2"
      ? await createAuctionLazyMintDefault(
          fileUrl,
          allData.creators,
          allData.royalties,
          allData.investors,
          allData.revenues,
          startDate,
          endDate,
          minBid,
          reserve,
          wallet,
          NIFTYSouqManager.address[chain],
          NIFTYSouq721.address[chain],
          await chains[chain].chainId
        )
      : await createDefaultLazyMintData(
          wallet,
          isMultiple
            ? NIFTYSouq1155.address[chain]
            : NIFTYSouq721.address[chain],
          NIFTYSouqManager.address[chain],
          currency === "USDC"
            ? await web3.utils.toWei(price.toString(), "mwei")
            : await web3.utils.toWei(price.toString()),
          allData.creators,
          allData.royalties,
          allData.investors,
          allData.revenues,
          fileUrl,
          amount,
          currency === "USDC" ? "USDC" : "",
          await chains[chain].chainId
        );

  return lazyData.signature;
};

export const lazyMint = async (args) => {
  const {
    NIFTYSouqManager,
    NIFTYSouq1155,
    NIFTYSouq721,
    isMultiple,
    chain,
    saleType,
    web3,
    price,
    currency,
    fileUrl,
    amount,
    allData,
    reservePrice,
    startBidPrice,
    endDate,
  } = args;

  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  const signer = provider.getSigner();
  console.log("market---" + NIFTYSouqManager.address[chain]);
  console.log(signer);
  let reserve = 0;
  let minBid = 0;
  if (saleType == "2") {
    reserve = web3.utils.toWei(reservePrice.toString());
    minBid = web3.utils.toWei(startBidPrice.toString());
  }
  console.log(isMultiple)
  const lazyData =
    saleType == "2"
      ? await createAuctionLazyMint(
          fileUrl,
          allData.creators,
          allData.royalties,
          allData.investors,
          allData.revenues,
          startDate,
          endDate,
          minBid,
          reserve,
          signer,
          NIFTYSouqManager.address[chain],
          NIFTYSouq721.address[chain]
        )
      : await createLazyMintData(
          signer,
          isMultiple
            ? NIFTYSouq1155.address[chain]
            : NIFTYSouq721.address[chain],
          NIFTYSouqManager.address[chain],
          currency === "USDC"
            ? await web3.utils.toWei(price.toString(), "mwei")
            : await web3.utils.toWei(price.toString()),
          allData.creators,
          allData.royalties,
          allData.investors,
          allData.revenues,
          fileUrl,
          amount,
          currency === "USDC" ? "USDC" : ""
        );
  console.log(lazyData);

  return lazyData.signature;
};

export const createTokenFiat = (
  chain,
  selected_wallet,
  setWertPaymentStatus
) => {
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC,USDC",
    commodity_amount: chain === "ETH" ? "0.05" : "2",
    address: selected_wallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
        console.log("status", status);
      },
    },
  };

  const wertWidget = new WertWidget(options);
  wertWidget.mount();
};

export const checkCreateNftInputsValidation = async (inputs) => {
  const {
    algo_wallet,
    name,
    description,
    price,
    saleType,
    reservePrice,
    startBidPrice,
    selectedWallet,
    chain,
    isLazymint,
  } = inputs;
  console.log("-------->", algo_wallet);
  console.dir(inputs);
  const web3 = new Web3(
    new Web3.providers.HttpProvider(chains[chain].rpcTarget)
  );

  if (
    (selectedWallet.create_wallet
      ? selectedWallet.create_wallet
      : selectedWallet) === algo_wallet
  ) {
    //check balance and others...
  } else {
    const accountBalance = await web3.eth.getBalance(
      selectedWallet.create_wallet
        ? selectedWallet.create_wallet
        : selectedWallet
    );
    const accountbalance = web3.utils.fromWei(accountBalance, "ether");
    console.log("accountbalance", accountbalance);
    if (!isLazymint && accountbalance == 0) {
      errorAlert(t("no_funds"));
      return false;
    }
  }

  if (!name) {
    errorAlert("please_input_name");
    return false;
  } else if (!description) {
    errorAlert("please_input_description");
    return false;
  } else if (!price && saleType == "1") {
    errorAlert("please_input_price");
    return false;
  } else if (saleType == "2" && reservePrice <= startBidPrice) {
    errorAlert("reserve_price_greater_than_bid");
    return false;
  } else return true;
};

export const createFormDataObject = async (args) => {
  const {
    selectedWallet,
    name,
    chain,
    category,
    selectedCollection,
    contract,
    description,
    price,
    saleType,
    file,
    isLazymint,
    amount,
    startBidPrice,
    reservePrice,
    day,
    allData,
    currency
  } = args;
  console.log("allData", allData);
  const form_data = new FormData();
  console.log(allData);
  form_data.append(
    "wallet",
    selectedWallet.create_wallet ? selectedWallet.create_wallet : selectedWallet
  );
  form_data.append("name", name);
  form_data.append("blockchain", chain);

  selectedCollection &&
    form_data.append("creator_collectionId", selectedCollection._id);
  form_data.append("contract", contract);
  form_data.append("description", description);
  form_data.append("price", saleType == "2" ? reservePrice : price || 0);
  form_data.append("currency", currency);
  form_data.append("sale_type", saleType);
  form_data.append("file", file);
  form_data.append("is_lazy", isLazymint);
  form_data.append("quantity", amount || 1);
  for (const prt of allData.partners) {
    form_data.append("partners[]", prt);
  }
  for (const rev of allData.revenues) {
    form_data.append("revenues[]", rev);
  }

  saleType == "2" &&
    form_data.append(
      "startBid",
      saleType == "2" ? parseFloat(startBidPrice) : 0
    );
  saleType == "2" &&
    form_data.append("auctionPeriod", saleType == "2" ? day : 0);
  form_data.append("start_date", date);
  console.log({ start_date: startDate });
  return form_data;
};
export const createEditFormDataObject = async (args, signature) => {
  const {
    selectedWallet,
    name,
    chain,
    category,
    selectedCollection,
    contract,
    description,
    price,
    currency,
    saleType,
    file,
    isLazymint,
    amount,
    startBidPrice,
    reservePrice,
    day,
    allData,
    royalties,
  } = args;
  console.log("allData", allData);

  const form_data = {
    name,
    price: saleType == "2" ? reservePrice : price || 0,
    description,
    blockchain: chain,
    startBid: saleType == "2" ? parseFloat(startBidPrice) : 0,
    sale_type: saleType,
    creator_collectionId: selectedCollection?._id || null,
    categories: category ? [category] : [],
    quantity: amount || 1,
    revenues: allData.revenues,
    contract,
    auctionPeriod: saleType == "2" ? day : 0,
    wallet: selectedWallet,
    partners: allData.partners,
    start_date: date,
    royalties,
    signature,
    is_lazy: isLazymint,
  };

  return form_data;
};

export const lazyMintToken = async (args) => {
  const { chain, selectedWallet, defaultWallet, fileUrl = null } = args;
  console.log(args)
  let sig;
  //lazy mint
  try {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    if (
      (selectedWallet.create_wallet
        ? selectedWallet.create_wallet
        : selectedWallet) === defaultWallet
    ) {
      sig = await lazyMintDefault({ ...args, web3, fileUrl });
    } else {
      sig = await lazyMint({ ...args, web3, fileUrl });
    }
  } catch (e) {
    console.log("e", e);
  }
  return { sig };
};

export const createToken = async (
  processStatusCallback,
  inputs,
  openModalCallback,
  createNftCreator
) => {
  try {
    //clear transaction status
    processStatusCallback({
      upload: "none",
      mint: "none",
    });
    //check validation inputs
    console.dir(inputs);
    const check_input = await checkCreateNftInputsValidation(inputs);
    console.log("check_input", check_input);
    //if validation failed abort
    if (!check_input) return;
    // open Modal
    check_input && openModalCallback(true);

    // set transaction status 1
    check_input &&
      processStatusCallback({
        upload: "loading",
        mint: "none",
      });
    //*====================================================

    // create form data obj
    const form_data = await createFormDataObject(inputs);

    // create nft
    await createNftCreator(form_data, inputs.isMultiple ? 1155 : 721);
  } catch (e) {
    console.log("e", e);
  }
};

export const mintUnlistedToken = async (args) => {
  const {
    algo_wallet,
    creator,
    chain,
    royalties,
    fileUrl,
    amount,
    selectedWallet,
    defaultWallet,
    mint,
    isMultiple,
    allData,
    mintDefaultAlgorand,
  } = args;
  console.log("args ->");
  console.dir(args);
  const web3 = new Web3(
    new Web3.providers.HttpProvider(chains[chain].rpcTarget)
  );
  console.log(isMultiple, allData);
  const mintData = {
    minter: selectedWallet.create_wallet
      ? selectedWallet.create_wallet
      : selectedWallet,
    tokenAddress: isMultiple
      ? NIFTYSouq1155.address[chain]
      : NIFTYSouq721.address[chain],
    uri: fileUrl,
    creators: allData.creators,
    royalties: allData.royalties,
    investors: allData.investors,
    revenues: allData.revenues,
    quantity: amount.toString(),
  };

  //call contract (mint)

  if (creator === algo_wallet) {
    console.log("creator === algo_wallet");
    const result = await mintDefaultAlgorand(mintData);
    console.log(result);
  } else {
    const result = await mint(
      mintData,
      selectedWallet === defaultWallet
    );
    console.log(result);
    const tx = result.data.transactionHash;
    const tokenId = result.data.events.eMint.returnValues.tokenId;
    console.log(result);
    return { tx, tokenId, royalties, result };
  }
};

export const mintFixedPriceToken = async (args) => {
  const {
    chain,
    royalties,
    fileUrl,
    amount,
    selectedWallet,
    defaultWallet,
    mintList,
    price,
    isMultiple,
    allData,
    currency
  } = args;

  const web3 = new Web3(
    new Web3.providers.HttpProvider(chains[chain].rpcTarget)
  );
  const value =
    currency === "USDC"
      ? web3.utils.toWei(price.toString(), "mwei")
      : web3.utils.toWei(price.toString());
  console.log(value);
  const listingData = {
    minter: selectedWallet.create_wallet
      ? selectedWallet.create_wallet
      : selectedWallet,
    tokenAddress: isMultiple
      ? NIFTYSouq1155.address[chain]
      : NIFTYSouq721.address[chain],
    uri: fileUrl,
    creators: allData.creators,
    royalties: allData.royalties,
    investors: allData.investors,
    revenues: allData.revenues,
    quantity: amount,
  };

  //TODO LISTING HERE
  const res = await mintList(
    listingData,
    value,
    currency,
    selectedWallet === defaultWallet
  );

  console.log({ res });
  const tx = res?.data?.transactionHash;
  const tokenId = res?.data?.events?.eFixedPriceSale?.returnValues?.tokenId;
  const offerId = res?.data?.events?.eFixedPriceSale?.returnValues?.offerId;
  return {
    tx,
    tokenId,
    offerId,
    royalties,
  };
};

export const mintAuctionToken = async (args) => {
  const {
    chain,
    royalties,
    fileUrl,
    selectedWallet,
    defaultWallet,
    reservePrice,
    startBidPrice,
    contract,
    endDate,
    mintCreateAuction,
    isMultiple,
    allData,
  } = args;

  const web3 = new Web3(
    new Web3.providers.HttpProvider(chains[chain].rpcTarget)
  );
  const reserve = web3.utils.toWei(reservePrice.toString());
  const minBid = web3.utils.toWei(startBidPrice.toString());
  const AuctionData = {
    tokenAddress: isMultiple
      ? NIFTYSouq1155.address[chain]
      : NIFTYSouq721.address[chain],
    uri: fileUrl,
    creators: allData.creators,
    royalties: allData.royalties,
    investors: allData.investors,
    revenues: allData.revenues,
    duration: endDate,
    startBidPrice: minBid,
    reservePrice: reserve,
  };
  console.log(AuctionData);
  const res = await mintCreateAuction(
    AuctionData,
    selectedWallet === defaultWallet
  );

  const tx = res.data.transactionHash;
  const tokenId = res.data.events.eCreateAuction.returnValues.tokenId;
  const offerId = res?.data?.events?.eCreateAuction?.returnValues?.offerId;
  console.log({ tokenId, offerId });
  return {
    tx,
    tokenId,
    offerId,
    royalties,
  };
};

export const mintToken = async (
  processStatusCallback,
  inputs,
  approveNftCreator
) => {
  try {
    console.log("start minting", inputs);
    const { isLazymint, saleType, category, token, royalties } = inputs;
    let approve_data = {};

    // heck if create nft success
    processStatusCallback({
      upload: "done",
      mint: "loading",
    });

    if (isLazymint) {
      approve_data = await lazyMintToken(inputs);
    } else {
      if (saleType == "0") {
        console.log("mint unlisted");
        console.log(inputs);
        approve_data = await mintUnlistedToken(inputs);
      } else if (saleType == "1") {
        approve_data = await mintFixedPriceToken(inputs);
      } else if (saleType == "2") {
        approve_data = await mintAuctionToken(inputs);
      }
    }
    console.log(approve_data);
    if (approve_data.sig || approve_data.tx) {
      //* start aprrove nft created for backend
      let approveData = {
        categories: category ? [category] : [],
        token: token || "",
        signature: approve_data.sig || "0x00",
        tx: approve_data.tx || "0x00",
        offer_id: approve_data.offerId || "0",
      };

      if (royalties) {
        approveData.royalties = royalties;
      }
      if (approve_data.tokenId) {
        approveData.token_id = approve_data.tokenId;
      }

      if (approve_data.tokenId) approveData.token_id = approve_data.tokenId;

      await approveNftCreator(inputs.isMultiple ? 1155 : 721, approveData);
      //* end aprrove nft created progress for backend

      processStatusCallback({
        upload: "done",
        mint: "done",
      });
    } else {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("transaction_failed"),
      });
    }
  } catch (error) {
    processStatusCallback({
      upload: "done",
      mint: "error",
    });
  }
};
export const editMintToken = async (inputs, updateNftCreator) => {
  try {
    const approve_data = await lazyMintToken(inputs);
    // console.log("approve_data", approve_data);
    if (approve_data.sig) {
      // create form data obj
      const form_data = await createEditFormDataObject(
        inputs,
        approve_data.sig || "0x00"
      );
      await updateNftCreator(
        form_data,
        inputs.isMultiple ? 1155 : 721,
        inputs.nft_id
      );
    } else {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("transaction_failed"),
      });
    }
  } catch (error) {
    Swal.fire({
      icone: "error",
      title: t("oops"),
      text: t("transaction_failed"),
    });
  }
};

export const uploadFileToPreview = async (file) => {
  let file_type = "image";
  let error = null;

  //* check file type
  if (file.type.includes("video")) file_type = "video";
  else if (file.type.includes("audio")) file_type = "audio";

  //* check file size
  if (!keys.FILE_SUPPORTED_FORMATS.includes(file.type))
    error = "unsupported_file_types";

  //* check file ext
  if (file.size >= keys.FILE_SIZE) error = "invalid_file_size";

  //* convert file to url to render it
  const file_reader = URL.createObjectURL(file);

  return { file_type, error, file_reader, file };
};
export const getNetworkFromCurrency = (currency) => {
  switch (currency) {
    case "ETH":
      return "ethereum";
    case "MATIC":
      return "polygon";
    case "BOBA":
      return "boba";
    case "BNB":
      return "bnb";

    default:
      return "ethereum";
  }
};
