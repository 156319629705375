import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Spinner } from "reactstrap";
import {
  artistActionsCreators,
  creatorCollectionsCreators,
} from "../../../../store";
import NoDataFound from "../components/NoDataFound";
import ArtistCollectionNFT from "../components/ArtistCollectionNFT";
import CreateCollectionPopup from "../../../../pages/CreateCollectionPopup";
import profileCover from "../../../../assets/images/profile-cover.png";
import noCollections from "../../../../assets/images/no-collections.png";
import { social } from "../../../../common/constants/gallery";
import "./styles.scss";

const CreatedArtist = () => {
  const { artistId } = useParams();
  const [isOpenCreateCollection, setOpenCreateCollection] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authenticated, account } = useSelector((state) => state.auth);
  const artist = useSelector((state) => state.artist.details.entities);
  const owner = useSelector((state) => state.artist.owner.entities);
  const {
    getArtistCreator,
    fetchArtistCollectionCreator,
    resetArtistCollectionCreator,
    fetchOwnerIdCreator,
  } = bindActionCreators(artistActionsCreators, dispatch);

  const isCollectionCreated = useSelector(
    (state) => state.creatorCollections.collectionCreated
  );
  const categoryState = useSelector((state) => state.category);
  const {
    loading,
    data,
    hasMore: has_more,
  } = useSelector((state) => state.artist.collections);
  const { resetCollectionFlags, resetCollectionState } = bindActionCreators(
    creatorCollectionsCreators,
    dispatch
  );

  useEffect(() => {
    getArtistCreator(artistId);
    fetchOwnerIdCreator(artistId);
    fetchArtistCollectionCreator(
      { artistId: artistId },
      {
        skip: 0,
        limit: 6,
      }
    );
    return () => {
      dispatch(resetArtistCollectionCreator());
      dispatch(resetCollectionState());
    };
  }, []);

  const getConfirmationModal = () => {
    if (isCollectionCreated) {
      Swal.fire({
        icon: "success",
        text: t("collection_created"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          setOpenCreateCollection(false);
          dispatch(resetCollectionFlags());
        },
        confirmButtonColor: "#6d3190",
      });
    }
  };

  const getUserAuthentication = () => {
    return authenticated && account?._id === owner?.ownerId;
  };
  if (artistId != artist?._id)
    return (
      <Container>
        <p className="p-5">{t("Loading...")}</p>
      </Container>
    );

  const urlWithProtocol = (data) => {
    if (data) {
      if (data.includes("https://")) {
        return data;
      } else if (data.includes("http://")) {
        return data;
      } else {
        return "https://" + data;
      }
    } else {
      return "";
    }
  };
  return (
    <div>
      <div className="cover-photo">
        <img
          src={artist?.coverImage || profileCover}
          className="profile-bg"
          alt=""
        />
      </div>
      <Container
        className="container-fluid galleries mr-0"
        style={{
          maxWidth: "1240px",
        }}
      >
        <div className="artist-info">
          <div>
            <Row>
              <Col
                xs={12}
                lg={8}
                className="d-flex flex-column flex-lg-row align-items-center"
              >
                <img
                  src={artist?.profileImage || "/user-placeholder.png"}
                  alt=""
                  width="120"
                  height="120"
                  className="rounded-full me-3 mb-3"
                  style={{ objectFit: "cover" }}
                />
                <div className="text-center text-lg-start flex-fill">
                  <div className="page-header-md ">{artist?.name}</div>
                  <p className="profile-message">{artist?.description}</p>
                  <div className="d-flex justify-content-center justify-content-lg-start"></div>
                </div>
              </Col>
              <div className="d-flex flex-row justify-content-end">
                {artist?.media &&
                  social.map(
                    ({ value, Icon }) =>
                      artist?.media[value] &&
                      artist?.media[value] !== "undefined" && (
                        <a
                          href={urlWithProtocol(artist?.media[value])}
                          target="_blank"
                        >
                          <div className="social-icon-button cursor">
                            <Icon width="14" height="14" color="#fff" />
                          </div>
                        </a>
                      )
                  )}
              </div>
            </Row>
          </div>
        </div>
        <Row
          style={{
            paddingTop: "35px",
            paddingBottom: "17px",
          }}
        >
          <Col
            md="10"
            style={{
              fontWeight: "400",
              fontSize: "28px",
              lineHeight: "42px",
              color: "#3C3C3C",
            }}
          >
            {t("Collections")}
          </Col>
          <Col md="2">
            {getUserAuthentication() && (
              <Button onClick={() => setOpenCreateCollection(true)}>
                {t("create_collection")}
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <div
            style={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#000000",
            }}
          >
            {data?.length} {data?.length <= 1 ? t("item") : t("items")}
          </div>
        </Row>
        <Row>
          {data.map((el) => (
            <Col xs={12} md={4} lg={4} style={{ margin: "10px 0" }}>
              <ArtistCollectionNFT item={el} />
            </Col>
          ))}
        </Row>
        {has_more && data?.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                borderRadius: 25,
                border: "none",
                margin: "0 auto",
                padding: "10px 20px",
                background: `linear-gradient(to right, #6D3190, #BC60F1)`,
              }}
              onClick={() => {
                fetchArtistCollectionCreator(
                  { artistId: artistId },
                  {
                    skip: data?.length,
                    limit: 8,
                  }
                );
              }}
            >
              {t("Load More")}
              {loading && (
                <Spinner
                  size="sm"
                  style={{ color: "#fff", marginLeft: "5px" }}
                />
              )}
            </Button>
          </div>
        )}

        {/* {!loading && data?.length === 0 && getUserAuthentication() && (
          <NoDataFound text={t("no_collection_found")} />
        )}
        {!loading && data?.length === 0 && !getUserAuthentication() && (
          <NoDataFound text={t("public_no_collection_found")} />
        )} */}
        {!loading && data?.length === 0 && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img
              src={noCollections}
              width="100%"
              height="300"
              className="my-5"
              style={{ objectFit: "contain" }}
            />
            <div className="mt-4 mb-1" style={{ fontSize: 22 }}>
              {t("no_collections_found")}
            </div>
          </div>
        )}

        <CreateCollectionPopup
          isOpen={isOpenCreateCollection}
          setIsOpen={setOpenCreateCollection}
          categories={categoryState.categories}
          creatorId={account?._id}
          artistId={artistId}
        />
      </Container>
      {getConfirmationModal()}
    </div>
  );
};

export default React.memo(CreatedArtist);
