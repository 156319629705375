import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "@context/Web3auth";
import {
  chains,
  NIFTYSouq721,
  NIFTYSouq1155,
  NIFTYSouqMarketPlace,
  web3auth
} from "@config";
import { useEffect, useState } from "react";
export const ABIs = {
  [NIFTYSouq721.address["ETH"].toLowerCase()]: NIFTYSouq721.abi,
  [NIFTYSouq721.address["MATIC"].toLowerCase()]: NIFTYSouq721.abi,
  [NIFTYSouq1155.address["ETH"].toLowerCase()]: NIFTYSouq1155.abi,
  [NIFTYSouq1155.address["MATIC"].toLowerCase()]: NIFTYSouq1155.abi,
  [NIFTYSouqMarketPlace.address["ETH"].toLowerCase()]: NIFTYSouqMarketPlace.abi,
  [NIFTYSouqMarketPlace.address["MATIC"].toLowerCase()]: NIFTYSouqMarketPlace.abi,
};

export const useAddress = () => {
  const { chain } = useWeb3Auth();
  const getNiftySouqAddress = () => {
    return NIFTYSouqMarketPlace.address[chain].toLowerCase();
  }
  const getNiftySouq721Address = () => {
    return NIFTYSouq721.address[chain].toLowerCase();
  }
  const getNiftySouq1155Address = () => {
    return NIFTYSouq1155.address[chain].toLowerCase();
  }

  return {
    getNiftySouqAddress,
    getNiftySouq721Address,
    getNiftySouq1155Address
  }
}

export const useContract = () => {
  const { library, account } = useWeb3React();
  const { privateKey, chain } = useWeb3Auth();
  const [web3AuthProvider, setWeb3AuthProvider] = useState(null);
  const [web3Provider, setWeb3Provider] = useState(null);

  useEffect(() => {
    if (chain) {
      setWeb3AuthProvider(new ethers.providers.WebSocketProvider(web3auth.websocketUrl[chain]));
    }
  }, [chain]);

  useEffect(() => {
    if (library?.provider) {
      console.log('----metamask provider----');

      setWeb3Provider(new ethers.providers.Web3Provider(library.provider));
    }
  }, [library]);

  const getSigner = (walletAddr) => {
    if (walletAddr != account) {
      return new ethers.Wallet(privateKey);
    }
    else if (web3Provider) {
      console.log('----metamask signer----', web3Provider, web3Provider.getSigner());
      return web3Provider.getSigner();
    }
  }

  const createContract = (contractAddr, walletAddr) => {
    const provider = getProvider(walletAddr != account);
    if (contractAddr && ABIs[contractAddr.toLowerCase()]) {
      const contract = new ethers.Contract(contractAddr, ABIs[contractAddr.toLowerCase()], provider);
      return contract;
    }
  }

  const getProvider = (isWeb3Auth) => {
    if (isWeb3Auth && web3AuthProvider) {
      return web3AuthProvider;
    }
    return web3Provider || (new ethers.providers.JsonRpcProvider(chains[chain].rpcTarget));
  }

  return {
    createContract,
    getSigner,
    getProvider,
    chain
  };
}