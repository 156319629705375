/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { Button, Form, Image, Spinner } from "react-bootstrap";
import Web3 from "web3";
import { chains, NIFTYSouq1155, NIFTYSouq721 } from "../config";
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { nftActionsCreators, partnerActionCreators } from "../store";
import { useTranslation } from "react-i18next";
import { getEditions, extractLoggedInOwner } from "../core/nft";
//icons
import ModalAndOverlay from "./ModalAndOverlay";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import visamaster from "@assets/icons/visa-master.png";
import { Checkout } from "./Modals";
import Swal from "sweetalert2";
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";
import WalletModal from "./WalletModal";
import { useWeb3React } from "@web3-react/core";

const TransferNftModal = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { purchaseNFTCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );
  console.log(props);
  const { nft, externalNFT, selectedOwner } = props;
  //library
  const { chain } = useWeb3Auth();
  const { transferToken, transferToken1155 } = useWeb3Context();
  const { account } = useWeb3React();
  const [error, setError] = useState("");
  const [status, setStaus] = useState(0);
  const [isFiat, setFiatPay] = useState(false);
  const [address, setAddress] = useState("");
  const [otherWallet, setOtherWallet] = useState(false);
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [selectedWallet, setSelectWallet] = useState(
    nft?.token_standard === "1155"
      ? selectedOwner.user.create_wallet
      : nft?.owner?.create_wallet
  );
  const [selectedReceiverWallet, setSelectedReceiverWallet] = useState("");
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");
  const [amount, setAmount] = useState(1);
  const is1155 = nft?.token_standard === "1155";
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC",
    commodity_amount: 0 + 0.009,
    address: selectedWallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
      },
    },
  };
  const wertWidget = new WertWidget(options);
  const transfer = async (isFiat) => {
    try {
      if (isFiat) wertWidget.mount();
      else {
        console.log(
          selectedReceiverWallet,
          nft?.token_id,
          nft?.contract,
          amount,
          selectedWallet == props.defaultWallet
        );
        setStaus(1);

        const res = await transferToken(
          selectedReceiverWallet,
          nft?.token_id,
          nft?.contract,
          amount,
          selectedWallet == props.defaultWallet
        );
        console.log(res);
        // save to backend
        if (res.status) {
          let buyEditions = [];
          if (nft?.token_standard === "1155") {
            const user = await extractLoggedInOwner(
              selectedOwner?.user?._id,
              nft?.owners
            );

            buyEditions = await getEditions(user?.unlisted_editions, amount);
            console.log(user, buyEditions);
          }
          const buyData = {
            wallet: selectedReceiverWallet,
            price: 0,
            tx: res.data.transactionHash,
            token_id: nft?.token_id,
            owner: selectedWallet,
            editions: nft?.token_standard === "1155" ? buyEditions : [],
            is_transfer: true,
          };
          purchaseNFTCreator(nft?._id, buyData, nft?.token_standard);
          setStaus(2);
        } else {
          setStaus(3);
          setError(res.data);
          closeOfferModal();
        }
      }
    } catch (e) {
      setStaus(3);
      setError(e.message);
      closeOfferModal();
    }
  };

  const { colors, closeOfferModal, walletOptions } = props;
  return (
    <div>
      {connectWalletModal && (
        <WalletModal
          colors={colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}
      <ModalAndOverlay colors={colors}>
        <div>
          <div className="d-flex justify-content-between mb-4">
            <h3 className="modal-heading" style={{ color: colors?.text }}>
              {t("buy_with_card")}
            </h3>
            <CloseIcon
              color={colors?.text1}
              className="modal-close-icon"
              onClick={() => setFiatPay(false)}
            />
          </div>
          <div id="wert-widget" />
          {wertPaymentStatus == "success" && (
            <Button
              className={`modal-button ${colors?.button}`}
              onClick={() => {
                transfer();
                setFiatPay(false);
              }}
            >
              {t("Proceed")}
            </Button>
          )}
        </div>
      </ModalAndOverlay>

      {!isFiat && (
        <ModalAndOverlay colors={colors}>
          <Checkout colors={colors}>
            <div className="d-flex justify-content-between mb-3">
              <div className="modal-heading">
                <div className="title">{t("transfer_nft")}</div>
              </div>
              <CloseIcon
                color={colors?.text1}
                onClick={closeOfferModal}
                className="modal-close-icon"
              />
            </div>

            {status === 3 ? (
              <>
                <h4>{t("transaction_failed")}</h4>
                <div className="message p-4 title">
                  <div className="d-flex table-row">
                    <span>{error.toString()}</span>
                  </div>
                </div>
              </>
            ) : status === 2 ? (
              <>
                <div className="d-flex flex-column">
                  <div className="success-message">
                    {t("successfully_transferred")}
                    <strong className="mx-1">{nft?.name}</strong>
                  </div>
                  <div className="success-message_1 mt-3">
                    {t("congratulations_successfully_transferred")}
                    <strong className="mx-1">{nft?.name}</strong>
                    {t("on")}
                    <strong className="mx-1">{t("Nifty Souq")}</strong>
                  </div>
                  <Image
                    className="my-4"
                    src={nft?.url}
                    height={250}
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <Button className="modal-button mb-3" onClick={closeOfferModal}>
                  {t("View NFT")}
                </Button>
                {/* <Button
                  onClick={() => props.closeOfferModal()}
                  className={`modal-button ${props?.colors?.button}`}
                >
                  {t("Cancel")}
                </Button> */}
              </>
            ) : (
              <>
                {status === 0 ? (
                  <>
                    <div style={{ fontWeight: 325 }}>
                      {t("transfer_note")} <span>{nft?.name}</span>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Image
                        className="my-4"
                        src={nft?.url}
                        height={250}
                        style={{ objectFit: "contain" }}
                      />
                    </div>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        <div>{t("Owner address")}</div>
                        {/* </Form.Label>
                      <Form.Label> */}
                        <div>
                          {nft?.token_standard === "1155"
                            ? selectedOwner.user.create_wallet
                            : nft?.owner?.create_wallet}
                        </div>
                      </Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>
                        <div className="mt-3">{t("To")}</div>
                      </Form.Label>
                      {/* <Form.Select
                        aria-label="Wallet address"
                        className="payment-page-input select"
                        value={selectedReceiverWallet}
                        onChange={(e) => {
                          {
                            e.target.value === "Other"
                              ? setOtherWallet(true)
                              : setOtherWallet(false);
                          }
                          setSelectedReceiverWallet(e.target.value);
                        }}
                      >
                        {walletOptions?.map((item, key) => {
                          return (
                            <option value={item.value} key={key}>
                              {item.label}
                            </option>
                          );
                        })}
                        <option>{t("Other")}</option>
                      </Form.Select>

                      {otherWallet && ( */}
                      <Form.Control
                        className="payment-page-input"
                        style={{ marginTop: "30px" }}
                        type="text"
                        placeholder="Insert another wallet address"
                        value={
                          selectedReceiverWallet === "Other"
                            ? ""
                            : selectedReceiverWallet
                        }
                        onChange={(e) =>
                          setSelectedReceiverWallet(e.target.value)
                        }
                      />

                      {is1155 && (
                        <Form.Control
                          className="payment-page-input"
                          style={{ marginTop: "30px" }}
                          type="text"
                          placeholder="Insert amount"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                      )}
                    </Form.Group>
                  </>
                ) : (
                  <div className="mb-4 pb-2">
                    <div className="d-flex align-items-center mb-3">
                      <div>
                        {status === 1 ? (
                          <Spinner animation="border" variant="primary" />
                        ) : status > 1 ? (
                          <Image src={completedIcon}></Image>
                        ) : (
                          <Image src={lockOrderIcon}></Image>
                        )}
                      </div>
                      &nbsp;&nbsp;
                      <div>
                        <div className="m-0 modal-section-title title">
                          <div>{t("Purchasing")}</div>
                        </div>
                        <p className="m-0 modal-section-text title">
                          {t("Call contract method")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <Button
                  style={{ marginTop: "50px" }}
                  className="modal-button mb-3"
                  id="buy-crypto"
                  disabled={
                    status === 0 &&
                    !selectedReceiverWallet
                  }
                  onClick={() => {
                    nft?.blockchain === chain
                      ?  status === 0
                        ? transfer()
                        : closeOfferModal()
                      : Swal.fire({
                          icone: "error",
                          title: t("oops"),
                          text: t("switch_network"),
                          color: props?.colors?.text,
                          background: props?.colors?.bg,
                          confirmButtonText: t("Ok"),
                          confirmButtonColor: "#6d3190",
                        });
                  }}
                >
                  {account === undefined
                    ? t("please connect your wallet")
                    : status === 0
                    ? t("Proceed to transfer")
                    : status === 1
                    ? t("Calling...")
                    : t("View NFT")}
                </Button>
                {/* <Button
                  className={`modal-button mb-3 ${colors?.button}`}
                  id="buy-fiat"
                  onClick={() => {
                    setFiatPay(true);
                    status === 0 ? transfer(true) : closeOfferModal(true);
                  }}
                >
                  {status === 0
                    ? t("Pay with card")
                    : status === 1
                    ? "Calling..."
                    : "View NFT"}{" "}
                  <img
                    src={visamaster}
                    className="no-rotate"
                    width="80"
                    height="30"
                  />
                </Button> */}
              </>
            )}
          </Checkout>
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default TransferNftModal;
