import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./MarketplaceDropdown.css";

const MarketplaceDropdown = ({ isDropdown, dropdownLocation }) => {
  const { loading, error, categories } = useSelector((state) => state.category);
  const container = useRef(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const dropdown = container.current;
    dropdown.style.left = `${dropdownLocation.center - 100}px`;
    dropdown.style.top = `${dropdownLocation.bottom}px`;
  }, [dropdownLocation]);

  //   const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  return (
    <div
      className={`marketplaceDropdown shadow-lg${isDropdown ? " show" : ""} ${
        categories && categories?.length > 0 ? "" : " d-none"
      }`}
      ref={container}
    >
      {/* if loading */}
      {loading && ""}
      {/* if error */}
      {error && <p>{error}</p>}
      {/* if latest items array contains items */}
      {categories && categories?.length > 0 && (
        <div className="d-flex">
          {/* main links */}
          <ul className="main-links m-0 p-0">
            {categories.map((item, index) => {
              return (
                <li className="py-3 px-2" key={index}>
                  <Link to={`/category/${item._id}`} className="li-item">
                    <span>{t(item.name)}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MarketplaceDropdown;
