import {
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useRef } from "react";
import axios from "axios";
import keys from "../lib/config";
import whiteLogo from "../assets/images/white-logo.png";
import logo from "../assets/images/purple-logo.png";
import { ReactComponent as Facebook } from "../assets/icons/footer/facebook.svg";
import { ReactComponent as Instagram } from "../assets/icons/footer/instagram.svg";
import { ReactComponent as Twitter } from "../assets/icons/footer/twitter.svg";
import { ReactComponent as Linkedin } from "../assets/icons/footer/linkedin.svg";
import { ReactComponent as Discord } from "../assets/icons/footer/discord.svg";
import { ReactComponent as Telegram } from "../assets/icons/footer/telegram.svg";
import emailIcon from "../assets/icons/footer/email.svg";
import styled from "styled-components";
import Swal from "sweetalert2";

const FooterWrapper = styled.footer`
  background-color: ${(props) => (props.theme == "light" ? "#fff" : "#121212")};
  color: ${(props) => (props.theme == "light" ? "#000" : "#fff")};
  border-top: ${(props) =>
    props.theme == "light" ? "#e6e8ec solid 1px" : "#4E4E4E solid 1px"};

  .footer-section {
    padding: 76px 0 56px 0;
  }
  .label {
    font-size: 40px;
    line-height: 48px;
  }
  .item {
    flex-grow: 1;
  }
  .subitem {
    flex-grow: 1;
  }
  .subscribe {
    font-size: 28px;
  }
  .subscribe-input {
    border-radius: 50px;
    width: 500px;
    height: 51px;
    background: linear-gradient(180deg, #f8f8f8 0%, #ffffff 100%);
    border: 3px solid #f4f4f4;
    padding-inline-end: 140px;
    padding-inline-start: 38px;
    font-weight: 325;
  }
  .subscribe-btn-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${(props) => (props.isLTR ? "unset" : "10px")};
    right: ${(props) => (props.isLTR ? "10px" : "unset")};
    z-index: 5;
    display: flex;
    align-items: center;
  }
  .subscribe-btn {
    background-image: ${(props) =>
      props.theme == "light"
        ? "linear-gradient(to right,#6d3190 0%,#a63fcd 50%,#bc60f1 100%)"
        : "linear-gradient(79.84deg, #9b6f21 -4.82%,rgba(173, 128, 49, 0.63) 103.83%)"};
    background-image: ;
    color: #fff;
    font-weight: 350;
    border-radius: 50px;
    text-decoration: none;
    &:hover {
      opacity: 0.85;
    }
    width: 126px;
    height: 40px;
  }
  .icon-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: ${(props) => (props.isLTR ? "undefined" : "15px")};
    left: ${(props) => (props.isLTR ? "15px" : "undefined")};
    z-index: 5;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1200px) {
    .subscribe-input {
      width: 400px;
    }
  }
  .join-txt {
    font-size: 18px;
  }
  @media (max-width: 992px) {
    .links-section {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: #e6e8ec solid 1px;
    }
    .footer-section {
      padding: 30px 0 30px 0;
    }
    .item {
      flex-shrink: 0;
      flex-basis: 100%;
    }
    .label {
      font-size: 30px;
      line-height: 30px;
    }
    .subscribe {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 767px) {
    .subscribe-input {
      width: 350px;
    }
    .subitem {
      flex-shrink: 0;
      flex-basis: 100%;
    }
    .footer-left {
      text-align: center;
    }
  }
  .social-icons {
    display: flex;
    a {
      margin-inline-end: 20px;
      border-radius: 50%;
      width: 27px;
      height: 27px;
      background-color: ${(props) =>
        props.theme == "light" ? "#6d3190" : "#fff"};
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .copyright {
    width: 100%;
    box-sizing: border-box;
    padding-top: 25px;
    padding-bottom: 10px;
    // padding-left: 75px;
    // padding-right: 75px;
    // background: #f4f4f4;
    color: ${(props) => (props.theme == "light" ? "#662c80" : "#fff")};
    font-size: 14px;
    a {
      color: ${(props) => (props.theme == "light" ? "#662c80" : "#fff")};
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .footer-link {
    color: ${(props) => (props.theme == "light" ? "#232323" : "#fff")};
    font-size: 16px;
    font-weight: 325;
    :hover {
      color: ${(props) => (props.theme == "light" ? "#662c80" : "#fff")};
    }
  }
  .footer-link-label {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .validation {
    color: red;
    font-size: 12px;
    margin-inline-start: 15px;
    font-weight: 325;
  }
`;

function Footer({ theme = "light" }) {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language !== "ar";
  const { categories } = useSelector((state) => state.category);

  const form = useRef(null);
  const [email, setEmail] = useState(null);
  const [valid, setValid] = useState(true);

  const validateEmail = () => {
    const isValid = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/gi.test(
      `${email.trim()}`
    );
    setValid(isValid);
    return isValid;
  };
  const handleSubmit = async (event) => {
    if (validateEmail()) {
      const data = { email };
      axios
        .post(window.API_URL + "/api/user/subscribe", data)
        .then((response) => {
          setEmail(null);
          Swal.fire({
            icon: "success",
            title: t("Newsletter"),
            text: t("Subscribed Successfully"),
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        });
      form.current.reset();
    }
  };

  return (
    <FooterWrapper isLTR={isLTR} theme={theme}>
      <Container>
        {/* --------- Join our Newsletter & Join The Community --------- */}
        <Row className="footer-section d-flex justify-content-between mb-0 mb-lg-4">
          <Col xs={4} className="links-section d-block d-lg-none">
            <Link to="/" className="d-inline-block">
              <img
                width="192"
                height="auto"
                alt="NiftySouq"
                src={theme == "light" ? logo : whiteLogo}
              />
            </Link>
          </Col>
          <Col
            lg={6}
            xs={8}
            className="links-section d-flex align-items-center align-items-lg-end"
          >
            <div className="label">
              {t("The first and largest NFT marketplace in MENA")}
            </div>
          </Col>

          <Col
            xs={12}
            lg={6}
            className="d-flex justify-content-start justify-content-lg-end links-section"
          >
            <div>
              <div className="mb-4 subscribe">
                {t("Subscribe to the newsletter")}
              </div>
              <Form ref={form}>
                <div style={{ position: "relative" }}>
                  <FormControl
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={t("Enter your email address")}
                    className="subscribe-input"
                    aria-label="Enter Your Email"
                  />

                  <div className="subscribe-btn-container">
                    <Button
                      className="subscribe-btn"
                      variant="outline-none my-auto"
                      onClick={handleSubmit}
                    >
                      {t("Subscribe")}
                    </Button>
                  </div>
                  <div className="icon-container">
                    <img src={emailIcon} />
                  </div>
                </div>
                {
                  <div className="validation">
                    {valid ? "" : t("EmailValidation")}
                  </div>
                }
              </Form>
            </div>
          </Col>
        </Row>

        {/* --------- Links --------- */}
        <div className=" pb-0 pb-lg-5 d-flex justify-content-between flex-wrap">
          <div className="item links-section d-none d-lg-block">
            <Link to="/" className="d-inline-block">
              <img
                src={theme == "light" ? logo : whiteLogo}
                width="192"
                height="auto"
                alt="NiftySouq"
              />
            </Link>
          </div>
          <div className="item d-flex justify-content-between flex-wrap">
            {/* <div className="subitem links-section">
              {categories && categories?.length > 0 && (
                <>
                  <div className="footer-link-label">{t("Marketplace")}</div>
                  <ul className="list-unstyled">
                    {categories.map((item, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={`/category/${item._id}`}
                            className="footer-link"
                          >
                            {t(item.name)}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div> */}
            <div className="subitem links-section">
              <div className="footer-link-label">{t("Info")}</div>
              <ul className="list-unstyled">
                {/* <li>
                <Link to="#">{t("Help Center")}</Link>
              </li> */}
                <li>
                  <Link to="/contact-us" className="footer-link">
                    {t("Contact Us")}
                  </Link>
                </li>
                <li>
                  <Link to="/faq" className="footer-link">
                    {t("FAQs")}
                  </Link>
                </li>
                {/* <li>
                <Link to="#">{t("How to became a partner")}</Link>
              </li> */}
                {/* <li>
                <Link to="#">{t("Demos")}</Link>
              </li> */}
              </ul>
              {/* </Col> */}
            </div>

            <div className="subitem links-section">
              {/* <Col xs="12" md="6" lg="1" className="links-section"> */}
              <div className="footer-link-label">{t("Services")}</div>
              <ul className="list-unstyled">
                <li>
                  <Link to="/discover" className="footer-link">
                    {t("Discover")}
                  </Link>
                </li>
                {/* <li>
                <Link to="#">{t("Blog")}</Link>
              </li> */}
                <li>
                  <Link to="/gallery-intro" className="footer-link">
                    {t("About Galleries")}
                  </Link>
                </li>
                <li>
                  <Link to="/about-us" className="footer-link">
                    {t("About Us")}
                  </Link>
                </li>
              </ul>
              {/* </Col> */}
            </div>
            <div className="item links-section d-flex flex-column justify-content-center align-items-lg-end mt-lg-2">
              <div className=" d-flex flex-column align-items-start">
                <div className="mb-2 mb-lg-3 join-txt">
                  {t("Join The Community")}
                </div>
                <div className="social-icons">
                  <a href="https://www.facebook.com/NIFTYSouq/" target="_blank">
                    <Facebook
                      width="18"
                      height="18"
                      color={theme == "light" ? "#fff" : "#000"}
                    />
                  </a>
                  <a
                    href="https://www.instagram.com/nifty_souq/"
                    target="_blank"
                  >
                    <Instagram
                      width="18"
                      height="18"
                      color={theme == "light" ? "#fff" : "#000"}
                    />
                  </a>
                  <a href="https://twitter.com/niftysouq/" target="_blank">
                    <Twitter
                      width="18"
                      height="18"
                      color={theme == "light" ? "#fff" : "#000"}
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/niftysouq/"
                    target="_blank"
                  >
                    <Linkedin
                      width="18"
                      height="18"
                      color={theme == "light" ? "#fff" : "#000"}
                    />
                  </a>
                  <a href="https://discord.gg/niftysouq" target="_blank">
                    <Discord
                      width="18"
                      height="18"
                      color={theme == "light" ? "#fff" : "#000"}
                    />
                  </a>
                  <a href="https://t.me/NiftySouq" target="_blank">
                    <Telegram
                      width="18"
                      height="18"
                      color={theme == "light" ? "#fff" : "#000"}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* --------- Copyright --------- */}
        <div className="copyright">
          <Row>
            <Col xs="12" md="6" className="footer-left">
              {t("Copyright © 2022 Nifty Souq All Rights Reserved")}
            </Col>
            <Col xs="12" md="6" className="">
              <ul className="list-unstyled d-flex justify-content-center justify-content-md-end">
                <li className="me-2">
                  <Link to="/terms-and-conditions">{t("terms_footer")}</Link>
                </li>
                <span className="me-2">|</span>
                <li>
                  <Link to="/privacy">{t("Privacy policy")}</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>

      {/* --------- Copyright --------- */}
      {/* <div className="copyright">
        <Row>
          <Col xs="12" md="6" className="footer-left">
            {t("Copyright © 2022 Nifty Souq All Rights Reserved")}
          </Col>
          <Col xs="12" md="6" className="">
            <ul className="list-unstyled d-flex justify-content-center justify-content-md-end">
              <li className="me-2">
                <Link to="/terms-and-conditions">
                  {t("terms_footer")}
                </Link>
              </li>
              <span className="me-2">|</span>
              <li>
                <Link to="/privacy">{t("Privacy policy")}</Link>
              </li>
            </ul>
          </Col>
        </Row>
      </div> */}
    </FooterWrapper>
  );
}

export default Footer;
