import React, { useEffect } from "react";
import { NFT } from "../pages/NFT";
import { useParams, useLocation } from "react-router-dom";
import bezelLogo from "@assets/images/bezel-club/logo.png";
import { saveCookie, removeFromCookies } from "../core/cookies";
//store
import { bindActionCreators } from "redux";
import { partnerActionCreators } from "../store";
import { useSelector, useDispatch } from "react-redux";

export const PartnerNFTDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { state } = useLocation();
  const { contract, nft_id } = useParams();
  const { loading, nft, error } = useSelector(
    (state) => state.partner_nft_details
  );

  const { getNFTDetailsPartner } = bindActionCreators(
    partnerActionCreators,
    dispatch
  );
  const getData = () => {
    getNFTDetailsPartner(contract, nft_id);
  };
  useEffect(() => {
    const pagePath = window.location.href.replace(window.location.origin, "");
    const pageObj = pagePath.split("/");
    if (pageObj[1] === "nft" || pageObj[1] === "club") {
      removeFromCookies("return_page");
      saveCookie("return_page", pagePath);
    }
    getData();
  }, []);
  useEffect(() => {
    if (state?.refresh) getData();
  }, [state?.refresh]);

  const partner = {
    club: "TheBezelClub",
    logo: bezelLogo,
    name: "The Bezel Club",
    redirect: "/the-bezel-club",
    contract,
  };
  return (
    <NFT
      error={error}
      loading={loading}
      externalNFT={partner}
      nft={{ ...nft, type: "video", redirect: partner?.redirect }}
      theme={partner?.club == "TheBezelClub" ? "dark" : "light"}
      refresh={getData}
    />
  );
};
