import * as profileTypes from "../types/profile";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getUserProfile = (id) => {
  return (dispatch) => {
    dispatch({
      type: profileTypes.GET_USER_PROFILE_LOADING,
      loading: true,
    });

    API.get(`api/user/profile/${id}`, {})
      .then((res) => {
        dispatch({
          type: profileTypes.GET_USER_PROFILE_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: profileTypes.GET_USER_PROFILE_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const updateProfileImages = (type, body) => {
  return (dispatch) => {
    dispatch({
      type: profileTypes.UPDATE_PROFILE_IMAGES_LOADING,
      loading: true,
    });

    API.put(`api/user/profile/${type}/update`, body)
      .then((res) => {
        dispatch({
          type: profileTypes.UPDATE_PROFILE_IMAGES_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: profileTypes.UPDATE_PROFILE_IMAGES_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const updateProfileCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: profileTypes.UPDATE_PROFILE_LOADING,
      loading: true,
    });

    API.put(`api/user/profile/update`, body)
      .then((res) => {
        dispatch({
          type: profileTypes.UPDATE_PROFILE_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: profileTypes.UPDATE_PROFILE_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
