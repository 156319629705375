import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useWeb3Auth } from "../context/Web3auth";

const WalletDropDownList = () => {
  const { t } = useTranslation();

  const { another_wallet } = useSelector((state) => state.wallets);
  const { getAccounts } = useWeb3Auth();
  const [default_wallet, setDefault_wallet] = useState();
  const getWallets = async () => {
    const wallet = await getAccounts();
    setDefault_wallet(wallet?.address);
  };
  useEffect(() => {
    getWallets();
  }, []);
  return (
    <>
      <option value="">{t("create-nft.select_wallet")}</option>
      {default_wallet && (
        <option value={default_wallet}>{t("nifty_wallet")}</option>
      )}

      {another_wallet && (
        <option value={another_wallet}>{t("another_wallet")}</option>
      )}
    </>
  );
};

export default WalletDropDownList;
