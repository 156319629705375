import { Row, Col } from "react-bootstrap";
import NFT from "../NFT.js";

const NFTs = (props) => {
  if (props.noActions) {
    return (
      <Row>
        {props.nfts?.map((item, index) => {
          return (
            <Col key={index} xs={12} sm={6} md={4} lg={3}>
              <NFT {...item} />
            </Col>
          );
        })}
      </Row>
    );
  } else {
    return (
      <Row style={{ padding: "0 10px" }}>
        {props?.nfts?.map((item, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3}>
            <NFT {...item} />
          </Col>
        ))}
      </Row>
    );
  }
};

export default NFTs;
