/* eslint-disable react-hooks/exhaustive-deps */
// import NFTs from "../components/Profile/NFTs";
import { Container, Row, Col } from "react-bootstrap";
// import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { nftActionsCreators } from "../store";
// import InfiniteScroll from "react-infinite-scroll-component";
import { Button, Spinner } from "reactstrap";
import { NftCard } from "../components/NftCard";
import { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";

export const Discover = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { getAllNftsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );
  const { loading, nfts, has_more } = useSelector(
    (state) => state.discover_nfts
  );

  // useEffect(() => getAllNftsCreator(), []);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);
  return (
    <Container
      style={{
        width: "100%",
        overflow: "none",
        padding: "0px 0px 20px 0px",
      }}
    >
      <div className="mt-5 mb-4 page-header  d-md-block justify-content-center d-flex">
        {t("Discover")}
      </div>
      <Button
        onClick={scrollToTop}
        style={{
          display: visible ? "inline" : "none",
        }}
        className="me-4 mb-2 arrow-buttom "
      >
        <FaArrowCircleUp />
      </Button>
      <Row>
        {nfts.map((item) => {
          let props = { item };
          if (item.artist) {
            props.userData = {
              _id: item.artist?._id,
              name: item.artist?.name,
              img: item.artist?.profileImage,
            };
            props.creator = item.creators?.minter;
          }
          return (
            <Col xs={12} md={6} lg={3} style={{ margin: "10px 0" }}>
              <NftCard {...props} />
            </Col>
          );
        })}
      </Row>
      {has_more && nfts?.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            style={{
              borderRadius: 25,
              border: "none",
              margin: "0 auto",
              padding: "10px 20px",
              background: `linear-gradient(to right, #6D3190, #BC60F1)`,
            }}
            onClick={() => {
              getAllNftsCreator({ skip: nfts?.length }, true);
            }}
          >
            {t("Load More")}{" "}
            {loading && <Spinner size="sm" style={{ color: "#fff" }} />}{" "}
          </Button>
        </div>
      )}
      {loading && nfts?.length === 0 && <p>{t("Loading...")}</p>}
    </Container>
  );
};
