import { useWeb3Auth } from "../../context/Web3auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useJwt from "@hooks/jwt/useJwt";
import { Container, Form, Button } from "react-bootstrap";

//import { useTranslation } from 'react-i18next';

export const Agreement = () => {
  const { jwt } = useJwt({});
  const { load, logout, getAccounts, userPrincipal } = useWeb3Auth();
  //const classes = useStyles();
  //const { t } = useTranslation();
  const navigate = useNavigate();
  const [agreement, setAgreement] = useState(false);
  const { t } = useTranslation();

  const confirm = async () => {
    if (agreement) {
      const data = await load();
      const walletData = await getAccounts();
      if (data) {
        try {
          const loginData = {
            principal: userPrincipal,
            name: data.name,
            email: data.email,
            profileImage: data.profileImage,
            typeLogin: data.typeOfLogin,
            walletAddress: [{ address: walletData.address, status: true }],
            status: 1,
          };
          await jwt.login({ data: loginData }).then((res) => {
            if (res.data.data.status) {
              navigate("/profile");
            }
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const cancel = async () => {
    navigate("/login");
    await logout();
  };

  return (
    <Container className="mt-3">
      <h1>{t("agree_on_terms_title")}</h1>
      <Form>
        <Form.Check
          type="checkbox"
          id="terms_agree"
          onChange={() => {
            agreement ? setAgreement(false) : setAgreement(true);
          }}
          label={t("agree_terms")}
        ></Form.Check>
      </Form>
      <br />
      <Button
        variant="secondary-outline"
        type="submit"
        onClick={() => cancel()}
        className="m-4 rounded-btn"
      >
        {t("Cancel Signup")}
      </Button>
      <Button
        variant="primary"
        type="submit"
        disabled={!agreement}
        onClick={() => confirm()}
        className="rounded-btn"
      >
        {t("Confirm")}
      </Button>
    </Container>
  );
};
