import * as errorTypes from "../actions/types/error";

const error = {
  error: null,
};

export const errorReducer = (state = error, action) => {
  switch (action.type) {
    case errorTypes.ERROR_HAPPENED:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
