import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import {
  artistActionsCreators,
  galleryActionsCreators,
} from "../../../../store";
import createGalleryImage from "../../../../assets/images/create-gallery.jpg";
import arrowLeft from "../../../../assets/icons/arrow-left.svg";
import CardList from "../components/CardList";
import {
  getGalleryArtistDropDownConfig,
  GALLERY_ARTIST_STATUS,
  ACTION_STATUS,
} from "../../../../common/constants/gallery";
import ArtistNav from "./artistNav";
import "./styles.css";
import NoDataFound from "../components/NoDataFound";
import sadFaceIcon from "../../../../assets/icons/sad-face-icon.svg";
import CreateAndEditArtist from "../components/Modal/CreateAndEditArtist";
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import image from "../../../../assets/images/no-artists.png";

const GalleryArtist = () => {
  const { galleryId, isOpen } = useParams();
  let { entities: gallery } = useSelector(
    (state) => state.gallery.getGalleryById
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDeleted, setIsDeleted] = useState(false);
  const [status, setStatus] = useState(GALLERY_ARTIST_STATUS.createdArtist);
  const [isCreateOpen, setIsCreateOpen] = useState(
    isOpen === "true" ? true : false
  );
  const [selectedId, setSelectedId] = useState(false);
  const { data, count, loading } = useSelector((state) => state.artist.artists);

  const { status: created } = useSelector(
    (state) => state.artist.createGalleryArtist
  );
  const { status: deleted } = useSelector(
    (state) => state.artist.deleteGalleryArtist
  );
  const { status: updated } = useSelector(
    (state) => state.artist.updateGalleryArtist
  );
  const { status: assigned } = useSelector(
    (state) => state.artist.assignGalleryArtist
  );
  const {
    getGalleryArtistCreator,
    resetDeleteGalleryArtistCreator,
    resetCreateGalleryArtistCreator,
    resetUpdateGalleryArtistCreator,
    resetGalleryArtistByIdCreator,
    deleteGalleryArtistCreator,
  } = bindActionCreators(artistActionsCreators, dispatch);
  const { getGalleryByIdCreator, resetGalleryByIdCreator } = bindActionCreators(
    galleryActionsCreators,
    dispatch
  );
  useEffect(() => {
    if (galleryId) {
      getGalleryByIdCreator(galleryId);
    }
    return () => {
      resetGalleryByIdCreator();
    };
  }, [galleryId]);
  useEffect(() => {
    if (created === ACTION_STATUS.COMPLETE) {
      setIsCreateOpen(false);
      Swal.fire({
        icon: "success",
        text: t("artist_created"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetCreateGalleryArtistCreator);
        },
        confirmButtonColor: "#6d3190",
      });
    }
    if (deleted === ACTION_STATUS.COMPLETE) {
      Swal.fire({
        icon: "success",
        text: t("artist_deleted"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetDeleteGalleryArtistCreator);
        },
        confirmButtonColor: "#6d3190",
      });
    }
    if (updated === ACTION_STATUS.COMPLETE) {
      setIsCreateOpen(false);
      Swal.fire({
        icon: "success",
        text: t("artist_updated"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetUpdateGalleryArtistCreator);
          dispatch(resetGalleryArtistByIdCreator);
        },
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
    setSelectedId(null);
    getGalleryArtistCreator({
      galleryId,
      limit: 1000,
      skip: 0,
    });
  }, [created, deleted, updated, assigned]);

  useEffect(() => {
    getGalleryArtistCreator({
      galleryId,
      limit: 1000,
      skip: 0,
    });
  }, []);

  const handleStatusChange = (e, sectionName) => {
    e.preventDefault();
    setStatus(sectionName);
  };
  const renderNoArtistFoundWithStatus = () => {
    return (
      <div className="ga-no-data-found">
        <Row>
          <Col className="d-flex justify-content-center">
            <img src={sadFaceIcon} alt="sadFace" />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <p className="sub-text">{t("no_artist_found")}</p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderBackBtn = () => {
    return (
      <Row>
        <div className="ga-back">
          <button
            className="button-icon-grey-40 me-2"
            onClick={() => navigate(-1)}
          >
            <img src={arrowLeft} alt="leftArrowIcon" />
          </button>
          <p className="ga-title">
            {t("Gallery")}
            <span className="ga-divider">/</span>
            <span className="ga-sub-text">{t("add_and_edit_Artist")}</span>
          </p>
        </div>
      </Row>
    );
  };

  const handleSelect = (eventKey, data) => {
    if (eventKey === "viewArtist") {
      navigate(`/created-artist/${data._id}`);
    }
    if (eventKey === "addEditArtist") {
      setSelectedId(data._id);
      setIsCreateOpen(true);
    }
    if (eventKey === "deleteArtist") {
      setSelectedId(data._id);
      setIsDeleted(true);
    }
  };
  return (
    <div>
      <div className="cover-photo">
        <img
          src={gallery?.coverImage || createGalleryImage}
          className="gallery-artist-bg"
          alt=""
        />
      </div>
      <Container
        style={{
          maxWidth: "1120px",
        }}
      >
        {renderBackBtn()}
        {loading ? (
          <p style={{ padding: 50 }}>{t("Loading...")}</p>
        ) : (
          <React.Fragment>
            {count > 0 ? (
              <React.Fragment>
                <Row>
                  <h1 className="cg-title">{gallery?.title || t("gallery")}</h1>
                </Row>
                <Row>
                  <ArtistNav
                    status={status}
                    handleStatusChange={handleStatusChange}
                    onCreateArtistClicked={() => setIsCreateOpen(true)}
                  />
                </Row>
                <Row>
                  {data?.length > 0 ? (
                    <CardList
                      style={{ marginBottom: 150 }}
                      dataList={data}
                      dropdownConfig={getGalleryArtistDropDownConfig(t)}
                      type="artist"
                      onSelect={handleSelect}
                    />
                  ) : (
                    renderNoArtistFoundWithStatus()
                  )}
                </Row>
              </React.Fragment>
            ) : (
              <NoDataFound
                image={image}
                text={t("no_gallery_artist_found")}
                onCreateButtonClick={() => setIsCreateOpen(true)}
                createBtnText={t("create_artist")}
              />
            )}
          </React.Fragment>
        )}
        {/* <div className="mb-436px" /> */}
        {isCreateOpen && (
          <CreateAndEditArtist
            artistId={selectedId}
            isOpen={isCreateOpen}
            setIsOpen={() => {
              setIsCreateOpen(false);
              setSelectedId(null);
            }}
          />
        )}
        {isDeleted && (
          <ConfirmationModal
            title={t("artist_deletion")}
            isOpen={isDeleted}
            text={t("artist_deletion_msg")}
            submitBtnText={t("confirm")}
            isBtnIconEnable={true}
            onConfirmClick={() => {
              deleteGalleryArtistCreator(galleryId, selectedId);
              setIsDeleted(false);
            }}
            onHideClick={() => {
              setIsDeleted(false);
              setSelectedId(null);
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default GalleryArtist;
