import { Row } from "react-bootstrap";
import styled from "styled-components";

export const DropDownList = styled("div")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  box-sizing: border-box;
  color: #23262f;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
`;
export const ListItem = styled("li")`
  color: ${(props) => props.colors?.label};
  cursor: pointer;
  padding: 8px 0 0;
  &:hover {
    color: #6d3190;
  }
`;
