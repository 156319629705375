import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import { Spinner } from "reactstrap";
import {
  getCreateAndEditGalleryValidationSchema,
  getCreateAndEditGalleryInitialValues,
} from "../utilities";
import { galleryActionsCreators } from "../../../../store";
import { ACTION_STATUS, social } from "../../../../common/constants/gallery";
import createGalleryImage from "@assets/images/create-gallery.jpg";
import UploadImage from "../../../uploadImage/UploadImage";
import "./styles.css";

const CreateAndEditGallery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { galleryId } = useParams();
  const [isError, setError] = useState(false);
  let gallery = useSelector((state) => state.gallery.getGalleryById.entities);
  const { status: galleryCreatedStatus } = useSelector(
    (state) => state.gallery.createGallery
  );
  const { status: galleryUpdatedStatus } = useSelector(
    (state) => state.gallery.updateGallery
  );
  const {
    createGalleryCreator,
    updateGalleryCreator,
    getGalleryByIdCreator,
    resetCreateGalleryCreator,
    resetUpdateGalleryCreator,
    resetGalleryByIdCreator,
  } = bindActionCreators(galleryActionsCreators, dispatch);

  const handleGallerySubmit = (values) => {
    console.log("v", values);
    if (galleryId) {
      updateGalleryCreator(galleryId, values);
    } else {
      createGalleryCreator(values);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: getCreateAndEditGalleryInitialValues(),
    validationSchema: getCreateAndEditGalleryValidationSchema(t),
    onSubmit: handleGallerySubmit,
  });

  useEffect(() => {
    if (galleryId) {
      getGalleryByIdCreator(galleryId);
    }
    return () => {
      resetGalleryByIdCreator();
    };
  }, []);
  const urlWithProtocol = (data) => {
    if (data) {
      if (data.includes("https://")) {
        return data;
      } else if (data.includes("http://")) {
        return data;
      } else {
        return "https://" + data;
      }
    } else {
      return "";
    }
  };
  useEffect(() => {
    if (values && !values.title && gallery) {
      setFieldValue("title", gallery.title);
      setFieldValue("description", gallery.description);
      setFieldValue("profileImage", gallery.profileImage);
      setFieldValue("coverImage", gallery.coverImage);
      setFieldValue(
        "instagram_url",
        urlWithProtocol(gallery?.media?.instagram_url)
      );
      setFieldValue(
        "twitter_url",
        urlWithProtocol(gallery?.media?.twitter_url)
      );
      setFieldValue(
        "website_url",
        urlWithProtocol(gallery?.media?.website_url)
      );
      setFieldValue(
        "facebook_url",
        urlWithProtocol(gallery?.media?.facebook_url)
      );
      setFieldValue(
        "youtube_url",
        urlWithProtocol(gallery?.media?.youtube_url)
      );
    }
  }, [gallery]);

  useEffect(() => {
    if (galleryCreatedStatus === ACTION_STATUS.COMPLETE) {
      Swal.fire({
        icon: "success",
        text: t("gallery_created"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetCreateGalleryCreator);
          navigate("/gallery");
        },
        confirmButtonColor: "#6d3190",
      });
    }
    if (galleryUpdatedStatus === ACTION_STATUS.COMPLETE) {
      Swal.fire({
        icon: "success",
        text: t("gallery_updated"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetUpdateGalleryCreator);
          navigate("/gallery");
        },
        confirmButtonColor: "#6d3190",
      });
    }
  }, [galleryCreatedStatus, galleryUpdatedStatus]);

  const isButtonDisabled = () => {
    return (
      galleryCreatedStatus === ACTION_STATUS.LOADING ||
      galleryUpdatedStatus === ACTION_STATUS.LOADING
    );
  };

  return (
    <div>
      <div className="cover-photo">
        <img src={createGalleryImage} className="gallery-artist-bg" alt="" />
      </div>
      <Container
        className="create-page mt-0 mb-0"
        style={{
          maxWidth: "1120px",
        }}
      >
        <Row>
          <h1 className="ceg-title">{t("Gallery")}</h1>
        </Row>
        <Row>
          <h4 className="ceg-sub-title">
            {galleryId ? t("edit_gallery") : t("create_gallery")}
          </h4>
        </Row>
        <Row>
          <Col md="7">
            <Form
              noValidate
              onSubmit={handleSubmit}
              style={
                isButtonDisabled()
                  ? {
                      pointerEvents: "none",
                      cursor: "not-allowed",
                      opacity: "0.65",
                    }
                  : {}
              }
            >
              <div className="item-details pb-0">
                <Form.Group controlId="item-name" className="mb-4">
                  <Form.Label className="create-section-label">
                    {t("title")}
                    <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="create-page-input-field"
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.title}
                    errors={errors.title}
                    value={values.title}
                    name="title"
                    placeholder={t("title_placeholder")}
                  />
                  {errors.title && (touched.title || isError) && (
                    <Form.Text className="text-danger">
                      {errors.title}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group controlId="item-description" className="mb-4">
                  <Form.Label className="create-section-label">
                    {t("description")}
                    <span className="required">*</span>
                  </Form.Label>
                  <Form.Control
                    className="create-page-input-field textarea-h-100"
                    as="textarea"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.description}
                    errors={errors.description}
                    value={values.description}
                    name="description"
                    placeholder={t("description_placeholder")}
                  />
                  {errors.description && (touched.description || isError) && (
                    <Form.Text className="text-danger">
                      {errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
                <Row>
                  <Col xs={12} md={8}>
                    <h5 className="mt-5 modal-section-title">
                      {t("upload_profile_image")}
                      <span className="required">*</span>
                    </h5>
                    <p className="mt-2 modal-section-text">
                      {t("upload_logo_p1")}
                    </p>
                    {errors.profileImage &&
                      (touched.profileImage || isError) && (
                        <Form.Text className="text-danger">
                          {errors.profileImage}
                        </Form.Text>
                      )}
                  </Col>
                  <Col xs={12} md={4} className="mt-5">
                    <UploadImage
                      fieldName="profileImage"
                      onFileSelected={(file) =>
                        setFieldValue("profileImage", file)
                      }
                      onFileRemove={() =>
                        setFieldValue("profileImage", undefined)
                      }
                      onlyPreviewImage={values.profileImage}
                      closeIcon={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <h5 className="mt-5 modal-section-title">
                      {t("upload_banner_image")}
                      <span className="required">*</span>
                    </h5>
                    <p className="mt-2 modal-section-text">
                      {t("upload_banner_p1")}
                    </p>
                    {errors.coverImage && (touched.coverImage || isError) && (
                      <Form.Text className="text-danger">
                        {errors.coverImage}
                      </Form.Text>
                    )}
                  </Col>

                  <Col xs={12} md={4} className="mt-5">
                    <UploadImage
                      fieldName="coverImage"
                      onFileSelected={(file) =>
                        setFieldValue("coverImage", file)
                      }
                      onFileRemove={() =>
                        setFieldValue("coverImage", undefined)
                      }
                      onlyPreviewImage={values.coverImage}
                      closeIcon={true}
                    />
                  </Col>
                </Row>
                <div className="mt-4">
                  {social.map(({ label, value, placeholder, Icon }) => (
                    <Form.Group className="mb-3" controlId="item-name">
                      <div className="position-relative">
                        <div className="social-icon-wrapper">
                          <div className="social-icon">
                            <Icon width="14" height="14" color="#fff" />
                          </div>
                          <div className="mx-2 social-divider" />
                        </div>
                        <Form.Control
                          type="text"
                          className="create-page-input-field"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          touched={touched[value]}
                          errors={errors[value]}
                          value={values[value]}
                          name={value}
                          placeholder={t(placeholder)}
                          style={{ paddingInlineStart: 60 }}
                        />
                      </div>
                      {errors[value] && (touched[value] || isError) && (
                        <Form.Text className="text-danger">
                          {errors[value]}
                        </Form.Text>
                      )}
                    </Form.Group>
                  ))}
                </div>
                <button
                  style={{ width: "163px", height: "48px" }}
                  className="mx-0 mx-md-2 my-2 my-md-5 btn btn-primary"
                  type="submit"
                  onClick={() => setError(true)}
                  disabled={isButtonDisabled()}
                >
                  {galleryId ? t("update") : t("submit")}
                  {isButtonDisabled() && (
                    <Spinner
                      size="sm"
                      style={{ color: "#fff", marginLeft: "5px" }}
                    />
                  )}
                </button>
                <button
                  style={{ width: "163px", height: "48px" }}
                  className="mx-0 mx-md-5 my-2 my-md-0 btn btn-secondary-outline radius-50 mb-4"
                  onClick={(event) => {
                    event.preventDefault();
                    navigate(-1);
                  }}
                  disabled={isButtonDisabled()}
                >
                  {t("cancel")}
                </button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateAndEditGallery;
