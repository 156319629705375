import React, { useState, useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StylesWrapper from "./styles";

const NFTDetails = ({ nft, nftStatus, colors, name }) => {
  const { t } = useTranslation();
  const [usdPrice, setUsdPrice] = useState(0);

  const { usd } = useSelector((state) => state.eth);
  const is1155 = nft?.token_standard === "1155";
  const price = nftStatus == 0 ? nft?.listing?.sold_price : nft?.listing?.price;
  useEffect(() => {
    if (nft?.blockchain === "ETH" && usd?.eth)
      setUsdPrice(
        nft?.listing?.price ? nft?.listing?.price * parseFloat(usd.eth) : 0
      );
    if (nft?.blockchain === "MATIC" && usd?.matic)
      setUsdPrice(
        nft?.listing?.price ? nft?.listing?.price * parseFloat(usd.matic) : 0
      );
    if (nft?.listing?.currency === "USDC")
      setUsdPrice(nft?.listing?.price ? nft?.listing?.price : 0);
  }, [nft, usd]);
  return (
    <StylesWrapper colors={colors}>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
        <div>
          <div className="name mb-2">{name ? name : nft?.name} </div>
          <div className="d-flex flex-column flex-md-row align-items-start">
            <div className="editions">
              {is1155
                ? nft?.editions?.total
                  ? nft?.editions?.total + " " + t("editions")
                  : t("Not available")
                : `1 ${t("edition")}`}
            </div>
            {nftStatus == 0 && (
              <div
                style={{ marginInlineStart: 5 }}
                className="d-flex flex-row align-items-center"
              >
                <div className="slash" />
                <div className="note">{t("Sold")}</div>
              </div>
            )}
            {nftStatus == 1 && nft?.token_standard !== "1155" && (
              <div
                style={{ marginInlineStart: 5 }}
                className="d-flex flex-row align-items-center"
              >
                <div className="slash" />
                <div className="note">{t("Not for Sale")}</div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex flex-column align-items-start mt-3">
          {nftStatus == 3 && <div className="label">{t("Reserve Price")}</div>}
          {nftStatus != 1 && (
            <div>
              <div className="price">
                {price || 0}
                <span className="chain">{nft?.listing?.currency}</span>
              </div>
              <div className="price-usd">${usdPrice.toFixed(3)}</div>
            </div>
          )}
        </div>
      </div>
    </StylesWrapper>
  );
};
export default NFTDetails;
