import * as bidTypes from "../types/bid";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const placeBidCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: bidTypes.PLACE_BID_LOADING,
      loading: true,
    });

    API.post("api/nft/bid/place/", body)
      .then((res) => {
        dispatch({
          type: bidTypes.PLACE_BID_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: bidTypes.PLACE_BID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const editBidCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: bidTypes.EDIT_BID_LOADING,
      loading: true,
    });

    API.post("api/nft/bid/edit/", body)
      .then((res) => {
        dispatch({
          type: bidTypes.EDIT_BID_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: bidTypes.EDIT_BID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const cancelBidCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: bidTypes.CANCEL_BID_LOADING,
      loading: true,
    });

    API.post("api/nft/bid/cancel/", body)
      .then((res) => {
        dispatch({
          type: bidTypes.CANCEL_BID_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: bidTypes.CANCEL_BID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const acceptBidCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: bidTypes.ACCEPT_NFT_BID_LOADING,
      loading: true,
    });

    API.post("api/nft/bid/accept/", body)
      .then((res) => {
        dispatch({
          type: bidTypes.ACCEPT_NFT_BID_SUCCESS,
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: bidTypes.ACCEPT_NFT_BID_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getHotBidsCreator = () => {
  return (dispatch) => {
    dispatch({
      type: bidTypes.GET_HOT_BIDS_LOADING,
      loading: true,
    });
    API.get("api/nft/hot-bids")
      .then((res) => {
        dispatch({
          type: bidTypes.GET_HOT_BIDS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: bidTypes.GET_HOT_BIDS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
