import { BiTransfer } from "react-icons/bi";
import { FaBurn } from "react-icons/fa";
import { GiSevenPointedStar } from "react-icons/gi";
import { AiFillCloseCircle } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { RiFileTransferFill, RiTimerFill } from "react-icons/ri";

export const nftDetailsOptions = [
  {
    text: "Change Collection",
    icon: <BiTransfer />,
    key: "change_collection",
  },
  {
    text: "Put for Sale",
    icon: <GiSevenPointedStar />,
    key: "put_sale",
  },
  {
    text: "Burn NFT",
    icon: <FaBurn />,
    key: "burn",
  },
];
export const partnerDetailsOptions = [
  {
    text: "Put for Sale",
    icon: <GiSevenPointedStar />,
    key: "put-sale",
  },
];
export const nftLazyMinted = [
  {
    text: "Edit NFT",
    icon: <MdModeEditOutline />,
    key: "edit-nft",
  },
  {
    text: "Delete NFT",
    icon: <FaBurn />,
    key: "burn",
  },
];
export const nft1155Minted = [
  {
    text: "Change Collection",
    icon: <BiTransfer />,
    key: "change-collection",
  },
];
export const nft721ListedMintedFixedPrice = [
  {
    text: "Edit Sale",
    icon: <MdModeEditOutline />,
    key: "edit-sale",
  },
  {
    text: "Change Collection",
    icon: <BiTransfer />,
    key: "change-collection",
  },
  {
    text: "Cancel Sale",
    icon: <AiFillCloseCircle />,
    key: "cancel-sale",
  },
];
export const nft721ListedMintedAuction = [
  {
    text: "Change Collection",
    icon: <BiTransfer />,
    key: "change-collection",
  },
];
export const nft721UnListedMinted = [
  {
    text: "transfer_nft",
    icon: <RiFileTransferFill />,
    key: "transfer",
  },
  {
    text: "Change Collection",
    icon: <BiTransfer />,
    key: "change-collection",
  },
  {
    text: "Put for Sale",
    icon: <GiSevenPointedStar />,
    key: "put-sale",
  },
  {
    text: "Burn Edition",
    icon: <FaBurn />,
    key: "burn",
  },
];

export const nft1155ListedMinted = [
  {
    text: "Edit Sale",
    icon: <MdModeEditOutline />,
    key: "edit-sale",
  },
  {
    text: "Cancel Sale",
    icon: <AiFillCloseCircle />,
    key: "cancel-sale",
  },
];
export const nft1155LAZY_MINT = [
  {
    text: "Cancel Sale",
    icon: <AiFillCloseCircle />,
    key: "cancel-sale",
  },
];
export const nft1155UnListedMinted = [
  {
    text: "Put for Sale",
    icon: <GiSevenPointedStar />,
    key: "put-sale",
  },
  {
    text: "Burn Edition",
    icon: <FaBurn />,
    key: "burn",
  },

  {
    text: "transfer_nft",
    icon: <RiFileTransferFill />,
    key: "transfer",
  },
];
