export function truncate(str, length = 20) {
  return str?.length > length ? str.substring(0, length - 5) + "...." : str;
}

export const walletCut = (address) => {
  return (
    address?.substring(0, address?.length - 28) +
    "..." +
    address?.substring(address?.length - 4)
  );
};
export const walletSmallCut = (address) => {
  return (
    address?.substring(0, address?.length - 41) +
    "..." +
    address?.substring(address?.length - 18)
  );
};

export const walletCutPhoneView = (address) => {
  return (
    address?.substring(0, address?.length - 48) +
    "..." +
    address?.substring(address?.length - 5)
  );
};
export const walletCutPhoneViewExternalWallet = (address) => {
  return (
    address?.substring(0, address?.length - 30) +
    "..." +
    address?.substring(address?.length - 5)
  );
};
export const token_id_cut = (token_id) => {
  if (token_id?.length > 40) {
    return (
      token_id?.substring(0, token_id?.length - 50) +
      "..." +
      token_id?.substring(token_id?.length - 5)
    );
  } else {
    return token_id;
  }
};
export const offers_cut = (token_id) => {
  if (token_id?.length > 40) {
    return (
      token_id?.substring(0, token_id?.length - 20) +
      "..." +
      token_id?.substring(token_id?.length - 7)
    );
  } else {
    return token_id;
  }
};
