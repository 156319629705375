import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { token_id_cut, walletCut } from "../../../../core/string";
import StylesWrapper from "./styles";
import CopyWallet from "../../../../components/CopyWallet";
import { useWeb3React } from "@web3-react/core";
import { useState } from "react";
import { useSelector } from "react-redux";

/**
 * @param nft.project_details: to check if the data for project nft
 */

const NFTProperties = ({ nft, colors, externalNFT, filteredLinkedNFTs }) => {
  const { t, i18n } = useTranslation();
  const { account } = useSelector((state) => state.auth);

  return (
    <StylesWrapper colors={colors}>
      {filteredLinkedNFTs ? (
        <div className="d-flex flex-row align-items-center">
          <Link to={`/profile/${account?._id}`}>
            <img
              alt=""
              className="profile-img"
              src={account?.profile.image_url || "/user-placeholder.png"}
            />
          </Link>
          <div className="mx-2">
            <div style={{ color: "#777E91", fontSize: 20 }}>
              {account?.name}
            </div>

            {filteredLinkedNFTs && (
              <CopyWallet
                fullWallet={false}
                externalNFT={externalNFT}
                id={"30"}
                nft={filteredLinkedNFTs}
                address={
                  filteredLinkedNFTs?.owner_of
                    ? filteredLinkedNFTs?.owner_of
                    : filteredLinkedNFTs?.ownerOf
                }
              />
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row align-items-center">
          <Link
            to={
              nft?.collection_details?.artist
                ? `/created-artist/${nft.collection_details?.artist?._id}`
                : nft?.project_details
                ? `/partners/${nft?.project_details?.contract}`
                : externalNFT
                ? nft?.redirect
                : `/profile/${nft?.creators?.minter?._id}`
            }
          >
            <img
              alt=""
              className="profile-img"
              src={
                nft?.collection_details?.artist
                  ? nft.collection_details?.artist?.profileImage ||
                    "/user-placeholder.png"
                  : nft?.project_details
                  ? nft?.project_details?.image_url
                  : externalNFT
                  ? nft?.creator?.profile?.image_url || "/user-placeholder.png"
                  : nft?.creators?.minter?.profile?.image_url ||
                    "/user-placeholder.png"
              }
            />
          </Link>
          <div className="mx-2">
            <div style={{ color: "#777E91", fontSize: 20 }}>
              {nft?.collection_details?.artist
                ? nft.collection_details?.artist?.name
                : nft?.project_details
                ? nft?.project_details?.name
                : externalNFT
                ? nft?.creator?.name
                : nft?.creators?.minter?.name}
            </div>

            {nft?.collection_details?.artist ? (
              <div className="mb-1">
                <span style={{ color: "#777E91" }}>{t("By")} </span>
                <span>
                  {nft?.project_details
                    ? nft?.project_details?.name
                    : externalNFT
                    ? nft?.creator?.name
                    : nft?.creators?.minter?.name}
                </span>
              </div>
            ) : (
              <CopyWallet
                fullWallet={false}
                externalNFT={externalNFT}
                id={"3"}
                nft={nft}
                address={
                  nft?.project_details?.name
                    ? nft?.project_details?.contract
                    : externalNFT
                    ? nft?.creator?.create_wallet
                    : nft?.creators?.minter?.create_wallet
                }
              />
            )}
          </div>
        </div>
      )}
      <hr className="divider" />
      <div className="details">
        {/* <Row>
                  <Col>{t('Category')}</Col>
                  <Col className="content">Tiger</Col>
                </Row>
                <Row>
                  <Col>{t('Subcategory')}</Col>
                  <Col className="content">WonderfulDay</Col>
                </Row> */}
        {/* <Row>
                  <Col>{t('Contract Id')}</Col>
                  <Col className="content">W912hd-3434-343-igkf</Col>
                </Row> */}
        <Row>
          <Col className="label">{t("Token Standard")}</Col>
          <Col className="value">
            {filteredLinkedNFTs
              ? filteredLinkedNFTs?.contract_type
                ? filteredLinkedNFTs?.contract_type
                : filteredLinkedNFTs?.contractType
              : `ERC-${nft?.token_standard}`}
          </Col>
        </Row>

        {nft?.token_id ? (
          <Row>
            <Col className="label">{t("token_id")}</Col>
            <Col className="value">{token_id_cut(nft?.token_id)}</Col>
          </Row>
        ) : (
          filteredLinkedNFTs && (
            <Row>
              <Col className="label">{t("token_id")}</Col>
              <Col className="value">
                {token_id_cut(
                  filteredLinkedNFTs?.token_id
                    ? filteredLinkedNFTs?.token_id
                    : filteredLinkedNFTs?.tokenId
                )}
              </Col>
            </Row>
          )
        )}
        {!filteredLinkedNFTs && (
          <Row>
            <Col className="label">{t("Blockchain")}</Col>
            <Col className="value">{t(nft?.blockchain)}</Col>
          </Row>
        )}

        <Row>
          <Col className="label">{t("create-nft.total_royalties")}</Col>
          <Col className="value">
            {nft?.royalties?.total_royalties
              ? nft?.royalties?.total_royalties / 100
              : 0}
            %
          </Col>
        </Row>
      </div>
    </StylesWrapper>
  );
};

export default NFTProperties;
