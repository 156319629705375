import { Modal, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import metaMask from "../../assets/icons/meta-mask.svg";
import coinBase from "../../assets/icons/coinbase.svg";
import walletConnect from "../../assets/icons/wallet-connect.svg";

const Wrapper = styled.div`
  padding: 16px;
  .modal-header {
    border: none;
  }
  .modal-title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  p {
    font-weight: 18px;
    color: #777e90;
    margin-top: -32px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 16px;
  }
`;

const ConnectWalletModal = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal {...props} size="lg" centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Connect your wallet")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("Connect_001")}</p>
          <Row className="mt-4">
            <Col xs={12} lg={4} className="mb-3">
              <div className="large-btn-outline">
                <img src={metaMask} width="60" height="60" alt="" />
                Meta Mask
              </div>
            </Col>
            <Col xs={12} lg={4} className="mb-3">
              <div className="large-btn-outline">
                <img src={coinBase} width="60" height="60" alt="" />
                Coinbase
              </div>
            </Col>
            <Col xs={12} lg={4} className="mb-3">
              <div className="large-btn-outline">
                <img src={walletConnect} width="60" height="60" alt="" />
                Wallet Connect
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
};

export default ConnectWalletModal;
