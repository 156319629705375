import * as appTypes from "../actions/types/app";

const app = {
  chain: "polygon",
};

export const appReducer = (state = app, action) => {
  switch (action.type) {
    case appTypes.CHANGE_CHAIN:
      return { ...state, chain: action.chain };
    default:
      return state;
  }
};
