import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import keys from "../lib/config";

// const { chain } = useWeb3Auth();

const POLLING_INTERVAL = 12000;
// export const RPC_URL = "https://rpc-mumbai.maticvigil.com";
export const RPC_URL = window.INFURA_RPC_URL;
// "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161";

export const injected = new InjectedConnector({
  supportedChainIds: [window.CHAIN_ID_MATIC, window.CHAIN_ID_ETH, window.CHAIN_ID_BOBA, window.CHAIN_ID_BNB],
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URL },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});

export const CoinbaseWallet = new WalletLinkConnector({
  url: RPC_URL,
  supportedChainIds: [window.CHAIN_ID_MATIC, window.CHAIN_ID_ETH],
});

export const bsc = new BscConnector({
  supportedChainIds: [56, 97],
});
