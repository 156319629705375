import { Card } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import artistIcon from "../../../../assets/icons/artist-icon.svg";

const Wrapper = styled.div`
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: #23262f;
    z-index: 10;
  }
  .purchasing .overlay {
    display: flex;
  }
  .time-remaining {
    color: #fcfcfd;
    background-color: #ee2a7b;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    img {
      margin-top: -4px;
    }
  }
  .purchasing-container {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    span {
      background-color: #45b26b;
      padding: 6px 8px;
      border-radius: 4px;
    }
    div {
      background-color: #fcfcfd;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  .nft-card {
    height: 250px;
    max-height: 250px;
    overflow: hidden;
  }
`;

const GalleryCard = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Card
        className="collection nft-item card"
        style={{
          width: "350px",
          padding: "0px",
          marginBottom: "20px",
          marginRight: "0px !important",
        }}
      >
        <Link to={`/galleries/${item._id}/artists`}>
          <div className="position-relative nft-card d-flex">
            <Card.Img
              variant="top"
              src={item.coverImage}
              className="nft-image"
              style={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            className="d-flex pt-3 p-3"
            style={{ paddingBottom: "0px !important" }}
          >
            <Card.Title
              className="flex-fill nft-name"
              style={{ width: "70%", fontWeight: 350 }}
            >
              {item.title}
            </Card.Title>
            <Card.Body className="mt-0 p-0" style={{ lineHeight: "73px" }}>
              <div className="flex-fill">
                <Link
                  to={`/galleries/${item._id}/artists`}
                  className=" nft-price"
                  style={{
                    display: "initial",
                    float: "right",
                  }}
                >
                  <img
                    src={artistIcon}
                    width="16px"
                    height="18px"
                    style={{ marginInlineEnd: "5px" }}
                    alt="artistIcon"
                  />
                  {item.artistCount}{" "}
                  {item?._id === "63fee9aeede894003eee3879"
                    ? t("Editions")
                    : item.artistCount <= 1
                    ? t("artist")
                    : t("artists_count")}
                </Link>
              </div>
            </Card.Body>
          </div>
        </Link>
      </Card>
    </Wrapper>
  );
};

export default GalleryCard;
