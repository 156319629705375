// ** Auth Endpoints
import keys from '../../lib/config'
export default {
    //user api
    loginEndpoint: `${window.API_URL}/api/user/login`,
    getEndpoint: `${window.API_URL}/api/user/get`,
    checkEndpoint: `${window.API_URL}/api/user/check`,
    saveWalletEndpoint: `${window.API_URL}/api/user/saveWallet`,
    //card payment
    getEncryptKeyEndpoint: `${window.API_URL}/api/cards/getEncryptkey`,
    addCardEndpoint: `${window.API_URL}/api/cards/addCard`,
    makePaymentEndpoint: `${window.API_URL}/api/cards/makePayment`,
    getCardsEndpoint: `${window.API_URL}/api/cards/getCards`,
    //token
    createTokenEndpoint: `${window.API_URL}/api/token/create`,
    getAllTokenEndpoint: `${window.API_URL}/api/token/get-all`,
    getItemEndpoint: `${window.API_URL}/api/token/get`,
    getLazyItemsEndpoint: `${window.API_URL}/api/token/get-lazy`,
    updateItemEndpoint: `${window.API_URL}/api/token/update`,
    //history
    saveHistoryEndpoint: `${window.API_URL}/api/history/save`,
    getHistoryEndpoint: `${window.API_URL}/api/history/get`,
    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: 'Bearer',

    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
}
