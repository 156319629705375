import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Spinner } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { galleryActionsCreators } from "../../../../store";
import arrowRight from "../../../../assets/icons/arrow-right-white.svg";
import artistIcon from "../../../../assets/icons/artist-white-icon.svg";
import NoDataFound from "../components/NoDataFound";
import GalleryCard from "../components/GalleryCard";
import image from "../../../../assets/images/no-galleries.png";
import "./styles.scss";

const Galleries = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, loading, count } = useSelector(
    (state) => state.gallery.publicGalleries
  );
  const { entities } = useSelector((state) => state.gallery.featuredGalleries);
  const {
    getPublicGalleriesCreator,
    resetPublicGalleriesCreator,
    getFeaturedGalleriesCreator,
  } = bindActionCreators(galleryActionsCreators, dispatch);

  useEffect(() => {
    getPublicGalleriesCreator({ limit: 6, skip: 0, status: "approved" });
    getFeaturedGalleriesCreator(1, 0);
    console.log(data);
    return () => {
      resetPublicGalleriesCreator();
    };
  }, []);

  return (
    <div>
      <Container
        className="container galleries mr-0"
        style={{
          maxWidth: "1180px",
        }}
      >
        <div className="title">{t("galleries")}</div>
        {entities && entities.details && (
          <React.Fragment>
            <p className="sub-title-1">{t("featured")}</p>
            <div className="featured-gallery">
              <img
                src={entities.details.coverImage}
                className=""
                width={"100%"}
                height={"365px"}
                alt=""
                style={{ objectFit: "cover" }}
              />
              <div className="bottom-right">
                {/* <p>{t("three_d_modal_gallery")}</p> */}
                <p>
                  <img
                    src={artistIcon}
                    width="16px"
                    height="18px"
                    style={{ marginInlineEnd: "5px" }}
                    alt="artistIcon"
                  />
                  {entities.details.artists?.length}{" "}
                  {entities.details.artists?.length <= 1
                    ? t("artist")
                    : t("artists_count")}
                </p>
                <button type="button" className="btn btn-primary">
                  <span
                    className="create-gallery-button"
                    onClick={() =>
                      navigate(`/galleries/${entities.details._id}/artists`)
                    }
                  >
                    {t("view_gallery")}
                  </span>
                  <img src={arrowRight} alt="arrowIcon" />
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="sub-title-2">{t("explore_galleries")}</div>
        <Row>
          {data.map((el) => (
            <Col xs={12} md={4} lg={4} style={{ margin: "10px 0" }}>
              <GalleryCard item={el} />
            </Col>
          ))}
        </Row>
        {data?.length < count && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{
                borderRadius: 25,
                border: "none",
                margin: "0 auto",
                padding: "10px 20px",
                background: `linear-gradient(to right, #6D3190, #BC60F1)`,
              }}
              onClick={() => {
                getPublicGalleriesCreator(
                  { skip: data?.length, limit: 6, status: "approved" },
                  true
                );
              }}
            >
              {t("Load More")}
              {loading && (
                <Spinner
                  size="sm"
                  style={{ color: "#fff", marginLeft: "5px" }}
                />
              )}
            </Button>
          </div>
        )}
        {/* {!has_more && (
          <p
            style={{
              textAlign: "center",
            }}
          >
            {t("all_seen")}
          </p>
        )} */}
        {!loading && data?.length == 0 && (
          <NoDataFound text={t("no_galleries_found_text")} image={image} />
        )}
      </Container>
    </div>
  );
};

export default Galleries;
