import { Table } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//icons
import { GiCancel } from "react-icons/gi";
import { CgPlayListRemove } from "react-icons/cg";
import { BiTransfer, BiListUl } from "react-icons/bi";
import { FaBurn, FaShoppingBag } from "react-icons/fa";
import { FcAcceptDatabase } from "react-icons/fc";
import { RiZzzFill, RiExternalLinkLine } from "react-icons/ri";
import { MdPublishedWithChanges, MdReceipt } from "react-icons/md";
import { IoMdSwap } from "react-icons/io";
import { txScanURL } from "../core/nft";
import NoDataImage from "@assets/images/noDataNftDetails.png";
import { timeAgo } from "../core/dates";
import styled from "styled-components";

const StylesWrapper = styled.div`
  .history-nodata {
    font-size: 15px;
    color: ${(props) => props?.colors?.label};
  }
  .history-label {
    font-size: 16px;
    color: ${(props) => props?.colors?.text};
  }
  .history-action {
    font-size: 16px;
    margin-inline-start: 4px;
    color: ${(props) => props?.colors?.text};
  }
  .history-text {
    font-size: 14px;
    font-weight: 325;
    color: ${(props) => props?.colors?.text};
  }
  .history-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  .history-name {
    font-size: 16px;
    font-weight: 325;
    margin-inline-start: 10px;
    color: ${(props) => props?.colors?.text};
  }
  .history-date {
    font-size: 14px;
    color: ${(props) => props?.colors?.text};
  }
  .history-icon {
    font-size: 20px;
    margin-bottom: 5px;
    margin-inline-start: 15px;
    color: ${(props) => props?.colors?.text};
  }
  .chain {
    margin-left: 5px;
  }
`;
const NFTHistory = ({ colors }) => {
  const { t, i18n } = useTranslation();
  const { history } = useSelector((state) => state.nft_history);
  const iconStyle = { fontSize: 14, color: colors?.text };

  const historyEvents = {
    TRANSFER: {
      text: "Transfer",
      icon: <BiTransfer {...iconStyle} />,
    },
    LAZY_MINT: {
      text: "Lazy Mint",
      icon: <RiZzzFill {...iconStyle} />,
    },
    MINT: {
      text: "Mint",
      icon: <MdReceipt {...iconStyle} />,
    },
    LISTED: {
      text: "List",
      icon: <BiListUl {...iconStyle} />,
    },
    UNLISTED: {
      text: "Delist",
      icon: <CgPlayListRemove {...iconStyle} />,
    },
    CANCEL: {
      text: "Cancel",
      icon: <GiCancel {...iconStyle} />,
    },
    BURN: {
      text: "Burn",
      icon: <FaBurn {...iconStyle} />,
    },
    PURCHASE: {
      text: "Purchase",
      icon: <IoMdSwap {...iconStyle} />,
    },
    CHANGE_PRICE: {
      text: "Change price",
      icon: <MdPublishedWithChanges {...iconStyle} />,
    },
    ACCEPT_OFFER: {
      text: "Accept offer",
      icon: <FcAcceptDatabase {...iconStyle} />,
    },
    ACCEPT_BID: {
      text: "Accept bid",
      icon: <FcAcceptDatabase {...iconStyle} />,
    },
  };

  return (
    <StylesWrapper colors={colors}>
      {history?.length == 0 ? (
        <div style={{ textAlign: "center" }}>
          <img src={NoDataImage} alt="no data" height="155" />
          <p className="history-nodata">{t("no_history")}</p>
        </div>
      ) : (
        <Table responsive borderless>
          <thead>
            <tr>
              <td className="history-label">{t("Event")}</td>
              <td className="history-label">{t("Price")}</td>
              <td className="history-label">{t("From")}</td>
              <td className="history-label">{t("To")}</td>
              <td className="history-label">{t("Date")}</td>
            </tr>
          </thead>
          <tbody>
            {history.map((el, index) => (
              <tr key={index}>
                <th valign="middle">
                  <span>{historyEvents[el.action].icon}</span>{" "}
                  <span className="history-action">
                    {" "}
                    {t(historyEvents[el.action].text)}
                  </span>
                </th>
                <td valign="middle" className="history-text">
                  {el?.price || 0}
                  <span className="history-text chain">
                    {el?.payment_method}
                  </span>
                </td>
                <td valign="middle">
                  {el?.tx_from ? (
                    <div>
                      <img
                        className="history-image"
                        alt={el?.tx_from?.name}
                        src={
                          el?.tx_from?.profile?.image_url ||
                          "/user-placeholder.png"
                        }
                      />
                      <span className="history-name">{el?.tx_from?.name}</span>
                    </div>
                  ) : (
                    <div className="history-text ">{t("None")}</div>
                  )}
                </td>
                <td valign="middle">
                  {el?.tx_to ? (
                    <div>
                      <img
                        className="history-image"
                        alt={el?.tx_to?.name}
                        src={
                          el?.tx_to?.profile?.image_url ||
                          "/user-placeholder.png"
                        }
                      />
                      <span className="history-name">{el?.tx_to?.name}</span>
                    </div>
                  ) : (
                    <div className="history-text">{t("None")}</div>
                  )}
                </td>
                <td valign="middle">
                  <div>
                    <span className="history-date">
                      {timeAgo(el?.createdAt, t, i18n.language)}
                    </span>{" "}
                    {el?.action !== "LAZY_MINT" && (
                      <span>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={txScanURL(el?.tx, el?.payment_method)}
                        >
                          <RiExternalLinkLine className="history-icon" />
                        </a>
                      </span>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </StylesWrapper>
  );
};

export default NFTHistory;
