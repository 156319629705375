import * as socketTypes from "../actions/types/socket";
import * as historyTypes from "../actions/types/history";
import * as nftTypes from "../actions/types/nft";

const nftHistory = {
  loading: false,
  history: [],
  error: null,
};

export const nftHistoryReducer = (state = nftHistory, action) => {
  switch (action.type) {
    case historyTypes.GET_NFT_HISTORIES_LOADING:
      return { ...state, loading: true };
    case historyTypes.GET_NFT_HISTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        history: [...state.history, ...action.payload],
        error: null,
      };
    case historyTypes.GET_NFT_HISTORIES_ERROR:
      return { ...state, loading: false, error: action.error };
    //* get nft details
    case nftTypes.GET_NFT_DETAILS_SUCCESS:
      return { ...state, history: action?.payload?.history };
    //* socket
    case socketTypes.PURCHASE_NFT_SOCKET:
      return {
        ...state,
        history: state.history.some(
          (el) => el._id === action?.payload?.new_history._id
        )
          ? state.history
          : [action?.payload?.new_history, ...state.history],
      };
    case socketTypes.CANCEL_NFT_SOCKET:
      return {
        ...state,
        history: state.history.some(
          (el) => el._id === action?.payload?.new_history._id
        )
          ? state.history
          : [action?.payload?.new_history, ...state.history],
      };
    default:
      return state;
  }
};
