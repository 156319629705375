import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { truncate } from "../core/string";
import { Link, useNavigate } from "react-router-dom";

import styled from "styled-components";

import arrowLeft from "../assets/icons/arrow-left.svg";
import arrowRight from "../assets/icons/arrow-right.svg";
//store
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../components/DropDown";
import { Card } from "./common/Card";
import { useEffect, useRef, useState } from "react";
import { getTrendingCollectionsCreator } from "../store/actions/creators/creatorCollection";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { CollectionCard } from "../pages/Partners/Popular/Partials";
import ScrollBar from "./ScrollBar";

const Wrapper = styled.div`
  .main {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 65px;
    }
  }
  .image {
    object-fit: cover;
  }
  .name {
    font-size: 21px;
    color: #23262f;
    font-style: normal;
  }
  .chip {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 325;
    color: gray;
    border: #d3d3d3 solid 1.5px;
    border-radius: 5px;
  }
  .title {
    font-size: 24px;
    font-weight: 350;
  }
  .subtitle {
    color: #29367a;
    font-size: 24px;
    font-weight: 450;
  }
  .divider {
    height: 0.66px;
    width: 100%;
    background-color: #c6c6c6;
  }
  .member-since {
    font-size: 14px;
    color: #23262f;
    margin-inline-start: 5px;
    font-weight: 350;
  }
`;

const TrendingCollections = () => {
  const {
    trendingCollectionsLoading: loading,
    error,
    trendingCollections,
  } = useSelector((state) => state.creatorCollections);
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language != "ar";
  const carousel = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [steps, setSteps] = useState(0);

  const options = [
    { label: t("Last 24 hours"), id: "0", value: "today" },
    { label: t("Last 7 days"), id: "1", value: "week" },
    { label: t("Last 30 days"), id: "2", value: "month" },
    { label: t("Last 3 months"), id: "3", value: "quarter" },
  ];

  useEffect(() => {
    dispatch(getTrendingCollectionsCreator("quarter"));
  }, []);

  const type = "trending";
  const data = trendingCollections;
  function itemText(numb) {
    if (numb < 2) {
      return t("nft_item", {
        count: numb,
      }).toUpperCase();
    } else if (numb === 2) {
      return t("nft_item_for_two", {
        count: numb,
      }).toUpperCase();
    } else {
      return t("nft_items", {
        count: numb,
      }).toUpperCase();
    }
  }
  return (
    <>
      {data?.length > 0 && (
        <section id="trending">
          <Wrapper>
            <Container fluid="lg" className="main">
              {/* if loading */}

              {/* if error */}
              {/* {error && <p>{error}</p>} */}
              {/* if the featured item exists */}

              {loading ? (
                <p>{t("Loading...")}</p>
              ) : (
                <div>
                  <div className="mt-5">
                    <ScrollBar
                      data={data}
                      link=""
                      title={isLTR ? t(type) : t("Collections")}
                      subtitle={isLTR ? t("Collections") : t(type)}
                      noDataMessage="no_trending_collection"
                      // scrollStyle="pe-4"
                      renderFilter={() => (
                        <div style={{ width: 200 }} className="mt-2">
                          <DropDown
                            label={t("Filter by")}
                            options={options}
                            onOptionSelected={(value) =>
                              dispatch(getTrendingCollectionsCreator(value))
                            }
                          />
                        </div>
                      )}
                      renderCard={(item) => {
                        return (
                          <div
                            key={Math.random()}
                            style={{ maxWidth: 300, minWidth: 300 }}
                            className="pb-4 cursor mx-2 card"
                          >
                            <Link
                              to={`/collection/nft/${item._id}`}
                              className="d-block position-absolute"
                              style={{ width: "100%", height: "100%" }}
                            />
                            <div className="d-flex">
                              <img
                                alt=""
                                src={item?.coverImage}
                                className="card-img-top"
                                style={{ height: 205, objectFit: "cover" }}
                                onClick={() =>
                                  navigate(`/collection/nft/${item._id}`)
                                }
                              />
                            </div>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                bottom: 23,
                                position: "relative",
                              }}
                            >
                              <img
                                src={item?.logo || "/user-placeholder.png"}
                                className="user-40-rounded image"
                                style={{
                                  width: 80,
                                  height: 80,
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                            </div>
                            <div
                              className="d-flex px-2 flex-column"
                              style={
                                !isLTR
                                  ? { direction: "rtl", textAlign: "start" }
                                  : { direction: "ltr" }
                              }
                            >
                              <div
                                className="name"
                                onClick={() => {
                                  navigate(`/collection/nft/${item._id}`);
                                }}
                              >
                                {truncate(item?.title)}
                              </div>
                              <div className="d-flex mt-3 justify-content-between">
                                <div>
                                  <Link
                                    className="member-since position-relative "
                                    to={`/profile/${item?.user?._id}`}
                                    style={{
                                      zIndex: "5",
                                      textDecoration: "none",
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.user?.profile?.image_url ||
                                        "/user-placeholder.png"
                                      }
                                      className="user-30-rounded image"
                                      style={{ marginInlineEnd: 10 }}
                                      alt=""
                                    />
                                    <span
                                      className=" pe-2 ps-2"
                                      style={{ fontSize: "initial" }}
                                    >
                                      {t("by")}
                                    </span>
                                    <span
                                      className="a_tag_collection"
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {truncate(item?.user?.name, 20) || ""}
                                    </span>
                                  </Link>
                                </div>
                                <div className="chip ">
                                  <span className="px-1">
                                    {itemText(item.nftCounts)}
                                  </span>
                                </div>
                              </div>
                              {/* <Favorite width="16" id={item?._id} /> */}
                            </div>
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
              )}
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

export default TrendingCollections;
