//* get partners
export const GET_PARTNERS_LOADING = "GET_PARTNERs_LOADING";
export const GET_PARTNERS_SUCCESS = "GET_PARTNERs_SUCCESS";
export const GET_PARTNERS_ERROR = "GET_PARTNERs_ERROR";

//* get partner nfts
export const GET_PARTNER_NFTS_LOADING = "GET_PARTNER_NFTS_LOADING";
export const GET_PARTNER_NFTS_SUCCESS = "GET_PARTNER_NFTS_SUCCESS";
export const GET_PARTNER_NFTS_ERROR = "GET_PARTNER_NFTS_ERROR";

//* get partner nft details
export const GET_PARTNER_NFT_DETAILS_LOADING =
  "GET_PARTNER_NFT_DETAILS_LOADING";
export const GET_PARTNER_NFT_DETAILS_SUCCESS =
  "GET_PARTNER_NFT_DETAILS_SUCCESS";
export const GET_PARTNER_NFT_DETAILS_ERROR = "GET_PARTNER_NFT_DETAILS_ERROR";

//* purchase partner nft
export const PURCHASE_PARTNER_NFT_LOADING = "PURCHASE_PARTNER_NFT_LOADING";
export const PURCHASE_PARTNER_NFT_SUCCESS = "PURCHASE_PARTNER_NFT_SUCCESS";
export const PURCHASE_PARTNER_NFT_ERROR = "PURCHASE_PARTNER_NFT_ERROR";

//* burn partner nft
export const BURN_PARTNER_NFT_LOADING = "BURN_PARTNER_NFT_LOADING";
export const BURN_PARTNER_NFT_SUCCESS = "BURN_PARTNER_NFT_SUCCESS";
export const BURN_PARTNER_NFT_ERROR = "BURN_PARTNER_NFT_ERROR";

//* cancel partner nft
export const CANCEL_PARTNER_NFT_LOADING = "CANCEL_PARTNER_NFT_LOADING";
export const CANCEL_PARTNER_NFT_SUCCESS = "CANCEL_PARTNER_NFT_SUCCESS";
export const CANCEL_PARTNER_NFT_ERROR = "CANCEL_PARTNER_NFT_ERROR";

//* list partner nft
export const LIST_PARTNER_NFT_LOADING = "LIST_PARTNER_NFT_LOADING";
export const LIST_PARTNER_NFT_SUCCESS = "LIST_PARTNER_NFT_SUCCESS";
export const LIST_PARTNER_NFT_ERROR = "LIST_PARTNER_NFT_ERROR";

//* chnage partner nft price
export const CHANGE_PARTNER_NFT_PRICE_LOADING =
  "CHANGE_PARTNER_NFT_PRICE_LOADING";
export const CHANGE_PARTNER_NFT_PRICE_SUCCESS =
  "CHANGE_PARTNER_NFT_PRICE_SUCCESS";
export const CHANGE_PARTNER_NFT_PRICE_ERROR = "CHANGE_PARTNER_NFT_PRICE_ERROR";
