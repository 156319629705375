import * as creatorCollectionTypes from "../types/creatorCollection";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const resetCollectionFlags = () => ({
  type: creatorCollectionTypes.RESET_COLLECTION_FLAG,
});

export const resetCollectionState = () => ({
  type: creatorCollectionTypes.RESET_INITIAL_STATE,
});

export const createCollectionCreator = (requestData) => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.CREATE_COLLECTION,
      loading: true,
    });

    const formData = new FormData();
    formData.append("title", requestData.title);
    formData.append("categoryId", requestData.categoryId);
    formData.append("subCategoryId", requestData.subcategoryId);
    formData.append("logo", requestData.logo);
    formData.append("coverImage", requestData.coverImage);
    formData.append("creatorId", requestData.creatorId);
    formData.append("artistId", requestData.artistId);

    API.postMultiPart("api/creatorcollection/create", formData)
      .then((res) => {
        console.log("CreateCollectionSuccess", res.data);
        dispatch({
          type: creatorCollectionTypes.CREATE_COLLECTION_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        console.log("CreateCollectionFAilure", error);
        dispatch({
          type: creatorCollectionTypes.CREATE_COLLECTION_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const fetchUserCollectionCreator = (request, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.FETCH_USER_COLLECTIONS,
      loading: true,
    });
    API.postWithParams("api/creatorcollection/find", request, queryParams)
      .then((res) => {
        dispatch({
          type: queryParams
            ? creatorCollectionTypes.FETCH_USER_COLLECTIONS_PAGINATED_SUCCESS
            : creatorCollectionTypes.FETCH_USER_COLLECTIONS_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          hasMore: res.data.data?.length === 6,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: creatorCollectionTypes.FETCH_USER_COLLECTIONS_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const fetchCollectionDetailCreator = (collectionId) => {
  // console.log("Request", request, queryParams);
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.FETCH_COLLECTION_DETAIL,
      loading: true,
    });

    API.get(`api/creatorcollection/find/${collectionId}`)
      .then((res) => {
        console.log("Response: ", res.data.data);
        dispatch({
          type: creatorCollectionTypes.FETCH_COLLECTION_DETAIL_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);
        dispatch({
          type: creatorCollectionTypes.FETCH_COLLECTION_DETAIL_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const fetchCollectionActivitiesCreator = (collectionId) => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.FETCH_COLLECTION_ACTIVITIES,
      loading: true,
    });

    API.get(`api/creatorcollection/activities/${collectionId}`)
      .then((res) => {
        console.log("Response: ", res.data.data);
        dispatch({
          type: creatorCollectionTypes.FETCH_COLLECTION_ACTIVITIES_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: creatorCollectionTypes.FETCH_COLLECTION_ACTIVITIES_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

/**
 *
 * @param  timeline: today/week/month
 * @returns
 */
export const getTrendingCollectionsCreator = (timeline = "month") => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.FETCH_TRENDING_COLLECTIONS,
      loading: true,
    });

    API.get(`api/creatorcollection/trending?timeline=${timeline}`)
      .then((res) => {
        dispatch({
          type: creatorCollectionTypes.FETCH_TRENDING_COLLECTIONS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: creatorCollectionTypes.FETCH_TRENDING_COLLECTIONS_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const fetchCollectionNFTsCreator = (
  collectionId,
  requestObj,
  pagination
) => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.FETCH_COLLECTION_NFTS,
      loading: true,
    });

    API.post(
      `api/creatorcollection/nft/${collectionId}?skip=${pagination.skip}&limit=${pagination.limit}`,
      requestObj
    )
      .then((res) => {
        dispatch({
          type: creatorCollectionTypes.FETCH_COLLECTION_NFTS_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          hasMore: res.data.data?.length === pagination.limit,
          skip: pagination.skip,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: creatorCollectionTypes.FETCH_COLLECTION_NFTS_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getLatestCollectionsCreator = () => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.FETCH_LATEST_COLLECTIONS,
      loading: true,
    });

    API.get(`api/creatorcollection/latest`)
      .then((res) => {
        dispatch({
          type: creatorCollectionTypes.FETCH_LATEST_COLLECTIONS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: creatorCollectionTypes.FETCH_LATEST_COLLECTIONS_FAILURE,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const editCollectionCreator = (id, body) => {
  console.log({ id, body });
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.EDIT_COLLECTION_LOADING,
      loading: true,
    });

    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("categoryId", body.categoryId);
    formData.append("subCategoryId", body.subcategoryId);
    formData.append("logo", body.logo);
    formData.append("coverImage", body.coverImage);
    formData.append("creatorId", body.creatorId);
    formData.append("artistId", body.artistId);

    API.postMultiPart(`api/creatorcollection/edit/${id}`, formData)
      .then((res) => {
        console.log("CreateCollectionSuccess", res.data);
        dispatch({
          type: creatorCollectionTypes.EDIT_COLLECTION_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        console.log("CreateCollectionFAilure", error);
        dispatch({
          type: creatorCollectionTypes.EDIT_COLLECTION_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const deleteCollectionCreator = (id) => {
  return (dispatch) => {
    dispatch({
      type: creatorCollectionTypes.DELETE_COLLECTION_LOADING,
      loading: true,
    });

    API.remove(`api/creatorcollection/delete/${id}`)
      .then((res) => {
        dispatch({
          type: creatorCollectionTypes.DELETE_COLLECTION_SUCCESS,
          loading: false,
          id,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: creatorCollectionTypes.DELETE_COLLECTION_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
