//* get user profile
export const GET_USER_PROFILE_LOADING = 'GET_USER_PROFILE_LOADING';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_ERROR = 'GET_USER_PROFILE_ERROR';

export const UPDATE_PROFILE_IMAGES_LOADING = 'UPDATE_PROFILE_IMAGES_LOADING';
export const UPDATE_PROFILE_IMAGES_SUCCESS = 'UPDATE_PROFILE_IMAGES_SUCCESS';
export const UPDATE_PROFILE_IMAGES_ERROR = 'UPDATE_PROFILE_IMAGES_ERROR';


export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';