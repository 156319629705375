import bezelLogo from "@assets/images/bezel-club/logo.png";

// export const partners = {
//   "0xEAE5655aCF5fAbF2F14589cB32aE6AcBf66b53c6": {
//     club: "TheBezelClub",
//     logo: bezelLogo,
//     name: "The Bezel Club",
//     redirect: "/the-bezel-club",
//     contract: "0xEAE5655aCF5fAbF2F14589cB32aE6AcBf66b53c6",
//   },
// };

export const themeColors = (theme) => ({
  bg: theme == "dark" ? "#000" : "#fff",
  bg1: theme == "dark" ? "#23262f" : "#fff",
  bg2: theme == "dark" ? "#000" : "transparent",
  text: theme == "dark" ? "#fff" : "#000",
  text1: theme == "dark" ? "#fff" : "#23262f",
  text2: theme == "dark" ? "#fff" : "#777E90",
  divider: theme == "dark" ? "#fff" : "#e6e8ec",
  label: theme == "dark" ? "#fff" : "#777e91",
  value: theme == "dark" ? "#fff" : "#040404",
  timer: theme == "dark" ? "#0C0C0C" : "#F4F5F7",
  tab: theme == "dark" ? "#AAAAAA" : "#777E91",
  social: theme == "dark" ? "#fff" : "#1C0039",
  hover: theme == "dark" ? "#363945" : "#f4f5f6",
  icon: theme == "dark" ? "#fff" : "#414141",
  button:
    theme == "dark" ? "modal-outline-button-dark" : "modal-outline-button",
});
