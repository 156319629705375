import { Row } from "react-bootstrap";
import styled from "styled-components";

export const StylesWrapper = styled(Row)`
  display: flex;
  align-items: center;
  margin: 25px 0px 35px 0px;
  .edit-cancel-sale-wrapper {
    width: 45%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .edit-sale {
    border: none;
    width: 100%;
    font-size: 16px;
    font-weight: 350;
    color: #fcfcfd;
    padding: 12px 24px;
    border-radius: 30px;
    background-color: #6d3190;
  }
  .cancel-sale {
    margin-top: 10px;
    border: 2px solid #e6e8ec;
    width: 100%;
    font-size: 16px;
    font-weight: 350;
    color: #23262f;
    padding: 12px 24px;
    border-radius: 30px;
    background-color: #ffffff;
  }
  .buy-now-wrapper {
    margin-top: 20px;
  }
  .buy-now {
    border: none;
    width: 45%;
    font-size: 16px;
    font-weight: 350;
    color: #fcfcfd;
    padding: 12px 24px;
    border-radius: 30px;
    background-color: #6d3190;
  }
  .label {
    color: #23262f;
    font-size: 18px;
  }
  .value {
    color: #23262f;
    font-size: 25px;
  }
  .ends-in {
    font-size: 20px;
  }
  .ended {
    font-size: 20;
    font-weight: 400;
  }

  .blank {
    width: 15px;
  }
  .buttons {
    flex: 1;
    margin: 20px 5px;
  }
  .place-bid {
    border: none;
    width: 100%;
    font-size: 16px;
    color: #fcfcfd;
    padding: 12px 24px;
    border-radius: 30px;
    background: #6d3190;
  }
  .edit-bid {
    border: 2px solid #e6e8ec;
    width: 100%;
    font-size: 16px;
    color: #23262f;
    padding: 12px 24px;
    border-radius: 30px;
    background: #ffffff;
  }
`;

export default StylesWrapper;
