import React from "react";

const TimerBox = ({ time, text, colors }) => {
  return (
    <div
      style={{
        width: 65,
        height: 65,
        backgroundColor: colors?.timer,
        border: "1px solid #EEEEEE",
        borderRadius: 7,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: 6,
        padding: 10,
        fontSize: 18,
        fontWeight: 700,
      }}
    >
      <span style={{ color: "#6d3190" }}>{time}</span>
      <span style={{ color: colors?.label, fontSize: 16 }}>{text}</span>
    </div>
  );
};

export default TimerBox;
