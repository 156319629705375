import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const HomeTopSlider = () => {
  const { t } = useTranslation();
  const {
    account,
    authenticated,
    verify_loading: loading,
  } = useSelector((state) => state.auth);
  return (
    <section id="home">
      <Container className="main-section mt-1">
        <Row style={{ paddingBottom: "200px" }}>
          <Col className="text-center">
            <div className="slogan mt-2">
              {t("NIFTY SOUQ IS MENA'S FIRST AND LARGEST NFT MARKETPLACE")}
            </div>
            <div className="home-title pb-4 pt-1 mb-1">
              {t("Create, explore, and collect")}
              <br />
              {t("the rarest NFTs")}
            </div>

            <div className="d-flex flex-row align-items-center justify-content-center">
              <Link to="/discover" className="mx-2 btn btn-primary gradient">
                {t("Explore")}
              </Link>
              {loading ? (
                <div className="btn btn-primary gradient">
                  <Spinner size="sm" animation="border" color="#fff" />
                </div>
              ) : authenticated ? (
                // Upload
                <Link to="/nft/create">
                  <div className="mx-2 btn btn-primary gradient">
                    {t("Create")}
                  </div>
                </Link>
              ) : (
                // Register
                <Link to="/login" className="mx-2 btn btn-primary gradient">
                  {t("login_register")}
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeTopSlider;
