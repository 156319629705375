import { Card } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const Wrapper = styled.div`
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: #23262f;
    z-index: 10;
  }
  .purchasing .overlay {
    display: flex;
  }
  .time-remaining {
    color: #fcfcfd;
    background-color: #ee2a7b;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    img {
      margin-top: -4px;
    }
  }
  .purchasing-container {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    span {
      background-color: #45b26b;
      padding: 6px 8px;
      border-radius: 4px;
    }
    div {
      background-color: #fcfcfd;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  .nft-card {
    height: 250px;
    max-height: 250px;
    overflow: hidden;
  }
`;

const ArtistCollectionNFT = ({ item }) => {
  const { t } = useTranslation();
  const { artistId } = useParams();

  return (
    <Wrapper>
      <Card
        className="collection nft-item card"
        style={{
          width: "350px",
          padding: "0px",
          marginBottom: "70px",
          marginRight: "0px !important",
        }}
      >
        <Link to={`/collection/nft/${item._id}`}>
          <div className="position-relative nft-card d-flex">
            <Card.Img
              variant="top"
              src={item.coverImage}
              className="nft-image"
              style={{
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                objectFit: 'cover'
              }}
            />
          </div>
          <div
            className="d-flex pt-3 p-3"
            style={{ paddingBottom: "0px !important" }}
          >
            <div
              className="flex-fill nft-name"
              style={{ width: "70%", fontSize: 22 }}
            >
              {item.title}
            </div>
            <Card.Body className="mt-0 p-0" style={{ lineHeight: "73px" }}>
              <div className="flex-fill">
                <Link
                  to={`/collection/nft/${item._id}`}
                  className="nft-price"
                  style={{
                    display: "initial",
                    float: "right",
                    fontWeight: 250,
                  }}
                >
                  {t("nfts", {
                    count: item.nftCounts,
                  })}
                </Link>
              </div>
            </Card.Body>
          </div>
        </Link>
      </Card>
    </Wrapper>
  );
};

export default ArtistCollectionNFT;
