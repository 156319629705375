import React from 'react'


const header_style = {
  
}
export const BezelClub = () => {
  return (
    <div>
      <header style={{
        height: 500,

        background: '#000 url("/images/bezel.png") no-repeat left',
        backgroundSize: 950,
        backgroundAttachment: 'fixed'
      }}>
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '10rem'
        }}>
          <div 
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            width: 'fit-content',
            alignItems: 'flex-start',
          }}>
          <h1 style={{padding: 0, margin: 0, fontSize: '7rem', textTransform: 'uppercase', color: "#fff"}}>The</h1>
          <h1 style={{padding: 0, margin: '0px 60px', fontSize: '7rem', textTransform: 'uppercase', color: "#bc995e"}}>Bezel</h1>
          <h1 style={{padding: 0, margin: 0, fontSize: '7rem', textTransform: 'uppercase', color: "#fff"}}>Club</h1>
          </div>
        </div>
      </header>
    </div>
  )
}
