import * as offerTypes from "../actions/types/offer";
import * as projectTypes from "../actions/types/project";

const nftOffers = {
  loading: false,
  offers: [],
  error: null,
};

const offerActions = {
  loading: false,
  success: false,
  action: null,
  error: null,
};

export const offersReducer = (state = nftOffers, action) => {
  switch (action.type) {
    case offerTypes.GET_OFFERS_LOADING:
      return { ...state, loading: true };
    case offerTypes.GET_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.paginated
          ? [...state.offers, ...action.payload]
          : action.payload,
        has_more: action.has_more,
        error: null,
      };
    case offerTypes.GET_OFFERS_ERROR:
      return { ...state, error: action.error };
    //* get nft details

    default:
      return state;
  }
};

export const offerActionsReducer = (state = offerActions, action) => {
  switch (action.type) {
    case offerTypes.MAKE_OFFER_LOADING:
      return { ...state, loading: true };
    case offerTypes.MAKE_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "make-offer",
        error: null,
      };
    case offerTypes.MAKE_OFFER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "make-offer",
        error: action.error,
      };
    case offerTypes.EDIT_OFFER_LOADING:
      return { ...state, loading: true };
    case offerTypes.EDIT_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "edit-offer",
        error: null,
      };
    case offerTypes.EDIT_OFFER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "edit-offer",
        error: action.error,
      };
    case offerTypes.CANCEL_OFFER_LOADING:
      return { ...state, loading: true };
    case offerTypes.CANCEL_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "cancel-offer",
        error: null,
      };
    case offerTypes.CANCEL_OFFER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "cancel-offer",
        error: action.error,
      };
    case offerTypes.ACCEPT_OFFER_LOADING:
      return { ...state, loading: true };
    case offerTypes.ACCEPT_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "accept-offer",
        error: null,
      };
    case offerTypes.ACCEPT_OFFER_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "accept-offer",
        error: action.error,
      };
    default:
      return state;
  }
};
