import deleteIcon from "../../assets/icons/delete-icon.svg";
import addEditAritst from "../../assets/icons/add-edit-artist.svg";
import changeIcon from "../../assets/icons/change-icon.svg";
import artistIcon from "../../assets/icons/view-artist-icon.svg";
import nftIcon from "../../assets/icons/add-nft-icon.svg";
import { ReactComponent as Facebook } from "@assets/icons/footer/facebook.svg";
import { ReactComponent as Instagram } from "@assets/icons/footer/instagram.svg";
import { ReactComponent as Twitter } from "@assets/icons/footer/twitter.svg";
import { ReactComponent as Youtube } from "@assets/icons/footer/youtube.svg";
import { ReactComponent as Website } from "@assets/icons/footer/website.svg";

export const artistBtnConfig = {
  btnConfig: [{ key: "createdArtist", text: "Created Artist" }],
};
export const artistDataList = [
  {
    image: null,
    name: "Melinda James",
    totalNfts: "10 NFT’s",
    type: "Created Artist",
  },
];

export const getGalleryDropDownConfig = (t, status) => {
  const galleryDropdownConfig = [
    {
      image: changeIcon,
      text: t("edit_gallery"),
      alt: "three-dots",
      eventkey: "editGallery",
    },
    {
      image: addEditAritst,
      text: t("add_artist"),
      alt: "add-artist",
      eventkey: "addGalleryArtist",
    },
    {
      image: deleteIcon,
      text: t("delete_gallery"),
      alt: "delete",
      eventkey: "deleteGallery",
    },
    {
      image: changeIcon,
      text: t("view_reason"),
      alt: "viewReason",
      eventkey: "viewReason",
    },
  ];

  const newArray = [...galleryDropdownConfig];

  switch (status) {
    case "approved":
      newArray.splice(3, 1);
      return newArray;
    case "pending":
      newArray.splice(1, 1);
      newArray.splice(2, 1);
      return newArray;
    case "rejected":
      newArray.splice(0, 3);
      return newArray;
    default:
      return newArray;
  }
};

export const galleryDataList = [
  {
    image: null,
    name: "Melinda James",
    totalNfts: "10 NFT’s",
    type: "Created Artist",
  },
];

export const galleryBtnConfig = {
  btnConfig: [
    { key: "approved", text: "Approved" },
    { key: "pendingReview", text: "Pending Review" },
    { key: "rejected", text: "Rejected" },
  ],
};

export const getGalleryNav = (t) => {
  return [
    { key: "approved", text: t("approved") },
    { key: "pending", text: t("pending_review") },
    { key: "rejected", text: t("rejected") },
  ];
};

export const getGalleryArtistNav = (t) => {
  return [{ key: "createdArtist", text: t("created_artist") }];
};

export const getAssignedArtistNav = (t) => {
  return [
    { key: "createdNfts", text: t("createdNfts") },
    { key: "collected", text: t("collected") },
    { key: "collection", text: t("collection") },
    { key: "liked", text: t("liked") },
  ];
};

export const ASSIGNED_ARTIST_STATUS = {
  createdNfts: "createdNfts",
  collected: "collected",
  collection: "collection",
  liked: "liked",
};

export const GALLERY_STATUS = {
  approved: "approved",
  pendingReview: "pending",
  rejected: "rejected",
};

export const GALLERY_ARTIST_STATUS = {
  createdArtist: "createdArtist",
};

export const galleryArtistBtnConfig = {
  btnConfig: [{ key: "allArtist", text: "All Artist" }],
};

export const getGalleryArtistDropDownConfig = (t) => {
  const artistDropdownConfig = [
    {
      image: artistIcon,
      text: t("view_artist"),
      alt: "viewArtist",
      eventkey: "viewArtist",
    },
    {
      image: addEditAritst,
      text: t("edit_artist"),
      alt: "addEditArtist",
      eventkey: "addEditArtist",
    },
    {
      image: deleteIcon,
      text: t("delete_artist"),
      alt: "deleteArtist",
      eventkey: "deleteArtist",
    },
  ];

  return artistDropdownConfig;
};

export const ACTION_STATUS = {
  UNSET: "UNSET",
  LOADING: "LOADING",
  COMPLETE: "COMPLETE",
  FAILURE: "FAILURE",
};

export const GALLERY_ARTIST_KEY_MAP = {
  allArtist: "all_artist",
  createdArtist: "created_artist",
  assignedArtist: "assigned_artist",
};

export const social = [
  {
    label: "facebook",
    placeholder: "fb_placeholder",
    Icon: Facebook,
    value: "facebook_url",
  },
  {
    label: "instagram",
    placeholder: "insta_placeholder",
    Icon: Instagram,
    value: "instagram_url",
  },
  {
    label: "twitter",
    placeholder: "twitter_placeholder",
    Icon: Twitter,
    value: "twitter_url",
  },
  {
    label: "youtube",
    placeholder: "youtube_placeholder",
    Icon: Youtube,
    value: "youtube_url",
  },
  {
    label: "website",
    placeholder: "website_placeholder",
    Icon: Website,
    value: "website_url",
  },
];
