import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import exploreIcon from "../../../../icons/explore-icon.svg";
import galleryImage from "../../../../assets/images/gallery-intro.png";
import galleryIcon from "../../../../icons/gallery.svg";
import artistIcon from "../../../../icons/artist.svg";
import morrowLogo from "../../../../assets/images/gallery-partners/morrow.png";
import saudiclubLogo from "../../../../assets/images/gallery-partners/saudinftclub.png";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "./styles.scss";

const PartnersWrapper = styled(Col)`
  .title {
    font-size: 32px;
  }
  .img {
    object-fit: contain;
  }
`;
const GalleryIntro = () => {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language != "ar";
  const navigate = useNavigate();
  const { authenticated } = useSelector((state) => state.auth);

  return (
    <div>
      <div className="gallery-header-overlay">
        <img
          className="gallery-header-image"
          src={galleryImage}
          alt="galleryIcon"
        />
        <div className="gallery-header-text">
          <div className="title-text">{t("About")}</div>
          <div className="title-text"> {t("Nifty Souq Gallery")}</div>
          <p className="title-sub-text mt-4 mt-sm-5">{t("gallery_desc")}</p>
          <div className="d-flex flex-row align-items-center justify-content-center mt-3">
            {authenticated ? (
              // Upload
              <Link to={`/gallery/create`}>
                <div className="mx-2 btn btn-primary gradient">
                  {t("Create")}
                </div>
              </Link>
            ) : (
              // Register
              <Link to="/login" className="mx-2 btn btn-primary gradient">
                {t("login_register")}
              </Link>
            )}
            <Link to="/galleries" className="mx-2 btn btn-primary gradient">
              {t("Explore")}
            </Link>
          </div>
        </div>
      </div>
      <div className="gallery-main-content mt-5">
        <div className="d-flex flex-column justify-content-center align-items-center mt-3">
          <div>
            <p className="gallery-sub-text">{t("Why choose")}</p>
            {isLTR ? (
              <div>
                <p className="gallery-sub-text">
                  {t("Nifty Souq")}
                  <span className="purple-text mx-2">{t("Gallery")}</span>
                </p>
              </div>
            ) : (
              <div className="d-flex flex-row">
                <p className="gallery-sub-text purple-text mx-1">
                  {t("Gallery")}
                </p>
                <p className="gallery-sub-text">{t("Nifty Souq")}</p>
              </div>
            )}
          </div>
          <div className="mt-5 pt-3 choose-container">
            <div className="d-flex flex-md-row flex-column justify-content-evenly align-items-center">
              <div className="choose-box mb-lg-0 mb-5 mx-1">
                {t("gallery_card_text_1")}
              </div>
              <div className="choose-box mb-lg-0 mb-5 mx-1">
                {t("gallery_card_text_2")}
              </div>
              <div className="choose-box mb-lg-0 mb-5 mx-1">
                {t("gallery_card_text_3")}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5 mt-5">
          <div className="text-center pt-5 mt-5 mb-5 page-header">
            {t("How it works")}
          </div>
          <Col className="pb-5 mb-5">
            <div className="d-flex flex-md-row flex-column justify-content-center align-items-md-stretch align-items-center">
              <div className="d-flex flex-column align-items-center justify-content-center how-box mx-4 mb-4 mb-md-0">
                <img src={galleryIcon} alt="" width="40" height="40" />
                <div className="mb-4 page-header-sm uppercase text-center">
                  {t("create_gallery")}
                </div>
                <p className="text-center" style={{ flexGrow: 1 }}>
                  {t("submit_gallery")}
                </p>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center how-box mx-4 mb-4 mb-md-0">
                <img src={artistIcon} alt="" width="40" height="40" />
                <div className="mb-4 page-header-sm uppercase text-center">
                  {t("add_assign_artist")}
                </div>
                <p className="text-center" style={{ flexGrow: 1 }}>
                  {t("create_or_search_artist")}
                </p>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center how-box mx-4 mb-4 mb-md-0">
                <img src={exploreIcon} alt="" width="40" height="40" />
                <div className="mb-4 page-header-sm uppercase text-center">
                  {t("create_nft_artist")}
                </div>
                <p className="text-center" style={{ flexGrow: 1 }}>
                  {t("create_nft_collection")}
                </p>
              </div>
            </div>
          </Col>
        </div>
        <div className="pt-5 mt-5">
          <div className="text-center pt-5 page-header">
            {t("our_partners")}
          </div>
          <PartnersWrapper className="pb-5 mb-3 pt-5">
            <div className="d-flex flex-column flex-md-row justify-content-around align-items-center align-items-md-end">
              <div className="d-flex flex-column justify-content-between align-items-center pb-5 pb-md-0 mb-5 mb-md-0">
                <div>
                  <img src={morrowLogo} height="180" className="img" />
                </div>
                <div>
                  <p className="mt-md-5 mt-1 title">MORROW Collective</p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-center">
                <img src={saudiclubLogo} height="230" className="img" />
                <p className="mt-5 title">Saudi NFT Club</p>
              </div>
            </div>
          </PartnersWrapper>
        </div>
        <Col className="pb-5 mb-5 pt-5">
          <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-primary">
              <span
                className="create-gallery-button"
                onClick={() => navigate("/gallery/create")}
              >
                {t("create_gallery_btn")}
              </span>
            </button>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default GalleryIntro;
