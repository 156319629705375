import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import StylesWrapper from "./styles";

const NFTDetails = ({ nft, nftStatus, externalNFT, colors }) => {
  const { t } = useTranslation();
  const [usdPrice, setUsdPrice] = useState(0);

  const { usd, loading } = useSelector((state) => state.eth);

  const isBezelClub = externalNFT?.club == "TheBezelClub";

  useEffect(() => {
    if (nft?.blockchain === "ETH" && usd?.eth)
      setUsdPrice(
        nft?.listing?.price ? nft?.listing?.price * parseFloat(usd.eth) : 0
      );

    if (nft?.blockchain === "MATIC" && usd?.matic)
      setUsdPrice(
        nft?.listing?.price ? nft?.listing?.price * parseFloat(usd.matic) : 0
      );
  }, [nft, usd]);

  return (
    <StylesWrapper colors={colors}>
      <div className="d-flex flex-row justify-content-between">
        <div className="name">{nft?.name}</div>
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div>
          {nftStatus == 0 && (
            <div className="d-flex flex-row align-items-center">
              <div className="note">{t("Sold")}</div>
              <div className="mx-2 value">|</div>
              <div>
                {nft?.listing?.price || 0}{" "}
                {nft?.is_lazy ? "USDC" : nft?.blockchain}
              </div>
              <div className="mx-2 value">|</div>
              <div>
                $
                {loading
                  ? "..."
                  : nft?.is_lazy
                  ? nft?.listing?.price
                  : usdPrice.toFixed(3)}
              </div>
            </div>
          )}
          {nftStatus == 1 && (
            <div
              style={{ marginInlineStart: 5 }}
              className="d-flex flex-row align-items-center"
            >
              <div className="note">{t("Not for Sale")}</div>
            </div>
          )}
          {(nftStatus == 2 || nftStatus == 3) && (
            <div>
              <div className="label">
                {nftStatus == 3 ? t("Reserve price") : t("Price")}
              </div>

              <div className="d-flex flex-row">
                <div>
                  {nft?.listing?.price || 0}{" "}
                  {nft?.is_lazy ? "USDC" : nft?.blockchain}
                </div>
                <div className="mx-2 value">|</div>
                <div>
                  $
                  {loading
                    ? "..."
                    : nft?.is_lazy
                    ? nft?.listing?.price
                    : usdPrice.toFixed(3)}
                </div>
                <div className="mx-2 value">|</div>
                <div>
                  {nft?.editions?.current
                    ? nft?.editions?.current + " " + t("in stock")
                    : t("Not available")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </StylesWrapper>
  );
};
export default NFTDetails;
