import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import keys from "./lib/config";
// import Footer from "./components/Footer";
import logo from "./assets/images/logo-new.svg";
import footerLogo from "./assets/images/purple-logo.png";
// import { useTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      ...this.state,
      error: error.toString(),
      errorInfo,
    });
  }

  render() {
    if (this.props.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          {window.ENV_NAME === "production" ? (
            <div>
              <header className="app-header">
                <Navbar expand="lg" className="py-0 px-2 navbar-dark ">
                  <Container
                    fluid
                    className="position-relative ms-sm-5 ps-sm-3 me-sm-4 "
                  >
                    {/* dropdowns */}

                    {/* brand */}
                    <div
                      className="me-4 pe-2 py-0"
                      style={{ zIndex: "1011" }}
                      // onMouseEnter={closeDropdowns}
                    >
                      <a href="/">
                        <img
                          src={logo}
                          width="117"
                          height="auto"
                          className="d-inline-block align-top"
                          alt="NiftySouq"
                        />
                      </a>
                    </div>
                  </Container>
                </Navbar>
              </header>
              <Container>
                <h1 className="text-center">Something went wrong.</h1>
                <p className="text-center">
                  An error occcured, please check again later
                </p>
              </Container>
              <footer
                className="mt-4 pt-4"
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  borderTop: "#e6e8ec solid 1px",
                }}
              >
                <Container>
                  {/* --------- Join our Newsletter & Join The Community --------- */}
                  <Row className="footer-section d-flex justify-content-between mb-0 mb-lg-4">
                    <Col xs={4} className="links-section">
                      <a href="/" className="d-inline-block">
                        <img
                          width="192"
                          height="auto"
                          alt="NiftySouq"
                          src={footerLogo}
                        />
                      </a>
                    </Col>
                    <Col lg={6} xs={8} className="links-section">
                      <div className="label">
                        The first and largest NFT marketplace in MENA
                      </div>

                      <div className="copyright">
                        <Row>
                          <Col xs="12" className="footer-left">
                            Copyright © 2022 Nifty Souq All Rights Reserved
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col
                      xs={12}
                      lg={6}
                      className="d-flex justify-content-start justify-content-lg-end links-section"
                    >
                      <div></div>
                    </Col>
                  </Row>

                  {/* --------- Copyright --------- */}
                </Container>
              </footer>
            </div>
          ) : (
            <div>
              <h1>Something went wrong.</h1>
              <div style={{ whiteSpace: "pre-wrap" }}>
                {this.props.error && this.props.error.toString()}
                <br />
                {this.props.errorInfo && this.props.errorInfo.componentStack}
              </div>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
