import React from "react";
import {
  Button,
  Modal as StrapModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const Modal = ({
  open,
  onClose,
  onSubmit,
  onCancel,
  title,
  header,
  content,
  footer,
  className = "",
  hasCancelButton,
  hasSubmitButton,
  cancelLabel = "Cancel",
}) => {
  const { t } = useTranslation();
  return (
    <StrapModal isOpen={open} toggle={onClose} className={className} centered>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      {content ? <ModalBody> {content} </ModalBody> : null}
      {hasCancelButton || hasSubmitButton ? (
        <ModalFooter>
          {hasCancelButton ? (
            <Button color="secondary" onClick={onCancel}>
              {t(cancelLabel)}
            </Button>
          ) : null}
          {hasSubmitButton ? (
            <Button color="primary" onClick={onSubmit}>
              {t("Submit")}
            </Button>
          ) : null}
        </ModalFooter>
      ) : null}
    </StrapModal>
  );
};

export default Modal;
