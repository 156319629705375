/* eslint-disable import/no-anonymous-default-export */
// ** Auth Endpoints
import keys from '../../lib/config'
export default {
    loginEndpoint: `${window.API_URL}/api/user/login`,
    getEndpoint: `${window.API_URL}/api/user/get`,
    saveWalletEndpoint: `${window.API_URL}/api/user/saveWallet`,
    getEncryptKeyEndpoint: `${window.API_URL}/api/cards/getEncryptkey`,
    addCardEndpoint: `${window.API_URL}/api/cards/addCard`,
    makePaymentEndpoint: `${window.API_URL}/api/cards/makePayment`,
    getCardsEndpoint: `${window.API_URL}/api/cards/getCards`,
    createTokenEndpoint: `${window.API_URL}/api/nft/create`,
    getAllTokenEndpoint: `${window.API_URL}/api/nft/getAll`,
    getItemEndpoint: `${window.API_URL}/api/nft/getNFT`,
    tokenType: 'Bearer',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
}
