export const GET_WERT_DATA = 'GET_WERT_DATA';

//* get order data
export const GET_WERT_DATA_LOADING = "GET_WERT_DATA_LOADING";
export const GET_WERT_DATA_SUCCESS = "GET_WERT_DATA_SUCCESS";
export const GET_WERT_DATA_ERROR = "GET_WERT_DATA_ERROR";

//* check backend status
export const CHECK_BACKEND_STATUS_LOADING = "CHECK_BACKEND_STATUS_LOADING";
export const CHECK_BACKEND_STATUS_SUCCESS = "CHECK_BACKEND_STATUS_SUCCESS";
export const CHECK_BACKEND_STATUS_ERROR = "CHECK_BACKEND_STATUS_ERROR";