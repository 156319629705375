/** actions for collection */
export const CREATE_COLLECTION = "CREATE_COLLECTION";
export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION_FAILURE = "CREATE_COLLECTION_FAILURE";

export const FETCH_USER_COLLECTIONS = "FETCH_USER_COLLECTIONS";
export const FETCH_USER_COLLECTIONS_SUCCESS = "FETCH_USER_COLLECTIONS_SUCCESS";
export const FETCH_USER_COLLECTIONS_PAGINATED_SUCCESS =
  "FETCH_USER_COLLECTIONS_PAGINATED_SUCCESS";
export const FETCH_USER_COLLECTIONS_FAILURE = "FETCH_USER_COLLECTIONS_FAILURE";

export const RESET_COLLECTION_FLAG = "RESET_COLLECTION_FLAG";
export const RESET_INITIAL_STATE = "RESET_INITIAL_STATE";

export const FETCH_COLLECTION_DETAIL = "FETCH_COLLECTION_DETAIL";
export const FETCH_COLLECTION_DETAIL_SUCCESS =
  "FETCH_COLLECTION_DETAIL_SUCCESS";
export const FETCH_COLLECTION_DETAIL_FAILURE =
  "FETCH_COLLECTION_DETAIL_FAILURE";

export const FETCH_COLLECTION_ACTIVITIES = "FETCH_COLLECTION_ACTIVITIES";
export const FETCH_COLLECTION_ACTIVITIES_SUCCESS =
  "FETCH_COLLECTION_ACTIVITIES_SUCCESS";
export const FETCH_COLLECTION_ACTIVITIES_FAILURE =
  "FETCH_COLLECTION_ACTIVITIES_FAILURE";

export const FETCH_TRENDING_COLLECTIONS = "FETCH_TRENDING_COLLECTIONS";
export const FETCH_TRENDING_COLLECTIONS_SUCCESS =
  "FETCH_TRENDING_COLLECTIONS_SUCCESS";
export const FETCH_TRENDING_COLLECTIONS_FAILURE =
  "FETCH_TRENDING_COLLECTIONS_FAILURE";

export const FETCH_COLLECTION_NFTS = "FETCH_COLLECTION_NFTS";
export const FETCH_COLLECTION_NFTS_SUCCESS = "FETCH_COLLECTION_NFTS_SUCCESS";
export const FETCH_COLLECTION_NFTS_FAILURE = "FETCH_COLLECTION_NFTS_FAILURE";

export const FETCH_LATEST_COLLECTIONS = "FETCH_LATEST_COLLECTIONS";
export const FETCH_LATEST_COLLECTIONS_SUCCESS =
  "FETCH_LATEST_COLLECTIONS_SUCCESS";
export const FETCH_LATEST_COLLECTIONS_FAILURE =
  "FETCH_LATEST_COLLECTIONS_FAILURE";

//* edit collection
export const EDIT_COLLECTION_LOADING = "EDIT_COLLECTION_LOADING";
export const EDIT_COLLECTION_SUCCESS = "EDIT_COLLECTION_SUCCESS";
export const EDIT_COLLECTION_ERROR = "EDIT_COLLECTION_ERROR";

//* delete collection
export const DELETE_COLLECTION_LOADING = "DELETE_COLLECTION_LOADING";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_ERROR = "DELETE_COLLECTION_ERROR";
