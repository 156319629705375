import { useEffect } from "react";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import { FormGroup, Label, Input } from "reactstrap";
import { Navigate } from "react-router-dom";
import arrowLeft from "../icons/arrow-left.svg";
// import plusCircle from "../icons/plus-circle.svg";
import xCircle from "../icons/x-circle.svg";
import "./edit-profile.css";
// import { AuthContext } from "../context/AuthContext";
// import axios from "axios";
// import keys from "../lib/config";
// import { useWeb3Auth } from "../context/Web3auth";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { profileActionsCreators } from "../store";
import { updateProfileSchema } from "../validations";
import { Formik, Field } from "formik";
import Modal from "../components/common/Modal";

const Wrapper = styled.div`
  input[type="file"] {
    color: rgba(0, 0, 0, 0);
  }
`;

export const EditProfile = () => {
  // import user from context api
  const dispatch = useDispatch();
  const { updateProfileCreator, updateProfileImages } = bindActionCreators(
    profileActionsCreators,
    dispatch
  );

  const { account } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { loading, success, error } = useSelector(
    (state) => state.profile_update
  );

  const { getUserProfile } = bindActionCreators(
    profileActionsCreators,
    dispatch
  );
  const urlWithProtocol = (data) => {
    if (data) {
      if (data.includes("https://")) {
        return data;
      } else if (data.includes("http://")) {
        return data;
      } else {
        return "https://" + data;
      }
    } else {
      return "";
    }
  };
  const initialValues = {
    name: user?.name,
    bio: account?.bio,
    website: urlWithProtocol(account?.media?.website),
    twitter: urlWithProtocol(account?.media?.twitter),
    facebook: urlWithProtocol(account?.media?.facebook),
    instagram: urlWithProtocol(account?.media?.instagram),
    youtube: urlWithProtocol(account?.media?.youtube),
    dribble: account?.media?.dribble,
  };
  const { t, i18n } = useTranslation();

  const onChangePhoto = async (e) => {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    await updateProfileImages("image", formData);
  };

  useEffect(() => getUserProfile(account?._id), [account?._id]);
  useEffect(() => {
    // if (user && user._id) {
    //   setName(user.name);
    //   setTwitter(user.twitter);
    //   setProfilePhoto({ data: "", preview: account?.profile?.image_url });
    // }
  }, [account]);

  return (
    <Wrapper>
      <div>
        {/* --------- breadcrumb --------- */}
        <div className="breadcrumb-section">
          <Container>
            <div className="d-flex justify-content-between">
              <div>
                <Link
                  to={`/profile/${account?._id}`}
                  className="btn btn-secondary-outline radius-50"
                >
                  <img
                    src={arrowLeft}
                    alt="arrow left icon"
                    className="button-icon"
                  />
                  {t("Back to profile")}
                </Link>
              </div>
              <div>
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to={`/profile/${account?._id}`}> {t("Profile")}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{t("Edit profile")}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>
          </Container>
        </div>

        <Container className="edit-page-section">
          {/* --------- title and description --------- */}
          <Row className="title-description">
            <Col>
              <div className="edit-page-title">{t("Edit profile")}</div>
              <p className="edit-page-info">{t("username_001")}</p>
            </Col>
          </Row>
          <Row className="gx-5">
            {/* --------- profile photo --------- */}
            <Col lg={5}>
              <div className="d-flex justify-content-start mb-5">
                <div>
                  <div className="edit-page-photo">
                    <div className="overlay-loading">
                      <img
                        src={
                          account?.profile?.image_url || "/user-placeholder.png"
                        }
                        className="edit-page-photo"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <h3 className="edit-section-title">{t("Profile image")}</h3>
                  <p className="edit-section-text">{t("upload_logo_p1")}</p>
                  <input
                    type="file"
                    style={{ fontWeight: 350 }}
                    onChange={onChangePhoto}
                  />
                </div>
              </div>
            </Col>
            {/* --------- form --------- */}
            <Col lg={7}>
              <Formik
                initialValues={initialValues}
                validationSchema={updateProfileSchema(t)}
                onSubmit={async (values, actions) => {
                  actions.setSubmitting(true);
                  const {
                    bio,
                    facebook,
                    instagram,
                    name,
                    twitter,
                    website,
                    youtube,
                  } = values;

                  const body = { bio, name };

                  if (facebook) {
                    body.facebook = urlWithProtocol(facebook);
                  }
                  if (instagram) {
                    body.instagram = urlWithProtocol(instagram);
                  }
                  if (twitter) {
                    body.twitter = urlWithProtocol(twitter);
                  }
                  if (website) {
                    body.website = urlWithProtocol(website);
                  }
                  if (youtube) {
                    body.youtube = urlWithProtocol(youtube);
                  }
                  await updateProfileCreator(body);
                  actions.setSubmitting(false);
                }}
              >
                {({ handleSubmit, errors, touched, isSubmitting }) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        {/* --- Account info --- */}
                        <Col md={6} lg={12} className="mb-5">
                          <h3 className="mb-4 edit-section-title">
                            {t("Account info")}
                          </h3>
                          <Form.Group controlId="display-name" className="mb-4">
                            <Form.Label className="edit-section-label">
                              {t("Username")}
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              type="text"
                              name="name"
                              placeholder={t("Enter your display name")}
                              className="edit-page-input-field"
                            />
                            <div style={{ color: "red" }}>{errors.name}</div>
                          </Form.Group>
                          <FormGroup>
                            <Label for="bio" className="edit-section-label">
                              {" "}
                              {t("Bio")}
                            </Label>
                            <Field
                              as={Input}
                              name="bio"
                              id="bio"
                              rows="3"
                              type="textarea"
                              placeholder={t("info_001")}
                              className="edit-page-text-area"
                            />
                            <div style={{ color: "red" }}>{errors.bio}</div>
                          </FormGroup>
                        </Col>

                        {/* --- Social --- */}
                        <Col md={6} lg={12} className="mb-5">
                          <h3 className="mb-4 edit-section-title">
                            {t("Social Media")}
                          </h3>
                          <Form.Group controlId="twitter" className="mb-4">
                            <Form.Label className="edit-section-label">
                              {t("twitter")}
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              type="text"
                              name="twitter"
                              placeholder={t("Enter URL")}
                              className="edit-page-input-field"
                            />
                            <div style={{ color: "red" }}>{errors.twitter}</div>
                          </Form.Group>
                          <Form.Group controlId="display-name" className="mb-4">
                            <Form.Label className="edit-section-label">
                              {t("instagram")}
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              type="text"
                              name="instagram"
                              placeholder={t("Enter URL")}
                              className="edit-page-input-field"
                            />
                            <div style={{ color: "red" }}>
                              {errors.instagram}
                            </div>
                          </Form.Group>
                          <Form.Group controlId="facebook" className="mb-4">
                            <Form.Label className="edit-section-label">
                              {t("facebook")}
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              type="text"
                              name="facebook"
                              placeholder={t("Enter URL")}
                              className="edit-page-input-field"
                            />
                            <div style={{ color: "red" }}>
                              {errors.facebook}
                            </div>
                          </Form.Group>
                          <Form.Group controlId="youtube" className="mb-4">
                            <Form.Label className="edit-section-label">
                              {t("youtube")}
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              type="text"
                              name="youtube"
                              placeholder={t("Enter URL")}
                              className="edit-page-input-field"
                            />
                            <div style={{ color: "red" }}>{errors.youtube}</div>
                          </Form.Group>
                          <Form.Group controlId="website" className="mb-4">
                            <Form.Label className="edit-section-label">
                              {t("website")}
                            </Form.Label>
                            <Field
                              as={Form.Control}
                              type="text"
                              name="website"
                              placeholder={t("Enter URL")}
                              className="edit-page-input-field"
                            />
                            <div style={{ color: "red" }}>{errors.website}</div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="pb-3 update-message">
                          <p className="edit-page-info">
                            {t("update_settings_001")}
                          </p>
                        </Col>
                        <Col xs={12} className="mt-4">
                          <Button
                            className="me-4"
                            disabled={isSubmitting}
                            onClick={handleSubmit}
                            type="submit"
                          >
                            {loading ? "..." : t("Update Profile")}
                          </Button>
                          <Link
                            // onClick={clearAllFields}
                            to={`/profile/${account?._id}`}
                            className="clear-all-link"
                          >
                            <img
                              src={xCircle}
                              alt="x circle icon"
                              className="button-icon"
                            />
                            {t("Cancel")}
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
      {success ? (
        <Modal
          open={true}
          title={t("profile_updated_title")}
          onClose={false}
          content={t("profile_updated_content")}
          hasCancelButton={true}
          hasSubmitButton={false}
          cancelLabel="Ok"
          onCancel={function () {
            window.location = `/profile/${account?._id}`;
          }}
        />
      ) : null}
    </Wrapper>
  );
};
