// import { Button } from "react-bootstrap";
import styled from "styled-components";
import ModalAndOverlay from "./ModalAndOverlay";
import closeIcon from "../../src/icons/close-circle.svg";
// import errorIcon from "../../src/icons/error.svg";
import facebookIcon from "../../src/icons/facebook.svg";
import twitterIcon from "../../src/icons/twitter.svg";
import instagramIcon from "../../src/icons/instagram.svg";
import pinterestIcon from "../../src/icons/pinterest.svg";

const Checkout = styled.div`
  h4 {
    color: #23262f;
    font-size: 24px;
  }
  h6 {
    color: #23262f;
    font-weight: 500;
  }
  p {
    color: #777e90;
    margin-bottom: 8px;
    font-weight: 500;
  }
  span {
    color: #23262f;
    font-weight: 500;
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
  .error-container {
    background-color: #fce8ed;
    border-radius: 8px;
    h6,
    p {
      color: #ef466f;
      font-weight: 500;
    }
  }
`;

const CheckoutCompleted = styled.div`
  text-align: center;
  h2 {
    font-size: 48px;
    font-weight: 400;
  }
  .message {
    border: 1px solid #e6e8ec;
    border-radius: 8px;
    margin: 64px 0 32px 0;
    span {
      text-align: left;
      flex: 1;
      font-weight: 500;
    }
    .table-title {
      span {
        color: #777e90;
      }
    }
    .table-row {
      span:first-child {
        color: #6d3190;
      }
      span:last-child {
        color: #23262f;
      }
    }
  }
  .icons {
    h6 {
      color: #23262f;
      font-size: 16px;
    }
    a {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #e6e8ec;
      border-radius: 50%;
      margin-right: 8px;
      margin-left: 8px;
      &:hover {
        border: 2px solid #23262f;
      }
    }
  }
`;

const CheckoutModals = (props) => {
  return (
    <ModalAndOverlay>
      {/* --------- checkout --------- */}
      {/* <Checkout>
                <div className="d-flex justify-content-between mb-5">
                    <h3 className="modal-heading">Checkout</h3>
                    <img
                        className="modal-close-icon"
                        src={closeIcon}
                        alt="close icon"
                        onClick={props.closeModal}
                    />
                </div>
                <div className="d-flex justify-content-between enter-bid mb-3 pb-1">
                    <h6>15.00</h6>
                    <span>XTZ</span>
                </div>
                <div className="d-flex justify-content-between">
                    <p>Your balance</p>
                    <span>200 XTZ</span>
                </div>
                <div className="d-flex justify-content-between">
                    <p>Service fee</p>
                    <span>3.75 XTZ</span>
                </div>
                <div className="d-flex justify-content-between">
                    <p>You will pay</p>
                    <span>18.75 XTZ</span>
                </div>
                <div className="error-container d-flex my-4 p-4">
                    <img
                        src={errorIcon}
                        alt="error"
                        className="me-3 align-items-center"
                    />
                    <div>
                        <h6>This creator is not verified</h6>
                        <p>Purchase this item at your own risk</p>
                    </div>
                </div>
                <Button className="modal-button mb-3">Place a bid</Button>
                <Button className="modal-button modal-outline-button">
                    Cancel
                </Button>
            </Checkout> */}

      {/* --------- checkout completed --------- */}
      <CheckoutCompleted>
        <div className="d-flex justify-content-end mb-4">
          <img
            src={closeIcon}
            alt="close icon"
            className="modal-close-icon"
            onClick={props.closeModal}
          />
        </div>
        <h2>Yay! 🎉</h2>
        <div className="message p-4">
          <div className="d-flex table-title mb-3">
            <span>Status</span>
            <span>Transaction ID</span>
          </div>
          <div className="d-flex table-row">
            <span>Processing</span>
            <span>0msx836930...87r398</span>
          </div>
        </div>
        <div className="icons">
          <h6>Time to show-off</h6>
          <div className="d-flex justify-content-center">
            <a href="#">
              <img src={facebookIcon} alt="facebook" />
            </a>
            <a href="#">
              <img src={twitterIcon} alt="facebook" />
            </a>
            <a href="#">
              <img src={instagramIcon} alt="facebook" />
            </a>
            <a href="#">
              <img src={pinterestIcon} alt="facebook" />
            </a>
          </div>
        </div>
      </CheckoutCompleted>
    </ModalAndOverlay>
  );
};

export default CheckoutModals;
