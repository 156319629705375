import * as categoryTypes from "../types/category";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getCategoriesCreator = (limit = 4) => {
  return (dispatch) => {
    dispatch({
      type: categoryTypes.GET_CATEGORIES_LOADING,
      loading: true,
    });

    API.get("api/category", { limit })
      .then((res) => {
        dispatch({
          type: categoryTypes.GET_CATEGORIES_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: categoryTypes.GET_CATEGORIES_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
