import * as authTypes from "../actions/types/auth";
import * as profileTypes from "../actions/types/profile";

const auth = {
  loading: true,
  verify_loading: true,
  authenticated: false,
  account: null,
  error: null,
  progress: 0,
};

const authActions = {
  loading: false,
  success: false,
  action: null,
  error: null,
};

export const authReducer = (state = auth, action) => {
  switch (action.type) {
    //*reset progress
    case authTypes.RESET_PROGRESS:
      return { ...state, progress: 0 };
    //* logout
    case authTypes.LOG_OUT:
      return { ...state, authenticated: false, account: null };
    //* login
    case authTypes.LOGIN_LOADING:
      return { ...state, loading: true, progress: 0 };
    case authTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        verify_loading: false,
        authenticated: true,
        account: action.payload,
        progress: 100,
        error: null,
      };
    case authTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        verify_loading: false,
        authenticated: false,
        progress: 100,
        error: action.error,
      };
    //* verify
    case authTypes.VERIFY_ACCOUNT_LOADING:
      return {
        ...state,
        // loading: false,
        progress: 0,
        verify_loading: true,
      };
    case authTypes.VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        verify_loading: false,
        authenticated: true,
        account: action.payload,
        progress: 100,
        error: null,
      };
    case authTypes.VERIFY_ACCOUNT_ERROR:
      return {
        ...state,
        // loading: false,
        verify_loading: false,
        authenticated: false,
        progress: 100,
        error: action.error,
      };
    //* update profile images
    case profileTypes.UPDATE_PROFILE_IMAGES_SUCCESS:
      return {
        ...state,
        account: Object.assign(state.account, action.payload),
      };
    //* update profile
    case profileTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, account: action.payload };
    default:
      return state;
  }
};

export const authActionsReducer = (state = authActions, action) => {
  switch (action.type) {
    case authTypes.IMPORT_WALLET_LOADING:
      return { ...state, loading: true };
    case authTypes.IMPORT_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "import wallet",
        error: null,
      };
    case authTypes.IMPORT_WALLET_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "import wallet",
        error: action.error,
      };
    default:
      return state;
  }
};
