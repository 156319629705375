const NFT_ITEMS = [
    {
        id: 'n1',
        name: 'Amazing digital art',
        price: '0.2700000',
        stock: 3,
        ntfUrl: '#',
        imageUrl: '/images/nft_1.png',
        owners: [
            {
                id: 'u1',
                profileUrl: '#',
                imageUrl: '/user1.jpg',
            },
            {
                id: 'u2',
                profileUrl: '#',
                imageUrl: '/user2.jpg',
            },
            {
                id: 'u3',
                profileUrl: '#',
                imageUrl: '/user3.jpg',
            },
        ],
        highestBid: 0.001,
    },
    {
        id: 'n2',
        name: 'Amazing digital art',
        price: '0.2700000',
        stock: 4,
        ntfUrl: '#',
        imageUrl: '/images/nft_2.png',
        owners: [
            {
                id: 'u1',
                profileUrl: '#',
                imageUrl: '/user1.jpg',
            },
            {
                id: 'u2',
                profileUrl: '#',
                imageUrl: '/user2.jpg',
            },
            {
                id: 'u3',
                profileUrl: '#',
                imageUrl: '/user3.jpg',
            },
        ],
        highestBid: 0.001,
    },
    {
        id: 'n3',
        name: 'Amazing digital art',
        price: '0.2700000',
        stock: 5,
        ntfUrl: '#',
        imageUrl: '/images/nft_5.png',
        owners: [
            {
                id: 'u1',
                profileUrl: '#',
                imageUrl: '/user1.jpg',
            },
            {
                id: 'u2',
                profileUrl: '#',
                imageUrl: '/user2.jpg',
            },
            {
                id: 'u3',
                profileUrl: '#',
                imageUrl: '/user3.jpg',
            },
        ],
        highestBid: 0.001,
    },
    {
        id: 'n4',
        name: 'Amazing digital art',
        price: '0.2700000',
        stock: 6,
        imageUrl: '/images/nft_1.png',
        owners: [
            {
                id: 'u1',
                profileUrl: '#',
                imageUrl: '/user1.jpg',
            },
            {
                id: 'u2',
                profileUrl: '#',
                imageUrl: '/user2.jpg',
            },
            {
                id: 'u3',
                profileUrl: '#',
                imageUrl: '/user3.jpg',
            },
        ],
        highestBid: 0.001,
    },
    {
        id: 'n5',
        name: 'Amazing digital art',
        price: '0.2700000',
        stock: 6,
        imageUrl: '/images/nft_2.png',
        owners: [
            {
                id: 'u1',
                profileUrl: '#',
                imageUrl: '/user1.jpg',
            },
            {
                id: 'u2',
                profileUrl: '#',
                imageUrl: '/user2.jpg',
            },
            {
                id: 'u3',
                profileUrl: '#',
                imageUrl: '/user3.jpg',
            },
        ],
        highestBid: 0.001,
    },
]

export default NFT_ITEMS
