/* eslint-disable eqeqeq */
import * as partnerTypes from "../types/partner";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getPartnerNFTs = (contract, params, paginated = false) => {
  return (dispatch) => {
    dispatch({ loading: true, type: partnerTypes.GET_PARTNER_NFTS_LOADING });

    API.get(`api/club/nft/${contract}`, params)
      .then((res) => {
        dispatch({
          paginated,
          loading: false,
          payload: res.data.data,
          has_more: res.data.data?.length === params?.limit,
          type: partnerTypes.GET_PARTNER_NFTS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: partnerTypes.GET_PARTNER_NFTS_ERROR,
        });
      });
  };
};

export const getNFTDetailsPartner = (contract, id) => {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: partnerTypes.GET_PARTNER_NFT_DETAILS_LOADING,
    });

    API.get(`api/club/nft/details/${contract}/${id}`, {})
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data.data,
          type: partnerTypes.GET_PARTNER_NFT_DETAILS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: partnerTypes.GET_PARTNER_NFT_DETAILS_ERROR,
        });
      });
  };
};
export const purchasePartnerNFT = (contract, id, body) => {
  return (dispatch) => {
    dispatch({
      type: partnerTypes.PURCHASE_PARTNER_NFT_LOADING,
      loading: true,
    });

    API.post(`api/club/nft/purchase/${contract}/${id}`, body)
      .then((res) => {
        dispatch({
          type: partnerTypes.PURCHASE_PARTNER_NFT_SUCCESS,
          action: "purchase",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: partnerTypes.PURCHASE_PARTNER_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const cancelPartnerNFT = (contract, id, body) => {
  return (dispatch) => {
    dispatch({
      type: partnerTypes.CANCEL_PARTNER_NFT_LOADING,
      loading: true,
    });

    API.put(`api/club/nft/cancel/${contract}/${id}`, body)
      .then((res) => {
        dispatch({
          type: partnerTypes.CANCEL_PARTNER_NFT_SUCCESS,
          action: "cancel",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: partnerTypes.CANCEL_PARTNER_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const burnPartnerNFT = (contract, id, body) => {
  return (dispatch) => {
    dispatch({
      type: partnerTypes.BURN_PARTNER_NFT_LOADING,
      loading: true,
    });

    API.post(`api/club/nft/burn/${contract}/${id}`, body)
      .then((res) => {
        dispatch({
          type: partnerTypes.BURN_PARTNER_NFT_SUCCESS,
          action: "burn",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: partnerTypes.BURN_PARTNER_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
export const putOnSalePartnerNFT = (contract, id, body) => {
  return (dispatch) => {
    dispatch({
      type: partnerTypes.LIST_PARTNER_NFT_LOADING,
      loading: true,
    });

    API.post(`api/club/nft/list/${contract}/${id}`, body)
      .then((res) => {
        dispatch({
          type: partnerTypes.LIST_PARTNER_NFT_SUCCESS,
          action: "list",
          loading: false,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: partnerTypes.LIST_PARTNER_NFT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
export const changePartnerNftPrice = (contract, id, body) => {
  return (dispatch) => {
    dispatch({
      type: partnerTypes.CHANGE_PARTNER_NFT_PRICE_LOADING,
      loading: true,
    });

    API.put(`api/club/nft/price/change/${contract}/${id}`, body)
      .then((res) => {
        dispatch({
          type: partnerTypes.CHANGE_PARTNER_NFT_PRICE_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: partnerTypes.CHANGE_PARTNER_NFT_PRICE_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
