import { Container, Spinner } from "react-bootstrap";

import { useWeb3Auth } from "../../context/Web3auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useJwt from "@hooks/jwt/useJwt";
import { useTranslation } from "react-i18next";
import md5 from "md5";

//store

import { userSelector, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { authActionsCreators } from "../../store";

import { getCookie } from "../../core/cookies";

export const Auth = () => {
  const dispatch = useDispatch();
  const { loginCreator } = bindActionCreators(authActionsCreators, dispatch);
  const { loading, account, authenticated } = useSelector(
    (state) => state.auth
  );
  const { t, i18n } = useTranslation();

  const { jwt } = useJwt({});
  const { privateKey, logout, load, getAccounts } = useWeb3Auth();
  const navigate = useNavigate();

  const navigateUser = () => {
    const return_page = localStorage.getItem("return_page");
    if (return_page) {
      localStorage.removeItem("return_page");
      navigate(return_page);
      return;
    }
    navigate(`/profile/${account._id}`);
  };

  const navigateAfterLogin = () => {
    authenticated ? navigateUser() : navigate("/agreement");
  };

  const init = async () => {
    const data = await load();
    try {
      // const loginData = {
      //   name: data.name,
      //   principal: md5(data.email),
      //   email: data.email,
      //   profileImage: data.profileImage,
      //   typeLogin: data.typeOfLogin,
      // };
      // console.log({ loginData });
      // await loginCreator(loginData);
      navigateUser();
    } catch (e) {
      console.log("=== ===== >>>>", e);
    }
  };

  useEffect(() => {
    authenticated ? navigateAfterLogin() : init();
  }, [authenticated]);
  return (
    <Container className="text-center">
      <h4 className="mt-5">{t("login_successful")}</h4>
      <div>{t("wait_moment")}</div>
      <div className="py-3 mb-5">
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
        />
      </div>
    </Container>
  );
};
