import * as categoryTypes from "../actions/types/category";

const category = {
  loading: false,
  categories: [],
  error: null,
};

export const categoryReducer = (state = category, action) => {
  switch (action.type) {
    case categoryTypes.GET_CATEGORIES_LOADING:
      return { ...state, loading: true };
    case categoryTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
        error: null,
      };
    case categoryTypes.GET_CATEGORIES_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return {...state};
  }
};
