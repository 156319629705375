import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import App from "./App";
import AuthProvider from "./context/AuthContext";
import GlobalContext from "@context/index";
import * as Sentry from "@sentry/react";
import keys from "./lib/config";
import { BrowserTracing } from "@sentry/tracing";

import "./index.css";

import { Provider } from "react-redux";
import { store } from "./store";
Sentry.init({
  dsn: window.SENTRY_DNS_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <GlobalContext>
        <App />
      </GlobalContext>
    </AuthProvider>
  </Provider>
);

// <Route path="*" element={<NoPage />} />
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
