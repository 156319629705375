import { Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import metaMask from "../../assets/icons/meta-mask.svg";
import metaMaskAddress from "../../assets/icons/meta-mask-address.svg";
import switchIcon from "../../assets/icons/switch.svg";
import deleteIcons from "../../assets/icons/delete-black.svg";
import linked from "../../assets/icons/linked.svg";

const Wrapper = styled.div`
  padding: 16px;
  .modal-header {
    border: none;
  }
  .modal-title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .address {
    margin-top: -60px;
    margin-left: 60px;
    font-size: 18px;
  }
  .button-container {
    border-top: 1px solid #ececec;
    padding-top: 32px;
  }
  .icon-button {
    cursor: pointer;
  }
  @media (max-width: 992px) {
    .view-listed-btn {
      width: 100%;
      margin-bottom: 36px;
    }
  }
`;

const MetaMaskModal = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <Modal {...props} size="lg" centered>
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <img src={metaMask} alt="" width="60" height="60" /> Meta Mask
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="address">
            4fyhygt348{" "}
            <img src={metaMaskAddress} alt="" className="ms-2 icon-button" />
          </p>
          <Row className="mt-4 mb-3 button-container">
            <Col xs={12} lg={6} className="mb-3">
              <div className="large-btn-outline">
                <span>{t("Balance")}</span>
                <h2>1000</h2>
              </div>
            </Col>
            <Col xs={12} lg={6} className="mb-3">
              <div className="large-btn-outline">
                <span>{t("Holds")}</span>
                <h2>200</h2>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col
              xs={12}
              lg={6}
              className="d-flex justify-content-center justify-content-md-start"
            >
              <Button className="view-listed-btn">
                <img src={linked} alt="" className="me-2" />
                {t("View listed NFT")}
              </Button>
            </Col>
            <Col xs={12} lg={6} className="d-flex justify-content-end">
              <div>
                <img src={switchIcon} alt="" className="me-3 icon-button" />
                <img src={deleteIcons} alt="" className="icon-button" />
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Wrapper>
    </Modal>
  );
};

export default MetaMaskModal;
