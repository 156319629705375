import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { partnerActionCreators } from "../../../store";
import BannerImage from "@assets/images/partners-cover.png";
import SearchIcon from "@assets/icons/search-icon.svg";
import { Banner, CollectionCard } from "./Partials";
import StylesWrapper from "./styles";

export const PopularPartners = () => {
  const { t, i18n } = useTranslation();
  const { loading, error, projects } = useSelector((state) => state.projects);

  return (
    <StylesWrapper isLTR={i18n.language != "ar"}>
      <Banner
        image={BannerImage}
        imageHeight="554"
        title={t("partners_collections")}
        subtitle={t("explore_partners")}
        renderMain={() => (
          <div className="px-5 mx-4">
            {/* <div className="d-flex flex-row justify-content-center justify-content-md-end mb-5 pb-3 mx-5 px-1">
              <div className="search-wrapper">
                <input placeholder={t("search_nft")} className="search-input" />
                <img className="search-icon" src={SearchIcon} />
              </div>
            </div> */}
            {error && <p>{error}</p>}
            {loading && projects?.length === 0 && <p>{t("Loading...")}</p>}
            {projects && projects?.length > 0 && (
              <div className="mt-5 d-flex flex-row justify-content-evenly flex-wrap">
                {projects.map((partner, index) => (
                  <CollectionCard item={partner} />
                ))}
              </div>
            )}
          </div>
        )}
      />
    </StylesWrapper>
  );
};
