import * as userTypes from "../types/user";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getPopularSellersCreator = () => {
  return (dispatch) => {
    dispatch({
      type: userTypes.GET_POPULAR_SELLERS_LOADING,
      loading: true,
    });

    API.get(`api/user/featured/`, {})
      .then((res) => {
        dispatch({
          type: userTypes.GET_POPULAR_SELLERS_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: userTypes.GET_POPULAR_SELLERS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getLatestSellersUploadCreator = () => {
  return (dispatch) => {
    dispatch({
      type: userTypes.GET_LATEST_SELLERS_UPLOAD_LOADING,
      loading: true,
    });

    API.get(`api/nft/creators/latest/`, {})
      .then((res) => {
        dispatch({
          type: userTypes.GET_LATEST_SELLERS_UPLOAD_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: userTypes.GET_LATEST_SELLERS_UPLOAD_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const getAllSellersCreator = (params, load) => {
  return (dispatch) => {
    dispatch({ type: userTypes.GET_ALL_SELLERS_LOADING, loading: true });

    API.get(`api/user/populer-seller/`, params)
      .then((res) => {
        dispatch({
          type: userTypes.GET_ALL_SELLERS_SUCCESS,
          loading: false,
          payload: res.data.data,
          count: res.data.count,
          has_more: res.data.data?.length === 20,
          load,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          type: userTypes.GET_ALL_SELLERS_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};
