import styled from "styled-components";

const StylesWrapper = styled("div")`
  .name {
    font-size: 42px;
    color: ${(props) => props?.colors?.text1};
  }
  .label {
    font-size: 18px;
    color: ${(props) => props?.colors?.text1};
  }
  .value {
    color: ${(props) => props?.colors?.label};
  }
  .note {
    color: ${(props) => props?.colors?.label};
    font-weight: 400;
    text-transform: uppercase;
  }
`;
export default StylesWrapper;
