import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { public_routes } from "../pages/routing";
import { useSelector } from "react-redux";
import keys from "../lib/config";
// import "../App.css";

function Layout() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const { contract } = useParams();
  document.body.dir = i18n.dir();
  document.querySelector("body").className = i18n.dir();
  const { authenticated, loading } = useSelector((state) => state.auth);

  const coverImage =
    location.pathname == public_routes.home ? "home-cover-image" : "";

  const footerTheme =
    contract == window.ADDRESSES.BEZEL.ETH ||
    location.pathname == public_routes.theBezelClub
      ? "dark"
      : "light";

  useEffect(() => {
    const noStorePages = ["/auth*", "/login$", "/wallet$", "/gallery$"];
    const pagePath = window.location.href.replace(window.location.origin, "");
    if (
      !noStorePages.find((url) => new RegExp("^" + url, "g").test(pagePath))
    ) {
      if (!authenticated) {
        localStorage.setItem("return_page", pagePath);
      }
    }
  });

  return (
    <div className={coverImage}>
      <Header />
      <Outlet />
      <Footer theme={footerTheme} />
    </div>
  );
}

export default Layout;
