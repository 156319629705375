import * as profileTypes from "../actions/types/profile";
import * as nftTypes from "../actions/types/nft";

const profile = {
  loading: false,
  has_more: false,
  user: null,
  error: null,
};

const updateProfile = {
  loading: false,
  success: false,
  error: null,
};

export const profileReducer = (state = profile, action) => {
  switch (action.type) {
    //* profile data
    case profileTypes.GET_USER_PROFILE_LOADING:
      return { ...state, loading: true };
    case profileTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.user,
        error: null,
      };
    case profileTypes.GET_USER_PROFILE_ERROR:
      return { ...state, loading: false, error: action.error };
    //* update profile images
    case profileTypes.UPDATE_PROFILE_IMAGES_SUCCESS:
      return { ...state, user: Object.assign(state.user, action.payload) };
    //* update profile
    case profileTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: Object.assign(state.user, {
          name: action.payload.name,
          media: action.payload.media,
        }),
      };
    case nftTypes.GET_USER_LIKED_NFTS_ERROR:
      return { ...state, error: action.error };
    //* default
    default:
      return state;
  }
};

export const updatingProfileReducer = (state = updateProfile, action) => {
  switch (action.type) {
    //* update profile images
    case profileTypes.UPDATE_PROFILE_IMAGES_LOADING:
      return { ...state, loading: true };
    case profileTypes.UPDATE_PROFILE_IMAGES_SUCCESS:
      return { ...state, loading: false, success: true };
    case profileTypes.UPDATE_PROFILE_IMAGES_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    //* update profile
    case profileTypes.UPDATE_PROFILE_LOADING:
      return { ...state, loading: true };
    case profileTypes.UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, success: true };
    case profileTypes.UPDATE_PROFILE_ERROR:
      return { ...state, loading: false, success: false, error: action.error };
    default:
      return state;
  }
};
