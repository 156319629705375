import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import wallet from "@assets/icons/copy-wallet.svg";
import CopyWallet from "../components/CopyWallet";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const StylesWrapper = styled.div`
  .owner-label {
    font-size: 16px;
    color: ${(props) => props?.colors?.text};
  }
  .owner-image {
    object-fit: cover;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .owner-name {
    font-size: 16px;
    font-weight: 325;
    margin-inline-start: 10px;
    color: ${(props) => props?.colors?.text};
  }
  .owner-wallet {
    font-size: 16px;
    font-weight: 325;
    margin-inline-end: 5px;
    color: ${(props) => props?.colors?.text};
  }
  .copy-icon {
    margin-bottom: 7px;
  }
`;
const NFTOwners = ({ owners, nft, colors, externalNFT, erc, linkednft }) => {
  const { t } = useTranslation();

  const { account } = useSelector((state) => state.auth);
  const is721 = nft?.token_standard === "721";
  const is1155 = nft?.token_standard === "1155";

  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const [filteredOwners, setFilteredOwners] = useState("");

  useEffect(() => {
    if (is1155) {
      const filteredOwners0 = Object.values(
        owners.reduce(function (id, item) {
          const userId = item.user.name;
          id[userId] = item;
          return id;
        }, {})
      );
      setFilteredOwners(filteredOwners0);
    }
  }, [is1155, owners]);

  return (
    <StylesWrapper colors={colors}>
      <Table borderless>
        <thead>
          <tr>
            <td className="owner-label">{t("user_name")}</td>
            <td className="owner-label">{t("wallet_address")}</td>
          </tr>
        </thead>
        <tbody>
          {" "}
          {linkednft && (
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                {console.log(owners._id, "owners")}
                <Link
                  to={`/profile/${account?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    className="owner-image"
                    alt={account?.name || "Anonymous"}
                    src={account?.profile?.image_url || "/user-placeholder.png"}
                  />
                  <span className="owner-name">
                    {account?.name || "Anonymous"}
                  </span>
                </Link>
              </td>
              <td style={{ verticalAlign: "middle", width: "70%" }}>
                <CopyWallet
                  fullWallet
                  colors={colors}
                  address={owners}
                  id={"546"}
                />
              </td>
            </tr>
          )}
          {(isBezelClub || is721) && (
            <tr>
              <td style={{ verticalAlign: "middle" }}>
                {console.log(owners._id, "owners")}
                <Link
                  to={`/profile/${owners._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <img
                    className="owner-image"
                    alt={owners?.name || "Anonymous"}
                    src={owners?.profile?.image_url || "/user-placeholder.png"}
                  />
                  <span className="owner-name">
                    {owners?.name || "Anonymous"}
                  </span>
                </Link>
              </td>
              <td style={{ verticalAlign: "middle", width: "70%" }}>
                <CopyWallet
                  fullWallet
                  colors={colors}
                  address={owners?.create_wallet || nft?.owner_wallet}
                  id={"5"}
                />
              </td>
            </tr>
          )}
          {is1155 &&
            typeof filteredOwners === "object" &&
            filteredOwners.map((owner, index) => {
              return (
                <tr key={index}>
                  <td style={{ verticalAlign: "middle" }}>
                    <Link
                      to={`/profile/${owner?.user?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div>
                        <img
                          className="owner-image"
                          alt={owner?.user?.name || "Anonymous"}
                          src={
                            owner?.user?.profile?.image_url ||
                            "/user-placeholder.png"
                          }
                        />
                        <span className="owner-name">
                          {owner?.user?.name || "Anonymous"}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td style={{ verticalAlign: "middle", width: "70%" }}>
                    <div className="mt-1">
                      <CopyWallet
                        fullWallet
                        colors={colors}
                        address={
                          owner?.user?.create_wallet || nft?.owner_wallet
                        }
                        id={"6"}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </StylesWrapper>
  );
};

export default NFTOwners;
