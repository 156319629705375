import React, { useState } from "react";
import Routes from "./pages/routing/router";
import ReactGA from "react-ga";
import "moment/locale/ar";
import keys from "./lib/config";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  nftActionsCreators,
  profileActionsCreators,
  userActionsCreators,
  authActionsCreators,
  categoryActionsCreators,
  ethActionsCreators,
  bidActionsCreators,
  walletActionsCreators,
  partnerActionCreators,
  projectActionCreators,
} from "./store";
import { useEffect } from "react";
// import { useWeb3Auth } from "./context/Web3auth";
// import { saveWalletsCreator } from "./store/actions/creators/wallet";
import { useWeb3React } from "@web3-react/core";
import ErrorBoundary from "./ErrorBoundary";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
function FallbackComponent() {
  return <ErrorBoundary />;
}
function App() {
  const dispatch = useDispatch();

  const [gaSent, setGaSend] = useState(false);
  const { getUserCLikedNFTsCreator, getAllNftsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );

  const { clearAnotherWallet } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );

  const { deactivate } = useWeb3React();
  const tagManagerArgs = {
    gtmId: "GTM-W25DT8H",
  };

  const { action } = useSelector((state) => state.auth_actions);
  const { error } = useSelector((state) => state.allError);
  const { account } = useSelector((state) => state.auth);
  const { last_wallet, wallets } = useSelector((state) => state.wallets);
  const { verifyAccount } = bindActionCreators(authActionsCreators, dispatch);

  const { getCategoriesCreator } = bindActionCreators(
    categoryActionsCreators,
    dispatch
  );
  const { getUserProfile } = bindActionCreators(
    profileActionsCreators,
    dispatch
  );

  const { getEthUSDCreator } = bindActionCreators(ethActionsCreators, dispatch);

  const initialApp = () => {
    verifyAccount();
    getCategoriesCreator();
    getEthUSDCreator();
    getAllNftsCreator({}, false);

    if (window.ENV_NAME === "production") {
      ReactGA.initialize({ trackingId: "UA-216466353-1" });
      TagManager.initialize(tagManagerArgs);
      if (gaSent === false) {
        setGaSend(true);
        ReactGA.pageview(window.location.pathname);
      }
    }
  };

  const onDeactivateWallet = () => {
    sessionStorage.close = "true";
    deactivate(true);
    clearAnotherWallet();
  };

  useEffect(() => initialApp(), []);

  useEffect(() => {
    if (account?._id) getUserCLikedNFTsCreator(account._id);
  }, [account]);

  useEffect(() => {
    if (error && action === "import wallet") {
      onDeactivateWallet();
    }
  }, [error, action]);
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog={false}>
      <Routes />
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(App);
