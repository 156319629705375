import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import profileCover from "../assets/images/profile-cover.png";
// import DropDown from "../components/DropDown";
import styled from "styled-components";
import { userActionsCreators } from "../store";

const Wrapper = styled.div`
  .cover-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 451px;
    object-fit: cover;
  }
  .cover-txt {
    position: absolute;
    color: #fff;
    top: 170px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .txt {
    text-align: center;
    font-size: 64px;
    fontweight: 400;
    // line-height: 21px;
  }
  .subtxt {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
  }
  .main {
    width: 100%;
    overflow: none;
    padding-top: 400px;
    padding-bottom: 100px;
  }
  .sort-dropdown {
    margin-inline-end: 55px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 40px;
    }
    .sort-dropdown {
      margin-inline-end: 0px;
    }
  }

  .card {
    max-width: 300px;
    min-width: 300px;
    height: 361px;
    background: #ffffff;
    border: 0.987557px solid #efefef;
    box-shadow: 0px 25.0181px 39.5023px rgba(214, 214, 214, 0.25);
    border-radius: 12px;
    margin-bottom: 40px;
    color: #000;
  }
  .cover-user-img {
    width: 100%;
    height: 45%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .user-img-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 25%;
    display: flex;
    justify-content: center;
  }
  .usr-img {
    width: 136px;
    height: 136px;
    border-radius: 200px;
    border: 2.77083px solid #ededed;
    object-fit: cover;
  }
  .bottom-txt {
    text-align: center;
    margin-top: 68px;
  }
  .usr-name {
    font-weight: 450;
    font-size: 18px;
  }
  .label {
    font-weight: 325;
    font-size: 14px;
    color: #777e90;
  }
`;
const options = [
  { label: "Newly", id: "0" },
  { label: "This Week", id: "1" },
  { label: "This Month", id: "2" },
];
export const AllSellers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading, error, users, has_more } = useSelector(
    (state) => state.all_sellers
  );
  const { getAllSellersCreator } = bindActionCreators(
    userActionsCreators,
    dispatch
  );

  useEffect(() => {
    getAllSellersCreator({ skip: 0, limit: 20 }, false);
  }, []);

  const getData = () =>
    getAllSellersCreator({ skip: users?.length, limit: 20 }, true);

  const loadMore = has_more && users?.length > 0;
  return (
    <Wrapper>
      <Container fluid>
        <img
          className="cover-img"
          src={require("../assets/images/popular_sellers_cover.png")}
        />
        <div className="cover-txt">
          <div className="subtxt">
            {t("EXPLORE TALENTED CREATORS ON NIFTY SOUQ")}
          </div>
          <div className="txt">{t("Creators")}</div>
        </div>

        {/* </div> */}
        <div className="main">
          <div className="d-flex flex-row justify-content-center justify-content-md-end mb-3 sort-dropdown">
            {/* <div style={{ width: 200 }}>
              <DropDown label={t("Newly")} options={options} />
            </div> */}
          </div>
          {error && <p>{error}</p>}

          {loading && users?.length === 0 && <p>{t("Loading...")}</p>}

          {users && users?.length > 0 && (
            <div className="d-flex flex-row justify-content-evenly flex-wrap mx-4">
              {users.map((user, index) => (
                <Link
                  key={user?._id}
                  className="link"
                  to={`/profile/${user?._id}`}
                >
                  <div key={user?._id} className="mx-2 card">
                    <img
                      className="cover-user-img"
                      src={user?.profile?.cover_url || profileCover}
                    />
                    <div className="mx-auto user-img-wrapper">
                      <img
                        className="usr-img"
                        src={
                          user?.profile?.image_url || "/user-placeholder.png"
                        }
                      />
                    </div>
                    <div className="py-2 bottom-txt">
                      <div className="usr-name">{user?.name}</div>
                      <div className="d-flex flex-row justify-content-evenly mt-3">
                        <div>
                          <div>{user?.nfts_count}</div>
                          <div className="label">{t("NFTs")}</div>
                        </div>
                        <div>
                          <div>{user?.collections_count}</div>
                          <div className="label">{t("Collections")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}

          {loadMore && (
            <div className="d-flex justify-content-center align-itemns-center">
              <Button
                disabled={loading}
                onClick={getData}
                className="btn btn-primary gradient"
              >
                <div> {t("Load More")}</div>
              </Button>
            </div>
          )}
        </div>
      </Container>
    </Wrapper>
  );
};
