import React from "react";
import { Accordion } from "react-bootstrap";
import { AccordionProp } from "./components/AccordionProp";
import { Reset } from "./components/Reset";

export const SidebarFilter = ({properties}) => {
  return (
    <div
      style={{
        position: "relative",
        boxShadow: "0px 25.0181px 39.5023px rgba(214, 214, 214, 0.25)",
        borderRadius: 13,
        border: "0.987557px solid #EFEFEF",
        flex: '1 2.8 100%'
      }}
    >
      <Reset />
      <div style={{ padding: "10px" }}>
        <Accordion flush defaultActiveKey={['0']} style={{ border: "none !important" }}>
          {properties && properties?.map((el, i) => (
            <AccordionProp key={Math.random()} prop={el} index={i} />
          ))}
        </Accordion>
      </div>
    </div>
  );
};
