import algosdk, {
  makeApplicationCallTxnFromObject,
  bigIntToBytes,
  decodeAddress,
} from "algosdk";

import { AlgoEnforcer, AlgoMarketplace } from "@config";

/* eslint import/no-webpack-loader-syntax: off */
import enforcer_approvalTeal from "!!raw-loader!./assets/enforcer_approval.teal";
import enforcer_clearTeal from "!!raw-loader!./assets/enforcer_clear.teal";
import marketplace_approvalTeal from "!!raw-loader!./assets/marketplace_approval.teal";
import marketplace_clearTeal from "!!raw-loader!./assets/marketplace_clear.teal";

const ZERO_ADDRESS = algosdk.encodeAddress(new Uint8Array(32));
const iface_enforcer = new algosdk.ABIInterface({ ...AlgoEnforcer.abi });
const iface_marketplace = new algosdk.ABIInterface({ ...AlgoMarketplace.abi });

// Utility function to return an ABIMethod by its name
function getMethodByName(name, iface) {
  const m = iface.methods.find((mt) => {
    return mt.name === name;
  });
  if (m === undefined) throw Error("Method undefined: " + name);
  return m;
}

async function getApproalBytes(client, approvalTeal) {
  return getBasicProgramBytes(client, approvalTeal);
}

async function getClearBytes(client, clearTeal) {
  return getBasicProgramBytes(client, clearTeal);
}

async function getBasicProgramBytes(client, teal) {
  let encoder = new TextEncoder();
  let programBytes = encoder.encode(teal);

  // use algod to compile the program
  const compiledProgram = await client.compile(programBytes).do();
  return new Uint8Array(Buffer.from(compiledProgram.result, "base64"));
}

export async function deployEnforcer(sender, algodClient) {
  // define application parameters
  const from = sender.addr;
  const onComplete = algosdk.OnApplicationComplete.NoOpOC;
  const approvalProgram = await getApproalBytes(
    algodClient,
    enforcer_approvalTeal
  );
  const clearProgram = await getClearBytes(algodClient, enforcer_clearTeal);
  const numLocalInts = 0;
  const numLocalByteSlices = 0;
  const numGlobalInts = 1;
  const numGlobalByteSlices = 2; // for enforcer = 1; for market = 0
  const appArgs = [];

  // get suggested params
  const suggestedParams = await algodClient.getTransactionParams().do();

  // create the application creation transaction
  const createTxn = algosdk.makeApplicationCreateTxn(
    from,
    suggestedParams,
    onComplete,
    approvalProgram,
    clearProgram,
    numLocalInts,
    numLocalByteSlices,
    numGlobalInts,
    numGlobalByteSlices,
    appArgs
  );

  // send the transaction
  console.log("Sending application creation transaction.");
  const signedCreateTxn = createTxn.signTxn(sender.sk);
  console.log("Signed");
  const txFull = await algodClient.sendRawTransaction(signedCreateTxn).do();
  console.log("sent off tx: ", txFull.txId);

  // wait for confirmation
  const completedTx = await verboseWaitForConfirmation(
    algodClient,
    txFull.txId
  );
  console.log("completedTx"); // , completedTx);
  let appId = completedTx["application-index"];
  console.log("Created new app-id: ", appId);
  return appId;
}

export async function deployMarketplace(sender, algodClient) {
  // define application parameters
  const from = sender.addr;
  const onComplete = algosdk.OnApplicationComplete.NoOpOC;
  const approvalProgram = await getApproalBytes(
    algodClient,
    marketplace_approvalTeal
  );
  const clearProgram = await getClearBytes(algodClient, marketplace_clearTeal);
  const numLocalInts = 0;
  const numLocalByteSlices = 0;
  const numGlobalInts = 1;
  const numGlobalByteSlices = 0; // for enforcer = 1; for market = 0
  const appArgs = [];

  // get suggested params
  const suggestedParams = await algodClient.getTransactionParams().do();

  // create the application creation transaction
  const createTxn = algosdk.makeApplicationCreateTxn(
    from,
    suggestedParams,
    onComplete,
    approvalProgram,
    clearProgram,
    numLocalInts,
    numLocalByteSlices,
    numGlobalInts,
    numGlobalByteSlices,
    appArgs
  );

  // send the transaction
  console.log("Sending application creation transaction.");
  const signedCreateTxn = createTxn.signTxn(sender.sk);
  console.log("Signed");
  const txFull = await algodClient.sendRawTransaction(signedCreateTxn).do();
  console.log("sent off tx: ", txFull.txId);

  // wait for confirmation
  const completedTx = await verboseWaitForConfirmation(
    algodClient,
    txFull.txId
  );
  console.log("completedTx"); // , completedTx);
  let appId = completedTx["application-index"];
  console.log("Created new app-id: ", appId);
  return appId;
}

export async function deployNFT(sender, algodClient, enforcerAddress) {
  // Construct the transaction
  let params = await algodClient.getTransactionParams().do();
  // comment out the next two lines to use suggested fee
  params.fee = algosdk.ALGORAND_MIN_TX_FEE;
  params.flatFee = true;

  // define application parameters
  const from = sender.addr;
  const defaultFrozen = true;
  const unitName = "test";
  const assetName = "test";
  const assetURL = "example/url";
  let note = undefined;
  const manager = enforcerAddress;
  const reserve = enforcerAddress;
  const freeze = enforcerAddress;
  const clawback = enforcerAddress;
  let assetMetadataHash = undefined;
  const total = 1;
  const decimals = 0;

  // create the application creation transaction
  const createTxn = algosdk.makeAssetCreateTxnWithSuggestedParams(
    from,
    note,
    total,
    decimals,
    defaultFrozen,
    manager,
    reserve,
    freeze,
    clawback,
    unitName,
    assetName,
    assetURL,
    assetMetadataHash,
    params
  );

  // send the transaction
  console.log("Sending application creation transaction.");
  const signedCreateTxn = createTxn.signTxn(sender.sk);
  console.log("Signed");
  const txFull = await algodClient.sendRawTransaction(signedCreateTxn).do();
  console.log("sent off tx: ", txFull.txId);

  // wait for confirmation
  const completedTx = await verboseWaitForConfirmation(
    algodClient,
    txFull.txId
  );
  console.log("completedTx"); // , completedTx);
  let assetIndex = completedTx["asset-index"];
  console.log("Asset index(nftId): ", assetIndex);
  return completedTx["asset-index"];
}

const stringToBytes = (value) => {
  return new Uint8Array(Buffer.from(value));
};

export async function setEnforcerPolicy(
  algodClient,
  enforcerAppId,
  sender,
  basisPoints,
  royaltyAddress
) {
  console.log("setting enforcer policy...", sender.addr);

  let params = await algodClient.getTransactionParams().do();
  //ToDo:: add note here let note = new TextEncoder().encode(enforcerNote);
  const atc = new algosdk.AtomicTransactionComposer();

  // Method call to drop the asset
  atc.addMethodCall({
    appID: enforcerAppId,
    method: getMethodByName("set_policy", iface_enforcer),
    sender: sender.addr,
    suggestedParams: params,
    methodArgs: [basisPoints, royaltyAddress],
    signer: sender,
  });
  // Dump transaction array
  const completedTx = atc.buildGroup().map((tws) => {
    return tws.txn;
  });

  console.log("completedTx", completedTx);
}

export async function marketplaceListNft(
  algodClient,
  enforcerAppId,
  marketplaceAppId,
  sender,
  assetId,
  amount,
  price
) {
  console.log("listing nft in the market...", sender.addr);

  let params = await algodClient.getTransactionParams().do();
  // params.fee = algosdk.ALGORAND_MIN_TX_FEE;
  // params.flatFee = true;
  const atc = new algosdk.AtomicTransactionComposer();
  const marketPlaceAddress = algosdk.getApplicationAddress(marketplaceAppId);

  // Method call to drop the asset
  atc.addMethodCall({
    appID: enforcerAppId,
    method: getMethodByName("offer", iface_enforcer),
    sender: sender.addr,
    suggestedParams: params,
    methodArgs: [assetId, amount, marketPlaceAddress, 0, ZERO_ADDRESS],
    signer: sender,
  });
  const group = atc.buildGroup();
  const atc1 = new algosdk.AtomicTransactionComposer();
  // console.dir(group)
  // console.log('group <---')
  // Method call to drop the asset
  atc1.addMethodCall({
    appID: marketplaceAppId,
    method: getMethodByName("list", iface_marketplace),
    sender: sender.addr,
    suggestedParams: params,
    methodArgs: [assetId, enforcerAppId, amount, price, group[0]],
    signer: sender,
  });
  // Dump transaction array
  const completedTx = atc1.buildGroup().map((tws) => {
    return tws.txn;
  });

  console.log("completedTx", completedTx);
}

// Create an account and add funds to it. Copy the address off
// The Algorand TestNet Dispenser is located here:
// https://dispenser.testnet.aws.algodev.network/
export const createAccount = function () {
  try {
    const myaccount = algosdk.generateAccount();
    console.log("Account Address = " + myaccount.addr);
    let account_mnemonic = algosdk.secretKeyToMnemonic(myaccount.sk);
    console.log("Account Mnemonic = " + account_mnemonic);
    console.log("Account created. Save off Mnemonic and address");
    console.log("Add funds to account using the TestNet Dispenser: ");
    console.log(
      "https://dispenser.testnet.aws.algodev.network?account=" + myaccount.addr
    );

    return myaccount;
  } catch (err) {
    console.log("err", err);
  }
};

/**
 * utility function to wait on a transaction to be confirmed
 * the timeout parameter indicates how many rounds do you wish to check pending transactions for
 */
export async function waitForConfirmation(algodclient, txId, timeout) {
  // Wait until the transaction is confirmed or rejected, or until 'timeout'
  // number of rounds have passed.
  //     Args:
  // txId(str): the transaction to wait for
  // timeout(int): maximum number of rounds to wait
  // Returns:
  // pending transaction information, or throws an error if the transaction
  // is not confirmed or rejected in the next timeout rounds
  if (algodclient == null || txId == null || timeout < 0) {
    throw new Error("Bad arguments.");
  }
  const status = await algodclient.status().do();
  if (typeof status === "undefined")
    throw new Error("Unable to get node status");
  const startround = status["last-round"] + 1;
  let currentround = startround;

  /* eslint-disable no-await-in-loop */
  while (currentround < startround + timeout) {
    const pendingInfo = await algodclient
      .pendingTransactionInformation(txId)
      .do();
    if (pendingInfo !== undefined) {
      if (
        pendingInfo["confirmed-round"] !== null &&
        pendingInfo["confirmed-round"] > 0
      ) {
        // Got the completed Transaction
        return pendingInfo;
      }

      if (
        pendingInfo["pool-error"] != null &&
        pendingInfo["pool-error"]?.length > 0
      ) {
        // If there was a pool error, then the transaction has been rejected!
        throw new Error(
          `Transaction Rejected pool error${pendingInfo["pool-error"]}`
        );
      }
    }
    await algodclient.statusAfterBlock(currentround).do();
    currentround += 1;
  }
  /* eslint-enable no-await-in-loop */
  throw new Error(`Transaction not confirmed after ${timeout} rounds!`);
}

/**
 * Wait for confirmation — timeout after 2 rounds
 */
export async function verboseWaitForConfirmation(client, txnId) {
  console.log("Awaiting confirmation (this will take several seconds)...");
  const roundTimeout = 2;
  const completedTx = await waitForConfirmation(client, txnId, roundTimeout);
  console.log("Transaction successful.");
  return completedTx;
}
