import React from "react";
import { ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import GalleryDropDown from "../GalleryDropDown";
import { formatDate } from "../../../../../common/utilities/format";
import artistImage from "../../../../../assets/icons/artist-image.jpeg";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const CardList = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { dataList, dropdownConfig, type, onSelect, style } = props;
  const getArtist = (data) => {
    return (
      <div
        className="flex-fill"
        style={{
          minHeight: 120,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          className="profile-image"
          alt="profile-image"
          onClick={() => {
            navigate(`/created-artist/${data._id}`);
          }}
        >
          <img
            style={{ objectFit: "cover" }}
            src={data.profileImage || artistImage}
            width="96px"
            height="96px"
            alt="profileIcon"
          />
        </div>
        <div
          className="text flex-fill"
          onClick={() => {
            navigate(`/created-artist/${data._id}`);
          }}
        >
          <div className="t1">{data.name}</div>
          <div className="t2">
            {data.collectionCounts}{" "}
            {data.collectionCounts <= 1
              ? t("collection")
              : t("collections_count")}
          </div>
          <div className="t3">{formatDate(data.createdAt)}</div>
        </div>
      </div>
    );
  };

  const getGallery = (data) => {
    return (
      <div
        className="flex-fill"
        style={{
          minHeight: 120,
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div className="profile-image" alt="profile-image">
          <img
            style={{ objectFit: "cover" }}
            src={data.profileImage || artistImage}
            width="96px"
            height="96px"
            alt="profileIcon"
            onClick={() => {
              if (type === "gallery")
                navigate(`/gallery/${data._id}/artist/${false}`);
              else if (type === "artist")
                navigate(`/created-artist/${data._id}`);
            }}
          />
        </div>
        <div
          className="text flex-fill py-2"
          onClick={() => {
            if (type === "gallery")
              navigate(`/gallery/${data._id}/artist/${false}`);
            else if (type === "artist") navigate(`/created-artist/${data._id}`);
          }}
        >
          <div className="t1">{data.title}</div>
          <div className="t2">
            {data.artistCount}{" "}
            {data.artistCount <= 1 ? t("artist") : t("artists_count")}
          </div>
          <div className="t3">{formatDate(data.createdAt)}</div>
        </div>
      </div>
    );
  };
  return (
    <ListGroup className="list-group-style" style={style}>
      {dataList &&
        dataList?.length > 0 &&
        dataList.map((data, idx) => (
          <ListGroup.Item className="cursor" key={idx}>
            <div className="list-item-style flex-fill">
              <div className="profile flex-fill">
                {type === "gallery" ? getGallery(data) : getArtist(data)}
              </div>
              <GalleryDropDown
                dropdownConfig={dropdownConfig}
                onClick={(eventKey) => onSelect(eventKey, data)}
              />
            </div>
          </ListGroup.Item>
        ))}
    </ListGroup>
  );
};

export default CardList;
