import { useWeb3React } from '@web3-react/core'
import { injected, walletconnect, CoinbaseWallet, bsc } from './connectors'
import { useWeb3Context } from 'web3-react'
import MetaMaskLogo from '@assets/images/wallets/meta-mask.svg'
import WalletConnect from '@assets/images/wallets/wallet-connect.svg'
import CoinbaseLogo from '@assets/images/wallets/coinbase-wallet.png'
import BinanceLogo from '@assets/images/wallets/bnb.png'

const Wallets = [
    {
        title: 'MetaMask',
        description: ' is connecting to your wallet',
        logo: MetaMaskLogo,
        connector: injected,
    },
    // {
    //     title: 'WalletConnect',
    //     description: 'is connecting to your wallet',
    //     logo: WalletConnect,
    //     connector: walletconnect,
    // },
    {
        title: 'Coinbase',
        description: 'is connecting to your wallet',
        logo: CoinbaseLogo,
        connector: CoinbaseWallet,
    },
    {
        title: 'Binance',
        description: 'is connecting to your wallet',
        logo: BinanceLogo,
        connector: bsc,
    },
]

const ConnectedWallet = () => {
    const { connector } = useWeb3React()
    if (connector) {
        switch (connector) {
            case injected: {
                return {
                    name: 'MetaMask',
                    logo: MetaMaskLogo,
                }
            }
            case walletconnect: {
                return {
                    name: 'WalletConnect',
                    logo: WalletConnect,
                }
            }
            case CoinbaseWallet: {
                return {
                    name: 'Coinbase',
                    logo: CoinbaseLogo,
                }
            }
            case bsc: {
                return {
                    name: 'Binance',
                    logo: BinanceLogo,
                }
            }

            default: 
            return;
            
        }
    } else {
        return {}
    }
}

export { Wallets, ConnectedWallet }
