import { useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './NotificationsDropdown.css'
import unreadNotification from '../../assets/icons/header/unread-notification.svg'

const NotificationDropdown = ({ isDropdown, dropdownLocation }) => {
    const container = useRef(null)
    const { t, i18n } = useTranslation()

    useEffect(() => {
        const dropdown = container.current
        dropdown.style.left = `${dropdownLocation.center - 266}px`
        dropdown.style.top = `${dropdownLocation.bottom}px`
    }, [dropdownLocation])

    return (
        <div
            className={
                isDropdown
                    ? 'notificationsDropdown shadow-lg show'
                    : 'notificationsDropdown shadow-lg'
            }
            ref={container}
        >
            {/* heading */}
            <div className="d-flex justify-content-between px-3 py-4">
                <h3>{t('Notifications')}</h3>
                <Button>{t('See All')}</Button>
            </div>
            {/* notifications */}
            <div>
                {/* notification */}
                <div className="d-flex align-items-center notification">
                    <div className="me-4">
                        <img src="/thumb-3.jpg" alt="" className="img-thumb" />
                    </div>
                    <div className="flex-fill">
                        <h5 className="mb-1">Tokyo Olympics</h5>
                        <h6 className="bm-2">0.08 XTZ Received</h6>
                        <p className="m-0">2 days ago</p>
                    </div>
                    <div>
                        <img
                            src={unreadNotification}
                            alt="unread notification"
                        />
                    </div>
                </div>
                {/* notification */}
                <div className="d-flex align-items-center notification">
                    <div className="me-4">
                        <img src="/thumb-4.jpg" alt="" className="img-thumb" />
                    </div>
                    <div className="flex-fill">
                        <h5 className="mb-1">The Future</h5>
                        <h6 className="bm-2">New Bid 0.2 XTZ</h6>
                        <p className="m-0">3 days ago</p>
                    </div>
                    <div>
                        <img
                            src={unreadNotification}
                            alt="unread notification"
                        />
                    </div>
                </div>
                {/* notification */}
                <div className="d-flex align-items-center notification">
                    <div className="me-4">
                        <img src="/thumb-5.jpg" alt="" className="img-thumb" />
                    </div>
                    <div className="flex-fill">
                        <h5 className="mb-1">Pharoan Calighraphy</h5>
                        <h6 className="bm-2">0.08 XTZ Recived</h6>
                        <p className="m-0">4 days ago</p>
                    </div>
                    <div>
                        <img
                            src={unreadNotification}
                            alt="unread notification"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificationDropdown
