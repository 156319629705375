import React from "react";
import { Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getGalleryNav } from "../../../../common/constants/gallery";
import "./styles.css";

const GalleryNav = ({ status, handleStatusChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderNavItem = (config) => {
    return (
      <li className="nav-item">
        <a
          onClick={(e) => handleStatusChange(e, config.key)}
          className={`nav-link ${status === config.key ? "active" : ""}`}
          aria-current="page"
          href="#"
        >
          {config.text}
        </a>
      </li>
    );
  };

  return (
    <React.Fragment>
      <Col md="10">
        <div className="ga-navs">
          <div>
            <ul className="nav nav-pills">
              {getGalleryNav(t).map((config) => renderNavItem(config))}
            </ul>
          </div>
        </div>
      </Col>
      <Col md="2">
        <div className="ga-navs-btn">
          <Button onClick={() => navigate("/gallery/create")}>
            {t("create_gallery")}
          </Button>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default GalleryNav;
