import React, { useEffect, useState } from "react";
//web3 integraion
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import { Form, Button, Spinner, Image, InputGroup } from "react-bootstrap";
import { Modals } from "./Modals";
import { NIFTYSouq721, NIFTYSouq1155, chains } from "@config";
import { useNavigate } from "react-router-dom";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3Context } from "@context/Web3";
//import "./CreateSingle.css";

//wert
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";

//svg file
import ModalAndOverlay from "@components/ModalAndOverlay";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import visamaster from "@assets/icons/visa-master.png";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { getEditions, extractLoggedInOwner } from "../core/nft";

import { useAddress, useContract } from "../sc/useContract";
import {
  nftActionsCreators,
  walletActionsCreators,
  authActionsCreators,
} from "../store";

import useModal from "../hooks/useModal";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import styled from "styled-components";

const BurnModal = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    font-weight: 500;
    color: ${(props) => props.colors?.text1};
  }

  .note {
    font-size: 24px;
    // line-height: 1.5px;
  }
  .action {
    font-weight: 400;
    color: #6d3190;
  }
  .note1 {
    font-size: 14px;
    margin-top: 20px;
    width: 55%;
  }
  .light-text {
    font-weight: 325;
  }
`;
export const BurnTokenModal = (props) => {
  const auth_account = useSelector((state) => state.auth);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { burnNFTCreator } = bindActionCreators(nftActionsCreators, dispatch);
  const { saveWalletsCreator } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );

  const { nft, error, selectedOwner, externalNFT } = props;
  const is1155 = nft?.token_standard == 1155;

  //contract address
  const [contract, setContract] = useState("");
  //Sale Type
  const { chainId, account } = useWeb3React();
  const { burnToken, burnToken1155 } = useWeb3Context();
  const { userPrincipal, privateKey, getAccounts, chain } = useWeb3Auth();

  //when create token , stauts
  const [status, setStatus] = useState(0);
  //is stauts
  const [isMultiple, setMultiple] = useState(props.status);
  const [isFiat, setFiatPay] = useState(false);

  //basic datas
  const [defaultWallet, setDefaultWallet] = useState(props.defaultWallet);
  const [selectedWallet, setSelectWallet] = useState(props.selectedWallet);
  const [txHash, setTx] = useState("");
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");
  const endDate = 0 * 86400;

  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const [walletBalance, setWalletBalance] = useState(0);

  const owner = nft?.owners?.find(
    (e) => e.user?._id == auth_account?.account?._id
  );
  const [tokenData, setTokenData] = useState({
    name: nft?.name,
    price: "",
    creators: nft?.royalties?.royalties[0]?.creator,
    royalties: nft?.royalties?.royalties.map(function (item) {
      return item["royalty"];
    }),
    amount: is1155
      ? nft?.is_lazy
        ? nft?.editions.total
        : selectedOwner?.unlisted_editions?.length
      : 1,
    tx: "",
    isLazymint: nft?.is_lazy,
    property: "",
    description: nft?.description,
    isList: false,
    isAuction: false,
    startBidPrice: "",
    reservePrice: "",
    auctionPeriod: endDate,
    categories: [],
  });
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC",
    commodity_amount: "0.03",
    address: selectedWallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
        console.log("status", status);
      },
    },
  };

  const wertWidget = new WertWidget(options);

  //burning function
  const burn = async (isFiat) => {
    if (isFiat) {
      wertWidget.mount();
    } else {
      try {
        //burn 721
        if (nft?.token_standard == 721) {
          setStatus(1);
          await burn721();
        }
        if (is1155) {
          setStatus(1);
          await burn1155();
        }
      } catch (e) {
        setStatus(0);
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: e.message,
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };

  const burn721 = async () => {
    if (nft?.is_lazy) {
      const burnData = {
        editions: [],
        tx: "0x00",
        wallet: nft?.owner?.create_wallet,
        payment_method: isBezelClub ? "USDC" : chain,
      };
      burnNFTCreator(nft?._id, nft?.token_standard, burnData);
      setStatus(2);
    } else {
      try {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(chains[chain].rpcTarget)
        );
        console.log(selectedWallet);
        const res = await burnToken(
          nft?.token_id,
          nft?.contract,
          defaultWallet === nft?.owner?.create_wallet
        );
        setTokenData({ ...tokenData, tx: res.data.transactionHash });
        const burnData = {
          editions: [],
          tx: res.data.transactionHash,
          wallet: nft?.owner?.create_wallet,
          payment_method: isBezelClub ? "USDC" : chain,
        };
        console.log(res);
        //Save to backend
        if (res.status) {
          burnNFTCreator(nft?._id, nft?.token_standard, burnData);
          setStatus(2);
          setTx(res.data.transactionHash);
        } else {
          Swal.fire({
            icone: "error",
            title: t("oops"),
            text: t("tx_canceled"),
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }
      } catch (e) {
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: e.message,
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };

  const burn1155 = async () => {
    try {
      const user = await extractLoggedInOwner(
        auth_account.account._id,
        nft?.owners
      );
      const burnEditions = await getEditions(
        [...user?.listed_editions, ...user?.unlisted_editions],
        tokenData.amount
      );
      console.log(burnEditions);
      if (burnEditions?.length === 0) {
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("remove_from_sale"),
        });
        props.closeOfferModal();
      } else {
        if (nft?.is_lazy) {
          burnNFTCreator(nft?._id, nft?.token_standard, {
            editions: burnEditions,
            tx: "0x00",
            wallet: selectedWallet,
            payment_method: chain,
          });
          setStatus(2);
        } else {
          const res = await burnToken1155(
            nft?.token_id,
            tokenData.amount,
            nft?.contract,
            defaultWallet === selectedOwner?.user?.create_wallet
          );

          setTokenData({ ...tokenData, tx: res.data.transactionHash });
          console.log(res);
          //Save to backend
          if (res.status) {
            burnNFTCreator(nft?._id, nft?.token_standard, {
              editions: burnEditions,
              tx: res.data.transactionHash,
              wallet: selectedWallet,
              payment_method: chain,
            });
            setStatus(2);
            setTx(res.data.transactionHash);
          } else {
            Swal.fire({
              icone: "error",
              title: t("oops"),
              text: t("tx_canceled"),
              color: props?.colors?.text,
              background: props?.colors?.bg,
              confirmButtonText: t("Ok"),
              confirmButtonColor: "#6d3190",
            });
          }
        }
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  const dataload = async () => {
    //get default wallet
    const wallet = await getAccounts();
    auth_account.account &&
      wallet.address &&
      !auth_account.account?.wallets.includes(wallet.address) &&
      (await importWalletCreator({ wallet: wallet.address }));
    saveWalletsCreator(0, wallet.address);
    setSelectWallet(
      nft?.token_standard === "721"
        ? nft?.owner?.create_wallet
        : selectedOwner?.user?.create_wallet
    );
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }
    //contrat setting
    setMultiple(props.status);
  };

  useEffect(() => {
    if (privateKey) dataload();
  }, [userPrincipal, privateKey, account, chain, isMultiple]);

  useEffect(() => {
    const interval = setInterval(async () => {
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );
      if (selectedWallet) {
        const exbalance = await web3.eth.getBalance(selectedWallet);
        setWalletBalance(exbalance);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [selectedWallet]);
  const { closeOfferModal } = props;

  return (
    <div>
      {" "}
      <ModalAndOverlay colors={props.colors}>
        <BurnModal colors={props.colors}>
          <div>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading title">{t("Burn with card")}</h3>
              <CloseIcon
                className="modal-close-icon"
                color={props.colors?.text1}
                onClick={() => setFiatPay(false)}
              />
            </div>

            <div id="wert-widget"></div>
            {0.003 <= parseInt(walletBalance) ? (
              <Button
                disabled={status === 0 ? false : true}
                className={`modal-button ${props?.colors?.button}`}
                onClick={() => {
                  setFiatPay(false);
                  burn();
                }}
              >
                {t("Proceed")}
              </Button>
            ) : (
              <Button
                className={`modal-button ${props?.colors?.button}`}
                disabled={true}
              >
                {t("We are depositting your crypto, please wait...")}
              </Button>
            )}
          </div>
        </BurnModal>
      </ModalAndOverlay>
      {!isFiat && (
        <ModalAndOverlay colors={props.colors}>
          <BurnModal colors={props.colors}>
            <Modals>
              <div className="d-flex justify-content-between mb-4">
                <h3 className="modal-heading title">
                  {nft?.is_lazy
                    ? t("delete_nft")
                    : is1155
                    ? t("burn_edition")
                    : t("burn_nft")}
                </h3>
                <CloseIcon
                  className="modal-close-icon"
                  color={props.colors?.text1}
                  onClick={closeOfferModal}
                />
              </div>
              {status === 0 ? (
                <>
                  <div>
                    <div className="note">
                      {t("are_you_sure")}
                      <div className="action">
                        {nft?.is_lazy
                          ? t("delete_nft")
                          : is1155
                          ? t("burn_edition")
                          : t("burn_nft")}
                      </div>
                    </div>
                    {is1155 && !nft?.is_lazy && (
                      <Form.Group className="mt-4 mb-3" controlId="editions">
                        <Form.Label>
                          <div style={{ fontWeight: 400, fontSize: 16 }}>
                            {t("no_of_editions")}
                          </div>
                        </Form.Label>
                        <InputGroup size="md" style={{ width: "50%" }}>
                          <Form.Control
                            min={1}
                            step={1}
                            type="number"
                            value={tokenData.amount}
                            className="create-page-input-field"
                            placeholder={t("amount_placeholder")}
                            onChange={(e) => {
                              setTokenData({
                                ...tokenData,
                                amount: e.target.value,
                              });
                              setSelectWallet(
                                is1155
                                  ? owner?.user?.create_wallet
                                  : nft?.owner?.create_wallet
                              );
                            }}
                            max={selectedOwner?.unlisted_editions?.length || 1}
                          />
                          <InputGroup.Text>
                            {selectedOwner?.unlisted_editions?.length}
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                    )}
                    {/* <div className="note1">
                      {t("note")} :{" "}
                      <span className="light-text">
                        {t("burn_note")}
                        <span> 0.25 Matic.</span>
                      </span>
                    </div> */}
                  </div>
                </>
              ) : status === 2 ? (
                <>
                  <div className="success-message">
                    {nft?.is_lazy
                      ? t("successfully_deleted")
                      : t("successfully_burnt")}
                    <strong className="mx-1">{nft?.name}</strong>
                  </div>{" "}
                  {/* <div className="message p-4">
                    <div className="d-flex table-title mb-3">
                      <span>{t("Status")}</span>
                      <span>{t("Transaction ID")}</span>
                    </div>
                    <div className="d-flex table-row">
                      <span>{t("Success")}</span>
                      <span>
                        <a
                          href={
                            chain === "ETH"
                              ? window.ETH_SCAN + "/" + `${txHash}`
                              : window.POLYGON_SCAN + "/" + `${txHash}`
                          }
                        >
                          {txHash.substring(40)}
                        </a>
                      </span>
                    </div>
                  </div> */}
                </>
              ) : status === 3 ? (
                <>
                  <h4>{t("transaction_failed")} 😢</h4>
                  <div className="message p-4">
                    <div className="d-flex table-row">
                      <span>{error}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mb-4 pb-2">
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      {status === 1 ? (
                        <Spinner animation="border" variant="primary" />
                      ) : status > 1 ? (
                        <Image src={completedIcon}></Image>
                      ) : (
                        <Image src={lockOrderIcon}></Image>
                      )}
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <h5 className="m-0 modal-section-title">
                        <strong>
                          {nft?.is_lazy ? t("Delete") : t("Burn")}
                        </strong>
                      </h5>
                      <p className="m-0 modal-section-text">
                        {t("Call contract method")}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <Button
                className="modal-button mb-3 mt-4"
                disabled={status == 1}
                onClick={() => {
                  if (status == 2) {
                    props.closeOfferModal();
                    navigate(`/profile/${auth_account?.account?._id}`);
                  } else burn();
                }}
              >
                {status === 0
                  ? t("Confirm")
                  : status === 1
                  ? nft?.is_lazy
                    ? t("Deleting")
                    : t("Burning")
                  : status === 2
                  ? t("Done")
                  : t("Failed")}
              </Button>
              {
                nft?.is_lazy ? "" : ""
                // (
                //   <Button
                //     className={`modal-button mb-2  ${props?.colors?.button}`}
                //     id="burn-fiat"
                //     onClick={() => {
                //       setFiatPay(true);
                //       burn(true);
                //     }}
                //   >
                //     {status === 0
                //       ? t("burn_with_card")
                //       : status === 1
                //       ? t("Calling...")
                //       : t("View NFT")}
                //     <img src={visamaster} width="80" height="30" />
                //   </Button>
                // )
              }
              {/* <Button
                className={`modal-button ${props?.colors?.button}`}
                disabled={status === 0 || status === 2 ? false : true}
                onClick={() => props.closeOfferModal()}
              >
                {t("Cancel")}
              </Button> */}
            </Modals>
          </BurnModal>
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default BurnTokenModal;
