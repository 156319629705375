import * as authTypes from "../types/auth";
import * as API from "../../../utils/axios";
import { removeFromCookies, saveCookie } from "../../../core/cookies";

export const loginCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: authTypes.LOGIN_LOADING,
      loading: true,
    });

    API.post("api/user/login", body)
      .then((res) => {
        saveCookie("token", res.data.token);
        dispatch({
          type: authTypes.LOGIN_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: authTypes.LOGIN_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const verifyAccount = (body) => {
  return (dispatch) => {
    dispatch({
      type: authTypes.VERIFY_ACCOUNT_LOADING,
      loading: true,
    });

    API.get("api/user/verify", body)
      .then((res) => {
        dispatch({
          type: authTypes.VERIFY_ACCOUNT_SUCCESS,
          loading: false,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: authTypes.VERIFY_ACCOUNT_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const importWalletCreator = (body) => {
  return (dispatch) => {
    dispatch({
      type: authTypes.IMPORT_WALLET_LOADING,
      loading: true,
    });

    API.post("api/user/wallet/import", body)
      .then((res) => {
        dispatch({
          type: authTypes.IMPORT_WALLET_SUCCESS,
          loading: false,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: authTypes.IMPORT_WALLET_ERROR,
          loading: false,
          error: error.response.data,
        });
      });
  };
};

export const logoutCreator = () => {
  return (dispatch) => {
    localStorage.clear();
    removeFromCookies("token");
    dispatch({
      type: authTypes.LOG_OUT,
    });
  };
};

export const resetProgressCreator = () => {
  return (dispatch) => dispatch({ type: authTypes.RESET_PROGRESS });
};
