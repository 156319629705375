//* place bid
export const PLACE_BID_LOADING = "PLACE_BID_LOADING";
export const PLACE_BID_SUCCESS = "PLACE_BID_SUCCESS";
export const PLACE_BID_ERROR = "PLACE_BID_ERROR";

//* cancel bid
export const CANCEL_BID_LOADING = "CANCEL_BID_LOADING";
export const CANCEL_BID_SUCCESS = "CANCEL_BID_SUCCESS";
export const CANCEL_BID_ERROR = "CANCEL_BID_ERROR";

//* edit bid
export const EDIT_BID_LOADING = "EDIT_BID_LOADING";
export const EDIT_BID_SUCCESS = "EDIT_BID_SUCCESS";
export const EDIT_BID_ERROR = "EDIT_BID_ERROR";

//* get bids
export const GET_NFT_BIDS_LOADING = "GET_NFT_BIDS_LOADING";
export const GET_NFT_BIDS_SUCCESS = "GET_NFT_BIDS_SUCCESS";
export const GET_NFT_BIDS_ERROR = "GET_NFT_BIDS_ERROR";

//buy 1155
export const SET_OWNER_EDITIONS = "SET_OWNER_EDITIONS ";

//* accept bid
export const ACCEPT_NFT_BID_LOADING = "ACCEPT_NFT_BID_LOADING";
export const ACCEPT_NFT_BID_SUCCESS = "ACCEPT_NFT_BID_SUCCESS";
export const ACCEPT_NFT_BID_ERROR = "ACCEPT_NFT_BID_ERROR";

//* get hot bids
export const GET_HOT_BIDS_LOADING = "GET_HOT_BIDS_LOADING";
export const GET_HOT_BIDS_SUCCESS = "GET_HOT_BIDS_SUCCESS";
export const GET_HOT_BIDS_ERROR = "GET_HOT_BIDS_ERROR";
