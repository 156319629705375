import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import profileCover from "@assets/images/profile-cover.png";
import StylesWrapper from "./styles";

const PartnersCollectionsCard = ({ item, small = false }) => {
  const { t } = useTranslation();
  return (
    <StylesWrapper small={small}>
      <Link key={item?._id} className="link" to={`/partners/${item?.contract}`}>
        <div key={item?._id} className="mx-2 card">
          <img
            className="card-cover-img"
            src={item?.cover_url || profileCover}
            alt={item?.name}
          />
          <div className="mx-auto user-img-wrapper">
            <img
              className="user-img"
              src={item?.image_url || "/user-placeholder.png"}
              alt={item?.name}
            />
          </div>
          <div className="d-flex flex-row justify-content-between align-items-center card-text mx-3">
            <div className="card-name">{item?.name}</div>
            <div className="card-label">{`${item?.supply} ${t("NFTs")}`}</div>
          </div>
        </div>
      </Link>
    </StylesWrapper>
  );
};
export default PartnersCollectionsCard;
