import { createContext, useContext } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "@context/Web3auth";
import { Contract, ethers } from "ethers";
import keys from "../lib/config";

import {
  NIFTYSouq721,
  NIFTYSouq1155,
  NIFTYSouqMarketPlace,
  NIFTYSouqFixed,
  NIFTYSouqAuction,
  NIFTYSouqManager,
  Weth,
  Wmatic,
  usdt,
  nsc,
} from "@config";

export const Web3IntegrationProvider = ({ children }) => {
  const { library, account } = useWeb3React();
  const {
    chain,
    cancelListingDefault,
    mintDefault,
    listSaleDefault,
    buyOfferDefault,
    buyOfferUSDTDefault,
    lazyMintDefault,
    lazyMintTokenUSDTDefault,
    mintListDefault,
    listBatchCollectionDefault,
    transferTokenDefault,
    burnTokenDefault,
    transferToken1155Default,
    burnToken1155Default,
    lazyPlaceBidDefault,
    createAuctionDefault,
    mintCreateAuctionDefault,
    placeBidDefault,
    placeHigherBidDefault,
    cancelBidDefault,
    acceptBidDefault,
    acceptHighestBidDefault,
    cancelAuctionDefault,
    editSaleDefault,
    placeOfferDefault,
    changeOfferDefault,
    deleteOfferDefault,
    acceptOfferPriceDefault,
  } = useWeb3Auth();
  const mint = async (data, status) => {
    try {
      if (status) {
        const res = await mintDefault(data);
        return res;
      } else {
        console.log(data);
        const web3 = new Web3(library.provider);
        const NIFTYSouqContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqContract.methods.mintNft(data).send({
            from: account,
            gasPrice: web3.utils.toWei("250", "gwei"),
          });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqContract.methods
            .mintNft(data)
            .send({ from: account });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //List Fixed Price
  const listSale = async (
    tokenId,
    tokenAddress,
    price,
    amount,
    currency,
    status
  ) => {
    try {
      if (status) {
        const res = await listSaleDefault(
          tokenId,
          tokenAddress,
          price,
          amount,
          currency === "USDC" ? "USDC" : ""
        );
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        console.log(gasPricePlus);

        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .sellNft(
              tokenId,
              tokenAddress,
              price,
              amount,
              currency === "USDC" ? "USDC" : ""
            )
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .sellNft(
              tokenId,
              tokenAddress,
              price,
              amount,
              currency === "USDC" ? "USDC" : ""
            )
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //List Batch NFT collection
  const listBatchCollection = async (
    tokenAddress,
    intialTokenId,
    price,
    status
  ) => {
    try {
      if (status) {
        const res = await listBatchCollectionDefault(
          tokenAddress,
          intialTokenId,
          price
        );
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .batchSellNft(tokenAddress, intialTokenId, "USDT", price)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .batchSellNft(tokenAddress, intialTokenId, "USDT", price)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //Mint & list fixed price
  //List Fixed Price
  const mintList = async (mintData, price, currency, status) => {
    console.log(currency);
    const currencyToken = currency === "USDC" ? "USDC" : "";
    try {
      if (status) {
        const res = await mintListDefault(mintData, price, currencyToken);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        console.log(gasPricePlus);
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .mintSellNft(mintData, price, currencyToken)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .mintSellNft(mintData, price, currencyToken)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const editSale = async (offerId, newPrice, status) => {
    try {
      if (status) {
        const res = await editSaleDefault(offerId, newPrice);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .updateSalePrice(offerId, newPrice, "")
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .updateSalePrice(offerId, newPrice, "")
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const cancelListing = async (offerId, status) => {
    try {
      if (status) {
        const res = await cancelListingDefault(offerId);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .cancelSale(offerId)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .cancelSale(offerId)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const buyOffer = async (offerId, amount, price, buyer, status) => {
    try {
      if (status) {
        const res = await buyOfferDefault(offerId, amount, price, buyer);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const value = web3.utils.toWei(price.toString());
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .buyNft(offerId, amount, buyer)
            .send({
              from: account,
              value: value,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .buyNft(offerId, amount, buyer)
            .send({
              from: account,
              value: value,
              gasLimit: window.GAS_LIMIT[chain],
            });
          console.log(res);
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const buyOfferUSDT = async (offerId, amount, price, buyer, status) => {
    try {
      if (status) {
        const res = await buyOfferUSDTDefault(offerId, amount, price, buyer);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
        const NIFTYSouqFixedContract = new web3.eth.Contract(
          NIFTYSouqFixed.abi,
          NIFTYSouqFixed.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        await tusdt.methods
          .approve(
            NIFTYSouqFixed.address[chain],
            ethers.BigNumber.from(2).pow(256).sub(1)
          )
          .send({
            from: account,
            gasLimit: window.GAS_LIMIT[chain],
            gasPrice: web3.utils.toWei("250", "gwei"),
          });

        if (chain === "MATIC") {
          const res = await NIFTYSouqFixedContract.methods
            .buyNft(offerId, amount, buyer)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqFixedContract.methods
            .buyNft(offerId, amount, buyer)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const lazyMintToken = async (
    amount,
    data,
    value,
    currency,
    buyer,
    status
  ) => {
    console.log("----data----", data, value, account, status, amount, buyer);
    try {
      if (status) {
        const res = await lazyMintDefault(amount, data, value, currency, buyer);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        console.log(gasPricePlus);
        if (currency === "USDC") {
          console.log(tusdt);
          const allowance = await tusdt.methods.allowance(
            account,
            NIFTYSouqMarketPlace.address[chain]
          );
          if (parseInt(allowance) < parseInt(value)) {
            chain === "MATIC"
              ? await tusdt.methods
                  .approve(
                    NIFTYSouqMarketPlace.address[chain],
                    ethers.BigNumber.from(2).pow(256).sub(1)
                  )
                  .send({
                    from: account,
                    gasLimit: window.GAS_LIMIT[chain],
                    gasPrice: web3.utils.toWei("250", "gwei"),
                  })
              : await tusdt.methods
                  .approve(
                    NIFTYSouqMarketPlace.address[chain],
                    ethers.BigNumber.from(2).pow(256).sub(1)
                  )
                  .send({
                    from: account,
                    gasLimit: window.GAS_LIMIT[chain],
                  });
          }
        }

        if (chain === "MATIC") {
          const result = await NIFTYSouqMarketPlaceContract.methods
            .lazyMintSellNft(amount, data, buyer)
            .call({
              from: account,
              value: value,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          console.log(result);
          const res =
            currency === "USDC"
              ? await NIFTYSouqMarketPlaceContract.methods
                  .lazyMintSellNft(amount, data, buyer)
                  .send({
                    from: account,
                    gasLimit: window.GAS_LIMIT[chain],
                    gasPrice: web3.utils.toWei("250", "gwei"),
                  })
              : await NIFTYSouqMarketPlaceContract.methods
                  .lazyMintSellNft(amount, data, buyer)
                  .send({
                    from: account,
                    value: value,
                    gasLimit: window.GAS_LIMIT[chain],
                    gasPrice: web3.utils.toWei("250", "gwei"),
                  });
          console.log(res);
          return { status: true, data: { result, res } };
        } else {
          console.log(data);
          const result = await NIFTYSouqMarketPlaceContract.methods
            .lazyMintSellNft(amount, data, buyer)
            .call({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
            });
          console.log(result);
          const res =
            currency === "USDC"
              ? await NIFTYSouqMarketPlaceContract.methods
                  .lazyMintSellNft(amount, data, buyer)
                  .send({
                    from: account,
                    gasLimit: window.GAS_LIMIT[chain],
                  })
              : await NIFTYSouqMarketPlaceContract.methods
                  .lazyMintSellNft(amount, data, buyer)
                  .send({
                    from: account,
                    value: value,
                    gasLimit: window.GAS_LIMIT[chain],
                  });
          console.log(res);
          return { status: true, data: { res, result } };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const lazyMintTokenUSDT = async (data, value, currency, status) => {
    console.log("----data----", data, account, status, value, currency);
    try {
      if (status) {
        const res = await lazyMintTokenUSDTDefault(
          data,
          value,
          currency === "USDC" ? "USDC" : ""
        );
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const tusdt = new web3.eth.Contract(usdt.abi, usdt.address[chain]);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (currency === "USDC") {
          console.log(tusdt);
          await tusdt.methods
            .approve(
              NIFTYSouqMarketPlace.address[chain],
              ethers.BigNumber.from(2).pow(256).sub(1)
            )
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .sellBezelNft(data)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          console.log(data);

          const res =
            currency === "USDC"
              ? await NIFTYSouqMarketPlaceContract.methods
                  .sellBezelNft(data)
                  .send({
                    from: account,
                    gasLimit: window.GAS_LIMIT[chain],
                  })
              : await NIFTYSouqMarketPlaceContract.methods
                  .sellBezelNft(data)
                  .send({
                    from: account,
                    value: value,
                    gasLimit: window.GAS_LIMIT[chain],
                  });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //token trasnfer erc721
  const transferToken = async (to, tokenId,tokenAddress, amount, status) => {
    console.log("s", status);
    try {
      if (status) {
        const res = await transferTokenDefault(to, tokenId, tokenAddress, amount);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const data = await NIFTYSouqMarketPlaceContract.methods
            .transferNFT(account, to, tokenId, tokenAddress, amount)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: data };
        } else {
          const data = await NIFTYSouqMarketPlaceContract.methods
            .transferNFT(account, to, tokenId, tokenAddress, amount)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: data };
        }
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //burn trasnfer erc721
  const burnToken = async (tokenId, contract, status) => {
    try {
      if (status) {
        const res = await burnTokenDefault(tokenId, contract);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqContract = new web3.eth.Contract(
          NIFTYSouq721.abi,
          contract
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const data = await NIFTYSouqContract.methods.burn(tokenId).send({
            from: account,
            gasPrice: web3.utils.toWei("250", "gwei"),
            gasLimit: window.GAS_LIMIT[chain],
          });
          return { status: true, data: data };
        } else {
          const data = await NIFTYSouqContract.methods
            .burn(tokenId)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: data };
        }
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //token trasnfer erc1155
  const transferToken1155 = async (to, tokenId, amount, status) => {
    try {
      if (status) {
        const res = await transferToken1155Default(to, tokenId, amount);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqContract = new web3.eth.Contract(
          NIFTYSouq1155.abi,
          NIFTYSouq1155.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const data = await NIFTYSouqContract.methods
            .transferNft(account, to, tokenId, amount)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: data };
        } else {
          const data = await NIFTYSouqContract.methods
            .transferNft(account, to, tokenId, amount)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: data };
        }
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //burn trasnfer erc1155
  const burnToken1155 = async (tokenId, amount, contract, status) => {
    try {
      if (status) {
        const res = await burnToken1155Default(tokenId, amount, contract);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqContract = new web3.eth.Contract(
          NIFTYSouq1155.abi,
          contract
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const data = await NIFTYSouqContract.methods
            .burn(account, tokenId, amount)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: data };
        } else {
          const data = await NIFTYSouqContract.methods
            .burn(account, tokenId, amount)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: data };
        }
      }
    } catch (e) {
      return { status: false, data: e };
    }
  };

  //List Auction
  const createAuction = async (AuctionData, status) => {
    try {
      if (status) {
        const res = await createAuctionDefault(AuctionData);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const result = await NIFTYSouqAuctionContract.methods
            .createAuction(AuctionData)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          return { status: true, data: result };
        } else {
          const result = await NIFTYSouqAuctionContract.methods
            .createAuction(AuctionData)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: result };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  // Mint List Auction
  const mintCreateAuction = async (mintAuctionData, status) => {
    console.log(status);
    try {
      if (status) {
        const res = await mintCreateAuctionDefault(mintAuctionData);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const result = await NIFTYSouqAuctionContract.methods
            .mintCreateAuctionNft(mintAuctionData)
            .send({
              from: account,
              gasPrice: web3.utils.toWei("250", "gwei"),
              gasLimit: window.GAS_LIMIT[chain],
            });
          console.log(result);
          return { status: true, data: result };
        } else {
          const result = await NIFTYSouqAuctionContract.methods
            .mintCreateAuctionNft(mintAuctionData)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: result };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //end Auction and accept bid
  const acceptBid = async (offerId, bidIdx, status) => {
    console.log(offerId, bidIdx);
    try {
      if (status) {
        const res = await acceptBidDefault(offerId, bidIdx);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId, bidIdx)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId, bidIdx)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //accept hieghest bid
  const acceptHighestBid = async (offerId, status) => {
    try {
      if (status) {
        const res = await acceptHighestBidDefault(offerId);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .endAuction(offerId)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //cancel auction
  const cancelAuction = async (offerId, status) => {
    console.log(status);
    try {
      if (status) {
        const res = await cancelAuctionDefault(offerId);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();

        if (chain === "MATIC") {
          try {
            const res = await NIFTYSouqAuctionContract.methods
              .cancelAuction(offerId)
              .send({
                from: account,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
            console.log(res);
            return { status: true, data: res };
          } catch (error) {
            console.log(error);
          }
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .cancelAuction(offerId)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //Place Bid
  const placeBid = async (offerId, bidPrice, status) => {
    try {
      console.log(offerId, bidPrice, status);
      if (status) {
        const res = await placeBidDefault(offerId, bidPrice);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        console.log(wrapped);
        const checkAllowance = await wrapped.methods
          .allowance(account, NIFTYSouqAuction.address[chain])
          .call({ from: account });
        console.log(checkAllowance);

        if (checkAllowance < bidPrice) {
          chain === "ETH"
            ? await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({ from: account, gasLimit: window.GAS_LIMIT[chain] })
            : await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: account,
                  gasLimit: window.GAS_LIMIT[chain],
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        }

        const weth_balance = await wrapped.methods
          .balanceOf(account)
          .call({ from: account });

        console.log(weth_balance);

        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(bidPrice);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          let deposit = bidPrice;
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .placeBid(offerId, bidPrice)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .placeBid(offerId, bidPrice)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //edit Bid
  const placeHigherBid = async (offerId, bidIdx, amount, status) => {
    try {
      if (status) {
        const res = await placeHigherBidDefault(offerId, bidIdx, amount);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        const checkAllowance = await wrapped.methods
          .allowance(account, NIFTYSouqAuction.address[chain])
          .call({ from: account });
        console.log(checkAllowance);
        if (checkAllowance < amount) {
          const appr = await wrapped.methods
            .approve(
              NIFTYSouqAuction.address[chain],
              ethers.BigNumber.from(2).pow(256).sub(1)
            )
            .call({ from: account });
          console.log(appr);
        }

        const weth_balance = await wrapped.methods
          .balanceOf(account)
          .call({ from: account });

        console.log(weth_balance);

        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(amount);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          let deposit = amount;
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .placeHigherBid(offerId, bidIdx, amount)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .placeHigherBid(offerId, bidIdx, amount)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //Lazy Place Bid
  const lazyPlaceBid = async (lazyMintAuctionData, bidPrice, status) => {
    console.log(lazyMintAuctionData);
    try {
      if (status) {
        const res = await lazyPlaceBidDefault(lazyMintAuctionData, bidPrice);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        const checkAllowance = await wrapped.methods
          .allowance(account, NIFTYSouqAuction.address[chain])
          .call({ from: account, gasLimit: window.GAS_LIMIT[chain] });
        console.log(checkAllowance);
        if (checkAllowance < bidPrice) {
          chain === "ETH"
            ? await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({ from: account, gasLimit: window.GAS_LIMIT[chain] })
            : await wrapped.methods
                .approve(
                  NIFTYSouqAuction.address[chain],
                  ethers.BigNumber.from(2).pow(256).sub(1)
                )
                .send({
                  from: account,
                  gasLimit: window.GAS_LIMIT[chain],
                  gasPrice: web3.utils.toWei("250", "gwei"),
                });
        }

        const weth_balance = await wrapped.methods
          .balanceOf(account)
          .call({ from: account, gasLimit: window.GAS_LIMIT[chain] });

        console.log(weth_balance);
        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(bidPrice);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();

          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          console.log(wrapped);
          console.log("depositting " + bidPrice.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: bidPrice,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: bidPrice,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }

        if (chain === "MATIC") {
          const result = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .call({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          const res = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          console.log(res);

          return { status: true, data: { res, result } };
        } else {
          const result = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .call({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          console.log(result);
          const res = await NIFTYSouqAuctionContract.methods
            .lazyMintAuctionNPlaceBid(lazyMintAuctionData, bidPrice)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: { res, result } };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //cancel Bid
  const cancelBid = async (offerId, bidIdx, bidAmount, status) => {
    console.log(bidIdx);
    try {
      if (status) {
        const res = await cancelBidDefault(offerId, bidIdx, bidAmount);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqAuctionContract = new web3.eth.Contract(
          NIFTYSouqAuction.abi,
          NIFTYSouqAuction.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqAuctionContract.methods
            .cancelBid(offerId, bidIdx)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqAuctionContract.methods
            .cancelBid(offerId, bidIdx)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //Place Offer
  const placeOffer = async (data, value, status) => {
    try {
      console.log(data, status);
      if (status) {
        const res = await placeOfferDefault(data, value);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        const checkAllowance = await wrapped.methods
          .allowance(account, NIFTYSouqMarketPlace.address[chain])
          .call({ from: account });
        console.log(checkAllowance);
        if (checkAllowance < value) {
          const appr = await wrapped.methods
            .approve(
              NIFTYSouqMarketPlace.address[chain],
              ethers.BigNumber.from(2).pow(256).sub(1)
            )
            .call({ from: account });
          console.log(appr);
        }

        const weth_balance = await wrapped.methods
          .balanceOf(account)
          .call({ from: account });

        console.log(weth_balance);

        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(value);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          let deposit = value;
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .makeOffer(data)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .makeOffer(data)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //edit Offer
  const changeOffer = async (offerId, price, status) => {
    try {
      if (status) {
        const res = await changeOfferDefault(offerId, price);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const wrapped =
          chain === "ETH"
            ? new web3.eth.Contract(Weth.abi, Weth.address[chain])
            : new web3.eth.Contract(Wmatic.abi, Wmatic.address[chain]);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        const checkAllowance = await wrapped.methods
          .allowance(account, NIFTYSouqMarketPlace.address[chain])
          .call({ from: account });
        console.log(checkAllowance);
        if (checkAllowance < price) {
          const appr = await wrapped.methods
            .approve(
              NIFTYSouqMarketPlace.address[chain],
              ethers.BigNumber.from(2).pow(256).sub(1)
            )
            .call({ from: account });
          console.log(appr);
        }

        const weth_balance = await wrapped.methods
          .balanceOf(account)
          .call({ from: account });

        console.log(weth_balance);

        const weth_balance_int = parseInt(weth_balance);
        const bid_price = parseInt(price);

        if (weth_balance_int < bid_price) {
          let deposit = (bid_price - weth_balance_int).toString();
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (weth_balance_int > bid_price) {
          console.log(weth_balance);
        }
        if (weth_balance_int === 0) {
          let deposit = price;
          console.log(wrapped);
          console.log("depositting " + deposit.toString());
          chain === "ETH"
            ? await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
              })
            : await wrapped.methods.deposit().send({
                from: account,
                value: deposit,
                gasLimit: window.GAS_LIMIT[chain],
                gasPrice: web3.utils.toWei("250", "gwei"),
              });
        }
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .editOffer(offerId, price)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .editOffer(offerId, price)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //Cancel Offer
  const deleteOffer = async (offerId, status) => {
    try {
      if (status) {
        const res = await deleteOfferDefault(offerId, status);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .cancelOffer(offerId)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .cancelOffer(offerId)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  //Accept Offer
  const acceptOfferPrice = async (offerId, status) => {
    try {
      if (status) {
        const res = await acceptOfferPriceDefault(offerId);
        return res;
      } else {
        const web3 = new Web3(library.provider);
        const NIFTYSouqMarketPlaceContract = new web3.eth.Contract(
          NIFTYSouqMarketPlace.abi,
          NIFTYSouqMarketPlace.address[chain]
        );
        const gasPricePlus = (await web3.eth.getGasPrice() * 1.1).toFixed();
        if (chain === "MATIC") {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .acceptOffer(offerId)
            .send({
              from: account,
              gasLimit: window.GAS_LIMIT[chain],
              gasPrice: web3.utils.toWei("250", "gwei"),
            });
          return { status: true, data: res };
        } else {
          const res = await NIFTYSouqMarketPlaceContract.methods
            .acceptOffer(offerId)
            .send({ from: account, gasLimit: window.GAS_LIMIT[chain] });
          return { status: true, data: res };
        }
      }
    } catch (e) {
      console.log(e);
      return { status: false, data: e };
    }
  };

  const contextProvider = {
    mint,
    listSale,
    cancelListing,
    buyOffer,
    buyOfferUSDT,
    lazyMintToken,
    lazyMintTokenUSDT,
    mintList,
    listBatchCollection,
    transferToken,
    burnToken,
    transferToken1155,
    burnToken1155,
    lazyPlaceBid,
    createAuction,
    mintCreateAuction,
    placeBid,
    placeHigherBid,
    cancelBid,
    acceptBid,
    acceptHighestBid,
    cancelAuction,
    editSale,
    placeOffer,
    changeOffer,
    deleteOffer,
    acceptOfferPrice,
  };
  return (
    <Web3IntegrationContext.Provider value={contextProvider}>
      {children}
    </Web3IntegrationContext.Provider>
  );
};

export const Web3IntegrationContext = createContext({
  mint: async () => {},
  listSale: async () => {},
  cancelListing: async () => {},
  buyOffer: async () => {},
  buyOfferUSDT: async () => {},
  lazyMintToken: async () => {},
  lazyMintTokenUSDT: async () => {},
  mintList: async () => {},
  listBatchCollection: async () => {},
  transferToken: async () => {},
  burnToken: async () => {},
  transferToken1155: async () => {},
  burnToken1155: async () => {},
  lazyPlaceBid: async () => {},
  createAuction: async () => {},
  mintCreateAuction: async () => {},
  placeBid: async () => {},
  placeHigherBid: async () => {},
  cancelBid: async () => {},
  acceptBid: async () => {},
  acceptHighestBid: async () => {},
  cancelAuction: async () => {},
  editSale: async () => {},
  placeOffer: async () => {},
  changeOffer: async () => {},
  deleteOffer: async () => {},
  acceptOfferPrice: async () => {},
});

export function useWeb3Context() {
  return useContext(Web3IntegrationContext);
}
