import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import keys from "../lib/config";

export default function StripeCheckoutForm(props) {
  const { nftId, colors } = props;

  const { t } = useTranslation();

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.FRONT_END_URL}/club/${window.ADDRESSES.BEZEL.ETH}/${nftId}`,
      },
      // redirect: "if_required",
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === "card_error" || error?.type === "validation_error")
      setMessage(error.message);
    else setMessage(t("payment_error"));

    setIsLoading(false);
  };

  return !stripe || !elements ? (
    <div className="d-flex justify-content-center align-items center">
      <Spinner animation="border" color="#fff" />
    </div>
  ) : (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        id="submit"
        disabled={isLoading || !stripe || !elements}
        className={`btn btn-primary modal-button ${colors?.button} mt-4`}
      >
        <span id="button-text">
          {isLoading ? (
            <Spinner animation="border" color="#fff" size="sm" />
          ) : (
            t("Pay now")
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div className="m-2">{message}</div>}
    </form>
  );
}
