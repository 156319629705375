/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import DropDown from "../../../../components/DropDown";
import Price from "@assets/icons/price.svg";
import SearchIcon from "@assets/icons/search-icon.svg";
import ProfileCover from "@assets/images/profile-cover.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StylesWrapper from "./styles";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { projectActionCreators } from "../../../../store";
import { NftCard } from "@components/NftCard";
import { SidebarFilter } from "../../../../components/SidebarFilter";
import { Button } from "reactstrap";

export const PopularPartnerDetails = () => {
  const dispatch = useDispatch();
  const { contract } = useParams();
  const { t, i18n } = useTranslation();
  // const { loading, user, error } = useSelector((state) => state.profile);
  const { loading, nfts, project, error, has_more } = useSelector(
    (state) => state.project
  );

  const { name, price, sale_types, properties, status } = useSelector(
    (state) => state.project_search
  );
  const options = [
    { label: t("no_filter"), id: "0", value: null },
    { label: t("highest_price"), id: "1", value: 1 },
    { label: t("lowest_price"), id: "2", value: -1 },
  ];
  const {
    getProjectDetailsCreator,
    getProjectNftsCreator,
    savePriceFilter,
    saveNameFilter,
    saveContractFilter,
  } = bindActionCreators(projectActionCreators, dispatch);

  useEffect(() => {
    getProjectDetailsCreator(contract, { limit: 9 });
    saveContractFilter(contract);
  }, []);

  useEffect(() => {
    const body = {
      name,
      price,
      sale_types,
      properties,
      status,
      contract,
    };
    const params = {
      skip: 0,
      limit: 9,
    };
    getProjectNftsCreator(body, params, true);
  }, [name, price, sale_types, properties, status]);

  return (
    <StylesWrapper isLTR={i18n.language != "ar"}>
      {/* cover photo */}
      <div className="cover-photo">
        <img
          src={project?.cover_url || ProfileCover}
          className="profile-bg"
          alt=""
        />
      </div>
      <Container>
        {/* profile info */}
        <div className="profile-box mx-3">
          {loading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <div>
              <Row>
                <Col
                  lg={8}
                  xs={12}
                  className="d-flex flex-column flex-lg-row align-items-center"
                >
                  <img
                    alt=""
                    width="114"
                    height="114"
                    className="rounded-full me-3"
                    style={{
                      objectFit: "cover",
                      minWidth: 114,
                      minHeight: 114,
                    }}
                    src={project?.image_url || "/user-placeholder.png"}
                  />
                  <div className="text-center text-lg-start flex-fill">
                    <div className="name">{project?.name}</div>
                    <div className="description mt-2 mb-2 mb-lg-0">
                      {project?.description}
                    </div>
                  </div>
                </Col>
                <Col
                  lg={4}
                  xs={12}
                  className="d-flex justify-content-center justify-content-lg-end align-items-end"
                >
                  <div className="mb-3 d-flex flex-row">
                    <div className="me-4 d-flex flex-column">
                      <div className="value">{project?.supply}</div>
                      <div className="label">{t("Items")}</div>
                    </div>
                    <div className="me-4 d-flex flex-column">
                      <div className="value">{project?.owners}</div>
                      <div className="label">{t("Owners")}</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
        {/* links */}
        <div
          className="pb-3 px-3 pt-4"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-flex flex-row justify-content-between align-items-center mt-2">
            <div style={{ width: 256, height: 48 }}>
              <DropDown
                label={t("Sort by")}
                options={options}
                onOptionSelected={(e) => savePriceFilter(e)}
              />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center justify-content-md-start mb-4 mb-md-0">
            <div className="search-wrapper">
              <input
                placeholder={t("search_nft")}
                value={name}
                className="search-input"
                onChange={(e) => saveNameFilter(e.target.value)}
              />
              <img className="search-icon" src={SearchIcon} />
            </div>
          </div>
        </div>
        {/* sections */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <SidebarFilter properties={project?.properties} />
          <div className="pt-2" style={{ flex: "1 1 100%" }}>
            {nfts?.length > 0 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="d-flex flex-row flex-wrap justify-content-around mx-1">
                  {nfts.map((el) => (
                    <div className="mb-4">
                      <NftCard
                        isProject
                        item={el}
                        userData={{
                          name: project?.name,
                          img: project?.image_url,
                        }}
                      />
                    </div>
                  ))}
                </div>
                {has_more && (
                  <Button
                    style={{
                      borderRadius: 25,
                      border: "none",
                      margin: "0 auto",
                      padding: "10px 20px",
                      background: `linear-gradient(to right, #6D3190, #BC60F1)`,
                    }}
                    onClick={() => {
                      const body = {
                        name,
                        price,
                        sale_types,
                        properties,
                        status,
                        contract,
                      };
                      const params = {
                        skip: nfts?.length,
                        limit: 9,
                      };
                      getProjectNftsCreator(body, params, false);
                    }}
                  >
                    {t("Load More")}{" "}
                    {loading && <Spinner size="sm" style={{ color: "#fff" }} />}{" "}
                  </Button>
                )}
              </div>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center pb-5 mb-5">
                <img
                  width="450"
                  height="450"
                  style={{ objectFit: "contain" }}
                  src={require("@assets/images/empty-section.png")}
                />
                <div className="mt-4 note">{t("There are no NFTs!")}</div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </StylesWrapper>
  );
};
