import * as yup from "yup";

const urlMatches =
  /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9_.#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
export const updateProfileSchema = (t) =>
  yup.object().shape({
    name: yup
      .string()
      .max(50, t("name_max_length"))
      .required(t("name_required")),
    bio: yup.string().max(200, t("bio_max_length")),
    website: yup
      .string()
      .matches(urlMatches, "website must be a valid url")
      .nullable(),
    twitter: yup
      .string()
      .matches(urlMatches, "twitter must be a valid url")
      .nullable()
      .matches("twitter.com", "twitter must be a valid url"),
    instagram: yup
      .string()
      .matches(urlMatches, "instagram must be a valid url")
      .nullable()
      .matches("instagram.com", "instagram must be a valid url"),
    youtube: yup
      .string()
      .matches(urlMatches, "youtube must be a valid url")
      .nullable()
      .matches("youtube.com", "youtube must be a valid url"),
    dribble: yup
      .string()
      .matches(urlMatches, "dribble must be a valid url")
      .nullable(),
    facebook: yup
      .string()
      .matches(urlMatches, "facebook must be a valid url")
      .nullable()
      .matches("facebook.com", "facebook must be a valid url"),
  });

export const listFixedPriceInitialValues = { price: 0 };
export const listAuctionInitialValues = { reservePrice: 0, startBidPrice: 0 };
export const buy1155InitialValues = { amount: 1 };

export const listForSaleFixedPrice = (t) =>
  yup.object().shape({
    price: yup
      .number()
      .positive(t("create-nft.price_positive"))
      .required(t("create-nft.price_required"))
      .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
        (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
      ),
  });
export const listForSaleFixedPrice1155 = (t, max) =>
  yup.object().shape({
    price: yup
      .number()
      .positive(t("create-nft.price_positive"))
      .required(t("create-nft.price_required"))
      .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
        (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
      ),
    amount: yup
      .number()
      .max(max, `Maximum allowed is ${max} editions`)
      .positive(t("create-nft.amount_positive"))
      .integer(t("create-nft.amount_positive"))
      .required(t("create-nft.amount_required")),
  });
export const listForSaleAuction = (t) =>
  yup.object().shape({
    reservePrice: yup
      .number()
      .positive(t("create-nft.price_positive"))
      .required(t("create-nft.price_required"))
      .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
        (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
      ),
    startBidPrice: yup
      .number()
      .positive(t("create-nft.price_positive"))
      .required(t("create-nft.price_required"))
      .test("is-decimal", t("create-nft.price_three_decimals"), (value) =>
        (value + "").match(/^[0-9]{1,11}(?:\.[0-9]{1,3})?$/)
      ),
  });
export const buy1155 = (t, max) =>
  yup.object().shape({
    amount: yup
      .number()
      .max(max, `Maximum allowed is ${max} editions`)
      .positive(t("create-nft.amount_positive"))
      .integer(t("create-nft.amount_positive"))
      .required(t("create-nft.amount_required")),
  });
