/* eslint-disable eqeqeq */
import * as stripeTypes from "../types/stripe";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getClientKey = (body) => {
  return (dispatch) => {
    dispatch({ loading: true, type: stripeTypes.GET_CLIENT_KEY_LOADING });

    API.post(`api/buycrypto/createintent`, body)
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data,
          type: stripeTypes.GET_CLIENT_KEY_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: stripeTypes.GET_CLIENT_KEY_ERROR,
        });
      });
  };
};
export const checkBackendStatus = (body) => {
  return (dispatch) => {
    dispatch({ loading: true, type: stripeTypes.CHECK_BACKEND_STATUS_LOADING });

    API.post(`api/buycrypto/checkpaymentstatus`, body)
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data,
          type: stripeTypes.CHECK_BACKEND_STATUS_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);

        dispatch({
          loading: false,
          error: error.response.data,
          type: stripeTypes.CHECK_BACKEND_STATUS_ERROR,
        });
      });
  };
};
