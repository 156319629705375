/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Row, Col } from "react-bootstrap";
import "./profile.css";
import { useEffect } from "react";
import profileCover from "../assets/images/profile-cover.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import arrowLeft from "../assets/icons/arrow-left.svg";
import { Table } from "react-bootstrap";
import { format, parseISO } from "date-fns";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { creatorCollectionsCreators } from "../store";
export const CollectionActivity = () => {
  const { collectionId } = useParams();
  const { collectionDetail } = useSelector((state) => state.creatorCollections);
  const { collectionActivities } = useSelector(
    (state) => state.creatorCollections
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchCollectionDetailCreator, fetchCollectionActivitiesCreator } =
    bindActionCreators(creatorCollectionsCreators, dispatch);

  const { t } = useTranslation();

  useEffect(() => {
    fetchCollectionDetailCreator(collectionId);
    fetchCollectionActivitiesCreator(collectionId);
  }, []);

  return (
    <div>
      {/* cover photo */}
      <div className="cover-photo">
        <img src={profileCover} className="profile-bg" alt="" />
      </div>
      <Container>
        {/* profile info */}
        <div className="profile-info">
          <Row>
            <Col
              xs={12}
              lg={8}
              className="d-flex flex-column flex-lg-row align-items-center"
            >
              <img
                src={collectionDetail?.logo || "/user-placeholder.png"}
                alt=""
                width="120"
                height="120"
                className="rounded-full me-3 mb-3"
                style={{ objectFit: "cover" }}
              />
              <div className="text-center text-lg-start flex-fill">
                <h3>{collectionDetail?.title}</h3>
                <span style={{ fontWeight: "100" }}>{t("by")}</span>
                <span style={{ fontWeight: "100" }}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/profile/${collectionDetail?.creator?._id}`}
                  >
                    {" "}
                    {collectionDetail?.creator?.name}
                  </Link>
                </span>
                <Link
                  to={`/profile/${collectionDetail?.user?._id}`}
                  className="member-since"
                >
                  {collectionDetail?.user?.name || ""}
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col
              xs={12}
              lg={8}
              className="d-flex flex-column flex-lg-row align-items-center mb-5 mt-5"
            >
              {" "}
              <button
                className="button-icon-grey-40 me-2"
                onClick={() => navigate(-1)}
              >
                <img src={arrowLeft} alt="" />
              </button>
              <div className="text-center text-lg-start member-since mt-3">
                <p style={{ fontSize: "30px", lineHeight: "30px" }}>
                  {t("Activities")}
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="table-container justify-content-center">
          <Table borderless responsive>
            <thead style={{ borderBottom: "1px solid #E6E8EC" }}>
              <tr style={{ fontSize: "18px" }}>
                <th>{t("collection_activities.nftTitle")}</th>
                <th>{t("collection_activities.nftPrice")}</th>
                <th>{t("collection_activities.purchasedOn")}</th>
                <th>{t("collection_activities.createdBy")}</th>
                <th>{t("collection_activities.ownedBy")}</th>
              </tr>
            </thead>

            <tbody>
              {collectionActivities?.length === 0 && (
                <tr>
                  <td>{t("no_activities")}</td>
                </tr>
              )}

              {collectionActivities.map((item, index) => (
                <tr
                  style={{ fontWeight: "100", verticalAlign: "middle" }}
                  key={index.toString()}
                >
                  <Link
                    to={`/nft/${item?.token_standard}/${item?.nftId}`}
                    className="activity_link"
                  >
                    <td>
                      {item.nftType === "image" && (
                        <img
                          alt=""
                          width="20"
                          height="20"
                          src={item?.nftURL}
                          className="my-3 image radius"
                        />
                      )}{" "}
                      <span style={{ marginLeft: 10 }}>{item.nftTitle}</span>
                    </td>
                  </Link>
                  <td>
                    {item.nftOwners?.length > 0 ? item.nftOwners[0].price : "-"}
                  </td>

                  <td>
                    {item.nftOwners?.length > 0
                      ? format(
                          parseISO(item.nftOwners[0].createdAt),
                          "dd MMM yyyy"
                        )
                      : "-"}
                  </td>
                  <td>
                    {" "}
                    <Link
                      to={`/profile/${item.nftCreators._id}`}
                      className="activity_link"
                    >
                      <img
                        alt=""
                        width="20"
                        height="20"
                        src={
                          item?.nftCreators?.profile?.image_url ||
                          "/user-placeholder.png"
                        }
                        className="mx-2"
                        style={{ borderRadius: "50%" }}
                      />
                      {item.nftCreators.name}
                    </Link>
                  </td>
                  <td>
                    {item.nftOwners?.length > 0 && (
                      <Link
                        to={`/profile/${item?.nftOwners[0]?.user?._id}`}
                        className="activity_link"
                      >
                        <img
                          alt=""
                          width="20"
                          height="20"
                          src={item?.nftOwners[0]?.user?.profile?.image_url}
                          className="mx-2"
                          style={{ borderRadius: "50%" }}
                        />
                      </Link>
                    )}
                    {item.nftOwners?.length > 0 ? (
                      <Link
                        to={`/profile/${item?.nftOwners[0]?.user?._id}`}
                        className="activity_link"
                      >
                        {item.nftOwners[0].user.name}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Container>
    </div>
  );
};
