import { TypedDataUtils } from 'ethers-eip712'

export const createAuctionLazyMintDefault = async (
    uri,
    creators,
    royalties,
    investors,
    revenues,
    startTime,
    duration,
    startBidPrice,
    reservePrice,
    signer,
    managerAddress,
    tokenAddress,
    chainId,
    domainName = "NiftySouqMarketplaceManager",
    version = "1.0"
) => {
    const types = {
        EIP712Domain: [
            { name: 'name', type: 'string' },
            { name: 'version', type: 'string' },
            { name: 'chainId', type: 'uint256' },
            { name: 'verifyingContract', type: 'address' }
        ],
        LazyMintAuctionData: [
            { name: 'uri', type: 'string' },
            { name: 'creators', type: 'address[]' },
            { name: 'royalties', type: 'uint256[]' },
            { name: 'investors', type: 'address[]' },
            { name: 'revenues', type: 'uint256[]' },
            { name: 'startTime', type: 'uint256' },
            { name: 'duration', type: 'uint256' },
            { name: 'startBidPrice', type: 'uint256' },
            { name: 'reservePrice', type: 'uint256' }
        ]
    };

    const domain = {
        name: domainName,
        version: version,
        chainId: chainId,
        verifyingContract: managerAddress
    };

    const lazyMintAuctionData = {
        uri,
        creators,
        royalties,
        investors,
        revenues,
        startTime,
        duration,
        startBidPrice,
        reservePrice
    };
    console.log(lazyMintAuctionData)
    const digest = TypedDataUtils.encodeDigest({
        domain,
        types,
        primaryType: 'LazyMintAuctionData',
        message: lazyMintAuctionData
    });
    const signature = await signer.signMessage(digest);

    return {
        ...lazyMintAuctionData,
        signature,
        seller: await signer.getAddress(),
        tokenAddress
    };
};