import styled from "styled-components";
import { useEffect, useState } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "@context/Web3auth";
import {
  Container,
  Row,
  Col,
  Button,
  Stack,
  ButtonGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import wallet from "../../assets/icons/wallet.svg";
import walletWhite from "../../assets/icons/connect-wallet-white.svg";
import rightArrowWhite from "../../assets/icons/right-arrow-white.svg";
import closeIcon from "@assets/images/icons/close-circle.svg";
import ConnectWalletModal from "../../components/Payment/ConnectWalletModal";
import MetaMaskModal from "../../components/Payment/MetaMaskModal";
import RemoveMetaMaskModal from "./RemoveMetaMaskModal";
import keys from "../../lib/config";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { appActionsCreators } from "../../store";
import ModalAndOverlay from "../ModalAndOverlay";
import CopyWallet from "../CopyWallet";
import { InfoTooltip } from "@components";
import { Wallets, ConnectedWallet } from "@config/wallets";

// import { truncate } from "../../core/string";

const Wrapper = styled.div`
  border-bottom: 1px solid #efefef;
  background-color: #fbfbfb;
  padding-top: 18px;
  padding-bottom: 18px;
  .text-grey {
    color: #666666;
  }
  .balance {
    font-size: 30px;
    color: #6d3190;
  }
  .connect-wallet-btn {
    padding: 24px !important;
    display: flex;
    align-items: center;
  }
`;

const WalletInfo = ({
  address,
  balance,
  onConnectWallet,
  AlgoAccount,
  algoBalance,
  wrappedBalance,
  usdtBalance,
}) => {
  const dispatch = useDispatch();
  const { changeChainCreator } = bindActionCreators(
    appActionsCreators,
    dispatch
  );
  const { another_wallet, default_wallet } = useSelector(
    (state) => state.wallets
  );
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [metaMaskModal, setMetaMaskModal] = useState(false);
  const [removeMetaMaskModal, setRemoveMetaMaskModal] = useState(false);
  const { t } = useTranslation();
  const [externalBalance, setExternalBalance] = useState(undefined);
  const {
    account,
    deactivate,
    connector,
    error,
    setError,
    chainId,
    library,
    activate,
    active,
  } = useWeb3React();
  const { chain, onChangeChain, privateKey, getAlgoAccount } = useWeb3Auth();
  const [isKey, setKey] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [accountEmail, setAccountEmail] = useState("");
  const [confirmed, setConfirmed] = useState(false);
  const [loadingBalance, setLoadingBalance] = useState(false);
  useEffect(() => {
    if (chain === "MATIC") {
      changeChainCreator("polygon");
      setLoadingBalance(true);
      if (active) {
        activate();
      }
    } else if (chain === "ETH") {
      changeChainCreator("mainnet");
      setLoadingBalance(true);
      if (active) {
        activate();
      }
    }
  }, [chain]);

  useEffect(() => {
    setLoadingBalance(false);
  }, [balance, algoBalance, externalBalance, wrappedBalance, usdtBalance]);

  const exBalance = async () => {
    if (library) {
      const web3 = new Web3(library?.provider);
      if (account) {
        const exbalance = web3.utils.fromWei(
          await web3.eth.getBalance(account)
        );
        setExternalBalance(exbalance);
      }
    }
  };

  useEffect(() => {
    exBalance();
  });
  return (
    <Wrapper>
      {/* modals */}
      <ConnectWalletModal
        show={connectWalletModal}
        onHide={() => setConnectWalletModal(false)}
      />
      <MetaMaskModal
        show={metaMaskModal}
        onHide={() => setMetaMaskModal(false)}
      />
      <RemoveMetaMaskModal
        show={removeMetaMaskModal}
        onHide={() => setRemoveMetaMaskModal(false)}
      />
      {/*  */}
      <Container>
        <Row>
          <p className="mb-1 text-grey">
            {t("Blockchain")}
            <InfoTooltip text={t("wallet.blockchain_tooltip")} />
          </p>
          <div className="d-flex flex-column flex-md-row mb-2">
            <div>
              <ButtonGroup>
                <Button
                  style={{
                    background: chain !== "MATIC" ? "#6d6e71" : "#6d3190",
                    borderColor: chain !== "MATIC" ? "#6d6e71" : "#6d3190",
                  }}
                  onClick={() => {
                    onChangeChain("MATIC");
                  }}
                >
                  {t("Polygon")}
                </Button>
                <Button
                  style={{
                    background: chain !== "ETH" ? "#6d6e71" : "#6d3190",
                    borderColor: chain !== "ETH" ? "#6d6e71" : "#6d3190",
                  }}
                  onClick={() => {
                    onChangeChain("ETH");
                  }}
                >
                  {t("Ethereum")}
                </Button>
                {window.ENV_NAME !== "production" ? (
                  <Button
                    style={{
                      background: chain !== "BOBA" ? "#6d6e71" : "#6d3190",
                      borderColor: chain !== "BOBA" ? "#6d6e71" : "#6d3190",
                    }}
                    onClick={() => {
                      onChangeChain("BOBA");
                    }}
                  >
                    {t("Boba-BNB")}
                  </Button>
                ) : (
                  ""
                )}
                {/* <Button
                   style={{
                     background: chain !== "BNB" ? "#6d6e71" : "#6d3190",
                     borderColor: chain !== "BNB" ? "#6d6e71" : "#6d3190",
                   }}
                   onClick={() => {
                     onChangeChain("BNB");
                   }}
                 >
                   {t("Binance")}
                 </Button> */}
              </ButtonGroup>
            </div>
            <div className="mx-2 mt-2 mt-md-0">
              <Button
                className="d-flex align-items-center justify-content-lg-end"
                onClick={() => setKey(true)}
              >
                <img src={walletWhite} className="me-2" alt="" />
                <span className="me-2">{t("private_key")}</span>
              </Button>
            </div>
          </div>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={3} style={{ marginTop: "20px" }}>
            <div>
              <p className="mb-1 text-grey">{t("Balance")}</p>
              <p className="balance mb-0">
                {loadingBalance || balance === undefined ? (
                  "--"
                ) : (
                  <>
                    {Math.round(balance * 10000) / 10000} {chain}
                  </>
                )}
              </p>
              {chain === "ETH" || chain === "MATIC" ? (
                <>
                  <p className="mb-1 text-grey">
                    {chain === "ETH" ? t("wETH Balance") : t("wMATIC Balance")}
                  </p>
                  <p className="balance mb-0">
                    {loadingBalance || wrappedBalance === undefined ? (
                      "--"
                    ) : (
                      <>
                        {Math.round(wrappedBalance * 10000) / 10000}{" "}
                        {chain === "ETH" ? "wETH" : "wMATIC"}
                      </>
                    )}
                  </p>
                </>
              ) : (
                ""
              )}

              <p className="mb-1 text-grey">{t("Algo Balance")}</p>
              <p className="balance mb-0">
                {loadingBalance || algoBalance === undefined ? (
                  "--"
                ) : (
                  <>
                    {Math.round((algoBalance / 1000000) * 10000) / 10000}{" "}
                    {"ALGOS"}
                  </>
                )}
              </p>
              <p className="mb-1 text-grey">{t("USDC Balance")}</p>
              <p className="balance mb-0">
                {loadingBalance || usdtBalance === undefined ? (
                  "--"
                ) : (
                  <>
                    {parseFloat(usdtBalance) * 1e12} {"USDC"}
                  </>
                )}
              </p>
              {active && (
                <>
                  <p className="mb-1 text-grey">
                    {t("External wallet balance")}
                  </p>
                  <p className="balance mb-0">
                    {loadingBalance || externalBalance === undefined ? (
                      "--"
                    ) : (
                      <>
                        {Math.round(externalBalance * 10000) / 10000} {chain}
                      </>
                    )}
                  </p>
                </>
              )}
            </div>
          </Col>
          <Col xs={12} sm={6} lg={6} style={{ marginTop: "20px" }}>
            <div>
              <p className="text-grey mb-2">
                {t("NiftySouq wallet address (EVM)")}
              </p>
              <div>
                <p className="text-grey mb-2">{t("Default:")}</p>

                <div className=" d-none d-lg-block">
                  <CopyWallet fullWallet={true} address={address} id={"1"} />
                </div>
                <div className=" d-block d-lg-none">
                  <CopyWallet fullWallet={false} address={address} id={"19"} />
                </div>
                {AlgoAccount ? (
                  <>
                    <p className="text-grey mb-2 mt-4">
                      {t("NiftySouq wallet address (Algorand)")}
                    </p>
                    <div className=" d-none d-lg-block">
                      <CopyWallet
                        fullWallet={true}
                        address={AlgoAccount}
                        id={"3"}
                      />{" "}
                    </div>
                    <div className=" d-block d-lg-none">
                      <CopyWallet
                        fullWallet={false}
                        address={AlgoAccount}
                        id={"39"}
                        phoneView={true}
                      />{" "}
                    </div>
                  </>
                ) : (
                  ""
                )}

                <p className="text-grey mb-2 mt-4">{t("External wallet")}</p>
                <Stack direction="horizontal">
                  {another_wallet ? (
                    <>
                      <div className=" d-none d-lg-block">
                        <CopyWallet
                          fullWallet={true}
                          address={another_wallet}
                          id={"2"}
                        />{" "}
                      </div>
                      <div className=" d-block d-lg-none">
                        <CopyWallet
                          fullWallet={false}
                          address={another_wallet}
                          id={"29"}
                          phoneViewExternalWallet={true}
                        />{" "}
                      </div>
                    </>
                  ) : (
                    t("Disconnected")
                  )}
                </Stack>
                {/* <p className="text-grey mb-2">BNB:</p>

                <Stack direction="horizontal">
                  <span
                    className="me-2 fw-bold"
                    onClick={() => {
                      navigator.clipboard.writeText(account ? account : "");
                    }}
                  >
                    {chainId === 97 ? `${account}` : t("Disconnected")}{" "}
                    {chainId === 97 && <img src={wallet} alt="" />}
                  </span>
                </Stack> */}
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={3}
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0"
          >
            <div>
              <Button
                className="connect-wallet-btn"
                onClick={() => onConnectWallet()}
              >
                <img src={walletWhite} className="me-2" alt="" />
                <span className="me-2">
                  {t(
                    !another_wallet
                      ? t("Connect your wallet")
                      : t("Disconnect your wallet")
                  )}
                </span>
                <img src={rightArrowWhite} alt="" />
              </Button>
              {/* {!AlgoAccount ? <Button
                style={{marginTop: "30px"}}
                className="connect-wallet-btn"
                onClick={() => getAlgoAccount()}
              >
                <img src={walletWhite} className="me-2" alt="" />
                <span className="me-2">
                  {t(
                    !another_wallet
                      ? t("Connect your wallet")
                      : t("Create Algorand Address")
                  )}
                </span>
                <img src={rightArrowWhite} alt="" />
              </Button> : ""} */}
            </div>
          </Col>
        </Row>
      </Container>
      {isKey && (
        <ModalAndOverlay>
          <div>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading">{t("get_private_key")}</h3>
              <img
                className="modal-close-icon"
                src={closeIcon}
                alt="close icon"
                onClick={() => setKey(false)}
              />
            </div>
            <div>
              <p>{t("your_account_private_key")}</p>
              <div className=" d-none d-lg-block">
                <CopyWallet
                  fullWallet={false}
                  address={privateKey}
                  id={"10"}
                  phoneView={false}
                />
              </div>
              <div className=" d-block d-lg-none">
                <CopyWallet
                  fullWallet={false}
                  address={privateKey}
                  id={"109"}
                  phoneView={true}
                />
              </div>
            </div>
          </div>
        </ModalAndOverlay>
      )}
    </Wrapper>
  );
};

export default WalletInfo;
