import styled from "styled-components";

const StylesWrapper = styled.div`
  .owner-label {
    font-size: 16px;
    colors: ${(props) => props?.colors?.text};
  }
  .owner-image {
    object-fit: cover;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .owner-name {
    font-size: 16px;
    font-weight: 325;
    margin-inline-start: 10px;
    colors: ${(props) => props?.colors?.text};
  }
  .owner-status {
    color: #6c2e90;
    font-size: 16px;
    font-weight: 325;
  }
  .owner-text {
    font-size: 16px;
    font-weight: 325;
    margin-inline-end: 5px;
    colors: ${(props) => props?.colors?.text};
  }
  .copy-icon {
    margin-bottom: 7px;
  }
`;
export default StylesWrapper;
