import * as walletTypes from "../actions/types/wallet";

const wallets = { default_wallet: null, another_wallet: null };

export const walletReducer = (state = wallets, action) => {
  switch (action.type) {
    case walletTypes.SAVE_DEFAULT_WALLET:
      return {
        ...state,
        default_wallet: action.wallet,
      };
    case walletTypes.SAVE_ANOTHER_WALLET:
      return {
        ...state,
        another_wallet: action.wallet,
      };
    case walletTypes.CLEAR_ANOTHER_WALLET:
      return { ...state, another_wallet: null };
    default:
      return state;
  }
};
