import * as projectTypes from "../actions/types/project";

const projects = {
  loading: false,
  projects: [],
  error: null,
};

const project = {
  loading: false,
  project: null,
  nfts: [],
  error: null,
  has_more: false,
};

const projectSearch = {
  name: null,
  sale_types: [],
  contract: null,
  status: null,
  properties: [],
  price: null,
};

const nftProjectDetails = {
  loading: false,
  nft: null,
  error: null,
};

export const projectsReducer = (state = projects, action) => {
  switch (action.type) {
    case projectTypes.GET_PROJECTS_LOADING:
      return { ...state, loading: true };
    case projectTypes.GET_PROJECTS_SUCCESS:
      console.log("tttt", action.payload);
      return {
        ...state,
        loading: false,
        projects: action.payload?.length > 0 && action.payload,
        error: null,
      };
    case projectTypes.GET_PROJECTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const projectNftDetailsReducer = (state = nftProjectDetails, action) => {
  switch (action.type) {
    case projectTypes.GET_PROJECT_NFT_DETAILS_LOADING:
      return { ...state, loading: true };
    case projectTypes.GET_PROJECT_NFT_DETAILS_SUCCESS:
      return { ...state, loading: false, nft: action.payload, error: null };
    case projectTypes.GET_PROJECT_NFT_DETAILS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const projectReducer = (state = project, action) => {
  switch (action.type) {
    case projectTypes.GET_PROJECT_DETAILS_LOADING:
      return { ...state, loading: true };
    case projectTypes.GET_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        project: action.payload,
        has_more: action.has_more,
        nfts: action.payload.nfts,
      };
    case projectTypes.GET_PROJECT_NFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        has_more: action.has_more,
        nfts: action.is_new
          ? action.payload
          : [...state.nfts, ...action.payload],
      };
    case projectTypes.GET_PROJECT_DETAILS_ERROR:
      return { ...state, loading: false, error: action.error };
    case projectTypes.GET_PROJECT_NFTS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const projectSearchReducer = (state = projectSearch, action) => {
  switch (action.type) {
    case projectTypes.SAVE_PROJECT_FILTER_CONTRACT:
      return { ...state, contract: action.payload };
    case projectTypes.SAVE_PROJECT_FILTER_NAME:
      return { ...state, name: action.payload };
    case projectTypes.SAVE_PROJECT_FILTER_PRICE:
      return { ...state, price: action.payload };
    case projectTypes.SAVE_PROJECT_FILTER_SALE_TYPES:
      return { ...state, sale_types: [...state.sale_types, action.payload] };
    case projectTypes.SAVE_PROJECT_FILTER_PROPERTIES:
      return { ...state, properties: [...state.properties, action.payload] };
    case projectTypes.REMOVE_PROJECT_FILTER_PROPERTIES:
      return {
        ...state,
        properties: state.properties.filter(
          (el) => el.value !== action.payload.value
        ),
      };
    case projectTypes.REMOVE_PROJECT_FILTER_SALE_TYPES:
      return {
        ...state,
        sale_types: state.sale_types.filter(
          (el) => el.value !== action.payload.value
        ),
      };
    case projectTypes.CLEAR_PROJECT_FILTER:
      return {
        ...state,
        name: null,
        sale_types: [],
        status: null,
        properties: [],
        price: null,
      };
    default:
      return state;
  }
};
