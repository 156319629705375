import { Container, Form, Button, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useState, useRef } from "react";
import keys from "../lib/config";
import Swal from "sweetalert2";

import contactIcon from "../icons/contact-nifty.png";

const Wrapper = styled.div`
  padding-top: 55px;
  padding-bottom: 100px;
  input,
  textarea {
    max-width: 500px;
    border: #e6e8ec solid 2px;
    border-radius: 12px;
    font-weight: 350;
  }
  .label {
    font-size: 14px;
    font-weight: 350;
    text-transform: uppercase;
    color: #b1b5c3;
  }
  .validation {
    color: red;
    font-size: 12px;
    margin-inline-start: 5px;
    margin-top: 5px;
    font-weight: 325;
  }
`;

export const ContactUs = () => {
  const { t } = useTranslation();
  let [valid, setValid] = useState(true);
  let [messageValidation, setMessageValidation] = useState(true);
  let [fileValidation, setFileValidation] = useState(true);
  let [email, setEmail] = useState("");
  let [message, setMessage] = useState("");
  let [selectedFile, setSelectedFile] = useState("");
  const form = useRef(null);

  const handleSubmit = async () => {
    if (validateEmail() && validateMessage() && validateFile()) {
      let formData = new FormData();
      formData.append("email", email);
      formData.append("message", message);
      formData.append("image", selectedFile);
      await axios({
        url: window.API_URL + "/api/user/contactus",
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire({
        icon: "success",
        title: t("Contact Us"),
        text: t("Email Sent Successfully"),
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });

      setEmail("");
      setMessage("");
      setSelectedFile("");
      form.current.reset();
    }
  };
  function fileHandler(event) {
    event.stopPropagation();
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
  }
  const validateEmail = () => {
    const isValid = /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/gi.test(
      `${email?.trim()}`
    );
    setValid(isValid);
    return isValid;
  };
  const validateMessage = () => {
    const isValidForMassege = message?.trim();

    setMessageValidation(isValidForMassege);
    return isValidForMassege;
  };
  const validateFile = (e) => {
    if (selectedFile) {
      let acceptedfile = [
        "image/jpg",
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "audio/mp3",
        "video/mp4",
        "application/pdf",
      ];
      const isValidForFile =
        selectedFile.size < 800000000 &&
        acceptedfile.includes(selectedFile.type);
      setFileValidation(isValidForFile);
      return isValidForFile;
    } else if (selectedFile === "") {
      return true;
    }
  };

  const handelEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail();
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col sm={12} md={6} className="order-2">
            <div className="my-4 page-header">{t("Contact Us")}</div>
            <Form ref={form} autocomplete="off">
              <Form.Group controlId="email" className="mb-4">
                <Form.Label className="label">{t("Email")}</Form.Label>
                <Form.Control
                  type="email"
                  onChange={handelEmailChange}
                  placeholder={t("Enter your email address")}
                />
                <div className="validation">
                  {valid ? "" : t("EmailValidation")}
                </div>
              </Form.Group>
              <Form.Group controlId="message" className="mb-4">
                <Form.Label className="label">{t("Your Message")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={t("Please write your message details")}
                />
                <div className="validation">
                  {messageValidation ? "" : t("message_validation")}
                </div>
              </Form.Group>
              <Form.Control
                type="file"
                className="mb-4"
                accept="image/jpg, image/jpeg, image/png, image/gif, image/webp, audio/mp3, video/mp4,application/pdf"
                onChange={fileHandler}
              />
              <div className="validation">
                {fileValidation ? "" : t("file_validation")}
              </div>
              <Button onClick={handleSubmit}>{t("Submit")}</Button>
            </Form>
          </Col>
          <Col sm={12} className="order-1">
            <img src={contactIcon} alt="" width="555" />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
