import keys from "../lib/config.js";

// import NIFTYSouq721ABI from './NiftySouqToken721.json'
// import NIFTYSouq1155ABI from './NiftySouqToken1155.json'
// import NIFTYSouqMarketPlaceABI from './marketplace.json'

import NIFTYSouq721ABI from "./NiftySouq721.json";
import NIFTYSouq1155ABI from "./NiftySouq1155.json";
import NIFTYSouqMarketPlaceABI from "./NiftySouq.json";
import NIFTYSouqFixedABI from "./NiftySouqFixed.json";
import NIFTYSouqAuctionABI from "./NiftySouqAuction.json";
import AlgoEnforcerABI from './AlgoEnforcer.json';
import AlgoMarketplaceABI from './AlgoMarketplace.json';
import WethABI from "./Weth.json";
import ManagerABI from "./NiftySouqManager.json";
import WmaticABI from "./Wmatic.json";
import usdtABI from "./usdt.json";
import nscABI from "./nsc.json";


export const nsc ={
  address:{
    ETH: window.ADDRESSES.NSC.ETH,
    MATIC: window.ADDRESSES.NSC.MATIC,
    BOBA: window.ADDRESSES.NSC.BOBA,
    BNB: window.ADDRESSES.NSC.BNB,
  },
  abi: nscABI,
};
export const usdt ={
  address:{
    ETH: window.USDC_ETH_ADDRESS,
    MATIC: window.USDC_MATIC_ADDRESS,
  },
  abi: usdtABI,
};
export const Weth ={
  address:{
    ETH: window.WETH_ADDRESS,
  },
  abi: WethABI,
};

export const Wmatic ={
  address:{
    MATIC: window.WMATIC_ADDRESS,
  },
  abi: WmaticABI,
};

export const NIFTYSouqManager ={
  address:{
    ETH: window.ADDRESSES.MANAGER.ETH,
    MATIC: window.ADDRESSES.MANAGER.MATIC,
    BOBA: window.ADDRESSES.MANAGER.BOBA,
    BNB: window.ADDRESSES.MANAGER.BNB,
  },
  abi: ManagerABI,
}

export const NIFTYSouqMarketPlace = {
  address: {
    ETH: window.ADDRESSES.MARKETPLACE.ETH,
    MATIC: window.ADDRESSES.MARKETPLACE.MATIC,
    BOBA: window.ADDRESSES.MARKETPLACE.BOBA,
    BNB: window.ADDRESSES.MARKETPLACE.BNB,
  },
  abi: NIFTYSouqMarketPlaceABI,
};

export const NIFTYSouqFixed = {
  address: {
    ETH: window.ADDRESSES.FIXED.ETH,
    MATIC: window.ADDRESSES.FIXED.MATIC,
    BOBA: window.ADDRESSES.FIXED.BOBA,
    BNB: window.ADDRESSES.FIXED.BNB,
  },
  abi: NIFTYSouqFixedABI,
};

export const NIFTYSouqAuction = {
  address: {
    ETH: window.ADDRESSES.AUCTION.ETH,
    MATIC: window.ADDRESSES.AUCTION.MATIC,
    BOBA: window.ADDRESSES.AUCTION.BOBA,
    BNB: window.ADDRESSES.AUCTION.BNB,
  },
  abi: NIFTYSouqAuctionABI,
};

export const NIFTYSouq721 = {
  address: {
    ETH: window.ADDRESSES.N721.ETH,
    MATIC: window.ADDRESSES.N721.MATIC,
    BOBA: window.ADDRESSES.N721.BOBA,
    BNB: window.ADDRESSES.N721.BNB,
  },
  abi: NIFTYSouq721ABI,
};

export const NIFTYSouq1155 = {
  address: {
    ETH: window.ADDRESSES.N1155.ETH,
    MATIC: window.ADDRESSES.N1155.MATIC,
    BOBA: window.ADDRESSES.N1155.BOBA,
    BNB: window.ADDRESSES.N1155.BNB,
  },
  abi: NIFTYSouq1155ABI,
};

export const AlgoEnforcer = {
  abi: AlgoEnforcerABI
}

export const AlgoMarketplace = {
  abi: AlgoMarketplaceABI
}

export const CONTRACTS = {
  NIFTYSouqMarketPlace,
  NIFTYSouq721,
  NIFTYSouq1155,
  niftysouq: {
    721: {
      address: {
        ETH: window.ADDRESSES.N721.ETH,
        MATIC: window.ADDRESSES.N721.MATIC,
      },
    },
    1155: {
      address: {
        ETH: window.ADDRESSES.N1155.ETH,
        MATIC: window.ADDRESSES.N1155.MATIC,
      },
    },
  },
  rarible: {
    721: {
      address: {
        ETH: "0x5A3Ed919C18137dcC67fBEA707d7E41F3E498BEF",
        MATIC: "0x5A3Ed919C18137dcC67fBEA707d7E41F3E498BEF",
      },
    },
    1155: {
      address: {
        ETH: "0x67A8fE17Db4d441f96f26094677763a2213a3B5f",
        MATIC: "0x67A8fE17Db4d441f96f26094677763a2213a3B5f",
      },
    },
  },
};

export const RealTimePeriod = 5000;

// export const chains = {
//   ETH: {
//     rpcTarget: "https://ropsten.infura.io/v3/21cbdd90ee52445298dc9290c746547b",
//     blockExplorer: "https://ropsten.etherscan.io/",
//     chainId: 3,
//     displayName: "Ethereum Ropsten Testnet RPC",
//     tickerName: "ETH",
//   },
//   MATIC: {
//     rpcTarget:
//       "https://polygon-mumbai.infura.io/v3/21cbdd90ee52445298dc9290c746547b",
//     blockExplorer: "https://mumbai.polygonscan.com/",
//     chainId: 80001,
//     displayName: "Polygon TestNet",
//     tickerName: "Matic",
//   },
// };

export const chains = {
  ETH: {
    rpcTarget: window.RPC_URL_ETH, //"https://mainnet.infura.io/v3/415cb1324810478cacbd6b3449232a39",
    blockExplorer: window.INFURA_BLOCKEXPLORER,
    chainId: window.CHAIN_ID_ETH,
    displayName: window.ETH_NET_NAME,
    tickerName: "ETH",
  },
  MATIC: {
    rpcTarget: window.RPC_URL_MATIC, //"https://polygon.infura.io/v3/415cb1324810478cacbd6b3449232a39",
    blockExplorer: window.INFURA_BLOCKEXPLORER_MATIC,
    chainId: window.CHAIN_ID_MATIC,
    displayName: window.MATIC_NET_NAME,
    tickerName: "Matic",
  },
  BOBA: {
    rpcTarget: window.RPC_URL_BOBA, //"https://mainnet.infura.io/v3/415cb1324810478cacbd6b3449232a39",
    blockExplorer: window.BLOCKEXPLORER_BOBA,
    chainId: window.CHAIN_ID_BOBA,
    displayName: window.BOBA_NET_NAME,
    tickerName: "BOBA",
  },
  BNB: {
    rpcTarget: window.RPC_URL_BNB, //"https://polygon.infura.io/v3/415cb1324810478cacbd6b3449232a39",
    blockExplorer: window.BLOCKEXPLORER_BNB,
    chainId: window.CHAIN_ID_BNB,
    displayName: window.BNB_NET_NAME,
    tickerName: "BNB",
  },
};

// export const web3auth = {
//   net: "testnet",
//   websocketUrl: {
//     ETH: "wss://speedy-nodes-nyc.moralis.io/5dbb9be3647bf224c53c45fc/eth/ropsten/ws",
//     MATIC:
//       "wss://speedy-nodes-nyc.moralis.io/5dbb9be3647bf224c53c45fc/polygon/mumbai/ws",
//   },
// };
export const web3auth = {
  net: window.INFURA_LOGIN_NETWORK,
  websocketUrl: {
    ETH: window.WS_ETH,
    MATIC: window.WS_MATIC,
    BOBA: window.WS_BOBA,
    BNB: window.WS_BNB
  },
};

// export const BACKENDURL = "http://localhost:2003";
//export const BACKENDURL = "http://localhost:2053";
export const BACKENDURL = window.API_URL; //window.API_URL
export const GOOGLE = "google";
export const FACEBOOK = "facebook";
export const REDDIT = "reddit";
export const DISCORD = "discord";
export const TWITCH = "twitch";
export const GITHUB = "github";
export const APPLE = "apple";
export const LINKEDIN = "linkedin";
export const TWITTER = "twitter";
export const WEIBO = "weibo";
export const LINE = "line";
export const EMAIL_PASSWORD = "email_password";
export const PASSWORDLESS = "passwordless";
export const HOSTED_EMAIL_PASSWORDLESS = "hosted_email_passwordless";
export const HOSTED_SMS_PASSWORDLESS = "hosted_sms_passwordless";
export const WEBAUTHN = "webauthn";
export const COGNITO = "cognito";

export const AUTH_DOMAIN = "https://torus-test.auth0.com";
export const COGNITO_AUTH_DOMAIN =
  "https://torus-test.auth.ap-southeast-1.amazoncognito.com/oauth2/";

export const verifierMap = {
  [GOOGLE]: {
    name: "Google",
    typeOfLogin: "google",
    clientId:
      "221898609709-obfn3p63741l5333093430j3qeiinaa8.apps.googleusercontent.com",
    verifier: "google-lrc",
  },
  [FACEBOOK]: {
    name: "Facebook",
    typeOfLogin: "facebook",
    clientId: "617201755556395",
    verifier: "facebook-lrc",
  },
  [REDDIT]: {
    name: "Reddit",
    typeOfLogin: "jwt",
    clientId: "RKlRuuRoDKOItbJSoOZabDLzizvd1uKn",
    verifier: "torus-reddit-test",
  },
  [TWITCH]: {
    name: "Twitch",
    typeOfLogin: "twitch",
    clientId: "f5and8beke76mzutmics0zu4gw10dj",
    verifier: "twitch-lrc",
  },
  [DISCORD]: {
    name: "Discord",
    typeOfLogin: "discord",
    clientId: "682533837464666198",
    verifier: "discord-lrc",
  },
  [EMAIL_PASSWORD]: {
    name: "Email Password",
    typeOfLogin: "email_password",
    clientId: "sqKRBVSdwa4WLkaq419U7Bamlh5vK1H7",
    verifier: "torus-auth0-email-password",
  },
  [APPLE]: {
    name: "Apple",
    typeOfLogin: "apple",
    clientId: "m1Q0gvDfOyZsJCZ3cucSQEe9XMvl9d9L",
    verifier: "torus-auth0-apple-lrc",
  },
  [GITHUB]: {
    name: "Github",
    typeOfLogin: "github",
    clientId: "PC2a4tfNRvXbT48t89J5am0oFM21Nxff",
    verifier: "torus-auth0-github-lrc",
  },
  [LINKEDIN]: {
    name: "Linkedin",
    typeOfLogin: "linkedin",
    clientId: "59YxSgx79Vl3Wi7tQUBqQTRTxWroTuoc",
    verifier: "torus-auth0-linkedin-lrc",
  },
  [TWITTER]: {
    name: "Twitter",
    typeOfLogin: "twitter",
    clientId: "A7H8kkcmyFRlusJQ9dZiqBLraG2yWIsO",
    verifier: "torus-auth0-twitter-lrc",
  },
  [WEIBO]: {
    name: "Weibo",
    typeOfLogin: "weibo",
    clientId: "dhFGlWQMoACOI5oS5A1jFglp772OAWr1",
    verifier: "torus-auth0-weibo-lrc",
  },
  [LINE]: {
    name: "Line",
    typeOfLogin: "line",
    clientId: "WN8bOmXKNRH1Gs8k475glfBP5gDZr9H1",
    verifier: "torus-auth0-line-lrc",
  },
  [HOSTED_EMAIL_PASSWORDLESS]: {
    name: "Hosted Email Passwordless",
    typeOfLogin: "jwt",
    clientId: "P7PJuBCXIHP41lcyty0NEb7Lgf7Zme8Q",
    verifier: "torus-auth0-passwordless",
  },
  [HOSTED_SMS_PASSWORDLESS]: {
    name: "Hosted SMS Passwordless",
    typeOfLogin: "jwt",
    clientId: "nSYBFalV2b1MSg5b2raWqHl63tfH3KQa",
    verifier: "torus-auth0-sms-passwordless",
  },
  [WEBAUTHN]: {
    name: "WebAuthn",
    typeOfLogin: "webauthn",
    clientId: "webauthn",
    verifier: "webauthn-lrc",
  },
  [COGNITO]: {
    name: "Cognito",
    typeOfLogin: "jwt",
    clientId: "78i338ev9lkgjst3mfeuih9tsh",
    verifier: "demo-cognito-example",
  },
};
