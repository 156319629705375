import * as bidTypes from "../actions/types/bid";
import * as socketTypes from "../actions/types/socket";
import * as nftTypes from "../actions/types/nft";

const bidActions = {
  loading: false,
  success: false,
  action: null,
  error: null,
};

const nftBids = {
  loading: false,
  bids: [],
  error: null,
};

const hotBids = {
  loading: false,
  nfts: [],
  error: null,
};
const ownerEditions = {
  owner: null,
};

export const ownerEditionsReducer = (state = ownerEditions, action) => {
  switch (action.type) {
    case bidTypes.SET_OWNER_EDITIONS:
      return { ...state, owner: action.payload };
    default:
      return state;
  }
};

export const nftBidsReducer = (state = nftBids, action) => {
  switch (action.type) {
    //* get bids
    case bidTypes.GET_NFT_BIDS_LOADING:
      return { ...state, loading: true };
    case bidTypes.GET_NFT_BIDS_SUCCESS:
      return { ...state, bids: [...state.bids, action.payload], error: null };
    case bidTypes.GET_NFT_BIDS_ERROR:
      return { ...state, error: action.error };
    //* socket
    case socketTypes.PLACE_BID_SOCKET:
      return { ...state, bids: [action?.payload?.new_bid, ...state.bids] };
    case socketTypes.EDIT_BID_SOCKET:
      return {
        ...state,
        bids: [
          action?.payload?.new_bid,
          ...state.bids.filter((el) => el?._id !== action?.payload?.prev_bid),
        ],
      };
    case socketTypes.CANCEL_BID_SOCKET:
      return {
        ...state,
        bids: [
          ...state.bids.filter((el) => el?._id !== action?.payload?.prev_bid),
        ],
      };
    //* get nft details
    case nftTypes.GET_NFT_DETAILS_SUCCESS:
      return { ...state, bids: action?.payload?.bids || [] };
    default:
      return state;
  }
};

export const bidActionsReducer = (state = bidActions, action) => {
  switch (action.type) {
    //* place bid
    case bidTypes.PLACE_BID_LOADING:
      return { ...state, loading: true };
    case bidTypes.PLACE_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "place",
        error: null,
      };
    case bidTypes.PLACE_BID_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "place",
        error: action.error,
      };
    //* accept bid
    case bidTypes.ACCEPT_NFT_BID_LOADING:
      return { ...state, loading: true };
    case bidTypes.ACCEPT_NFT_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "accept",
        error: null,
      };
    case bidTypes.ACCEPT_NFT_BID_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "accept",
        error: action.error,
      };
    //* edit bid
    case bidTypes.EDIT_BID_LOADING:
      return { ...state, loading: true };
    case bidTypes.EDIT_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "edit",
        error: null,
      };
    case bidTypes.EDIT_BID_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "edit",
        error: action.error,
      };
    //* cancel bid
    case bidTypes.CANCEL_BID_LOADING:
      return { ...state, loading: true };
    case bidTypes.CANCEL_BID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        action: "cancel",
        error: null,
      };
    case bidTypes.CANCEL_BID_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        action: "cancel",
        error: action.error,
      };
    default:
      return state;
  }
};

export const hotBidsReducer = (state = hotBids, action) => {
  switch (action.type) {
    case bidTypes.GET_HOT_BIDS_LOADING:
      return { ...state, loading: true };
    case bidTypes.GET_HOT_BIDS_SUCCESS:
      return { ...state, loading: false, nfts: action.payload, error: null };
    case bidTypes.GET_HOT_BIDS_ERROR:
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};
