import React, { useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import profile from "../../assets/icons/header/profile.svg";
import wallet from "../../assets/icons/header/wallet.svg";
import logOut from "../../assets/icons/header/log-out.svg";
import nightMode from "../../assets/icons/header/night-mode.svg";
import { Form } from "react-bootstrap";
import "./UserDropdown.css";
import { useWeb3Auth } from "@context/Web3auth";
import { AuthContext } from "../../context/AuthContext";

import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authActionsCreators } from "../../store";

const UserDropdown = ({
  isDropdown,
  dropdownLocation,
  userName,
  setIsUserDropdown,
}) => {
  const dispatch = useDispatch();
  const { logoutCreator } = bindActionCreators(authActionsCreators, dispatch);
  const { account } = useSelector((state) => state.auth);
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const { logout } = useWeb3Auth();

  const Logout = async () => {
    authContext.logoutUser();
    setIsUserDropdown(false);
    await logout();
    logoutCreator();
  };

  const container = useRef(null);

  useEffect(() => {
    const dropdown = container.current;
    dropdown.style.left = `${dropdownLocation.center - 200}px`;
    dropdown.style.top = `${dropdownLocation.bottom}px`;
  }, [dropdownLocation]);

  return (
    <div
      className={
        isDropdown ? "userDropdown shadow-lg show" : "userDropdown shadow-lg"
      }
      ref={container}
    >
      {/* header */}
      <div className="mb-3 mt-3">
        <p className="greeting mb-1">{t("Welcome")}</p>
        <h6>{userName}</h6>
      </div>
      {/* links */}
      <ul className="links m-0 p-0">
        <li className="py-3 px-2">
          <Link to={`/profile/${account?._id}`} className="li-item">
            <img className="me-3" src={profile} alt="" />
            <span>{t("Profile")}</span>
          </Link>
        </li>
        <li className="py-3 px-2">
          <Link to="/wallet" className="li-item">
            <img className="me-3" src={wallet} alt="" />
            <span>{t("Wallet")}</span>
          </Link>
        </li>
        {/* <li className="py-3 px-2">
          <Link to="#">
            <img className="me-3" src={settings} alt="" />
            <span>{t("Settings")}</span>
          </Link>
        </li> */}
        <li className="py-3 px-2" onClick={() => Logout()}>
          <a className="cursor-pointer li-item">
            <img className="me-3" src={logOut} alt="" />
            <span>{t("Logout")}</span>
          </a>
        </li>
        <li className="py-3 px-2 d-none">
          <Form>
            <Form.Group
              controlId="theme"
              className="d-flex justify-content-between"
            >
              <Form.Label className="m-0">
                <p className="switch-theme">
                  <img className="me-3" src={nightMode} alt="" />
                  <span>{t("Night Mode")}</span>
                </p>
              </Form.Label>
              <Form.Check type="switch" id="theme" />
            </Form.Group>
          </Form>
        </li>
      </ul>
    </div>
  );
};

export default UserDropdown;
