import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: require("./locales/en/translations.json"),
  },
  ar: {
    translation: require("./locales/ar/translations.json"),
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: [
      "querystring",
      "cookie",
      "localStorage",
      "sessionStorage",
      "path",
      "navigator",
      "htmlTag",
    ],
    resources,
    fallbackLng: "en",
    lookupFromPathIndex: 0,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
