import { Card } from "react-bootstrap";
import styled from "styled-components";
// import purchasing from "../assets/icons/purchasing.svg";
import heart from "../assets/icons/heart-pink.svg";
import { useTranslation } from "react-i18next";
// import { useWeb3Auth } from "../context/Web3auth";
import { Link } from "react-router-dom";
// import NFT_ITEMS from "../data/nftItems.js";
import { truncate } from "../core/string";
// import { useEffect, useState } from "react";
// import axios from "axios";

const Wrapper = styled.div`
  .overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    background-color: #23262f;
    z-index: 10;
  }
  .purchasing .overlay {
    display: flex;
  }
  .time-remaining {
    color: #fcfcfd;
    background-color: #6d3190;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 400;
    font-size: 14px;
    img {
      margin-top: -4px;
    }
  }
  .purchasing-container {
    color: #fcfcfd;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    span {
      background-color: #45b26b;
      padding: 6px 8px;
      border-radius: 4px;
    }
    div {
      background-color: #fcfcfd;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
  .nft-card {
    height: 330px;
    max-height: 330px;
    overflow: hidden;
  }
  .img {
    object-fit: contain;
  }
`;

const NFT = ({
  _id,
  name,
  listing,
  status,
  token_standard,
  stock,
  ntfUrl,
  url,
  type,
  owners,
  editions,
  highestBid,
  creators,
  blockchain,
  className,
  token_id,
  contract_address,
  no_action,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Card className={`collection nft-item ${className}`}>
        {!no_action ? (
          <Link to={`/nft/${token_standard}/${_id}`}>
            <div className="d-flex mb-2">
              <div className="flex-fill nft-name">{truncate(name)}</div>
            </div>
            <div className="position-relative nft-card d-flex align-items-center">
              <div className="overlay flex-column justify-content-between p-3">
                <div className="d-flex justify-content-between purchasing-container">
                  <span>{t("purchasing")}!</span>
                  <div>
                    <img src={heart} alt="" />
                  </div>
                </div>
                {/* <div className="time-remaining justify-content-center align-items-center align-self-center">
                <span className="me-3">2 d : 5 h</span>
                <span>
                  <img src={purchasing} alt="" />
                </span>
              </div> */}
              </div>
              {type === "image" ? (
                <img
                  src={url}
                  width="100%"
                  height="100%"
                  className="rounded-0 nft-image img"
                />
              ) : type === "video" ? (
                <video
                  muted
                  autoPlay
                  loop
                  width="100%"
                  height="100%"
                  className="rounded-0 nft-image img"
                  playsInline
                >
                  <source src={url} type="video/mp4" />
                </video>
              ) : (
                type === "audio" && (
                  <audio
                    controls
                    controlsList="nodownload"
                    width="100%"
                    height="100%"
                    className="rounded-0 nft-image img"
                  >
                    <source src={url} type="audio/mp3" />
                  </audio>
                )
              )}
            </div>

            <Card.Body className="mt-2 p-0">
              <div className="mb-2 mt-1">
                <Link
                  to={`/nft/${token_standard}/${_id}`}
                  className="nft-price"
                >
                  {listing?.price || 0} {blockchain}{" "}
                  {/* <span className="dollar"> | $5000</span> */}
                </Link>
                <div style={{ color: "#000" }}>
                  {status == "PURCHASE" ? (
                    <div>{t("sold")}</div>
                  ) : listing.sale_type === "0" ||
                    status == "CANCEL" ||
                    status == "UNLISTED" ? (
                    <div>{t("Not for sale")}</div>
                  ) : listing.sale_type === "1" ? (
                    <div>{t("Fixed price")}</div>
                  ) : listing.sale_type === "2" ? (
                    <div>{t("Auction")}</div>
                  ) : null}
                </div>
              </div>
              {/* 
  <div className="card-bid d-flex">
   <div className="flex-fill">
     <i className="bid-icon"></i>
     {t("Highest bid")} <b>{highestBid} XTZ</b>
   </div>
   <div>{t("New bid")} 🔥</div>
 </div>  */}
            </Card.Body>
          </Link>
        ) : (
          <Link to={`/linked/${contract_address}/${token_id}`}>
            <div className="d-flex mb-2">
              <Card.Title className="flex-fill nft-name">
                {truncate(name || "???")}
              </Card.Title>
            </div>
            <div className="position-relative nft-card d-flex align-items-center">
              <div className="overlay flex-column justify-content-between p-3">
                <div className="d-flex justify-content-between purchasing-container">
                  <span>{t("purchasing")}!</span>
                  <div>
                    <img src={heart} alt="" />
                  </div>
                </div>
                {/* <div className="time-remaining justify-content-center align-items-center align-self-center">
                <span className="me-3">2 d : 5 h</span>
                <span>
                  <img src={purchasing} alt="" />
                </span>
              </div> */}
              </div>
              {type === "image" ? (
                <img
                  src={url}
                  width="100%"
                  height="100%"
                  className="rounded-0 nft-image img"
                />
              ) : type === "video" ? (
                <video
                  muted
                  autoPlay
                  loop
                  width="100%"
                  height="100%"
                  className="rounded-0 nft-image img"
                  playsInline
                >
                  <source src={url} type="video/mp4" />
                </video>
              ) : (
                type === "audio" && (
                  <audio
                    controls
                    controlsList="nodownload"
                    width="100%"
                    height="100%"
                    className="rounded-0 nft-image img"
                  >
                    <source src={url} type="audio/mp3" />
                  </audio>
                )
              )}
            </div>
          </Link>
        )}

        <Card.Body className="mt-2 p-0">
          {/* <div className="mb-2 mt-1">
            <Link to={`/nft/${_id}`} className="nft-price">
              {listing?.price || 0} {chain}{" "}
              <span className="dollar"> | $5000</span>
            </Link>

            {listing.sale_type === "0" ? (
              <div>{t("Not for sale")}</div>
            ) : listing.sale_type === "1" ? (
              <div>{t("Fixed price")}</div>
            ) : (
              <div>{t("Auction")}</div>
            )}
          </div> */}

          {/* <div className="card-bid d-flex">
            <div className="flex-fill">
              <i className="bid-icon"></i>
              {t("Highest bid")} <b>{highestBid} XTZ</b>
            </div>
            <div>{t("New bid")} 🔥</div>
          </div> */}
        </Card.Body>
      </Card>
    </Wrapper>
  );
};

export default NFT;
