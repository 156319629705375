import React from 'react'
import { useState, useEffect } from 'react'

// context
export const AuthContext = React.createContext({
    user: {},
    loginUser: (user) => {},
    logoutUser: () => {},
})

// provider
const AuthProvider = (props) => {
    const [user, setUser] = useState({})

    useEffect(() => {
        const fetchUserFromLocalStorage = async () => {
            const user = await JSON.parse(localStorage.getItem('user'))
            if (user && user.data && user.data.email) {
                setUser({ ...user })
            }
        }
        fetchUserFromLocalStorage()
    }, [])

    const loginUser = (user) => {
        localStorage.setItem('user', JSON.stringify(user))
        setUser({ ...user })
    }

    const logoutUser = () => {
        setUser({})
        localStorage.removeItem('user')
    }

    const authContext = {
        user,
        loginUser,
        logoutUser,
    }

    return (
        <AuthContext.Provider value={authContext}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
