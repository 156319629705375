import React, { useEffect } from "react";
import { NFT } from "../pages/NFT";
import { useParams } from "react-router-dom";
import { saveCookie, removeFromCookies } from "../core/cookies";
//store
import { bindActionCreators } from "redux";
import { nftActionsCreators } from "../store";
import { useSelector, useDispatch } from "react-redux";
import PageNotFound from "../components/PageNotFound";

export const NFTDetails = () => {
  const dispatch = useDispatch();
  const { nft_id, erc } = useParams();
  const { getNFTDetailsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );
  const { loading, nft, error, error_status } = useSelector(
    (state) => state.nft_details
  );
  console.log("error", error_status);
  const getData = () => {
    getNFTDetailsCreator(nft_id, erc);
  };
  useEffect(() => {
    const pagePath = window.location.href.replace(window.location.origin, "");
    const pageObj = pagePath.split("/");
    if (pageObj[1] === "nft" || pageObj[1] === "club") {
      removeFromCookies("return_page");
      saveCookie("return_page", pagePath);
    }
    getData();
  }, []);
  if (error_status === 404 || error_status === 406) {
    return <PageNotFound />;
  } else {
    return <NFT loading={loading} error={error} nft={nft} refresh={getData} />;
  }
};
