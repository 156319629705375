import * as transactionsTypes from '../actions/types/transactions'

const transactions = {
  loading: false,
  transactions: [],
  error: null,
}

export const transactionReducer = (state = transactions, action) => {
  switch (action.type) {
    case transactionsTypes.GET_TRANSACTIONS_LOADING:
      return { ...state, loading: true }
    case transactionsTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        transactions: action.payload,
        error: null,
      }
    case transactionsTypes.GET_TRANSACTIONS_ERROR:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
