import styled from "styled-components";

const StylesWrapper = styled("div")`
  .name {
    font-size: 42px;
    color: ${(props) => props?.colors?.text1};
  }
  .label {
    font-size: 18px;
    color: ${(props) => props?.colors?.text1};
  }
  .value {
    color: ${(props) => props?.colors?.label};
  }
  .editions {
    color: #777e91;
    border: #e6e8ec solid 2.17px;
    border-radius: 5px;
    padding: 5px;
    display: inline;
  }
  .price {
    font-size: 32px;
    font-weight: 400;
    color: #6d3190;
  }
  .chain {
    font-size: 32px;
    font-weight: 325;
    margin-left: 8px;
  }
  .price-usd {
    font-size: 20px;
    color: #808080;
  }
  .slash {
    width: 1px;
    height: 35px;
    background-color: #6d3190;
    margin-left: 10px;
    margin-right: 10px;
  }
  .note {
    color: #6d3190;
    font-weight: 400;
    text-transform: uppercase;
  }
`;
export default StylesWrapper;
