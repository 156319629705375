import React from "react";
import { Modal } from "react-bootstrap";

import arrowRight from "../../../../../../assets/icons/arrow-right-white.svg";
import "./styles.scss";

const ConfirmationModal = (props) => {
  const {
    isOpen,
    title,
    onHideClick,
    onConfirmClick,
    text,
    submitBtnText,
    isBtnIconEnable,
  } = props;

  return (
    <Modal
      show={isOpen}
      onHide={onHideClick}
      contentClassName="contentClass"
      dialogClassName="dialogClass"
      centered
    >
      {title && (
        <Modal.Header closeButton className="md-header" onClick={onHideClick}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="body">
        <p>{text}</p>
        {onConfirmClick && (
          <button
            type="button"
            className="btn btn-primary btn-custom"
            onClick={onConfirmClick}
          >
            <span className="create-gallery-button">{submitBtnText}</span>
            {isBtnIconEnable && <img src={arrowRight} alt="rightArrowIcon" />}
          </button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
