import React, { useState, useEffect } from "react";

// ** Web3 React
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from "@web3-react/walletconnect-connector";
import { Wallets, ConnectedWallet } from "@config/wallets";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";

import { useTranslation } from "react-i18next";

// Import Icons
import { BsWallet } from "react-icons/bs";

import { walletconnect } from "@config/connectors";
import { useEagerConnect, useInactiveListener } from "@library";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { chains } from "@config/index";
import { useWeb3Auth } from "@context/Web3auth";
import {
  Button,
  Image,
  ListGroup,
  Modal,
  Spinner,
  Stack,
} from "react-bootstrap";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { walletActionsCreators, authActionsCreators } from "../store";

const WalletModal = ({ isOpen, setIsOpen, colors }) => {
  const dispatch = useDispatch();
  const { saveWalletsCreator, clearAnotherWallet } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );

  const auth_action = useSelector((state) => state.auth_actions);

  const [newWallet, setNewWallet] = useState(null);

  const account_auth = useSelector((state) => state.auth);
  // const { authenticated, account } = useSelector((state) => state.auth);

  const triedEager = useEagerConnect();
  const { chain } = useWeb3Auth();
  const {
    activate,
    active,
    account,
    deactivate,
    connector,
    error,
    setError,
    chainId,
    isActivating,
  } = useWeb3React();
  const [activatingConnector, setActivatingConnector] = useState(false);
  const [isSelectingWallet, setIsSelectingWallet] = useState(true);
  const [walletError, setWalletError] = useState("");
  const { t, i18n } = useTranslation();
  const cWallet = ConnectedWallet();
  // ** Actions
  const onConnectWallet = async (item) => {
    console.log({ connector_item: item });
    setActivatingConnector(item.connector);
    setIsSelectingWallet(false);
    sessionStorage.close = false;
    await activate(item.connector);
  };

  const onDeactiveWallet = () => {
    sessionStorage.close = "true";
    setIsSelectingWallet(true);
    deactivate(true);
    clearAnotherWallet();
  };

  const saveWallets = () => {
    if (account) {
      account_auth.account &&
        !account_auth.account?.wallets.includes(account) &&
        importWalletCreator({ wallet: account });
      saveWalletsCreator(1, account);
      setWalletError(account);
    }
  };
  useEffect(() => saveWallets(), [account]);

  const retryConnect = async () => {
    setError(null);
    if (window.ethereum) {
      try {
        await window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${chains[chain].chainId.toString(16)}` }],
          })
          .then(() => {})
          .catch((error) => {});
      } catch (switchError) {
        try {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${chains[chain].chainId.toString(16)}`,
                  chainName: chains[chain].displayName,
                  rpcUrls: [chains[chain].rpcTarget],
                  nativeCurrency: {
                    name: chains[chain].tickerName,
                    symbol: chains[chain].tickerName,
                    decimals: 18,
                  },
                  blockExplorerUrls: [chains[chain].blockExplorer],
                },
              ],
            })
            .then(() => {
              // alert(
              //   `You have successfully changed ${chains[chain].displayName}.`,
              //   "info"
              // );
            })
            .catch((error) => {
              // alert(error.toString(), "error");
            });
        } catch (addError) {
          // handle "add" error
        }
      }
    }
  };

  const changeWallet = (error) => {
    if (!error) {
      return true;
    } else {
      setError(null);
      setIsSelectingWallet(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const getErrorMessage = (error) => {
    console.log(error);
    if (error instanceof NoEthereumProviderError) {
      return t(
        "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
      );
    } else if (error instanceof UnsupportedChainIdError) {
      return t("You're connected to an unsupported network.");
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return t(
        "Please authorize this website to access your Ethereum account."
      );
    } else {
      console.error(error);
      return t("unknown_error");
    }
  };

  useInactiveListener(!triedEager);
  // ** Effects
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // log the walletconnect URI
  useEffect(() => {
    const logURI = (uri) => {
      console.log("WalletConnect URI", uri);
    };

    walletconnect.on(URI_AVAILABLE, logURI);

    return () => {
      walletconnect.off(URI_AVAILABLE, logURI);
    };
  }, []);

  useEffect(() => {
    const load = async () => {
      if (Number(chainId) === Number(chains[chain].chainId)) {
      } else {
        await retryConnect();
      }
    };
    if (chain) {
      load();
    }
  }, [chain]);

  useEffect(() => {
    if (auth_action?.error && auth_action?.action === "import wallet") {
      onDeactiveWallet();
    }
  }, []);

  return (
    <Modal show={isOpen} onHide={handleClose}>
      <div
        style={{
          color: colors?.text,
          backgroundColor: colors?.bg,
        }}
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            {!active ? t("Select Wallet") : t("Your Account")}
          </Modal.Title>
          <CloseIcon className="modal-close-icon" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {active && (
            <ListGroup>
              <ListGroup.Item
                style={{
                  backgroundColor: colors?.bg,
                  color: colors?.text,
                }}
              >
                <Stack
                  direction="horizontal"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Image
                    src={cWallet.logo}
                    width={50}
                    height={50}
                    alt={cWallet.name}
                  ></Image>
                  <div>{t("connected_to") + " " + t(cWallet.name)} </div>
                  <Button
                    variant="danger"
                    onClick={() => {
                      onDeactiveWallet();
                      window.location.reload();
                    }}
                  >
                    {t("Disconnect wallet")}
                  </Button>
                </Stack>
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  backgroundColor: colors?.bg,
                  color: colors?.text,
                }}
              >
                <Stack direction="horizontal">
                  <BsWallet />
                  <div className="mx-2">{`${account.substring(
                    0,
                    8
                  )} ... ${account.substring(account?.length - 4)}`}</div>
                </Stack>

                {/* <ListItemSecondaryAction className="action">
                <Link
                  href={`https://mumbai.polygonscan.com/address/${account}`}
                  target="_blank"
                  underline="none"
                >
                  <Tooltip arrow title="View on explorer">
                    <IconButton size="small">
                      <LaunchRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </Link>
                <CopyToClipboard text={account}>
                  <Tooltip arrow title="Copy address">
                    <IconButton size="small">
                      <AssignmentTurnedInRoundedIcon />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </ListItemSecondaryAction> */}
              </ListGroup.Item>
            </ListGroup>
          )}
          {!active &&
            (() => {
              if (!isActivating) {
                return (
                  <ListGroup className="wallet-list">
                    {Wallets.map((item, idx) => {
                      return (
                        <ListGroup.Item
                          key={idx}
                          style={{
                            color: colors?.text,
                            backgroundColor: colors?.bg,
                          }}
                          onClick={() => onConnectWallet(item)}
                        >
                          <Stack direction="horizontal" gap={2}>
                            <Image
                              src={item.logo}
                              width={50}
                              height={50}
                              alt={item.logo}
                            ></Image>
                            <h5> {t(item.title)} </h5>
                          </Stack>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                );
              } else if (isActivating) {
                const activating = Wallets.find(
                  (item) =>
                    item.connector === activatingConnector ||
                    item.connector === connector
                );
                return (
                  <ListGroup>
                    <ListGroup.Item
                      style={{
                        backgroundColor: colors?.bg,
                        color: colors?.text,
                      }}
                    >
                      <Stack direction="horizontal" gap={3}>
                        {error ? (
                          <>{t("Error")}</>
                        ) : (
                          <Spinner animation="grow" />
                        )}
                        <h6>
                          {error
                            ? getErrorMessage(error)
                            : t("Initializing...")}
                        </h6>
                        {error && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              retryConnect();
                              onConnectWallet(activating);
                            }}
                          >
                            {t("Retry")}
                          </Button>
                        )}
                      </Stack>
                    </ListGroup.Item>
                    <ListGroup.Item
                      style={{
                        backgroundColor: colors?.bg,
                        color: colors?.text,
                      }}
                      className="item activating-item"
                      onClick={() => changeWallet(error)}
                    >
                      <Stack direction="horizontal" gap={2}>
                        <Image
                          src={activating ? activating.logo : ""}
                          width={50}
                          height={50}
                          alt={activating ? activating.logo : ""}
                        ></Image>
                        <h6>
                          {activating ? activating.title : ""}
                          {activating ? activating.description : ""}
                        </h6>
                      </Stack>
                    </ListGroup.Item>
                  </ListGroup>
                );
              }
            })()}
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default WalletModal;
