import React from "react";
import { Dropdown } from "react-bootstrap";

import threeDots from "../../../../../assets/icons/gray-dots.svg";
import "./styles.scss";

const GalleryDropDown = (props) => {
  const { dropdownConfig, onClick } = props;
  const handleItemClick = (eventKey) => {
    return () => onClick(eventKey);
  };

  return (
    <Dropdown className="custom-dropdown">
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="custom-dropdown-toggle dropdown-border"
      >
        <img src={threeDots} alt="three-dots" />
      </Dropdown.Toggle>
      {Array.isArray(dropdownConfig) && (
        <Dropdown.Menu className="dropdown-menu-center">
          {dropdownConfig.map((item, idx) => (
            <Dropdown.Item
              className="dropdown-menu-item"
              onClick={handleItemClick(item.eventkey)}
              key={idx}
            >
              <div className="dropdown-menu-item-style">
                <img src={item.image} alt={item.alt} className="image" />
                <span>{item.text}</span>
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default GalleryDropDown;
