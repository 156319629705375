import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { projectActionCreators } from "../../../store";

export const CheckBoxProp = ({prop, name}) => {
  const dispatch = useDispatch();
  const { addToPropertiesFilter, removeFromPropertiesFilter } = bindActionCreators(
    projectActionCreators,
    dispatch
  );
  const {properties} = useSelector(state => state.project_search);

const isChecked = () => properties.some(el => el.trait_type === name && el.value === prop.value)
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Form.Group>
        <Form.Check type="checkbox" checked={isChecked()} label={prop.value} onChange={e => {
          if(e.target.checked){
            addToPropertiesFilter({
              trait_type: name,
              value: prop.value
              })
          } else {
            removeFromPropertiesFilter({
              trait_type: name,
              value: prop.value
            })
          }
        }} />
      </Form.Group>

      <span style={{ color: "#B4B4B4", fontSize: 15, fontWeight: "bold" }}>
        {prop.count}
      </span>
    </div>
  );
};
