import * as wertTypes from "../types/wert";
import * as API from "../../../utils/axios";
import { catchError } from "./error";

export const getWertOrder = (body) => {
  return (dispatch) => {
    dispatch({ loading: true, type: wertTypes.GET_WERT_DATA_LOADING });

    API.post(`api/buycard/checkstatus`, body)
      .then((res) => {
        dispatch({
          loading: false,
          payload: res.data,
          type: wertTypes.GET_WERT_DATA_SUCCESS,
        });
      })
      .catch((error) => {
        catchError(error, dispatch);
        console.log(error)
        dispatch({
          loading: false,
          error: error.response.data,
          type: wertTypes.GET_WERT_DATA_ERROR,
        });
      });
  };
};

export const saveWertDataCreator = (data) => {
  return (dispatch) =>
    dispatch({
      type: wertTypes.GET_WERT_DATA,
      data,
    });
};
