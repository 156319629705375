import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfoIcon from "@assets/icons/info.svg";

const InfoTooltip = ({ text }) => {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language != "ar";
  return (
    <span>
      <OverlayTrigger
        placement={isLTR ? "right" : "left"}
        overlay={<Tooltip id="button-tooltip">{t(text)}</Tooltip>}
        delay={{ show: 250, hide: 300 }}
      >
        <img
          src={InfoIcon}
          className="cursor"
          style={{
            marginBottom: 3,
            marginInlineStart: 5,
            transform: isLTR ? " scaleX(1)" : "scaleX(-1)",
          }}
        />
      </OverlayTrigger>
    </span>
  );
};
export default InfoTooltip;
