import { useEffect } from "react";
import Pusher from "pusher-js";
import keys from "./lib/config";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { socketActionsCreators } from "./store";

const pusher = new Pusher(window.PUSHER_KEY, {
  cluster: window.PUSHER_CLUSTER,
});

export function useSubscription(channel_name, events) {
  const dispatch = useDispatch();
  const { handleSockets } = bindActionCreators(socketActionsCreators, dispatch);

  async function initSocket() {
    console.log("start subscribe channel");
    const channel = pusher.subscribe(channel_name);

    function handleEvent(data) {
      handleSockets(data.data);
    }

    if (channel) {
      console.log("subscribe to channel ", channel_name);
      if (Array.isArray(events)) {
        events.forEach((event) => channel.bind(event, handleEvent));
      } else {
        channel.bind(events, handleEvent);
      }
    }

    return () => {
      if (channel) {
        channel.unbind_all();
        pusher.unsubscribe(channel_name);
      }
    };
  }

  useEffect(() => {
    initSocket();
  }, [events]);
}
