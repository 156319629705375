import React, { useEffect, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { creatorCollectionsCreators, nftActionsCreators } from "../store";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";

const ExtendAuctionModal = ({ nftId, isOpen, setIsOpen, colors }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);

  const { updateNFTCollectionsCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Modal show={isOpen} onHide={handleClose} centered>
        <div style={{ backgroundColor: colors?.bg, color: colors?.text }}>
          <Modal.Header>
            <Modal.Title>
              <h3
                className="create-section-title"
                style={{ color: colors?.text }}
              >
                {t("extend_auction")}
              </h3>
            </Modal.Title>
            <CloseIcon
              className="modal-close-icon"
              color={colors?.text1}
              onClick={handleClose}
            />
          </Modal.Header>
          <Modal.Body>
            <Container className="create-page mt-0 mb-0">
              {/* preview */}
              <div>
                <Form.Label>
                  <div className="uppercase">{t("select_auction_period")}</div>
                </Form.Label>
                <Form.Select
                  style={{
                    color: colors?.text,
                    backgroundColor: colors?.bg,
                  }}
                  onChange={(e) => setSelectedOption(e)}
                >
                  {["1 day", "2 day"].map((item, key) => {
                    return (
                      <option value={item} key={key}>
                        {item}
                      </option>
                    );
                  })}
                </Form.Select>

                <button
                  onClick={handleClose}
                  className="modal-button mt-4 btn btn-primary"
                >
                  {t("Confirm")}
                </button>
              </div>
            </Container>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default ExtendAuctionModal;
