import styled from "styled-components";

export const ListItem = styled("div")`
  .list-item {
    color: ${(props) => props.colors?.label};
    background-color: ${(props) => props.colors?.bg2};
    padding: 8px;
    pointer: cursor;
    border-radius: 48px;
    &:hover {
      color: ${(props) => props.colors?.label};
      background-color: ${(props) => props?.colors?.hover};
    }
  }
`;
