import * as walletTypes from "../types/wallet";

export const saveWalletsCreator = (wallet_type, wallet) => {
  return (dispatch) => {
    wallet_type === 0
      ? dispatch({ type: walletTypes.SAVE_DEFAULT_WALLET, wallet })
      : dispatch({ type: walletTypes.SAVE_ANOTHER_WALLET, wallet });
  };
};

export const clearAnotherWallet = () => {
  return (dispatch) => dispatch({ type: walletTypes.CLEAR_ANOTHER_WALLET });
};
