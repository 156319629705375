import * as stripeTypes from "../actions/types/stripe";

const stripe = {
  data: {},
  error: null,
  loading: false,
  status: false,
};

export const stripeReducer = (state = stripe, action) => {
  switch (action.type) {
    //* loading
    case stripeTypes.GET_CLIENT_KEY_LOADING:
      return { ...state, loading: true };

    //* success
    case stripeTypes.GET_CLIENT_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    //* error
    case stripeTypes.GET_CLIENT_KEY_ERROR:
      return { ...state, loading: false, error: action.error };

    //* loading
    case stripeTypes.CHECK_BACKEND_STATUS_LOADING:
      return { ...state, loading: true, status: false };

    //* success
    case stripeTypes.CHECK_BACKEND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.payload.status == "success" ? true : false,
        error: null,
      };
    //* error
    case stripeTypes.CHECK_BACKEND_STATUS_ERROR:
      return { ...state, loading: false, status: false, error: action.error };

    default:
      return state;
  }
};
