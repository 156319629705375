//* make offer
export const MAKE_OFFER_LOADING = "MAKE_OFFER_LOADING";
export const MAKE_OFFER_SUCCESS = "MAKE_OFFER_SUCCESS";
export const MAKE_OFFER_ERROR = "MAKE_OFFER_ERROR";

//* cancel offer
export const CANCEL_OFFER_LOADING = "CANCEL_OFFER_LOADING";
export const CANCEL_OFFER_SUCCESS = "CANCEL_OFFER_SUCCESS";
export const CANCEL_OFFER_ERROR = "CANCEL_OFFER_ERROR";

//* edit offer
export const EDIT_OFFER_LOADING = "EDIT_OFFER_LOADING";
export const EDIT_OFFER_SUCCESS = "EDIT_OFFER_SUCCESS";
export const EDIT_OFFER_ERROR = "EDIT_OFFER_ERROR";

//* get offers
export const GET_OFFERS_LOADING = "GET_NFT_OFFERS_LOADING";
export const GET_OFFERS_SUCCESS = "GET_NFT_OFFERS_SUCCESS";
export const GET_OFFERS_ERROR = "GET_NFT_OFFERS_ERROR";

//* accept offer
export const ACCEPT_OFFER_LOADING = "ACCEPT_NFT_OFFER_LOADING";
export const ACCEPT_OFFER_SUCCESS = "ACCEPT_NFT_OFFER_SUCCESS";
export const ACCEPT_OFFER_ERROR = "ACCEPT_NFT_OFFER_ERROR";
