import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";
import { useParams } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Spinner } from "reactstrap";
import {
  ACTION_STATUS,
  social,
} from "../../../../../../common/constants/gallery";
import { artistActionsCreators } from "../../../../../../store";
import {
  getCreateAndEditArtistValidationSchema,
  getCreateAndEditArtistInitialValues,
} from "../../../utilities";
import ClosedFilled from "../../../../../../icons/ClosedFilled.svg";
import UploadImage from "../../../../../uploadImage/UploadImage";
import "./styles.scss";

const CreateAndEditArtist = ({ isOpen, setIsOpen, artistId }) => {
  const { galleryId } = useParams();
  const { t } = useTranslation();
  const [isError, setError] = useState(false);
  const dispatch = useDispatch();
  const createStatus = useSelector(
    (state) => state.artist.createGalleryArtist.status
  );
  const updateStatus = useSelector(
    (state) => state.artist.updateGalleryArtist.status
  );

  let artist = useSelector(
    (state) => state.artist.getGalleryArtistById.entities
  );

  const {
    createGalleryArtistCreator,
    updateGalleryArtistCreator,
    getGalleryArtistByIdCreator,
    resetGalleryArtistByIdCreator,
  } = bindActionCreators(artistActionsCreators, dispatch);

  useEffect(() => {
    if (galleryId && artistId) {
      getGalleryArtistByIdCreator(galleryId, artistId);
    }
    return () => {
      resetGalleryArtistByIdCreator();
    };
  }, []);

  const handleArtistSubmit = (values) => {
    if (artistId) {
      updateGalleryArtistCreator(galleryId, artistId, values);
    } else {
      createGalleryArtistCreator(galleryId, values);
    }
  };

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: getCreateAndEditArtistInitialValues(),
    validationSchema: getCreateAndEditArtistValidationSchema(t),
    onSubmit: handleArtistSubmit,
  });
  console.log("values", values);
  const urlWithProtocol = (data) => {
    if (data) {
      if (data.includes("https://")) {
        return data;
      } else if (data.includes("http://")) {
        return data;
      } else {
        return "https://" + data;
      }
    } else {
      return "";
    }
  };
  useEffect(() => {
    if (values && !values.name && artist) {
      setFieldValue("name", artist.name);
      setFieldValue("description", artist.description);
      setFieldValue("country", artist.country);
      setFieldValue("profileImage", artist.profileImage);
      setFieldValue("coverImage", artist.coverImage);
      setFieldValue(
        "instagram_url",
        urlWithProtocol(artist?.media?.instagram_url)
      );
      setFieldValue("twitter_url", urlWithProtocol(artist?.media?.twitter_url));
      setFieldValue("website_url", urlWithProtocol(artist?.media?.website_url));
      setFieldValue(
        "facebook_url",
        urlWithProtocol(artist?.media?.facebook_url)
      );
      setFieldValue("youtube_url", urlWithProtocol(artist?.media?.youtube_url));
    }
  }, [artist]);

  const handleClose = (event) => {
    event.preventDefault();
    setIsOpen(false);
  };

  const isButtonDisabled = () => {
    return (
      createStatus === ACTION_STATUS.LOADING ||
      updateStatus === ACTION_STATUS.LOADING
    );
  };

  return (
    <Modal
      size="lg"
      dialogClassName="dialogClass"
      show={isOpen}
      onHide={handleClose}
    >
      <Modal.Header className="border-btm-0 pb-3 pt-4 title-p">
        <Modal.Title>
          <h3 className="create-section-title">
            {artistId ? values?.name : t("create_new_artist")}
          </h3>
        </Modal.Title>
        <img
          onClick={handleClose}
          src={ClosedFilled}
          width={32}
          height={32}
          alt=""
        />
      </Modal.Header>
      <Modal.Body className="modal-container-p pl-2 pr-2 pb-0">
        <Container className="create-page mt-0 mb-0">
          <Form
            onSubmit={handleSubmit}
            style={
              isButtonDisabled()
                ? {
                    pointerEvents: "none",
                    cursor: "not-allowed",
                    opacity: "0.65",
                  }
                : {}
            }
          >
            <div className="item-details pb-0">
              <Form.Group controlId="name" className="mb-4">
                <Form.Label className="create-section-label">
                  {t("artist_name")}
                  <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  className="create-page-input-field"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.name}
                  errors={errors.name}
                  value={values.name}
                  name="name"
                  placeholder={t("name_placeholder")}
                />
                {errors.name && (touched.name || isError) && (
                  <Form.Text className="text-danger">{errors.name}</Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="description" className="mb-4">
                <Form.Label className="create-section-label">
                  {t("description")}
                  <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  className="create-page-input-field textarea-h-100"
                  as="textarea"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.description}
                  errors={errors.description}
                  value={values.description}
                  name="description"
                  placeholder={t("description_placeholder")}
                />
                {errors.description && (touched.description || isError) && (
                  <Form.Text className="text-danger">
                    {errors.description}
                  </Form.Text>
                )}
              </Form.Group>
              <Col xs={12} md={8} className="mb-4">
                <Form.Group>
                  <Form.Label className="create-section-label">
                    {t("location_optional")}
                  </Form.Label>
                  <ReactFlagsSelect
                    placeholder={t("select_country")}
                    name="country"
                    selectButtonClassName="select-flag"
                    className="flag-class"
                    selected={values.country}
                    onSelect={(code) => setFieldValue("country", code)}
                  />
                  {errors.country && (touched.country || isError) && (
                    <Form.Text className="text-danger">
                      {errors.country}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Row>
                <Col xs={12} md={8}>
                  <h5 className="mt-5 modal-section-title">
                    {t("upload_profile_image")}
                    <span className="required">*</span>
                  </h5>
                  <p className="mt-2 modal-section-text">
                    {t("upload_logo_p1")}
                  </p>
                  {errors.profileImage && (touched.profileImage || isError) && (
                    <Form.Text className="text-danger">
                      {errors.profileImage}
                    </Form.Text>
                  )}
                </Col>
                <Col xs={12} md={4} className="mt-5">
                  <UploadImage
                    fieldName="profileImage"
                    onFileSelected={(file) =>
                      setFieldValue("profileImage", file)
                    }
                    onFileRemove={() =>
                      setFieldValue("profileImage", undefined)
                    }
                    onlyPreviewImage={values.profileImage}
                    closeIcon={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  <h5 className="mt-5 modal-section-title">
                    {t("upload_banner_image")}
                    <span className="required">*</span>
                  </h5>
                  <p className="mt-2 modal-section-text">
                    {t("upload_banner_p1")}
                  </p>
                  {errors.coverImage && (touched.coverImage || isError) && (
                    <Form.Text className="text-danger">
                      {errors.coverImage}
                    </Form.Text>
                  )}
                </Col>

                <Col xs={12} md={4} className="mt-5">
                  <UploadImage
                    fieldName="coverImage"
                    onFileSelected={(file) => setFieldValue("coverImage", file)}
                    onFileRemove={() => setFieldValue("coverImage", undefined)}
                    onlyPreviewImage={values.coverImage}
                    closeIcon={true}
                  />
                </Col>
              </Row>
              <div className="mt-4">
                {social.map(({ value, placeholder, Icon }) => (
                  <Form.Group className="mb-3" controlId="item-name">
                    <div className="position-relative">
                      <div className="social-icon-wrapper">
                        <div className="social-icon">
                          <Icon width="14" height="14" color="#fff" />
                        </div>
                        <div className="mx-2 social-divider" />
                      </div>
                      <Form.Control
                        type="text"
                        className="create-page-input-field"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched[value]}
                        errors={errors[value]}
                        value={values[value]}
                        name={value}
                        placeholder={t(placeholder)}
                        style={{ paddingInlineStart: 60 }}
                      />
                    </div>
                    {console.log("placeholder", placeholder)}
                    {errors[value] && (touched[value] || isError) && (
                      <Form.Text className="text-danger">
                        {errors[value]}
                      </Form.Text>
                    )}
                  </Form.Group>
                ))}
              </div>
              <button
                style={{ width: "163px", height: "48px" }}
                className="mx-0 mx-md-2 my-2 my-md-5 btn btn-primary"
                type="submit"
                onClick={() => setError(true)}
                disabled={isButtonDisabled()}
              >
                {artist && artist.name ? t("update") : t("create")}
                {isButtonDisabled() && (
                  <Spinner
                    size="sm"
                    style={{ color: "#fff", marginLeft: "5px" }}
                  />
                )}
              </button>
              <button
                style={{ width: "163px", height: "48px" }}
                className="mx-0 mx-md-5 my-2 my-md-0 btn btn-secondary-outline radius-50 mb-4"
                onClick={handleClose}
                disabled={isButtonDisabled()}
              >
                {t("Cancel")}
              </button>
            </div>
          </Form>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAndEditArtist;
