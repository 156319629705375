import styled from "styled-components";
const StylesWrapper = styled.div`
  color: #fff;
  background: #121212;
  padding-bottom: 25px;
  .logo {
    width: 100px;
    margin-inline-end: 10;
  }
  .title {
    font-size: 64px;
    font-weight: 400;
    // text-shadow: 2px 2px 4px #fff;
  }
  .subtitle {
    color: #c89d66;
    // text-shadow: 2px 2px 4px #c89d66;
  }
  .text {
    font-size: 24px;
    font-weight: 325;
  }
  .description {
    font-size: 24px;
    font-weight: 325;
    max-width: 426px;
    text-align: right;
  }
  .more {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;
  }
  .nfts {
    width: 87%;
    margin-left: auto;
    margin-right: auto;
  }
  .loadmore {
    color: #efd8ba;
    font-aize: 20px;
    fonweight: 325;
    text-decoration: underline;
  }
  .end-line {
    width: 50%;
    height: 35px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 161, 0, 0.2) 0%,
      rgba(210, 174, 113, 0) 100%
    );
    filter: blur(2px);
    transform: rotate(-180deg);
  }
  .cards-bg {
    position: relative;
  }
  .card-divider {
    z-index: 2;
    position: relative;
    width: 92%;
    height: 0.8px;
    background-color: #6b6102;
  }
  .scroll-note {
    font-size: 20px;
  }
  .scroll-note-gold {
    font-size: 20px;
    color: #c89d66;
    margin-inline-start: 5px;
  }
  .scroll-btn {
    transform: rotate(-90deg);
  }
  @media (max-width: 992px) {
    .img {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
    .text {
      font-size: 18px;
    }
    .title {
      font-size: 44px;
    }
    .description {
      font-size: 22px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
      max-width: 550px;
    }
  }
  @media (max-width: 768px) {
    .img {
      width: 80%;
    }
    .card-image {
      width: 70%;
      height: auto;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    .card-content {
      text-align: center;
    }
    .card-title {
      text-align: center;
    }
  }
  @media (max-width: 576px) {
    .card-image {
      width: 90%;
      height: auto;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
    .logo {
      width: 125px;
    }
  }
`;

const CardStyles = styled.div`
  .nft-small {
    min-width: 196px;
    max-width: 196px;
    min-height: 291px;
    max-height: 291px;
    border-radius: 15.4674px;
    border: 1.3px solid #975f00;
    padding: 15px;
    margin: 15px;
  }
  .nft {
    min-width: 325px;
    max-width: 325px;
    min-height: 460px;
    max-height: 460px;
    border-radius: 15.4674px;
    border: 1.3px solid #975f00;
    padding: 15px;
    margin: 15px;
  }
  .nft-small-video-wrapper  {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 170px;
    max-width: 170px;
    min-height: 170px;
    max-height: 170px;
    border-radius: 10px;
  }
  .nft-video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
    border-radius: 10px;
  }
  .nft-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  .nft-title {
    color: #fff;
    font-size: 17.9px;
  }
  .price {
    color: #fff;
    font-size: 16px;
  }
  .price-value {
    font-size: 15.5px;
    font-weight: 400;
    color: #fff;
  }
  .card-divider {
    z-index: 2;
    position: relative;
    width: 92%;
    height: 0.8px;
    background-color: #6b6102;
`;
export { StylesWrapper, CardStyles };
