import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import notFound from "../assets/images/404_light_theme.png";

const PageNotFound = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handelClick = () => {
    navigate("/");
  };
  return (
    <div className="mt-5 container ">
      <div className="d-flex flex-row  justify-content-center">
        <img
          src={notFound}
          alt="error"
          style={{ maxHeight: "250px", maxWidth: "auto", height: "auto" }}
        />
      </div>
      <p className="d-flex flex-row justify-content-center fs-1 mt-3">
        {t("not_found")}
      </p>
      <div className="d-flex flex-row align-items-center justify-content-center mt-3 mb-5">
        <Button
          onClick={handelClick}
          className="mx-2  btn-primary "
          style={{ borderRadius: "8px" }}
        >
          {t("not_found_button")}
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
