/* eslint-disable no-undef */
import { useEffect, useState } from "react";
//web3
import Web3 from "web3";
// import WalletModal from "./WalletModal";
//bootstrap
import { Button, Form, Image, Spinner, Row, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalAndOverlay from "./ModalAndOverlay";
// import connectWalletIcon from "../../src/icons/connectWallet.svg";
// grey icons
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import visamaster from "@assets/icons/visa-master.png";
// green completed icon
import completedIcon from "../../src/icons/completed.svg";

//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { nftActionsCreators, partnerActionCreators } from "../store";
//config and library
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3React } from "@web3-react/core";
import { chains } from "@config";

import Swal from "sweetalert2";
// import { setSyntheticLeadingComments } from "typescript";

//wert
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";
import styled from "styled-components";

const ConnectWallet = styled.div`
  text-align: center;
  p {
    font-weight: 500;
  }
`;

const EditSale = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    font-weight: 500;
    color: ${(props) => props.colors?.text1};
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
`;

const EditSalePriceModal = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { nft, selectedOwner, externalNFT } = props;
  const is1155 = nft?.token_standard == 1155;
  const [amount, setAmount] = useState(1);
  const [newPrice, setNewPrice] = useState(nft?.listing?.price || 0);
  const [status, setStatus] = useState(0);
  const [balance, setBalance] = useState("");
  const [selectedWallet, setSelectedWallet] = useState("");
  const [defaultWallet, setDefaultWallet] = useState("");
  const [accountbalance, setAccountBalance] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  const { changeNftPriceCreator } = bindActionCreators(
    nftActionsCreators,
    dispatch
  );

  const { changePartnerNftPrice } = bindActionCreators(
    partnerActionCreators,
    dispatch
  );
  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const { chain, privateKey, getAccounts, chainId } = useWeb3Auth();
  const { account } = useWeb3React();
  const { editSale } = useWeb3Context();
  const [isFiat, setFiatPay] = useState(false);
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");

  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC",
    commodity_amount: "0.003",
    address: selectedWallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
        console.log("status", status);
      },
    },
  };

  useEffect(() => {
    getWallets();
  }, [privateKey, account, balance, accountbalance]);

  const wertWidget = new WertWidget(options);

  const getWallets = async () => {
    const wallet = await getAccounts();
    setBalance(wallet.balance);
    setSelectedWallet(nft?.owner?.create_wallet);
    setDefaultWallet(wallet.address);

    const wallets = [];
    wallets.push({
      value: wallet.address,
      label: wallet.address,
      balance: wallet.balance,
    });
    if (account)
      if (chains[chain].chainId === chainId)
        wallets.push({ value: account, label: account });

    // setWalletOptions(props.walletOptions);
    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    if (account) {
      const accountBalance = await web3.eth.getBalance(account);
      const accountbalance = web3.utils.fromWei(accountBalance, "ether");
      setAccountBalance(accountbalance);
    }
  };

  const editSalePrice = async (isFiat) => {
    if (isFiat) {
      setStatus(1);
      wertWidget.mount();
    } else {
      setStatus(1);
      try {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(chains[chain].rpcTarget)
        );

        if (nft?.is_lazy) {
          const editSaleData = {
            price: newPrice,
          };
          changeNftPriceCreator(nft?._id, editSaleData);
          setStatus(2);
        } else {
          const value = web3.utils.toWei(newPrice.toString());
          const res = await editSale(
            nft?.offer_id,
            value,
            selectedWallet === props.defaultWallet
          );
          console.log(res);
          const editSaleData = {
            price: newPrice,
            tx: res.data.transactionHash,
          };

          // save to backend
          if (res.status) {
            isBezelClub
              ? changePartnerNftPrice(nft?.contract, nft?._id, editSaleData)
              : changeNftPriceCreator(nft?._id, editSaleData);
            setStatus(2);
          } else {
            Swal.fire({
              icone: "error",
              title: t("Ooops..."),
              text: t("transaction_failed"),
              color: props?.colors?.text,
              background: props?.colors?.bg,
              confirmButtonText: t("Ok"),
              confirmButtonColor: "#6d3190",
            });
          }
        }
      } catch (e) {
        console.log(e.message);
        Swal.fire({
          icone: "error",
          title: t("Ooops..."),
          text: t("bidding failed"),
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };
  return (
    <div>
      <ModalAndOverlay colors={props.colors}>
        <div>
          <div className="d-flex justify-content-between mb-4">
            <h3 className="modal-heading">{t("Edit Sale Price")}</h3>
            <CloseIcon
              className="modal-close-icon"
              color={props.colors?.text1}
              onClick={props.closeOfferModal}
            />
          </div>
          <div id="wert-widget"></div>
          {wertPaymentStatus == "success" && (
            <Button
              className={`modal-button ${props?.colors?.button}`}
              onClick={() => {
                setFiatPay(false);
                editSalePrice();
                setIsEdit(true);
              }}
            >
              {t("Proceed")}
            </Button>
          )}
        </div>
      </ModalAndOverlay>

      {!isFiat && (
        <ModalAndOverlay colors={props.colors}>
          {/* --------- edit price --------- */}

          <EditSale colors={props.colors}>
            <div>
              <div className="d-flex justify-content-between mb-5">
                <div className="modal-heading title">{t("Edit Sale")}</div>
                <CloseIcon
                  className="modal-close-icon"
                  color={props.colors?.text1}
                  onClick={props.closeOfferModal}
                />
              </div>
              {chain !== nft?.blockchain ? (
                <>
                  <h4> {t("Please switch network")}</h4>
                </>
              ) : (
                ""
              )}
              <Form.Group controlId="price" className="mb-4">
                <Form.Label>
                  <div style={{ fontWeight: 400, fontSize: 16 }}>
                    {t("Price")}
                  </div>
                </Form.Label>
                <InputGroup size="md">
                  <Form.Control
                    type="text"
                    value={newPrice}
                    placeholder="Price"
                    className="create-page-input-field"
                    onChange={(e) => setNewPrice(e.target.value)}
                  />
                  <InputGroup.Text>{nft?.blockchain}</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Label>
                <div>{t("Wallet address")}</div>
              </Form.Label>
              <p>{selectedWallet}</p>
            </div>

            <Button
              disabled={status >= 1 || newPrice == 0 ? true : false}
              className={`modal-button mb-3 mt-5 ${props?.colors?.button}`}
              onClick={() => {
                editSalePrice();
                setIsEdit(true);
              }}
            >
              {t("Confirm")}
            </Button>
            {/* {!nft?.is_lazy && (
              <Button
                className={`modal-button mb-3 ${props?.colors?.button}`}
                id="cancel-fiat"
                disabled={status >= 1 || newPrice == 0 ? true : false}
                onClick={() => {
                  setFiatPay(true);
                  editSalePrice(true);
                }}
              >
                {t("Edit Sale Price with card")}{" "}
                <img src={visamaster} width="80" height="30" />
              </Button>
            )} */}
            {/* <Button
              onClick={props.closeOfferModal}
              className={`modal-button ${props?.colors?.button}`}
            >
              {t("Cancel")}
            </Button> */}
          </EditSale>
          {isEdit && (
            <ModalAndOverlay colors={props.colors}>
              <div className="mb-4 pb-2">
                <div className="d-flex align-items-center mb-3">
                  <div>
                    {status === 1 ? (
                      <Spinner animation="border" variant="primary" />
                    ) : status > 1 ? (
                      <Image src={completedIcon}></Image>
                    ) : (
                      <Image src={lockOrderIcon}></Image>
                    )}
                  </div>
                  &nbsp;&nbsp;
                  <div>
                    <div
                      className="m-0 modal-section-title"
                      style={{ color: props?.colors?.text }}
                    >
                      <div>
                        {status === 1
                          ? t("Updating the Price")
                          : t("Price updated")}
                      </div>
                    </div>
                    <p className="m-0 modal-section-text">
                      {status === 1
                        ? t("Call contract method")
                        : t("You rock! 🚀")}
                    </p>
                  </div>
                </div>
              </div>
              <Button
                className={`modal-button ${props?.colors?.button}`}
                disabled={status === 0 || status === 2 ? false : true}
                onClick={() => props.closeOfferModal(true)}
              >
                {status === 0
                  ? t("Cancel")
                  : status === 1
                  ? t("Updating...")
                  : t("Done")}
              </Button>
            </ModalAndOverlay>
          )}
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default EditSalePriceModal;
