import React, { useEffect, useState } from "react";
import { Button, Spinner, Image } from "react-bootstrap";
import { useWeb3Auth } from "@context/Web3auth";
import { Modals } from "./Modals";

//svg file
import ModalAndOverlay from "@components/ModalAndOverlay";
import errorIcon from "@assets/images/icons/error.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
//store
import { useSelector, useDispatch } from "react-redux";
import { offerActionsCreators } from "../store";
import { bindActionCreators } from "redux";
import { useWeb3Context } from "@context/Web3";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import styled from "styled-components";

const AcceptOffer = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  .enter-bid {
    border-bottom: 1px solid #e6e8ec;
  }
  .note {
    font-size: 20px;
  }
  .purple-text {
    color: #6d3190;
  }
  .price {
    font-size: 32px;
    color: #6d3190;
  }
  .chain {
    font-weight: 325;
  }
`;
export const AcceptOfferModals = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { nft, isProject } = props;

  const { acceptOfferCreator } = bindActionCreators(
    offerActionsCreators,
    dispatch
  );
  const { acceptOfferPrice } = useWeb3Context();

  const [status, setStatus] = useState(0);
  const [error, setError] = useState("");

  const acceptNFTOffer = async () => {
    try {
      setStatus(1);

      const selectedWallet = nft?.owner?.create_wallet;

      const res = await acceptOfferPrice(
        props.selectedOffer?.offer_id,
        selectedWallet === props.defaultWallet
      );

      // save to backend
      if (res.status) {
        const offerData = {
          nft_id: nft._id,
          tx: res.data.transactionHash,
          offer_id: res.data.events.eAcceptOffer.returnValues.makeOfferId,
        };
        //setResponse(res.data);
        acceptOfferCreator(offerData, isProject ? "project" : "nft");
        setStatus(2);
      } else {
        setStatus(3);
        setError(res.data);
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("transaction_failed"),
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
        props.closeOfferModal();
      }
    } catch (e) {
      setStatus(3);
      setError(e);
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: t("accepting_offer_failed"),
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      props.closeOfferModal();
    }
  };

  return (
    <div>
      <ModalAndOverlay colors={props.colors}>
        <Modals colors={props.colors}>
          <AcceptOffer colors={props.colors}>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading title">{t("accept_offer")}</h3>
              <CloseIcon
                className="modal-close-icon"
                color={props.colors?.text1}
                onClick={props.closeOfferModal}
              />
            </div>
            {status === 0 ? (
              <>
                <div>
                  <div className="note">
                    {t("are_you_sure")}
                    <span className="purple-text"> {t("accept_offer")} </span>
                  </div>
                  <span>{t("from")}</span>
                  <span className="purple-text">
                    {" "}
                    {props.selectedOffer?.user?.name}{" "}
                  </span>
                </div>
                <div>
                  <div className="mt-4">{t("offer_amount")}</div>
                  <div className="price">
                    {props.selectedOffer?.price || 0}
                    <span className="mx-2 chain">
                      {props.selectedOffer?.payment_method}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <div className="mb-4 pb-2">
                {status == 3 && (
                  <div className="mb-3">
                    <h4>{t("transaction_failed")}</h4>
                    <span>{error.toString()}</span>
                  </div>
                )}
                <div className="d-flex align-items-center mb-3">
                  <div>
                    {status === 1 ? (
                      <Spinner animation="border" variant="primary" />
                    ) : status === 3 ? (
                      <Image src={errorIcon}></Image>
                    ) : (
                      <Image src={completedIcon}></Image>
                    )}
                  </div>
                  &nbsp;&nbsp;
                  <div>
                    <h5 className="m-0 modal-section-title">
                      <strong>{t("accept_offer")}</strong>
                    </h5>
                    <p className="m-0 modal-section-text">
                      {t("accepting_offer")}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <Button
              disabled={status == 1}
              className="modal-button mb-3 mt-4"
              onClick={() => {
                status === 2 ? props.closeOfferModal() : acceptNFTOffer();
              }}
            >
              {status === 0
                ? t("Confirm")
                : status === 1
                ? t("Calling...")
                : t("Done")}
            </Button>
          </AcceptOffer>
        </Modals>
      </ModalAndOverlay>
    </div>
  );
};

export default AcceptOfferModals;
