import { t } from "i18next";
import React, { useState } from "react";
import { truncate } from "../core/string";
import styled from "styled-components";

const Wrapper = styled.div`
  .main {
    padding-bottom: 50px;
  }
  .card {
    positions: relative;
    min-width: 291px;
    margin-right: 15px;
    margin-left: 10px;
    border: 1px solid #efefef;
    // box-shadow: 0px 5.0181px 0px 1.8258px rgba(214, 214, 214, 0.25);
    border-radius: 13.8258px;
  }
  .image {
    object-fit: cover;
  }
  .radius {
    border-radius: 9.2px;
  }
  .creator {
    color: #000;
    font-size: 12px;
    font-weight: 450;
  }
  .tag {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 400;
  }
  .name {
    font-size: 16px;
    color: #1c0039;
  }
  .divider {
    width: 100%;
    height: 0.66px;
    background-xolor: #e5e4de;
  }
  .label {
    color: #afa9bd;
    font-size: 12px;
  }
  .value {
    color: #1c0039;
    font-size: 14px;
    font-weight: 400;
  }
  .card:hover {
    .overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  .overlay {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 255, 255, 0.83) 0%,
      rgba(255, 255, 255, 0.7802) 100%
    );
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 291px;
    border-radius: 13.8258px;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
  }
`;

export const PreviewNftCard = ({ item }) => {
  const [duration, setDuration] = useState(null);
  return (
    <Wrapper>
      <div
      // className="p-3 pb-2 card"
      // style={{
      //   minWidth: 300,
      //   maxWidth: 300,
      //   filter: "drop-shadow(.5rem .5rem 1rem #EFEFEF)",
      // }}
      >
        {/* <div className="d-flex">
          <div>
            <img
              src={item?.creator_image_url || "/user-placeholder.png"}
              className="user-30-rounded image me-3"
              alt=""
            />
          </div>
          <div>
            <div className="creator">{item?.creator_name}</div>
            <div className="tag">{t("Artist")}</div>
          </div>
        </div> */}
        <div>
          <div
            className="my-3"
            style={{
              maxWidth: "300px",
              minWidth: "300px",
              height: "auto",
              position: "relative",
            }}
          >
            {item?.type === "image" ? (
              <img
                alt=""
                src={item?.url}
                className="image radius"
                style={{
                  maxWidth: "300px",
                  minWidth: "300px",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            ) : item?.type === "video" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  maxWidth: "300px",
                  minWidth: "300px",
                  height: "auto",
                  objectFit: "contain",
                }}
              >
                <video
                  muted
                  autoPlay
                  loop
                  width={"100%"}
                  height={"100%"}
                  className=" image radius"
                  playsInline
                >
                  <source src={item?.url} type="video/mp4" />
                </video>
              </div>
            ) : (
              item?.type === "audio" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    maxWidth: "300px",
                    minWidth: "300px",
                    minHeight: "200px",
                    maxHeight: "200px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                >
                  <audio className=" image radius" controls>
                    <source src={item?.url} type="audio/mp3" />
                  </audio>
                </div>
              )
            )}
          </div>
        </div>
        {/* <div className="d-flex justify-content-between align-items-center">
          <div style={{ textDecoration: "none" }}>
            <div className="name">{truncate(item?.name)}</div>
          </div>
        </div> */}
        {/* <div className="my-2 divider" /> */}
        {/* {item?.status == "PURCHASE" ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="label">{t("sold")}</div>
            </div>
          </div>
        ) : item?.listing?.sale_type == "0" ||
          item?.status == "CANCEL" ||
          item?.status == "UNLISTED" ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="label">{t("Not for Sale")}</div>
            </div>
          </div>
        ) : item?.sale_type == "1" ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="label">{t("Fixed Price")}</div>
              <div className="value">{item?.price}</div>
            </div>
          </div>
        ) : item?.sale_type == "2" ? (
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="label">{t("Reserve Price")}</div>
              <div className="value">{item?.price}</div>
            </div>
            <div>
              <div className="label">{t("Auction Ends in")}</div>
              <span className="mx-1 value">
                {duration?.days || "00"}
                <span className="value">{t("d")}</span>
              </span>
              <span className="mx-1 value">
                {duration?.hours || "00"}
                <span className="value">{t("h")}</span>
              </span>
              <span className="mx-1 value">
                {duration?.minutes || "00"}
                <span className="value">{t("m")}</span>
              </span>
              <span className="mx-1 value">
                {duration?.seconds || "00"}
                <span className="value">{t("s")}</span>
              </span>
            </div>
          </div>
        ) : null} */}
      </div>
    </Wrapper>
  );
};
