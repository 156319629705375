import styled from "styled-components";

const StylesWrapperDefault = styled("div")`
  .img-container {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 20px;
  }
`;
const StylesWrapperFullView = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .img-container {
    width: 100%;
    height: 525px;
    object-fit: contain;
  }
  .audio-container {
    width: 50%;
    height: 250px;
    object-fit: contain;
  }
`;
export { StylesWrapperDefault, StylesWrapperFullView };
