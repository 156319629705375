import React, { useState } from "react";
//pages
import NFTDescription from "@pages/NFTDescription";
import NFTHistory from "@pages/NFTHistory";
import NFTOwners from "@pages/NFTOwners";
import NFTBidding from "@pages/NFTBidding";
import NFTOffers from "@pages/NFTOffers";
import { useTranslation } from "react-i18next";
import StylesWrapper from "./styles";

const Tabs = (props) => {
  const { nft, selectedTab, externalNFT, filteredLinkedNFTs, description } =
    props;

  const { t } = useTranslation();

  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const is721 = nft?.token_standard === "721";

  return (
    <StylesWrapper>
      {selectedTab == 0 ? (
        <NFTDescription {...props} description={description} />
      ) : selectedTab == 1 ? (
        filteredLinkedNFTs ? (
          <NFTOwners
            erc={
              filteredLinkedNFTs?.contract_type
                ? filteredLinkedNFTs?.contract_type
                : filteredLinkedNFTs?.contractType
            }
            owners={
              filteredLinkedNFTs?.owner_of
                ? filteredLinkedNFTs?.owner_of
                : filteredLinkedNFTs?.ownerOf
            }
            linkednft={true}
          />
        ) : (
          <NFTOwners
            {...props}
            owners={isBezelClub || is721 ? nft?.owner : nft?.owners}
          />
        )
      ) : selectedTab == 2 ? (
        <NFTHistory {...props} />
      ) : selectedTab == 3 ? (
        <NFTBidding {...props} />
      ) : selectedTab == 4 ? (
        <NFTOffers {...props} />
      ) : (
        ""
      )}
    </StylesWrapper>
  );
};
export default Tabs;
