// import { Row, Col } from "react-bootstrap";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import NFTAcceptBid from "./../pages/NFTAcceptBid";
import Modal from "../components/common/Modal";
import { useWeb3Context } from "@context/Web3";
import { useWeb3React } from "@web3-react/core";
import { useWeb3Auth } from "../context/Web3auth";
import { bindActionCreators } from "redux";
import { bidActionsCreators } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { Button, Table } from "reactstrap";
import NoDataImage from "@assets/images/noDataNftDetails.png";
import { timeAgo } from "../core/dates";
import styled from "styled-components";
import Swal from "sweetalert2";
import moment from "moment";

const StylesWrapper = styled.div`
  .history-nodata {
    font-size: 15px;
    color: ${(props) => props?.colors?.label};
  }
  .history-label {
    font-size: 16px;
    color: ${(props) => props?.colors?.text};
  }
  .history-action {
    font-size: 16px;
    margin-inline-start: 4px;
    color: ${(props) => props?.colors?.text};
  }
  .history-text {
    font-size: 14px;
    font-weight: 325;
    color: ${(props) => props?.colors?.text};
  }
  .history-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }
  .history-name {
    font-size: 16px;
    font-weight: 325;
    margin-inline-start: 10px;
    color: ${(props) => props?.colors?.text};
  }
  .history-date {
    font-size: 14px;
    color: ${(props) => props?.colors?.text};
  }
  .history-icon {
    font-size: 20px;
    margin-bottom: 5px;
    margin-inline-start: 15px;
    color: ${(props) => props?.colors?.text};
  }
`;
const NFTBidding = (props) => {
  const { nft, colros } = props;
  const dispatch = useDispatch();
  const { cancelBidCreator } = bindActionCreators(bidActionsCreators, dispatch);
  const { bids } = useSelector((state) => state.nft_bids);
  const eth = useSelector((state) => state.eth);

  const { t, i18n } = useTranslation();

  const [acceptBidding, setAcceptBidding] = useState(false);
  const [nftData, setnftData] = useState({});

  const closeAcceptModal = useCallback(() => {
    setAcceptBidding(false);
  }, []);

  const { cancelBid, endAuction } = useWeb3Context();
  const { account, active, chainId } = useWeb3React();
  const { chain, privateKey, getAccounts } = useWeb3Auth();
  const [defaultWallet, setDefaultWallet] = useState("");
  const [selectedWallet, setSelectWallet] = useState("");
  const [walletOptions, setWalletOptions] = useState([]);
  // place bid amount
  const [bidAmount, setBidAmount] = useState(0);

  const onApproveBid = (bidding) => {
    setAcceptBidding(true);
    setnftData({
      name: nft?.name,
      highestBid: bidding?.priceInCrypto,
      serviceFee: 0.025 * bidding?.priceInCrypto,
      receiveAmount: bidding?.priceInCrypto - 0.025 * bidding?.priceInCrypto,
      USD: 55,
    });
  };

  // const openAcceptModal = () => {
  //   max = biddingData.reduce(function (prev, current) {
  //     return prev.priceInCrypto > current.priceInCrypto ? prev : current;
  //   }, 0);
  //   setnftData({
  //     name: props?.name,
  //     highestBid: max.priceInCrypto,
  //     serviceFee: 0.025 * max.priceInCrypto,
  //     receiveAmount: max.priceInCrypto - 0.025 * max.priceInCrypto,
  //     USD: 55,
  //   });
  //   setAcceptBidding(true);
  // };

  const cancelBidAction = async (bidIndx) => {
    try {
      const res = await cancelBid(
        nft?.offer_id,
        bidIndx,
        selectedWallet === defaultWallet
      );
      const bidData = {
        nft_id: nft?.offer_id,
        wallet: props?.selectedWallet,
        price: 0,
        tx: res.data.transactionHash,
      };
      await cancelBidCreator(bidData);
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  const endAuctionAction = async (bidIndx) => {
    try {
      const res = await endAuction(
        nft?.offer_id,
        bidIndx,
        selectedWallet === defaultWallet
      );

      // todo : call backend api
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  if (nft?.bids?.length === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <img src={NoDataImage} alt="no data" />
        <p className="history-nodata">{t("There are no offers to show")}</p>
      </div>
    );
  }
  return (
    <StylesWrapper>
      <div>
        {/* {acceptBidding && (
          <Modal
            open={acceptBidding}
            title={t("Accept Bid")}
            onSubmit={() => endAuctionAction(1)}
            onClose={closeAcceptModal}
            content={<NFTAcceptBid {...nftData} />}
            hasCancelButton={false}
            hasSubmitButton={false}
          />
        )} */}
      </div>
      <Table borderless>
        <thead>
          <tr>
            <td className="history-label">{t("user_name")}</td>
            <td className="history-label">{t("Price")}</td>
            <td className="history-label">{t("Date")}</td>
          </tr>
        </thead>
        <tbody>
          {bids.map((el, index) => {
            let usdPrice = 0;
            if (nft.blockchain === "ETH")
              usdPrice = el?.price ? el?.price * parseFloat(eth?.usd.eth) : 0;
            if (nft.blockchain === "MATIC")
              usdPrice = el?.price ? el?.price * parseFloat(eth?.usd.matic) : 0;

            return (
              <tr key={index}>
                <td valign="middle">
                  {el?.user ? (
                    <div>
                      <img
                        src={el?.user?.profile?.image_url}
                        alt={el?.user?.name}
                        style={{
                          objectFit: "cover",
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                        }}
                      />
                      <span className="history-name">{el?.user?.name}</span>
                    </div>
                  ) : (
                    <div>{t("None")}</div>
                  )}
                </td>
                <td valign="middle">
                  <span className="history-text">
                    {`${el?.price} ${el?.payment_method || ""}` || 0}
                  </span>
                  <span className="history-text mx-1">{nft?.blockchain}</span>
                  <span style={{ color: "#6d3190" }}>
                    ({usdPrice.toFixed(4)}$)
                  </span>
                </td>
                <td valign="middle">
                  <span className="history-text">
                    {moment(new Date(el?.createdAt))
                      .locale(i18n.language)
                      .format("DD/MM/YYYY (hh:mm)")}
                    {/* {timeAgo(el?.createdAt, t, i18n.language)} */}
                  </span>
                </td>
                {/* <td valign="middle">
                <Button
                  style={{
                    fontSize: 14,
                    fontWeight: 400,
                    borderRadius: 20,
                    padding: "4px 12px",
                    color: props?.colors?.text1,
                    backgroundColor: props?.colors?.bg1,
                    border: "2px solid #E6E8EC",
                  }}
                >
                  {t("Accept")}
                </Button>
              </td> */}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </StylesWrapper>
  );
};

export default NFTBidding;
