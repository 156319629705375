import { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ReactComponent as Twitter } from "@assets/icons/twitter-share.svg";
import { ReactComponent as Facebbok } from "@assets/icons/facebook-share.svg";
import { ReactComponent as Copy } from "@assets/icons/copy-chain.svg";
// import linkedin from "../assets/icons/linkedin-share.svg";
// import Favorite from "./FavoriteIcon";
import { Link } from "react-router-dom";
import { intervalToDuration } from "date-fns";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import styled from "styled-components";
import { CreatorName } from "./CreatorName";

import moment from "moment";

//store
import { useSelector } from "react-redux";
import keys from "../lib/config";
import { Badge } from "reactstrap";

const options = [
  {
    Icon: Twitter,
    type: "twitter",
    link: "https://twitter.com/i/flow/login",
    Comp: TwitterShareButton,
  },
  {
    Icon: Facebbok,
    type: "fb",
    link: "https://www.instagram.com/nifty_souq",
    Comp: FacebookShareButton,
  },
  {
    Icon: Copy,
    type: "copy",
    link: "",
    Comp: "div",
  },
  // {
  //   icon: linkedin,
  //   type: "linkedin",
  //   link: "https://www.linkedin.com/company/niftysouq/",
  //   Comp: LinkedinShareButton,
  // },
];
const Wrapper = styled.div`
  .main {
    padding-bottom: 90px;
  }
  .tag {
    color: #6d3190;
    border-radius: 57px;
    background-color: #f4f4f4;
  }
  .title {
    font-size: 40px;
  }
  .description {
    color: #7b7b7b;
    font-weight: 350;
    line-height: 27.2px;
  }
  .price-label {
    color: #afa9bd;
    margin-inline-start: 1.5px;
    margin-inline-end: 1.5px;
  }
  .chip {
    display: inline-block;
    margin: 15px;
    margin-right: 22px;
    margin-left: 0px;
    margin-top: 0;
    border-radius: 76.66px;
    background-color: #ffffff;
    border: #d3d3d3 solid 2.17px;
    align-items: center;
    padding-inline-start: 10px;
    padding-inline-end: 25px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .padding {
    padding: 4px 45px;
  }
  .padding-horizontal {
    padding-left: 40px;
    padding-right: 30px;
  }
  .img {
    object-fit: cover;
  }
  .collection {
    font-size: 18px;
    text-align: center;
  }
  .link {
    height: 100%;
  }
  .share-link {
    font-size: 16px;
    font-weight: 350;
    color: #151515;
  }
  .featured-image {
    width: 550px;
    height: auto;
    object-fit: cover;
    border-radius: 14px;
    filter: drop-shadow(0px 35px 30px rgba(0, 0, 0, 0.09));
  }
  @media (max-width: 992px) {
    .container {
      margin-top: 25px;
    }
    .featured-image {
      width: 350px;
    }
  }
  .tooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 1;
    font-size: 12px;
    font-weight: 350;
    font-family: GothamNarrow;
    padding: 3px 4px 3px 4px;
    background: #1c0039;
    border: #1c0039 solid 1px;
    border-radius: 5px;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    color: #fff;
    left: ${(props) => (props.isLTR ? "125%" : "unset")};
    right: ${(props) => (props.isLTR ? "unset" : "125%")};
  }
  .tooltip-arrow {
    position: absolute;
    top: 35%;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: ${(props) => (props.isLTR ? "5px solid #1c0039" : "unset")};
    border-left: ${(props) => (props.isLTR ? "unset" : "5px solid #1c0039")};
    right: ${(props) => (props.isLTR ? "100%" : "unset")};
    left: ${(props) => (props.isLTR ? "unset" : "100%")};
  }
  .icon {
    margin-inline-start: 0;
    margin-inline-end: 18px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 65px;
    }
    .icon {
      margin-inline-end: 10px;
      margin-inline-start: 10px;
    }
  }
`;
const DropDownList = styled("div")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  box-sizing: border-box;
  color: #23262f;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
`;
const ListItem = styled("li")`
  color: #777e90;
  font-weight: 400;
  list-style: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 48px;
  &:hover {
    background-color: #f4f5f6;
  }
`;

const FeaturedItem = () => {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language !== "ar";

  const shareOptionsRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const [duration, setDuration] = useState(null);
  const { loading, error, nft } = useSelector((state) => state.featured_nfts);

  const toggling = () => setIsOpen(!isOpen);

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let startTimerVar = null;

    function startTimer() {
      if (nft?.listing?.sale_type === "2" && nft?.listing?.auction_end_date)
        if (
          moment(new Date(nft?.listing?.auction_end_date)).isAfter(new Date())
        )
          startTimerVar = setInterval(() => {
            let dur = intervalToDuration({
              start: new Date(),
              end: new Date(nft?.listing?.auction_end_date),
            });
            setDuration(dur);
          }, 1000);

      return {
        if(startTimerVar) {
          clearInterval(startTimerVar);
        },
      };
    }
    startTimer();
  }, [nft?.listing]);
  return (
    <>
      {nft && nft?._id && (
        <section id="home">
          <Wrapper isLTR={isLTR}>
            <Container fluid="lg" className="main">
              {/* if loading */}
              {loading && <p>{t("Loading...")}</p>}
              {/* if error */}
              {error && <p>{error}</p>}
              {/* if the featured item exists */}
              <Row>
                <Col
                  sm={12}
                  lg={6}
                  className="px-lg-5 d-flex justify-content-center align-items-center"
                >
                  <Link to={`/nft/${nft?.token_standard}/${nft?._id}`}>
                    {nft?.type === "image" ? (
                      <img alt="" className="featured-image" src={nft?.url} />
                    ) : nft?.type === "video" ? (
                      <video
                        muted
                        autoPlay
                        loop
                        className="featured-image"
                        playsInline
                      >
                        <source src={nft?.url} type="video/mp4" />
                      </video>
                    ) : (
                      nft?.type === "audio" && (
                        <div className="featured-image d-flex align-items-center justify-content-center">
                          <audio controls controlsList="nodownload" muted>
                            <source src={nft?.url} type="audio/mp3" />
                          </audio>
                        </div>
                      )
                    )}
                  </Link>
                </Col>
                <Col
                  sm={12}
                  lg={6}
                  className="d-flex align-items-center justify-content-center "
                >
                  <div className="ps-lg-0" style={{ width: "100%" }}>
                    {nft?.status && (
                      <Badge
                        color="secondary"
                        style={{ padding: "10px 20px" }}
                        pill
                      >
                        {nft?.status}
                      </Badge>
                    )}
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <div className="text-normal title">{nft?.name}</div>
                    </div>
                    <div className="d-flex creator-buy-box justify-content-center justify-content-lg-start flex-wrap mt-4">
                      <div className="chip">
                        <CreatorName item={nft} />
                      </div>
                      <div className="chip">
                        <Link
                          to={`/profile/${nft?.owner?._id}`}
                          className="d-flex align-items-center link"
                        >
                          <img
                            src={
                              nft?.owner?.profile?.image_url ||
                              "/user-placeholder.png"
                            }
                            alt="user"
                            className="me-2 user-40-rounded img"
                          />
                          <span className="creator-box">
                            <span>{nft?.owner?.name}</span>
                            <span className="d-block text-muted">
                              {t("Owner")}
                            </span>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <div className="mt-4">
                        <div className="text-normal">{t("Description")}</div>
                        <div className="mt-2 text-normal description">
                          {nft?.description}
                        </div>
                      </div>
                      {/*  */}
                      {nft?.listing?.sale_type == "1" ? (
                        <div className="mt-4 d-flex justify-content-center justify-content-lg-start">
                          <div>
                            <div className="text-normal price-label">
                              {t("Fixed Price")}
                            </div>
                            <div className="current-bid">
                              {nft?.listing?.price || 0} {nft?.blockchain}
                            </div>
                          </div>
                        </div>
                      ) : nft?.listing?.sale_type == "2" ? (
                        <div className="mt-4 d-flex justify-content-center justify-content-lg-start">
                          <div>
                            <div className="text-normal price-label">
                              {t("Reserve Price")}
                            </div>
                            <div className="current-bid">
                              {nft?.listing?.price || 0} {nft?.blockchain}
                            </div>
                          </div>
                          <div className="padding-horizontal">
                            <div className="divider" />
                          </div>
                          <div>
                            {moment(
                              new Date(nft?.listing?.auction_end_date)
                            ).isAfter(new Date()) ? (
                              <div>
                                <div className="mx-2 text-normal price-label">
                                  {t("Auction Ends in")}
                                </div>
                                <div className="current-bid">
                                  <span className="mx-2">
                                    {duration?.days || "00"}
                                    <span className="price-label">
                                      {t("d")}
                                    </span>
                                  </span>
                                  <span className="mx-2">
                                    {duration?.hours || "00"}
                                    <span className="price-label">
                                      {t("h")}
                                    </span>
                                  </span>
                                  <span className="mx-2">
                                    {duration?.minutes || "00"}
                                    <span className="price-label">
                                      {t("m")}
                                    </span>
                                  </span>
                                  <span className="mx-2">
                                    {duration?.seconds || "00"}
                                    <span className="price-label">
                                      {t("s")}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div className="mx-2 text-normal price-label">
                                  {t("Auction Ended")}
                                </div>
                                <div className="mx-2 current-bid">
                                  <span>
                                    {moment(
                                      new Date(nft?.listing?.auction_end_date)
                                    )
                                      .locale(i18n.language)
                                      .fromNow()}
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : null}
                      <div className="mt-3 pt-2 d-flex justify-content-center justify-content-lg-start align-items-center">
                        {nft?.listing?.sale_type == "1" ? (
                          <div className="me-4">
                            <Link
                              to={`/nft/${nft?.token_standard}/${nft?._id}`}
                              className="btn btn-primary gradient"
                            >
                              {t("Buy Now")}
                            </Link>
                          </div>
                        ) : nft?.listing?.sale_type == "2" ? (
                          <div className="me-4">
                            <Link
                              to={`/nft/${nft?.token_standard}/${nft?._id}`}
                              className="btn btn-primary gradient"
                            >
                              {t("Place a Bid")}
                            </Link>
                          </div>
                        ) : null}
                        <div>
                          <Link
                            className="btn-border-gradient"
                            to={`/nft/${nft?.token_standard}/${nft?._id}`}
                          >
                            {t("View Item")}
                          </Link>
                        </div>
                      </div>
                      <div className="mt-5 pt-2">
                        <div className="d-flex justify-content-center justify-content-lg-start share-link">
                          {t("Share this NFT")}
                        </div>
                        <div className="mt-3 d-flex justify-content-center justify-content-lg-start">
                          {options.map(({ Icon, type, Comp }) => {
                            const link = `${window.FRONT_END_URL}/nft/${nft?.token_standard}/${nft?._id}`;
                            return (
                              <Comp
                                key={Math.random()}
                                className="icon cursor"
                                url={link}
                                quote={type == "fb" ? nft?.name : ""}
                                title={type == "fb" ? "" : nft?.name}
                                onClick={
                                  type == "copy"
                                    ? () => {
                                        setShowTooltip(true);
                                        navigator.clipboard.writeText(link);
                                        setTimeout(
                                          () => setShowTooltip(false),
                                          1000
                                        );
                                      }
                                    : undefined
                                }
                              >
                                <div style={{ position: "relative" }}>
                                  <Icon
                                    width="25"
                                    height="25"
                                    color="#1C0039"
                                  />
                                  {type == "copy" && (
                                    <div
                                      className="tooltip"
                                      style={{ opacity: showTooltip ? 1 : 0 }}
                                    >
                                      {t("copied")}
                                      <span className="tooltip-arrow" />
                                    </div>
                                  )}
                                </div>
                              </Comp>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

export default FeaturedItem;
