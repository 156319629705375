import React, { useEffect, useState } from "react";
import { Form, Button, Spinner, Image } from "react-bootstrap";
import { Modals } from "./Modals";
import { NIFTYSouq721, NIFTYSouq1155, chains } from "@config";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3Context } from "@context/Web3";
//web3 integraion
import { useWeb3React } from "@web3-react/core";

//svg file
import ModalAndOverlay from "@components/ModalAndOverlay";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import visamaster from "@assets/icons/visa-master.png";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  nftActionsCreators,
  walletActionsCreators,
  authActionsCreators,
} from "../store";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
//wert
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";
import styled from "styled-components";
import { setMaxListeners } from "events";

const CancelSale = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    font-weight: 500;
    color: ${(props) => props.colors?.text1};
  }
  .note {
    font-size: 24px;
  }
  .action {
    font-weight: 400;
    color: #6d3190;
  }
  .note1 {
    font-size: 14px;
    margin-top: 20px;
    width: 55%;
  }
  .light-text {
    font-weight: 325;
  }
`;
export const CancelSaleModal = (props) => {
  const auth_account = useSelector((state) => state.auth);
  const { externalNFT } = props;

  const isBezelClub = externalNFT?.club == "TheBezelClub";

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { cancelNFTCreator } = bindActionCreators(nftActionsCreators, dispatch);
  const { saveWalletsCreator } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );
  const { nft, error, selectedOwner } = props;
  const is1155 = nft?.token_standard == 1155;
  const isFixedPrice = nft?.listing?.sale_type == "1";

  const { chainId, account } = useWeb3React();
  const { cancelListing, cancelAuction } = useWeb3Context();
  const { userPrincipal, privateKey, getAccounts, chain } = useWeb3Auth();

  //when create token , stauts
  const [status, setStatus] = useState(0);
  //is stauts
  const [isMultiple, setMultiple] = useState(props.status);
  //basic datas
  const [defaultWallet, setDefaultWallet] = useState("");
  const [walletOptions, setWalletOptions] = useState([]);
  const [selectedWallet, setSelectWallet] = useState(nft?.owner?.create_wallet);
  const [isFiat, setFiatPay] = useState(false);
  const [txHash, setTx] = useState("");
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");
  const endDate = 0 * 86400;
  const [tokenData, setTokenData] = useState({
    name: nft?.name,
    price: "",
    creators: nft?.creators?.minter?.create_wallet,
    royalties: nft?.royalties?.royalties.map(function (item) {
      return item["royalty"];
    }),
    amount: nft?.editions?.current,
    isLazymint: nft?.is_lazy,
    property: "",
    description: nft?.description,
    isList: false,
    isAuction: false,
    startBidPrice: "",
    reservePrice: "",
    auctionPeriod: endDate,
    categories: [],
    chain: chain,
  });
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC",
    commodity_amount: "0.003",
    address: selectedWallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
        console.log("status", status);
      },
    },
  };

  const wertWidget = new WertWidget(options);

  //canceling function
  const cancel = async (isFiat) => {
    if (isFiat) {
      setStatus(1);
      wertWidget.mount();
    } else {
      try {
        setStatus(1);
        isFixedPrice ? await cancelSale() : await cancelAuctionSale();
      } catch (e) {
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: e.message,
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };

  const cancelSale = async () => {
    try {
      let cancelEditions = [];
      if (is1155) {
        // const user = await extractLoggedInOwner(
        //   auth_account.account._id,
        //   nft?.owners
        // );
        // cancelEditions = await getEditions(
        //   selectedOwner?.listed_editions,
        //   tokenData.amount
        // );
        cancelEditions = selectedOwner?.listed_editions;
      }

      if (nft?.is_lazy) {
        const cancelData = {
          tx: "0x00",
          editions: cancelEditions,
          wallet: selectedWallet,
          payment_method: isBezelClub ? "USDC" : chain,
        };
        cancelNFTCreator(nft?._id, nft?.token_standard, cancelData);
        setStatus(2);
      } else {
        const res = await cancelListing(
          nft?.token_standard === "721"
            ? nft?.offer_id
            : selectedOwner?.offer_id,
          selectedWallet === props.defaultWallet
        );
        console.log(
          selectedOwner.offer_id,
          selectedOwner?.user?.create_wallet,
          props.defaultWallet
        );
        const cancelData = {
          editions: cancelEditions,
          tx: res.data.transactionHash,
          wallet: selectedWallet,
          payment_method: isBezelClub ? "USDC" : chain,
        };
        //Save to backend
        if (res.status) {
          cancelNFTCreator(nft?._id, nft?.token_standard, cancelData);
          setTx(res.data.transactionHash);
          setStatus(2);
        } else {
          Swal.fire({
            icone: "error",
            title: t("oops"),
            text: t("tx_canceled"),
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  const cancelAuctionSale = async () => {
    try {
      if (nft?.is_lazy) {
        setTokenData({
          ...tokenData,
          tx: "0x00",
        });
        const cancelData = {
          editions: [],
          tx: "0x00",
          wallet: selectedWallet,
          payment_method: isBezelClub ? "USDC" : chain,
        };
        cancelNFTCreator(nft?._id, nft?.token_standard, cancelData);
        setStatus(2);
      } else {
        const res = await cancelAuction(
          nft?.offer_id,
          selectedWallet === props.defaultWallet
        );
        if (res.status) {
          const cancelData = {
            editions: [],
            tx: res.data.transactionHash,
            wallet: selectedWallet,
            payment_method: isBezelClub ? "USDC" : chain,
          };
          //Save to backend
          cancelNFTCreator(nft?._id, nft?.token_standard, cancelData);
          setTx(res.data.transactionHash);
          setStatus(2);
        }
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  const dataload = async () => {
    //get default wallet
    const wallet = await getAccounts();
    auth_account.account &&
      wallet.address &&
      !auth_account.account?.wallets.includes(wallet.address) &&
      importWalletCreator({ wallet: wallet.address });
    saveWalletsCreator(0, wallet.address);
    setSelectWallet(
      nft?.token_standard === "721"
        ? nft?.owner?.create_wallet
        : selectedOwner?.user?.create_wallet
    );
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }
    setWalletOptions(wallets);
    //contrat setting
    setMultiple(props.status);
  };

  useEffect(() => {
    if (privateKey) {
      dataload();
    }
  }, [userPrincipal, privateKey, account, chain]);

  useEffect(() => {
    dataload();
  }, [isMultiple]);
  const { closeOfferModal } = props;

  return (
    <div>
      <ModalAndOverlay colors={props.colors}>
        <CancelSale>
          <div>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading">{t("Cancel Sale")}</h3>
              <CloseIcon
                className="modal-close-icon"
                color={props.colors?.text1}
                onClick={() => setFiatPay(false)}
              />
            </div>
            <div id="wert-widget" />
            {wertPaymentStatus == "success" && (
              <Button
                className={`modal-button ${props?.colors?.button}`}
                onClick={() => {
                  setFiatPay(false);
                  cancel();
                }}
              >
                {t("Proceed")}
              </Button>
            )}
          </div>
        </CancelSale>
      </ModalAndOverlay>

      {!isFiat && (
        <ModalAndOverlay colors={props.colors}>
          <CancelSale>
            <Modals colors={props.colors}>
              <div className="d-flex justify-content-between mb-4">
                <h3 className="modal-heading title">
                  {isFixedPrice ? t("cancel__sale") : t("cancel_auction")}
                </h3>
                <CloseIcon
                  className="modal-close-icon"
                  color={props.colors?.text1}
                  onClick={closeOfferModal}
                />
              </div>
              {status === 0 ? (
                <>
                  {isFixedPrice ? (
                    <div>
                      <div className="note">
                        {t("are_you_sure")}
                        <div className="action">{t("cancel_sale")}</div>
                      </div>
                      <div className="note1">
                        {t("note")} :{" "}
                        <span className="light-text">{t("cancel_note")}</span>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="note">
                        {t("are_you_sure")}
                        <div className="action">{t("cancel_auction")}</div>
                      </div>
                      <div className="note1">
                        {t("note")} :{" "}
                        <span className="light-text">{t("cancel_note")}</span>
                      </div>
                    </div>
                  )}
                </>
              ) : status === 2 ? (
                <>
                  <h2>{t("Yay!")} 🎉</h2>
                  <div className="message p-4">
                    <div className="d-flex table-title mb-3">
                      <span>{t("Status")}</span>
                      <span>{t("Transaction ID")}</span>
                    </div>
                    <div className="d-flex table-row">
                      <span>{t("Success")}</span>
                      <span>
                        <a
                          href={
                            chain === "ETH"
                              ? window.ETH_SCAN + "/" + `${txHash}`
                              : window.POLYGON_SCAN + "/" + `${txHash}`
                          }
                        >
                          {txHash.substring(40)}
                        </a>
                      </span>
                    </div>
                  </div>
                </>
              ) : status === 3 ? (
                <>
                  <h4>{t("transaction_failed")} 😢</h4>
                  <div className="message p-4">
                    <div className="d-flex table-row">
                      <span>{error}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="mb-4 pb-2">
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      {status === 1 ? (
                        <Spinner animation="border" variant="primary" />
                      ) : status > 1 ? (
                        <Image src={completedIcon}></Image>
                      ) : (
                        <Image src={lockOrderIcon}></Image>
                      )}
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <h5 className="m-0 modal-section-title title">
                        <span>{t("Delist NFT")}</span>
                      </h5>
                      <p className="m-0 modal-section-text">
                        {t("Call contract method")}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <Button
                className="modal-button mb-3 mt-4"
                disabled={status == 1 ? true : false}
                onClick={status === 2 ? closeOfferModal : () => cancel()}
              >
                {status === 0
                  ? t("Confirm")
                  : status === 1
                  ? t("Delisting...")
                  : t("Done")}
              </Button>
              {/* {!nft?.is_lazy && (
                <Button
                  className={`modal-button mb-3 ${props?.colors?.button}`}
                  id="cancel-fiat"
                  disabled={status >= 1 ? true : false}
                  onClick={() => {
                    setFiatPay(true);
                    cancel(true);
                  }}
                >
                  {status === 0
                    ? t("Cancel with card")
                    : status === 1
                    ? t("Calling...")
                    : "Done"}{" "}
                  <img
                    className="visaMaster"
                    src={visamaster}
                    width="80"
                    height="30"
                  />
                </Button>
              )} */}

              {/* <Button
              className={`modal-button ${props?.colors?.button}`}
              disabled={status === 0 || status === 2 ? false : true}
              onClick={() => props.closeOfferModal}
            >
              {t("Cancel")}
            </Button> */}
            </Modals>
          </CancelSale>
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default CancelSaleModal;
