import ReactDOM from "react-dom";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(35, 38, 47, 0.9);
  z-index: 9999;
`;

const Modal = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  width: 450px;
  padding: 32px;
  border-radius: 16px;
  overflow: scroll;
  max-height: 95vh;
  color: ${(props) => props?.colors?.text};
  background-color: ${(props) => props?.colors?.bg || "#fff"};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ModalAndOverlay = (props) => {
  return ReactDOM.createPortal(
    <div style={{ zIndex: 999, position: "absolute" }}>
      <Overlay />
      <Modal>
        <Card colors={props.colors}>{props.children}</Card>
      </Modal>
    </div>,
    document.getElementById("modal")
  );
};

export default ModalAndOverlay;
