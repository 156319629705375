import * as galleryTypes from "../actions/types/gallery";
import { ACTION_STATUS } from "../../common/constants/gallery";

const galleryState = {
  createGallery: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  updateGallery: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  featuredGalleries: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  galleries: {
    loading: false,
    status: ACTION_STATUS.UNSET,
    data: [],
    count: 0,
    has_more: true,
    error: null,
  },
  publicGalleries: {
    loading: false,
    data: [],
    count: 0,
    has_more: true,
    error: null,
  },
  getGalleryById: {
    loading: true,
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  deleteGallery: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
};

export const galleryReducer = (state = galleryState, action) => {
  switch (action.type) {
    case galleryTypes.FETCH_FEATURED_GALLERIES:
      return {
        ...state,
        featuredGalleries: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case galleryTypes.FETCH_FEATURED_GALLERIES_SUCCESS:
      return {
        ...state,
        featuredGalleries: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload[0],
        },
      };
    case galleryTypes.FETCH_FEATURED_GALLERIES_ERROR:
      return {
        ...state,
        featuredGalleries: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case galleryTypes.FETCH_GALLERIES:
      return {
        ...state,
        galleries: {
          ...state.galleries,
          status: ACTION_STATUS.LOADING,
          loading: true,
        },
      };
    case galleryTypes.FETCH_GALLERIES_SUCCESS:
      return {
        ...state,
        galleries: {
          data: action.load
            ? [...state.galleries.data, ...action.payload]
            : action.payload,
          count: action.count,
          has_more: action.has_more,
          error: null,
          status: ACTION_STATUS.COMPLETE,
          loading: false,
        },
      };
    case galleryTypes.FETCH_GALLERIES_ERROR:
      return {
        ...state,
        galleries: {
          ...state.galleries,
          loading: ACTION_STATUS.FAILURE,
          error: action.error,
          loading: false,
        },
      };

    case galleryTypes.FETCH_PUBLIC_GALLERIES:
      return {
        ...state,
        publicGalleries: { ...state.publicGalleries, loading: true },
      };
    case galleryTypes.FETCH_PUBLIC_GALLERIES_SUCCESS:
      return {
        ...state,
        publicGalleries: {
          data: action.load
            ? [...state.publicGalleries.data, ...action.payload]
            : action.payload,
          count: action.count,
          has_more: action.has_more,
          error: null,
          loading: false,
        },
      };
    case galleryTypes.FETCH_PUBLIC_GALLERIES_ERROR:
      return {
        ...state,
        publicGalleries: {
          ...state.publicGalleries,
          loading: false,
          error: action.error,
        },
      };
    case galleryTypes.CREATE_GALLERY:
      return {
        ...state,
        createGallery: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case galleryTypes.CREATE_GALLERY_SUCCESS:
      return {
        ...state,
        createGallery: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case galleryTypes.CREATE_GALLERY_ERROR:
      return {
        ...state,
        createGallery: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case galleryTypes.CREATE_GALLERY_RESET:
      return {
        ...state,
        createGallery: galleryState.createGallery,
      };
    case galleryTypes.UPDATE_GALLERY:
      return {
        ...state,
        updateGallery: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case galleryTypes.UPDATE_GALLERY_SUCCESS:
      return {
        ...state,
        updateGallery: {
          status: ACTION_STATUS.COMPLETE,

          entities: action.payload,
        },
      };
    case galleryTypes.UPDATE_GALLERY_ERROR:
      return {
        ...state,
        updateGallery: {
          status: ACTION_STATUS.FAILURE,

          error: action.payload,
        },
      };
    case galleryTypes.UPDATE_GALLERY_RESET:
      return {
        ...state,
        updateGallery: galleryState.updateGallery,
      };

    case galleryTypes.DELETE_GALLERY:
      return {
        ...state,
        deleteGallery: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case galleryTypes.DELETE_GALLERY_SUCCESS:
      return {
        ...state,
        deleteGallery: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case galleryTypes.DELETE_GALLERY_ERROR:
      return {
        ...state,
        deleteGallery: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case galleryTypes.DELETE_GALLERY_RESET:
      return {
        ...state,
        deleteGallery: galleryState.deleteGallery,
      };
    case galleryTypes.FETCH_GALLERY_BY_ID:
      return {
        ...state,
        getGalleryById: {
          loading: true,
          status: ACTION_STATUS.LOADING,
        },
      };
    case galleryTypes.FETCH_GALLERY_BY_ID_SUCCESS:
      return {
        ...state,
        getGalleryById: {
          loading: false,
          status: ACTION_STATUS.COMPLETE,
          entities:
            action.payload && action.payload[0] ? action.payload[0] : {},
        },
      };
    case galleryTypes.FETCH_GALLERY_BY_ID_ERROR:
      return {
        ...state,
        getGalleryById: {
          loading: false,
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case galleryTypes.GALLERY_BY_ID_RESET:
      return {
        ...state,
        getGalleryById: galleryState.getGalleryById,
      };
    default:
      return state;
  }
};
