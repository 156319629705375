import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import profile from "../../assets/icons/header/profile.svg";
import wallet from "../../assets/icons/header/wallet.svg";
import logOut from "../../assets/icons/header/log-out.svg";
import { useWeb3Auth } from "@context/Web3auth";
import { AuthContext } from "../../context/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { authActionsCreators } from "../../store";
import galleryIcon from "../../assets/icons/header/gallery-menu.svg";
import styled from "styled-components";

const Wrapper = styled(NavDropdown)`
  .user-avatar-small {
    min-width: 30px;
    width: 30px;
    height: 30px;
    border-radius: 500px;
    border: #bfbfbf solid 1.13px;
  }
  .user-avatar-big {
    min-width: 40px;
    width: 40px;
    height: 40px;
    border-radius: 500px;
    border: #bfbfbf solid 1.13px;
  }
  .label {
    font-weight: 325;
    color: #777e90;
  }
  .name {
    font-size: 18px;
  }
  @media (max-width: 400px) {
    .user-avatar-big {
      min-width: 30px;
      width: 30px;
      height: 30px;
    }
  }
`;

const UserNavDropdown = ({ navStyle, img }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useWeb3Auth();

  const authContext = useContext(AuthContext);

  const { account } = useSelector((state) => state.auth);

  const { logoutCreator } = bindActionCreators(authActionsCreators, dispatch);

  const Logout = async () => {
    authContext.logoutUser();
    logoutCreator();
    await logout();
    navigate("/");
  };
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  return (
    <NavDropdown
      // show={show}
      // onMouseEnter={showDropdown}
      // onMouseLeave={hideDropdown}
      align="end"
      title={
        <img
          alt="user"
          className="user-40-rounded"
          src={account?.profile?.image_url || "/user-placeholder.png"}
        />
      }
      className={`user-menu mx-0 my-0 ${navStyle} text-start`}
    >
      <div className="py-3 px-2">
        <p className="mb-1 label">{t("Welcome")}</p>
        <div className="name">{account?.name}</div>
      </div>
      <NavDropdown.Item href={`/profile/${account?._id}`} className="py-3 px-2">
        <img className="me-3" src={profile} alt="" />
        <span>{t("Profile")}</span>
      </NavDropdown.Item>
      <NavDropdown.Item href={`/wallet`} className="py-3 px-2">
        <img className="me-3" src={wallet} alt="" />
        <span>{t("Wallet")}</span>
      </NavDropdown.Item>
      <NavDropdown.Item href={`/gallery`} className="py-3 px-2">
        <img
          className="me-3"
          src={galleryIcon}
          width="17px"
          height="16px"
          alt="exploreIcon"
        />
        <span>{t("My Gallery")}</span>
      </NavDropdown.Item>
      <NavDropdown.Item href="" className="py-3 px-2" onClick={Logout}>
        <img className="me-3" src={logOut} alt="" />
        <span>{t("Logout")}</span>
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserNavDropdown;
