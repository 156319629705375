import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import Favorite from "./FavoriteIcon";
import { truncate } from "../core/string";
import ScrollBar from "./ScrollBar";
import { Link } from "react-router-dom";
import { intervalToDuration } from "date-fns";
import { NftCard } from "@components/NftCard";
import styled from "styled-components";
import moment from "moment";

//store
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  .main {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 65px;
    }
  }
  .card {
    positions: relative;
    min-width: 291px;
    max-width: 291px;
    margin-right: 15px;
    margin-left: 10px;
    border: 1px solid #efefef;
    // box-shadow: 0px 5.0181px 0px 1.8258px rgba(214, 214, 214, 0.25);
    border-radius: 13.8258px;
  }
  .wrapper {
    width: 264px;
    height: 255px;
  }
  .image {
    object-fit: cover;
  }
  .radius {
    border-radius: 9.2px;
  }
  .creator {
    color: #000;
    font-size: 14px;
    font-weight: 450;
    number-of-lines: 1;
  }
  .tag {
    color: #bdbdbd;
    font-size: 13px;
    font-weight: 400;
  }
  .name {
    font-size: 16px;
    color: #1c0039;
  }
  .divider {
    width: 100%;
    height: 0.66px;
    background-xolor: #e5e4de;
  }
  .label {
    color: #afa9bd;
    font-size: 12px;
  }
  .note {
    color: #afa9bd;
    height: 100%;
    font-size: 14px;
  }
  .value {
    color: #1c0039;
    font-size: 14px;
    font-weight: 400;
  }
  .card:hover {
    .overlay {
      visibility: visible;
      opacity: 1;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    min-width: 291px;
    border-radius: 13.8258px;
    z-index: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.25s linear;
  }
`;

const LatestNFTs = () => {
  const { t, i18n } = useTranslation();
  const { account } = useSelector((state) => state.auth);
  const { loading, error, nfts } = useSelector((state) => state.latest_nfts);

  return (
    <>
      {nfts && nfts?.length > 0 && (
        <section id="latest" style={{ backgroundColor: "#F4F4F4" }}>
          <Wrapper>
            <Container fluid="lg" className="main">
              {/* if loading */}
              {loading && <p>{t("Loading...")}</p>}
              {/* if error */}
              {error && <p>{error}</p>}
              {/* if the featured item exists */}

              <div>
                <ScrollBar
                  data={nfts
                    .filter(
                      (e) =>
                        e.listing?.sale_type === "1" ||
                        e.listing?.sale_type === "2"
                    )
                    .map((filteredItem) => filteredItem)}
                  link="discover"
                  title={t("Latest")}
                  subtitle={t("NFTs")}
                  renderCard={(item) => {
                    let props = { item };
                    console.log(item);
                    if (item.artist) {
                      props.userData = {
                        _id: item.artist?._id,
                        name: item.artist?.name,
                        img: item.artist?.profileImage,
                      };
                      props.creator = item.creators?.minter;
                    }
                    return <NftCard {...props} key={Math.random()} />;
                  }}
                />
              </div>
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

export default LatestNFTs;
