import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";
import { galleryActionsCreators } from "../../../../store";
import {
  getGalleryDropDownConfig,
  GALLERY_STATUS,
  ACTION_STATUS,
} from "../../../../common/constants/gallery";
import GalleryNav from "./galleryNav";
import CardList from "../components/CardList";
import ConfirmationModal from "../components/Modal/ConfirmationModal";
import emptyGallery from "../../../../assets/images/empty-gallery.png";
import createGalleryImage from "../../../../assets/images/create-gallery.jpg";
import "./styles.css";

const CreatedGalleries = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDeleted, setIsDeleted] = useState(false);
  const [status, setStatus] = useState(GALLERY_STATUS.approved);
  const [selectedId, setSelectedId] = useState(null);
  const { data, loading } = useSelector((state) => state.gallery.galleries);
  const deleteGalleyStatus = useSelector(
    (state) => state.gallery.deleteGallery.status
  );
  const {
    getAllGalleriesCreator,
    deleteGalleryCreator,
    resetDeleteGalleryCreator,
  } = bindActionCreators(galleryActionsCreators, dispatch);

  useEffect(() => {
    getAllGalleriesCreator({ limit: 1000 });
  }, []);

  useEffect(() => {
    if (deleteGalleyStatus === ACTION_STATUS.COMPLETE) {
      setSelectedId(null);
      Swal.fire({
        icon: "success",
        text: t("gallery_deleted"),
        timer: 2000,
        showConfirmButton: true,
        confirmButtonText: t("close"),
        didClose: () => {
          dispatch(resetDeleteGalleryCreator);
          getAllGalleriesCreator({});
        },
        confirmButtonColor: "#6d3190",
      });
    }
  }, [deleteGalleyStatus]);

  const handleStatusChange = (e, status) => {
    e.preventDefault();
    setStatus(status);
  };

  const renderNoGalleryFoundWithStatus = () => {
    return (
      <div className="ga-no-data-found">
        <Row>
          <Col className="d-flex justify-content-center">
            <img
              src={emptyGallery}
              alt=""
              width="100%"
              height="200"
              style={{ objectFit: "contain" }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <p className="sub-gallery-text">
              {status == GALLERY_STATUS.approved
                ? t("no_approved_gallery_found")
                : status == GALLERY_STATUS.pendingReview
                ? t("no_pending_gallery_found")
                : t("no_rejected_gallery_found")}
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const handleSelect = (eventKey, data) => {
    if (eventKey === "editGallery") {
      navigate(`/gallery/edit/${data._id}`);
    }
    if (eventKey === "addGalleryArtist") {
      navigate(`/gallery/${data._id}/artist/${true}`);
    }
    if (eventKey === "deleteGallery") {
      setSelectedId(data._id);
      setIsDeleted(true);
    }
    if (eventKey === "viewReason") {
      navigate(`/gallery/${data._id}/rejection`);
    }
  };

  const getGalleriesByStatus = (data) => {
    return data.filter((gallery) => gallery.status === status);
  };
  return (
    <div>
      <div className="cover-photo">
        <img src={createGalleryImage} className="gallery-artist-bg" alt="" />
      </div>
      <Container style={{ maxWidth: "1120px" }}>
        <React.Fragment>
          <Row>
            <h1 className="cg-title">{t("Gallery")}</h1>
          </Row>
          <Row>
            <GalleryNav
              status={status}
              handleStatusChange={handleStatusChange}
            />
          </Row>
          {loading ? (
            <p>{t("Loading...")}</p>
          ) : (
            <Row>
              {getGalleriesByStatus(data)?.length > 0 ? (
                <CardList
                  dataList={getGalleriesByStatus(data)}
                  dropdownConfig={getGalleryDropDownConfig(t, status)}
                  type="gallery"
                  onSelect={handleSelect}
                />
              ) : (
                renderNoGalleryFoundWithStatus()
              )}
            </Row>
          )}
        </React.Fragment>
        <div className="mb-436px" />
        {isDeleted && (
          <ConfirmationModal
            title={t("gallery_deletion")}
            isOpen={isDeleted}
            text={t("gallery_deletion_msg")}
            submitBtnText={t("confirm")}
            isBtnIconEnable={true}
            onConfirmClick={() => {
              deleteGalleryCreator(selectedId);
              setIsDeleted(false);
            }}
            onHideClick={() => setIsDeleted(false)}
          />
        )}
      </Container>
    </div>
  );
};

export default CreatedGalleries;
