/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Row,
  Col,
  Spinner,
  FormControl,
  Button,
} from "react-bootstrap";
// import { Link } from 'react-router-dom'
import "../pages/profile.css";
import styled from "styled-components";
// import facebook from "../icons/facebook.svg";
// import twitter from "../icons/twitter.svg";
// import instagram from "../icons/instagram.svg";
// import filterIcon from "../icons/filter.svg";
// import resetIcon from "../icons/reset.svg";
import DropDown from "../components/DropDown";
import profileCover from "../assets/images/profile-cover.png";
import noNFTs from "../assets/images/no-nfts.png";
import NFTs from "../components/Profile/NFTs";
import { Popover, PopoverBody } from "reactstrap";
import { ReactComponent as Twitter } from "@assets/icons/twitter-share.svg";
import { ReactComponent as Facebbok } from "@assets/icons/facebook-share.svg";
import { ReactComponent as Copy } from "@assets/icons/copy-chain.svg";

import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import edit from "./../assets/icons/edit.svg";
import uploadCircle from "./../assets/icons/upload.svg";
// import NFTSearchBox from "../components/searchBox/SearchBox";
import { NftCard } from "../components/NftCard";
import { TwitterShareButton, FacebookShareButton } from "react-share";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { creatorCollectionsCreators } from "../store";
import { useEffect, useState, useRef } from "react";
import { resetCollectionState } from "../store/actions/creators/creatorCollection";
import { useDebounce } from "../utils/useDebounce";
import threeDots from "./../assets/icons/activities/three-dots.svg";
import CreateCollectionPopup from "../pages/CreateCollectionPopup";
import Swal from "sweetalert2";
import { ConfirmDeleteModal } from "../components/modals/ConfirmDeleteModal";

const DropDownList = styled("div")`
  padding: 0;
  margin: 0;
  padding-left: 1em;
  background: #ffffff;
  box-sizing: border-box;
  color: #23262f;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
`;

const ListItem = styled("li")`
  color: #777e90;
  font-weight: 400;
  list-style: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 48px;
  &:hover {
    background-color: #f4f5f6;
  }
`;

export const CollectionNFTsComponents = () => {
  //* collection popup states
  const [isOpenCreateCollection, setOpenCreateCollection] = useState(false);
  const categoryState = useSelector((state) => state.category);
  const { artistId } = useParams();

  //* delete collection modal
  const [isOpenDeleteCollection, setOpenDeleteCollection] = useState(false);

  const { collectionId } = useParams();
  const [searchText, setSearchText] = useState("");
  const shareOptionsRef = useRef();
  const actionOptionsRef = useRef();
  const [requestObj, setRequestObj] = useState({
    categoryId: "",
    subcategoryId: "",
    searchText: "",
    sort: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [handleDuplicate, sethandleDuplicate] = useState(true);
  const [isActionOpen, setActionIsOpen] = useState(false);

  const { collections } = useSelector((state) => state.artist);

  const debouncedSearchTerm = useDebounce(searchText, 1000);
  const { account } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const {
    fetchCollectionNFTsCreator,
    deleteCollectionCreator,
    resetCollectionFlags,
    fetchCollectionDetailCreator,
  } = bindActionCreators(creatorCollectionsCreators, dispatch);

  const { collectionNFTs, collectionDetail, loading, hasMore } = useSelector(
    (state) => state.creatorCollections
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const options = [
    { label: t("Price: Low to High"), id: "0", value: "price_asc" },
    { label: t("Price: High to Low"), id: "1", value: "price_desc" },
  ];

  const getConfirmationModal = () => {
    if (collections.action_success && !collections.action_loading) {
      if (handleDuplicate) {
        sethandleDuplicate(false);
        Swal.fire({
          icon: "success",
          text:
            collections.action === "edit"
              ? t("collection_edited")
              : collections.action === "delete" && t("collection_deleted"),
          timer: 2000,
          showConfirmButton: true,
          confirmButtonText: t("close"),
          didClose: () => {
            collections.action === "delete" && setOpenCreateCollection(false);
            collections.action_success = false;
            dispatch(resetCollectionFlags());
            sethandleDuplicate(true);
            {
              collectionDetail?.artist?._id
                ? navigate(`/created-artist/${collectionDetail?.artist?._id}`)
                : navigate(`/profile/${user?._id}/collections`);
            }
          },
          confirmButtonColor: "#6d3190",
        });
      }
    }
  };

  useEffect(() => {
    fetchCollectionDetailCreator(collectionId);
    return () => dispatch(resetCollectionState());
  }, []);

  useEffect(() => {
    if (requestObj) {
      fetchCollectionNFTsCreator(collectionId, requestObj, {
        skip: 0,
        limit: 12,
      });
    }
  }, [requestObj]);

  useEffect(() => {
    setRequestObj({ ...requestObj, searchText: debouncedSearchTerm });
  }, [debouncedSearchTerm]);
  const toggling = () => setIsOpen(!isOpen);
  const actionToggle = () => setActionIsOpen(!isActionOpen);

  const useClickOutsideShareOptionsRef = (handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (
          !shareOptionsRef.current ||
          shareOptionsRef.current.contains(event.target)
        ) {
          return;
        }
        handler(event);
      };

      document.addEventListener("click", listener);
      return () => document.removeEventListener("click", listener);
    }, [shareOptionsRef, handler]);

    return [shareOptionsRef];
  };
  useClickOutsideShareOptionsRef(() => setIsOpen(false));

  const getShareFeature = () => {
    return (
      <div ref={shareOptionsRef}>
        <img
          className="uploadcircle cursor"
          id="uploadoptions"
          src={uploadCircle}
          onClick={toggling}
          style={{
            padding: "8px",
          }}
          alt=""
        />
        <Popover
          className="upload-popover"
          placement="bottom"
          target="uploadoptions"
          toggle={toggling}
          isOpen={isOpen}
        >
          <PopoverBody>
            <DropDownList>
              <div className="d-flex flex-column">
                <TwitterShareButton
                  url={window.location.href}
                  title={collectionDetail?.title}
                >
                  <ListItem className="d-flex flex-row justify-content-start">
                    <div className="mx-1">
                      <Twitter width="15" height="15" />
                    </div>
                    <div>{t("Twitter")}</div>
                  </ListItem>
                </TwitterShareButton>
                <FacebookShareButton
                  url={window.location.href}
                  title={collectionDetail?.title}
                >
                  <ListItem className="d-flex flex-row justify-content-start">
                    <div className="mx-1">
                      <Facebbok width="15" height="15" />
                    </div>
                    <div>{t("Facebook")}</div>
                  </ListItem>
                </FacebookShareButton>
                <ListItem
                  className="d-flex flex-row justify-content-start"
                  onClick={() =>
                    navigator.clipboard.writeText(window.location.href)
                  }
                >
                  <div className="mx-1">
                    <Copy width="15" height="15" />
                  </div>
                  <div>{t("Copy link")}</div>
                </ListItem>
              </div>
            </DropDownList>
          </PopoverBody>
        </Popover>
      </div>
    );
  };

  const useClickOutsideActionOptionsRef = (handler) => {
    useEffect(() => {
      const listener = (event) => {
        if (
          !actionOptionsRef.current ||
          actionOptionsRef.current.contains(event.target)
        ) {
          return;
        }
        handler(event);
      };

      document.addEventListener("click", listener);
      return () => document.removeEventListener("click", listener);
    }, [actionOptionsRef, handler]);

    return [actionOptionsRef];
  };
  useClickOutsideActionOptionsRef(() => setActionIsOpen(false));

  const getActionDropDown = () => {
    return (
      <div ref={actionOptionsRef} style={{ marginInlineStart: "10px" }}>
        <img
          className="uploadcircle cursor"
          id="uploadoptions1"
          src={threeDots}
          onClick={actionToggle}
          style={{
            padding: "12px",
          }}
          alt=""
        />
        <Popover
          className="upload-popover"
          placement="bottom"
          target="uploadoptions1"
          toggle={actionToggle}
          isOpen={isActionOpen}
        >
          <PopoverBody>
            <DropDownList>
              <div className="d-flex flex-column">
                <ListItem
                  onClick={() =>
                    navigate(`/collection/activity/${collectionId}`)
                  }
                >
                  {t("View Activity")}
                </ListItem>
                {collectionDetail?.gallery_owner
                  ? account?._id === collectionDetail?.gallery_owner && (
                      <ListItem onClick={() => setOpenCreateCollection(true)}>
                        {t("edit_collection")}
                      </ListItem>
                    )
                  : account?._id === collectionDetail?.creator?._id && (
                      <ListItem onClick={() => setOpenCreateCollection(true)}>
                        {t("edit_collection")}
                      </ListItem>
                    )}
                {collectionDetail?.gallery_owner
                  ? account?._id === collectionDetail?.gallery_owner && (
                      <ListItem onClick={() => setOpenDeleteCollection(true)}>
                        {t("delete_collection")}
                      </ListItem>
                    )
                  : account?._id === collectionDetail?.creator?._id && (
                      <ListItem onClick={() => setOpenDeleteCollection(true)}>
                        {t("delete_collection")}
                      </ListItem>
                    )}
              </div>
            </DropDownList>
          </PopoverBody>
        </Popover>
      </div>
    );
  };

  return (
    <div>
      {/* cover photo */}
      <div className="cover-photo">
        <img
          src={collectionDetail?.coverImage || profileCover}
          className="profile-bg"
          alt=""
        />
      </div>
      <Container>
        <div className="profile-info">
          <div>
            <Row>
              <Col
                xs={12}
                lg={8}
                className="d-flex flex-column flex-lg-row align-items-center"
              >
                <img
                  src={collectionDetail?.logo || "/user-placeholder.png"}
                  alt=""
                  width="120"
                  height="120"
                  className="rounded-full me-3 mb-3"
                  style={{ objectFit: "cover" }}
                />
                <div className="text-center text-lg-start flex-fill">
                  <div className="page-header-md">
                    {collectionDetail?.title}
                  </div>
                  <p className="profile-message">
                    <span>{t("by")}</span>
                    <Link
                      className="member-since"
                      to={
                        collectionDetail?.artist
                          ? `/created-artist/${collectionDetail.artist?._id}`
                          : `/profile/${collectionDetail?.creator?._id}`
                      }
                    >
                      {collectionDetail?.artist
                        ? collectionDetail?.artist?.name
                        : collectionDetail?.creator?.name || ""}
                    </Link>
                  </p>
                  <div className="d-flex flex-row">
                    {getShareFeature()}
                    {getActionDropDown()}
                  </div>
                </div>
              </Col>
              {/* <Col xs={12} lg={4}>
                    {getShareFeature()}
                  </Col> */}
              <Col
                xs={12}
                lg={4}
                className="mt-4 d-flex justify-content-center justify-content-lg-end"
              >
                <div>
                  <div className="mb-3">
                    <button
                      className="followers-btn me-4"
                      style={{ cursor: "auto" }}
                    >
                      <span>{collectionDetail?.nftCount}</span>
                      {collectionDetail?.nftCount <= 1 ? t("Item") : t("Items")}
                    </button>
                    <button
                      className="followers-btn"
                      style={{ cursor: "auto" }}
                    >
                      <span>{collectionDetail?.purchaseCount}</span>{" "}
                      {t("Owners")}
                    </button>
                    <button
                      className="followers-btn"
                      style={{ cursor: "auto" }}
                    >
                      <span>{collectionDetail?.floorPrice || 0}</span>{" "}
                      {t("Floor price")}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* links */}
        <Row style={{ paddingTop: "30px" }}>
          <Col md={3} className="d-flex">
            <div className="d-flex ">
              <FormControl
                className="create-page-input-field"
                type="text"
                placeholder={t("search")}
                autoComplete="off"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <i className="searchIcon" />
            </div>
          </Col>
          <Col md={6}></Col>
          <Col md="3">
            <div>
              <DropDown
                label={t("Sort by")}
                options={options}
                onOptionSelected={(value) => {
                  setRequestObj({ ...requestObj, sort: value });
                }}
              />
            </div>
          </Col>
        </Row>
        {/* sections */}
        <Container
          style={{ width: "100%", overflow: "none", padding: "20px 0" }}
        >
          {collectionDetail?.gallery_owner
            ? account?._id === collectionDetail?.gallery_owner && (
                <div className="clearfix">
                  <button
                    style={{ margin: "0 0 10px" }}
                    className="btn btn-primary float-end"
                    type="button"
                    disabled={loading}
                    onClick={() => navigate(`/nft/create/${collectionId}`)}
                  >
                    {t("Create NFT")}
                  </button>
                </div>
              )
            : account?._id === collectionDetail?.creator?._id && (
                <div className="clearfix">
                  <button
                    style={{ margin: "0 0 10px" }}
                    className="btn btn-primary float-end"
                    type="button"
                    disabled={loading}
                    onClick={() => navigate(`/nft/create/${collectionId}`)}
                  >
                    {t("Create NFT")}
                  </button>
                </div>
              )}
          <Row>
            {collectionNFTs.map((el, idx) => (
              <Col xs={12} md={6} lg={3} style={{ margin: "10px 0" }}>
                <NftCard
                  key={idx}
                  item={el}
                  userData={{
                    _id: collectionDetail?.artist?._id,
                    name: collectionDetail?.artist?.name,
                    img: collectionDetail?.artist?.profileImage,
                  }}
                  creator={collectionDetail?.creator}
                />
              </Col>
            ))}
          </Row>
          {hasMore && collectionNFTs.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  borderRadius: 25,
                  border: "none",
                  margin: "0 auto",
                  padding: "10px 20px",
                  background: `linear-gradient(to right, #6D3190, #BC60F1)`,
                }}
                onClick={() => {
                  fetchCollectionNFTsCreator(collectionId, requestObj, {
                    skip: collectionNFTs?.length,
                    limit: 12,
                  });
                }}
              >
                {t("Load More")}{" "}
                {loading && <Spinner size="sm" style={{ color: "#fff" }} />}{" "}
              </Button>
            </div>
          )}
          {loading && collectionNFTs?.length === 0 && <p>{t("Loading...")}</p>}
          {!loading && collectionNFTs?.length === 0 && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img
                src={noNFTs}
                width="100%"
                height="300"
                className="my-5"
                style={{ objectFit: "contain" }}
              />
              <div className="mt-4 mb-1" style={{ fontSize: 22 }}>
                {t("no_nfts_found")}
              </div>
            </div>
          )}
        </Container>
        <CreateCollectionPopup
          isOpen={isOpenCreateCollection}
          setIsOpen={setOpenCreateCollection}
          categories={categoryState.categories}
          creatorId={account?._id}
          artistId={artistId}
          collection={collectionDetail}
          action="edit"
        />
        <ConfirmDeleteModal
          isOpen={isOpenDeleteCollection}
          setIsOpen={setOpenDeleteCollection}
          name={collectionDetail?.title}
          id={collectionDetail?._id}
          callback={deleteCollectionCreator}
        />
      </Container>
      {getConfirmationModal()}
    </div>
  );
};
