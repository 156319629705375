/* eslint-disable no-undef */
import { useEffect, useMemo, useState } from "react";
//web3
import Web3 from "web3";
import WalletModal from "./WalletModal";
//bootstrap
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ModalAndOverlay from "./ModalAndOverlay";
// grey icons
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { bidActionsCreators } from "../store";
//config and library
import { useWeb3Context } from "@context/Web3";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3React } from "@web3-react/core";
import { chains } from "@config";
import Swal from "sweetalert2";

const ConnectWallet = styled.div`
  text-align: center;
  p {
    font-weight: 500;
  }
`;

const CancelBid = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  h4 {
    font-size: 24px;
    color: ${(props) => props.colors?.text1};
  }
  h6 {
    font-weight: 500;
    color: ${(props) => props.colors?.text2};
  }
  p {
    margin-bottom: 8px;
    color: ${(props) => props.colors?.text2};
  }
  span {
    color: ${(props) => props.colors?.text1};
  }
  .note {
    font-size: 24px;
  }
  .action {
    font-weight: 400;
    color: #6d3190;
  }
  .light-text {
    font-weight: 325;
  }
`;

const CancelBidModal = (props) => {
  const { nft } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [walletOptions, setWalletOptions] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [defaultWallet, setDefaultWallet] = useState("");
  const [balance, setBalance] = useState("");
  const [accountbalance, setAccountBalance] = useState("");
  const [connectWalletModal, setConnectWalletModal] = useState(false);
  const [status, setStatus] = useState(0);
  const { cancelBidCreator } = bindActionCreators(bidActionsCreators, dispatch);
  const { chain, privateKey, getAccounts, chainId } = useWeb3Auth();
  const { account } = useWeb3React();
  const { cancelBid } = useWeb3Context();

  const getWallets = async () => {
    const wallet = await getAccounts();
    setBalance(wallet.balance);
    setSelectedWallet(nft?.bid?.user);
    setDefaultWallet(wallet.address);

    const wallets = [];
    wallets.push({
      value: wallet.address,
      label: wallet.address,
      balance: wallet.balance,
    });
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }
    setWalletOptions(props.walletOptions);
    const web3 = new Web3(
      new Web3.providers.HttpProvider(chains[chain].rpcTarget)
    );
    if (account) {
      const accountBalance = await web3.eth.getBalance(account);
      const accountbalance = web3.utils.fromWei(accountBalance, "ether");
      setAccountBalance(accountbalance);
    }
  };

  useEffect(() => {
    getWallets();
  }, [privateKey, account, balance, accountbalance]);

  const cancelBidPrice = async () => {
    try {
      setStatus(1);
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );

      if (nft?.status === "LAZY_MINT") {
        const bidData = {
          nft_id: nft._id,
          prev_bid: nft?.bid?._id,
          wallet: selectedWallet,
          price: nft?.bid?.price,
          tx: "0x00",
        };
        cancelBidCreator(bidData);
        setStatus(2);
      } else {
        const res = await cancelBid(
          nft?.offer_id,
          nft?.bid?.bid_index,
          nft?.bid?.price,
          selectedWallet === props.defaultWallet
        );

        const bidData = {
          nft_id: nft._id,
          prev_bid: nft?.bid?._id,
          wallet: selectedWallet,
          price: nft?.bid?.price,
          tx: res.data.transactionHash,
          bid_index: nft?.bid?.bid_index,
        };
        // save to backend
        if (res.status) {
          cancelBidCreator(bidData);
          setStatus(2);
        } else {
          Swal.fire({
            icone: "error",
            title: t("Ooops..."),
            text: t("transaction_failed"),
            color: props?.colors?.text,
            background: props?.colors?.bg,
            confirmButtonText: t("Ok"),
            confirmButtonColor: "#6d3190",
          });
        }
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("Ooops..."),
        text: t("bidding failed"),
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
    }
  };

  const currentWalletBalence = useMemo(() => {
    const walletInfo = walletOptions.find(
      (wallet) => wallet.value === selectedWallet
    );
    if (walletInfo) return walletInfo.balance;
    else return null;
  }, [selectedWallet, walletOptions]);

  return (
    <ModalAndOverlay colors={props.colors}>
      {connectWalletModal && (
        <WalletModal
          colors={props.colors}
          isOpen={connectWalletModal}
          setIsOpen={setConnectWalletModal}
        />
      )}

      {/* --------- place a bid --------- */}
      <CancelBid colors={props.colors}>
        <div className="d-flex justify-content-between mb-5">
          <h3 className="modal-heading title">{t("Cancel Bid")}</h3>
          <CloseIcon
            className="modal-close-icon"
            color={props.colors?.text1}
            onClick={props.closeOfferModal}
          />
        </div>
        {chain !== nft?.blockchain ? (
          <>
            <h4> {t("Please switch network")}</h4>
          </>
        ) : (
          ""
        )}

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <div className="note">
            {t("are_you_sure")}
            <div className="action">{t("cancel_bid")}</div>
          </div>{" "}
          <div className="mt-4 light-text">{t("cance_bid_note")}</div>
        </Form.Group>

        <Button
          className="modal-button my-3"
          onClick={
            status === 2
              ? props.closeOfferModal
              : () => {
                  cancelBidPrice();
                }
          }
        >
          {status === 0
            ? t("Confirm")
            : status === 1
            ? t("Canceling bid...")
            : status === 2
            ? t("Done")
            : t("Error")}{" "}
        </Button>
        {/* <Button
          className={`modal-button ${props?.colors?.button}`}
          onClick={props.closeOfferModal}
        >
          {t("Cancel")}
        </Button> */}
      </CancelBid>
    </ModalAndOverlay>
  );
};

export default CancelBidModal;
