import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const Privacy = () => {
  const { t } = useTranslation();
  const Wrapper = styled.div`
    .subtitle {
      font-size: 26px;
      font-weight: 400;
    }
    .terms-label {
      color: #777e91;
      font-size: 12px;
      text-transform: uppercase;
    }
    .terms-header {
      font-size: 48px;
      margin: -8px 0 24px 0;
    }
    .terms {
      color: #777e90;
      font-weight: 500;
      margin-bottom: 32px;
    }
  `;
  return (
    <Wrapper>
      <Container className="pb-5">
        <div className="mt-5 mt-5 mb-4 page-header">{t("Privacy policy")}</div>
        <Row className="align-items-center pb-5">
          <Col>
            <div className="subtitle">{t("Nifty Souq")}</div>
            <p>{t("Last Revised: December 24, 2021")}</p>
            <p>{t("privacy_p1")}</p>
            <p>{t("privacy_p2")}</p>
            <div className="subtitle">
              {t("Personal Information We Collect")}
            </div>
            <p>{t("privacy_p3")}</p>

            <ol>
              <li>
                {t("ol1_li1")}
                <ul>
                  <li>{t("ul1_li1")}</li>
                  <li>{t("ul1_li2")}</li>
                  <li>{t("ul1_li3")}</li>
                  <li>{t("ul1_li4")}</li>
                </ul>
              </li>
              <li>
                {t("ol1_li2")}
                <br />
                {t("ol1_li2_2")}
                <ul>
                  <li>{t("ul2_li1")}</li>
                  <li>{t("ul2_li2")}</li>

                  <li>{t("ul2_li3")}</li>

                  <li>
                    {t("ul2_li4")}
                    <br />
                    {t("ul2_li4_2")}
                  </li>
                </ul>
              </li>
              <li>
                {t("ol1_li3")}
                <br />
                {t("ol1_li3_2")}
                <ul>
                  <li>{t("ul3_li1")}</li>
                  <li>{t("ul3_li2")}</li>

                  <li>{t("ul3_li3")}</li>

                  <li>{t("ul3_li4")}</li>
                </ul>
              </li>

              <li>
                {t("ol1_li4")} <br />
                {t("ol1_li4_2")}
              </li>
            </ol>

            <div className="subtitle">
              {t("How We Use Your Personal Information")}
            </div>
            <p>
              {t("privacy_p4")}
              <ol>
                <li>{t("ol2_li1")}</li>

                <li>{t("ol2_li2")}</li>

                <li>{t("ol2_li3")}</li>

                <li>{t("ol2_li4")}</li>

                <li>{t("ol2_li5")}</li>

                <li>{t("ol2_li6")}</li>

                <li>{t("ol2_li7")}</li>
              </ol>
            </p>
            <div className="subtitle">
              {t("How We Share Your Personal Information")}
            </div>
            <p>
              {t("privacy_p5")}
              <ol>
                <li>{t("ol3_li1")}</li>

                <li>{t("ol3_li2")}</li>

                <li>{t("ol3_li3")}</li>

                <li>{t("ol3_li4")}</li>

                <li>{t("ol3_li5")}</li>

                <li>{t("ol3_li6")}</li>

                <li>{t("ol3_li7")}</li>
              </ol>
              {t("privacy_p6")}
            </p>
            <div className="subtitle">{t("Cookies")}</div>
            <p>{t("privacy_p7")}</p>
            <p>{t("privacy_p8")}</p>
            <div className="subtitle">{t("Direct Marketing")}</div>
            <p>{t("privacy_p9")}</p>
            <div className="subtitle">{t("Information Security")}</div>
            <p>{t("privacy_p10")}</p>
            <div className="subtitle">
              {t("Information For Persons Subject to EU Data Protection Law")}
            </div>
            <p>{t("privacy_p11")}</p>

            <p>
              {t("Lawful bases for processing")}
              <br />
              {t("privacy_p12")}
            </p>
            <ul>
              <li>{t("ul4_li1")}</li>

              <li>{t("ul4_li2")}</li>

              <li>{t("ul4_li3")}</li>

              <li>{t("ul4_li4")}</li>
            </ul>
            <p>
              {t("European privacy rights")}
              <br />
              {t("privacy_p13")}
            </p>
            <ul>
              <li>{t("ul5_li1")}</li>

              <li>{t("ul5_li2")}</li>

              <li>{t("ul5_li3")}</li>

              <li>{t("ul5_li4")}</li>
            </ul>
            <p>
              {t("privacy_p14")}
              <br />
              {t("privacy_p15")}
            </p>

            <p>{t("privacy_p16")}</p>
            <ul>
              <li>{t("ul6_li1")}</li>

              <li>{t("ul6_li2")}</li>
            </ul>
            <p>
              {t("ul6_li3")}
              <br />
              {t("ul6_li3_2")}
              <br />
              {t("ul6_li3_3")}
              <br />
              {t("ul6_li3_4")}
            </p>

            <div className="subtitle">{t("Contact Us")}</div>
            <p>
              {t("contact1")}{" "}
              <a href="mailto:yo@niftySouq.com">YO@niftySouq.com</a>
              {t("contact2")}
            </p>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
