import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Form,
  Button,
  Spinner,
  Image,
  Col,
  InputGroup,
} from "react-bootstrap";
import { NIFTYSouq721, NIFTYSouq1155, chains } from "@config";
import { useWeb3Auth } from "@context/Web3auth";
import { useWeb3Context } from "@context/Web3";
import { useFormik } from "formik";
import {
  listAuctionInitialValues,
  listForSaleAuction,
  listForSaleFixedPrice1155,
  listForSaleFixedPrice,
  listFixedPriceInitialValues,
} from "../validations";

//web3 integraion
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";
//wert
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import keys from "../lib/config";
//svg file
import ModalAndOverlay from "@components/ModalAndOverlay";
import lockOrderIcon from "@assets/images/icons/lock-order-circle.svg";
import completedIcon from "@assets/images/icons/completed.svg";
import visamaster from "@assets/icons/visa-master.png";
import { ReactComponent as CloseIcon } from "@assets/images/icons/close-circle.svg";
import { ReactComponent as BackArrow } from "@assets/icons/back-arrow.svg";
//store
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import {
  nftActionsCreators,
  walletActionsCreators,
  partnerActionCreators,
  authActionsCreators,
} from "../store";
import { useTranslation } from "react-i18next";
import { extractLoggedInOwner, getEditions } from "../core/nft";
import { Checkout } from "./Modals";
import styled from "styled-components";
import Swal from "sweetalert2";
import { setMaxListeners } from "events";

const ListModal = styled.div`
  .title {
    color: ${(props) => props.colors?.text};
  }
  .label {
    color: ${(props) => props.colors?.text2};
  }
`;
export const ListTokenModal = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const auth_account = useSelector((state) => state.auth);

  const { nft, error, externalNFT, selectedOwner } = props;
  const isBezelClub = externalNFT?.club == "TheBezelClub";
  const is1155 = nft?.token_standard === "1155";
  const is721 = nft?.token_standard === "721";
  const isOnlyFixedPrice = is1155 || isBezelClub;

  const { putOnSaleCreator } = bindActionCreators(nftActionsCreators, dispatch);
  const { putOnSalePartnerNFT } = bindActionCreators(
    partnerActionCreators,
    dispatch
  );
  const { saveWalletsCreator } = bindActionCreators(
    walletActionsCreators,
    dispatch
  );
  const { importWalletCreator } = bindActionCreators(
    authActionsCreators,
    dispatch
  );

  const navigate = useNavigate();
  const { chainId, account } = useWeb3React();
  const { listSale, createAuction, listBatchCollection } = useWeb3Context();
  const { userPrincipal, privateKey, getAccounts, chain, onChangeChain } =
    useWeb3Auth();
  //is stauts
  const [isMultiple, setMultiple] = useState(props.status);
  const [isFiat, setFiatPay] = useState(false);
  //basic datas
  const [status, setStatus] = useState(0);
  const [step, setStep] = useState(isOnlyFixedPrice ? 1 : 0);
  const [saleType, setSaleType] = useState("1"); //1 fixed price, 2 auction
  const [defaultWallet, setDefaultWallet] = useState("");
  const [selectedWallet, setSelectWallet] = useState("");
  const [txHash, setTx] = useState("");
  const [wertPaymentStatus, setWertPaymentStatus] = useState("pending");
  const [day, setDay] = useState(1);
  const endDate = day * 86400;
  const owner = nft?.owners?.find(
    (e) => e.user?._id == auth_account?.account?._id
  );
  const [currency, setCurrency] = useState("");
  const [tokenData, setTokenData] = useState({
    name: nft?.name,
    price: nft?.listing?.price || "",
    amount: is1155 ? selectedOwner?.unlisted_editions?.length : 1,
    isList: false,
    isAuction: false,
    startBidPrice: "",
    reservePrice: "",
    auctionPeriod: endDate,
  });
  const options = {
    partner_id: window.WERT_PARTNER_ID,
    container_id: "wert-widget",
    click_id: uuidv4(), // unique id of purhase in your system
    width: 400,
    height: 500,
    currency: "USD",
    commodity: chain,
    commodities: "BTC,ETH,MATIC",
    commodity_amount: "0.003",
    address: selectedWallet,
    origin: window.WERT_ORIGIN,
    listeners: {
      "payment-status": ({ status = "pending" }) => {
        setWertPaymentStatus(status);
      },
    },
  };

  const wertWidget = new WertWidget(options);

  //listing function
  const list = async () => {
    // if (isFiat) {
    //   setStatus(1);
    //   wertWidget.mount();
    //   if (options.listeners["payment-status"].status === "success")
    //     setStatus(3);
    // } else {
    try {
      //List Fixed Price
      if (saleType === "1") {
        setStatus(1);
        await make(nft?.token_id, selectedWallet);
      }
      //List Auction
      if (saleType === "2") {
        setStatus(1);
        await auction(nft?.token_id);
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      props.closeOfferModal();
    }
    // }
  };

  const make = async (tokenId, wallet) => {
    try {
      if (nft?.is_lazy) {
        let quantityArr = [];
        if (is1155)
          quantityArr = getEditions(
            selectedOwner?.unlisted_editions,
            tokenData.amount
          );

        //Save to backend
        const body = {
          wallet,
          price: tokenData.price,
          sale_type: saleType,
          offer_id: 0,
          tx: "0x00",
          auctionPeriod: day,
          editions: nft?.token_standard == 1155 ? quantityArr : [],
          currency: currency === "USDC" ? "USDC" : chain,
          payment_method: chain,
        };
        if (isBezelClub)
          putOnSalePartnerNFT(window.ADDRESSES.BEZEL.ETH, nft?._id, body);
        else putOnSaleCreator(nft?._id, body, nft?.token_standard);
        setStatus(2);
      } else {
        const web3 = new Web3(
          new Web3.providers.HttpProvider(chains[chain].rpcTarget)
        );
        const value =
          currency === "USDC"
            ? web3.utils.toWei(tokenData.price.toString(), "mwei")
            : web3.utils.toWei(tokenData.price.toString());

        const res = await listSale(
          tokenId,
          nft?.contract,
          value,
          tokenData.amount,
          currency,
          selectedWallet === defaultWallet
        );

        let quantityArr = [];
        if (is1155)
          quantityArr = getEditions(
            selectedOwner?.unlisted_editions,
            tokenData.amount
          );

        //Save to backend
        const body = {
          price: tokenData.price,
          sale_type: "1",
          offer_id: res.data.events.eFixedPriceSale.returnValues.offerId,
          auctionPeriod: day,
          editions: is1155 ? quantityArr : [],
          tx: res.data.transactionHash,
          wallet: selectedWallet,
          currency: currency === "USDC" ? "USDC" : chain,
          payment_method: chain,
        };

        if (res.status) {
          if (isBezelClub)
            putOnSalePartnerNFT(window.ADDRESSES.BEZEL.ETH, nft?._id, body);
          else putOnSaleCreator(nft?._id, body, nft?.token_standard);
          setTx(res.data.transactionHash);
          setStatus(2);
        } else {
          Swal.fire({
            confirmButtonColor: "#6d3190",
            icone: "error",
            title: t("oops"),
            text: t("tx_canceled"),
          });
          props.closeOfferModal();
        }
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      props.closeOfferModal();
    }
  };

  const auction = async (tokenId) => {
    try {
      const web3 = new Web3(
        new Web3.providers.HttpProvider(chains[chain].rpcTarget)
      );
      const reserve = web3.utils.toWei(tokenData.reservePrice.toString());
      const minBid = web3.utils.toWei(tokenData.startBidPrice.toString());
      const AuctionData = {
        tokenId: tokenId,
        tokenContract: nft?.contract,
        duration: endDate,
        startBidPrice: minBid,
        reservePrice: reserve,
      };
      const res = await createAuction(
        AuctionData,
        selectedWallet === props.defaultWallet
      );
      //Save to backend
      const body = {
        price: parseFloat(tokenData.reservePrice),
        sale_type: "2",
        offer_id: res.data.events.eCreateAuction.returnValues.offerId,
        auctionPeriod: day,
        editions: [],
        tx: res.data.transactionHash,
        wallet: selectedWallet,
        payment_method: chain,
        currency: chain,
      };

      if (body.sale_type === "2") {
        body.auction_start_date = Date.now();
        body.auction_end_date = endDate;
      }
      if (res.status) {
        if (isBezelClub)
          putOnSalePartnerNFT(window.ADDRESSES.BEZEL.ETH, nft?._id, body);
        else putOnSaleCreator(nft?._id, body, nft?.token_standard);
        setTx(res.data.transactionHash);
        setStatus(2);
      } else {
        Swal.fire({
          icone: "error",
          title: t("oops"),
          text: t("tx_canceled"),
          color: props?.colors?.text,
          background: props?.colors?.bg,
          confirmButtonText: t("Ok"),
          confirmButtonColor: "#6d3190",
        });
        props.closeOfferModal();
      }
    } catch (e) {
      Swal.fire({
        icone: "error",
        title: t("oops"),
        text: e.message,
        color: props?.colors?.text,
        background: props?.colors?.bg,
        confirmButtonText: t("Ok"),
        confirmButtonColor: "#6d3190",
      });
      props.closeOfferModal();
    }
  };

  const dataload = async () => {
    //get default wallet
    const wallet = await getAccounts();
    auth_account.account &&
      wallet.address &&
      !auth_account.account?.wallets.includes(wallet.address) &&
      (await importWalletCreator({ wallet: wallet.address }));
    saveWalletsCreator(0, wallet.address);
    setSelectWallet(
      nft?.token_standard === "721"
        ? nft?.owner?.create_wallet
        : selectedOwner?.user?.create_wallet
    );
    setDefaultWallet(wallet.address);
    const wallets = [];
    wallets.push({ value: wallet.address, label: wallet.address });
    if (account) {
      if (chains[chain].chainId === chainId) {
        wallets.push({ value: account, label: account });
      }
    }

    //contrat setting
    setMultiple(props.status);
  };

  const {
    values,
    handleSubmit: handleSubmitFixedPrice,
    touched: touchedFixedPrice,
    errors: errorsFixedPrice,
    setFieldValue: setFieldValueFixedPrice,
  } = useFormik({
    initialValues: is1155
      ? { price: tokenData.price, amount: tokenData.amount }
      : listFixedPriceInitialValues,
    onSubmit: list,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: is1155
      ? listForSaleFixedPrice1155(t, selectedOwner?.unlisted_editions?.length)
      : listForSaleFixedPrice(t),
  });
  const {
    handleSubmit: handleSubmitAuction,
    touched: touchedAuction,
    errors: errorsAuction,
    setFieldValue: setFieldValueAuction,
  } = useFormik({
    initialValues: listAuctionInitialValues,
    onSubmit: list,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: listForSaleAuction(t),
  });

  useEffect(() => {
    if (privateKey) dataload();
  }, [userPrincipal, privateKey, account, chain]);

  useEffect(() => {
    dataload();
  }, [isMultiple]);

  const { closeOfferModal, colors } = props;

  return (
    <div>
      <ModalAndOverlay colors={props.colors}>
        <ListModal colors={props.colors}>
          <div>
            <div className="d-flex justify-content-between mb-4">
              <h3 className="modal-heading title">{t("List your NFT")}</h3>
              <CloseIcon
                color={colors?.text1}
                className="modal-close-icon"
                onClick={() => setFiatPay(false)}
              />
            </div>
            <div id="wert-widget"></div>
            {wertPaymentStatus == "success" && (
              <Button
                className={`modal-button ${props?.colors?.button}`}
                onClick={() => {
                  setFiatPay(false);
                  list();
                }}
              >
                {t("Proceed")}
              </Button>
            )}
          </div>
        </ListModal>
      </ModalAndOverlay>
      {!isFiat && (
        <ModalAndOverlay colors={props.colors}>
          <Checkout colors={props.colors} isLTR={i18n.language != "ar"}>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="d-flex flex-row align-items-center">
                {step == 1 && !isOnlyFixedPrice && (
                  <div onClick={() => setStep(0)} className="arrow cursor">
                    <BackArrow />
                  </div>
                )}
                <div className="modal-heading title">
                  {step == 0
                    ? t("Put on sale")
                    : saleType === "1"
                    ? t("Put on Fixed Price")
                    : t("Put for Auction")}
                </div>
              </div>
              <CloseIcon
                color={colors?.text1}
                className="modal-close-icon"
                onClick={closeOfferModal}
              />
            </div>
            {/* //is721 && */}
            {step == 0 ? (
              <div>
                <div style={{ fontWeight: 325 }}>
                  {t("you_are_about_to_list")}
                  <span className="mx-1">{nft?.name}</span> {t("For sale")}
                </div>
                <Form.Label className="mt-4">{t("Sale type")}</Form.Label>
                <Row className="mt-1 mb-5">
                  <Col md={4}>
                    <Form.Check
                      type="radio"
                      id="check-checkbox"
                      checked={saleType === "1"}
                      label={t("Fixed Price")}
                      onClick={(e) => setSaleType("1")}
                    />
                  </Col>
                  {chain !== "BOBA" && (
                    <Col md={4}>
                      <Form.Check
                        type="radio"
                        label={t("Auction")}
                        id="check2-checkbox"
                        checked={saleType === "2"}
                        onClick={(e) => {
                          setSaleType("2");
                          setTokenData({ ...tokenData, isAuction: true });
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </div>
            ) : status == 0 ? (
              <div>
                {saleType === "2" ? (
                  <Form onSubmit={handleSubmitAuction}>
                    <div style={{ fontWeight: 325 }}>
                      {t("you_are_about_to_list")}
                      <span style={{ fontWeight: 400 }} className="mx-1">
                        {props?.nft?.name}
                      </span>
                      <span style={{ fontWeight: 400 }}>
                        {t("For auction")}
                      </span>
                    </div>
                    <Row className="mt-4">
                      <Col>
                        <Form.Group controlId="reserve-price" className="mb-4">
                          <Form.Label className="create-section-label title">
                            {t("Reserve Price")}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="reservePrice"
                              placeholder={t("Reserve Price")}
                              className="create-page-input-field"
                              value={tokenData.reservePrice}
                              onChange={(e) => {
                                setTokenData({
                                  ...tokenData,
                                  reservePrice: e.target.value,
                                });
                                setSelectWallet(
                                  is1155
                                    ? owner?.user?.create_wallet
                                    : nft?.owner?.create_wallet
                                );
                                setFieldValueAuction(
                                  "reservePrice",
                                  e.target.value
                                );
                              }}
                              style={{
                                color: colors?.text,
                                backgroundColor: colors?.bg,
                              }}
                            />
                            <InputGroup.Text
                              style={{
                                color: colors?.text,
                                backgroundColor: colors?.bg,
                              }}
                            >
                              {nft?.blockchain}
                            </InputGroup.Text>
                          </InputGroup>
                          {errorsAuction.reservePrice &&
                            touchedAuction.reservePrice && (
                              <Form.Text className="text-danger">
                                {errorsAuction.reservePrice}
                              </Form.Text>
                            )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group
                          controlId="start-bid-price"
                          className="mb-4"
                        >
                          <Form.Label className="create-section-label title">
                            {t("Minimum Bid")}
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              type="text"
                              name="startBidPrice"
                              placeholder={t("Minimum Bid")}
                              className="create-page-input-field"
                              value={tokenData.startBidPrice}
                              onChange={(e) => {
                                setTokenData({
                                  ...tokenData,
                                  startBidPrice: e.target.value,
                                });
                                setFieldValueAuction(
                                  "startBidPrice",
                                  e.target.value
                                );
                              }}
                              style={{
                                color: props?.colors?.text,
                                backgroundColor: props?.colors?.bg,
                              }}
                            />
                            <InputGroup.Text
                              style={{
                                color: props?.colors?.text,
                                backgroundColor: props?.colors?.bg,
                              }}
                            >
                              {nft?.blockchain}
                            </InputGroup.Text>
                          </InputGroup>
                          {errorsAuction.startBidPrice &&
                            touchedAuction.startBidPrice && (
                              <Form.Text className="text-danger">
                                {errorsAuction.startBidPrice}
                              </Form.Text>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-4">
                      <Form.Label className="create-section-label title">
                        {t("Auction Period")}
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        className="create-page-input-field"
                        value={day}
                        onChange={(e) => setDay(e.target.value)}
                        style={{
                          color: colors?.text,
                          backgroundColor: colors?.bg,
                        }}
                      >
                        <option value={1}>1 {t("Day")}</option>
                        <option value={3}>3 {t("Days")}</option>
                        <option value={5}>5 {t("Days")}</option>
                        <option value={7}>7 {t("Days")}</option>
                      </Form.Select>
                    </Form.Group>
                    <Button type="submit" className="modal-button mb-3">
                      {t("Confirm")}
                    </Button>
                  </Form>
                ) : (
                  <Form onSubmit={handleSubmitFixedPrice}>
                    {is1155 && (
                      <h6 style={{ color: "red" }}>
                        {selectedOwner?.unlisted_editions?.length == 0
                          ? t("You don't have editions to list")
                          : ""}
                      </h6>
                    )}
                    <Row>
                      <div style={{ fontWeight: 325 }}>
                        {t("you_are_about_to_list")}
                        <span style={{ fontWeight: 400 }} className="mx-1">
                          {props?.nft?.name}
                        </span>
                        <span style={{ fontWeight: 400 }}>
                          {t("For fixed price")}
                        </span>
                      </div>
                      <Form.Group controlId="price" className="mb-5 mt-4">
                        <Row>
                          <Col>
                            <Form.Label className="create-section-label title">
                              {t("Price")}
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                name="price"
                                placeholder={t("Price")}
                                className="create-page-input-field"
                                value={tokenData.price}
                                onChange={(e) => {
                                  setTokenData({
                                    ...tokenData,
                                    price: e.target.value,
                                  });
                                  setSelectWallet(
                                    is1155
                                      ? selectedOwner?.user?.create_wallet
                                      : nft?.owner?.create_wallet
                                  );
                                  setFieldValueFixedPrice(
                                    "price",
                                    e.target.value
                                  );
                                }}
                                touched={touchedFixedPrice.price}
                                style={{
                                  color: props?.colors?.text,
                                  backgroundColor: props?.colors?.bg,
                                }}
                              />
                              <Form.Select
                                type="text"
                                className="create-page-input-field"
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}
                                style={{
                                  color: props.colors?.text,
                                  backgroundColor: props.colors?.bg,
                                }}
                              >
                                {/* <option value={"USDC"}>{"USDC"}</option> */}
                                <option value={""}>{nft?.blockchain}</option>
                                <option value={"USDC"}>{"USDC"}</option>
                              </Form.Select>
                            </InputGroup>
                            {errorsFixedPrice.price &&
                              touchedFixedPrice.price && (
                                <Form.Text className="text-danger">
                                  {errorsFixedPrice.price}
                                </Form.Text>
                              )}
                          </Col>
                          {is1155 && (
                            <Col>
                              <Form.Label className="create-section-label label">
                                {t("Amount")}
                              </Form.Label>
                              <InputGroup size="md">
                                <Form.Control
                                  disabled={
                                    selectedOwner?.unlisted_editions?.length ==
                                    0
                                  }
                                  name="amount"
                                  type="number"
                                  placeholder={t("amount")}
                                  className="create-page-input-field"
                                  value={tokenData.amount}
                                  onChange={(e) => {
                                    setTokenData({
                                      ...tokenData,
                                      amount: e.target.value,
                                    });
                                    setFieldValueFixedPrice(
                                      "amount",
                                      e.target.value
                                    );
                                  }}
                                  step={1}
                                />
                                <InputGroup.Text>
                                  {selectedOwner?.unlisted_editions?.length}
                                </InputGroup.Text>
                              </InputGroup>
                              {errorsFixedPrice.amount &&
                                touchedFixedPrice.amount && (
                                  <Form.Text className="text-danger">
                                    {errorsFixedPrice.amount}
                                  </Form.Text>
                                )}
                            </Col>
                          )}
                        </Row>
                      </Form.Group>
                    </Row>
                    <Button type="submit" className="modal-button mb-3">
                      {t("Confirm")}
                    </Button>
                  </Form>
                )}
              </div>
            ) : status === 3 ? (
              <div>
                <div className="d-flex justify-content-between mb-4">
                  <h3 className="modal-heading">{t("Follow steps")}</h3>
                </div>
                <div className="mb-4 pb-2">
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      <Spinner animation="border" variant="primary" />
                    </div>
                    <div>
                      <h5 className="m-0 modal-section-title">
                        {nft?.listing?.sale_type == "1"
                          ? t("Put on sale")
                          : t("Create auction")}
                      </h5>
                      <p className="m-0 modal-section-text">
                        {t("Sign message to set fixed price")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : status === 4 ? (
              <>
                <h4>{t("transaction_failed")} 😢</h4>
                <div className="message p-4">
                  <div className="d-flex table-row">
                    <span>{error}</span>
                  </div>
                </div>
              </>
            ) : status === 5 ? (
              <>
                <h2>{t("Yay!")} 🎉</h2>
                <div className="d-flex align-items-center justify-content-center">
                  <Image src={props.nft?.url} height={250}></Image>
                </div>
                <div className="message p-4">
                  <div className="d-flex table-title mb-3">
                    <span>{t("Status")}</span>
                    <span>{t("Transaction ID")}</span>
                  </div>
                  <div className="d-flex table-row">
                    <span>{t("Success")}</span>
                    <span>
                      <a
                        href={
                          chain === "ETH"
                            ? window.ETH_SCAN + "/" + `${txHash}`
                            : window.POLYGON_SCAN + "/" + `${txHash}`
                        }
                      >
                        {txHash.substring(40)}
                      </a>
                    </span>
                  </div>
                </div>
                <Button className="modal-button mb-3" onClick={closeOfferModal}>
                  {t("View NFT")}
                </Button>
              </>
            ) : (
              <>
                <div className="mb-4 pb-2">
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      {status === 1 ? (
                        <Spinner animation="border" variant="primary" />
                      ) : status > 1 ? (
                        <Image src={completedIcon}></Image>
                      ) : (
                        <Image src={lockOrderIcon}></Image>
                      )}
                    </div>
                    &nbsp;&nbsp;
                    <div>
                      <p className="m-0 modal-section-text">
                        {t("Call contract method")}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {step !== 1 && (
              <Button
                className="modal-button mb-3"
                disabled={
                  step == 0
                    ? false
                    : (status === 0 &&
                        (tokenData.price ||
                          (tokenData.reservePrice &&
                            tokenData.startBidPrice != 0))) ||
                      status === 2 ||
                      (is1155 && selectedOwner?.unlisted_editions?.length)
                    ? false
                    : true
                }
                onClick={
                  status === 2
                    ? closeOfferModal
                    : step === 0
                    ? () => setStep(1)
                    : () => {}
                }
              >
                {status === 0
                  ? step == 0
                    ? t("Proceed")
                    : t("Confirm")
                  : status === 1
                  ? t("Call contract method")
                  : status === 2
                  ? t("Done")
                  : t("Error")}
              </Button>
            )}
            {/* {step == 1 && (
              <Button
                className={`modal-button mb-2 ${props?.colors?.button}`}
                id="cancel-fiat"
                disabled={
                  (status === 0 &&
                    (tokenData.price ||
                      (tokenData.reservePrice &&
                        tokenData.startBidPrice != 0))) ||
                  status === 2
                    ? false
                    : true
                }
                onClick={() => {
                  setFiatPay(true);
                  list(true);
                }}
              >
                {status === 0
                  ? t("List with card")
                  : status === 1
                  ? t("Call contract method")
                  : status === 2
                  ? t("Done")
                  : t("Error")}{" "}
                <img src={visamaster} width="80" height="30" />
              </Button>
            )} */}
            {step == 1 && (
              <Button
                className={`modal-button ${props?.colors?.button}`}
                // disabled={status === 0 || status === 3 || status === 4 ? false : true}
                onClick={closeOfferModal}
              >
                {t("Cancel")}
              </Button>
            )}
          </Checkout>
        </ModalAndOverlay>
      )}
    </div>
  );
};

export default ListTokenModal;
