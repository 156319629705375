import { Row } from "react-bootstrap";
import styled from "styled-components";

const StylesWrapper = styled("div")`
  margin-top: 15px;
  .header {
    padding: 5px;
    list-style-type: none;
    border: 2px solid #e6e8ec;
    border-radius: 25px;
    width: fit-content;
  }
  .list {
    display: inline-block;
    margin: 0 3px;
    font-size: 15px;
    border-radius: 25px;
    padding: 6px 12px;
    cursor: pointer;
  }
`;
export default StylesWrapper;
