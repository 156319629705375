import React, { useRef, useState, useEffect } from "react";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TwitterShareButton, FacebookShareButton } from "react-share";
import { ReactComponent as UploadCircle } from "@assets/icons/upload.svg";
import { ReactComponent as Twitter } from "@assets/icons/twitter-share.svg";
import { ReactComponent as Facebook } from "@assets/icons/facebook-share.svg";
import { ReactComponent as Copy } from "@assets/icons/copy-chain.svg";

import { ListItem } from "./styles";

const ShareOptions = ({ nft, externalNFT, colors }) => {
  const { t, i18n } = useTranslation();
  const isBezelClub = externalNFT?.club == "TheBezelClub";

  return (
    <ListItem colors={colors}>
      <NavDropdown
        align="end"
        className={isBezelClub ? "nft-menu-dark" : "nft-menu"}
        id="dropdown-menu-align-end"
        style={{ zIndex: 0 }}
        title={
          <div className="uploadcircle cursor expand">
            <UploadCircle style={{ fontSize: 22 }} />
          </div>
        }
      >
        <NavDropdown.Item>
          <FacebookShareButton
            title={nft?.name}
            url={window.location.href}
            style={{ width: "100%" }}
          >
            <div className="d-flex justify-content-start align-items-center list-item">
              <Facebook width="15" height="15" color={colors?.social} />
              <div style={{ marginInlineStart: 7 }}>{t("Facebook")}</div>
            </div>
          </FacebookShareButton>
        </NavDropdown.Item>
        <div>
          <hr style={{ width: "100%", margin: "8px 0px" }} />
        </div>
        <NavDropdown.Item>
          <TwitterShareButton
            title={nft?.name}
            url={window.location.href}
            style={{ width: "100%" }}
          >
            <div className="d-flex justify-content-start align-items-center list-item">
              <Twitter width="15" height="15" color={colors?.social} />
              <div style={{ marginInlineStart: 7 }}>{t("Twitter")}</div>
            </div>
          </TwitterShareButton>
        </NavDropdown.Item>
        <div>
          <hr style={{ width: "100%", margin: "8px 0px" }} />
        </div>
        <NavDropdown.Item
          onClick={() => navigator.clipboard.writeText(window.location.href)}
        >
          <div className="d-flex justify-content-start align-items-center list-item">
            <Copy width="15" height="15" color={colors?.social} />
            <div style={{ marginInlineStart: 7 }}>{t("Copy link")}</div>
          </div>
        </NavDropdown.Item>
      </NavDropdown>
    </ListItem>
  );
};

export default ShareOptions;
