import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Slider from "rc-slider";
import { useTranslation } from "react-i18next";
// import "rc-slider/assets/index.css";
import DropDown from "../components/DropDown";
import "./search.css";
import NFT from "../components/NFT";
import NFT_ITEMS from "../data/nftItems"; // to be replaced with real data later
import fillClose from "../assets/icons/fill-close.svg";

export const Search = () => {
  
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const options = [
    { label: "Recently added", id: "0" },
    { label: "Hot bids", id: "1" },
    { label: "Sold items", id: "2" },
  ];
  const currencyOptions = [
    {
      label: "Polygon",
      id: "0",
    },
    {
      label: "Ethereum",
      id: "1",
    },
  ];
  const categoriesOptions = [
    { label: "Collectibles", id: "0" },
    { label: "Music", id: "1" },
    { label: "Photography", id: "2" },
    { label: "Art", id: "3" },
  ];
  const subCategoriesOptions = [
    { label: "Khaliji", id: "0" },
    { label: "Egyptian", id: "1" },
  ];
  const nftStatusOptions = [
    { label: "On Auction", id: "0" },
    { label: "Sold", id: "1" },
  ];
  const creatorOptions = [
    { label: "Anita Bins", id: "0" },
    { label: "Joana Wuckert", id: "1" },
    { label: "Lorena Ledner", id: "2" },
  ];

  return (
    <Container>
      <div className="search-form d-flex">
        <input
          type="text"
          className="big-search flex-fill"
          placeholder={t("Enter your keywords")}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <button className="btn btn-purple rounded-full" >
          <i className="search-icon"></i>
        </button>
      </div>
      <Row>
        {/* --------- filter --------- */}
        <Col md={4} lg={3}>
          <div className="pb-5">
            <DropDown label="Recently added" options={options} />
          </div>
          <div className="pb-5">
            <p className="dropdown-label">Currency</p>
            <DropDown label="Polygon" options={currencyOptions} />
          </div>
          <div className="pb-3 border-bottom-1">
            {/* <Slider className="mb-3" /> */}
            <div className="d-flex justify-content-between">
              <div className="range-min-max">0.01 XTZ</div>
              <div className="range-min-max">10 XTZ</div>
            </div>
          </div>
          {/* trending searches */}
          <div className="py-3 border-bottom-1">
            <p className="dropdown-label">Trending searches</p>
            <div>
              {["Monkey", "Whiskers", "Akutars", "The Banksters"].map(
                (trending, index) => {
                  return (
                    <span key={index} className="span">
                      {trending}
                    </span>
                  );
                }
              )}
            </div>
          </div>
          {/* filters */}
          <div className="pb-3 mt-3">
            <p className="dropdown-label">Categories</p>
            <DropDown label="Collectibles" options={categoriesOptions} />
          </div>
          <div className="pb-3">
            <p className="dropdown-label">Sub categories</p>
            <DropDown label="Khaliji" options={subCategoriesOptions} />
          </div>
          <div className="pb-3">
            <p className="dropdown-label">NFT status</p>
            <DropDown label="On Auction" options={nftStatusOptions} />
          </div>
          <div className="pb-3 border-bottom-1">
            <p className="dropdown-label">Creator</p>
            <DropDown label="Anita Bins" options={creatorOptions} />
          </div>
          {/*  */}
          <div className="my-3">
            <span className="span">
              <img src={fillClose} alt="" className="me-2 close-filter" />
              <span>Collectibles</span>
            </span>
            <span className="span">
              <img src={fillClose} alt="" className="me-2 close-filter" />
              <span>Khaliji</span>
            </span>
            <span className="span">
              <img src={fillClose} alt="" className="me-2 close-filter" />
              <span>On Auction</span>
            </span>
            <span className="span">
              <img src={fillClose} alt="" className="me-2 close-filter" />
              <span>Anita Bins</span>
            </span>
            <span className="reset-filter-span">
              <img src={fillClose} alt="" className="me-2 close-filter" />
              <span>Reset Filter</span>
            </span>
          </div>
        </Col>
        {/* --------- results --------- */}
        <Col md={8} lg={9} className="mt-5 pt-lg-5 ps-md-4 ps-lg-5">
          <Row>
            {NFT_ITEMS.map((item) => {
              return (
                <Col key={item.id} sm={6} lg={4}>
                  <NFT {...item} />
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
