import * as artistTypes from "../actions/types/artist";
import * as createCollectionTypes from "../actions/types/creatorCollection";
import { ACTION_STATUS } from "../../common/constants/gallery";

const artistState = {
  artists: {
    loading: false,
    status: ACTION_STATUS.UNSET,
    data: [],
    count: 0,
    has_more: true,
    error: null,
  },
  publicArtists: {
    galleryId: null,
    loading: false,
    data: [],
    count: 0,
    has_more: true,
    error: null,
  },
  collections: {
    data: [],
    count: 0,
    action: null,
    action_loading: false,
    action_success: false,
    loading: false,
    has_more: true,
    error: null,
  },
  createGalleryArtist: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  updateGalleryArtist: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  getGalleryArtistById: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  deleteGalleryArtist: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  assignGalleryArtist: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  details: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
  owner: {
    entities: {},
    status: ACTION_STATUS.UNSET,
    error: {},
  },
};

export const artistReducer = (state = artistState, action) => {
  switch (action.type) {
    //* delete collection section
    case createCollectionTypes.DELETE_COLLECTION_LOADING:
      return {
        ...state,
        collections: {
          ...state.collections,
          action: "delete",
          action_success: false,
          action_loading: true,
        },
      };
    case createCollectionTypes.DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          action_success: true,
          action_loading: false,
          data: [
            ...state.collections.data.filter((el) => el._id !== action.id),
          ],
        },
      };
    case createCollectionTypes.DELETE_COLLECTION_ERROR:
      return {
        ...state,
        collections: {
          ...state.collections,
          action_success: false,
          action_loading: false,
          error: action.error,
        },
      };
    //* edit collection section
    case createCollectionTypes.EDIT_COLLECTION_LOADING:
      return {
        ...state,
        collections: {
          ...state.collections,
          action_success: false,
          action: "edit",
          action_loading: true,
        },
      };
    case createCollectionTypes.EDIT_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          action_success: true,
          action_loading: false,
        },
      };
    case createCollectionTypes.EDIT_COLLECTION_ERROR:
      return {
        ...state,
        collections: {
          ...state.collections,
          action_success: false,
          action_loading: false,
          error: action.error,
        },
      };
    case artistTypes.FETCH_GALLERY_ARTIST:
      return {
        ...state,
        artists: {
          ...state.artists,
          loading: true,
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.FETCH_GALLERY_ARTIST_SUCCESS:
      return {
        ...state,
        artists: {
          data: action.load
            ? [...state.artists.data, ...action.payload]
            : action.payload,
          count: action.count,
          has_more: action.has_more,
          error: null,
          loading: false,
          status: ACTION_STATUS.COMPLETE,
        },
      };
    case artistTypes.FETCH_GALLERY_ARTIST_ERROR:
      return {
        ...state,
        artists: {
          ...state.artists,
          loading: false,
          error: action.error,
        },
      };
    case artistTypes.FETCH_PUBLIC_GALLERY_ARTIST:
      return {
        ...state,
        publicArtists: {
          ...state.publicArtists,
          loading: true,
          galleryId: null,
        },
      };
    case artistTypes.FETCH_PUBLIC_GALLERY_ARTIST_SUCCESS:
      return {
        ...state,
        publicArtists: {
          data: action.load
            ? [...state.publicArtists.data, ...action.payload]
            : action.payload,
          count: action.count,
          has_more: action.has_more,
          error: null,
          loading: false,
          galleryId: action.galleryId,
        },
      };
    case artistTypes.FETCH_PUBLIC_GALLERY_ARTIST_ERROR:
      return {
        ...state,
        publicArtists: {
          ...state.publicArtists,
          loading: false,
          error: action.error,
          galleryId: null,
        },
      };
    case artistTypes.RESET_FETCH_GALLERY_ARTIST:
      return {
        ...state,
        publicArtists: state.publicArtists,
      };
    case artistTypes.CREATE_GALLERY_ARTIST:
      return {
        ...state,
        createGalleryArtist: {
          ...state.createGalleryArtist,
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.CREATE_GALLERY_ARTIST_SUCCESS:
      return {
        ...state,
        createGalleryArtist: {
          ...state.createGalleryArtist,
          status: ACTION_STATUS.COMPLETE,

          entities: action.payload,
        },
      };
    case artistTypes.CREATE_GALLERY_ARTIST_ERROR:
      return {
        ...state,
        createGalleryArtist: {
          ...state.createGalleryArtist,
          status: ACTION_STATUS.FAILURE,

          entities: {},
          error: action.payload,
        },
      };
    case artistTypes.CREATE_GALLERY_ARTIST_RESET:
      return {
        ...state,
        createGalleryArtist: artistState.createGalleryArtist,
      };
    case artistTypes.UPDATE_GALLERY_ARTIST:
      return {
        ...state,
        updateGalleryArtist: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.UPDATE_GALLERY_ARTIST_SUCCESS:
      return {
        ...state,
        updateGalleryArtist: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case artistTypes.UPDATE_GALLERY_ARTIST_ERROR:
      return {
        ...state,
        updateGalleryArtist: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case artistTypes.UPDATE_GALLERY_ARTIST_RESET:
      return {
        ...state,
        updateGalleryArtist: artistState.updateGalleryArtist,
      };

    case artistTypes.FETCH_GALLERY_ARTIST_BY_ID:
      return {
        ...state,
        getGalleryArtistById: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.FETCH_GALLERY_ARTIST_BY_ID_SUCCESS:
      return {
        ...state,
        getGalleryArtistById: {
          status: ACTION_STATUS.COMPLETE,
          entities:
            action.payload && action.payload[0] ? action.payload[0] : {},
        },
      };
    case artistTypes.FETCH_GALLERY_ARTIST_BY_ID_ERROR:
      return {
        ...state,
        getGalleryArtistById: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case artistTypes.RESET_FETCH_GALLERY_ARTIST_BY_ID:
      return {
        ...state,
        getGalleryArtistById: artistState.getGalleryArtistById,
      };
    case artistTypes.DELETE_GALLERY_ARTIST:
      return {
        ...state,
        deleteGalleryArtist: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.DELETE_GALLERY_ARTIST_SUCCESS:
      return {
        ...state,
        deleteGalleryArtist: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case artistTypes.DELETE_GALLERY_ARTIST_ERROR:
      return {
        ...state,
        deleteGalleryArtist: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case artistTypes.DELETE_GALLERY_ARTIST_RESET:
      return {
        ...state,
        deleteGalleryArtist: artistState.deleteGalleryArtist,
      };
    case artistTypes.ASSIGN_GALLERY_ARTIST:
      return {
        ...state,
        assignGalleryArtist: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.ASSIGN_GALLERY_ARTIST_SUCCESS:
      return {
        ...state,
        assignGalleryArtist: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case artistTypes.ASSIGN_GALLERY_ARTIST_ERROR:
      return {
        ...state,
        assignGalleryArtist: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    case artistTypes.ASSIGN_GALLERY_ARTIST_RESET:
      return {
        ...state,
        assignGalleryArtist: artistState.assignGalleryArtist,
      };
    case artistTypes.FETCH_ARTIST:
      return {
        ...state,
        details: {
          ...state.details,
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.FETCH_ARTIST_SUCCESS:
      return {
        ...state,
        details: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case artistTypes.FETCH_ARTIST_ERROR:
      return {
        ...state,
        details: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };

    case createCollectionTypes.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          data: [
            { ...action.payload, nftCounts: 0 },
            ...state.collections.data,
          ],
          loading: false,
          error: null,
        },
      };
    case artistTypes.FETCH_ARTIST_COLLECTIONS:
      return {
        ...state,
        collections: {
          ...state.collections,
          loading: true,
        },
      };
    case artistTypes.FETCH_ARTIST_COLLECTIONS_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          data: [...action.payload],
          hasMore: action.hasMore,
          count: action.count,
          loading: false,
          error: null,
        },
      };
    case artistTypes.FETCH_ARTIST_COLLECTIONS_PAGINATED_SUCCESS:
      return {
        ...state,
        collections: {
          ...state.collections,
          hasMore: action.hasMore,
          data: [...state.collections.data, ...action.payload],
          count: action.count,
          loading: false,
          error: null,
        },
      };
    case artistTypes.FETCH_ARTIST_COLLECTIONS_FAILURE:
      return {
        ...state,
        collections: {
          ...state.collections,
          error: action.error,
          loading: false,
        },
      };
    case artistTypes.RESET_ARTIST_COLLECTIONS:
      return {
        ...state,
        collections: artistState.collections,
      };
    case artistTypes.FETCH_OWNER_ID:
      return {
        ...state,
        owner: {
          status: ACTION_STATUS.LOADING,
        },
      };
    case artistTypes.FETCH_OWNER_ID_SUCCESS:
      return {
        ...state,
        owner: {
          status: ACTION_STATUS.COMPLETE,
          entities: action.payload,
        },
      };
    case artistTypes.FETCH_OWNER_ID_ERROR:
      return {
        ...state,
        owner: {
          status: ACTION_STATUS.FAILURE,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
