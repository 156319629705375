export function getFileType(fileUrl) {
  return fetch(fileUrl)
    .then((response) => {
      if (response.headers && response.headers.get("Content-Type")) {
        const mimeType = response.headers.get("Content-Type");
        console.log("mimeType", mimeType);
        if (mimeType.includes("video")) {
          return "video";
        } else if (mimeType.includes("image")) {
          return "image";
        } else if (mimeType.includes("audio")) {
          return "audio";
        } else {
          return "unknown";
        }
      } else {
        return "unknown";
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}
