import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ScrollBar from "./ScrollBar";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
//store
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  .main {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 65px;
    }
  }
  .card {
    max-width: 297px;
    min-width: 297px;
    margin-inline-end: 35px;
    border: 1px solid #efefef;
    // box-shadow: 0px 5.0181px 0px 1.8258px rgba(214, 214, 214, 0.25);
    border-radius: 13.8258px;
    :last-child {
      margin-right: 0px;
    }
  }
  .wrapper {
    width: 126px;
    height: 123px;
  }
  .image {
    object-fit: cover;
  }
  .radius {
    border-radius: 9.2px;
  }
  .nft {
    margin-bottom: 10px;
  }
  .creator {
    color: #000;
    font-size: 20px;
  }
  .tag {
    color: #6d3190;
    font-size: 14px;
  }
  .name {
    font-size: 16px;
    color: #1c0039;
  }
  .divider {
    width: 100%;
    height: 0.66px;
    background-xolor: #e5e4de;
  }
  .label {
    color: #afa9bd;
    font-size: 12px;
  }
  .value {
    color: #1c0039;
    font-size: 14px;
    font-weight: 400;
  }
  .overlay {
    background-color: rgba(0, 0, 0, 0.66);
    border-radius: 9.21719px;
    width: 127px;
    height: 123px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ffffff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .more {
    color: #fff;
    text-align: center;
  }
  .img-overlay {
    width: 126px;
    height: 123px;
    border-radius: 9.2px;
    background-color: #f7f7f7;
  }
`;

const LatestNFTs = () => {
  const { t, i18n } = useTranslation();
  const isLTR = i18n.language !== "ar";
  const { loading, error, users } = useSelector(
    (state) => state.popular_sellers
  );
  const renderNFT = (nft) =>
    nft?.type === "image" ? (
      <LazyLoadImage
        alt=""
        effect="opacity"
        className="wrapper image radius"
        src={nft.url}
      />
    ) : nft?.type === "video" ? (
      <video
        muted
        autoPlay
        loop
        className="wrapper image radius"
        webkit-playsinline="true"
        playsInline
      >
        <source src={nft?.url} type="video/mp4" />
      </video>
    ) : (
      nft?.type === "audio" && (
        <div className="wrapper image radius d-flex align-items-center justify-content-center">
          <audio contolrs controlsList="nodownload" muted>
            <source src={nft?.url} type="audio/mp3" />
          </audio>
        </div>
      )
    );

  return (
    <>
      {users && (
        <section id="popular" style={{ backgroundColor: "#F4F4F4" }}>
          <Wrapper>
            <Container fluid="lg" className="main">
              {/* if loading */}
              {loading && <p>{t("Loading...")}</p>}
              {/* if error */}
              {error && <p>{error}</p>}
              {/* if the featured item exists */}
              {users && users?.length > 0 && (
                <div>
                  <ScrollBar
                    link="sellers"
                    data={users}
                    title={isLTR ? t("Popular") : t("Sellers")}
                    subtitle={isLTR ? t("Sellers") : t("Popular")}
                    renderCard={(item) => {
                      const nfts =
                        item?.nfts?.length > 4
                          ? item?.nfts.slice(0, 3)
                          : item?.nfts;
                      return (
                        // <Link
                        //   key={item._id}
                        //   to={`/nft/${item?.token_standard}/${item?._id}`}
                        //   style={{ textDecoration: "none" }}
                        // >
                        <div className="p-3 pb-2 card" key={Math.random()}>
                          <div className="d-flex">
                            <div>
                              <img
                                src={
                                  item?.user?.profile?.image_url ||
                                  "/user-placeholder.png"
                                }
                                className="user-60-rounded image me-3"
                                alt=""
                              />
                            </div>

                            <div className="d-flex flex-column justify-content-center">
                              <div className="creator">{item?.user?.name}</div>
                              <Link
                                key={item._id}
                                className="link"
                                to={`/profile/${item?.user?._id}`}
                              >
                                <div className="tag">{t("See profile")}</div>
                              </Link>
                            </div>
                          </div>
                          <div className="divider my-3" />
                          <div className="d-flex justify-content-between flex-wrap">
                            {nfts.map((nft) => (
                              <Link
                                key={nft._id}
                                className="link img-overlay nft"
                                to={`/nft/${nft.token_standard}/${nft._id}`}
                              >
                                {renderNFT(nft)}
                              </Link>
                            ))}
                            {item?.nfts?.length > 4 ? (
                              <div style={{ position: "relative" }}>
                                <div className="">
                                  {renderNFT(item?.nfts[3])}
                                </div>
                                <div className="overlay">
                                  <Link
                                    key={item._id}
                                    className="link"
                                    to={`/profile/${item?.user?._id}`}
                                  >
                                    <div className="more">
                                      + {nfts?.length - 3} NFTs
                                    </div>
                                    <div className="more">{t("More")}</div>
                                  </Link>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        // </Link>
                      );
                    }}
                  />
                </div>
              )}
            </Container>
          </Wrapper>
        </section>
      )}
    </>
  );
};

export default LatestNFTs;
