import styled from "styled-components";

const StylesWrapper = styled.div`
  .cover-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    object-fit: cover;
    height: ${(props) => `${props.imageHeight}px`};
  }
  .cover-txt {
    position: absolute;
    color: #fff;
    top: ${(props) => `${props.imageHeight / 2.5}px`};
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    font-size: 64px;
    fontweight: 400;
    text-align: center;
  }
  .subtitle {
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
  }
  .main {
    width: 100%;
    overflow: none;
    padding-top: ${(props) => `${props.imageHeight / 1.1}px`};
    padding-bottom: 100px;
  }
  @media (max-width: 768px) {
    .main {
      padding-bottom: 40px;
    }
  }
`;
export default StylesWrapper;
