import React, { useRef, useState, useEffect, Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Popover, PopoverBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { BsThreeDots } from "react-icons/bs";
import { ReactComponent as Arrow } from "@assets/icons/arrow-down.svg";
import {
  nft1155ListedMinted,
  nft1155UnListedMinted,
  nft1155LAZY_MINT,
  nftLazyMinted,
} from "../../../../common/nftDetailsOptions";
import { ListItem } from "./styles";
const NFT1155Options = ({
  nft,
  index,
  listed,
  colors,
  onOpenModal,
  setSelectedOwner,
  setConnectWalletModal,
}) => {
  const dotsRef = useRef();
  const { t, i18n } = useTranslation();
  const [isDotsOpen, setIsDotsOpen] = useState(false);
  const dotsToggle = () => setIsDotsOpen(!isDotsOpen);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dotsRef.current && !dotsRef.current.contains(event.target))
        setIsDotsOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dotsRef]);

  const onClick = (el) => {
    setSelectedOwner();
    //setConnectWalletModal(true);
    onOpenModal(el.key);
  };
  const options = listed
    ? nft?.status !== "LAZY_MINT"
      ? nft1155ListedMinted
      : nftLazyMinted
    : nft1155UnListedMinted;
  return (
    <Row ref={dotsRef}>
      <div id={`options-${index}`} className="cursor btn btn-purple-outline">
        {listed ? t("for_sale") : t("not_for_sale")}
        <Arrow style={{ marginInlineStart: 10 }} color="#6d3190" />
      </div>
      <Popover
        target={`options-${index}`}
        placement="bottom"
        isOpen={isDotsOpen}
        toggle={dotsToggle}
      >
        <PopoverBody>
          {options.map((el, index) => (
            <div style={{ fontSize: 15 }} key={el.key}>
              <ListItem
                colors={colors}
                className="d-block"
                onClick={() => onClick(el)}
              >
                {el.icon} {t(el.text)}
                {index !== options?.length - 1 && (
                  <div>
                    <hr style={{ margin: "8px 0 0" }} />
                  </div>
                )}
              </ListItem>
            </div>
          ))}
        </PopoverBody>
      </Popover>
    </Row>
  );
};

export default NFT1155Options;
