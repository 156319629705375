export const socketChannels = {
  NFT_DETAILS: "nft_details",
  CREATING_NFT: 'creating_nft'
};

export const socketEvents = {
  bid: {
    CANCEL_BID: "cancel_bid",
    PLACE_BID: "place_bid",
    EDIT_BID: "edit_bid",
  },
  nft_history: {
    CANCEL_NFT: "cancel_nft",
    PURCHASE_NFT: "purchase_nft",
  },
  progress: {
    CREATING_NFT_PROGRESS: 'creating_nft_progress'
  }
};

export const nftDetailsEvents = [
  socketEvents.bid.CANCEL_BID,
  socketEvents.bid.EDIT_BID,
  socketEvents.bid.PLACE_BID,
  socketEvents.nft_history.CANCEL_NFT,
  socketEvents.nft_history.PURCHASE_NFT,
  socketEvents.progress.CREATING_NFT_PROGRESS,
];
